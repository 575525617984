import {
  Header,
  Layout,
  Title,
} from 'ProjectProfile/common/components/Layout/Layout';

import { DuringDesign } from 'ProjectProfile/PreDesign/DuringDesign/DuringDesign';
import { Links } from 'ProjectProfile/PreDesign/Links/Links';
import { PreKickoff } from 'ProjectProfile/PreDesign/PreKickoff/PreKickoff';
import { Looks } from 'ProjectProfile/PreDesign/Looks/Looks';
import { ShareButton } from 'ProjectProfile/common/components/ShareButton/ShareButton';
import {
  useHomeownerLooks,
  useLooks,
} from 'ProjectProfile/PreDesign/Looks/Looks.hooks';

const Predesign = () => {
  const { data: roomsWithCurrentLooks, isLoading: isLooksLoading } = useLooks();
  const { isLoading: isHomeownerLooksLoading, roomsWithHomeownerLooks } =
    useHomeownerLooks();

  return (
    <Layout>
      <Header>
        <Title>Pre-Design</Title>
        <ShareButton />
      </Header>

      <Links />
      <PreKickoff />
      <Looks
        isHomeownerRooms
        isLoading={isHomeownerLooksLoading}
        rooms={roomsWithHomeownerLooks}
      />
      <Looks isLoading={isLooksLoading} rooms={roomsWithCurrentLooks} />
      <DuringDesign />
    </Layout>
  );
};

export default Predesign;
