import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FormControl as FormControlMui } from '@material-ui/core';
import MUIDialogActions from '@material-ui/core/DialogActions';

import { mq } from 'common/utils/mediaQueries';

export const FieldWrapper = styled.div`
  position: relative;
`;

export const FieldInfo = styled.span`
  color: ${({ theme }) => theme.color.gray};
  font-size: 14px;
  line-height: 20px;
  position: absolute;
  bottom: 10px;
  right: 15px;
`;

export const DialogActions = styled(MUIDialogActions)`
  display: flex;
  flex-flow: row;
  padding: 15px 24px;

  ${mq.medium(css`
    padding: 24px;
  `)};
`;

type DialogContentProps = {
  scrollable?: boolean;
};

export const DialogContent = styled.div<DialogContentProps>`
  padding: 10px 20px;

  ${({ scrollable }) =>
    scrollable &&
    css`
      overflow: auto;
      max-height: 400px;
      min-height: 100px;
      background:
        linear-gradient(white 10%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), white 90%) 0 100%,
        radial-gradient(
          50% 0,
          farthest-side,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
        radial-gradient(
            50% 100%,
            farthest-side,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0)
          )
          0 100%;
      background:
        linear-gradient(white 10%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), white 90%) 0 100%,
        radial-gradient(
          farthest-side at 50% 0,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
        radial-gradient(
            farthest-side at 50% 100%,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0)
          )
          0 100%;
      background-repeat: no-repeat;
      background-color: white;
      background-size:
        100% 40px,
        100% 40px,
        100% 6px,
        100% 6px;
      background-attachment: local, local, scroll, scroll;
    `};

  ${({ scrollable }) =>
    mq.medium(css`
      ${scrollable && `max-height: 700px;`}
      padding: 10px 24px;
    `)};
`;

export const FormControl = styled(FormControlMui)`
  width: 100%;
`;
