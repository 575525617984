import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

export const identifiers = [
  PropertyGroupIdentifier.CONCEPT_BOARD_NOTES,
  PropertyGroupIdentifier.MATERIAL_PRICE_NOTES,
  PropertyGroupIdentifier.BEFORE_DESIGN,
  PropertyGroupIdentifier.NEEDS_VS_NICE_TO_HAVES,
];

export const preKickoffNotes = {
  [PropertyGroupIdentifier.BEFORE_DESIGN]: `Sales to voice over design of Look, positives and specifics of products listed below.

- How does the homeowner respond to the Look (positive/ negative/ neutral)?

- What are things that they like/dislike about the Look?

- Are there other Looks that were considered?

- Who uses the space?

- What are functional and design priorities for the renovation?

- Do they have an overall budget in mind?

- Are there timeline considerations that we should be aware of?`,
};
