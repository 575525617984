import { gql } from '@apollo/client/core';

export const UPDATE_PROJECT_MATERIAL = gql`
  mutation ProjectMaterialUpdate($input: ProjectMaterialUpdateInput!) {
    projectMaterialUpdate(input: $input) {
      id
      status
      amount
      pricingEngine {
        id
        name
      }
    }
  }
`;
