import { css } from '@emotion/react';

export const formStyles = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px 14px;
  padding-top: 8px;

  > div {
    grid-area: auto / 1 / auto / -1;
  }
`;

export const buttonStyles = css`
  margin-top: 28px;
`;
