import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { mq } from 'common/utils/mediaQueries';
import { Typography } from 'common/components/Typography/Typography';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-height: 100vh;

  padding: 30px 16px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  text-align: center;
`;

export const Title = styled(Typography)`
  font-size: 24px;
  line-height: 28px;

  ${mq.large(css`
    font-size: 36px;
    line-height: 44px;
    max-width: 640px;
  `)}
`;

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.color.gray8};

  ${mq.large(css`
    max-width: 500px;
  `)}

  a {
    color: ${({ theme }) => theme.color.orange4};
    text-decoration: underline;
    cursor: pointer;
  }
`;
