import { gql } from '@apollo/client';

export const PUBLISH_PROPOSAL = gql`
  mutation ProposalPublish($input: ProposalPublishInput!) {
    proposalPublish(input: $input) {
      id
      status
      publishedAt
    }
  }
`;

export const UNPUBLISH_PROPOSAL = gql`
  mutation ProposalUnPublish($input: ProposalUnPublishInput!) {
    proposalUnPublish(input: $input) {
      id
      status
      publishedAt
    }
  }
`;

export const CREATE_PROPOSAL = gql`
  mutation ProposalCreate($input: ProposalCreateInput!) {
    proposalCreate(input: $input) {
      id
    }
  }
`;

export const DUPLICATE_PROPOSAL = gql`
  mutation ProposalDuplicate($input: ProposalDuplicateInput!) {
    proposalDuplicate(input: $input) {
      id
      # TODO: Remove when scope of work is in place
      pricingEngines {
        id
      }
    }
  }
`;
