import styled from '@emotion/styled';

import { GridTable } from 'common/components/Tables/GridTable/GridTable';

export const TableWrapper = styled.div`
  padding: 16px;
`;

export const Table = styled(GridTable)`
  overflow: unset;
`;
