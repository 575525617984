import EditIcon from '@material-ui/icons/Edit';
import { ReactNode } from 'react';

import { Box, IconButton } from '@material-ui/core';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { ZipCodeStatus } from 'common/apollo/enums/ZipCodeStatus';

import { ZIP_CODE_STATUS_DICTIONARY } from 'common/consts/zipCodeStatus';

import { Button } from 'common/components/Buttons/Button/Button';
import { Collapse } from 'common/components/Collapse/Collapse';
import { CollapseContent } from 'common/components/Collapse/CollapseContent';
import { CopyToClipboardButton } from 'common/components/Buttons/CopyToClipboardButton/CopyToClipboardButton';
import { RightChevronCollapseButton } from 'common/components/Collapse/CollapseButtons/RightChevronCollapseButton/RightChevronCollapseButton';
import { SidebarElement } from 'common/components/Sidebar/SidebarContent.styles';
import { useUserFullAddress, useUserFullName } from 'common/hooks/user.hooks';

import {
  useFetchLoginLink,
  useLoginAsUser,
  useSendLoginLink,
} from 'common/hooks/LoginAsUser/loginAsUser.hooks';

import { AuthReferral } from 'common/apollo/enums/AuthReferral';
import { MarketCode } from 'common/enums/MarketCode';
import { ProjectType } from 'common/apollo/enums/ProjectType';

import { ProjectTypeBadge } from 'common/shared/Sidebar/ProjectTypeTag/ProjectTypeTag';

import { UserProfile } from 'common/apollo/models/userProfile';

import { FeatureFlags } from 'common/enums/FeatureFlags';

import { useAlert } from 'common/hooks/useAlert';

import {
  ButtonsWrapper,
  Content,
  ContentTwoColumnsItem,
  editIconStyles,
  titleStyles,
  LoginAsUserButton,
  ServiceableStatusExternalLink,
} from './Profile.styles';

type Props = {
  children?: ReactNode;
  marketCode?: MarketCode | null;
  onEdit: () => void;
  projectId: string;
  projectType?: ProjectType | null;
  serviceableStatus: ZipCodeStatus;
  userId: string;
} & UserProfile;

export const Profile = ({
  children,
  city,
  email = '',
  firstName = '',
  lastName = '',
  marketCode,
  onEdit,
  phoneNumber,
  projectId,
  serviceableStatus,
  state,
  street,
  street_2,
  userId,
  zip,
}: Props) => {
  const { loginAsUser } = useLoginAsUser();
  const { fetchLoginLink } = useFetchLoginLink();
  const { sendUserLoginLink } = useSendLoginLink();
  const { onWarning } = useAlert();
  const flags = useFlags();
  const changeHomeownerLinkFunctionalityEnabled =
    flags[FeatureFlags.CHANGE_COPY_HOMEOWNER_LINK_TO_SEND_EMAIL];

  const userFullName = useUserFullName({ firstName, lastName });
  const address = useUserFullAddress({
    city,
    state,
    zip,
  });

  const isServiceable = serviceableStatus === ZipCodeStatus.SERVICEABLE;

  const onCopy = () => {
    onWarning(
      'This link can only be used one time, please do not click it before sending to the user',
    );
    return fetchLoginLink(userId, AuthReferral.USER, projectId);
  };

  return (
    <>
      <SidebarElement>
        <Box mb={1}>
          <ProjectTypeBadge renovationPlanId={projectId} />
        </Box>

        <Collapse isOpen>
          <RightChevronCollapseButton
            css={titleStyles}
            title={`Project ID: #${projectId} - ${marketCode}`}
          />
          <CollapseContent>
            <Content>
              <span>User ID: {userId}</span>
              <ContentTwoColumnsItem>
                <span>{userFullName}</span>
                <IconButton
                  aria-label="edit-profile"
                  css={editIconStyles}
                  onClick={onEdit}
                >
                  <EditIcon />
                </IconButton>
              </ContentTwoColumnsItem>

              <span>{email}</span>
              {phoneNumber && <span>{phoneNumber}</span>}
              {street && <span>{street}</span>}
              {street_2 && <span>{street_2}</span>}
              {!!address.length && <span>{address}</span>}

              <ServiceableStatusExternalLink
                href="https://retool.blockrenovation.com/apps/Sales/Service%20Area%20Map"
                isServiceable={isServiceable}
                rel="noreferrer"
                target="_blank"
              >
                {ZIP_CODE_STATUS_DICTIONARY[serviceableStatus]}
              </ServiceableStatusExternalLink>
            </Content>
          </CollapseContent>
        </Collapse>
        {children}
      </SidebarElement>

      <SidebarElement>
        <ButtonsWrapper>
          <LoginAsUserButton
            color="primary"
            onClick={() => loginAsUser(userId, projectId)}
            small
            variant="outlined"
          >
            Log in as User
          </LoginAsUserButton>
          {changeHomeownerLinkFunctionalityEnabled ? (
            <Button
              color="primary"
              onClick={() => sendUserLoginLink(email)}
              small
              variant="outlined"
            >
              Send Homeowner Link
            </Button>
          ) : (
            <CopyToClipboardButton
              asyncGetCopyText={onCopy}
              color="primary"
              small
              variant="outlined"
            >
              Copy Homeowner Link
            </CopyToClipboardButton>
          )}
        </ButtonsWrapper>
      </SidebarElement>
    </>
  );
};
