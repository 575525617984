import { useMutation } from '@apollo/client';

import { useParams } from 'react-router-dom';

import { useAlert } from 'common/hooks/useAlert';

import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

import { GetProjectPlanQuestionsResponse } from 'common/apollo/responses/getProjectPlanQuestionsResponse';
import { UPDATE_PROJECT_OVERVIEW_QUESTIONS } from 'common/apollo/mutations/updatePropertyProject';
import {
  UpdateProjectBuildingInfoQuestions,
  UpdateProjectBuildingInfoQuestionsVariables,
} from 'common/apollo/mutations/__generated__/projectOverview.graphql.generated';

import { GET_PROJECT_PLAN_QUESTIONS } from 'ProjectProfile/common/apollo/gql/getProjectPlanQuestions';
import { FormValues } from 'ProjectProfile/common/types/formValues';

import { QueryParams } from 'ProjectProfile/common/types/queryParams';

import { normalizeQuestionsSelection } from '../utils/projectBuildingQuestions';

type Props = {
  identifiers: PropertyGroupIdentifier[];
};

type SubmitProps = {
  handleSuccess?: () => void;
  questionId: string;
  values: FormValues;
};

export const useProjectBuildingInformationUpdate = ({ identifiers }: Props) => {
  const { projectId } = useParams<QueryParams>();
  const { onCompleted, onError } = useAlert();

  const [updateProjectBuildingInformation, { loading: isLoading }] =
    useMutation<
      UpdateProjectBuildingInfoQuestions,
      UpdateProjectBuildingInfoQuestionsVariables
    >(UPDATE_PROJECT_OVERVIEW_QUESTIONS, {
      onCompleted: () => onCompleted('Changes saved'),
      onError: (error) => onError(error.message),
    });

  const handleSubmit = async ({
    handleSuccess,
    questionId,
    values,
  }: SubmitProps) => {
    const response = await updateProjectBuildingInformation({
      update: (cache) => {
        const query = {
          query: GET_PROJECT_PLAN_QUESTIONS,
          variables: {
            input: { identifiers, projectId },
          },
        };

        const cachedData =
          cache.readQuery<GetProjectPlanQuestionsResponse>(query);

        if (!cachedData) return;

        const [question] = cachedData.projectPlanQuestions;
        const [selectedId] = Object.values(values);

        const answers = question.answers.map((answer) => ({
          ...answer,
          isSelected: answer.id === selectedId,
        }));

        cache.writeQuery({
          data: {
            projectPlanQuestions: [{ ...question, answers }],
          },
          ...query,
        });
      },
      variables: {
        input: {
          answers: [
            {
              answerIds: normalizeQuestionsSelection(values),
              questionId,
            },
          ],
          projectId,
        },
      },
    });

    if (response && handleSuccess) handleSuccess();
  };

  return {
    handleSubmit,
    isLoading,
  };
};
