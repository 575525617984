import styled from '@emotion/styled';

import { css } from '@emotion/react';

import { mq } from 'common/utils/mediaQueries';

export const Wrapper = styled.div<{ isDirty: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin: 0 auto;
  max-width: 544px;
  padding: 48px 12px;

  ${({ isDirty }) => css`
    margin-top: ${isDirty ? '179px' : '0'};

    ${mq.small(css`
      margin-top: ${isDirty ? '77px' : '0'};
    `)}
  `}
`;

export const InfoWrapper = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.color.gray2};
  border-radius: 4px;
  display: flex;
  gap: 16px;
  padding: 12px 24px;
  position: relative;

  &:before {
    background: ${({ theme }) => theme.color.gray4};
    border-radius: 4px 0 0 4px;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 8px;
  }
`;

export const InfoTitle = styled.p`
  ${({ theme }) => theme.typography.body2};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const SpacesWrapper = styled.div`
  border: solid 1px ${({ theme }) => theme.color.gray6};
  border-radius: 8px;
`;

export const SpacesOuter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Label = styled.label`
  ${({ theme }) => theme.typography.header6};
  color: ${({ theme }) => theme.color.gray11};
`;

export const AddSpace = styled.button`
  ${({ theme }) => theme.typography.header6};
  color: ${({ theme }) => theme.color.gray11};
`;

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const VisibilityLabel = styled.div`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.gray11};
  display: flex;
  gap: 5px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.p`
  ${({ theme }) => theme.typography.header5};
  margin-bottom: 4px;
`;

export const SectionDescription = styled.p`
  ${({ theme }) => theme.typography.body2};
  margin-bottom: 24px;
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
