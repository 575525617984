import { ProjectStatus } from 'common/apollo/enums/ProjectStatus';

export const projectStatusesDictionary = {
  [ProjectStatus.ACTIVE]: 'Active',
  [ProjectStatus.COMPLETED]: 'Completed',
  [ProjectStatus.LOST]: 'Lost',
  [ProjectStatus.ON_HOLD]: 'On Hold',
};

export const statusList = [
  {
    label: projectStatusesDictionary[ProjectStatus.ACTIVE],
    value: ProjectStatus.ACTIVE,
  },
  {
    label: projectStatusesDictionary[ProjectStatus.COMPLETED],
    value: ProjectStatus.COMPLETED,
  },
  {
    label: projectStatusesDictionary[ProjectStatus.LOST],
    value: ProjectStatus.LOST,
  },
  {
    label: projectStatusesDictionary[ProjectStatus.ON_HOLD],
    value: ProjectStatus.ON_HOLD,
  },
];
