import { Grid, GridProps } from '@material-ui/core';

import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & GridProps;

export const LinkItem = ({ children, ...gridProps }: Props) => (
  <Grid item md={6} sm={12} xs={12} {...gridProps}>
    {children}
  </Grid>
);
