import styled from '@emotion/styled';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Lock from '@material-ui/icons/Lock';

export const Row = styled.div`
  padding: 12px 0;
`;

export const InputWrapper = styled.div`
  display: flex;
  padding: 12px 0;
  width: 100%;
`;

export const LineHeader = styled.h4`
  font-size: 14px;
  font-weight: bold;
`;

export const Date = styled.p`
  font-size: 12px;
`;

export const EditIcon = styled(Edit)`
  cursor: pointer;
  font-size: 19px;
  font-weight: bold;
  margin-left: 8px;
  position: relative;
  top: 5px;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  font-size: 19px;
  font-weight: bold;
  margin-left: 8px;
  position: relative;
  top: 8px;
`;

export const LockIcon = styled(Lock)`
  font-size: 19px;
  font-weight: bold;
  margin-left: 8px;
  position: relative;
  top: 8px;
`;

export const SidebarHeader = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
`;
