import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isPropValid from '@emotion/is-prop-valid';

export const Wrapper = styled.div<{ fluid?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
`;

export const InputWrapper = styled('div', { shouldForwardProp: isPropValid })<{
  disabled?: boolean;
  hasError?: boolean;
  isTextarea?: boolean;
  small?: boolean;
}>`
  ${({ disabled, hasError, isTextarea, small, theme }) => css`
    align-items: ${isTextarea ? 'baseline' : 'center'};
    background: ${theme.color.white};
    border-radius: 0;
    border-radius: 4px;
    border: 1px solid ${theme.color.gray6};
    color: ${theme.color.darkGray};
    display: inline-flex;
    gap: 8px;
    outline: 0;
    padding: ${small ? '10px 12px' : '12px 16px'};
    transition: all 0.125s ease;
    width: 100%;

    ${hasError &&
    css`
      background-color: ${theme.color.red2};
      border-color: ${theme.color.red6};
      color: ${theme.color.red11};
    `}

    ${disabled &&
    css`
      background-color: ${theme.color.gray1};
    `}

    &:focus-within {
      border-color: ${disabled ? theme.color.gray7 : theme.color.orange6};
      color: ${disabled ? theme.color.gray11 : theme.color.black};
    }

    &:hover {
      border-color: ${disabled ? theme.color.gray7 : theme.color.orange6};
    }

    ${Input} {
      ${small ? theme.typography.body2 : theme.typography.body1};

      &::placeholder {
        color: ${hasError ? theme.color.red11 : theme.color.gray11};
      }

      &:focus::placeholder {
        color: ${hasError ? theme.color.red11 : theme.color.gray11};
      }

      &[disabled] {
        color: ${theme.color.gray11};
        cursor: not-allowed;
      }

      &[disabled]::placeholder {
        color: ${theme.color.gray11};
      }
    }
  `};
`;

export const Input = styled.input`
  background: transparent;
  border: 0;
  cursor: text;
  outline: 0;
  resize: vertical;
  width: 100%;
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.label`
  ${({ theme }) => theme.typography.header6};
  color: ${({ theme }) => theme.color.gray11};
`;

export const ExtraLabel = styled.div`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.gray11};
  margin-left: auto;
`;

export const HelperWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HelperText = styled.p<{ hasError?: boolean }>`
  ${({ theme }) => theme.typography.body2};
  color: ${({ hasError, theme }) =>
    hasError ? theme.color.red11 : theme.color.gray11};
`;

export const Counter = styled.p`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.gray11};
  margin-left: auto;
`;
