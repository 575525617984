import { Grid } from '@material-ui/core';

import { Container } from 'ProjectProfile/common/components/Container/Container';
import { DesignerNotes } from 'ProjectProfile/common/components/DesignerNotes/DesignerNotes';

export const DuringDesign = () => (
  <Container title="During Design">
    <Grid container spacing={4}>
      <Grid item md={10} sm={12} xs={12}>
        <DesignerNotes editable />
      </Grid>
    </Grid>
  </Container>
);
