import {
  ChangeEvent,
  DragEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useField, useFormikContext } from 'formik';

import { v4 as uuid } from 'uuid';

import { IMAGE_MIME_TYPES, VIDEO_MIME_TYPES } from 'common/consts/mimeTypes';

import { useSwitch } from 'common/hooks/useSwitch';
import { useMatchMedia } from 'common/hooks/useMatchMedia';

import { breakpoints } from 'common/utils/mediaQueries';

import {
  Dictionary,
  PhotoUI,
} from 'common/components/PhotoUpload/photoUpload.types';
import { reduceImage } from 'common/utils/reduceImage';
import { useAlert } from 'common/hooks/useAlert';
import { PricingEngineMediaType } from 'common/apollo/enums/PricingEngineMediaType';

const MAX_FILE_SIZE_MB = 25;

export const usePhotoUpload = (
  allowedFileTypes: string[],
  fieldName: string,
) => {
  const { setFieldValue } = useFormikContext<Dictionary>();
  const [field] = useField<PhotoUI[]>(fieldName);
  const inputRef = useRef<HTMLInputElement>(null);
  const { isOn: isEditMode, toggle } = useSwitch();
  const { onError } = useAlert();

  const isMobile = useMatchMedia({
    breakpoint: breakpoints.mobileMax,
    mediaQuery: 'max-width',
  });

  const photos = field.value;

  const addFiles = (files: File[]) => {
    Promise.all(
      files.map((file) => {
        if (IMAGE_MIME_TYPES.includes(file.type)) {
          // reduce images to max of 3 megapixels
          return reduceImage(file, 3);
        }
        return file;
      }),
    ).then((reducedFiles) =>
      setFieldValue(fieldName, [
        ...photos,
        ...reducedFiles.map((reducedFile) => {
          const url = URL.createObjectURL(reducedFile);

          let mediaType = PricingEngineMediaType.IMAGE;

          if (IMAGE_MIME_TYPES.includes(reducedFile.type)) {
            mediaType = PricingEngineMediaType.IMAGE;
          } else if (VIDEO_MIME_TYPES.includes(reducedFile.type)) {
            mediaType = PricingEngineMediaType.VIDEO;
          } else {
            mediaType = PricingEngineMediaType.DOCUMENT;
          }

          return {
            alt: reducedFile.name,
            file: reducedFile,
            id: `tmp-file-id-${Date.now()}-${uuid()}`,
            lightBoxMobileUrl: url,
            lightBoxUrl: url,
            type: mediaType,
            url,
          };
        }),
      ]),
    );
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);

    if (!files.length) return;

    const isValid = files.every((file) => allowedFileTypes.includes(file.type));

    if (!isValid) return;

    const validFiles = files.filter((f) => {
      if (
        !IMAGE_MIME_TYPES.includes(f.type) &&
        f.size >= MAX_FILE_SIZE_MB * 1024 * 1024
      ) {
        onError(
          `${f.name} is bigger than the maximum file size of ${MAX_FILE_SIZE_MB}MB`,
        );
        return false;
      }
      return true;
    });

    if (!isValid) {
      return;
    }

    addFiles(validFiles);
  };

  const handleFileDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const transferItems = Array.from(event.dataTransfer.items);
    const files = transferItems
      .map((item) => item.getAsFile())
      .filter((file) => file && allowedFileTypes.includes(file.type)) as File[];

    if (!files.length) return;

    addFiles(files);
  };

  const handleFileDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleUploadButtonClick = () => {
    inputRef.current?.click();
  };

  const handlePhotoDelete = (photoId: string) => {
    setFieldValue(
      fieldName,
      photos.filter(({ id }) => id !== photoId),
    );
  };

  const [photoIndex, setPhotoIndex] = useState<number | null>(null);

  const handleOpen = useCallback(() => setPhotoIndex(null), []);
  const mediaList = useMemo(
    () =>
      photos
        ?.filter(({ type }) => type === PricingEngineMediaType.IMAGE)
        .map(({ url }) => url),
    [photos],
  );

  const lightBoxProps = {
    enableZoom: false,
    handleOpen,
    isOpen: photoIndex !== null,
    mediaList,
    photoIndex: photoIndex ?? 0,
    previewRender: setPhotoIndex,
    setPhotoIndex,
  };

  const isEmpty = !photos?.length;

  return {
    allowedFileTypes,
    handleFileDragOver,
    handleFileDrop,
    handleInputChange,
    handlePhotoDelete,
    handleUploadButtonClick,
    inputRef,
    isEditMode,
    isEmpty,
    isMobile,
    lightBoxProps,
    photos,
    toggle,
  };
};
