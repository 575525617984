import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mq } from 'common/utils/mediaQueries';

type TableHeaderCellProps = {
  minWidth?: string;
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

export const Content = styled.section`
  overflow: auto;
`;

export const Table = styled.table`
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGray};
  border-collapse: collapse;
  border-spacing: 0;
  font: 400 14px/24px ${({ theme }) => theme.font.inter};
  letter-spacing: 0;
  text-align: left;
  width: 100%;
`;

export const TableHead = styled.thead`
  ${({ theme }) => css`
    --color: ${theme.color.concrete};
  `}

  background: var(--color);
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGray};

  ${mq.large(css`
    white-space: nowrap;
  `)};

  th {
    background: var(--color);
    font-weight: 500;
    padding: 16px;
    position: sticky;
    text-transform: none;
    top: 0;
    z-index: 3;
  }

  .MuiTableSortLabel-root {
    color: black;
  }
`;

export const TableHeaderCell = styled.th<TableHeaderCellProps>`
  min-width: ${({ minWidth }) => minWidth};
`;
