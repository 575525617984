export const EMAIL_VALIDATION = 'Enter a valid email.';
export const FULL_NUMBER_VALIDATION = 'Should be full number';
export const MAXIMUM_VALUE_999 = 'Maximum value is 999';
export const MINIMUM_VALUE_999 = 'Minimum value is -999';
export const POSITIVE_NUMBER = 'Value has to be greater than 0';
export const NEGATIVE_NUMBER = 'Value has to be lower than 0';
export const REQUIRED_VALIDATION = 'This field is required.';
export const PASSWORD_STRENGTH_POLICY_DESCRIPTION =
  'Your password must be between 8 and 64 characters. Avoid using sequential or repeated characters.';
export const PASSWORD_MATCH = 'Passwords must match';
export const URL_REQUIRED = 'Enter a valid url.';
export const FIELD_REQUIRED = 'Field is required.';
export const IMAGE_REQUIRED = 'Image Field is required.';
export const FIELD_TAPE_MEASUREMENTS_REQUIRED =
  'This field requires valid tape measurement value';
