import { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery, FetchResult, DataProxy } from '@apollo/client';

import { mapToProposal } from 'common/mappers/toProposal';
import { useProfileSidebarSection } from 'common/shared/Sidebar/Profile/profile.hooks';

import { ProjectAddressUpdate } from 'common/shared/Sidebar/Profile/__generated__/profile.graphql.generated';

import { useAlert } from 'common/hooks/useAlert';

import { GET_PROJECT_MARKET } from 'common/apollo/queries/projectMarket.graphql';

import { useGetProjectPlanSecondaryContact } from 'common/hooks/useProjectPlanSecondaryContact';

import { GET_SIDEBAR_PROPOSAL } from './pricingEngineSidebar.graphql';

import {
  SidebarProposalGet,
  SidebarProposalGetVariables,
} from './__generated__/pricingEngineSidebar.graphql.generated';

const useGetProposal = (proposalId: string) => {
  const { data, loading } = useQuery<
    SidebarProposalGet,
    SidebarProposalGetVariables
  >(GET_SIDEBAR_PROPOSAL, {
    skip: !proposalId,
    variables: {
      id: proposalId,
    },
  });

  const proposal = mapToProposal(data?.proposal || {});

  const {
    address,
    id: renovationPlanId,
    projectType,
    user,
  } = proposal?.renovationPlan;

  const isLoading = !data && loading;

  return {
    address,
    isLoading,
    projectType,
    proposal,
    renovationPlanId,
    user,
    userId: user?.id,
  };
};

const useGetMarketCode = (id: string) => {
  const { onError } = useAlert();

  const { data } = useQuery(GET_PROJECT_MARKET, {
    onError: ({ message }) => onError(message),
    skip: !id,
    variables: {
      id,
    },
  });

  const { market, serviceableStatus } = data?.project || {};

  return {
    marketCode: (market && market.code) || null,
    serviceableStatus,
  };
};

const useProposalAddressUpdate = (proposalId: string) => {
  const onAddressUpdateComplete = useCallback(
    (proxy: DataProxy, { data }: FetchResult<ProjectAddressUpdate>) => {
      const proposalDetails = proxy.readQuery<
        SidebarProposalGet,
        SidebarProposalGetVariables
      >({
        query: GET_SIDEBAR_PROPOSAL,
        variables: {
          id: proposalId,
        },
      });

      if (!proposalDetails || !data?.projectAddressUpdate) return;

      const { address } = data.projectAddressUpdate;

      proxy.writeQuery({
        data: {
          proposal: {
            ...proposalDetails.proposal,
            renovationPlan: {
              ...proposalDetails.proposal.renovationPlan,
              address,
            },
          },
        },
        query: GET_SIDEBAR_PROPOSAL,
        variables: {
          id: proposalId,
        },
      });
    },
    [proposalId],
  );

  return { onAddressUpdateComplete };
};

export const usePricingEngineSidebar = () => {
  const [isEditInProgress, setIsEditInProgress] = useState(false);
  const { proposalId } = useParams<{ proposalId: string }>();

  const {
    address,
    isLoading,
    projectType,
    proposal,
    renovationPlanId,
    user,
    userId,
  } = useGetProposal(proposalId);

  const { onAddressUpdateComplete } = useProposalAddressUpdate(proposalId);
  const toggleIsEditInProgress = useCallback(
    () => setIsEditInProgress((status) => !status),
    [setIsEditInProgress],
  );

  const { initialValues, loadingSubmit, onSubmit, userData } =
    useProfileSidebarSection({
      address,
      onAddressUpdateComplete,
      onEditComplete: toggleIsEditInProgress,
      renovationPlanId,
      user,
      userId,
    });

  const { marketCode, serviceableStatus } = useGetMarketCode(renovationPlanId);
  const { secondaryContact } = useGetProjectPlanSecondaryContact({
    projectId: renovationPlanId,
  });
  const secondaryContactEmail = secondaryContact.email;

  return {
    initialValues,
    isEditInProgress,
    isLoading,
    loadingSubmit,
    marketCode,
    onSubmit,
    projectType,
    proposal,
    renovationPlanId,
    secondaryContactEmail,
    serviceableStatus,
    toggleIsEditInProgress,
    userData,
    userId,
  };
};
