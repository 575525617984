import { gql } from '@apollo/client';

export const UPDATE_PROJECT_ORDER = gql`
  mutation UpdateProjectOrder($input: OrderUpdateInput!) {
    orderUpdate(input: $input) {
      id
      blockRequestedDeliveryDate
      comments
      confirmedDeliveryDate
      dateOrdered
      initialRequestedDeliveryDate
      number
      reason
      requestedDeliverTo
      requestedShippingMethod
      shippingTotal
      subtotal
      taxTotal
      timing
      vendorComments
      webOrderNumber
      refundAmount
    }
  }
`;
