import styled from '@emotion/styled';

import { css } from '@emotion/react';

import { Button } from 'common/components/Buttons/Button/Button';

import { THEME } from 'common/consts/theme';

type WrapperProps = {
  isAction?: boolean;
  isSelected?: boolean;
};

type CellProps = {
  cellWidth: string;
  isAction?: boolean;
  isSticky?: boolean;
  zIndex?: string;
};

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.color.orange2 : theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray7};
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  padding-right: 2px;

  &:last-of-type {
    border-bottom: none;
  }

  ${({ isAction }) =>
    isAction &&
    css`
      text-decoration-color: ${THEME.color.orange11};
      &:hover {
        text-decoration-line: underline;
      }
    `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.color.gray12};
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
`;

export const SaveButton = styled(Button)`
  color: ${({ theme }) => theme.color.primaryOrangeDark};
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
`;

export const Cell = styled.div<CellProps>`
  color: ${({ isAction, theme }) =>
    isAction ? theme.color.orange11 : theme.color.gray12};
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
  padding: 4px 16px;
  text-align: left;
  width: ${({ cellWidth }) => `${cellWidth}`};

  &:first-of-type {
    text-align: center;
  }
`;

export const Row = styled.div<WrapperProps>`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 2px 0;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.color.orange2 : theme.color.white};
`;

export const iconStyles = css`
  color: ${THEME.color.gray11};
  height: 14px;
  width: 14px;

  svg {
    height: inherit;
    width: inherit;
  }
`;

export const ActionWrapper = styled.div`
  align-items: center;
  display: flex;
  padding: 2px 10px;
`;

export const stickyColumnStyle = css`
  background: inherit;
  position: sticky;
  &:nth-of-type(1) {
    left: 0;
  }
  &:nth-of-type(2) {
    left: 64px;
  }
  &:nth-of-type(3) {
    left: 128px;
  }
`;
