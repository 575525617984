import { dateToUSFormatWithAt } from 'common/utils/date';

import ChangeLogChange from 'ProjectProfile/ChangeLog/ChangeLogChange/ChangeLogChange';
import { GroupedProjectPlanChangeLog } from 'ProjectProfile/common/utils/normalizeChangeLog';

import {
  Header,
  Outer,
  Strong,
  Timestamp,
} from 'ProjectProfile/ChangeLog/ChangeLogItem/changeLogItem.styles';
import { ChangeLogType } from 'common/apollo/enums/ChangeLogType';
import ChangeLogFlag from 'ProjectProfile/ChangeLog/ChangeLogFlag/ChangeLogFlag';

type Props = {
  changes: GroupedProjectPlanChangeLog;
};

const getHeaderText = (type: ChangeLogType) => {
  switch (type) {
    case ChangeLogType.FLAG:
      return 'flagged in ';
    case ChangeLogType.UNFLAG:
      return 'unflagged in ';
    default:
      return 'edited ';
  }
};

const ChangeLogItem = ({ changes }: Props) => (
  <Outer>
    <Header>
      <Strong>{changes.name}</Strong>
      {getHeaderText(changes.type)}
      <Strong>{changes.subSection}</Strong>
    </Header>
    <Timestamp>{dateToUSFormatWithAt(changes.date)}</Timestamp>
    {changes.changes &&
      (changes.type === ChangeLogType.FLAG ||
        changes.type === ChangeLogType.UNFLAG) &&
      changes.changes.map((c) => (
        <ChangeLogFlag difference={c} key={c.question.id} />
      ))}
    {changes.changes &&
      (changes.type === ChangeLogType.INSERT ||
        changes.type === ChangeLogType.UPDATE) &&
      changes.changes.map((c) => (
        <ChangeLogChange difference={c} key={c.question.id} />
      ))}
  </Outer>
);

export default ChangeLogItem;
