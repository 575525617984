// eslint-disable-next-line import/no-extraneous-dependencies
import { TokenRefreshLink } from 'apollo-link-token-refresh';
// eslint-disable-next-line import/no-extraneous-dependencies
import { jwtDecode } from 'jwt-decode';
import { ApolloLink } from '@apollo/client';

import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from 'common/consts/localStorage';

export const refreshTokenMiddleware = ApolloLink.from([
  new TokenRefreshLink({
    accessTokenField: 'accessToken',
    fetchAccessToken: () => {
      return fetch(
        `${process.env.REACT_APP_API_URL}/admin/auth/refresh-token`,
        {
          credentials: 'include',
          method: 'POST',
        },
      );
    },
    handleFetch: (accessToken) => {
      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, accessToken);
    },
    isTokenValidOrUndefined: async () => {
      const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);

      if (!token) return true;

      try {
        return Date.now() < (jwtDecode(token)?.exp ?? 0) * 1000;
      } catch {
        return false;
      }
    },
  }),
]);
