import { ReactNode } from 'react';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

type Props = {
  children: ReactNode;
};

export const DateTimePickerProvider = ({ children }: Props) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    {children}
  </MuiPickersUtilsProvider>
);
