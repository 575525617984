import { useFormikContext } from 'formik';

import { FormEvent, useEffect } from 'react';

import { ApolloError, useMutation } from '@apollo/client';

import { GET_ADMIN_KEY_DATES } from 'common/apollo/queries/getAdminKeyDates.graphql';
import { TargetDateSetResponse } from 'common/apollo/responses/targetDateSetResponse';
import { TargetDateSetRequest } from 'common/apollo/requests/targetDateSetRequest';
import { ProjectKeyDateType } from 'common/apollo/enums/ProjectKeyDateType';

import { calculateDaysLate } from 'common/utils/date';
import { useAlert } from 'common/hooks/useAlert';
import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';

import { TARGET_DATE_SET } from 'ClientTasks/TargetDate/SetTargetDateModal/setTargetDateModal.graphql';
import { MATERIALS_LONGEST_LEAD_TIME_SET_DIALOG } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeSet/materialsLongestLeadTimeSet.consts';
import { useProjectId } from 'MaterialsLongestLeadTime/common/hooks/useProjectId';

type FromValues = {
  date: Date;
  days: number;
  materialItem: any;
  notes: string;
};

export const useMaterialsLongestLeadTimeSetForm = () => {
  const setModalOpen = useSetModalOpen();
  const projectId = useProjectId();
  const { onError } = useAlert();
  const {
    dirty,
    isValid,
    setFieldValue,
    touched,
    values: { date, days, materialItem, notes },
  } = useFormikContext<FromValues>();

  const [targetDateSet] = useMutation<
    TargetDateSetResponse,
    TargetDateSetRequest
  >(TARGET_DATE_SET, {
    onError: (error: ApolloError) => onError(error.message),
    refetchQueries: [
      {
        query: GET_ADMIN_KEY_DATES,
        variables: {
          input: {
            renovationPlanId: projectId || '',
          },
        },
      },
    ],
  });

  useEffect(() => {
    if (touched.date) {
      const daysDiff = -1 * calculateDaysLate(`${date}`);
      setFieldValue('days', daysDiff);
    }
  }, [date, setFieldValue, touched.date]);

  useEffect(() => {
    const dateToday = new Date();

    if (dirty) {
      dateToday.setDate(dateToday.getDate() + days);
      setFieldValue('date', dateToday);
    }
  }, [days, setFieldValue, dirty]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const input = {
      note: notes,
      projectId: projectId || '',
      reasonId: materialItem,
      type: ProjectKeyDateType.LONGEST_LEAD_TIME,
      value: date,
    };

    await targetDateSet({
      variables: {
        input,
      },
    });

    setModalOpen(MATERIALS_LONGEST_LEAD_TIME_SET_DIALOG, false);
  };

  const disableSubmitButton = !isValid || !dirty;

  return {
    disableSubmitButton,
    handleSubmit,
  };
};
