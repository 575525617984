import styled from '@emotion/styled';

import { Typography } from 'common/components/Typography/Typography';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.h6`
  ${({ theme }) => theme.typography.subHeading};

  color: ${({ theme }) => theme.color.grayWithOpacity};
  font-weight: 400;
  text-transform: none;
`;

export const Value = styled(Typography)`
  color: ${({ theme }) => theme.color.black};
  word-break: break-word;
`;
