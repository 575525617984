export enum AuthUserPermission {
  CHANGE_ORDER_READ = 'CHANGE_ORDER_READ',
  CHANGE_ORDER_WRITE = 'CHANGE_ORDER_WRITE',
  FINANCE_WRITE = 'FINANCE_WRITE',
  MATERIAL_CATALOG_WRITE = 'MATERIAL_CATALOG_WRITE',
  PORTFOLIO_REPORTING_READ = 'PORTFOLIO_REPORTING_READ',
  PROJECT_FINANCES_READ = 'PROJECT_FINANCES_READ',
  USER_ACCOUNT_WRITE = 'USER_ACCOUNT_WRITE',
  WORK_ORDER_READ = 'WORK_ORDER_READ',
}
