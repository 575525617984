import { PricingEngineMediaType } from 'common/apollo/enums/PricingEngineMediaType';
import {
  DeleteButton,
  Image,
  Wrapper,
  IconImage,
} from 'common/components/PhotoUpload/Photo/Photo.styles';

type Props = {
  alt?: string;
  isEditMode?: boolean;
  onClick: () => void;
  onDelete: () => void;
  type: PricingEngineMediaType;
  url: string;
};

export const Photo = ({
  alt,
  isEditMode,
  onClick,
  onDelete,
  type,
  url,
}: Props) => (
  <Wrapper>
    <>
      <DeleteButton
        aria-label="Delete Photo"
        data-testid="delete-photon-button"
        isEditMode={isEditMode}
        onClick={onDelete}
      />
      {(!type || type === PricingEngineMediaType.IMAGE) && (
        <Image
          alt={alt || ' Photo'}
          loading="lazy"
          onClick={onClick}
          src={url}
          title={alt || ' Photo'}
        />
      )}
      {type === PricingEngineMediaType.DOCUMENT && (
        <IconImage
          alt={alt || ' Document'}
          loading="lazy"
          onClick={onClick}
          src="/assets/images/doc-file.svg"
          title={alt || ' Document'}
        />
      )}
      {type === PricingEngineMediaType.VIDEO && (
        <IconImage
          alt={alt || ' Video'}
          loading="lazy"
          onClick={onClick}
          src="/assets/images/video-file.svg"
          title={alt || ' Video'}
        />
      )}
    </>
  </Wrapper>
);
