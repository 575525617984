import { useMutation } from '@apollo/client';

import { useCallback, useEffect, useRef, useState } from 'react';

import { useAlert } from 'common/hooks/useAlert';

import { UpdatePricingEngineResponse } from 'ProjectProfile/common/responses/updatePricingEngineResponse';
import { UpdatePricingEngineRequest } from 'ProjectProfile/common/requests/updatePricingEngineRequest';
import { UPDATE_PRICING_ENGINE } from 'ProjectProfile/common/apollo/gql/updatePricingEngine';
import { useFileUpload } from 'common/hooks/FileUpload/fileUpload.hooks';
import { FILE_PRESIGN } from 'ProjectProfile/common/apollo/gql/filePresign';
import { FilePresignResponse } from 'ProjectProfile/common/responses/filePresignResponse';
import { FilePresignRequest } from 'ProjectProfile/common/requests/filePresignRequest';
import { UploadStatus } from 'common/hooks/FileUpload/fileUpload.consts';
import {
  FileDetails,
  FileState,
} from 'common/hooks/FileUpload/fileUpload.types';
import { PricingEngineMediaType } from 'common/apollo/enums/PricingEngineMediaType';
import { IMAGE_MIME_TYPES } from 'common/consts/mimeTypes';

type Props = {
  onSuccessCallback?: () => void;
  request: UpdatePricingEngineRequest;
};

export const usePricingEngineUpdate = (
  acceptedFileTypes: string[] = [...IMAGE_MIME_TYPES],
) => {
  const { onError } = useAlert();

  const [updatePricingEngine] = useMutation<
    UpdatePricingEngineResponse,
    UpdatePricingEngineRequest
  >(UPDATE_PRICING_ENGINE, {
    onError: (error) => onError(error.message),
  });

  const [presignFile] = useMutation<FilePresignResponse, FilePresignRequest>(
    FILE_PRESIGN,
    {
      onError: () => onError('Error presigning file'),
    },
  );

  const [submissionDetails, setSubmissionDetails] = useState<{
    onSuccessCallback?: () => void;
    resolve: () => void;
    values: UpdatePricingEngineRequest | undefined;
  }>({ onSuccessCallback: () => {}, resolve: () => {}, values: undefined });

  const prevUploadListRef = useRef<FileState[]>([]);

  const {
    clear,
    errors,
    fileUploadsList,
    sendFileToS3,
    storePresignedFileData,
    validateAndStoreFileData,
  } = useFileUpload({
    allowedMimeTypes: acceptedFileTypes,
    onUploadError: (error) => {
      onError(error);
    },
    onUploadSuccess: async (uploadedFiles) => {
      await finishRoomUpdate(
        uploadedFiles.filter((f) => !!f.fileDetails).map((f) => f.fileDetails!),
      );
      fileUploadsList.forEach((file) => clear(file.id));
    },
  });

  const getPresignedFileData = useCallback(
    async (contentType: string, name: string) => {
      const result = await presignFile({
        variables: {
          input: {
            contentType,
            name,
            uploadCategory: 'pricing-engine-file',
          },
        },
      });

      return result.data?.prepareFileUpload;
    },
    [presignFile],
  );

  useEffect(() => {
    if (errors.length > 0) {
      submissionDetails.resolve();
    }
  }, [errors.length, submissionDetails]);

  useEffect(() => {
    fileUploadsList.forEach((file) => {
      const previousFileState = prevUploadListRef.current.find(
        (prevFile) => prevFile.id === file.id,
      );

      if (
        file.status === UploadStatus.PresignedDataStored &&
        previousFileState?.status === UploadStatus.InitialDataStored
      ) {
        sendFileToS3(file.id);
      }
    });
  }, [fileUploadsList, sendFileToS3]);

  useEffect(() => {
    prevUploadListRef.current = fileUploadsList;
  });

  const uploadPhotosToStorage = async (files: File[]) => {
    files.forEach(async (file) => {
      const id = validateAndStoreFileData(file);

      if (!file.name || !id) return;
      const presignedFileData = await getPresignedFileData(
        file.type,
        file.name,
      );

      if (!presignedFileData) return;

      storePresignedFileData(presignedFileData, id);
    });
  };

  const handleSubmit = async ({ onSuccessCallback, request }: Props) => {
    return new Promise<void>((resolve) => {
      setSubmissionDetails({
        onSuccessCallback,
        resolve,
        values: request,
      });
    });
  };

  const finishRoomUpdate = async (assignPhotoDetails: FileDetails[]) => {
    const { onSuccessCallback, resolve, values } = submissionDetails;

    if (!values) {
      onError('No values for room!');
      return;
    }

    const assignPhotos = assignPhotoDetails.map((f) => {
      let fileType: PricingEngineMediaType;
      if (f.contentType.startsWith('image')) {
        fileType = PricingEngineMediaType.IMAGE;
      } else if (f.contentType.startsWith('video')) {
        fileType = PricingEngineMediaType.VIDEO;
      } else {
        fileType = PricingEngineMediaType.DOCUMENT;
      }

      return {
        fileName: f.originalName,
        fileType,
        fileUrl: f.fileUrl,
        fileUuid: f.fileUuid,
      };
    });

    const request = { ...values, assignPhotos, uploadPhotos: undefined };

    const response = await updatePricingEngine({
      variables: request,
    });

    resolve();

    if (response && response.data && onSuccessCallback) {
      onSuccessCallback();
    }
  };

  useEffect(() => {
    if (!submissionDetails) {
      return;
    }
    const { values } = submissionDetails;
    if (!values) {
      return;
    }

    const { uploadPhotos } = values.input;

    if (uploadPhotos && uploadPhotos.length) {
      uploadPhotosToStorage(uploadPhotos);
    } else {
      finishRoomUpdate([]);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [submissionDetails]);

  return {
    handleSubmit,
  };
};
