import { gql } from '@apollo/client';

export const UPDATE_PROJECT_STATUS = gql`
  mutation ProjectStatusUpdate($input: ProjectStatusUpdateInput!) {
    projectStatusUpdate(input: $input) {
      id
      projectStatus
      projectStatusChangeEvents {
        id
        oldValue
        newValue
        createdAt
      }
    }
  }
`;

export const GET_PROJECT_STATUS = gql`
  query ProjectStatusGet($id: ID!) {
    project(id: $id) {
      id
      projectStatus
      projectStatusChangeEvents {
        id
        oldValue
        newValue
        createdAt
      }
    }
  }
`;
