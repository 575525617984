import { MaterialsLongestLeadTimeSetModal } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeSet/MaterialsLongestLeadTimeSetModal';

import { useMaterialsLongestLeadTimeSet } from './materialsLongestLeadTimeSet.hooks';

import { GearButton } from './materialsLongestLeadTimeSet.styles';

export const MaterialsLongestLeadTimeSet = () => {
  const { handleMaterialsLongestLeadTimeSetClick } =
    useMaterialsLongestLeadTimeSet();

  return (
    <>
      <GearButton
        isDisabled={false}
        onClick={handleMaterialsLongestLeadTimeSetClick}
      >
        Set Longest Lead Time
      </GearButton>
      <MaterialsLongestLeadTimeSetModal />
    </>
  );
};
