import { ClassNames } from '@emotion/react';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
} from '@material-ui/pickers';

import { TextFieldSize } from 'common/enums/TextField';
import {
  TextField,
  Props as TextFieldProps,
} from 'common/components/Form/TextField/TextField';

import { DatePickerProvider } from './DatePickerProvider';
import { datePickerStyles } from './DatePicker.styles';

const DEFAULT_FORMAT = 'MM/dd/yyyy';
const DEFAULT_INPUT_VARIANT = 'outlined';
const DEFAULT_PLACEHOLDER = 'Select';

export type Props = Omit<DatePickerProps, 'TextFieldComponent' | 'size'> & {
  TextFieldComponent?: TextFieldProps;
  errorMessage?: string;
  isDisabled?: boolean;
  size?: TextFieldSize;
};

// TODO: Revisit TextFieldComponent after updatng material-ui
export const DatePicker = ({
  className,
  format = DEFAULT_FORMAT,
  inputRef,
  inputVariant = DEFAULT_INPUT_VARIANT,
  isDisabled,
  label,
  onChange,
  placeholder = DEFAULT_PLACEHOLDER,
  size,
  value,
  ...props
}: Props) => (
  <DatePickerProvider>
    <ClassNames>
      {({ css }) => (
        <MuiDatePicker
          {...props}
          InputProps={{
            disabled: isDisabled,
          }}
          TextFieldComponent={TextField as any}
          css={css(datePickerStyles, className)}
          disabled={isDisabled}
          emptyLabel={placeholder}
          format={format}
          inputRef={inputRef}
          inputVariant={inputVariant}
          label={label}
          onChange={onChange}
          value={value}
          {...(size && ({ size } as any))}
        />
      )}
    </ClassNames>
  </DatePickerProvider>
);
