import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
`;

export const Text = styled.p`
  font: 400 normal 16px/24px ${({ theme }) => theme.font.inter};
`;

export const EmptyValue = styled.span`
  color: ${({ theme }) => theme.color.gray11};
`;
