import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GetProjectProcurementDeliveryBatchesRequest } from 'Project/Procurement/common/apollo/requests/getProjectProcurementDeliveryBatchesRequest';
import { GetProjectProcurementDeliveryBatchesResponse } from 'Project/Procurement/common/apollo/responses/getProjectProcurementDeliveryBatchesResponse';
import { GET_PROJECT_PROCUREMENT_DELIVERY_BATCHES } from 'Project/Procurement/Warehouse/warehouse.graphql';

import {
  filterBatchesBySearch,
  normalizeProcurementDeliveryBatchesData,
} from 'Project/Procurement/Warehouse/warehouse.utils';
import { useDeliveryBatchFilters } from 'common/components/Filters/DeliveryBatchFilters/deliveryBatchFilters.hooks';
import { DeliveryBatch } from 'common/apollo/models/deliveryBatch';
import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';
import { BULK_EDIT_MODAL } from 'Project/Procurement/Warehouse/BulkEditModal/bulkEditModal.consts';
import { sendEvent } from 'common/utils/segment';
import {
  PROJECT_PROCUREMENT_WAREHOUSE_BULK_EDIT_BUTTON_CLICKED,
  PROJECT_PROCUREMENT_WAREHOUSE_FILTER_WAREHOUSE_TAB_CLICKED,
} from 'common/consts/segmentEvents';
import { useAuth } from 'common/hooks/AuthenticationProvider/authenticationProvider.hooks';
import { generateSegmentData } from 'common/components/Filters/DeliveryBatchFilters/deliveryBatchFilters.utils';
import { OrderItem } from 'common/apollo/models/orderItem';

export const useWarehouse = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { currentUser } = useAuth();

  const [selectedOrderItems, setSelectedOrderItems] = useState<string[]>([]);
  const [selectedBatches, setSelectedBatches] = useState<string[]>([]);
  const [selectedBatchObjects, setSelectedBatchObjects] = useState<
    DeliveryBatch[]
  >([]);
  const [areAllBatchesSelected, setAreAllBatchesSelected] = useState(false);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredBatches, setFilteredBatches] = useState<DeliveryBatch[][]>([]);
  const [filteredOrderItems, setFilteredOrderItems] = useState<OrderItem[]>([]);

  const setDialogOpen = useSetModalOpen();

  const {
    data,
    error,
    loading: isLoading,
  } = useQuery<
    GetProjectProcurementDeliveryBatchesResponse,
    GetProjectProcurementDeliveryBatchesRequest
  >(GET_PROJECT_PROCUREMENT_DELIVERY_BATCHES, {
    fetchPolicy: 'cache-and-network',
    variables: { projectId },
  });

  const { allFilters, clearAllFilters, handleSelectFilter, selectedFilters } =
    useDeliveryBatchFilters(data?.project.deliveryBatches || []);

  useEffect(() => {
    if (!selectedFilters.length && !searchQuery.length) return;

    sendEvent(PROJECT_PROCUREMENT_WAREHOUSE_FILTER_WAREHOUSE_TAB_CLICKED, {
      adminUserId: currentUser?.id,
      renovationPlanId: projectId,
      ...generateSegmentData(selectedFilters),
      ...(searchQuery.length > 0 && { 'Search Bar': searchQuery }),
    });
  }, [currentUser?.id, projectId, searchQuery, selectedFilters]);

  const orderItemDeliveryBatchMap = useMemo(
    () =>
      normalizeProcurementDeliveryBatchesData(
        data?.project.deliveryBatches,
        selectedFilters,
      ),
    [data, selectedFilters],
  );

  const handleToggleBatch = (batchId: string) => {
    const updatedBatches = [...selectedBatches];
    const index = updatedBatches.indexOf(batchId);
    if (index === -1) {
      updatedBatches.push(batchId);
    } else {
      updatedBatches.splice(index, 1);
    }
    setSelectedBatches(updatedBatches);
  };

  const handleToggleAllBatchesForOrderItem = (orderItemId: string) => {
    const updatedOrderItems = [...selectedOrderItems];
    const index = updatedOrderItems.indexOf(orderItemId);
    const batches = orderItemDeliveryBatchMap[orderItemId].map(
      (batch) => batch.id,
    );
    const updatedBatches = [...selectedBatches];

    if (index === -1) {
      updatedOrderItems.push(orderItemId);

      batches.forEach((batch) => {
        updatedBatches.push(batch);
      });
    } else {
      updatedOrderItems.splice(index, 1);

      batches.forEach((batch) => {
        const batchIndex = updatedBatches.indexOf(batch);
        updatedBatches.splice(batchIndex, 1);
      });
    }
    setSelectedBatches(updatedBatches);
    setSelectedOrderItems(updatedOrderItems);
  };

  const onCollapseToggle = (orderItemId: string) => {
    const hasExpandedItem = expandedItems.includes(orderItemId);
    setExpandedItems((items) =>
      hasExpandedItem
        ? items.filter((id) => id !== orderItemId)
        : items.concat(orderItemId),
    );
  };

  useEffect(() => {
    const batchesBySearch = filterBatchesBySearch(
      orderItemDeliveryBatchMap,
      searchQuery,
    );
    setFilteredBatches(batchesBySearch);

    setFilteredOrderItems(
      batchesBySearch
        .flatMap((batches) => batches)
        .map((batch) => batch.orderItem),
    );
  }, [orderItemDeliveryBatchMap, searchQuery]);

  const onExpandAll = () => {
    const updatedToggleStates = filteredBatches.map(
      (batches) => batches[0].orderItem.id,
    );
    setExpandedItems(updatedToggleStates);
  };

  const onCollapseAll = () => {
    setExpandedItems([]);
  };

  const onToggleSelectAllBatches = () => {
    if (areAllBatchesSelected) {
      setSelectedBatches([]);
      setSelectedOrderItems([]);
      return;
    }

    const allBatches = filteredBatches.flatMap((batches) =>
      batches.map((batch) => batch.id),
    );
    setSelectedBatches(allBatches);

    const allOrderItems = filteredBatches.flatMap((batches) =>
      batches.map((batch) => batch.orderItem.id),
    );

    setSelectedOrderItems(allOrderItems);
  };

  useEffect(() => {
    if (!filteredBatches.length) return;

    const allBatches = filteredBatches.flatMap((batches) => batches);
    setAreAllBatchesSelected(selectedBatches.length === allBatches.length);

    setSelectedBatchObjects(
      allBatches.filter((batch) => selectedBatches.includes(batch.id)),
    );

    const updatedSelectedOrderItems: string[] = [];
    filteredOrderItems.forEach((orderItem) => {
      const isSelected = allBatches
        .filter((batch) => batch.orderItem === orderItem)
        .every((filteredBatch) => selectedBatches.includes(filteredBatch.id));

      if (isSelected) updatedSelectedOrderItems.push(orderItem.id);
    });

    setSelectedOrderItems(updatedSelectedOrderItems);
  }, [filteredBatches, filteredOrderItems, selectedBatches]);

  const onShowBulkEditModal = () => {
    sendEvent(PROJECT_PROCUREMENT_WAREHOUSE_BULK_EDIT_BUTTON_CLICKED, {
      adminUserId: currentUser?.id,
      renovationPlanId: projectId,
    });
    setDialogOpen(BULK_EDIT_MODAL, true);
  };

  const onEditAllBatchesForOrderItem = (orderItemId: string) => {
    const batches = orderItemDeliveryBatchMap[orderItemId];
    if (!batches.length) return;

    const orderItemBatches = batches.map((batch) => batch.id);
    setSelectedBatches(orderItemBatches);
    onShowBulkEditModal();
  };

  const numberOfBatches = filteredBatches.flatMap((batches) => batches).length;

  return {
    allFilters,
    areAllBatchesSelected,
    batchesPerItem: filteredBatches,
    clearAllFilters,
    error,
    expandedItems,
    handleSelectFilter,
    handleToggleAllBatchesForOrderItem,
    handleToggleBatch,
    isLoading,
    numberOfBatches,
    onCollapseAll,
    onCollapseToggle,
    onEditAllBatchesForOrderItem,
    onExpandAll,
    onShowBulkEditModal,
    onToggleSelectAllBatches,
    orderItemDeliveryBatchMap,
    selectedBatchObjects,
    selectedBatches,
    selectedFilters,
    selectedOrderItems,
    setSearchQuery,
  };
};
