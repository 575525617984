import styled from '@emotion/styled';
import { css } from '@emotion/react';

type WrapperProps = {
  isOpen?: boolean;
};

export const datePickerStyle = css`
  width: 100%;
`;

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ theme }) => theme.color.gray2};
  border: 1px solid ${({ theme }) => theme.color.gray6};
  border-radius: ${({ isOpen }) => (isOpen ? '4px 4px 0 0' : '4px')};
  color: ${({ theme }) => theme.color.gray12};
  display: flex;
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
  justify-content: space-between;
  padding: 16px;
`;

export const InnerWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.gray2};
  border: solid ${({ theme }) => theme.color.gray6};
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 4px 4px;
`;

export const Item = styled.div`
  background-color: ${({ theme }) => theme.color.gray2};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray6};
  color: ${({ theme }) => theme.color.gray12};
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
  padding: 16px;
  margin-left: 16px;

  &:last-of-type {
    border-bottom: none;
  }
`;
