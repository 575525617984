import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;

export const Label = styled.p`
  ${({ theme }) => theme.typography.header6};
  color: ${({ theme }) => theme.color.gray11};
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.red11};
`;

export const Description = styled.p`
  ${({ theme }) => theme.typography.header6};
  color: ${({ theme }) => theme.color.gray11};
`;

export const ExtraLabel = styled.span`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.gray11};
`;
