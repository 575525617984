import { OrderMaterial } from 'Project/Procurement/common/types/orderMaterial';

export const useOrderTableAction = (materials: OrderMaterial[]) => {
  const handleCopyTable = () => {
    // TODO:: Temporary solution
    const tableHeader = [
      'Product Name',
      'SKU ID',
      'Brand',
      'Brand Line',
      'Color',
      'Finish',
      'Size',
      'Total Units',
    ].join('\t');

    const content = [
      tableHeader,
      ...materials.map(
        ({
          brandLineName,
          brandName,
          color,
          finish,
          name,
          size,
          skuId,
          totalUnits,
        }) =>
          [
            name,
            skuId,
            brandName,
            brandLineName,
            color,
            finish,
            size,
            totalUnits,
          ].join('\t'),
      ),
    ].join('\n');

    navigator.clipboard.writeText(content);
  };

  const handleCopySkuInfo = () => {
    // TODO:: Temporary solution
    const content = materials
      .filter(({ vendorSkuId }) => Boolean(vendorSkuId))
      .map(({ boxCount, vendorSkuId }) => [boxCount, vendorSkuId].join('\t'))
      .join('\n');

    navigator.clipboard.writeText(content);
  };

  return { handleCopySkuInfo, handleCopyTable };
};
