import { createContext, ReactNode, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { ProposalStatus } from 'common/apollo/enums/ProposalStatus';

import { ProposalType } from '__generated/apolloTypes';

import { PricingEngineContextType } from './pricingEngine.types';

import {
  usePricingEngineFetch,
  usePricingEngineUnitsFetch,
} from './pricingEngine.hooks';

export const PricingEngineContext = createContext<PricingEngineContextType>({
  isDesignProposal: false,
  isLoading: false,
  isPreviewMode: false,
  isSalesProposal: false,
  units: {
    laborUnits: [],
    productSkuProcuredBys: [],
    productSkuStatuses: [],
    productSkuUnits: [],
    verifiedStates: [],
    vifStages: [],
  },
});

type Props = {
  children: ReactNode;
};

export const PricingEngineProvider = ({ children }: Props) => {
  const { pricingEngineId } = useParams<{ pricingEngineId: string }>();

  const { error, isLoading, pricingEngine } =
    usePricingEngineFetch(pricingEngineId);

  const {
    laborUnits,
    productSkuProcuredBys,
    productSkuStatuses,
    productSkuUnits,
    verifiedStates,
    vifStages,
  } = usePricingEngineUnitsFetch();

  const proposalStatus = pricingEngine?.proposal.status;

  const isPreviewMode =
    proposalStatus === ProposalStatus.PUBLISHED ||
    proposalStatus === ProposalStatus.ACCEPTED ||
    proposalStatus === ProposalStatus.PROPOSED;

  const isDesignProposal = pricingEngine?.proposal.type === ProposalType.DESIGN;
  const isSalesProposal = pricingEngine?.proposal.type === ProposalType.SALES;

  const value = useMemo(
    () => ({
      error,
      isDesignProposal,
      isLoading,
      isPreviewMode,
      isSalesProposal,
      pricingEngine,
      units: {
        laborUnits,
        productSkuProcuredBys,
        productSkuStatuses,
        productSkuUnits,
        verifiedStates,
        vifStages,
      },
    }),
    [
      error,
      isDesignProposal,
      isLoading,
      isPreviewMode,
      isSalesProposal,
      laborUnits,
      pricingEngine,
      productSkuProcuredBys,
      productSkuStatuses,
      productSkuUnits,
      verifiedStates,
      vifStages,
    ],
  );

  return (
    <PricingEngineContext.Provider value={value}>
      {children}
    </PricingEngineContext.Provider>
  );
};
