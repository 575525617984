import { Order } from 'common/apollo/models/order';

import { validShippedShipmentStates } from 'Project/Procurement/common/consts/order.consts';

export const normalizeProcurementOrdersData = (orders: Order[] = []) =>
  orders.map(
    ({
      blockRequestedDeliveryDate,
      id,
      items,
      number,
      requestedDeliverTo,
      shipments,
      status,
      timing,
    }) => ({
      blockRequestedDeliveryDate,
      hasOrderConflicts: items.some((item) => !!item.revisionActionItem),
      id,
      number,
      requestedDeliverTo,
      shipments: shipments.filter((shipment) =>
        validShippedShipmentStates.includes(shipment.status),
      ).length,
      status,
      timing,
      vendor: items[0].skuVendor.vendor.name || 'TBD',
    }),
  );
