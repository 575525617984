import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { THEME } from 'common/consts/theme';

export const titleStyles = css`
  color: ${THEME.color.grayWithOpacity};
  font: 500 14px ${THEME.font.roboto};
  line-height: 1.71;
`;

export const Trigger = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.color.darkGray};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0;
  width: 100%;
`;
