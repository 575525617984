import { MultiCheckbox } from 'common/shared/Question/Checkbox/MultiCheckbox/MultiCheckbox';
import { SingleCheckbox } from 'common/shared/Question/Checkbox/SingleCheckbox/SingleCheckbox';

import { Option } from 'common/shared/Question/question.types';

export type Props = {
  id: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  isSingleSelect?: boolean;
  label: string;
  name: string;
  onChange?: (value: string | string[]) => void;
  options: Option[];
};

export const Checkbox = ({
  isSingleSelect = false,
  options,
  ...props
}: Props) =>
  isSingleSelect ? (
    <SingleCheckbox {...props} option={options?.[0]} />
  ) : (
    <MultiCheckbox {...props} options={options} />
  );
