import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { addDays, differenceInCalendarDays } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FeatureFlags } from 'common/enums/FeatureFlags';
import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';
import { useAlert } from 'common/hooks/useAlert';
import { TargetDateSetResponse } from 'common/apollo/responses/targetDateSetResponse';
import { TargetDateSetRequest } from 'common/apollo/requests/targetDateSetRequest';

import { SET_TARGET_DATE_MODAL } from 'ClientTasks/TargetDate/SetTargetDateModal/setTargetDateModal.consts';
import { TARGET_DATE_SET } from 'ClientTasks/TargetDate/SetTargetDateModal/setTargetDateModal.graphql';
import { getModalId } from 'ClientTasks/TargetDate/common/targetDateModal.utils';
import { useGetKeyDateChangeEvents } from 'ClientTasks/TargetDateHistoryModal/targetDateHistoryModal.hooks';
import { StageIdentifier } from 'common/apollo/enums/StageIdentifier';
import { stageNamesMap } from 'ClientTasks/TargetDate/targetDate.consts';
import { useTargetDate } from 'ClientTasks/TargetDate/targetDate.hooks';

export const useSetTargetDateModal = (stageIdentifier: StageIdentifier) => {
  const { projectId } = useParams<{ projectId: string }>();
  const setDialogOpen = useSetModalOpen();
  const { onError } = useAlert();
  const { keyDateChangeEventRefetch } =
    useGetKeyDateChangeEvents(stageIdentifier);
  const { refetchKeyDates } = useTargetDate(stageIdentifier);

  const flags = useFlags();
  const breakGroundMinimumDaysFlag =
    flags[FeatureFlags.BREAK_GROUND_KEY_DATE_VALIDATION_MINIMUM_DAYS_FROM_NOW];

  const validateBreakGroundMinimumDays =
    breakGroundMinimumDaysFlag === 150 &&
    stageIdentifier === StageIdentifier.PERMITS;

  const [targetDate, setTargetDate] = useState<Date | null>(null);
  const [targetDays, setTargetDays] = useState<number | null>(null);
  const [note, setNote] = useState<string | null>(null);
  const modalId = getModalId(SET_TARGET_DATE_MODAL, stageIdentifier);

  const [targetDateSet] = useMutation<
    TargetDateSetResponse,
    TargetDateSetRequest
  >(TARGET_DATE_SET, {
    onCompleted: () => {
      setDialogOpen(modalId, false);
      keyDateChangeEventRefetch();
      refetchKeyDates();
    },
    onError: (error: ApolloError) => onError(error.message),
  });

  const handleSave = async () => {
    if (!targetDate) return;

    if (validateBreakGroundMinimumDays && (!targetDays || targetDays < 150)) {
      onError('Break ground target date should be set at least 150 days out.');
      return;
    }

    targetDate.setHours(0, 0, 0, 0);

    const input = {
      note,
      projectId,
      // FIXME: potential type issue, need to be fixed, disable to proceed with the nodejs upgrade
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      type: stageNamesMap[stageIdentifier],
      value: targetDate,
    };

    await targetDateSet({
      variables: {
        input,
      },
    });
  };

  const handleSetDays = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const date = addDays(new Date(), +value);
    setTargetDate(date);
    setTargetDays(+value);
  };

  const handleSetDate = (date: MaterialUiPickersDate) => {
    if (!date) return;
    const days = differenceInCalendarDays(new Date(date), new Date());
    setTargetDays(days);
    setTargetDate(date);
  };

  const handleSetNote = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setNote(value);
  };

  const handleCloseDialog = () => {
    setDialogOpen(modalId, false);
  };

  const isValid = !!targetDate;

  return {
    handleCloseDialog,
    handleSave,
    handleSetDate,
    handleSetDays,
    handleSetNote,
    isValid,
    note,
    projectId,
    targetDate,
    targetDays,
    validateBreakGroundMinimumDays,
  };
};
