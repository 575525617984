import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';
import { ProjectPlanRoomQuestion } from 'common/apollo/models/projectPlanRoomQuestion';
import { ProjectPlanRoom } from 'common/apollo/models/projectPlanRoom';
import { roomTypeNotes } from 'ProjectProfile/common/components/DesignerNotes/DesignerNotesEdit/designerNotesEdit.consts';

export const mapProjectPlanRoomsAndQuestions = (
  rooms: ProjectPlanRoom[],
  roomsQuestions: ProjectPlanRoomQuestion[],
) => {
  if (roomsQuestions.length === rooms.length) return roomsQuestions;

  const findRoom = (roomId: string) =>
    roomsQuestions.find(({ room }) => room.id === roomId);

  return rooms.map((room, index) => ({
    answers: [],
    id: `${index}`,
    identifier: PropertyGroupIdentifier.DESIGNER_NOTES,
    label: '',
    responseText:
      roomTypeNotes[room.roomType as keyof typeof roomTypeNotes] || 'N/A',
    room,
    title: null,
    ...findRoom(room.id),
  }));
};
