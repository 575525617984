import { useState, useEffect } from 'react';
import * as React from 'react';

import { TypographyProps } from '@material-ui/core/Typography';

import { Typography } from 'common/components/Typography/Typography';
import { TypographyCustomType } from 'common/components/Typography/typographyCustom.types';
import { TextField } from 'common/components/Form/TextField/TextField';
import { ENTER_KEY, ESC_KEY } from 'common/consts/keyboard';

import { ReactComponent as EditIcon } from 'common/assets/icons/edit.svg';

import {
  EditableTypographyWrapper,
  iconButtonStyles,
  textFieldStyles,
  IconWrapper,
} from 'common/components/Typography/EditableTypography/EditableTypography.styles';

type Props = {
  lineBreak?: boolean;
  maxWidth?: number;
  onSave: (value: string) => void;
  text: string;
  type?: TypographyProps['variant'] | TypographyCustomType;
};

export const EditableTypography = ({
  lineBreak,
  maxWidth,
  onSave,
  text,
  type,
}: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [value, setValue] = useState(text);

  const handleSave = () => {
    setIsEditMode(false);

    if (text !== value) {
      onSave(value);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.keyCode === ESC_KEY) {
      setIsEditMode(false);
      setValue(text);
    }

    if (event.keyCode === ENTER_KEY) {
      handleSave();
    }
  };

  useEffect(() => {
    if (!isEditMode) return;
    setValue(text);
  }, [isEditMode, text]);

  if (isEditMode) {
    return (
      <TextField
        autoFocus
        css={textFieldStyles}
        onBlur={handleSave}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        value={value}
      />
    );
  }

  return (
    <EditableTypographyWrapper>
      <Typography
        style={{
          maxWidth: `${maxWidth}px`,
          overflow: lineBreak ? 'hidden' : 'unset',
          overflowWrap: 'break-word',
          textOverflow: lineBreak ? 'ellipsis' : 'unset',
          whiteSpace: lineBreak ? 'nowrap' : 'normal',
        }}
        type={type}
      >
        {text}
      </Typography>
      <IconWrapper>
        <EditIcon
          aria-label="edit"
          css={iconButtonStyles}
          onClick={() => setIsEditMode(true)}
        />
      </IconWrapper>
    </EditableTypographyWrapper>
  );
};
