import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { mq } from 'common/utils/mediaQueries';

export const Content = styled.div<{ isEmpty: boolean }>`
  white-space: pre-wrap;

  ${({ isEmpty, theme }) =>
    isEmpty
      ? css`
          color: ${theme.color.gray11};
        `
      : ''};
`;

export const IconWrapper = styled.div`
  align-self: flex-end;

  ${mq.large(css`
    position: absolute;
    right: -55px;
    top: -10px;
  `)}
`;

export const Root = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;

  ${mq.large(css`
    flex-direction: row;
  `)}

  svg {
    transition: all 0.3s ease;
  }

  &:hover {
    .MuiIconButton-root svg {
      fill: ${({ theme }) => theme.color.black};
      transform: scale(1.1);
    }
  }
`;

export const Title = styled.div`
  font: 600 14px/18px ${({ theme }) => theme.font.inter};
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  ${mq.large(css`
    align-items: center;
    flex-direction: row;
  `)}
  margin-bottom: 10px;
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 10px;
  padding: 20px;
  width: 100%;

  ${mq.large(css`
    padding: 30px;
  `)}
`;

export const FlagWrapper = styled.div`
  position: absolute;
  top: -2px;

  right: -6px;

  ${mq.large(css`
    right: 0px;
  `)}
`;
