import { ChangeEvent } from 'react';

import { Field, FieldProps } from 'formik';

import { Checkbox, Props as CheckboxProps } from './Checkbox';

type Props = CheckboxProps & {
  label?: string;
  name: string;
};

const CheckboxWrapper = ({
  field,
  form: { setFieldValue },
  label,
  onChange,
  ...props
}: FieldProps & Props) => {
  const handleChange = (
    event: ChangeEvent<any> | ChangeEvent<HTMLInputElement>,
    value: boolean,
  ) => {
    setFieldValue(field.name, value);

    if (onChange) onChange(event, value);
  };

  return (
    <Checkbox
      {...props}
      checked={field.value}
      label={label}
      name={field.name}
      onChange={handleChange}
    />
  );
};

export const FormCheckbox = ({ ...props }: Props) => (
  <Field component={CheckboxWrapper} {...props} />
);
