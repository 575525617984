import styled from '@emotion/styled';
import { css } from '@emotion/react';

type CellProps = {
  cellWidth: string;
  isEmpty?: string;
  isSticky?: boolean;
  zIndex?: string;
};

export const HeaderCell = styled.div<CellProps>`
  color: ${({ theme }) => theme.color.gray12};
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
  padding: 0 16px;
  width: ${({ cellWidth }) => `${cellWidth}`};
  z-index: ${({ isSticky, zIndex }) => (isSticky && zIndex ? `${zIndex}` : '')};
  min-height: 20px;
`;

export const Row = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.white};
  border-color: ${({ theme }) => theme.color.gray7};
  border-style: solid;
  border-width: 1px 0;
  display: flex;
  justify-content: flex-start;
  padding: 8px 0;
`;

export const stickyColumnStyle = css`
  background: inherit;
  position: sticky;

  &:nth-of-type(1) {
    left: 0;
  }
  &:nth-of-type(2) {
    left: 64px;
  }
  &:nth-of-type(3) {
    left: 128px;
  }
`;
