import { GearButton } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeUpdate/materialsLongestLeadTimeUpdate.styles';
import { useMaterialsLongestLeadTimeConfirm } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeConfirm/materialsLongestLeadTimeConfirm.hooks';
import { MaterialsLongestLeadTimeConfirmModal } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeConfirm/MaterialsLongestLeadTimeConfirmModal';

export const MaterialsLongestLeadTimeConfirm = () => {
  const { handleMaterialsLongestLeadTimeConfirmClick } =
    useMaterialsLongestLeadTimeConfirm();

  return (
    <>
      <GearButton
        isDisabled={false}
        onClick={handleMaterialsLongestLeadTimeConfirmClick}
      >
        Confirm Longest Lead Time
      </GearButton>
      <MaterialsLongestLeadTimeConfirmModal />
    </>
  );
};
