const getWorkOrderPath = (workOrderId = ':workOrderId') =>
  `/work-orders/${workOrderId}`;

const getIssueWorkOrderPath = (projectId = ':projectId') =>
  `/issue-work-order/${projectId}`;

const getUpdateWorkOrderPath = (
  projectId = ':projectId',
  workOrderId = ':workOrderId',
) => `/projects/${projectId}/update-work-order/${workOrderId}`;

const getContractorRematchWorkOrderPath = (projectId = ':projectId') =>
  `/contractor-rematch-work-order/${projectId}`;

export const WORK_ORDER_PATHS = {
  getContractorRematchWorkOrderPath,
  getIssueWorkOrderPath,
  getUpdateWorkOrderPath,
  getWorkOrderPath,
};
