import { gql } from '@apollo/client';

export const GET_PROJECT_PROCUREMENT_VENDORS = gql`
  query GetProjectVendors {
    vendors {
      id
      name
    }
  }
`;
