import { Link } from 'react-router-dom';

import { dateToUSFormat } from 'common/utils/date';

import { OrderDeliverTo } from 'common/apollo/enums/OrderDeliverTo';
import { OrderStatus } from 'common/apollo/enums/OrderStatus';
import { OrderTiming } from 'common/apollo/enums/OrderTiming';

import { AUTH_PATHS } from 'common/routes/routerPaths';

import { Badge } from 'common/components/Badge/Badge';

import {
  Content,
  Footer,
  OrderNumber,
  OuterWrapper,
  Section,
  SectionWrapper,
  StatusWrapper,
  Title,
  Wrapper,
} from 'Project/Procurement/Orders/Order/order.styles';

import {
  ORDER_DELIVER_TO_DICTIONARY,
  ORDER_STATUS_COLOR_VARIANT,
  ORDER_STATUS_DICTIONARY,
  ORDER_TIMING_DICTIONARY,
} from 'Project/Procurement/common/consts/order.consts';
import { TextDisplay } from 'common/components/TextDisplay/TextDisplay';
import { OrderConflictWarning } from 'Project/Procurement/common/components/OrderConflictWarning/OrderConflictWarning';

type Props = {
  blockRequestedDeliveryDate: string;
  hasOrderConflicts: boolean;
  id: string;
  number: string;
  projectId: string;
  requestedDeliverTo: OrderDeliverTo;
  shipments: number;
  status: OrderStatus;
  timing: OrderTiming;
  vendor: string;
};

export const Order = ({
  blockRequestedDeliveryDate,
  hasOrderConflicts,
  id,
  number,
  projectId,
  requestedDeliverTo,
  shipments,
  status,
  timing,
  vendor,
}: Props) => (
  <OuterWrapper hasOrderConflicts={hasOrderConflicts}>
    <Wrapper hasOrderConflicts={hasOrderConflicts}>
      <Content>
        <Section>
          <SectionWrapper>
            <Title>{vendor}</Title>
            <OrderNumber>{number}</OrderNumber>
          </SectionWrapper>

          <StatusWrapper>
            <Badge variant={ORDER_STATUS_COLOR_VARIANT[status]}>
              {ORDER_STATUS_DICTIONARY[status]}
            </Badge>
          </StatusWrapper>
        </Section>

        <Section>
          <TextDisplay
            label="Order Timing"
            value={ORDER_TIMING_DICTIONARY[timing]}
          />

          <TextDisplay
            label="Destination"
            value={ORDER_DELIVER_TO_DICTIONARY[requestedDeliverTo]}
          />
        </Section>

        <Section>
          <TextDisplay label="# of Shipments" value={String(shipments)} />

          <TextDisplay
            label="BL REQ DEL Date"
            value={dateToUSFormat(blockRequestedDeliveryDate)}
          />
        </Section>
      </Content>

      <Footer>
        <Link to={AUTH_PATHS.getProjectProcurementEditOrderPath(projectId, id)}>
          View Order
        </Link>
      </Footer>
    </Wrapper>

    {hasOrderConflicts && <OrderConflictWarning />}
  </OuterWrapper>
);
