import Grid from '@material-ui/core/Grid';

import { PropertyInfo } from 'common/apollo/models/projectBuildingInfo';

import { Container } from 'ProjectProfile/common/components/Container/Container';
import { Section } from 'ProjectProfile/common/components/Section/Section';

import { EmptyCopy } from 'ProjectProfile/projectProfile.consts';

import { OutlinedLink } from 'ProjectProfile/common/components/Link/Link';

type Props = {
  isLoading: boolean;
  propertyData: PropertyInfo;
  retoolUrl: string;
  roles: any[];
};

export const PropertyData = ({
  isLoading,
  propertyData: {
    bathFixtures,
    bathsPartial,
    bathsTotal,
    beds,
    floors,
    levels,
    livingSize,
    unitsCount,
    yearBuilt,
  },
  retoolUrl,
  roles,
}: Props) => (
  <Container
    isLoading={isLoading}
    roles={roles}
    title="Additional Property data"
  >
    <Grid container spacing={4}>
      <Grid item md={6} sm={12}>
        <Section
          emptyValue={EmptyCopy.OPTIONAL_FIELD}
          title="Number of bedrooms"
        >
          {beds}
        </Section>
      </Grid>
      <Grid item md={6} sm={12}>
        <Section
          emptyValue={EmptyCopy.OPTIONAL_FIELD}
          title="Living square footage"
        >
          {livingSize}
        </Section>
      </Grid>

      <Grid item md={6} sm={12}>
        <Section
          emptyValue={EmptyCopy.OPTIONAL_FIELD}
          title="Number of partial bathrooms"
        >
          {bathsPartial}
        </Section>
      </Grid>
      <Grid item md={6} sm={12}>
        <Section
          emptyValue={EmptyCopy.OPTIONAL_FIELD}
          title="Number of bathrooms"
        >
          {bathsTotal}
        </Section>
      </Grid>
      <Grid item md={6} sm={12}>
        <Section
          emptyValue={EmptyCopy.OPTIONAL_FIELD}
          title="Number of Bathroom fixtures"
        >
          {bathFixtures}
        </Section>
      </Grid>
      <Grid item md={6} sm={12}>
        <Section
          emptyValue={EmptyCopy.OPTIONAL_FIELD}
          title="Number of stories"
        >
          {floors}
        </Section>
      </Grid>
      <Grid item md={6} sm={12}>
        <Section
          emptyValue={EmptyCopy.OPTIONAL_FIELD}
          title="Number of units in building"
        >
          {unitsCount}
        </Section>
      </Grid>
      <Grid item md={6} sm={12}>
        <Section emptyValue={EmptyCopy.OPTIONAL_FIELD} title="Year built">
          {yearBuilt}
        </Section>
      </Grid>
      <Grid item md={6} sm={12}>
        <Section emptyValue={EmptyCopy.OPTIONAL_FIELD} title="Levels">
          {levels}
        </Section>
      </Grid>
      <Grid item md={4} sm={12}>
        <OutlinedLink color="primary" href={retoolUrl} target="_blank">
          Home Valuation Retool
        </OutlinedLink>
      </Grid>
    </Grid>
  </Container>
);
