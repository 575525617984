import styled from '@emotion/styled';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
`;

export const DescriptionWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const Description = styled.p`
  font: 400 14px/24px ${({ theme }) => theme.font.inter};
  white-space: nowrap;
`;

export const Action = styled.button`
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.color.mariner};
  cursor: pointer;
  font: 400 14px/20px ${({ theme }) => theme.font.inter};
  outline: 0;
  padding: 0;
  text-align: left;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;

Action.defaultProps = { type: 'button' };
