import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mq } from 'common/utils/mediaQueries';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.white};
  display: grid;
  grid-auto-flow: row;
  grid-gap: 24px;
  height: 100%;
  max-width: 520px;
  min-height: 412px;
  padding: 32px;

  ${mq.small(css`
    min-width: 500px;
  `)};
`;

export const Header = styled.div`
  align-items: center;
  display: grid;
  font: 500 20px/28px ${({ theme }) => theme.font.inter};
  grid-template-columns: 1fr 20px;
  position: relative;
`;

export const SubHeader = styled.div``;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.color.secondaryGray};
  font: 400 14px/20px ${({ theme }) => theme.font.inter};
`;

export const Content = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
  position: relative;

  > div:first-of-type {
    grid-area: 1/1/-1/-1;
  }

  > div:last-of-type {
    grid-area: 3/1/4/-1;
  }
`;

export const Footer = styled.div`
  column-gap: 16px;
  display: flex;
  justify-content: flex-end;
`;

export const ClearSelectionButton = styled.button`
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.color.primaryOrange};
  cursor: pointer;
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
  position: absolute;
  right: 0;
  top: -25px;
`;
