import { useParams } from 'react-router-dom';

import { useProjectPlanRooms } from 'ProjectProfile/common/hooks/useProjectPlanRooms';
import { useProposalsFetch } from 'Proposals/proposals.hooks';
import { ProposalType } from 'common/apollo/enums/ProposalType';

export const useLooks = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const { data, isLoading } = useProjectPlanRooms({
    projectId,
  });

  return {
    data,
    isLoading,
  };
};

export const useHomeownerLooks = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const { isLoading, proposals } = useProposalsFetch(projectId);
  const looksProposal = proposals.find(
    (proposal) => proposal.type === ProposalType.LOOKS,
  );

  return {
    isLoading,
    roomsWithHomeownerLooks: looksProposal?.pricingEngines || [],
  };
};
