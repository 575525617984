import styled from '@emotion/styled';

import MuiModal from '@material-ui/core/Modal';

export const Wrapper = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrapperInner = styled.div`
  max-height: 100vh;
  max-width: 100%;
`;
