import { MODAL_QUERY } from 'common/consts/modal';

export type PricingEngineRouterParams = {
  pricingEngineId: string;
  proposalId: string;
};

const getPricingEnginePath = ({
  pricingEngineId = ':pricingEngineId',
  proposalId = ':proposalId',
} = {}) => `/proposals/${proposalId}/pricing-engine/${pricingEngineId}`;

const getPricingEngineEntryPath = (proposalId = ':proposalId') =>
  `/proposals/${proposalId}/pricing-engine/entry`;

const getPricingEngineRendersPath = ({
  pricingEngineId = ':pricingEngineId',
  proposalId = ':proposalId',
} = {}) => `/proposals/${proposalId}/pricing-engine/${pricingEngineId}/renders`;

const getPricingEngineHistoryPath = ({
  pricingEngineId = ':pricingEngineId',
  proposalId = ':proposalId',
} = {}) => `/proposals/${proposalId}/pricing-engine/${pricingEngineId}/history`;

const getPricingEngineHistoryItemPath = ({
  historyItemId = ':historyItemId',
  pricingEngineId = ':pricingEngineId',
  proposalId = ':proposalId',
} = {}) =>
  `/proposals/${proposalId}/pricing-engine/${pricingEngineId}/history?historyItemId=${historyItemId}`;

const getPricingEngineAddRoomPath = (proposalId = ':proposalId') =>
  `/proposals/${proposalId}/pricing-engine/add-room`;

const getPricingEngineRoomInfoPath = ({
  pricingEngineId = ':pricingEngineId',
  proposalId = ':proposalId',
} = {}) =>
  `/proposals/${proposalId}/pricing-engine/${pricingEngineId}/room-info-V1`;

const getPricingEngineLaborMaterialsPath = ({
  pricingEngineId = ':pricingEngineId',
  proposalId = ':proposalId',
} = {}) =>
  `/proposals/${proposalId}/pricing-engine/${pricingEngineId}/labor-materials`;

const getPricingEngineLaborSummaryPath = ({
  pricingEngineId = ':pricingEngineId',
  proposalId = ':proposalId',
} = {}) =>
  `/proposals/${proposalId}/pricing-engine/${pricingEngineId}/labor-summary`;

const getPricingEngineMaterialsSummaryPath = ({
  pricingEngineId = ':pricingEngineId',
  proposalId = ':proposalId',
} = {}) =>
  `/proposals/${proposalId}/pricing-engine/${pricingEngineId}/materials-summary`;

const getPricingEngineEstimateSummaryPath = ({
  pricingEngineId = ':pricingEngineId',
  proposalId = ':proposalId',
} = {}) =>
  `/proposals/${proposalId}/pricing-engine/${pricingEngineId}/estimate-summary`;

const getMaterialsSelectionPath = ({
  categoryId = ':categoryId',
  pricingEngineId = ':pricingEngineId',
  proposalId = ':proposalId',
} = {}) =>
  `/proposals/${proposalId}/pricing-engine/${pricingEngineId}/labor-materials?${MODAL_QUERY}=materialsSelection&categoryId=${categoryId}`;

const getLaborSelectionPath = ({
  categoryId = ':categoryId',
  pricingEngineId = ':pricingEngineId',
  proposalId = ':proposalId',
  roomType = ':roomType',
} = {}) =>
  `/proposals/${proposalId}/pricing-engine/${pricingEngineId}/labor-materials?${MODAL_QUERY}=linesOfWorkSelection&categoryId=${categoryId}&roomType=${roomType}`;

const getPricingEnginePhotosPath = ({
  pricingEngineId = ':pricingEngineId',
  proposalId = ':proposalId',
} = {}) =>
  `/proposals/${proposalId}/pricing-engine/${pricingEngineId}/room-photos`;

const getPricingEngineSiteSurveyPath = ({
  pricingEngineId = ':pricingEngineId',
  proposalId = ':proposalId',
} = {}) =>
  `/proposals/${proposalId}/pricing-engine/${pricingEngineId}/site-survey`;

const getPricingEngineMeasurementsPath = ({
  pricingEngineId = ':pricingEngineId',
  proposalId = ':proposalId',
} = {}) =>
  `/proposals/${proposalId}/pricing-engine/${pricingEngineId}/room-measurements`;

export const PRICING_ENGINE_PATHS = {
  getLaborSelectionPath,
  getMaterialsSelectionPath,
  getPricingEngineAddRoomPath,
  getPricingEngineEntryPath,
  getPricingEngineEstimateSummaryPath,
  getPricingEngineHistoryItemPath,
  getPricingEngineHistoryPath,
  getPricingEngineLaborMaterialsPath,
  getPricingEngineLaborSummaryPath,
  getPricingEngineMaterialsSummaryPath,
  getPricingEngineMeasurementsPath,
  getPricingEnginePath,
  getPricingEnginePhotosPath,
  getPricingEngineRendersPath,
  getPricingEngineRoomInfoPath,
  getPricingEngineSiteSurveyPath,
};
