import { gql } from '@apollo/client';

export const UPDATE_PROJECT_PLAN_ROOMS_QUESTIONS = gql`
  mutation UpdateProjectPlanRoomsQuestions(
    $input: ProjectPlanRoomsQuestionsUpdateInput!
  ) {
    projectPlanRoomsQuestionsUpdate(input: $input) {
      id
      label
      room {
        id
        name
        roomType
      }
      identifier
      answers {
        id
        label
        isSelected
        type
      }
      responseText
    }
  }
`;
