import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Typography } from 'common/components/Typography/Typography';
import { StyledButton } from 'common/components/Buttons/Button/Button.styles';
import { mq } from 'common/utils/mediaQueries';

export const Wrapper = styled.div<{ isEmpty?: boolean }>`
  background-color: ${({ isEmpty, theme }) =>
    isEmpty ? theme.color.cardboard1 : theme.color.white};
  border-radius: 8px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23C6C3C3FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  padding: 12px;
  position: relative;
`;

export const InnerWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;

  ${mq.large(css`
    gap: 10px;

    & > div:only-of-type {
      width: 100%;
    }
  `)};
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${mq.large(css`
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  `)};
`;

export const UploaderWrapper = styled.div`
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  gap: 16px;
  height: 80px;
  justify-content: center;
  width: 80px;
`;

export const UploadText = styled(Typography)`
  color: ${({ theme }) => theme.color.darkGray};
`;

export const MobileUploadButtonWrapper = styled.div`
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 20px;
  width: 100%;
`;

export const MobileActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PhotoInfoButton = styled(StyledButton.withComponent('a'))`
  grid-area: photo-action;

  && {
    svg {
      circle {
        fill: transparent;
      }
    }
  }
`;

export const UploadProgress = styled.div<{ progress?: number }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 4px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  ${({ progress }) =>
    progress
      ? `
    width: ${progress}%;
  `
      : `
    display: none;
  `}
`;
