import { THEME } from 'common/consts/theme';

export interface IRoleLabelColors {
  backgroundColor: string;
  borderColor: string;
  color: string;
}

export enum RoleLabelType {
  DESIGNER,
  DESIGN_OPS,
  PROJECT_PLANNER,
}

export const RoleLabelString = new Map<RoleLabelType, string>([
  [RoleLabelType.DESIGNER, 'Designer'],
  [RoleLabelType.DESIGN_OPS, 'Design Ops'],
  [RoleLabelType.PROJECT_PLANNER, 'Project Planner'],
]);

export const RoleLabelColors = new Map<RoleLabelType, IRoleLabelColors>([
  [
    RoleLabelType.DESIGNER,
    {
      backgroundColor: THEME.color.purple2,
      borderColor: THEME.color.purple5,
      color: THEME.color.purple11,
    },
  ],
  [
    RoleLabelType.DESIGN_OPS,
    {
      backgroundColor: THEME.color.blue2,
      borderColor: THEME.color.blue5,
      color: THEME.color.blue11,
    },
  ],
  [
    RoleLabelType.PROJECT_PLANNER,
    {
      backgroundColor: THEME.color.pink2,
      borderColor: THEME.color.pink5,
      color: THEME.color.pink11,
    },
  ],
]);
