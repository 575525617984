import { gql } from '@apollo/client';

export const GET_PROJECT_PLAN_ROOMS = gql`
  query GetProjectPlanRooms($input: ProjectPlanRoomsInput!) {
    projectPlanRooms(input: $input) {
      id
      name
      roomType
      selectedLook {
        addedBy
        look {
          id
          name
          images {
            file {
              url
            }
          }
          collection {
            name
            tags {
              tag {
                displayName
              }
            }
          }
          properties {
            id
            label
          }
        }
      }
    }
  }
`;
