import {
  KeyboardDateTimePickerProps,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

import { DateTimePickerProvider } from './DateTimePickerProvider';

export type Props = KeyboardDateTimePickerProps;

export const DateTimePicker = ({ ...props }: Props) => (
  <DateTimePickerProvider>
    <KeyboardDateTimePicker {...props} />
  </DateTimePickerProvider>
);
