export enum FilterableSkuAttribute {
  BRAND,
  BRAND_LINE,
  CLIENT_COST,
  COLOR,
  DEPTH,
  FINISH,
  HEIGHT,
  PATTERN,
  STOCK_STATUS,
  TAG,
  WIDTH,
  VENDOR,
  LENGTH,
  THICKNESS,
}
