import styled from '@emotion/styled';
import { css } from '@emotion/react';

import MaterialButton from '@material-ui/core/Button';
import { Add } from '@material-ui/icons';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

import { skipForwardProps } from 'common/utils/styles';
import { SPINNER_SIZE, TYPOGRAPHY } from 'common/consts/theme';

type Props = {
  isLoading?: boolean;
  small?: boolean;
  uppercase?: boolean;
};

export const StyledButton = styled(MaterialButton, {
  shouldForwardProp: skipForwardProps(['isLoading', 'small']),
})<Props>`
  ${({ isLoading, small, uppercase }) => css`
    && {
      font-size: ${small
        ? TYPOGRAPHY.buttonSmallFontSize
        : TYPOGRAPHY.buttonLargeFontSize};
      padding: ${small ? '5px 15px' : '10px 15px'};
      word-break: keep-all;
      text-transform: ${uppercase ? 'uppercase' : 'none'};

      .MuiButton-label {
        color: ${isLoading ? 'transparent' : 'inherit'};
      }
    }
  `}
`;

export const SpinnerContainer = styled.div<{ small?: boolean }>`
  ${({ small, theme }) => css`
    color: ${theme.color.lightGray};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(
      -${(small ? SPINNER_SIZE.small : SPINNER_SIZE.large) / 2}px,
      -${(small ? SPINNER_SIZE.small : SPINNER_SIZE.large) / 2}px
    );
  `}
`;

type AddIconProps = SvgIconProps & {
  iconSize?: 'small' | 'large';
  small?: boolean;
};

export const AddIcon = styled(Add, {
  shouldForwardProp: skipForwardProps(['iconSize', 'small']),
})<AddIconProps>`
  ${({ iconSize, small, theme }) => css`
    color: ${theme.color.orange11} && {
      margin-right: 4px;
      font-size: ${small ? '12px' : iconSize === 'small' ? '12px' : '24px'};
    }
  `}
`;
