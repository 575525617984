import { useCallback } from 'react';
import { OptionsObject, useSnackbar } from 'notistack';

import { Alert } from 'common/consts/alerts';

export const useAlert = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showAlert = useCallback(
    (message: string, options?: OptionsObject) =>
      enqueueSnackbar(message, {
        preventDuplicate: true,
        ...options,
      }),
    [enqueueSnackbar],
  );

  const onCompleted = useCallback(
    (message: string) => showAlert(message, { variant: Alert.SUCCESS }),
    [showAlert],
  );

  const onError = useCallback(
    (message: string) =>
      showAlert(message, {
        style: { whiteSpace: 'pre-wrap' },
        variant: Alert.ERROR,
      }),
    [showAlert],
  );

  const onWarning = useCallback(
    (message: string) => showAlert(message, { variant: Alert.WARNING }),
    [showAlert],
  );

  const onDisplay = useCallback(
    (message: string, options?: OptionsObject) => showAlert(message, options),
    [showAlert],
  );

  return {
    onCompleted,
    onDisplay,
    onError,
    onWarning,
  };
};
