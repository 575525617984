import { useMutation } from '@apollo/client';

import { useParams } from 'react-router-dom';

import { useAlert } from 'common/hooks/useAlert';

import { UpdateProjectPlanQuestionsResponse } from 'ProjectProfile/common/responses/updateProjectPlanQuestionsResponse';
import { UpdateProjectPlanLinksRequest } from 'ProjectProfile/common/requests/updateProjectPlanLinksRequest';

import { UPDATE_PROJECT_PLAN_LINKS } from 'ProjectProfile/common/apollo/gql/updateProjectPlanLinks';
import { ProjectPlanLinksInput } from 'ProjectProfile/PreDesign/Links/links.types';

import { GET_PROJECT_PLAN_LINKS } from '../apollo/gql/getProjectPlanLinks';

type Props = {
  onSuccessCallback?: () => void;
  values: ProjectPlanLinksInput;
};

export const useProjectPlanLinksUpdate = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { onCompleted, onError } = useAlert();

  const [updateProjectPlanLinks] = useMutation<
    UpdateProjectPlanQuestionsResponse,
    UpdateProjectPlanLinksRequest
  >(UPDATE_PROJECT_PLAN_LINKS, {
    onCompleted: () => onCompleted('Changes saved'),
    onError: (error) => onError(error.message),
    refetchQueries: () => [
      { query: GET_PROJECT_PLAN_LINKS, variables: { input: { projectId } } },
    ],
  });

  const handleSubmit = async ({ onSuccessCallback, values }: Props) => {
    const response = await updateProjectPlanLinks({
      variables: {
        input: {
          projectId,
          ...values,
        },
      },
    });

    if (response && onSuccessCallback) onSuccessCallback();
  };

  return {
    handleSubmit,
  };
};
