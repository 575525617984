import { ClassNames } from '@emotion/react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { useCollapseProps } from '../../Collapse.hooks';
import { titleStyles, Trigger } from './RightChevronCollapseButton.styles';

type Props = {
  className?: string;
  title?: string;
};

export const RightChevronCollapseButton = ({ className, title }: Props) => {
  const { isOpen, onCollapseToggle } = useCollapseProps();

  return (
    <ClassNames>
      {({ css }) => (
        <Trigger css={css([titleStyles, className])} onClick={onCollapseToggle}>
          {title}
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </Trigger>
      )}
    </ClassNames>
  );
};
