import { gql } from '@apollo/client';

export const ADD_ROOMS_TO_LOOKS_PROPOSAL = gql`
  mutation RenovationPlanAddRooms(
    $input: RenovationPlanAddRoomsToLooksProposalInput!
  ) {
    renovationPlanAddRoomsToLooksProposal(input: $input) {
      id
    }
  }
`;
