import { useParams } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';

import { useToggle } from 'common/hooks/useToggle';
import { useAlert } from 'common/hooks/useAlert';
import { ProjectType } from 'common/apollo/enums/ProjectType';
import { ProjectTypeUpdateResponse } from 'common/apollo/responses/projectTypeUpdateResponse';
import { ProjectTypeUpdateRequest } from 'common/apollo/requests/projectTypeUpdateRequest';
import { PROJECT_TYPE_UPDATE } from 'common/apollo/mutations/projectTypeUpdate.graphql';
import { projectTypeList } from 'common/consts/projectTypeList';

import { QueryParams } from 'ProjectProfile/common/types/queryParams';

import { validationSchema } from './projectType.consts';

export const useProjectTypeDialog = () => {
  const { projectId } = useParams<QueryParams>();

  const {
    isOn: isDialogOpen,
    toggleOff: closeDialog,
    toggleOn: openDialog,
  } = useToggle();

  const { projectTypeUpdate } = useProjectTypeUpdate();

  const handleSubmit = async (projectType: ProjectType) => {
    await projectTypeUpdate({
      variables: { input: { projectId, projectType } },
    });

    closeDialog();
  };

  return {
    closeDialog,
    handleSubmit,
    isDialogOpen,
    openDialog,
    selectOptions: projectTypeList,
    validationSchema,
  };
};

const useProjectTypeUpdate = () => {
  const { onError } = useAlert();

  const [projectTypeUpdate] = useMutation<
    ProjectTypeUpdateResponse,
    ProjectTypeUpdateRequest
  >(PROJECT_TYPE_UPDATE, {
    onError: (error: ApolloError) => onError(error.message),
  });

  return {
    projectTypeUpdate,
  };
};
