import { gql } from '@apollo/client';

export const UPDATE_PROPOSAL_REQUIRE_ACCEPTANCE = gql`
  mutation UpdateProposalRequireAcceptance(
    $input: ProposalRequireAcceptanceUpdateInput!
  ) {
    proposalRequireAcceptanceUpdate(input: $input) {
      id
      requireAcceptance
    }
  }
`;

export const GET_PROPOSAL_SIDEBAR_ACTION_DATA = gql`
  query GetProposalSidebarActionData($proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      name
      createdAt
      updatedAt
      publishedAt
      status
      type
      requireAcceptance
      requirePayment
      pricingEngines {
        id
        name
        dataErrors {
          quantityErrors {
            categoryId
            conflictingQuestion
            currentAnswer
          }
        }
      }
      latestDesignQaEntry {
        id
        status
        completedAt
      }
      latestProcurementQaEntry {
        id
        status
        completedAt
      }
      renovationPlan {
        id
      }
    }
  }
`;
