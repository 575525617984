import { createContext, ReactNode, useState, useMemo } from 'react';

import { Table } from './GridTable.styles';

type GridTableState = {
  columnsWidth: string;
  setColumnsWidth: (value: string[]) => void;
};

export const GridTableContext = createContext<GridTableState>({
  columnsWidth: '',
  setColumnsWidth: () => {},
});

export type Props = {
  children?: ReactNode;
  className?: string;
  columnGap?: string;
};

export const GridTable = ({ children, ...rest }: Props) => {
  const [columnsWidth, setWidth] = useState<string[]>([]);

  const value = useMemo(
    () => ({
      columnsWidth: columnsWidth && columnsWidth.join(' '),
      setColumnsWidth: setWidth,
    }),
    [columnsWidth, setWidth],
  );

  return (
    <GridTableContext.Provider value={value}>
      <Table {...rest} role="table" tabIndex={0}>
        {children}
      </Table>
    </GridTableContext.Provider>
  );
};
