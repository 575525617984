import styled from '@emotion/styled';

import { rgba } from 'common/utils/colors';

export const IconButton = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  height: 32px;
  outline: 0;

  &:hover {
    background-color: ${({ theme }) => rgba(theme.color.lightGray, 0.75)};
    border-radius: 32px;
  }
`;
