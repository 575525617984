import styled from '@emotion/styled';

export const Header = styled.div`
  display: grid;
  font: 500 24px/32px ${({ theme }) => theme.font.inter};
  padding-bottom: 24px;
`;

export const Wrapper = styled.div`
  padding: 24px;
`;

export const RequestFormBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background: #fef6f4;
  border-radius: 10px;
  font: normal 400 14px/20px ${({ theme }) => theme.font.inter};
  color: ${({ theme }) => theme.color.gray11};
  gap: 16px;

  svg {
    path {
      fill: ${({ theme }) => theme.color.orange11};
    }
  }
`;

export const FormLink = styled.a`
  display: flex;
  gap: 7px;
  font: normal 500 14px/20px ${({ theme }) => theme.font.inter};
  color: ${({ theme }) => theme.color.orange11};
`;

export const Label = styled.label`
  display: flex;
  gap: 10px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
`;

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 24px 0;
  font: normal 500 14px/20px ${({ theme }) => theme.font.inter};
  color: ${({ theme }) => theme.color.gray12};
`;

export const QaTimestampHeader = styled.div`
  font: normal 500 14px/20px ${({ theme }) => theme.font.inter};
  color: ${({ theme }) => theme.color.gray11};
  margin-top: 24px;
`;

export const QaTimestampExample = styled.div`
  font: normal 400 14px/20px ${({ theme }) => theme.font.inter};
  color: ${({ theme }) => theme.color.gray11};
  margin-bottom: 24px;
`;

export const DateTimeErrorsWrapper = styled.p`
  min-height: 20px;
  color: #f44336;
  font-size: 0.75rem;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;
