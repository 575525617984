import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Paper } from '@material-ui/core';
import TableMaterial, { TableProps } from '@material-ui/core/Table';

import { THEME } from 'common/consts/theme';

export const Table = styled(TableMaterial)<TableProps>`
  tbody tr:last-of-type > td {
    border-bottom: none;
  }
`;

export const ContentCard = styled(Paper)`
  width: 100%;
  box-shadow: none;
  border: 1px solid ${THEME.color.dividerColor};
  border-radius: 0;
  @media print {
    margin: 0;
    padding: 0;
    border: 0;
  }
`;

export const contentCardStyles = css`
  margin-top: 15px;
  overflow-x: auto;
`;
