import { DeliveryBatch } from 'common/apollo/models/deliveryBatch';
import { OrderItem } from 'common/apollo/models/orderItem';
import { doesBatchPassFilters } from 'common/components/Filters/DeliveryBatchFilters/deliveryBatchFilters.utils';
import { BaseFilter } from 'common/components/Filters/filters.types';

export const normalizeProcurementDeliveryBatchesData = (
  deliveryBatches: DeliveryBatch[] = [],
  selectedFilters: BaseFilter[],
) =>
  deliveryBatches
    .filter((batch) => doesBatchPassFilters(batch, selectedFilters))
    .map((batch) => ({
      allocatedAt: batch.allocatedAt,
      confirmedAt: batch.confirmedAt
        ? new Date(batch.confirmedAt).toLocaleDateString()
        : null,
      eta: batch.eta ? new Date(batch.eta).toLocaleDateString() : null,
      id: batch.id,
      inventoryUnits: batch.inventoryUnits,
      orderItem: batch.orderItem,
      pallet: batch.pallet,
      receivedAt: batch.receivedAt
        ? new Date(batch.receivedAt).toLocaleDateString()
        : null,
      shipments: batch.shipments,
      shippedAt: batch.shippedAt
        ? new Date(batch.shippedAt).toLocaleDateString()
        : null,
      units: batch.units,
    }))
    .reduce(
      (acc, batch) => ({
        ...acc,
        [batch.orderItem.id]: acc[batch.orderItem.id]
          ? acc[batch.orderItem.id].concat(batch)
          : [batch],
      }),
      {} as { [x: string]: DeliveryBatch[] },
    );

export const filterBatchesBySearch = (
  batchMap: { [key: string]: DeliveryBatch[] },
  searchQuery: string,
) =>
  Object.values(batchMap).filter((batches) =>
    filterBySearch(batches[0].orderItem, searchQuery),
  );

export const filterBySearch = (item: OrderItem, searchQuery: string) => {
  const lowerCaseQuery = searchQuery.trim().toLowerCase();
  return (
    item.projectMaterial?.name?.toLowerCase().includes(lowerCaseQuery) ||
    item.projectMaterial?.sku?.sku?.toLowerCase().includes(lowerCaseQuery) ||
    item.skuVendor.vendorSku?.toLowerCase().includes(lowerCaseQuery)
  );
};
