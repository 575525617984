import { ReactNode } from 'react';

import Button from '@material-ui/core/Button';

import { DialogActions, DialogContent } from './confirmationDialog.styles';

type Props = {
  children: ReactNode;
  onClose: () => void;
  onProceed: () => void;
};

export const ConfirmationDialog = ({ children, onClose, onProceed }: Props) => (
  <>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Button onClick={onClose} variant="contained">
        Back
      </Button>
      <Button color="primary" onClick={onProceed} variant="contained">
        Proceed
      </Button>
    </DialogActions>
  </>
);
