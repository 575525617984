import { usePermissions } from 'common/hooks/AuthenticationProvider/authenticationProvider.hooks';
import { AuthUserPermission } from 'common/enums/AuthUserPermission';

export const useHasCatalogAccess = () => {
  const hasWriteAccess = usePermissions(
    AuthUserPermission.MATERIAL_CATALOG_WRITE,
  );

  return {
    hasWriteAccess,
  };
};
