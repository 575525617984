import { Box, Grid, Divider } from '@material-ui/core';
import { Formik, Form } from 'formik';

import { DeliveryBatch } from 'common/apollo/models/deliveryBatch';

import { Button } from 'common/components/Button/Button';
import { Dialog } from 'common/components/Modals/Dialog/Dialog';
import { DialogTitle } from 'common/components/Modals/Dialog/DialogTitle';
import { DialogContent } from 'common/components/Modals/Dialog/DialogContent';

import { FormTextField } from 'common/components/Form/TextField/FormTextField';
import { FormDatePicker } from 'common/components/Form/DatePicker/FormDatePicker';

import { Collapse } from 'common/components/Collapse/Collapse';
import { CollapseContent } from 'common/components/Collapse/CollapseContent';

import { ExpandedIcon } from 'common/components/Icons/Icons';

import { useBulkEditModal } from 'Project/Procurement/Warehouse/BulkEditModal/bulkEditModal.hooks';
import {
  datePickerStyle,
  InnerWrapper,
  Item,
  Wrapper,
} from 'Project/Procurement/Warehouse/BulkEditModal/bulkEditModal.styles';
import {
  BULK_EDIT_FORM_FIELDS,
  BULK_EDIT_MODAL,
} from 'Project/Procurement/Warehouse/BulkEditModal/bulkEditModal.consts';
import { generateBatchListText } from 'Project/Procurement/Warehouse/BulkEditModal/bulkEditModal.utils';

type Props = {
  batches: DeliveryBatch[];
  orderItemDeliveryBatchMap: { [x: string]: DeliveryBatch[] };
};

export const BulkEditModal = ({
  batches,
  orderItemDeliveryBatchMap,
}: Props) => {
  const {
    batchesPerItem,
    handleOnCancel,
    handleOnSubmit,
    initialValues,
    selectionDescription,
  } = useBulkEditModal(batches);

  return (
    <Dialog disableBackdropClick fullWidth id={BULK_EDIT_MODAL} maxWidth="sm">
      <DialogTitle>Bulk Edit</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Formik initialValues={initialValues} onSubmit={handleOnSubmit}>
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Collapse>
                    {({ isOpen, onCollapseToggle }) => (
                      <>
                        <Wrapper isOpen={isOpen} onClick={onCollapseToggle}>
                          {selectionDescription}
                          <ExpandedIcon open={isOpen} />
                        </Wrapper>
                        <CollapseContent>
                          <InnerWrapper>
                            {Object.values(batchesPerItem).map(
                              (orderItemBatches) => {
                                const orderItemName =
                                  orderItemBatches[0].orderItem.projectMaterial
                                    ?.name;
                                return (
                                  <Item key={orderItemName}>
                                    {`${orderItemName} •
                                    ${generateBatchListText(
                                      orderItemBatches,
                                      orderItemDeliveryBatchMap,
                                    )}`}
                                  </Item>
                                );
                              },
                            )}
                          </InnerWrapper>
                        </CollapseContent>
                      </>
                    )}
                  </Collapse>
                </Grid>
                <Grid container direction="row" item>
                  <Grid item xs={6}>
                    <FormTextField
                      isFullWidth
                      label="Pallet"
                      name={BULK_EDIT_FORM_FIELDS.PALLET}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" item spacing={2}>
                  <Grid item xs={6}>
                    <FormDatePicker
                      css={datePickerStyle}
                      label="Received On"
                      name={BULK_EDIT_FORM_FIELDS.RECEIVED_AT}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDatePicker
                      css={datePickerStyle}
                      label="Shipped On"
                      name={BULK_EDIT_FORM_FIELDS.SHIPPED_AT}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" item spacing={2}>
                  <Grid item xs={6}>
                    <FormDatePicker
                      css={datePickerStyle}
                      label="Estimated Time of Arrival"
                      name={BULK_EDIT_FORM_FIELDS.ETA}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDatePicker
                      css={datePickerStyle}
                      label="Confirmed By Contractor"
                      name={BULK_EDIT_FORM_FIELDS.CONFIRMED_AT}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Box
                display="flex"
                flexGrow={1}
                gridColumnGap={16}
                justifyContent="flex-end"
                my={4}
              >
                <Button
                  onClick={handleOnCancel}
                  size="small"
                  variant="contained"
                >
                  Cancel
                </Button>

                <Button
                  color="secondary"
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
              </Box>
            </Form>
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
