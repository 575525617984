import { gql } from '@apollo/client';

export const REQUEST_PROPOSAL_QA = gql`
  mutation RequestProposalQa($input: RequestProposalQaInput!) {
    requestProposalQa(input: $input) {
      id
      status
      qaType
      requestedAt
    }
  }
`;
