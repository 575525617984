import { ChangeEvent } from 'react';

import { useField } from 'formik';

import { useHasFormError } from 'common/hooks/useHasFormError';
import { shouldValidateField } from 'common/utils/validations';

import { Option } from 'common/shared/Question/question.types';

import {
  Control,
  ControlLabel,
  ControlWrapper,
  Description,
  ErrorMessage,
  Label,
  LabelWrapper,
  Wrapper,
} from 'common/shared/Question/Radio/radio.styles';

export type Props = {
  description?: string;
  id?: string;
  isDisabled: boolean;
  isRequired: boolean;
  label: string;
  name: string;
  onChange?: (value: string) => void;
  options: Option[];
};

export const Radio = ({
  description,
  isDisabled,
  isRequired,
  label,
  name,
  onChange,
  options,
}: Props) => {
  const [field, , { setValue }] = useField({
    name,
    validate: shouldValidateField(isRequired),
  });

  const error = useHasFormError(name);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
    setValue(event.target.value);
  };

  return (
    <Wrapper>
      <LabelWrapper>
        <Label>{label}</Label>

        {description && <Description>{description}</Description>}
      </LabelWrapper>

      <Wrapper>
        {options.map((option) => (
          <ControlWrapper key={option.value}>
            <Control
              checked={field?.value === option.value}
              disabled={isDisabled}
              id={option.value}
              name={name}
              onChange={handleChange}
              value={option.value}
            />
            <ControlLabel htmlFor={option.value}>{option.label}</ControlLabel>
          </ControlWrapper>
        ))}
      </Wrapper>

      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};
