import { PricingEngine } from 'common/apollo/models/pricingEngine';

import { Text } from 'common/shared/Question/Text/Text';
import { Textarea } from 'common/shared/Question/Textarea/Textarea';
import { PhotoUpload } from 'common/components/PhotoUpload/PhotoUpload';

import {
  DeleteSpace,
  Wrapper,
} from 'ProjectProfile/ProjectInformation/v2/Space/space.styles';
import { useSpace } from 'ProjectProfile/ProjectInformation/v2/Space/space.hooks';
import { DeleteSpaceDialog } from 'ProjectProfile/ProjectInformation/v2/DeleteSpaceDialog/DeleteSpaceDialog';
import { MEDIA_MIME_TYPES } from 'common/consts/mimeTypes';

type Props = {
  index: number;
  projectId: string;
  proposalId: string;
  room: PricingEngine;
  rooms: PricingEngine[];
};

export const Space = ({ index, projectId, proposalId, room, rooms }: Props) => {
  const {
    cancelDeleteSpace,
    deleteSpace,
    handleDescriptionChange,
    handleNameChange,
    handleNiceToHaveDescriptionChange,
    niceToHaveDescriptionEnabled,
    onDeleteSpaceClick,
  } = useSpace(projectId, proposalId, room, rooms, index);

  return (
    <Wrapper>
      <Text
        id={`rooms[${index}].name`}
        isDisabled={false}
        isRequired
        label="Name*"
        name={`rooms[${index}].name`}
        onChange={handleNameChange}
      />

      <Textarea
        id={`rooms[${index}].description`}
        isDisabled={false}
        isRequired
        label="Detailed Description*"
        name={`rooms[${index}].description`}
        onChange={handleDescriptionChange}
      />

      {niceToHaveDescriptionEnabled && (
        <Textarea
          id={`rooms[${index}].niceToHaveDescription`}
          isDisabled={false}
          isRequired={false}
          label="Nice to Have"
          name={`rooms[${index}].niceToHaveDescription`}
          onChange={handleNiceToHaveDescriptionChange}
        />
      )}

      <PhotoUpload
        allowedFileTypes={[
          ...MEDIA_MIME_TYPES,
          'image/heic',
          'application/pdf',
        ]}
        fieldName={`rooms[${index}].photos`}
      />

      <DeleteSpace onClick={onDeleteSpaceClick}>Delete Space</DeleteSpace>

      <DeleteSpaceDialog
        id={room.id}
        onAccept={deleteSpace}
        onClose={cancelDeleteSpace}
      />
    </Wrapper>
  );
};
