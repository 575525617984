import { useTargetDateHistory } from 'ClientTasks/TargetDate/TargetDateHistoryList/TargetDateHistoryList.hooks';
import { StageIdentifier } from 'common/apollo/enums/StageIdentifier';

import {
  Divider,
  HistoryIcon,
  HistoryViewButton,
  TableHeader,
  TableRowHeader,
  TableRowWrapper,
  TableWrapper,
} from 'ClientTasks/TargetDate/TargetDateHistoryList/targetDateHistoryList.styles';

import { getDesignApprovalHistory } from 'ClientTasks/TargetDate/TargetDateHistoryList/DesignApprovalHistory/designApprovalHistory.utils';

import { DesignApprovalHistoryRow } from 'ClientTasks/TargetDate/TargetDateHistoryList/DesignApprovalHistory/DesignApprovalHistoryRow';

type Props = {
  isCompleted: boolean;
  stageName: StageIdentifier;
};

export const DesignApprovalHistory = ({ isCompleted, stageName }: Props) => {
  const { handleViewFullHistoryClick, keyDateChangeEvents } =
    useTargetDateHistory(stageName);

  const history = getDesignApprovalHistory(keyDateChangeEvents);

  return (
    <TableWrapper>
      <TableRowHeader>
        <TableHeader>Target Date History</TableHeader>
        {!!history.length && (
          <HistoryViewButton onClick={handleViewFullHistoryClick}>
            <HistoryIcon />
            View Full History
          </HistoryViewButton>
        )}
      </TableRowHeader>
      <Divider />
      {history.length ? (
        <DesignApprovalHistoryRow history={history} isCompleted={isCompleted} />
      ) : (
        <TableRowWrapper>Target Date: No target date set</TableRowWrapper>
      )}
    </TableWrapper>
  );
};
