import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { mq } from 'common/utils/mediaQueries';

export const Title = styled.div`
  font: 600 14px/18px ${({ theme }) => theme.font.inter};
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-bottom: 20px;

  ${mq.medium(css`
    padding-top: 30px;
  `)};
`;
