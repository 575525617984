import { gql } from '@apollo/client';

export const GET_PROJECT_PLAN_LINKS = gql`
  query GetProjectPlanLinks($input: ProjectPlanLinksInput!) {
    projectPlanLinks(input: $input) {
      id
      siteSurveyPhotos
      siteSurveyDrawings
      salesProposalSlides
      kickOffDeck
      otherLinks {
        name
        url
      }
    }
  }
`;
