export const ADD_MATERIALS_MODAL_CATALOG_ITEM_FILTER_SELECTION_EVENT =
  'Add Materials - Catalog Items Only Clicked';
export const ADD_MATERIALS_MODAL_PROJECT_COMPATIBLE_ITEMS_ONLY_FILTER_SELECTION_EVENT =
  'Add Materials - Project Compatible Items Only Clicked';
export const ADD_MATERIALS_MODAL_ALL_SECTIONS_EXPANDED_EVENT =
  'Add Materials - All Sections Expanded';
export const ADD_MATERIALS_MODAL_NEW_ITEM_CLICKED_EVENT =
  'Add a new product - New Item Clicked';

export const LABOR_AND_MATERIALS_PAGE_ADD_MATERIALS_CLICKED_EVENT =
  'Labor and Materials Page - Materials Added';
export const LABOR_AND_MATERIALS_PAGE_EDIT_SKU_CLICKED_EVENT =
  'Labor and Materials Page - Edit SKU Clicked';
export const LABOR_AND_MATERIALS_PAGE_REMOVE_SKU_CLICKED_EVENT =
  'Labor and Materials Page - Sku Removed';
export const LABOR_AND_MATERIALS_PAGE_REMOVE_SKU_SET_CLICKED_EVENT =
  'Labor and Materials Page - Sku Set Removed';

export const LABOR_AND_MATERIALS_PAGE_SKU_VARIANT_UPDATE_EVENT =
  'Labor And Materials Page - Sku Variant Update';
export const LABOR_AND_MATERIALS_PAGE_SKU_AMOUNT_UPDATE_EVENT =
  'Labor And Materials Page - Sku Amount Update';
export const LABOR_AND_MATERIALS_PAGE_SKU_OVERAGE_UPDATE_EVENT =
  'Labor And Materials Page - Sku Overage Update';

export const LABOR_AND_MATERIALS_PAGE_ADD_LINE_OF_WORK_BUTTON_CLICKED_EVENT =
  'Add Line of Work - Button Clicked';
export const LABOR_AND_MATERIALS_PAGE_ADD_CUSTOM_LINE_OF_WORK_BUTTON_CLICKED_EVENT =
  'Other Scope - Add New Line of Work Clicked';
export const LABOR_AND_MATERIALS_PAGE_ADD_CUSTOM_LINE_OF_WORK_SAVE_BUTTON_CLICKED_EVENT =
  'Other Scope - Add New Line of Work Saved';

export const LINE_OF_WORK_MODAL_ADD_BUTTON_CLICKED_EVENT =
  'Line of Work Modal - Add Button Clicked';

export const LINE_OF_WORK_MODAL_SELECT_LOW_EVENT =
  'New Line of Work - Lines of Work Selected';

export const ADMIN_NAVIGATION_MENU_CLICKED = 'Admin Navigation Menu Clicked';
export const UPP_TAB_CLICKED = 'Project Profile - Tab Clicked';
export const UPP_EDIT_CLICKED = 'Project Profile - Edit Clicked';

export const PROJECT_FINANCIALS_TAB_CLICKED_EVENT =
  'Project Financials Tab Clicked';
export const CHANGE_ORDERS_TAB_CLICKED_EVENT = 'Change Orders Tab Clicked';
export const INFORMATION_TIPS_FOR_PROJECT_FINANCIALS_CLICKED_EVENT =
  'Information Tips for Project Financials Clicked';
export const LEGACY_PLAN_INFORMATION_CLICKED_EVENT =
  'View Legacy Plan Information - Clicked';

export const MATERIALS_CATALOG_PRODUCTS_CATEGORIES_FILTER_SELECTED =
  'View Catalog - Categories Selected';
export const MATERIALS_CATALOG_PRODUCTS_SHOW_OUT_OF_CATALOG_FILTER_SELECTED =
  'View Catalog - Show out of catalog items Clicked';
export const MATERIALS_CATALOG_PRODUCTS_SEARCH_FILTER =
  'View Catalog - Searched';

export const MATERIALS_CATALOG_VIEW_PRODUCT_EDIT_PRODUCT_CLICKED =
  'View Product - Edit Product Clicked';
export const MATERIALS_CATALOG_VIEW_PRODUCT_DELETE_PRODUCT_CLICKED =
  'View Product - Delete Product Clicked';
export const MATERIALS_CATALOG_VIEW_PRODUCT_ADD_SKU_CLICKED =
  'View Product - Add SKU Clicked';

export const MATERIALS_CATALOG_VIEW_PRODUCT_EDIT_SKU_CLICKED =
  'View Product - Action Bar - Edit SKU Clicked';
export const MATERIALS_CATALOG_VIEW_PRODUCT_DELETE_SKU_CLICKED =
  'View Product - Action Bar - Delete SKU Clicked';
export const MATERIALS_CATALOG_VIEW_PRODUCT_DUPLICATE_SKU_CLICKED =
  'View Product - Action Bar - Duplicate SKU Clicked';
export const MATERIALS_CATALOG_VIEW_PRODUCT_SKU_NUMBER_CLICKED =
  'View Product - SKU Number - SKU Drawer Clicked';

export const MATERIALS_CATALOG_VIEW_PRODUCT_SKU_DRAWER_DELETE_SKU_CLICKED =
  'Edit SKU Drawer - Delete SKU Clicked';
export const MATERIALS_CATALOG_VIEW_PRODUCT_SKU_DRAWER_DUPLICATE_SKU_CLICKED =
  'Edit SKU Drawer - Duplicate SKU Clicked';
export const MATERIALS_CATALOG_VIEW_PRODUCT_SKU_DRAWER_ADD_SKU_VENDOR_CLICKED =
  'Edit SKU Drawer - New Vendor Clicked';
export const MATERIALS_CATALOG_VIEW_PRODUCT_SKU_DRAWER_EDIT_SKU_VENDOR_CLICKED =
  'Edit SKU Drawer - Vendor - Edit Clicked';
export const MATERIALS_CATALOG_VIEW_PRODUCT_SKU_DRAWER_DELETE_SKU_VENDOR_CLICKED =
  'Edit SKU Drawer - Vendor - Delete Clicked';
export const MATERIALS_CATALOG_VIEW_PRODUCT_SKU_DRAWER_MAKE_DEFAULT_SKU_VENDOR_CLICKED =
  'Edit SKU Drawer - Vendor - Make Default Clicked';

export const MATERIALS_CATALOG_VIEW_PRODUCT_SKU_DUPLICATE_SKU_CREATED =
  'Edit SKU Drawer - Duplicate SKU Created';

export const MATERIALS_CATALOG_ADD_SKU_SKU_TAB_NEXT_CLICKED =
  'Add New SKU - SKU Tab Next Clicked';
export const MATERIALS_CATALOG_ADD_SKU_VENDOR_TAB_NEXT_CLICKED =
  'Add New SKU - Vendor Tab Next Clicked';
export const MATERIALS_CATALOG_ADD_SKU_REVIEW_TAB_SAVE_CLICKED =
  'Add New SKU - Review Tab Save Clicked';

export const MATERIALS_CATALOG_BULK_UPLOAD_CLICKED = 'Bulk Upload - Clicked';
export const MATERIALS_CATALOG_BULK_UPLOAD_ADD_PRODUCTS_SKUS_VENDORS_CLICKED =
  'Bulk Upload - Add Products, SKUs, and/or Vendors - Clicked';
export const MATERIALS_CATALOG_BULK_UPLOAD_EDIT_PRODUCTS_SKUS_VENDORS_CLICKED =
  'Bulk Upload - Edit Products, SKUs, and/or Vendors - Clicked';
export const MATERIALS_CATALOG_BULK_UPLOAD_CANCEL_OR_BACK_CLICKED =
  'Bulk Upload - Cancel or Back - Clicked';
export const MATERIALS_CATALOG_BULK_UPLOAD_SKIP_CLICKED =
  'Bulk Upload - Skip - Clicked';
export const MATERIALS_CATALOG_BULK_UPLOAD_NEXT_CLICKED =
  'Bulk Upload - Next - Clicked';
export const MATERIALS_CATALOG_BULK_UPLOAD_FINISH_AND_CLOSED_CLICKED =
  'Bulk Upload - Finish & Closed - Clicked';

export const PROJECTS_FILTERS_CLICKED = 'Filter Projects Tab - Clicked';

export const PROJECT_PROCUREMENT_TAB_CLICKED = 'Procurement Tab Clicked';

export const PROJECT_PROCUREMENT_WAREHOUSE_BULK_EDIT_BUTTON_CLICKED =
  'Bulk Edit button clicked';

export const PROJECT_PROCUREMENT_WAREHOUSE_FILTER_WAREHOUSE_TAB_CLICKED =
  'Filter Warehouse Tab - Clicked';

export const IMPORT_LOOK = 'Admin Import Look';
export const IMPORT_LOOK_PROJECT_COMPATIBLE_TOGGLED =
  'Admin Import Look - Project Compatible';

export const SCOPE_REVISION_CREATE_REVISION_CLICKED =
  'Create Revision - Clicked';
export const SCOPE_REVISION_MODAL_CREATE_REVISION_CLICKED =
  'Create Revision Modal Action - Clicked';
export const SCOPE_REVISION_REVISION_LOG_ROOM_FILTER_CLICKED =
  'Revision Log Filter by room  - Clicked';
export const SCOPE_REVISION_REVISION_REDIRECT_CLICKED =
  'Scope and Revisions Access Points';

export const REVISION_NAV_TAB_CLICKED = 'Revision Top Navigation - Clicked';
export const REVISION_LABOR_AMD_MATERIALS_FILTERS_CLICKED =
  'Revision Labor & Materials Filters - Clicked';
export const REVISION_ADD_MATERIAL_CLICKED = 'Revision Add Material - Clicked';
export const REVISION_ADD_MATERIAL_FILTER_CLICKED =
  'Filters for Add Materials Modal';
export const REVISION_ADD_LABOR_CLICKED = 'Revision Add Labor - Clicked';
export const REVISION_REVIEW_CHANGES_CLICKED =
  'Revision Review Changes - Clicked';
export const REVISION_CHANGE_LOG_TAB_CLICKED =
  'Revisions Review Changes Section - Clicked';
export const REVISION_ROOM_SELECTED =
  'Revisions Review Changes Section - Clicked';
