export const DOCUMENT_MIME_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
];
export const IMAGE_MIME_TYPES = ['image/png', 'image/jpeg', 'image/svg+xml'];
export const VIDEO_MIME_TYPES = [
  'video/mp4',
  'application/x-mpegURL',
  'video/MP2T',
  'video/3gpp',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-ms-wmv',
];

export const MEDIA_MIME_TYPES = [...IMAGE_MIME_TYPES, ...VIDEO_MIME_TYPES];

export const ALL_MIME_TYPES = [
  ...DOCUMENT_MIME_TYPES,
  ...IMAGE_MIME_TYPES,
  ...VIDEO_MIME_TYPES,
];
