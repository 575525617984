import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { mq } from 'common/utils/mediaQueries';

export const Header = styled.div`
  display: flex;
  svg {
    transition: all 0.3s ease;
  }
`;

export const Inner = styled.div`
  padding-top: 10px;

  ${mq.large(css`
    padding-top: 18px;
  `)}
`;

export const Root = styled.section`
  background: ${({ theme }) => theme.color.sauvignon};
  border-radius: 10px;
  padding: 30px 20px;
  margin-bottom: 30px;
  position: relative;

  ${mq.large(css`
    padding: 30px 35px;
  `)}

  > .MuiIconButton-root {
    position: absolute;
    top: 14px;
    right: 14px;
  }
`;
