import { ProposalStatus } from 'common/apollo/enums/ProposalStatus';
import { ProposalType } from 'common/apollo/enums/ProposalType';
import { ProposalConstruction } from 'common/apollo/enums/ProposalConstruction';

import {
  Proposal,
  ProposalDetails,
  ProposalCost,
} from 'common/models/proposal';
import { PricingEngine } from 'common/apollo/models/pricingEngine';
import { RenovationPlan } from 'common/models/renovationPlan';
import { LaborVersion } from 'common/apollo/enums/LaborVersion';

const PROPOSAL_COSTS: ProposalCost = {
  construction: {
    allowances: 0,
    contingency: {
      discount: 0,
      subtotal: 0,
      total: 0,
    },
    discount: 0,
    labor: 0,
    margin: {
      discount: 0,
      subtotal: 0,
      total: 0,
    },
    subtotal: 0,
    total: 0,
    workOrder: {
      discount: 0,
      subtotal: 0,
      total: 0,
    },
  },
  materials: {
    materials: 0,
    shipping: 0,
    tax: 0,
    total: 0,
  },
  permits: {
    total: 0,
  },
  services: {
    discount: 0,
    subtotal: 0,
    total: 0,
  },
  total: {
    discount: 0,
    subtotal: 0,
    subtotalMin: 0,
    total: 0,
    totalMin: 0,
  },
};

const PROPOSAL_DETAILS: ProposalDetails = {
  constructionType: ProposalConstruction.SINGLE,
  costs: PROPOSAL_COSTS,
  paymentSchedule: '',
  proposalDiscounts: [],
  proposalFinancings: [],
  proposalPermits: [],
  timeline: '',
};

const PROPOSAL_PLACEHOLDER: Proposal = {
  createdAt: new Date().toLocaleDateString(),
  details: PROPOSAL_DETAILS,
  id: '0',
  isPermitRequired: true,
  isPublishDisabled: false,
  laborVersion: LaborVersion.V1,
  latestDesignQaEntry: null,
  latestProcurementQaEntry: null,
  name: '',
  organizationId: '',
  paymentScheduleTermId: null,
  pricingEngines: [] as PricingEngine[],
  publishedProjectType: null,
  renovationPlan: {} as RenovationPlan,
  requireAcceptance: false,
  status: ProposalStatus.DRAFT,
  totalCost: 0,
  type: ProposalType.SALES,
  updatedAt: new Date().toLocaleDateString(),
};

export const mapToProposal = (data: any): Proposal => ({
  ...PROPOSAL_PLACEHOLDER,
  ...data,
  details: {
    ...PROPOSAL_PLACEHOLDER.details,
    ...data.details,
  },
  pricingEngines: data.pricingEngines || [],
});
