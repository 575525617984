import styled from '@emotion/styled';

import { THEME } from 'common/consts/theme';

export const NavigationContent = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: 1fr;
  grid-template-rows: auto min-content min-content;
  padding: 20px 20px 56px 27px;
  overflow-y: auto;
`;

export const CurrentUserEmail = styled.div`
  font: normal 500 14px/1.71 ${THEME.font.roboto};
`;

export const LogOutButton = styled.button`
  background: none;
  border: none;
  color: ${THEME.color.grayWithOpacity};
  cursor: pointer;
  font: normal 500 14px/1.71 ${THEME.font.roboto};
  justify-self: start;
  padding: 0;
  transition: color 0.3s;

  &:hover {
    color: ${THEME.color.textExtraLight};
  }
`;
