import { date, number, object, string } from 'yup';

const REQUIRED_FIELD_VALIDATION = 'This field is required';
export const validationBatchSchema = (unitsRemaining: number) =>
  object().shape({
    confirmed: date().nullable(),
    eta: date().nullable(),
    inventoryUnits: number()
      .max(unitsRemaining, 'Cannot exceed total units of order item')
      .min(0, 'Units should not be negative')
      .required(REQUIRED_FIELD_VALIDATION),
    pallet: string(),
    receivedOn: date().nullable(),
    shippedOn: date().nullable(),
    units: number()
      .max(unitsRemaining, 'Cannot exceed total units of order item')
      .positive('Units should be positive')
      .required(REQUIRED_FIELD_VALIDATION),
  });
