import { useField } from 'formik';

import { useHasFormError } from 'common/hooks/useHasFormError';
import { shouldValidateField } from 'common/utils/validations';

import { QuestionTooltip } from 'common/shared/Question/common/QuestionTooltip/QuestionTooltip';

import {
  HelperText,
  Label,
  LabelWrapper,
  ExtraLabel,
  Wrapper,
} from 'common/shared/Question/Dropdown/dropdown.styles';

import { Select } from 'common/components/Select/Select';
import { SelectEvent } from 'common/components/Select/select.types';

export type Props = {
  description?: string;
  fluid?: boolean;
  id: string;
  infoDescription?: string;
  infoHeader?: string;
  isDisabled?: boolean;
  isRequired: boolean;
  label: string;
  name: string;
  onChange?: (value: string) => void;
  options: any;
  placeholder?: string;
  requireTouched?: boolean;
};

export const Dropdown = ({
  description,
  fluid,
  id,
  infoDescription,
  infoHeader,
  isDisabled,
  isRequired,
  label,
  name,
  onChange,
  options,
  placeholder,
  requireTouched = true,
}: Props) => {
  const [{ value }, , { setValue }] = useField({
    name,
    validate: shouldValidateField(isRequired),
  });

  const error = useHasFormError(name, requireTouched);

  const hasHelperText = !!description || !!error;

  const showTooltip = !!infoHeader && !!infoDescription;

  const handleChange = (event: SelectEvent) => {
    onChange?.(event.value);

    setValue(event.value);
  };

  return (
    <Wrapper fluid={fluid}>
      <LabelWrapper>
        <Label htmlFor={id}>{label}</Label>

        {showTooltip && (
          <QuestionTooltip
            infoDescription={infoDescription || ''}
            infoHeader={infoHeader || ''}
          />
        )}

        {!isRequired && <ExtraLabel>Optional</ExtraLabel>}
      </LabelWrapper>

      <Select
        disabled={isDisabled}
        hasError={!!error}
        id={id}
        isClearable={!isRequired}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        value={value}
      />

      {hasHelperText && (
        <HelperText hasError={!!error}>{description || error}</HelperText>
      )}
    </Wrapper>
  );
};
