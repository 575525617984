import { useRef, ReactNode } from 'react';

import { ClickAwayListener } from '@material-ui/core';
import { PopperPlacementType } from '@material-ui/core/Popper';

import { Popper } from 'common/components/Popper/Popper';

import { useMenuButtonContext } from 'common/components/Buttons/MenuButton/MenuButton';
import {
  Button,
  ButtonIcon,
  Menu,
  poperStyles,
} from 'common/components/Buttons/MenuButton/MenuButton.styles';

type Props = {
  children?: ReactNode;
  disablePortal?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  popperPlacement?: PopperPlacementType;
  title: string;
};

export const MenuButtonContent = ({
  children,
  disablePortal = true,
  isDisabled,
  onClick,
  popperPlacement = 'bottom',
  title,
}: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { handleIconClick, handleMenuClose, isMenuOpen } =
    useMenuButtonContext();

  const isNotDisabled = children && !isDisabled;

  return (
    <>
      <Button disabled={isDisabled} onClick={onClick} ref={buttonRef}>
        {title}
        {isNotDisabled && <ButtonIcon onClick={handleIconClick as any} />}
      </Button>

      {isNotDisabled && (
        <Popper
          anchorEl={buttonRef.current}
          css={poperStyles}
          disablePortal={disablePortal}
          open={isMenuOpen}
          placement={popperPlacement}
          role="menu"
        >
          <ClickAwayListener onClickAway={handleMenuClose}>
            <Menu square width={buttonRef?.current?.offsetWidth}>
              {children}
            </Menu>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
};
