import { ThemeProvider, Global } from '@emotion/react';

import { SeoManager } from 'Router/SeoManager';
import { ExternalButton } from 'common/components/Button/Button';

import { THEME } from 'common/consts/theme';
import { BASE_PATH } from 'common/routes/routerPaths';
import globalStyles from 'common/styles/global';

import {
  ContentWrapper,
  Description,
  Title,
  Wrapper,
} from 'common/components/ErrorHandlers/ErrorPage/errorPage.styles';

export const ErrorPage = () => (
  <SeoManager title="Something went wrong | Block Admin">
    <ThemeProvider theme={THEME}>
      <Global styles={globalStyles} />
      <Wrapper>
        <ContentWrapper>
          <Title>An error has occurred.</Title>

          <Description>
            Our team is working hard to address it. If the problem persists,
            please add a ticket in{' '}
            <a
              aria-label="Help Digital Product slack channel"
              href="https://blockrenovation.slack.com/archives/C051LVD6TAL"
              title="Help Digital Product slack channel"
            >
              #help-digitalproduct
            </a>{' '}
            slack channel
          </Description>

          <ExternalButton
            color="secondary"
            fluid
            href={BASE_PATH}
            size="large"
            variant="contained"
          >
            Return To Homepage
          </ExternalButton>
        </ContentWrapper>
      </Wrapper>
    </ThemeProvider>
  </SeoManager>
);
