import { TagGroupType } from 'common/apollo/enums/TagGroupType';
import { SelectControl } from 'common/components/Form/Select/components/SelectControl';
import { SelectMenu } from 'common/components/Form/Select/components/SelectMenu';
import { SelectMultipleControl } from 'common/components/Form/Select/components/SelectMultipleControl';
import { SelectMultipleMenu } from 'common/components/Form/Select/components/SelectMultipleMenu';

export const SelectControlMap = () => ({
  [TagGroupType.MULTI]: SelectMultipleControl,
  [TagGroupType.SINGLE]: SelectControl,
});

export const SelectMenuMap = () => ({
  [TagGroupType.MULTI]: SelectMultipleMenu,
  [TagGroupType.SINGLE]: SelectMenu,
});
