import { ReactNode } from 'react';

import { Checkbox as MuiCheckbox } from '@material-ui/core';
import { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import { CheckboxProps } from '@material-ui/core/Checkbox';

import { FormControlLabelStyled } from './Checkbox.styles';

type FormControlLabelPropsCustom = Omit<
  FormControlLabelProps,
  'control' | 'label'
>;

type FormControlProps = FormControlLabelPropsCustom & {
  label?: ReactNode;
};

type SimpleCheckboxProps = CheckboxProps & {
  isDisabled?: boolean;
};

export type Props = SimpleCheckboxProps & FormControlProps;

const SimpleCheckbox = ({
  isDisabled,
  value,
  ...rest
}: SimpleCheckboxProps) => (
  <MuiCheckbox {...rest} disabled={isDisabled} value={`${value}`} />
);

export const Checkbox = ({
  className,
  color = 'primary',
  isDisabled,
  label,
  labelPlacement,
  ...rest
}: Props) => {
  if (label) {
    return (
      <FormControlLabelStyled
        className={className}
        control={
          <SimpleCheckbox color={color} isDisabled={isDisabled} {...rest} />
        }
        disabled={isDisabled}
        label={label}
        labelPlacement={labelPlacement}
      />
    );
  }

  return (
    <SimpleCheckbox
      className={className}
      color={color}
      isDisabled={isDisabled}
      {...rest}
    />
  );
};
