import { useQuery } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';

import { GetProjectPlanLinksRequest } from 'ProjectProfile/common/requests/getProjectPlanLinksRequest';
import { GetProjectPlanLinksResponse } from 'ProjectProfile/common/responses/getProjectPlanLinksResponse';

import { GET_PROJECT_PLAN_LINKS } from 'ProjectProfile/common//apollo/gql/getProjectPlanLinks';

import { ProjectPlanLinks } from 'common/apollo/models/projectPlanLinks';

type Props = {
  projectId: string;
};

export const useProjectPlanLinks = ({ projectId }: Props) => {
  const { onError } = useAlert();
  const { data, loading } = useQuery<
    GetProjectPlanLinksResponse,
    GetProjectPlanLinksRequest
  >(GET_PROJECT_PLAN_LINKS, {
    onError: (error) => onError(error.message),
    variables: {
      input: {
        projectId,
      },
    },
  });

  const isLoadingProjectPlanLinks = !data && loading;

  const projectPlanLinks = {
    id: '',
    kickOffDeck: null,
    otherLinks: [],
    salesProposalSlides: null,
    siteSurveyDrawings: null,
    siteSurveyPhotos: null,
  } as ProjectPlanLinks;

  return {
    isLoadingProjectPlanLinks,
    projectPlanLinks: data?.projectPlanLinks || projectPlanLinks,
  };
};
