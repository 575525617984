import { useQuery } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';

import { GetProjectPlanSecondaryContactRequest } from 'common/apollo/requests/getProjectPlanSecondaryContactRequest';
import { GetProjectPlanSecondaryContactResponse } from 'common/apollo/responses/getProjectPlanSecondaryContactResponse';

import { GET_PROJECT_PLAN_SECONDARY_CONTACT } from 'common/apollo/queries/getProjectPlanSecondaryContact';

type Props = {
  projectId: string;
};

export const useGetProjectPlanSecondaryContact = ({ projectId }: Props) => {
  const { onError } = useAlert();
  const { data, loading } = useQuery<
    GetProjectPlanSecondaryContactResponse,
    GetProjectPlanSecondaryContactRequest
  >(GET_PROJECT_PLAN_SECONDARY_CONTACT, {
    onError: (error) => onError(error.message),
    skip: !projectId,
    variables: {
      input: {
        projectId,
      },
    },
  });

  const isLoading = !data && loading;

  const projectPlanSecondaryContact = data?.projectPlanSecondaryContact || {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    relationship: '',
  };

  return {
    isLoading,
    secondaryContact: {
      ...projectPlanSecondaryContact,
      name: `${projectPlanSecondaryContact.firstName} ${projectPlanSecondaryContact.lastName}`,
    },
  };
};
