import { useField, ErrorMessage } from 'formik';

import { useHasFormError } from 'common/hooks/useHasFormError';

import { shouldValidateField } from 'common/utils/validations';

import { Textarea } from 'common/_components/Form/Textarea/Textarea';
import { ErrorLabel } from 'common/components/Form/ErrorLabel/ErrorLabel';

import { InputWrapper } from 'common/_components/Form/FormTextField/formTexField.styles';

type Props = {
  disabled?: boolean;
  id: string;
  isRequired?: boolean;
  label?: string;
  name: string;
  placeholder?: string;
  readonly?: boolean;
  rows?: number;
};

export const FormTextArea = ({
  disabled,
  id,
  isRequired = false,
  label,
  name,
  placeholder,
  readonly,
  rows,
}: Props) => {
  const [field] = useField({
    name,
    validate: shouldValidateField(isRequired),
  });

  const hasError = useHasFormError(name);

  return (
    <InputWrapper>
      <Textarea
        {...field}
        disabled={disabled}
        hasError={!!hasError}
        id={id}
        label={label}
        placeholder={placeholder}
        readonly={readonly}
        rows={rows}
      />

      <ErrorMessage component={ErrorLabel} name={field.name} />
    </InputWrapper>
  );
};
