import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: fixed;
  right: 20px;
  top: 10px;
  z-index: 100;
`;

export const LoadingWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;
  background: rgba(0, 0, 0, 0.2);
`;
