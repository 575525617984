import { gql } from '@apollo/client';

export const UPDATE_PROJECT_OVERVIEW_QUESTIONS = gql`
  mutation UpdateProjectBuildingInfoQuestions(
    $input: PropertyProjectUpdateInput!
  ) {
    propertyProjectUpdate(input: $input) {
      id
    }
  }
`;
