import { Formik } from 'formik';

import { Button } from 'common/components/Button/Button';
import { CloseButton } from 'common/components/Form/CloseButton/CloseButton';

import { OrderShipment } from 'Project/Procurement/common/types/orderShipment';

import {
  Content,
  Footer,
  Header,
  Section,
  SectionContent,
  SectionTitle,
  Wrapper,
} from 'Project/Procurement/common/components/Layout/Shipment';

import { ShipmentForm } from 'Project/Procurement/EditOrder/EditShipment/ShipmentForm/ShipmentForm';
import { ShipmentItem } from 'Project/Procurement/EditOrder/EditShipment/ShipmentItem/ShipmentItem';

import { validationSchema } from 'Project/Procurement/EditOrder/EditShipment/editShipment.consts';

import { useEditShipment } from 'Project/Procurement/EditOrder/EditShipment/editShipment.hooks';

type Props = { shipment: OrderShipment | null };

export const EditShipment = ({ shipment }: Props) => {
  const { handleRemoveItem, handleSubmit, initialValues, items, onClose } =
    useEditShipment(shipment);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, isSubmitting, submitForm }) => (
        <Wrapper>
          <Header>
            Edit Shipment #{shipment?.number}
            <CloseButton onClick={onClose} />
          </Header>

          <Content>
            <Section>
              <SectionTitle>Delivery Information</SectionTitle>

              <SectionContent>
                <ShipmentForm />
              </SectionContent>
            </Section>

            {!!items.length && (
              <Section>
                <SectionTitle>Sku Information</SectionTitle>

                <SectionContent>
                  {items.map(
                    ({
                      id,
                      orderItem: { boxCount, name, unitType, unitsAvailable },
                      totalUnits,
                    }) => (
                      <ShipmentItem
                        boxCount={boxCount}
                        key={id}
                        name={name}
                        onRemove={() => handleRemoveItem(id)}
                        unitType={unitType}
                        units={totalUnits}
                        unitsAvailable={unitsAvailable}
                      />
                    ),
                  )}
                </SectionContent>
              </Section>
            )}
          </Content>

          <Footer>
            <Button onClick={onClose} small>
              Cancel
            </Button>

            <Button
              color="secondary"
              disabled={!dirty || isSubmitting}
              onClick={submitForm}
              small
              variant="contained"
            >
              Edit Shipment
            </Button>
          </Footer>
        </Wrapper>
      )}
    </Formik>
  );
};
