import styled from '@emotion/styled';

import DrawerBase, { DrawerProps } from '@material-ui/core/Drawer';

import {
  DRAWER_WIDTH_OPEN,
  DRAWER_WIDTH_CLOSED,
  SUB_DRAWER_WIDTH_CLOSED,
} from 'common/consts/theme';

export type DrawerType = Omit<DrawerProps, 'theme'>;

export const Drawer = styled(DrawerBase)<DrawerType>`
  > div.MuiPaper-root {
    overflow: hidden;
    transition: width 0.3s;
    width: ${({ open }) => (open ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSED)};
    z-index: 10;

    @media print {
      border: 0;
      display: none;
      position: initial;
      width: 100%;
    }
  }
`;

export const SubDrawer = styled(DrawerBase)<DrawerType>`
  > div.MuiPaper-root {
    top: 116px;
    overflow: hidden;
    transition: width 0.3s;
    padding-bottom: 116px;
    width: ${({ open }) =>
      open ? DRAWER_WIDTH_OPEN : SUB_DRAWER_WIDTH_CLOSED};
    z-index: 10;

    @media print {
      border: 0;
      position: initial;
      width: 100%;
    }
  }
`;
