import { Grid } from '@material-ui/core';

import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';
import { CollapsibleContainer } from 'ProjectProfile/common/components/CollapsibleContainer/CollapsibleContainer';

import { useLinks } from './links.hooks';

import { SiteSurveyLinks } from './SiteSurvey/SiteSurveyLinks';
import { SalesLinks } from './Sales/SalesLinks';
import { CanvasLinks } from './Canvas/CanvasLinks';
import { OtherLinks } from './OtherLinks/OtherLinks';

export const Links = () => {
  const { isLoading, projectPlanLinks, projectPlanRoomsLinks } = useLinks();

  const {
    kickOffDeck,
    otherLinks,
    salesProposalSlides,
    siteSurveyDrawings,
    siteSurveyPhotos,
  } = projectPlanLinks;

  return (
    <CollapsibleContainer title="Links">
      {isLoading ? (
        <LoadingSpinnerWithContainer />
      ) : (
        <Grid container spacing={4}>
          <Grid item md={11} sm={12}>
            <SalesLinks
              kickOffDeck={kickOffDeck}
              salesProposalSlides={salesProposalSlides}
            />

            <SiteSurveyLinks
              siteSurveyDrawings={siteSurveyDrawings}
              siteSurveyPhotos={siteSurveyPhotos}
            />

            <CanvasLinks projectPlanRoomsLinks={projectPlanRoomsLinks} />

            <OtherLinks otherLinks={otherLinks} />
          </Grid>
        </Grid>
      )}
    </CollapsibleContainer>
  );
};
