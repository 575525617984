import { Stage } from 'common/apollo/models/stage';

import { ClientPhase } from 'common/types/clientTasks.types';

export const mapClientTasks = (clientStages: Stage[]): ClientPhase[] => {
  const clientTasksList: ClientPhase[] = [];

  clientStages.forEach(({ name: stageName, phases }) => {
    phases.forEach((phase) => {
      const clientPhase = {
        id: phase.id,
        name: `${stageName}${phase.name ? `: ${phase.name}` : ''}`,
        tasks: phase.tasks,
      };

      clientTasksList.push(clientPhase);
    });
  });

  return clientTasksList;
};
