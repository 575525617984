import { useQuery } from '@apollo/client';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useParams } from 'react-router-dom';

import { FeatureFlags } from 'common/enums/FeatureFlags';
import { ProjectType } from 'common/apollo/enums/ProjectType';

import { useGetProject } from 'common/hooks/Project/useGetProject';

import { GetRenovationPlanOnboardingPlannerRequest } from 'ProjectProfile/common/requests/getRenovationPlanOnboardingPlannerRequest';
import { GetRenovationPlanOnboardingPlannerResponse } from 'ProjectProfile/common/responses/getRenovationPlanOnboardingPlannerResponse';
import { GET_RENOVATION_PLAN_ONBOARDING_PLANNER } from 'ProjectProfile/common/apollo/gql/getRenovationPlanOnboardingPlanner';

import { QueryParams } from 'ProjectProfile/common/types/queryParams';

export const useProjectInformation = () => {
  const { projectId } = useParams<QueryParams>();
  const flags = useFlags();
  const { projectType } = useGetProject(projectId);

  const isBlock20Project = projectType === ProjectType.BLOCK_2_0;

  const isHomeOwnerOnboardingV2Enabled =
    flags[FeatureFlags.HOMEOWNER_ONBOARDING_V2_ADMIN];

  const { data } = useQuery<
    GetRenovationPlanOnboardingPlannerResponse,
    GetRenovationPlanOnboardingPlannerRequest
  >(GET_RENOVATION_PLAN_ONBOARDING_PLANNER, {
    skip: !isHomeOwnerOnboardingV2Enabled || !isBlock20Project,
    variables: {
      renovationPlanId: projectId,
    },
  });

  const isProjectInformationV2 =
    isBlock20Project &&
    isHomeOwnerOnboardingV2Enabled &&
    !!data?.onboardingPlanner;

  return { isProjectInformationV2 };
};
