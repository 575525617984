import { gql } from '@apollo/client/core';

export const UPDATE_PROJECT_ORDER_ITEM_UNITS = gql`
  mutation UpdateProjectOrderItemUnits($input: OrderItemUpdateAmountInput!) {
    orderItemUpdateAmount(input: $input) {
      id
      amount
      boxCount
    }
  }
`;
