import { isAfter } from 'date-fns';

export const getLatestProposalQaCompletedAt = (
  designQaCompletedAt: Date | null,
  procurementQaCompletedAt: Date | null,
) => {
  if (
    designQaCompletedAt &&
    procurementQaCompletedAt &&
    isAfter(designQaCompletedAt, procurementQaCompletedAt)
  )
    return designQaCompletedAt;
  if (
    designQaCompletedAt &&
    procurementQaCompletedAt &&
    isAfter(procurementQaCompletedAt, designQaCompletedAt)
  )
    return procurementQaCompletedAt;
  if (!procurementQaCompletedAt && designQaCompletedAt)
    return designQaCompletedAt;
  if (procurementQaCompletedAt && !designQaCompletedAt)
    return procurementQaCompletedAt;
  return null;
};
