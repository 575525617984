import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { rgba } from 'common/utils/colors';

type WrapperProps = { small?: boolean };

const getButtonSize = (small = false) => (small ? '10px' : '20px');

export const CloseButton = styled.button<WrapperProps>`
  ${({ small, theme }) => css`
    background: transparent;
    border: 0;
    cursor: pointer;
    height: ${getButtonSize(small)};
    outline: 0;
    padding: 0;
    position: relative;
    transition:
      background-color 0.125s ease,
      color 0.125s ease,
      outline 0.125s linear;
    width: ${getButtonSize(small)};

    &:after,
    &:before {
      background-color: ${theme.color.black};
      content: '';
      height: ${getButtonSize(small)};
      position: absolute;
      top: 0;
      width: 2px;
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:before {
      transform: rotate(45deg);
    }

    &:focus,
    &:hover {
      background-color: ${rgba(theme.color.mediumGray, 0.25)};
      border-radius: 50%;
      box-shadow: 0 0 0 8px ${rgba(theme.color.mediumGray, 0.25)};
    }

    &[disabled] {
      cursor: not-allowed;
      &:after,
      &:before {
        background-color: ${theme.color.darkGray};
      }
    }
  `}
`;

CloseButton.defaultProps = {
  'aria-label': 'Close',
  type: 'button',
};
