import { IMAGE_MIME_TYPES } from 'common/consts/mimeTypes';

const MEGA_PIXEL = 1000000;

export const reduceImage = (file: File, maxMPs = 5) =>
  new Promise<File>((resolve, reject) => {
    const isImage = IMAGE_MIME_TYPES.includes(file.type);
    if (!isImage) return resolve(file);

    const img = new Image();

    img.src = URL.createObjectURL(file);
    img.onerror = reject;

    const onImageLoad = () => {
      const { height, width } = img;
      const originalMPs = (width * height) / MEGA_PIXEL;

      if (originalMPs < maxMPs && file.type === 'image/jpeg') {
        resolve(file);
        return;
      }

      const ratio = Math.sqrt(maxMPs / originalMPs);

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      if (originalMPs > maxMPs) {
        canvas.width = Math.floor(ratio * width);
        canvas.height = Math.floor(ratio * height);
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob: any) => {
        resolve(
          new File([blob], file.name.replace(/\..+?$/, '.jpg'), {
            type: 'image/jpeg',
          }),
        );
      }, 'image/jpeg');
    };

    img.onload = onImageLoad;
    if (img.naturalHeight) {
      onImageLoad();
    }
  });
