import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';

import { MATERIALS_LONGEST_LEAD_TIME_SET_DIALOG } from './materialsLongestLeadTimeSet.consts';

export const useMaterialsLongestLeadTimeSet = () => {
  const setModalOpen = useSetModalOpen();

  const handleMaterialsLongestLeadTimeSetClick = () => {
    setModalOpen(MATERIALS_LONGEST_LEAD_TIME_SET_DIALOG, true);
  };

  return {
    handleMaterialsLongestLeadTimeSetClick,
  };
};
