import { gql } from '@apollo/client';

export const UPDATE_PROJECT_ADDRESS = gql`
  mutation ProjectAddressUpdate($input: RenovationInfoAddressInput!) {
    projectAddressUpdate(input: $input) {
      id
      address {
        street
        street_2
        state
        city
        zip
      }
      user {
        id
      }
      serviceableStatus
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UserUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      id
      firstName
      lastName
      email
      phoneNumber
    }
  }
`;
