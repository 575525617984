import { typography, font } from 'common/consts/typography';

import { Theme } from 'common/types/theme';

/* eslint-disable sort-keys */
export const THEME: Theme = {
  color: {
    alabaster: '#fafafa',
    alto: '#cecece;',
    black: '#000',
    blue: '#2196f3',
    celeste: '#cecece',
    concrete: '#F2F2F2',
    darkGray: '#6b6b6b',
    darkGray2: '#424242',
    darkGray3: '#1b1b1b',
    darkGray4: '#323232',
    dividerColor: 'rgba(0, 0, 0, 0.12)',
    gainsboro: '#DADADA',
    gray: '#6F6F6F',
    grayWithOpacity: 'rgba(0, 0, 0, 0.54)',
    darkGreen: '#00823D',
    green: '#46A758',
    greige: '#F7F6F5',
    hippieGreen: '#5E8257',
    lightGray: '#e7e7e7',
    limeGreen: '#219653',
    mariner: '#2C6ECB',
    mediumGray: '#bfbfbf',
    mystic: '#e1e4ee',
    primary: '#ff7c55',
    primaryOrange: '#FF7C55',
    primaryOrangeDark: '#c64c2a',
    primaryOrangeLight: '#ffad83',
    red: '#ff1744',
    redOrange: '#F44336',
    silverChalice: '#9D9D9D',
    textExtraLight: 'rgba(0, 0, 0, 0.54)',
    textLight: 'rgba(0, 0, 0, 0.87)',
    white: '#ffffff',
    whiteSmoke: '#EEEEEE',
    yellow: '#FFB224',
    darkYellow: '#D9B600',
    secondaryGray: '#706F6C',

    secondaryLightGray: '#FAFAFA',
    // new colors
    citrineWhite: '#F9EED8',
    quillGray: '#D7D7D6',
    champagne: '#FAE1D4',
    linkWater: '#D6E4F7',
    moonRaker: '#DFD4F7',
    parisWhite: '#D8E7E2',
    cinderella: '#FBD6D5',
    sauvignon: '#F1EAE5',

    jordyBlue: '#8AB4F8',
    grayX11: '#BDBDBE',
    raisinBlack: '#202124',

    black54: '#6F6F6F',

    gray1: '#F9F9F8',
    gray2: '#F3F3F2',
    gray3: '#EEEEEC',
    gray4: '#E9E9E6',
    gray5: '#E3E3E0',
    gray6: '#DBDBD7',
    gray7: '#C8C7C1',
    gray8: '#90908C',
    gray9: '#868682',
    gray10: '#7A7A77',
    gray11: '#706F6C',
    gray12: '#1B1B18',
    grayBorder: '#CECECE',

    green1: '#F3FCF3',
    green2: '#EBF9EB',
    green3: '#DFF3DF',
    green4: '#CEEBCF',
    green5: '#B7DFBA',
    green6: '#97CF9C',
    green7: '#65BA75',
    green8: '#46A758',
    green9: '#3D9A50',
    green10: '#388C49',
    green11: '#297C3B',
    green12: '#1B311E',
    green13: '#00803C',
    green14: 'rgba(15, 170, 88, 0.1)',

    yellow1: '#FFF9ED',
    yellow2: '#FFF4D5',
    yellow3: '#FFECBC',
    yellow4: '#FFE3A2',
    yellow5: '#FFD386',
    yellow6: '#F3BA63',
    yellow7: '#EE9D2B',
    yellow8: '#FFB224',
    yellow9: '#FFA01C',
    yellow10: '#F0961A',
    yellow11: '#AD5700',
    yellow12: '#4E2009',

    orange1: '#FEF6F4',
    orange2: '#FFEDE7',
    orange3: '#FFE2D7',
    orange4: '#FFD2C3',
    orange5: '#FFBDA7',
    orange6: '#FF9E81',
    orange7: '#FC8E6D',
    orange8: '#FF7C55',
    orange9: '#F27049',
    orange10: '#E56740',
    orange11: '#BA4927',
    orange12: '#451D11',

    purple2: '#F9F1FE',
    purple5: '#E3CCF4',
    purple11: '#793AAF',

    pink2: '#FEEFF6',
    pink5: '#F4C6DB',
    pink11: '#D31E66',

    red1: '#FFF8F8',
    red2: '#FFEFEF',
    red3: '#FFE5E5',
    red4: '#FDD8D8',
    red5: '#F9C6C6',
    red6: '#F9C6C6',
    red7: '#EB9091',
    red8: '#E5484D',
    red9: '#DC3D43',
    red10: '#D6383E',
    red11: '#CD2B31',
    red12: '#381316',
    red13: '#F7E7E7',
    crimson6: '#960600',

    blue1: '#F5FAFF',
    blue2: '#EDF6FF',
    blue3: '#E1F0FF',
    blue4: '#CEE7FE',
    blue5: '#B7D9F8',
    blue6: '#96C7F2',
    blue7: '#5EB0EF',
    blue8: '#0091FF',
    blue9: '#0081F1',
    blue10: '#007BE5',
    blue11: '#006ADC',
    blue12: '#00254D',

    slate2: '#E4E9EC',
    slate6: '#556F81',
  },
  font,
  name: 'Default Block Admin Theme',
  typography,
};

export const SPINNER_SIZE = {
  large: 20,
  small: 10,
};

export const TYPOGRAPHY = {
  bodyFontSize: '14px',
  bodyLineHeight: '20px',
  bold: 500,
  buttonLargeFontSize: '16px',
  buttonSmallFontSize: '14px',
  headingFontSize: '20px',
  headingLineHeight: '28px',
  subheadingFontSize: '16px',
  subheadingLineHeight: '24px',
};

export const DRAWER_WIDTH_OPEN = '270px';
export const DRAWER_WIDTH_CLOSED = '61px';
export const SUB_DRAWER_WIDTH_CLOSED = '0px';
