import { RoomType } from 'common/apollo/enums/RoomType';
import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

export const roomTypeToIdentifiersMap = {
  [RoomType.BATHROOM]: [
    PropertyGroupIdentifier.BATHROOM_LAYOUT,
    PropertyGroupIdentifier.DESIGN_PACKAGE,
  ],
  [RoomType.KITCHEN]: [
    PropertyGroupIdentifier.DESIGN_PACKAGE,
    PropertyGroupIdentifier.FUTURE_ROOM_LAYOUT,
    PropertyGroupIdentifier.MAJOR_LAYOUT_CHANGES,
  ],
  [RoomType.OTHER]: [],
};
