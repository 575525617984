import { useParams } from 'react-router-dom';

import { useModal } from 'common/components/Modal/Modal';
import { useProjectSidebarStatus } from 'common/shared/Sidebar/StatusBreakGround/ProjectSidebarStatus/projectSidebarStatus.hooks';
import { ProjectStatus } from 'common/apollo/enums/ProjectStatus';

export const useConfirmProjectMarkedAsLost = () => {
  const { renovationPlanId } = useParams<{ renovationPlanId: string }>();
  const { onClose: handleCloseConfirmLostProjectModal } = useModal();
  const { changeStatus } = useProjectSidebarStatus(renovationPlanId);

  const handleConfirmMarkAsLost = async () => {
    await changeStatus(ProjectStatus.LOST);
    handleCloseConfirmLostProjectModal();
  };

  return {
    handleCloseConfirmLostProjectModal,
    handleConfirmMarkAsLost,
  };
};
