import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { Form, Formik } from 'formik';

import { ModalCloseButton } from 'common/components/Modals/ModalCloseButton';
import { Button } from 'common/components/Buttons/Button/Button';
import { FormTextArea } from 'common/_components/Form/FormTextarea/FormTextarea';

import { Section } from 'ProjectProfile/common/components/Section/Section';

import {
  DialogActions,
  DialogContent,
  FieldInfo,
  FieldWrapper,
} from 'ProjectProfile/common/components/EditForm/editForm.styles';

import { EmptyCopy } from 'ProjectProfile/projectProfile.consts';

import { MAX_EXCEPTIONS_LENGTH, validationSchema } from './exceptions.consts';
import { useExceptions } from './exceptions.hooks';

type Props = {
  placeholder?: string;
  value: string | null;
};

export const Exceptions = ({ placeholder, value }: Props) => {
  const { handleSubmit, isOn, toggleOff, toggleOn } = useExceptions();

  return (
    <>
      <Section
        emptyValue={EmptyCopy.OPTIONAL_FIELD}
        onEditClick={toggleOn}
        title="Exceptions"
      >
        {value}
      </Section>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={isOn}
      >
        <DialogTitle>Exceptions</DialogTitle>
        <ModalCloseButton onClick={toggleOff} />

        <Formik
          initialValues={{ exceptions: value }}
          onSubmit={({ exceptions }) => handleSubmit(exceptions)}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <DialogContent>
                <FieldWrapper>
                  <FormTextArea
                    disabled={isSubmitting}
                    id="exceptions"
                    name="exceptions"
                    placeholder={placeholder}
                    rows={5}
                  />
                  <FieldInfo>
                    {values.exceptions?.length}/{MAX_EXCEPTIONS_LENGTH}
                  </FieldInfo>
                </FieldWrapper>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
