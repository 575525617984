import { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { useFilters } from 'common/components/FiltersMenu/filtersMenu.hooks';
import { BaseFilter } from 'common/components/Filters/filters.types';
import { clearFilterFromUrl } from 'MaterialsCatalog/common/utils/urlSkuFilters';

export const useCheckboxFilter = (
  filter: BaseFilter,
  handleFilterChange: (filter: BaseFilter) => void,
) => {
  const { containerRef, isOpen, toggle } = useFilters();
  const { pathname, search: searchParams } = useLocation();
  const { replace } = useHistory();

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
    const initialSelectedOptions = filter.selectedOptions.map(
      (selectedOption) => selectedOption.value,
    );
    setSelectedOptions(initialSelectedOptions);
  }, [filter.selectedOptions]);

  const updateFilter = (updatedSelections: string[]) => {
    const updatedFilter = filter;
    const updatedSelectedOptions = updatedFilter.options.filter(
      (option) => updatedSelections.indexOf(option.value) !== -1,
    );
    updatedFilter.selectedOptions = updatedSelectedOptions;
    handleFilterChange(updatedFilter);
  };

  const toggleItem = (value: string) => {
    const updatedTags = [...selectedOptions];
    const valueIndex = selectedOptions.indexOf(value);
    if (valueIndex === -1) {
      updatedTags.push(value);
    } else {
      updatedTags.splice(valueIndex, 1);
    }
    setSelectedOptions(updatedTags);
    updateFilter(updatedTags);
  };

  const clearSelections = () => {
    setSelectedOptions([]);
    updateFilter([]);

    const updatedUrlParams = clearFilterFromUrl(searchParams, filter);
    replace({
      pathname,
      search: updatedUrlParams.toString(),
    });
  };

  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    if (isOpen) return;
    setSearchQuery('');
  }, [isOpen]);

  const filteredOptions = searchQuery
    ? filter.options.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : filter.options;

  return {
    clearSelections,
    containerRef,
    filteredOptions,
    isOpen,
    searchQuery,
    selectedOptions,
    setSearchQuery,
    toggle,
    toggleItem,
  };
};
