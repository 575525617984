import { Grid } from '@material-ui/core';

import { ReactComponent as KitchenIcon } from 'common/assets/icons/kitchen.svg';
import { ReactComponent as BathroomIcon } from 'common/assets/icons/bathroom.svg';

import { Section } from 'ProjectProfile/common/components/Section/Section';

import { RoomType } from 'common/apollo/enums/RoomType';

import { useRooms } from './rooms.hooks';

import { Answers, Name, Wrapper } from './rooms.styles';
import { RoomsSkeleton } from './RoomsSkeleton';
import { RoomsEmptyState } from './RoomsEmptyState';

export const Rooms = () => {
  const { hasRoomQuestions, isLoading, roomsQuestions } = useRooms();

  if (isLoading) {
    return (
      <Grid item md={5} sm={10}>
        <RoomsSkeleton />
      </Grid>
    );
  }

  if (!hasRoomQuestions) {
    return (
      <Grid item md={5} sm={10}>
        <RoomsEmptyState />
      </Grid>
    );
  }

  return (
    <>
      {Object.entries(roomsQuestions).map(([pricingEngineId, question]) => (
        <Grid item key={pricingEngineId} md={5} sm={10}>
          <Section>
            <Wrapper>
              {question.roomType === RoomType.BATHROOM && <BathroomIcon />}
              {question.roomType === RoomType.KITCHEN && <KitchenIcon />}
              <Answers>
                <Name>{question.name}</Name>
                {question.responseTexts.map((responseText, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <p key={index}>{responseText}</p>
                ))}
                {question.answers.map(({ id, label }) => (
                  <p key={id}>{label}</p>
                ))}
              </Answers>
            </Wrapper>
          </Section>
        </Grid>
      ))}
    </>
  );
};
