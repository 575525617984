import * as showdown from 'showdown';

export const showdownExtensions = [
  {
    extension: [
      {
        regex: /\(subcopy-\)/g,
        replace: '<p class="subcopy">',
        type: 'output',
      },
      {
        regex: /\(-subcopy\)/g,
        replace: '</p>',
        type: 'output',
      },
    ],
    name: 'subcopy',
  },
  {
    extension: [
      {
        regex: /\(indent-\)/g,
        replace: '<p class="indent">',
        type: 'output',
      },
      {
        regex: /\(-indent\)/g,
        replace: '</p>',
        type: 'output',
      },
    ],
    name: 'indent',
  },
  {
    extension: [
      {
        regex: /\(spacer\)/g,
        replace: '<p class="spacer"></p>',
        type: 'output',
      },
    ],
    name: 'spacer',
  },
  {
    extension: [
      {
        regex: /\(nowrap-\)/g,
        replace: '<span class="nowrap">',
        type: 'output',
      },
      {
        regex: /\(-nowrap\)/g,
        replace: '</span>',
        type: 'output',
      },
    ],
    name: 'nowrap',
  },
];

showdownExtensions.forEach(({ extension, name }) => {
  showdown.extension(name, () => extension);
});

const extensions = showdownExtensions.map(({ name }) => name);

export const markdown2HTML = (markdown: string) => {
  const converter = new showdown.Converter({
    extensions,
    tables: true,
  });

  return converter.makeHtml(markdown);
};
