import { ReactComponent as HelpOutline } from 'common/assets/icons/info-outline.svg';

import {
  Content,
  Header,
  Button,
  Wrapper,
} from 'common/shared/Question/common/QuestionTooltip/questionTooltip.styles';

import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { THEME } from 'common/consts/theme';
import { Markdown } from 'common/components/Markdown/Markdown';

type Props = { infoDescription: string; infoHeader: string };

export const QuestionTooltip = ({ infoDescription, infoHeader }: Props) => (
  <Tooltip
    arrow
    backgroundColor={THEME.color.white}
    content={
      <Wrapper>
        <HelpOutline />
        <Header>{infoHeader}</Header>

        <Content>
          <Markdown markdown={infoDescription} />
        </Content>
      </Wrapper>
    }
    useShadow
  >
    <Button>
      <HelpOutline />
    </Button>
  </Tooltip>
);
