import { PricingEngine } from 'common/apollo/models/pricingEngine';
import { PricingEngineMaterialsCategory } from 'common/apollo/models/pricingEngineMaterialsCategory';
import { Project } from 'common/apollo/models/project';
import { ProjectMaterial } from 'common/apollo/models/projectMaterial';
import { Vendor } from 'common/apollo/models/vendor';

import { ROOM_TYPE_DICTIONARY } from 'common/consts/roomTypes';
import { getSkuSizeWithFraction } from 'common/utils/skuSize';

import { Material } from 'Project/Procurement/Materials/materials.types';

export const normalizeProcurementMaterialsData = (
  materials: ProjectMaterial[] = [],
): Material[] =>
  materials.map(
    ({
      amount,
      boxCount,
      category: {
        id: subcategoryId,
        materialsCategory: { roomType: categoryRoomType },
        name: subcategory,
      },
      generated,
      id,
      name,
      notes,
      pricingEngine,
      revisionActionItem,
      shipments,
      sku: {
        color: { description: color },
        depth,
        finish: { description: finish },
        height,
        length,
        pattern: { description: pattern },
        sku: skuId,
        thickness,
        unit,
        vendor: {
          backInStockDate,
          leadTimeUpdatedAt,
          link: vendorLink,
          maxLeadTimeWeeks,
          minLeadTimeWeeks,
          stockStatus,
          vendor: { id: vendorId, name: vendorName },
          vendorSku: vendorSkuId,
        },
        width,
      },
      status,
      unitsOrdered: { amount: orderAmount, boxCount: orderBoxCount },
    }) => ({
      amount,
      backInStockDate,
      boxCount,
      categoryRoomType,
      color,
      finish,
      generated,
      id,
      leadTimeUpdatedAt,
      maxLeadTimeWeeks,
      minLeadTimeWeeks,
      name,
      notes,
      order: '-',
      orderAmount,
      orderBoxCount,
      pattern,
      revisionActionItem,
      roomId: pricingEngine?.id,
      roomName: pricingEngine?.name,
      shipments,
      size: getSkuSizeWithFraction({ depth, height, length, thickness, width }),
      skuId,
      status,
      stockStatus,
      subcategory,
      subcategoryId,
      unitType: unit?.type,
      vendorId,
      vendorLink,
      vendorName,
      vendorSkuId,
    }),
  );

const normalizeMaterialsCategoriesData = (
  materialsCategories: PricingEngineMaterialsCategory[] = [],
) =>
  materialsCategories
    .flatMap(({ categoryChildren }) => categoryChildren)
    .map(({ id: value, name: label, roomType }) => ({
      label: `${ROOM_TYPE_DICTIONARY[roomType as keyof typeof ROOM_TYPE_DICTIONARY]} | ${label}`,
      value,
    }))
    .sort((a, b) => (a.label < b.label ? -1 : 1));

const normalizeVendorsData = (
  vendors: Vendor[] = [],
  materials: Material[] = [],
) =>
  vendors
    .filter((vendor) =>
      materials.some((material) => material.vendorId === vendor.id),
    )
    .map(({ id: value, name: label }) => ({
      label,
      value,
    }));

const normalizePricingEnginesData = (pricingEngine: PricingEngine[] = []) =>
  pricingEngine.map(({ id: value, name: label }) => ({
    label,
    value,
  }));

export const normalizeProcurementMaterialsFiltersData = (
  data?: {
    materialsCategories: PricingEngineMaterialsCategory[];
    project: Pick<Project, 'pricingEngines'>;
    vendors: Vendor[];
  },
  materials: Material[] = [],
) => ({
  pricingEngines: normalizePricingEnginesData(data?.project.pricingEngines),
  subcategories: normalizeMaterialsCategoriesData(data?.materialsCategories),
  vendors: normalizeVendorsData(data?.vendors, materials),
});
