import { Formik } from 'formik';

import { Dialog } from 'common/components/Modals/Dialog/Dialog';
import { DialogTitle } from 'common/components/Modals/Dialog/DialogTitle';
import { DialogActions } from 'common/components/Modals/Dialog/DialogActions';
import { Text } from 'common/shared/Question/Text/Text';
import { Dropdown } from 'common/shared/Question/Dropdown/Dropdown';
import { AddSpaceFormValues } from 'ProjectProfile/ProjectInformation/v2/AddSpaceDialog/addSpaceDialog.types';
import { Wrapper } from 'ProjectProfile/ProjectInformation/v2/AddSpaceDialog/addSpaceDialog.styles';
import { RoomTypeOptions } from 'ProjectProfile/ProjectInformation/v2/projectInformation.types';

export const DIALOG_ID = 'ADD_SPACE';
const DIALOG_TITLE = 'Add a space';

type Props = {
  onAccept: (values: AddSpaceFormValues) => void;
  onClose: () => void;
  roomTypeOptions: RoomTypeOptions[];
};

export const AddSpaceDialog = ({
  onAccept,
  onClose,
  roomTypeOptions,
}: Props) => {
  const initialValues = {
    name: '',
    type: roomTypeOptions[0].value,
  } as AddSpaceFormValues;

  const handleSubmit = (values: AddSpaceFormValues) => {
    onAccept(values);
  };

  return (
    <Dialog id={DIALOG_ID} isClosable onClose={onClose}>
      <Wrapper>
        <DialogTitle>{DIALOG_TITLE}</DialogTitle>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ submitForm }) => (
            <>
              <Text
                id="name"
                isDisabled={false}
                isRequired
                label="Name"
                name="name"
              />

              <Dropdown
                id="type"
                isDisabled={false}
                isRequired
                label="Room Type"
                name="type"
                options={roomTypeOptions}
              />

              <DialogActions
                acceptLabel="Submit"
                cancelLabel="Cancel"
                onAccept={submitForm}
                onCancel={onClose}
              />
            </>
          )}
        </Formik>
      </Wrapper>
    </Dialog>
  );
};
