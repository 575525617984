import { useQuery } from '@apollo/client';

import { TeamMemberRole } from '__generated/apolloTypes';

import { ProjectType } from 'common/apollo/enums/ProjectType';
import { ZipCodeStatus } from 'common/apollo/enums/ZipCodeStatus';
import { GetProjectSidebarRequest } from 'common/apollo/requests/getProjectSidebarRequest';
import { GetProjectSidebarResponse } from 'common/apollo/responses/getProjectSidebarResponse';
import { useGetProjectPlanSecondaryContact } from 'common/hooks/useProjectPlanSecondaryContact';

import { GET_PROJECT_SIDEBAR_DETAILS } from 'Project/ProjectSidebar/projectSidebar.graphql';

const loadingProject: GetProjectSidebarResponse['project'] = {
  address: {},
  id: '',
  isPostDesignContractorAssignment: null,
  members: [],
  projectType: ProjectType.CORE,
  serviceableStatus: ZipCodeStatus.SERVICEABLE,
  user: {
    email: '',
    firstName: '',
    id: '',
    name: '',
  },
};

export const useGetProject = (projectId: string) => {
  const {
    data,
    error,
    loading: isLoading,
  } = useQuery<GetProjectSidebarResponse, GetProjectSidebarRequest>(
    GET_PROJECT_SIDEBAR_DETAILS,
    {
      skip: !projectId,
      variables: {
        id: projectId,
      },
    },
  );

  const {
    address,
    isPostDesignContractorAssignment,
    market,
    members,
    projectType,
    serviceableStatus,
    user: projectUser,
  } = data?.project ?? loadingProject;

  const { id: userId, ...user } = projectUser;

  const { secondaryContact } = useGetProjectPlanSecondaryContact({
    projectId,
  });
  const secondaryContactEmail = secondaryContact.email;

  const hasContractorAssigned = members.some(
    ({ role }) => role === TeamMemberRole.CONTRACTOR,
  );

  return {
    address,
    error,
    hasContractorAssigned,
    isLoading,
    isPostDesignContractorAssignment,
    marketCode: market?.code ?? null,
    projectType,
    secondaryContactEmail,
    serviceableStatus,
    user,
    userId,
  };
};
