import { THEME } from 'common/consts/theme';

export const muiTypography = {
  body1: {
    color: THEME.color.textLight,
    fontFamily: THEME.font.roboto,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '20px',
  },
  body2: {
    color: THEME.color.textLight,
    fontFamily: THEME.font.roboto,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
  },
  button: {
    color: THEME.color.textLight,
    fontFamily: THEME.font.roboto,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
  },
  caption: {
    color: THEME.color.textLight,
    fontFamily: THEME.font.roboto,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '16px',
  },
  h1: {
    color: THEME.color.textLight,
    fontFamily: THEME.font.roboto,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '32px',
  },
  h2: {
    color: THEME.color.textLight,
    fontFamily: THEME.font.roboto,
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '23px',
  },
  h3: {
    color: THEME.color.textLight,
    fontFamily: THEME.font.roboto,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '24px',
  },
};
