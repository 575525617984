import Grid from '@material-ui/core/Grid';

import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';

import { Container } from 'ProjectProfile/common/components/Container/Container';
import { RoleLabelType } from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';
import { Section } from 'ProjectProfile/common/components/Section/Section';
import { SectionOutlined } from 'ProjectProfile/common/components/Section/SectionOutlined';
import { EmptyCopy } from 'ProjectProfile/projectProfile.consts';

import { useSiteSurvey } from './siteSurvey.hooks';
import { SiteSurveyEdit } from './SiteSurveyEdit';

export const SiteSurvey = () => {
  const {
    answers,
    handleSuccessfulSubmit,
    importances,
    isLoading,
    isOn: isOpen,
    toggleOff: close,
    toggleOn: open,
  } = useSiteSurvey();

  const {
    ELECTRICAL_PANEL_LOCATION,
    IS_SCOPE_VERIFICATION_REQUIRED,
    IS_THERE_SPACE_IN_THE_SUB_E_PANEL_FOR_ADDITIONAL_BREAKERS,
    LIST_OF_ALL_TO_VIF,
    PHOTO_OF_ELECTRICAL_PANEL,
    POST_SITE_SURVEY_NOTES,
    PRICING_UPDATES_NOTES,
    SERVICE_ENTRANCE,
    SUPER_CONTACT_INFO,
    WHAT_IS_TOTAL_AMPERAGE_OF_ELECTRICAL_SERVICE,
  } = answers;

  return (
    <>
      <Container
        onEditClick={open}
        roles={[RoleLabelType.DESIGN_OPS]}
        title="Site Survey"
      >
        {isLoading ? (
          <LoadingSpinnerWithContainer />
        ) : (
          <Grid container spacing={4}>
            <Grid item md={6} sm={12}>
              <Section
                emptyValue={EmptyCopy.OPTIONAL_FIELD}
                isImportant={importances.SERVICE_ENTRANCE}
                title="Service entrance"
              >
                {SERVICE_ENTRANCE}
              </Section>
            </Grid>
            <Grid item md={6} sm={12}>
              <Section
                emptyValue={EmptyCopy.OPTIONAL_FIELD}
                isImportant={importances.SUPER_CONTACT_INFO}
                title="Super contact info"
              >
                {SUPER_CONTACT_INFO}
              </Section>
            </Grid>
            <Grid item md={6} sm={12}>
              <Section title="Scope verification required">
                {IS_SCOPE_VERIFICATION_REQUIRED}
              </Section>
            </Grid>
            <Grid item md={10} sm={12}>
              <SectionOutlined
                isImportant={importances.POST_SITE_SURVEY_NOTES}
                title="Post site-survey notes"
              >
                {POST_SITE_SURVEY_NOTES}
              </SectionOutlined>
            </Grid>
            <Grid item md={10} sm={12}>
              <SectionOutlined
                isImportant={importances.POST_SITE_SURVEY_NOTES}
                title="Pricing update notes"
              >
                {PRICING_UPDATES_NOTES}
              </SectionOutlined>
            </Grid>
            <Grid item md={10} sm={12}>
              <SectionOutlined
                isImportant={importances.LIST_OF_ALL_TO_VIF}
                title="List of All to VIF"
              >
                {LIST_OF_ALL_TO_VIF}
              </SectionOutlined>
            </Grid>

            <Grid item md={6} sm={12}>
              <Section title="Is there space in the sub-e-panel for additional breakers?">
                {IS_THERE_SPACE_IN_THE_SUB_E_PANEL_FOR_ADDITIONAL_BREAKERS}
              </Section>
            </Grid>
            <Grid item md={6} sm={12}>
              <Section title="What is the total amperage of electrical service?">
                {WHAT_IS_TOTAL_AMPERAGE_OF_ELECTRICAL_SERVICE}
              </Section>
            </Grid>
            <Grid item md={6} sm={12}>
              <Section title="Electrical Panel Location">
                {ELECTRICAL_PANEL_LOCATION}
              </Section>
            </Grid>

            <Grid item md={6} sm={12}>
              <Section title="Have you taken a photo of the electrical panel?">
                {PHOTO_OF_ELECTRICAL_PANEL ? 'Yes' : 'No'}
              </Section>
            </Grid>
          </Grid>
        )}
      </Container>

      {isOpen && (
        <SiteSurveyEdit
          close={close}
          handleSuccessfulSubmit={handleSuccessfulSubmit}
        />
      )}
    </>
  );
};
