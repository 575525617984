import {
  DifferenceContent,
  Header,
  NewDifference,
  OldDifference,
  Outer,
} from 'ProjectProfile/ChangeLog/ChangeLogChange/changeLogChange.styles';
import { ProjectPlanChangeLogDifference } from 'ProjectProfile/common/utils/normalizeChangeLog';

type Props = {
  difference: ProjectPlanChangeLogDifference;
};

const ChangeLogChange = ({ difference }: Props) => (
  <Outer>
    <Header>{difference.question.label}</Header>
    {!!difference.oldValue.length && (
      <OldDifference>
        {difference.oldValue.map((o) => (
          <DifferenceContent key={o}>{o}</DifferenceContent>
        ))}
      </OldDifference>
    )}
    {!!difference.newValue.length && (
      <NewDifference>
        {difference.newValue.map((n) => (
          <DifferenceContent key={n}>{n}</DifferenceContent>
        ))}
      </NewDifference>
    )}
  </Outer>
);

export default ChangeLogChange;
