import Grid from '@material-ui/core/Grid';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AUTH_PATHS } from 'common/routes/routerPaths';
import { FeatureFlags } from 'common/enums/FeatureFlags';

import { Container } from 'ProjectProfile/common/components/Container/Container';
import { OutlinedLink } from 'ProjectProfile/common/components/Link/Link';
import { TargetDateBreakground } from 'ProjectProfile/ProjectInformation/v1/Summary/TargetDateBreakground/TargetDateBreakground';

import { Exceptions } from './Exceptions/Exceptions';
import { PostDSOExperience } from './PostDSOExperience/PostDSOExperience';
import { ProjectCallOuts } from './ProjectCallOuts/ProjectCallOuts';
import { Rooms } from './Rooms/Rooms';
import { ProjectType } from './ProjectType/ProjectType';

import { useSummary } from './summary.hooks';

export const Summary = () => {
  const {
    exceptions,
    handleSegmentClick,
    importantProjectCallOuts,
    isPostDesignContractorAssignment,
    placeholders,
    projectType,
    userId,
  } = useSummary();

  const flags = useFlags();
  const targetDateBreakgroundEnabled =
    flags[FeatureFlags.TARGET_DATE_MGMT_BREAKGROUND];

  return (
    <Container title="Summary">
      <Grid container spacing={4}>
        <Rooms />

        <Grid item md={5} sm={10} xs={12}>
          <ProjectType type={projectType} />
        </Grid>

        <Grid item md={5} sm={10} xs={12}>
          <Exceptions
            placeholder={placeholders.EXCEPTIONS}
            value={exceptions}
          />
        </Grid>

        {targetDateBreakgroundEnabled && (
          <Grid item md={10} sm={10} xs={12}>
            <TargetDateBreakground />
          </Grid>
        )}

        <Grid item md={10} sm={10} xs={12}>
          <ProjectCallOuts
            placeholder={placeholders.IMPORTANT_PROJECT_CALL_OUTS}
            value={importantProjectCallOuts}
          />
        </Grid>

        <Grid item md={5} sm={10} xs={12}>
          <OutlinedLink
            color="primary"
            href={AUTH_PATHS.getUserPlanInformationPath(userId)}
            onClick={handleSegmentClick}
          >
            view legacy plan information
          </OutlinedLink>
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <PostDSOExperience value={isPostDesignContractorAssignment} />
        </Grid>
      </Grid>
    </Container>
  );
};
