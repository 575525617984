import { Formik } from 'formik';

import { FormTextField } from 'common/components/Form/TextField/FormTextField';
import { Button } from 'common/components/Button/Button';
import { CloseButton } from 'common/components/Form/CloseButton/CloseButton';

import { Material } from 'Project/Procurement/Materials/materials.types';

import {
  Content,
  Header,
  Footer,
  Wrapper,
} from 'Project/Procurement/Materials/UpdateMaterialNotes/updateMaterialNotes.styles';

import { useUpdateMaterialNotes } from 'Project/Procurement/Materials/UpdateMaterialNotes/updateMaterialNotes.hooks';

type Props = {
  material: Material | null;
};

export const UpdateMaterialNotes = ({ material }: Props) => {
  const { handleClose, handleSubmit } = useUpdateMaterialNotes(material?.id);

  return (
    <Formik
      initialValues={{ notes: material?.notes || '' }}
      onSubmit={handleSubmit}
    >
      {({ dirty, isSubmitting, submitForm }) => (
        <Wrapper>
          <Header>
            {material?.name} <CloseButton onClick={handleClose} />
          </Header>

          <Content>
            <FormTextField
              isFullWidth
              isMultiline
              label="Notes"
              name="notes"
              rows="10"
            />
          </Content>

          <Footer>
            <Button aria-label="Cancel" onClick={handleClose} small>
              Cancel
            </Button>

            <Button
              aria-label="Update"
              color="secondary"
              disabled={isSubmitting || !dirty}
              onClick={submitForm}
              small
              variant="contained"
            >
              Update Notes
            </Button>
          </Footer>
        </Wrapper>
      )}
    </Formik>
  );
};
