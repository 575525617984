import { ReactNode } from 'react';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { LDEvaluationDetail, LDOptions } from 'launchdarkly-js-client-sdk';
import { datadogRum } from '@datadog/browser-rum';

import { FeatureFlags } from 'common/enums/FeatureFlags';

const clientSideID = process.env.REACT_APP_LD_CLIENT_SIDE_ID || '';

type Props = {
  children: ReactNode;
};

export const FeatureFlagsProvider = ({ children }: Props) => {
  const featureFlagInspectors = [
    ...Object.values(FeatureFlags).map((key) => ({
      method: (flagKey: string, flagDetail: LDEvaluationDetail) => {
        datadogRum.addFeatureFlagEvaluation(flagKey, flagDetail.value);
      },
      name: key,
      type: 'flag-used',
    })),
  ];

  const options = {
    inspectors: featureFlagInspectors,
  } as LDOptions;

  return (
    <LDProvider clientSideID={clientSideID} options={options}>
      {children}
    </LDProvider>
  );
};
