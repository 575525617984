import { Select } from 'common/components/Form/Select/Select';
import { statusList } from 'common/consts/statusList';
import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';
import { Modal } from 'common/components/Modal/Modal';
import { ConfirmMarkProjectAsLost } from 'common/shared/Sidebar/StatusBreakGround/ProjectSidebarStatus/ConfirmMarkProjectAsLost/ConfirmMarkProjectAsLost';

import { useProjectSidebarStatus } from './projectSidebarStatus.hooks';

import { ProjectStatusHistory } from './ProjectStatusHistory';

type Props = {
  renovationPlanId: string;
};

export const ProjectSidebarStatus = ({ renovationPlanId = '' }: Props) => {
  const {
    checkProjectStatus,
    customStateReducer,
    handleCloseConfirmLostProjectModal,
    isConfirmLostProjectModalOpen,
    loading,
    status,
    statusHistory,
  } = useProjectSidebarStatus(renovationPlanId);

  const currentLabel = statusList.find(
    (statusItem) => statusItem.value === status,
  );

  return (
    <>
      {loading ? (
        <LoadingSpinnerWithContainer />
      ) : (
        <Select
          currentLabel={currentLabel?.label}
          customStateReducer={customStateReducer}
          label=""
          onChange={checkProjectStatus}
          options={statusList}
          placeholder=""
          value={status}
        />
      )}
      <ProjectStatusHistory statusHistory={statusHistory} />
      <Modal
        onClose={handleCloseConfirmLostProjectModal}
        open={isConfirmLostProjectModalOpen}
      >
        <ConfirmMarkProjectAsLost />
      </Modal>
    </>
  );
};
