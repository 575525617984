import { useState } from 'react';

import { useParams } from 'react-router-dom';

import { useToggle } from 'common/hooks/useToggle';
import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

import { useGetProjectPlanQuestions } from 'ProjectProfile/common/hooks/useProjectPlanQuestions';
import { findAnswerId } from 'ProjectProfile/common/utils/questionAnswer';

import { mapQuestionToFormSelectOptions } from 'ProjectProfile/BuildingInformation/common/utils/projectBuildingQuestions';

export const useBuildingAge = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [editMode, setEditMode] = useState(false);
  const { isOn: isOpen, toggleOff: close, toggleOn: open } = useToggle();

  const { data } = useGetProjectPlanQuestions({
    identifiers: [PropertyGroupIdentifier.BUILDING_AGE],
    projectId,
  });

  const dropdownOptions = mapQuestionToFormSelectOptions(
    PropertyGroupIdentifier.BUILDING_AGE,
    data,
  );

  const selectedAnswerId = findAnswerId(
    PropertyGroupIdentifier.BUILDING_AGE,
    data,
  );

  const handleClose = () => {
    setEditMode(false);
    close();
  };

  const handleEditFormOpen = () => {
    setEditMode(true);
  };

  const questionId = data[0]?.id || '';

  return {
    dropdownOptions,
    editMode,
    handleClose,
    handleEditFormOpen,
    isOpen,
    open,
    questionId,
    selectedAnswerId,
  };
};
