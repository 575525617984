import { useMemo, useState } from 'react';

import { DeliveryBatch } from 'common/apollo/models/deliveryBatch';

import { BaseFilter } from 'common/components/Filters/filters.types';

import { generateFilters } from 'common/components/Filters/DeliveryBatchFilters/deliveryBatchFilters.utils';

import { FilterType } from 'common/components/Filters/enums/FilterType';
import { isFilterActive } from 'common/components/Filters/filters.utils';

const updateSelectedFilters = (
  selectedFilter: BaseFilter,
  updatedFilters: BaseFilter[],
) => {
  const filterIndex = updatedFilters.findIndex(
    (filter) => filter.attribute === selectedFilter.attribute,
  );

  filterIndex === -1
    ? updatedFilters.push(selectedFilter)
    : (updatedFilters[filterIndex] = selectedFilter);
};

const removeSelectedFilter = (
  selectedFilter: BaseFilter,
  updatedFilters: BaseFilter[],
) => {
  const filterIndex = updatedFilters.findIndex(
    (filter) => filter.attribute === selectedFilter.attribute,
  );

  filterIndex !== -1 && updatedFilters.splice(filterIndex, 1);
};

export const useDeliveryBatchFilters = (batches: DeliveryBatch[]) => {
  const [selectedFilters, setSelectedFilters] = useState<BaseFilter[]>([]);

  const handleSelectFilter = (selectedFilter: BaseFilter) => {
    const updatedFilters = [...selectedFilters];

    isFilterActive(selectedFilter)
      ? updateSelectedFilters(selectedFilter, updatedFilters)
      : removeSelectedFilter(selectedFilter, updatedFilters);

    setSelectedFilters(updatedFilters);
  };

  const allFilters: BaseFilter[] = useMemo(
    () =>
      generateFilters(batches, selectedFilters).filter(
        (filter) =>
          filter.type !== FilterType.CHECKBOX || filter.options.length > 0,
      ),
    [batches, selectedFilters],
  );

  const clearAllFilters = () => {
    setSelectedFilters([]);
  };

  return {
    allFilters,
    clearAllFilters,
    handleSelectFilter,
    selectedFilters,
  };
};
