import { gql } from '@apollo/client';

export const GET_TASKS = gql`
  query AdminStages($input: AdminGetStagesInput!) {
    adminGetStages(input: $input) {
      id
      name
      identifier
      isCompleted
      phases {
        id
        name
        description
        duration
        isCompleted
        tasks {
          id
          name
          description
          duration
          note
          isCompleted
          isCanceled
          identifier
          owner
          isOptional
          isLocked
          imageUrl
          richDescription
          scheduledFor
          completedAt
          hasNotification
        }
      }
    }
  }
`;

export const GET_TASK = gql`
  query AdminGetTask($input: AdminGetTaskInput!) {
    adminGetTask(input: $input) {
      id
      name
      description
      duration
      note
      isCompleted
      owner
      isOptional
      isLocked
      imageUrl
      richDescription
      scheduledFor
      completedAt
    }
  }
`;
