/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
export enum ACTION_TYPES {
  fetchActionError = 'fetchActionError',
  fetchActionInit = 'fetchActionInit',
  fetchActionSuccess = 'fetchActionSuccess',
  setPopperOpen = 'setPopoverOpen',
}

export const fetchInit = () => ({
  payload: null,
  type: ACTION_TYPES.fetchActionInit as typeof ACTION_TYPES.fetchActionInit,
});

export const fetchSuccess = (payload: string) => ({
  payload,
  type: ACTION_TYPES.fetchActionSuccess as typeof ACTION_TYPES.fetchActionSuccess,
});

export const fetchError = () => ({
  error: true,
  payload: null,
  type: ACTION_TYPES.fetchActionError as typeof ACTION_TYPES.fetchActionError,
});

export const setPopperVisibility = (payload: boolean) => ({
  payload,
  type: ACTION_TYPES.setPopperOpen as typeof ACTION_TYPES.setPopperOpen,
});

export type Action =
  | ReturnType<typeof fetchInit>
  | ReturnType<typeof fetchSuccess>
  | ReturnType<typeof fetchError>
  | ReturnType<typeof setPopperVisibility>;
