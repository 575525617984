import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const EditableTypographyWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const textFieldStyles = css`
  width: inherit;
`;

export const IconWrapper = styled.div`
  border: 1px solid transparent;
  padding: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.color.gray3};
    border: 1px solid ${({ theme }) => theme.color.gray6};
    border-radius: 4px;
  }
`;

export const iconButtonStyles = css`
  cursor: pointer;
  height: 15px;
  width: 15px;
`;
