import { SpaceFormValues } from 'ProjectProfile/ProjectInformation/v2/projectInformation.types';
import { Question } from 'common/apollo/models/question';

import { openQuestionTypes } from 'common/shared/Question/question.utils';
import { ProjectType } from 'common/apollo/enums/ProjectType';
import { UserReferrer } from 'common/apollo/models/userReferrer';
import { PricingEngine } from 'common/apollo/models/pricingEngine';

const getSelectableQuestionAnswer = (
  answers: string[],
  isSingleSelect: boolean,
) => {
  if (isSingleSelect) {
    return answers.length ? answers[0] : '';
  }

  return answers;
};

export const getRoomValues = (pricingEngines: PricingEngine[]) =>
  (pricingEngines || []).map<SpaceFormValues>((pricingEngine) => ({
    description: pricingEngine.description || '',
    id: pricingEngine.id,
    name: pricingEngine.name,
    niceToHaveDescription: pricingEngine.niceToHaveDescription || '',
    photos: pricingEngine.userMedia.map((photo) => ({
      alt: photo.fileName,
      id: photo.id,
      type: photo.mediaType,
      url: photo.fileUrl,
    })),
  }));

export const getInitialValues = (
  pricingEngines: PricingEngine[],
  questions: Question[],
  projectType?: ProjectType,
  userReferer?: UserReferrer,
) => {
  const questionValues = (questions || []).reduce<{
    [key: string]: string | string[];
  }>(
    (acc, question) => ({
      ...acc,
      [question.id]: openQuestionTypes.includes(question.presentationType)
        ? question?.projectOpenAnswer ?? ''
        : getSelectableQuestionAnswer(
            question.selectedAnswers || [],
            question.isSingleSelect,
          ),
    }),
    {},
  );

  const roomValues = getRoomValues(pricingEngines);

  return {
    campaignCodePrefix: userReferer?.code || '',
    campaignId: userReferer?.campaignId || '',
    projectType: projectType || '',
    questions: questionValues,
    referrerId: userReferer?.id || '',
    rooms: roomValues,
  };
};
