import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { Form, Formik } from 'formik';

import { Box, Grid, TableBody, TableRow } from '@material-ui/core';

import { useState } from 'react';

import styled from '@emotion/styled';

import { ModalCloseButton } from 'common/components/Modals/ModalCloseButton';
import { Button } from 'common/components/Buttons/Button/Button';

import { Section } from 'ProjectProfile/common/components/Section/Section';

import {
  DialogActions,
  DialogContent,
  FieldWrapper,
} from 'ProjectProfile/common/components/EditForm/editForm.styles';

import { EmptyCopy } from 'ProjectProfile/projectProfile.consts';
import {
  useReferralCampaigns,
  useReferredBy,
} from 'ProjectProfile/ProjectInformation/v1/ClientDetails/ReferredBy/referredBy.hooks';
import { SearchInput } from 'common/components/Form/SearchInput/SearchInput';
import { useUsers } from 'Users/users.hooks';
import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';
import { TextFieldSize } from 'common/enums/TextField';
import { FormSelect } from 'common/components/Form/Select/FormSelect';
import { ResponsiveTableCell } from 'common/components/Tables/ResponsiveTable/ResponsiveTableCell';
import { TableLazy } from 'common/components/Tables/TableLazy/TableLazy';

type Props = {
  hasReferrer: boolean;
  referrer: {
    campaignName: string;
    code: string;
    email: string;
    id?: string | undefined;
    name: string;
    planInformationUrl: string;
  };
};

export const Option = styled(Box)`
  cursor: pointer;
  padding: 10px;
  &:hover {
    background-color: lightgrey;
  }
`;

export const ReferredBy = ({ hasReferrer, referrer }: Props) => {
  const { fetchMoreUsers, handleSearchUpdate, isLoading, searchValue, users } =
    useUsers();

  const { campaigns } = useReferralCampaigns();

  const {
    campaign,
    handleSubmit,
    isOn,
    referrerId,
    setCampaign,
    setReferrerId,
    toggleOff,
    toggleOn,
  } = useReferredBy();

  const [usersVisibility, setUsersVisibility] = useState<'hidden' | 'visible'>(
    'hidden',
  );
  const [selectedReferrerText, setSelectedReferrerText] = useState<string>('');

  return (
    <>
      <Section
        emptyValue={EmptyCopy.OPTIONAL_FIELD}
        onEditClick={toggleOn}
        title="Referred By"
      >
        {hasReferrer ? (
          <>
            {referrer.name || referrer.email}
            <br />
            {referrer.code}
            <br />
            {referrer.campaignName}
          </>
        ) : (
          ''
        )}
      </Section>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
        open={isOn}
      >
        <DialogTitle>Referred By</DialogTitle>
        <ModalCloseButton onClick={toggleOff} />

        <Formik initialValues={{}} onSubmit={handleSubmit}>
          {({ isSubmitting, values }) => (
            <Form>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={12}>
                    <FieldWrapper>
                      <FormSelect
                        id={PropertyGroupIdentifier.REFERRAL_CAMPAIGN}
                        isDisabled={isSubmitting}
                        label="Referral Campaign"
                        name={PropertyGroupIdentifier.REFERRAL_CAMPAIGN}
                        onSelect={(value) => setCampaign(value)}
                        options={campaigns.map((c) => ({
                          label: c.name,
                          value: c,
                        }))}
                        placeholder="Referral Campaign"
                        size={TextFieldSize.MEDIUM}
                      />
                    </FieldWrapper>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <FieldWrapper>
                      {referrerId ? (
                        <Option
                          onClick={() => {
                            setReferrerId(0);
                          }}
                        >
                          <span>{selectedReferrerText}</span>
                        </Option>
                      ) : (
                        <SearchInput
                          defaultValue={searchValue}
                          onFocus={() => setUsersVisibility('visible')}
                          onSearchChange={handleSearchUpdate}
                          placeholder="Search name or email"
                        />
                      )}
                    </FieldWrapper>
                    <Box
                      height="10em"
                      overflow="auto"
                      visibility={usersVisibility}
                    >
                      <TableLazy
                        dataLength={users.length}
                        isLoading={isLoading}
                        loadMore={fetchMoreUsers}
                      >
                        <TableBody>
                          {users.map(({ email, id, name }) => (
                            <TableRow key={id}>
                              <ResponsiveTableCell css={{ padding: 0 }}>
                                <Option
                                  onClick={() => {
                                    setReferrerId(Number(id));
                                    setSelectedReferrerText(
                                      `${name} - ${email}`,
                                    );
                                    setUsersVisibility('hidden');
                                  }}
                                >
                                  <span>
                                    {name}&nbsp;-&nbsp;{email}
                                  </span>
                                </Option>
                              </ResponsiveTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </TableLazy>
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  disabled={!referrerId || !campaign || isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
