import { css } from '@emotion/react';

import { ThemeTypography, ThemeFont } from 'common/types/typography';

const inter = 'Inter';
const roboto = 'Roboto';

export const body1 = css`
  font: normal 400 16px/24px ${inter};
`;

export const body2 = css`
  font: normal 400 14px/20px ${inter};
`;

export const buttonText = css`
  font: normal 500 14px/20px ${inter};
`;

export const header1 = css`
  font: normal 600 400 42px/44px ${inter};
`;

export const header2 = css`
  font: normal 600 28px/32px ${inter};
`;

export const header3 = css`
  font: normal 500 24px/32px ${inter};
`;

export const header4 = css`
  font: normal 500 20px/28px ${inter};
`;

export const header5 = css`
  font: normal 500 16px/24px ${inter};
`;

export const header6 = css`
  font: normal 500 14px/20px ${inter};
`;

export const subHeading = css`
  font: normal 600 12px/16px ${inter};
  text-transform: uppercase;
`;

export const caption = css`
  font: normal 400 12px/16px ${inter};
`;

export const typography: ThemeTypography = {
  body1,
  body2,
  buttonText,
  caption,
  header1,
  header2,
  header3,
  header4,
  header5,
  header6,
  subHeading,
};

export const font: ThemeFont = {
  inter,
  roboto,
};
