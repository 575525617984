import { object, string } from 'yup';
import { Form, Formik } from 'formik';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Grid } from '@material-ui/core';

import { REQUIRED_VALIDATION } from 'common/consts/validationMessages';
import { PHONE_NUMBER_REGEX } from 'common/utils/validations';
import { ModalCloseButton } from 'common/components/Modals/ModalCloseButton';
import { Button } from 'common/components/Buttons/Button/Button';

import {
  DialogActions,
  DialogContent,
} from 'ProjectProfile/common/components/EditForm/editForm.styles';

import { TextFieldSize } from 'common/enums/TextField';

import { FormSelect } from 'common/components/Form/Select/FormSelect';

import { useSecondaryContactEdit } from './contactInfo.hooks';
import {
  DUPLICATE_SECONDARY_EMAIL,
  EMAIL_VALIDATION,
  selectOptions,
} from './contactInfo.consts';
import { ContactInput } from './contactEdit.styles';

type Props = {
  close: () => void;
  primaryContact: string;
};

export const SecondaryContactEdit = ({ close, primaryContact }: Props) => {
  const { initialValues, submit } = useSecondaryContactEdit();

  const handleSubmit = async (values: any) => {
    await submit(values, close);
  };

  const validateSchema = object().shape({
    email: string()
      .email(EMAIL_VALIDATION)
      .required(REQUIRED_VALIDATION)
      .not([primaryContact], DUPLICATE_SECONDARY_EMAIL),
    firstName: string().required(REQUIRED_VALIDATION),
    lastName: string().required(REQUIRED_VALIDATION),
    phoneNumber: string().matches(
      PHONE_NUMBER_REGEX,
      'Entered Phone number is invalid',
    ),
    relationship: string().required(REQUIRED_VALIDATION),
  });

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onBackdropClick={close}
      onEscapeKeyDown={close}
      open
    >
      <DialogTitle>Secondary Contact</DialogTitle>
      <ModalCloseButton onClick={close} />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validateSchema}
      >
        {({ isSubmitting, resetForm }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12}>
                  <ContactInput
                    disabled={isSubmitting}
                    id="firstName"
                    label="First name"
                    name="firstName"
                    placeholder="First name"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <ContactInput
                    disabled={isSubmitting}
                    id="lastName"
                    label="Last name"
                    name="lastName"
                    placeholder="Last name"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <FormSelect
                    id="relationship"
                    isDisabled={isSubmitting}
                    label="Relationship to Primary Contact"
                    name="relationship"
                    options={selectOptions}
                    placeholder="Relationship to Primary Contact"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <ContactInput
                    disabled={isSubmitting}
                    id="phoneNumber"
                    label="Phone"
                    name="phoneNumber"
                    placeholder="Phone"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
                <Grid item md={12} sm={12}>
                  <ContactInput
                    disabled={isSubmitting}
                    id="email"
                    label="Email"
                    name="email"
                    placeholder="email"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                disabled={isSubmitting}
                onClick={() => resetForm()}
                variant="text"
              >
                discard changes
              </Button>
              <Button
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
