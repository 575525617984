import { ConstructionInfo } from 'ProjectProfile/BuildingInformation/PropertyInfo/ConstructionInfo/ConstructionInfo';
import { Hoa } from 'ProjectProfile/BuildingInformation/PropertyInfo/Hoa/Hoa';
import { PropertyData } from 'ProjectProfile/BuildingInformation/PropertyInfo/PropertyData/PropertyData';
import {
  PROPERTY_DATA_HOME_VALUATION_RETOOL_URL,
  PROPERTY_INFO_ROLES,
} from 'ProjectProfile/BuildingInformation/PropertyInfo/propertyInfo.consts';
import { useBuildingInfo } from 'ProjectProfile/BuildingInformation/PropertyInfo/propertyInfo.hooks';

export const PropertyInfo = () => {
  const { constructionInfo, hoa, isLoading, propertyInfo } = useBuildingInfo();

  return (
    <>
      {constructionInfo && (
        <ConstructionInfo
          constructionInfo={constructionInfo}
          isLoading={isLoading}
          roles={PROPERTY_INFO_ROLES}
        />
      )}
      {hoa && (
        <Hoa hoa={hoa} isLoading={isLoading} roles={PROPERTY_INFO_ROLES} />
      )}
      {propertyInfo && (
        <PropertyData
          isLoading={isLoading}
          propertyData={propertyInfo}
          retoolUrl={PROPERTY_DATA_HOME_VALUATION_RETOOL_URL}
          roles={PROPERTY_INFO_ROLES}
        />
      )}
    </>
  );
};
