import ReactLightbox, { ILightBoxProps } from 'lightbox-react';
import 'lightbox-react/style.css';

type Props = {
  handleOpen: () => void;
  isOpen: boolean;
  // I have to add any because of 'lightbox-react' inaccurate types
  mediaList: any[];
  photoIndex: number;
  setPhotoIndex: (index: number) => void;
} & Omit<ILightBoxProps, 'mainSrc' | 'onCloseRequest'>;

export const Lightbox = ({
  handleOpen,
  isOpen,
  mediaList,
  photoIndex,
  setPhotoIndex,
  ...props
}: Props) => {
  if (!isOpen) return null;
  const handleMoveNext = () =>
    setPhotoIndex((photoIndex + 1) % mediaList.length);
  const handleMovePrev = () =>
    setPhotoIndex((photoIndex + mediaList.length - 1) % mediaList.length);

  const mainSrc = mediaList[photoIndex];
  const nextSrc = mediaList[(photoIndex + 1) % mediaList.length];
  const prevSrc =
    mediaList[(photoIndex + mediaList.length - 1) % mediaList.length];

  return (
    <ReactLightbox
      mainSrc={mainSrc}
      nextSrc={nextSrc}
      onCloseRequest={handleOpen}
      onMoveNextRequest={handleMoveNext}
      onMovePrevRequest={handleMovePrev}
      prevSrc={prevSrc}
      {...props}
    />
  );
};
