import { ApolloClient, from, InMemoryCache } from '@apollo/client';

import { authMiddleware } from 'common/apollo/middlewares/authMiddleware';
import { errorMiddleware } from 'common/apollo/middlewares/errorMiddleware';
import { httpMiddleware } from 'common/apollo/middlewares/httpMiddleware';
import { refreshTokenMiddleware } from 'common/apollo/middlewares/refreshTokenMiddleware';

import { typePolicies } from 'common/apollo/typePolicies';
import { typeDefs } from 'common/apollo/typeDefs';
import { resolvers } from 'common/apollo/resolvers';

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies,
  }),
  link: from([
    refreshTokenMiddleware,
    errorMiddleware,
    authMiddleware,
    httpMiddleware,
  ]),
  name: 'admin',
  resolvers,
  typeDefs,
  version: process.env.REACT_APP_VERSION,
});
