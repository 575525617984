import styled from '@emotion/styled';
import { css } from '@emotion/react';

import MuiDialogContent, {
  DialogContentProps as MuiDialogContentProps,
} from '@material-ui/core/DialogContent';

import MuiDialogContentText, {
  DialogContentTextProps,
} from '@material-ui/core/DialogContentText';

type DialogContentProps = MuiDialogContentProps & {
  isCentered?: boolean;
  padding?: string;
};

const DialogContentStyled = styled(MuiDialogContent)<DialogContentProps>`
  display: flex;
  flex-flow: column wrap;

  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.textExtraLight};
  padding: ${({ padding = '8px 24px' }) => padding};

  ${({ isCentered }) =>
    isCentered &&
    css`
      align-items: center;
    `}
`;

export const DialogContent = (props: DialogContentProps) => (
  <DialogContentStyled {...props} />
);

export const DialogContentText = (props: DialogContentTextProps) => (
  <MuiDialogContentText {...props} />
);
