import { TextField } from 'common/components/Form/TextField/TextField';

import { ReactComponent as TrashIcon } from 'common/assets/icons/delete-small.svg';

import { SkuUnit } from 'common/apollo/enums/SkuUnit';

import { pluralize } from 'common/utils/pluralize';

import {
  Name,
  Title,
  Wrapper,
} from 'Project/Procurement/EditOrder/EditShipment/ShipmentItem/shipmentItem.styles';

type Props = {
  boxCount: number;
  name: string;
  onRemove: () => void;
  unitType?: SkuUnit;
  units: number;
  unitsAvailable: number;
};

export const ShipmentItem = ({
  boxCount,
  name,
  onRemove,
  unitType,
  units,
  unitsAvailable,
}: Props) => (
  <Wrapper>
    <Title>
      {unitType === SkuUnit.SQ_FOOT
        ? `${unitsAvailable}SF / ${boxCount} ${pluralize('box', boxCount)} `
        : `${unitsAvailable} ${pluralize('unit', unitsAvailable)} `}
      Available
      <TrashIcon onClick={onRemove} />
    </Title>

    <Name>{name}</Name>

    <TextField
      isDisabled
      label="Units In Shipment"
      type="number"
      value={units}
    />
  </Wrapper>
);
