import { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { useFilters } from 'common/components/FiltersMenu/filtersMenu.hooks';
import {
  BaseFilter,
  RangeFilter,
} from 'common/components/Filters/filters.types';
import { clearFilterFromUrl } from 'MaterialsCatalog/common/utils/urlSkuFilters';

export const useRangeFilter = (
  filter: RangeFilter,
  handleFilterChange: (skuFilter: BaseFilter) => void,
) => {
  const { containerRef, isOpen, toggle } = useFilters();
  const { pathname, search: searchParams } = useLocation();
  const { replace } = useHistory();

  const [maxValue, setMaxValue] = useState(filter.maxValue);
  const [minValue, setMinValue] = useState(filter.minValue);

  useEffect(() => {
    setMaxValue(filter.maxValue);
    setMinValue(filter.minValue);
  }, [filter.maxValue, filter.minValue]);

  const updateMaxValue = (value: number) => {
    setMaxValue(value);
    updateFilter(value, minValue);
  };

  const updateMinValue = (value: number) => {
    setMinValue(value);
    updateFilter(maxValue, value);
  };

  const updateFilter = (max: number | null, min: number | null) =>
    handleFilterChange({
      ...filter,
      maxValue: max,
      minValue: min,
    } as BaseFilter);

  const clearSelections = () => {
    updateFilter(null, null);

    const updatedUrlParams = clearFilterFromUrl(searchParams, filter);
    replace({
      pathname,
      search: updatedUrlParams.toString(),
    });
  };

  return {
    clearSelections,
    containerRef,
    isOpen,
    maxValue,
    minValue,
    toggle,
    updateMaxValue,
    updateMinValue,
  };
};
