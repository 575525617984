import { gql } from '@apollo/client';

export const GET_CONSUMER_KEY_DATES = gql`
  query consumerKeyDates($input: ConsumerKeyDatesInput!) {
    consumerKeyDates(input: $input) {
      id
      source
      type
      value
    }
  }
`;
