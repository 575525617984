import { useParams } from 'react-router-dom';

import { GetProjectPlanQuestionsResponse } from 'common/apollo/responses/getProjectPlanQuestionsResponse';

import {
  answersToFormikInitialValues,
  findImportance,
  formikValuesToOpenQuestionsData,
  getImportancesToggles,
  mapQuestionToPlaceholder,
} from 'ProjectProfile/common/utils/questionAnswer';
import { Answers } from 'ProjectProfile/common/types/answer';
import { QuestionPlaceholder } from 'ProjectProfile/common/types/questionPlaceholder';
import { useGetProjectPlanQuestions } from 'ProjectProfile/common/hooks/useProjectPlanQuestions';
import { useProjectPlanQuestionsUpdate } from 'ProjectProfile/common/hooks/useProjectPlanQuestionsUpdate';
import { UpdateProjectPlanQuestionsResponse } from 'ProjectProfile/common/responses/updateProjectPlanQuestionsResponse';
import { ImportancesToggles } from 'ProjectProfile/common/types/importance';

import { identifiers } from '../preKickoff.consts';

export const usePreKickoffEdit = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { handleSubmit } = useProjectPlanQuestionsUpdate();

  const { data: projectPlanQuestions, isLoading } = useGetProjectPlanQuestions({
    identifiers,
    onCompleted: (responseData: GetProjectPlanQuestionsResponse) => {
      identifiers.forEach((identifier) => {
        if (
          findImportance(identifier, responseData.projectPlanQuestions) !==
          importances[identifier].state
        ) {
          importances[identifier].toggle();
        }
      });
    },
    projectId,
  });

  const submit = (
    answers: Answers,
    importances: ImportancesToggles,
    close: (response: UpdateProjectPlanQuestionsResponse) => void,
  ) =>
    handleSubmit({
      onSuccessCallback: close,
      openQuestions: formikValuesToOpenQuestionsData(
        answers,
        identifiers,
        importances,
      ),
    });

  const initialValues = identifiers.reduce(
    (acc, identifier) => ({
      ...acc,
      [identifier]: answersToFormikInitialValues(
        identifier,
        projectPlanQuestions,
      ),
    }),
    {} as Answers,
  );

  const placeholders = identifiers.reduce(
    (acc, identifier) => ({
      ...acc,
      [identifier]: mapQuestionToPlaceholder(identifier, projectPlanQuestions),
    }),
    {} as QuestionPlaceholder,
  );

  const importances = getImportancesToggles(identifiers, projectPlanQuestions);

  return {
    importances,
    initialValues,
    isLoading,
    placeholders,
    projectId,
    submit,
  };
};
