import { useFormikContext } from 'formik';

import { useParams } from 'react-router-dom';

import { pluralize } from 'common/utils/pluralize';

import { AUTH_PATHS } from 'common/routes/routerPaths';

import { Button, LinkButton } from 'common/components/Button/Button';

import { OrderMaterial } from 'Project/Procurement/common/types/orderMaterial';

import { CreateOrderFormValues } from 'Project/Procurement/CreateOrder/createOrder.types';

import {
  Footer,
  Action,
  Content,
} from 'Project/Procurement/CreateOrder/OrderFooter/orderFooter.styles';

type Props = { materials: OrderMaterial[] };

export const OrderFooter = ({ materials }: Props) => {
  const { projectId } = useParams<{ projectId: string }>();

  const { dirty, isSubmitting, submitForm } =
    useFormikContext<CreateOrderFormValues>();

  return (
    <Footer>
      <Content>
        {materials.length} {pluralize('SKU', materials.length)}
      </Content>

      <Action>
        <LinkButton
          small
          to={AUTH_PATHS.getProjectProcurementMaterialsPath(projectId)}
        >
          Cancel
        </LinkButton>

        <Button
          color="secondary"
          disabled={isSubmitting || !dirty}
          onClick={submitForm}
          small
          variant="contained"
        >
          Create Order
        </Button>
      </Action>
    </Footer>
  );
};
