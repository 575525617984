import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mq } from 'common/utils/mediaQueries';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.white};
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  height: 100%;
  max-height: 100vh;
  row-gap: 16px;
  width: 100vw;

  ${mq.small(css`
    width: 90vw;
  `)};

  ${mq.large(css`
    width: 780px;
  `)};
`;

export const Header = styled.header`
  align-items: center;
  display: grid;
  font: 500 20px/28px ${({ theme }) => theme.font.inter};
  grid-template-columns: 1fr 20px;
  position: relative;
  padding: 32px 32px 0 32px;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  overflow: auto;
  row-gap: 40px;
  padding: 0 32px 16px 32px;
`;

export const Footer = styled.footer`
  column-gap: 16px;
  display: flex;
  justify-content: flex-end;
  padding: 0 32px 32px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const SectionTitle = styled.h2`
  font: 400 16px/24px ${({ theme }) => theme.font.inter};
`;

export const SectionContent = styled.div`
  display: grid;
  gap: 16px 32px;
  grid-template-columns: 1fr;

  ${mq.small(css`
    grid-template-columns: 1fr 1fr;
  `)};
`;
