import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { useToggle } from 'common/hooks/useToggle';

import { ProjectPlanQuestions } from 'common/apollo/models/projectPlanQuestion';
import { GetProjectPlanQuestionsResponse } from 'common/apollo/responses/getProjectPlanQuestionsResponse';

import {
  answersToFormikInitialValues,
  findAnswer,
  findImportance,
  formikValuesToOpenQuestionsData,
  formikValuesToQuestionsData,
  getImportances,
  getImportancesToggles,
  mapQuestionAnswer,
  mapQuestionToPlaceholder,
} from 'ProjectProfile/common/utils/questionAnswer';

import { useGetProjectPlanQuestions } from 'ProjectProfile/common/hooks/useProjectPlanQuestions';
import { useProjectPlanQuestionsUpdate } from 'ProjectProfile/common/hooks/useProjectPlanQuestionsUpdate';

import { Answers } from 'ProjectProfile/common/types/answer';
import { SelectOptions } from 'ProjectProfile/common/types/selectOptions';
import { QuestionPlaceholder } from 'ProjectProfile/common/types/questionPlaceholder';

import { AUTH_PATHS } from 'common/routes/routerPaths';

import { GET_PROJECT_REFERRER } from 'ProjectProfile/common/apollo/gql/getProjectReferrer';
import { GetProjectReferrerRequest } from 'ProjectProfile/common/requests/getProjectReferrerRequest';
import { GetProjectReferrerResponse } from 'ProjectProfile/common/responses/getProjectReferrerResponse';
import { UpdateProjectPlanQuestionsResponse } from 'ProjectProfile/common/responses/updateProjectPlanQuestionsResponse';
import {
  Importances,
  ImportancesToggles,
} from 'ProjectProfile/common/types/importance';

import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

import { useGetProject } from 'common/hooks/Project/useGetProject';

import { ProjectType } from 'common/apollo/enums/ProjectType';

import {
  identifiers,
  openQuestionsIdentifiers,
  ProjectExtensionNotesBlock20DefaultValue,
  questionIdentifiers,
} from './clientDetails.consts';

const useProjectReferrer = (projectId: string) => {
  const { data, loading } = useQuery<
    GetProjectReferrerResponse,
    GetProjectReferrerRequest
  >(GET_PROJECT_REFERRER, {
    variables: {
      id: projectId,
    },
  });

  const referrerSource = data?.project?.user.referrer;
  const hasReferrer = !!referrerSource;
  const referrer = {
    campaignName: '',
    code: '',
    email: '',
    name: '',
    planInformationUrl: AUTH_PATHS.getUserPlanInformationPath(
      referrerSource?.id,
    ),
    ...referrerSource,
  };

  return { hasReferrer, isReferrerLoading: loading, referrer };
};

export const useClientDetails = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { isOn, toggleOff, toggleOn } = useToggle(false);

  const { hasReferrer, isReferrerLoading, referrer } =
    useProjectReferrer(projectId);

  const { data: initialProjectPlanQuestions, isLoading } =
    useGetProjectPlanQuestions({
      identifiers,
      projectId,
    });

  const [projectPlanQuestions, setProjectPlanQuestions] = useState<
    ProjectPlanQuestions[]
  >(initialProjectPlanQuestions);
  const [importances, setImportances] = useState<Importances>(
    getImportances(openQuestionsIdentifiers, projectPlanQuestions),
  );

  useEffect(() => {
    if (initialProjectPlanQuestions.length) {
      setProjectPlanQuestions(initialProjectPlanQuestions);
    }
  }, [initialProjectPlanQuestions]);

  useEffect(() => {
    setImportances(
      getImportances(openQuestionsIdentifiers, projectPlanQuestions),
    );
  }, [projectPlanQuestions, isLoading]);

  // make sure to update the view based on the updates
  const handleSuccessfulSubmit = (data: UpdateProjectPlanQuestionsResponse) => {
    setProjectPlanQuestions(data.projectPlanQuestionsUpdate);
    toggleOff();
  };

  const answers = identifiers.reduce(
    (acc, identifier) => ({
      ...acc,
      [identifier]: findAnswer(identifier, projectPlanQuestions),
    }),
    {} as Answers,
  );

  return {
    answers,
    handleSuccessfulSubmit,
    hasReferrer,
    importances,
    isLoading: isLoading && isReferrerLoading,
    isOn,
    projectId,
    referrer,
    toggleOff,
    toggleOn,
  };
};

export const useClientDetailsEdit = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { handleSubmit } = useProjectPlanQuestionsUpdate();
  const { isLoading: isPrimaryContactLoading, projectType } =
    useGetProject(projectId);

  const { data: projectPlanQuestions, isLoading } = useGetProjectPlanQuestions({
    identifiers,
    onCompleted: (responseData: GetProjectPlanQuestionsResponse) => {
      openQuestionsIdentifiers.forEach((identifier) => {
        if (
          findImportance(identifier, responseData.projectPlanQuestions) !==
          importances[identifier].state
        ) {
          importances[identifier].toggle();
        }
      });
    },
    projectId,
  });

  const submit = async (
    answers: Answers,
    importances: ImportancesToggles,
    close: (response: UpdateProjectPlanQuestionsResponse) => void,
  ) =>
    handleSubmit({
      onSuccessCallback: close,
      openQuestions: formikValuesToOpenQuestionsData(
        answers,
        openQuestionsIdentifiers,
        importances,
      ),
      questions: formikValuesToQuestionsData(
        answers,
        projectPlanQuestions,
        questionIdentifiers,
      ),
    });

  const options = questionIdentifiers.reduce(
    (acc, identifier) => ({
      ...acc,
      [identifier]: mapQuestionAnswer(identifier, projectPlanQuestions),
    }),
    {} as SelectOptions,
  );

  const initialValues = identifiers.reduce(
    (acc, identifier) => ({
      ...acc,
      [identifier]: answersToFormikInitialValues(
        identifier,
        projectPlanQuestions,
      ),
    }),
    {} as Answers,
  );
  const addProjectExtensionNotesDefaultValue =
    !initialValues[PropertyGroupIdentifier.PROJECT_EXTENSION_NOTES] &&
    projectType === ProjectType.BLOCK_2_0;

  const initialValuesWithDefault = {
    ...initialValues,
    ...(addProjectExtensionNotesDefaultValue && {
      [PropertyGroupIdentifier.PROJECT_EXTENSION_NOTES]:
        ProjectExtensionNotesBlock20DefaultValue,
    }),
  };

  const importances = getImportancesToggles(
    openQuestionsIdentifiers,
    projectPlanQuestions,
  );

  const placeholders = openQuestionsIdentifiers.reduce(
    (acc, identifier) => ({
      ...acc,
      [identifier]: mapQuestionToPlaceholder(identifier, projectPlanQuestions),
    }),
    {} as QuestionPlaceholder,
  );

  return {
    importances,
    initialValues: initialValuesWithDefault,
    isLoading: isLoading || isPrimaryContactLoading,
    options,
    placeholders,
    projectId,
    submit,
  };
};
