import { ChangeEvent } from 'react';

import { debounce } from 'lodash-es';

import { ReactComponent as SearchIcon } from 'common/assets/icons/search.svg';

import {
  TextField,
  Props as TextFieldProps,
} from 'common/components/TextField/TextField';

type Props = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
} & Omit<TextFieldProps, 'onChange'>;

const UPDATE_DELAY = 300;

export const SearchInput = ({ onChange, ...props }: Props) => {
  const handleChange = debounce(onChange, UPDATE_DELAY);

  return (
    <TextField
      placeholder="Search"
      startAdornment={<SearchIcon />}
      {...props}
      onChange={handleChange}
    />
  );
};
