import { Fragment } from 'react';
import { Form, Formik, FieldArray } from 'formik';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { Grid, Button as AddLinkButton } from '@material-ui/core';

import { ReactComponent as TrashIcon } from 'common/assets/icons/delete-small.svg';

import { ModalCloseButton } from 'common/components/Modals/ModalCloseButton';
import { Button } from 'common/components/Buttons/Button/Button';

import {
  DialogActions,
  DialogContent,
} from 'ProjectProfile/common/components/EditForm/editForm.styles';

import { TextFieldSize } from 'common/enums/TextField';

import { validationSchema } from './otherLinks.consts';

import { useOtherLinksEdit } from './otherLinks.hooks';

import { LinkInput, DeleteLinkButton, LinkInputWrapper } from '../links.styles';

type Props = {
  close: () => void;
};

export const OtherLinksEdit = ({ close }: Props) => {
  const { emptyLink, initialValues, submit } = useOtherLinksEdit();

  const handleSubmit = (values: any) => submit(values, close);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onBackdropClick={close}
      onEscapeKeyDown={close}
      open
    >
      <DialogTitle>Other Helpful Links</DialogTitle>
      <ModalCloseButton onClick={close} />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, resetForm, values }) => (
          <Form>
            <FieldArray name="otherLinks">
              {({ insert, push, remove }) => (
                <DialogContent>
                  <Grid container spacing={2}>
                    {values.otherLinks.length ? (
                      (values.otherLinks || []).map((_, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={`name${index}`}>
                          <Grid item md={5} sm={12} xs={12}>
                            <LinkInput
                              disabled={isSubmitting}
                              id={`otherLinks.${index}.name`}
                              label="Link name"
                              name={`otherLinks.${index}.name`}
                              placeholder="Link name"
                              size={TextFieldSize.EXTRA_LARGE}
                            />
                          </Grid>
                          <Grid item md={7} sm={12} xs={12}>
                            <LinkInputWrapper>
                              <LinkInput
                                disabled={isSubmitting}
                                id={`otherLinks.${index}.url`}
                                label="Helpful Link URL"
                                name={`otherLinks.${index}.url`}
                                placeholder="Helpful Link URL"
                                size={TextFieldSize.EXTRA_LARGE}
                              />

                              <DeleteLinkButton onClick={() => remove(index)}>
                                <TrashIcon />
                              </DeleteLinkButton>
                            </LinkInputWrapper>
                          </Grid>
                        </Fragment>
                      ))
                    ) : (
                      <Grid item md={12} sm={12}>
                        No other helpful link is added yet, please add one.
                      </Grid>
                    )}
                    <Grid item md={12} sm={12}>
                      <AddLinkButton
                        color="secondary"
                        onClick={() => push(emptyLink)}
                        size="small"
                        type="button"
                        variant="outlined"
                      >
                        Add Helpful link
                      </AddLinkButton>
                    </Grid>
                  </Grid>
                </DialogContent>
              )}
            </FieldArray>
            <DialogActions>
              <Button
                color="primary"
                disabled={isSubmitting}
                onClick={() => resetForm()}
                variant="text"
              >
                disCard changes
              </Button>
              <Button
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
