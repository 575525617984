import { ChangeLogType } from 'common/apollo/enums/ChangeLogType';
import { ProjectPlanChangeLog } from 'common/apollo/models/projectPlanChangeLog';
import { getSectionByPropertyGroupIndentifier } from 'ProjectProfile/common/utils/getSectionByPropertyGroupIdentifier';
import { ProjectProfileSubSection } from 'ProjectProfile/projectProfile.consts';

export interface ProjectPlanChangeLogDifference extends ProjectPlanChangeLog {
  newValue: string[];
  oldValue: string[];
  subSection: ProjectProfileSubSection;
}

export interface GroupedProjectPlanChangeLog {
  changes: ProjectPlanChangeLogDifference[];
  date: Date;
  name: string;
  subSection: ProjectProfileSubSection;
  type: ChangeLogType;
}

const getQuestionAnswerString = (change: ProjectPlanChangeLog): string[] => {
  const rtrn = [];
  if (change.answers.length) {
    change.answers.forEach((c) => rtrn.push(c.answer.label));
  } else if (change.responseText) {
    rtrn.push(change.responseText);
  }

  return rtrn;
};

/**
 *
 * @param changeLog An ordered list of ProjectPlanChangeLog ordered by date descending
 */
export const normalizeProjectPlanChangeLog = (
  changeLog: ProjectPlanChangeLog[],
) => {
  const lastChangeByQuestion = new Map<string, ProjectPlanChangeLog>();
  const reversed = changeLog.slice().reverse();
  const rtrn: GroupedProjectPlanChangeLog[] = [];
  let lastGroupedChange: GroupedProjectPlanChangeLog;

  reversed.forEach((change) => {
    const questionId = change.question.id;
    const newValue: string[] = getQuestionAnswerString(change);
    const oldValue: string[] = lastChangeByQuestion.get(questionId)
      ? getQuestionAnswerString(lastChangeByQuestion.get(questionId)!)
      : [];

    // Because of the way updates sometimes happen for only one part of
    // an entire section, certain changelogs can be recorded that
    // didn't actually change anything. We'll ignore those.
    if (
      newValue.join() === oldValue.join() &&
      change.type !== ChangeLogType.FLAG &&
      change.type !== ChangeLogType.UNFLAG
    ) {
      return;
    }

    if (
      change.type === ChangeLogType.INSERT ||
      change.type === ChangeLogType.UPDATE
    ) {
      lastChangeByQuestion.set(change.question.id, change);
    }

    const diff = {
      ...change,
      newValue,
      oldValue,
      subSection: getSectionByPropertyGroupIndentifier(
        change.question.identifier,
      ),
    };

    if (
      lastGroupedChange?.date.toISOString() === diff.updatedAt &&
      lastGroupedChange?.subSection === diff.subSection &&
      lastGroupedChange?.type === diff.type
    ) {
      lastGroupedChange.changes.push(diff);
    } else {
      lastGroupedChange = {
        changes: [diff],
        date: new Date(change.updatedAt),
        name: change.authUser.firstName,
        subSection: diff.subSection,
        type: diff.type,
      };
      rtrn.push(lastGroupedChange);
    }
  });

  return rtrn.slice().reverse();
};
