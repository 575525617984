import { useQuery } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';

import { GetProjectPlanQuestionsRequest } from 'common/apollo/requests/getProjectPlanQuestionsRequest';
import { GetProjectPlanQuestionsResponse } from 'common/apollo/responses/getProjectPlanQuestionsResponse';

import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

import { GET_PROJECT_PLAN_QUESTIONS } from 'ProjectProfile/common/apollo/gql/getProjectPlanQuestions';

type Props = {
  identifiers: PropertyGroupIdentifier[];
  onCompleted?: (response: GetProjectPlanQuestionsResponse) => void;
  projectId: string;
};

export const useGetProjectPlanQuestions = ({
  identifiers,
  onCompleted = undefined,
  projectId,
}: Props) => {
  const { onError } = useAlert();
  const { data, loading } = useQuery<
    GetProjectPlanQuestionsResponse,
    GetProjectPlanQuestionsRequest
  >(GET_PROJECT_PLAN_QUESTIONS, {
    onCompleted: (responseData) => {
      if (onCompleted) {
        onCompleted(responseData);
      }
    },
    onError: (error) => onError(error.message),
    variables: {
      input: {
        identifiers,
        projectId,
      },
    },
  });

  const isLoading = !data && loading;

  return {
    data: data?.projectPlanQuestions || [],
    isLoading,
  };
};
