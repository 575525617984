import { Form, Formik } from 'formik';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Grid } from '@material-ui/core';

import { ModalCloseButton } from 'common/components/Modals/ModalCloseButton';
import { Button } from 'common/components/Buttons/Button/Button';

import {
  DialogActions,
  DialogContent,
} from 'ProjectProfile/common/components/EditForm/editForm.styles';

import { TextFieldSize } from 'common/enums/TextField';

import { validationSchema } from './salesLinks.consts';
import { useSalesLinksEdit } from './salesLinks.hooks';

import { LinkInput } from '../links.styles';

type Props = {
  close: () => void;
};

export const SalesLinksEdit = ({ close }: Props) => {
  const { initialValues, submit } = useSalesLinksEdit();

  const handleSubmit = async (values: any) => {
    await submit(values, close);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onBackdropClick={close}
      onEscapeKeyDown={close}
      open
    >
      <DialogTitle>Sales Links</DialogTitle>
      <ModalCloseButton onClick={close} />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, resetForm }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <LinkInput
                    disabled={isSubmitting}
                    id="salesProposalSlides"
                    label="Sales Proposal Slide URL"
                    name="salesProposalSlides"
                    placeholder="Sales Proposal Slide URL"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <LinkInput
                    disabled={isSubmitting}
                    id="kickOffDeck"
                    label="Kickoff Deck URL"
                    name="kickOffDeck"
                    placeholder="Kickoff Deck URL"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                disabled={isSubmitting}
                onClick={() => resetForm()}
                variant="text"
              >
                discard changes
              </Button>
              <Button
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
