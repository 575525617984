import { gql } from '@apollo/client';

export const GET_PROJECT_PLAN_QUESTIONS = gql`
  query GetProjectPlanQuestions($input: ProjectPlanQuestionsInput!) {
    projectPlanQuestions(input: $input) {
      id
      answers {
        id
        label
        isSelected
        type
      }
      identifier
      isImportant
      placeholder
      responseText
      label
      title
    }
  }
`;
