import { UploadStatus } from 'common/hooks/FileUpload/fileUpload.consts';
import {
  Action,
  ActionTypes,
} from 'common/hooks/FileUpload/fileUpload.actions';
import { FileState } from 'common/hooks/FileUpload/fileUpload.types';

const defaultFileState = {
  errors: [],
  fileBlob: null,
  fileDetails: undefined,
  id: '',
  isLoading: false,
  presignedData: undefined,
  progress: 0,
  status: UploadStatus.Ready,
};

export const initialState: FileState[] = [];

export const reducer = (state: FileState[], action: Action): FileState[] => {
  switch (action.type) {
    case ActionTypes.VALIDATE_AND_STORE_FILE_DATA:
      return [
        ...state,
        {
          ...defaultFileState,
          fileBlob: action.payload.file,
          id: action.payload.id,
          status: UploadStatus.InitialDataStored,
        },
      ];
    case ActionTypes.STORE_PRESIGNED_FILE_DATA:
      return state.map((file) => {
        if (file.id !== action.payload.id) return file;

        const {
          contentType,
          fileUrl,
          fileUuid,
          originalName,
          presignedFields,
          presignedUrl,
          thumbnailUrl,
          uploadCategory,
        } = action.payload.data;

        return {
          ...file,
          fileDetails: {
            contentType,
            fileUrl,
            fileUuid,
            originalName,
            thumbnailUrl,
            uploadCategory,
          },
          presignedData: {
            presignedFields,
            presignedUrl,
          },
          status: UploadStatus.PresignedDataStored,
        };
      });

    case ActionTypes.UPLOAD_ERROR:
      return state.map((file) =>
        file.id === action.payload.id
          ? {
              ...file,
              errors: [action.payload.error],
              status: UploadStatus.UploadError,
            }
          : file,
      );

    case ActionTypes.UPLOAD_LOADING:
      return state.map((file) =>
        file.id === action.payload.id
          ? {
              ...file,
              isLoading: action.payload.loading,
            }
          : file,
      );

    case ActionTypes.UPLOAD_SUCCESS:
      return state.map((file) =>
        file.id === action.payload.id
          ? { ...file, status: UploadStatus.Uploaded }
          : file,
      );

    case ActionTypes.UPLOAD_PROGRESS:
      return state.map((file) =>
        file.id === action.payload.id
          ? { ...file, progress: action.payload.progress }
          : file,
      );

    case ActionTypes.UPLOAD_CLEAR:
      return state.filter((file) => file.id !== action.payload.id);
    case ActionTypes.UPLOAD_CANCEL:
      return state.map((file) =>
        file.id === action.payload.id
          ? { ...file, status: UploadStatus.Canceled }
          : file,
      );

    default:
      return state;
  }
};
