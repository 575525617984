import { lazy } from 'react';

import { AUTH_PATHS, PUBLIC_PATHS } from 'common/routes/routerPaths';
import { Route } from 'common/types/route';

import UserSidebar from 'UserSidebar/UserSidebar';
import ProjectSidebar from 'Project/ProjectSidebar/ProjectSidebar';
import PricingEngineSidebar from 'PricingEngineSidebar/PricingEngineSidebar';

import BuildingInformation from 'ProjectProfile/BuildingInformation/BuildingInformation';
import ChangeLog from 'ProjectProfile/ChangeLog/ChangeLog';
import Notes from 'ProjectProfile/Notes/Notes';
import InternalNotes from 'ProjectProfile/InternalNotes/InternalNotes';
import PreDesign from 'ProjectProfile/PreDesign/PreDesign';
import ProjectInformation from 'ProjectProfile/ProjectInformation/ProjectInformation';

import ProjectProcurementMaterials from 'Project/Procurement/Materials/Materials';
import ProjectProcurementShipments from 'Project/Procurement/Shipments/Shipments';
import ProjectProcurementOrders from 'Project/Procurement/Orders/Orders';
import ProjectProcurementCreateOrder from 'Project/Procurement/CreateOrder/CreateOrder';
import ProjectProcurementEditOrder from 'Project/Procurement/EditOrder/EditOrder';
import ProjectProcurementEditMaterialsLeadTime from 'Project/Procurement/EditMaterialsLeadTime/EditMaterialsLeadTime';
import ProjectProcurementWarehouse from 'Project/Procurement/Warehouse/Warehouse';

const pricingEngineSidebarRoutes: Route[] = [
  {
    component: PricingEngineSidebar,
    path: AUTH_PATHS.getPricingEnginePath(),
  },
  {
    component: PricingEngineSidebar,
    path: AUTH_PATHS.getProjectOverviewPath(),
  },
  {
    component: PricingEngineSidebar,
    path: AUTH_PATHS.getProposalDetailsPath(),
  },
];

const projectSidebarRoutes: Route[] = [
  {
    component: ProjectSidebar,
    path: AUTH_PATHS.getProjectPath(),
  },
  {
    component: UserSidebar,
    path: AUTH_PATHS.getPaymentSchedulePath(),
  },
  {
    component: UserSidebar,
    path: AUTH_PATHS.getPayoutScheduleEntryPath(),
  },
  {
    component: UserSidebar,
    path: AUTH_PATHS.getProposalsPath(),
  },
  {
    component: UserSidebar,
    path: AUTH_PATHS.getUserPlanInformationPath(),
  },
  {
    component: UserSidebar,
    path: AUTH_PATHS.getProjectGalleryPath(),
  },
];

export const sidebarRoutes: Route[] = [
  ...pricingEngineSidebarRoutes,
  ...projectSidebarRoutes,
];

export const authRoutes: Route[] = [
  {
    component: lazy(() => import('Users/Users')),
    exact: true,
    path: AUTH_PATHS.USERS,
    title: 'Users | Block Admin',
  },
  {
    component: lazy(() => import('User/User')),
    path: AUTH_PATHS.getUserPath(),
    routes: [
      {
        component: lazy(() => import('PlanInformation/PlanInformation')),
        exact: true,
        path: AUTH_PATHS.getUserPlanInformationPath(),
        title: 'Plan Information',
      },
      {
        component: lazy(() => import('PaymentSchedule/PaymentSchedule')),
        exact: true,
        path: AUTH_PATHS.getPaymentSchedulePath(),
        title: 'Payment Schedule',
      },
      {
        component: lazy(
          () => import('PaymentScheduleTerm/PaymentScheduleTerm'),
        ),
        exact: true,
        path: AUTH_PATHS.getPaymentScheduleTermPath(),
        title: 'Payment Schedule Term',
      },
      {
        component: lazy(() => import('PayoutSchedule/PayoutSchedule')),
        exact: true,
        path: AUTH_PATHS.getPayoutScheduleEntryPath(),
        title: 'Payout Schedule',
      },
      {
        component: lazy(
          () =>
            import(
              'PayoutSchedule/PayoutScheduleDetails/PayoutScheduleDetails'
            ),
        ),
        exact: true,
        path: AUTH_PATHS.getPayoutSchedulePath(),
        title: 'Payout Schedule',
      },
      {
        component: lazy(() => import('PayoutScheduleTerm/PayoutScheduleTerm')),
        exact: true,
        path: AUTH_PATHS.getPayoutScheduleTermPath(),
        title: 'Payout Schedule Term',
      },
      {
        component: lazy(() => import('Proposals/Proposals')),
        exact: true,
        path: AUTH_PATHS.getProposalsPath(),
        title: 'Proposals',
      },
      {
        component: lazy(() => import('ProposalEdit/ProposalEdit')),
        exact: true,
        path: AUTH_PATHS.getProposalDetailsPath(),
        title: 'Proposal Details',
      },
      {
        component: lazy(
          () => import('ProposalScopeOfWork/ProposalScopeOfWork'),
        ),
        exact: true,
        path: AUTH_PATHS.getProposalScopeOfWorkPath(),
        title: 'Proposal Scope of Work',
      },
      {
        component: lazy(() => import('ProjectGallery/ProjectGallery')),
        exact: true,
        path: AUTH_PATHS.getProjectGalleryRoomPath(),
        title: 'Project Gallery | Block Admin',
      },
      {
        component: lazy(() => import('ProjectGallery/ProjectGallery')),
        exact: true,
        path: AUTH_PATHS.getProjectGalleryPath(),
        title: 'Project Gallery | Block Admin',
      },
    ],
    title: 'Block Admin',
  },
  {
    component: lazy(
      () => import('ProjectsVersionControl/ProjectsVersionControl'),
    ),
    exact: true,
    path: AUTH_PATHS.PROJECTS,
    title: 'Projects | Block Admin',
  },
  {
    component: lazy(() => import('Organizations/Organizations')),
    exact: true,
    path: AUTH_PATHS.ORGANIZATIONS,
    title: 'Organizations | Block Admin',
  },

  {
    component: lazy(() => import('Organization/Organization')),
    path: AUTH_PATHS.getOrganizationPath(),
    routes: [
      {
        component: lazy(() => import('Organization/Feedback/Feedback')),
        exact: true,
        path: AUTH_PATHS.getOrganizationFeedbackPath(),
        title: 'Organization | Block Admin',
      },
      {
        component: lazy(() => import('Organization/Details/Details')),
        exact: true,
        path: AUTH_PATHS.getOrganizationDetailsPath(),
        title: 'Organization | Block Admin',
      },
      {
        component: lazy(() => import('Organization/Preferences/Preferences')),
        exact: true,
        path: AUTH_PATHS.getOrganizationPreferencesPath(),
        title: 'Organization | Block Admin',
      },
    ],
    title: 'Organization | Block Admin',
  },
  {
    component: lazy(() => import('Project/Procurement/Procurement')),
    path: AUTH_PATHS.getProjectProcurementPath(),
    routes: [
      {
        component: ProjectProcurementMaterials,
        exact: true,
        path: AUTH_PATHS.getProjectProcurementMaterialsPath(),
        title: 'Procurement | Materials | Block Admin',
      },
      {
        component: ProjectProcurementShipments,
        exact: true,
        path: AUTH_PATHS.getProjectProcurementShipmentsPath(),
        title: 'Procurement | Shipments | Block Admin',
      },
      {
        component: ProjectProcurementOrders,
        exact: true,
        path: AUTH_PATHS.getProjectProcurementOrdersPath(),
        title: 'Procurement | Orders | Block Admin',
      },
      {
        component: ProjectProcurementCreateOrder,
        exact: true,
        path: AUTH_PATHS.getProjectProcurementCreateOrderPath(),
        title: 'Procurement | Create Order | Block Admin',
      },
      {
        component: ProjectProcurementEditOrder,
        exact: true,
        path: AUTH_PATHS.getProjectProcurementEditOrderPath(),
        title: 'Procurement | Edit Order | Block Admin',
      },
      {
        component: ProjectProcurementEditMaterialsLeadTime,
        exact: true,
        path: AUTH_PATHS.getProjectProcurementEditMaterialsLeadTimePath(),
        title: 'Procurement | Edit Materials | Block Admin',
      },
      {
        component: ProjectProcurementWarehouse,
        exact: true,
        path: AUTH_PATHS.getProjectProcurementWarehousePath(),
        title: 'Procurement | Warehouse | Block Admin',
      },
    ],
  },
  {
    component: lazy(() => import('ProjectProfile/ProjectProfile')),
    path: AUTH_PATHS.getProjectProfilePath(),
    routes: [
      {
        component: ProjectInformation,
        exact: true,
        path: AUTH_PATHS.getProjectProfileInformationPath(),
        title: 'Project Profile | Project Information | Block Admin',
      },
      {
        component: ChangeLog,
        exact: true,
        path: AUTH_PATHS.getProjectProfileChangeLogPath(),
        title: 'Project Profile | Change Log | Block Admin',
      },
      {
        component: BuildingInformation,
        exact: true,
        path: AUTH_PATHS.getProjectProfileBuildingInformationPath(),
        title: 'Project Profile | Building Information | Block Admin',
      },
      {
        component: PreDesign,
        exact: true,
        path: AUTH_PATHS.getProjectProfilePreDesignPath(),
        title: 'Project Profile | Pre-Design | Block Admin',
      },
      {
        component: Notes,
        exact: true,
        path: AUTH_PATHS.getProjectProfileNotesPath(),
        title: 'Project Profile | View All Notes | Block Admin',
      },
      {
        component: InternalNotes,
        exact: true,
        path: AUTH_PATHS.getProjectProfileInternalNotesPath(),
        title: 'Project Profile | Internal Notes | Block Admin',
      },
    ],
  },
  {
    component: lazy(() => import('Project/Performance/Performance')),
    exact: true,
    path: AUTH_PATHS.getProjectPerformancePath(),
    title: 'Performance | Block Admin',
  },
  {
    component: lazy(
      () => import('Project/ChangeOrdersFinancials/ChangeOrdersFinancials'),
    ),
    exact: true,
    path: AUTH_PATHS.getProjectChangeOrdersPath(),
    title: 'Change Orders & Financials | Block Admin',
  },
  {
    component: lazy(() => import('Project/Design/Design')),
    exact: true,
    path: AUTH_PATHS.getProjectDesignPath(),
    title: 'Designs | Block Admin',
  },
  {
    component: lazy(() => import('Project/PhotosVideos/PhotosVideos')),
    exact: true,
    path: AUTH_PATHS.getProjectPhotosAndVideosPath(),
    title: 'Photos & Videos | Block Admin',
  },
  {
    component: lazy(() => import('Project/ProcurementLinks/ProcurementLinks')),
    exact: true,
    path: AUTH_PATHS.getProjectProcurementLinksPath(),
    title: 'Procurement Links | Block Admin',
  },
  {
    component: lazy(() => import('Project/TeamMembers/TeamMembers')),
    exact: true,
    path: AUTH_PATHS.getProjectTeamMembersPath(),
    title: 'Team Members | Block Admin',
  },
  {
    component: lazy(() => import('Project/ProjectOverview/ProjectOverview')),
    exact: true,
    path: AUTH_PATHS.getProjectOverviewPath(),
    title: 'Project Overview | Block Admin',
  },
  {
    component: lazy(() => import('Schedule/ScheduleRouter')),
    exact: true,
    path: AUTH_PATHS.getProjectSchedulePath(),
    title: 'Schedule | Block Admin',
  },
  {
    component: lazy(
      () => import('Project/BuildingApprovals/BuildingApprovals'),
    ),
    exact: true,
    path: AUTH_PATHS.getProjectBuildingAndApprovalsPath(),
    title: 'Building Approvals | Block Admin',
  },
  {
    component: lazy(
      () =>
        import(
          'Project/BuildingApprovals/BuildingApprovalsScopeOfWork/BuildingApprovalsScopeOfWork'
        ),
    ),
    exact: true,
    path: AUTH_PATHS.getProjectScopeOfWorkPath(),
    title: 'Scope of Work | Block Admin',
  },
  {
    component: lazy(() => import('Project/WorkOrderLinks/WorkOrderLinks')),
    exact: true,
    path: AUTH_PATHS.getProjectWorkOrderPath(),
    title: 'Work Order | Block Admin',
  },
  {
    component: lazy(
      () => import('Project/ContractorDocuments/ContractorDocuments'),
    ),
    exact: true,
    path: AUTH_PATHS.getProjectContractorDocumentsPath(),
    title: 'Contractor Documents | Block Admin',
  },
  {
    component: lazy(() => import('Project/ScopeRevision/ScopeRevision')),
    path: AUTH_PATHS.getProjectScopeRevisionPath(),
    routes: [
      {
        component: lazy(
          () => import('Project/ScopeRevision/LaborMaterials/LaborMaterials'),
        ),
        exact: true,
        path: AUTH_PATHS.getProjectScopeRevisionLaborMaterialsPricingEnginePath(),
        title: 'Scope Revision Labor & Materials | Block Admin',
      },
      {
        component: lazy(() => import('Project/ScopeRevision/Permits/Permits')),
        exact: true,
        path: AUTH_PATHS.getProjectScopeRevisionPermitsPath(),
        title: 'Scope Revision Permits | Block Admin',
      },
      {
        component: lazy(() => import('Project/ScopeRevision/Rooms/Rooms')),
        exact: true,
        path: AUTH_PATHS.getProjectScopeRevisionRoomsPath(),
        title: 'Scope Revision Rooms | Block Admin',
      },
      {
        component: lazy(
          () => import('Project/ScopeRevision/RevisionLog/RevisionLog'),
        ),
        exact: true,
        path: AUTH_PATHS.getProjectScopeRevisionLogPath(),
        title: 'Scope Revision Log | Block Admin',
      },
      {
        component: lazy(
          () => import('Project/ScopeRevision/Revision/Revision'),
        ),
        exact: true,
        path: AUTH_PATHS.getProjectScopeRevisionDetails(),
        title: 'Scope Revision Details | Block Admin',
      },
    ],
    title: 'Project Scope Revision | Block Admin',
  },
  {
    component: lazy(() => import('Project/Revision/Revision')),
    path: AUTH_PATHS.getProjectRevisionPath(),
    routes: [
      {
        component: lazy(
          () => import('Project/Revision/LaborMaterials/LaborMaterials'),
        ),
        exact: true,
        path: AUTH_PATHS.getProjectRevisionLaborMaterialsPricingEnginePath(),
        title: 'Project Revision Labor & Materials | Block Admin',
      },
      {
        component: lazy(() => import('Project/Revision/Permits/Permits')),
        exact: true,
        path: AUTH_PATHS.getProjectRevisionPermitsPath(),
        title: 'Project Revision Permits | Block Admin',
      },
      {
        component: lazy(() => import('Project/Revision/Rooms/Rooms')),
        exact: true,
        path: AUTH_PATHS.getProjectRevisionRoomsPath(),
        title: 'Project Revision Rooms | Block Admin',
      },
      {
        component: lazy(() => import('Project/Revision/Summary/Summary')),
        exact: true,
        path: AUTH_PATHS.getProjectRevisionSummaryPath(),
        title: 'Project Revision Summary | Block Admin',
      },
    ],
    title: 'Project Revision | Block Admin',
  },
  {
    component: lazy(() => import('Contractors/Contractors')),
    exact: true,
    path: AUTH_PATHS.CONTRACTORS,
    title: 'Contractors | Block Admin',
  },
  {
    component: lazy(() => import('Contractor/ContractorAdd')),
    exact: true,
    path: AUTH_PATHS.CONTRACTOR_NEW,
    title: 'Contractor Add | Block Admin',
  },
  {
    component: lazy(() => import('Contractor/ContractorEdit')),
    exact: true,
    path: AUTH_PATHS.getContractorEditPath(),
    title: 'Contractor Edit | Block Admin',
  },
  {
    component: lazy(() => import('TeamMembers/TeamMembers')),
    exact: true,
    path: AUTH_PATHS.TEAM_MEMBERS,
    title: 'Team Members | Block Admin',
  },
  {
    component: lazy(() => import('TeamMember/TeamMemberAdd')),
    exact: true,
    path: AUTH_PATHS.TEAM_MEMBERS_NEW,
    title: 'Team Member | Add | Block Admin',
  },
  {
    component: lazy(() => import('TeamMember/TeamMemberEdit')),
    exact: true,
    path: AUTH_PATHS.getTeamMemberEditPath(),
    title: 'Team Member | Edit | Block Admin',
  },
  {
    component: lazy(() => import('UserAccounts/UserAccounts')),
    exact: true,
    path: AUTH_PATHS.USER_ACCOUNTS,
    title: 'User Accounts | Block Admin',
  },
  {
    component: lazy(() => import('UserAccounts/UserAccountAdd/UserAccountAdd')),
    exact: true,
    path: AUTH_PATHS.USER_ACCOUNTS_NEW,
    title: 'User Account | Add | Block Admin',
  },
  {
    component: lazy(() => import('PricingEngine/PricingEngine')),
    path: AUTH_PATHS.getPricingEnginePath(),
    routes: [
      {
        component: lazy(() => import('PricingEngine/Entry/Entry')),
        exact: true,
        path: AUTH_PATHS.getPricingEngineEntryPath(),
        title: 'Pricing Engine | Block Admin',
      },
      {
        component: lazy(() => import('PricingEngine/AddRoom/AddRoom')),
        exact: true,
        path: AUTH_PATHS.getPricingEngineAddRoomPath(),
        title: 'Add Room | Block Admin',
      },
      {
        component: lazy(() => import('PricingEngine/RoomInfo/RoomInfo')),
        exact: true,
        path: AUTH_PATHS.getPricingEngineRoomInfoPath(),
        title: 'Room Info | Block Admin',
      },
      {
        component: lazy(() => import('PricingEngine/SiteSurvey/SiteSurvey')),
        exact: true,
        path: AUTH_PATHS.getPricingEngineSiteSurveyPath(),
        title: 'Site Survey | Block Admin',
      },
      {
        component: lazy(
          () => import('PricingEngine/Measurements/Measurements'),
        ),
        exact: true,
        path: AUTH_PATHS.getPricingEngineMeasurementsPath(),
        title: 'Measurements | Block Admin',
      },
      {
        component: lazy(() => import('PricingEngine/Photos/Photos')),
        exact: true,
        path: AUTH_PATHS.getPricingEnginePhotosPath(),
        title: 'Photos | Block Admin',
      },
      {
        component: lazy(
          () =>
            import('PricingEngineLaborMaterials/PricingEngineLaborMaterials'),
        ),
        exact: true,
        path: AUTH_PATHS.getPricingEngineLaborMaterialsPath(),
        title: 'Labor & Materials | Block Admin',
      },
      {
        component: lazy(
          () => import('PricingEngine/LaborSummary/LaborSummary'),
        ),
        exact: true,
        path: AUTH_PATHS.getPricingEngineLaborSummaryPath(),
        title: 'Labor Summary | Block Admin',
      },
      {
        component: lazy(
          () => import('PricingEngine/MaterialsSummary/MaterialsSummary'),
        ),
        exact: true,
        path: AUTH_PATHS.getPricingEngineMaterialsSummaryPath(),
        title: 'Materials Summary | Block Admin',
      },
      {
        component: lazy(
          () =>
            import('PricingEngineEstimateSummary/PricingEngineEstimateSummary'),
        ),
        exact: true,
        path: AUTH_PATHS.getPricingEngineEstimateSummaryPath(),
        title: 'Estimate Summary | Block Admin',
      },
      {
        component: lazy(() => import('PricingEngine/Renders/Renders')),
        exact: true,
        path: AUTH_PATHS.getPricingEngineRendersPath(),
        title: 'Renders | Block Admin',
      },
      {
        component: lazy(() => import('PricingEngine/History/History')),
        exact: true,
        path: AUTH_PATHS.getPricingEngineHistoryPath(),
        title: 'History | Block Admin',
      },
    ],
    title: 'Pricing Engine | Block Admin',
  },
  {
    component: lazy(() => import('Notification/Notification')),
    exact: true,
    path: AUTH_PATHS.getNotificationsPath(),
    title: 'Notifications | Block Admin',
  },
  {
    component: lazy(() => import('NotificationAddNew/NotificationAddNew')),
    exact: true,
    path: AUTH_PATHS.getNotificationsAddPath(),
    title: 'Notification Add | Block Admin',
  },
  {
    component: lazy(() => import('NotificationEdit/NotificationEdit')),
    exact: true,
    path: AUTH_PATHS.getNotificationsEditPath(),
    title: 'Notification Edit | Block Admin',
  },
  {
    component: lazy(() => import('Notification/TaskNotification')),
    exact: true,
    path: AUTH_PATHS.getTaskNotificationsPath(),
    title: 'Notifications | Block Admin',
  },
  {
    component: lazy(() => import('NotificationAddNew/TaskNotificationAddNew')),
    exact: true,
    path: AUTH_PATHS.getTaskNotificationsAddPath(),
    title: 'Notification Add | Block Admin',
  },
  {
    component: lazy(() => import('NotificationEdit/TaskNotificationEdit')),
    exact: true,
    path: AUTH_PATHS.getTaskNotificationsEditPath(),
    title: 'Notification Edit | Block Admin',
  },
  {
    component: lazy(() => import('ScheduleTemplates/ScheduleTemplatesRouter')),
    path: AUTH_PATHS.SCHEDULE_TEMPLATES,
    routes: [
      {
        component: lazy(() => import('ScheduleTemplates/ScheduleTemplates')),
        exact: true,
        path: AUTH_PATHS.SCHEDULE_TEMPLATES,
        title: 'Schedule Templates | Block Admin',
      },
      {
        component: lazy(() => import('ScheduleTemplates/GenericItems')),
        exact: true,
        path: AUTH_PATHS.SCHEDULE_TEMPLATES_GENERIC_ITEMS,
        title: 'Schedule Templates | Generic Items | Block Admin',
      },
    ],
    title: 'Schedule Templates | Block Admin',
  },
  {
    component: lazy(() => import('SchedulesAll/SchedulesAll')),
    exact: true,
    path: AUTH_PATHS.ALL_SCHEDULES,
    title: 'All Schedules | Block Admin',
  },
  {
    component: lazy(() => import('WorkOrder/WorkOrder')),
    exact: true,
    path: AUTH_PATHS.getWorkOrderPath(),
    title: 'Work Order | Block Admin',
  },
  {
    component: lazy(() => import('WorkOrderIssue/WorkOrderIssue')),
    exact: true,
    path: AUTH_PATHS.getIssueWorkOrderPath(),
    title: 'Work Order | Issue | Block Admin',
  },
  {
    component: lazy(() => import('WorkOrderIssue/WorkOrderIssue')),
    exact: true,
    path: AUTH_PATHS.getContractorRematchWorkOrderPath(),
    title: 'Work Order | Contractor Rematch | Block Admin',
  },
  {
    component: lazy(() => import('ContentManagement/ContentManagement')),
    exact: true,
    path: AUTH_PATHS.WORK_ORDER_CONTENT,
    title: 'Work Order | Content | Block Admin',
  },
  {
    component: lazy(() => import('MaterialsCatalog/Products/Products')),
    exact: true,
    path: AUTH_PATHS.MATERIALS_CATALOG,
    title: 'Materials Catalog | Block Admin',
  },
  {
    component: lazy(() => import('MaterialsCatalog/Product/Product')),
    exact: true,
    path: AUTH_PATHS.getMaterialsCatalogProductPath(),
    title: 'Materials Catalog | Block Admin',
  },
  {
    component: lazy(() => import('MaterialsCatalog/BulkUpload/BulkUpload')),
    exact: true,
    path: AUTH_PATHS.getMaterialsCatalogBulkUploadPath(),
    title: 'Materials Catalog | Block Admin',
  },
  {
    component: lazy(() => import('Payouts/Payouts')),
    exact: true,
    path: AUTH_PATHS.PAYOUTS,
    title: 'Payouts | Block Admin',
  },
  {
    component: lazy(() => import('Payouts/PayoutDetails/PayoutDetails')),
    exact: true,
    path: AUTH_PATHS.getPayoutDetailsPath(),
    title: 'Payout details | Block Admin',
  },
  {
    component: lazy(
      () =>
        import(
          'MaterialsCatalog/BulkUpload/BulkUploadSteps/AddBulkUploadSteps'
        ),
    ),
    exact: true,
    hideSidebar: true,
    path: AUTH_PATHS.getMaterialsCatalogBulkUploadAddProductsPath(),
    title: 'Bulk Upload',
  },
  {
    component: lazy(
      () =>
        import(
          'MaterialsCatalog/BulkUpload/BulkUploadSteps/EditBulkUploadSteps'
        ),
    ),
    exact: true,
    hideSidebar: true,
    path: AUTH_PATHS.getMaterialsCatalogBulkUploadEditProductsPath(),
    title: 'Bulk Upload Edit',
  },
];

export const publicRoutes: Route[] = [
  { component: lazy(() => import('Login/Login')), path: PUBLIC_PATHS.LOG_IN },
  {
    component: lazy(() => import('ResetPassword/ResetPassword')),
    path: PUBLIC_PATHS.RESET_PASSWORD,
  },
];
