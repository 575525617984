import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';

import { SelectType } from 'common/types/select';
import { useOnClickOutside } from 'common/hooks/useOnClickOutside';

import { SelectEvent } from './select.types';

export const useSelect = (
  id: string,
  onChange: (event: SelectEvent) => void,
  options: SelectType[],
  initialValue: string,
) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [selectedOption, setSelectedOption] = useState<SelectType | null>(null);
  const [searchPhase, setSearchPhase] = useState<string>('');
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setSelectedIndex(-1);
    setIsOpen(false);
  };

  useEffect(() => {
    const preSelectedOption = options?.find(
      (option) => option?.value === initialValue,
    );
    if (inputRef.current && !!options.length && preSelectedOption) {
      inputRef.current.value = preSelectedOption?.label || '';
      setSelectedOption(preSelectedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialValue || !inputRef.current) return;

    inputRef.current.value = '';

    setSelectedOption(null);
  }, [initialValue]);

  useEffect(() => {
    if (!initialValue || !inputRef.current) return;

    const option = options.find(({ value }) => value === initialValue);

    if (!option) return;

    inputRef.current.value = option.label;

    setSelectedOption(option);
  }, [initialValue, options]);

  useOnClickOutside(wrapperRef, handleClose);

  const setValue = (value: SelectType | null) => {
    onChange({ id, value: value?.value || '' });

    if (inputRef?.current) inputRef.current.value = value?.label || '';

    setSelectedOption(value);
    setSearchPhase('');
  };

  const onOptionClicked = (value: SelectType) => () => {
    setValue(value);
    handleClose();
  };

  const handleTextInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchPhase(event.target.value);
  };

  const filteredOptions = searchPhase
    ? options.filter((option) =>
        option.label
          .toLocaleLowerCase()
          .includes(searchPhase.toLocaleLowerCase()),
      )
    : options;

  const clearInputValue = () => {
    setValue(null);
  };
  // TODO move me to separate hook
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      inputRef?.current?.blur();
      handleClose();
    }

    if (
      event.code === 'ArrowDown' &&
      filteredOptions.length >= selectedIndex - 1
    ) {
      const elementToScroll = listRef?.current?.children[selectedIndex + 1];
      elementToScroll?.scrollIntoView({
        block: 'end',
      });
      setSelectedIndex(selectedIndex + 1);
    }

    if (event.code === 'ArrowUp' && selectedIndex > 0) {
      const elementToScroll = listRef?.current?.children[selectedIndex - 1];
      elementToScroll?.scrollIntoView({
        block: 'end',
      });
      setSelectedIndex(selectedIndex - 1);
    }

    if (event.code === 'Enter' && filteredOptions[selectedIndex]) {
      const selectedOptionInput = filteredOptions[selectedIndex];
      setSelectedOption(selectedOption);
      setSearchPhase('');
      inputRef?.current?.blur();

      setValue(selectedOptionInput);
      handleClose();
    }
  };

  const handleOptionMouseOver = (index: number) => () => {
    setSelectedIndex(index);
  };

  return {
    clearInputValue,
    filteredOptions,
    handleClose,
    handleKeyDown,
    handleOpen,
    handleOptionMouseOver,
    handleTextInputChange,
    inputRef,
    isOpen,
    listRef,
    onOptionClicked,
    selectedIndex,
    selectedOption,
    wrapperRef,
  };
};
