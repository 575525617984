import { useParams } from 'react-router-dom';

import { ADMIN_NAVIGATION_MENU_CLICKED } from 'common/consts/segmentEvents';
import { useAuth } from 'common/hooks/AuthenticationProvider/authenticationProvider.hooks';
import { sendEvent } from 'common/utils/segment';

type Props = {
  name: string;
};

type SidebarItemQueryParams = {
  projectId?: string;
  renovationPlanId?: string;
};

export const useSidebarContentListItem = ({ name }: Props) => {
  const { currentUser } = useAuth();
  const { projectId, renovationPlanId } = useParams<SidebarItemQueryParams>();

  const handleClick = () =>
    sendEvent(ADMIN_NAVIGATION_MENU_CLICKED, {
      adminUserId: currentUser?.id,
      name,
      renovationPlanId: projectId || renovationPlanId || '',
    });

  return {
    handleClick,
  };
};
