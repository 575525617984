type UtilOptions = {
  hasCurrencyIndicator?: boolean;
  isCents?: boolean;
  skipFraction?: boolean;
};

export const floatToCurrencyString = (money: number, options?: UtilOptions) => {
  const isCents = options?.isCents ?? true;
  const precision = isCents ? 2 : 0;
  const hasCents = money % Math.pow(10, precision) !== 0;
  const skipFraction = options?.skipFraction ?? false;
  const fractionLevel = skipFraction || !hasCents ? 0 : 2;
  const hasCurrencyIndicator = options?.hasCurrencyIndicator ?? true;

  const actualValue = isCents ? money / 100 : money;

  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: fractionLevel,
    minimumFractionDigits: fractionLevel,
    ...(hasCurrencyIndicator && { style: 'currency' }),
  }).format(actualValue);
};

export const convertToCents = (value: number) => (value / 100).toString();

export const getDiscountIndicator = (value: number) => (value ? '-' : '');
