import {
  calculateDaysLate,
  dateTimeToUSFormatWOSeconds,
  dateToUSFormat,
  getDaysLateStr,
} from 'common/utils/date';

import { KeyDateChangeEvent } from 'common/apollo/models/keyDateChangeEvent';
import { KeyDate } from 'common/apollo/models/keyDate';
import { KeyDateSource } from 'common/apollo/enums/KeyDateSource';

import { filterRecentHistory } from 'ClientTasks/TargetDate/TargetDateHistoryList/TargetDateHistoryList.utils';

export const getBreakgroundHistory = (
  breakgroundEnabled: boolean,
  keyDateChangeEvents: KeyDateChangeEvent[],
  keyDates: KeyDate[],
) => {
  const history: Array<Record<string, string>> = [];

  const { confirmedDate, recentHistory } = populateRecentHistory(
    keyDateChangeEvents,
    keyDates,
  );

  recentHistory.forEach((entry, index) => {
    if (entry) {
      const clientViewValue = getClientViewValue(keyDates, entry.source);
      const targetDateFormat = dateToUSFormat(entry.date);

      const updatedDateFormat = dateTimeToUSFormatWOSeconds(entry.updatedAt);

      const [updatedDateStr, time, period] = updatedDateFormat.split(' ');
      const user = entry.user ? entry.user.name : 'System';

      let targetDateStr = '';
      let setTargetDateStr = '';
      if (entry.source === KeyDateSource.ORIGINAL) {
        targetDateStr = `Original Target Date: ${targetDateFormat}`;
        setTargetDateStr = `Set ${updatedDateStr} at ${time} ${period} by ${user}`;
      } else if (entry.source === KeyDateSource.LATEST) {
        targetDateStr = `Updated Target Date: ${targetDateFormat}`;
        setTargetDateStr = `Shifted ${updatedDateStr} at ${time}${period} by ${user}`;
      } else {
        targetDateStr = `Confirmed Break Ground Date: ${targetDateFormat}`;
        setTargetDateStr = `Confirmed ${updatedDateStr} at ${time}${period} by ${user}`;
      }

      const numDaysLate = calculateDaysLate(entry.date);
      const daysLateStr = getDaysLateStr(numDaysLate);

      history.unshift({
        daysLateText: daysLateStr,
        setTargetDateText: setTargetDateStr,
        targetDate: clientViewValue || targetDateFormat,
        targetDateText: targetDateStr,
      });
    }
  });

  const showAddConfirmedDate = breakgroundEnabled && !confirmedDate;

  return {
    history,
    showAddConfirmedDate,
  };
};

const populateRecentHistory = (
  dataList: KeyDateChangeEvent[],
  keyDates: KeyDate[],
) => {
  const recentHistory = filterRecentHistory(dataList);
  const confirmedKeyDate = dataList.find(
    (keyDate) => keyDate.source === KeyDateSource.CONFIRMED,
  );

  const confirmedDate = confirmedKeyDate?.date ? confirmedKeyDate : null;
  if (confirmedDate) recentHistory.unshift(confirmedKeyDate);

  return { confirmedDate, recentHistory };
};

const getClientViewValue = (
  keyDates: KeyDate[],
  keyDateSource: KeyDateSource,
) => {
  // keyDates does not have an entry for KeyDateSource.ORIGINAL, must grab KeyDateSource.LATEST
  if (keyDateSource === KeyDateSource.ORIGINAL) {
    const keyDate = keyDates.find(
      (entry) => entry.source === KeyDateSource.LATEST,
    );
    return keyDate?.value;
  }

  const keyDate = keyDates.find((entry) => entry.source === keyDateSource);
  return keyDate?.value;
};
