import { CollapsibleContainer } from 'ProjectProfile/common/components/CollapsibleContainer/CollapsibleContainer';
import {
  Content,
  LookWrapper,
  RoomWrapper,
  Title,
} from 'ProjectProfile/PreDesign/Looks/Looks.styles';
import { Look } from 'common/shared/Look/Look';

import noImage from 'common/assets/icons/no-image.svg';

import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';
import { SelectedLook } from 'common/apollo/models/selectedLook';

type Room = {
  id: string;
  name: string;
  selectedLook?: SelectedLook | null;
};

type Props = {
  isHomeownerRooms?: boolean;
  isLoading?: boolean;
  rooms: Room[];
};

export const Looks = ({ isHomeownerRooms, isLoading, rooms }: Props) => (
  <CollapsibleContainer
    title={
      isHomeownerRooms ? "Homeowner's Selected Looks From the Quiz" : 'Looks'
    }
  >
    {isLoading ? (
      <LoadingSpinnerWithContainer />
    ) : (
      <Content>
        {rooms.map(({ id, name, selectedLook }) => {
          const look = selectedLook?.look;

          return (
            <RoomWrapper key={id}>
              <Title>{name}</Title>
              {selectedLook && look ? (
                <LookWrapper>
                  <Look
                    collectionName={look.collection.name}
                    imageUrl={
                      look.images.length ? look.images[0].file.url : noImage
                    }
                    importedByUser={selectedLook.addedBy}
                    isImported={!isHomeownerRooms}
                    name={look.name}
                    properties={look.properties}
                    tags={look.collection.tags.map(
                      (collectionTag) => collectionTag.tag.displayName,
                    )}
                  />
                </LookWrapper>
              ) : (
                <>N/A</>
              )}
            </RoomWrapper>
          );
        })}
      </Content>
    )}
  </CollapsibleContainer>
);
