import styled from '@emotion/styled';

export const Footer = styled.footer`
  align-items: center;
  background: ${({ theme }) => theme.color.white};
  border-top: 1px solid ${({ theme }) => theme.color.alto};
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  font: 400 14px/24px ${({ theme }) => theme.font.inter};
  gap: 16px;
  gap: 16px;
  justify-content: space-between;
  margin-top: auto;
  padding: 16px 24px;
  position: sticky;
  z-index: 2;
`;
