import { css } from '@emotion/react';

import styled from '@emotion/styled';

import IconButton from '@material-ui/core/IconButton';

import { mq } from 'common/utils/mediaQueries';

export const Label = styled.span`
  padding: 10px;
  font: 500 14px/18px ${({ theme }) => theme.font.inter};
  letter-spacing: 1px;
  text-transform: uppercase;

  display: flex;
  align-items: center;

  ${mq.medium(css`
    padding-left: 20px 33px;
  `)};
`;

export const Root = styled.section`
  border: 1px solid ${({ theme }) => theme.color.black};

  .slide img {
    display: block;
    object-fit: cover;
    max-width: 100%;
    height: 174px;

    ${mq.medium(css`
      height: 320px;
    `)};
  }
`;

export const NextButton = styled(IconButton)`
  position: absolute;
  top: calc(50% - 29px);
  right: 0;
  z-index: 1;

  svg {
    height: 24px;

    ${mq.medium(css`
      height: 34px;
    `)};
  }
`;

export const PrevButton = styled(IconButton)`
  position: absolute;
  top: calc(50% - 29px);
  left: 0;
  z-index: 1;

  svg {
    height: 24px;

    ${mq.medium(css`
      height: 34px;
    `)};
  }
`;

export const EmptyState = styled.section`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.alto};
  color: ${({ theme }) => theme.color.gray};
  font: 600 12px/18px ${({ theme }) => theme.font.inter};
  letter-spacing: 1px;
  text-transform: uppercase;
  min-height: 320px;
`;
