import { Form, Formik } from 'formik';

import { Dialog, DialogTitle, Grid } from '@material-ui/core';

import { ModalCloseButton } from 'common/components/Modals/ModalCloseButton';
import { Button } from 'common/components/Buttons/Button/Button';
import { FormTextArea } from 'common/_components/Form/FormTextarea/FormTextarea';

import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

import {
  DialogActions,
  DialogContent,
} from 'ProjectProfile/common/components/EditForm/editForm.styles';
import { UpdateProjectPlanQuestionsResponse } from 'ProjectProfile/common/responses/updateProjectPlanQuestionsResponse';
import { FlagToggle } from 'ProjectProfile/common/components/FlagToggle/FlagToggle';

import { Answers } from 'ProjectProfile/common/types/answer';

import { usePreKickoffEdit } from './preKickoffEdit.hooks';
import { validationSchema } from './preKickoffEdit.consts';

type Props = {
  close: () => void;
  handleSuccessfulSubmit: (data: UpdateProjectPlanQuestionsResponse) => void;
};

export const PreKickoffEdit = ({ close, handleSuccessfulSubmit }: Props) => {
  const { importances, initialValues, placeholders, submit } =
    usePreKickoffEdit();

  const handleSubmit = (values: Answers) => submit(values, importances, close);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onBackdropClick={close}
      onEscapeKeyDown={close}
      open
    >
      <DialogTitle>Pre-Kickoff</DialogTitle>
      <ModalCloseButton onClick={close} />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogContent scrollable>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <FormTextArea
                    disabled={isSubmitting}
                    id={PropertyGroupIdentifier.CONCEPT_BOARD_NOTES}
                    name={PropertyGroupIdentifier.CONCEPT_BOARD_NOTES}
                    placeholder="Concept Board Notes"
                    rows={5}
                  />
                  <FlagToggle
                    disabled={isSubmitting}
                    isOn={importances.CONCEPT_BOARD_NOTES.isOn}
                    onToggle={importances.CONCEPT_BOARD_NOTES.toggle}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <FormTextArea
                    disabled={isSubmitting}
                    id={PropertyGroupIdentifier.MATERIAL_PRICE_NOTES}
                    name={PropertyGroupIdentifier.MATERIAL_PRICE_NOTES}
                    placeholder="Material Price Notes"
                    rows={5}
                  />
                  <FlagToggle
                    disabled={isSubmitting}
                    isOn={importances.MATERIAL_PRICE_NOTES.isOn}
                    onToggle={importances.MATERIAL_PRICE_NOTES.toggle}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormTextArea
                    disabled={isSubmitting}
                    id={PropertyGroupIdentifier.BEFORE_DESIGN}
                    label="Before Design"
                    name={PropertyGroupIdentifier.BEFORE_DESIGN}
                    placeholder={placeholders.BEFORE_DESIGN}
                    rows={5}
                  />
                  <FlagToggle
                    disabled={isSubmitting}
                    isOn={importances.BEFORE_DESIGN.isOn}
                    onToggle={importances.BEFORE_DESIGN.toggle}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormTextArea
                    disabled={isSubmitting}
                    id={PropertyGroupIdentifier.NEEDS_VS_NICE_TO_HAVES}
                    label="Needs vs. Nice to Haves"
                    name={PropertyGroupIdentifier.NEEDS_VS_NICE_TO_HAVES}
                    placeholder={placeholders.NEEDS_VS_NICE_TO_HAVES}
                    rows={5}
                  />
                  <FlagToggle
                    disabled={isSubmitting}
                    isOn={importances.NEEDS_VS_NICE_TO_HAVES.isOn}
                    onToggle={importances.NEEDS_VS_NICE_TO_HAVES.toggle}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
