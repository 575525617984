import { useFormikContext, getIn } from 'formik';

export const useHasFormError = <T>(name: string, requiredTouched = true) => {
  const { errors, submitCount, touched } = useFormikContext<T>();

  const isTouched = getIn(touched, name) || submitCount > 0;
  const error = getIn(errors, name) || errors[name as keyof typeof errors];

  return (isTouched || !requiredTouched) && error;
};
