import { useAddBatch } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/addBatchRow.hooks';

import { OrderItem } from 'common/apollo/models/orderItem';

import {
  Cell,
  Row,
  Wrapper,
} from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/batchRow.styles';
import { BatchColumnWidthMap } from 'Project/Procurement/Warehouse/warehouse.consts';
import { BatchRowEditable } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/BatchRowEditable';

export type Props = {
  isAdding: boolean;
  onAdd: () => void;
  onCancelAdd: () => void;
  orderItem: OrderItem;
  unitsRemaining: number;
};

export const AddBatchRow = ({
  isAdding,
  onAdd,
  onCancelAdd,
  orderItem,
  unitsRemaining,
}: Props) => {
  const { handleCreate } = useAddBatch(orderItem, onCancelAdd);

  return isAdding ? (
    <BatchRowEditable
      confirmedAt={null}
      eta={null}
      id=""
      inventoryUnits={0}
      number={0}
      onCancelEdit={onCancelAdd}
      onSave={handleCreate}
      pallet={null}
      receivedAt={null}
      shippedAt={null}
      units={0}
      unitsRemaining={unitsRemaining}
    />
  ) : (
    <Wrapper isAction onClick={onAdd}>
      <Row>
        <Cell cellWidth={BatchColumnWidthMap.ACTION_MENU} />
        <Cell cellWidth={BatchColumnWidthMap.CHECKBOX} />
        <Cell cellWidth={BatchColumnWidthMap.CONFIRMED_BY} isAction>
          + Add Batch
        </Cell>
      </Row>
    </Wrapper>
  );
};
