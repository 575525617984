import { createContext, ReactNode, useMemo } from 'react';

import { AnchorType, ANCHOR_TYPE, VariantType } from './sidebar.types';

type SidebarState = {
  anchor: AnchorType;
  isOpen: boolean;
  onOpenToggle?: () => void;
  variant?: VariantType;
};

export const SidebarContext = createContext<SidebarState>({
  anchor: ANCHOR_TYPE.left,
  isOpen: false,
});

type Props = {
  anchor?: AnchorType;
  children: ReactNode;
  onOpenToggle?: () => void;
  open?: boolean;
  variant?: VariantType;
};

export const SidebarProvider = ({
  anchor = ANCHOR_TYPE.left,
  children,
  onOpenToggle,
  open = false,
  variant,
}: Props) => {
  const value = useMemo(
    () => ({
      anchor,
      isOpen: open,
      onOpenToggle,
      variant,
    }),
    [anchor, onOpenToggle, open, variant],
  );

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};
