import { Button } from 'common/components/Button/Button';

import { OrderMaterial } from 'Project/Procurement/common/types/orderMaterial';

import {
  Footer,
  Title,
  Wrapper,
} from 'Project/Procurement/EditOrder/OrderMaterials/DeleteMaterial/deleteMaterial.styles';

import { useDeleteMaterial } from 'Project/Procurement/EditOrder/OrderMaterials/DeleteMaterial/deleteMaterial.hooks';

type Props = {
  material: OrderMaterial | null;
};

export const DeleteMaterial = ({ material }: Props) => {
  const { handleClose, handleDeleteMaterial } = useDeleteMaterial(material?.id);

  return (
    <Wrapper>
      <Title>Are you sure you want to cancel this order item?</Title>

      <Footer>
        <Button onClick={handleClose} small>
          Go Back
        </Button>

        <Button
          color="secondary"
          onClick={handleDeleteMaterial}
          small
          variant="contained"
        >
          Yes, I'm sure
        </Button>
      </Footer>
    </Wrapper>
  );
};
