import { StageIdentifier } from 'common/apollo/enums/StageIdentifier';
import { Tag } from 'common/shared/Tag/Tag';

import { useTargetDateHistory } from 'ClientTasks/TargetDate/TargetDateHistoryList/TargetDateHistoryList.hooks';
import { useConfirmDate } from 'ClientTasks/TargetDate/ConfirmTargetDateModal/confirmTargetDateModal.hooks';

import {
  Divider,
  HistoryIcon,
  HistoryText,
  HistoryTextWrapper,
  HistoryViewButton,
  TableHeader,
  TableRowHeader,
  TableRowWrapper,
  TableWrapper,
} from 'ClientTasks/TargetDate/TargetDateHistoryList/targetDateHistoryList.styles';

import { getBreakgroundHistory } from 'ClientTasks/TargetDate/TargetDateHistoryList/BreakgroundHistory/breakgroundHistory.utils';

import { BreakgroundHistoryRow } from 'ClientTasks/TargetDate/TargetDateHistoryList/BreakgroundHistory/BreakgroundHistoryRow';
import { KeyDate } from 'common/apollo/models/keyDate';

type Props = {
  isCompleted: boolean;
  keyDates: KeyDate[];
  stageName: StageIdentifier;
};

export const BreakgroundHistory = ({
  isCompleted,
  keyDates,
  stageName,
}: Props) => {
  const {
    breakgroundEnabled,
    handleViewFullHistoryClick,
    keyDateChangeEvents,
  } = useTargetDateHistory(stageName);

  const { history, showAddConfirmedDate } = getBreakgroundHistory(
    breakgroundEnabled,
    keyDateChangeEvents,
    keyDates,
  );

  const { handleConfirmBreakgroundDate } = useConfirmDate(stageName);

  return (
    <TableWrapper>
      <TableRowHeader>
        <TableHeader>Target Date History</TableHeader>
        {!!history.length && (
          <HistoryViewButton onClick={handleViewFullHistoryClick}>
            <HistoryIcon />
            View Full History
          </HistoryViewButton>
        )}
      </TableRowHeader>
      <Divider />
      {history.length ? (
        <>
          <BreakgroundHistoryRow
            history={history}
            isCompleted={isCompleted}
            showAddConfirmedDate={showAddConfirmedDate}
          />
          {showAddConfirmedDate && (
            <>
              <Divider />
              <TableRowWrapper>
                <HistoryTextWrapper>
                  <HistoryText type="h6">
                    Confirmed Break Ground Date: TBD
                  </HistoryText>
                </HistoryTextWrapper>
                <Tag
                  onClick={handleConfirmBreakgroundDate}
                  variant="addConfirm"
                >
                  Add Confirmed Date
                </Tag>
              </TableRowWrapper>
            </>
          )}
        </>
      ) : (
        <TableRowWrapper>Target Date: No target date set</TableRowWrapper>
      )}
    </TableWrapper>
  );
};
