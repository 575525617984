import { useState } from 'react';

import { useToggle } from 'common/hooks/useToggle';

import { useOrderTableAction } from 'Project/Procurement/common/hooks/useOrderTableAction';

import { OrderMaterial } from 'Project/Procurement/common/types/orderMaterial';

export const useOrderMaterials = (materials: OrderMaterial[]) => {
  const [selectedMaterial, setSelectedMaterial] =
    useState<OrderMaterial | null>(null);

  const {
    isOn: isOrderMaterialUnitsUpdateModalOpen,
    toggleOff: closeOrderMaterialUnitsUpdateModal,
    toggleOn: openOrderMaterialUnitsUpdateModal,
  } = useToggle();

  const { handleCopySkuInfo, handleCopyTable } = useOrderTableAction(materials);

  const handleOpenUpdateMaterialModal = (id: string) => {
    const material = materials.find((item) => item.id === id);

    if (!material) return;

    setSelectedMaterial(material);
    openOrderMaterialUnitsUpdateModal();
  };

  const handleCloseUpdateMaterialUnitsModal = () => {
    setSelectedMaterial(null);
    closeOrderMaterialUnitsUpdateModal();
  };

  return {
    handleCloseUpdateMaterialUnitsModal,
    handleCopySkuInfo,
    handleCopyTable,
    handleOpenUpdateMaterialModal,
    isOrderMaterialUnitsUpdateModalOpen,
    selectedMaterial,
  };
};
