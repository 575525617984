import { ReactElement } from 'react';

import {
  Label,
  Wrapper,
  Value,
} from 'common/components/TextDisplay/textDisplay.styles';

type Props = {
  label: string;
  value?: string;
  valueDisplayComponent?: ReactElement | null;
  valueFontWeight?: number;
};

export const TextDisplay = ({
  label,
  value,
  valueDisplayComponent,
  valueFontWeight = 400,
}: Props) => (
  <Wrapper>
    <Label>{label}</Label>
    {valueDisplayComponent || (
      <Value style={{ fontWeight: valueFontWeight }} type="h6">
        {value}
      </Value>
    )}
  </Wrapper>
);
