import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Typography, { TypographyProps } from '@material-ui/core/Typography';

import { THEME } from 'common/consts/theme';

import { TypographyCustomType } from './typographyCustom.types';

import { Props } from './Typography';

type TypographyType =
  | NonNullable<TypographyProps['variant']>
  | TypographyCustomType;

type StyleByType = {
  [key in TypographyType]?: string;
};

const STYLE_BY_TYPE: StyleByType = {
  'h1-500': `500 24px/32px ${THEME.font.roboto}`,
  'h1-normal': `normal 24px/32px ${THEME.font.roboto}`,
  h3: `normal 500 24px/32px ${THEME.font.inter}`,
  'h3-bold': `700 16px/24px ${THEME.font.roboto}`,
  'h3-normal': `normal 16px/24px ${THEME.font.roboto}`,
  h4: `normal 500 20px/28px ${THEME.font.inter}`,
  h5: `normal 500 16px/24px ${THEME.font.inter}`,
  h6: `normal 500 14px/20px ${THEME.font.inter}`,
  strong: `700 14px/1.71 ${THEME.font.roboto}`,
  'xl-bold': `700 32px/32px ${THEME.font.roboto}`,
};

export const MuiTypography = styled(Typography)<Props>`
  ${({ type }) =>
    type &&
    STYLE_BY_TYPE[type] &&
    css`
      &.MuiTypography-root {
        font: ${STYLE_BY_TYPE[type]};
      }
    `}
`;
