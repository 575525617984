import { useQuery } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';

import { GetProjectPlanRoomsLinksRequest } from 'ProjectProfile/common/requests/getProjectPlanRoomsLinksRequest';
import { GetProjectPlanRoomsLinksResponse } from 'ProjectProfile/common/responses/getProjectPlanRoomsLinksResponse';

import { GET_PROJECT_PLAN_ROOMS_LINKS } from 'ProjectProfile/common//apollo/gql/getProjectPlanRoomsLinks';

type Props = {
  pricingEngineIds: string[];
};

export const useProjectPlanRoomsLinks = ({ pricingEngineIds }: Props) => {
  const { onError } = useAlert();
  const { data, loading } = useQuery<
    GetProjectPlanRoomsLinksResponse,
    GetProjectPlanRoomsLinksRequest
  >(GET_PROJECT_PLAN_ROOMS_LINKS, {
    onError: (error) => onError(error.message),
    skip: !pricingEngineIds,
    variables: {
      input: {
        pricingEngineIds,
      },
    },
  });

  const isLoadingPlanRoomsLinks = !data && loading;

  return {
    isLoadingPlanRoomsLinks,
    projectPlanRoomsLinks: data?.projectPlanRoomsLinks || [],
  };
};
