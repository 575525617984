import { ClickAwayListener } from '@material-ui/core';

import { Button } from 'common/components/Button/Button';
import {
  useFilters,
  useSortFilters,
} from 'common/components/FiltersMenu/filtersMenu.hooks';
import { BaseFilter } from 'common/components/Filters/filters.types';

import {
  ClearAllFilters,
  Collapse,
  List,
  ListTitle,
  Wrapper,
  WrapperInner,
} from 'common/components/FiltersMenu/filtersMenu.styles';
import { ToggleFilterMenuItem } from 'common/components/FiltersMenu/ToggleFilter/ToggleFilter';
import { FilterItemMap } from 'common/components/FiltersMenu/filtersMenu.consts';
import { FilterCounter } from 'common/components/FiltersMenu/common/filterMenuItem.styles';
import { ExpandedIcon } from 'common/components/Icons/Icons';
import { Size } from 'common/components/Button/button.types';

type Props = {
  allFilters: BaseFilter[];
  catalogItemsOnlyFilter?: boolean;
  clearAllFilters: () => void;
  handleCatalogItemsOnlyFilterChange?: () => void;
  handleProjectCompatibileItemsOnlyFilterChange?: () => void;
  handleSelectFilter: (filter: BaseFilter) => void;
  isDisabled?: boolean;
  projectCompatibleItemsOnlyFilter?: boolean;
  rightAligned?: boolean;
  selectedFilters: BaseFilter[];
  size?: Size;
};

export const FiltersMenu = ({
  allFilters,
  catalogItemsOnlyFilter,
  clearAllFilters,
  handleCatalogItemsOnlyFilterChange,
  handleProjectCompatibileItemsOnlyFilterChange,
  handleSelectFilter,
  isDisabled,
  projectCompatibleItemsOnlyFilter,
  rightAligned,
  selectedFilters,
  size = 'small',
}: Props) => {
  const {
    buttonWidth,
    containerRef,
    handleClickAway,
    isOpen,
    leftOffset,
    toggle,
    topOffset,
  } = useFilters();

  useSortFilters(allFilters);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Wrapper ref={containerRef}>
        <Button
          disabled={isDisabled}
          onClick={toggle}
          size={size}
          type="button"
        >
          Filters
          {!!selectedFilters.length && (
            <FilterCounter role="img">{selectedFilters.length}</FilterCounter>
          )}
          <ExpandedIcon open={isOpen} />
        </Button>

        <Collapse
          buttonWidth={buttonWidth}
          in={isOpen}
          left={leftOffset}
          rightAligned={rightAligned}
          top={topOffset}
          unmountOnExit
        >
          <WrapperInner>
            <List>
              <ListTitle>
                Filters
                {!!selectedFilters.length && (
                  <ClearAllFilters onClick={clearAllFilters}>
                    Clear All
                  </ClearAllFilters>
                )}
              </ListTitle>
              {handleCatalogItemsOnlyFilterChange && (
                <ToggleFilterMenuItem
                  isOn={!!catalogItemsOnlyFilter}
                  label="Catalog Items"
                  onToggle={handleCatalogItemsOnlyFilterChange}
                />
              )}
              {handleProjectCompatibileItemsOnlyFilterChange && (
                <ToggleFilterMenuItem
                  isOn={!!projectCompatibleItemsOnlyFilter}
                  label="Project Compatible Items"
                  onToggle={handleProjectCompatibileItemsOnlyFilterChange}
                />
              )}
              {allFilters.map((filter) => {
                const FilterItem = FilterItemMap[filter.type];
                return (
                  <FilterItem
                    filter={filter}
                    handleFilterChange={handleSelectFilter}
                    key={filter.label}
                  />
                );
              })}
            </List>
          </WrapperInner>
        </Collapse>
      </Wrapper>
    </ClickAwayListener>
  );
};
