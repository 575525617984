import { ReactNode, useCallback } from 'react';

import { ResponsiveTable } from 'common/components/Tables/ResponsiveTable/ResponsiveTable';

import {
  EmptyState,
  Spinner,
  SpinnerWrapper,
} from 'common/components/Tables/TableLazy/tableLazy.styles';

import { useIntersectionObserver } from 'common/hooks/useIntersectionObserver';

type Props = {
  children: ReactNode;
  dataLength: number;
  isLoading: boolean;
  limit?: number;
  loadMore?: () => void;
  totalCount?: number;
};

const DEFAULT_API_LIMIT = 20;

export const TableLazy = ({
  children,
  dataLength,
  isLoading,
  limit = DEFAULT_API_LIMIT,
  loadMore,
  totalCount,
}: Props) => {
  const hasNoElements = !dataLength && !isLoading;
  const hasElements = dataLength > 0;
  const hasMoreElements = totalCount
    ? totalCount > dataLength
    : dataLength % limit === 0;

  const fetchMore = useCallback(
    (target: IntersectionObserverEntry) => {
      const isInView = target.isIntersecting && hasElements;
      const shouldLoadMore = isInView && hasMoreElements && !isLoading;

      if (shouldLoadMore && !hasNoElements) loadMore?.();
    },
    [hasElements, hasMoreElements, hasNoElements, isLoading, loadMore],
  );

  const [, nodeRef] = useIntersectionObserver<HTMLDivElement>({
    onChange: fetchMore,
    rootMargin: '24px',
  });

  return (
    <>
      <ResponsiveTable>{children}</ResponsiveTable>

      {hasNoElements && <EmptyState>No elements</EmptyState>}

      <SpinnerWrapper ref={nodeRef} visible={isLoading}>
        {isLoading && <Spinner />}
      </SpinnerWrapper>
    </>
  );
};
