import { ProposalRouteParams } from 'common/routes/paths/proposalPaths';
import { AUTH_PATHS } from 'common/routes/routerPaths';

export const PROPOSAL_LINKS = [
  {
    label: 'Scope of work',
    to: ({ proposalId, renovationPlanId, userId }: ProposalRouteParams) =>
      AUTH_PATHS.getProposalScopeOfWorkPath({
        proposalId,
        renovationPlanId,
        userId,
      }),
  },
  {
    label: 'Permits',
    to: ({ proposalId, renovationPlanId, userId }: ProposalRouteParams) =>
      AUTH_PATHS.getProposalDetailsPermitsPath({
        proposalId,
        renovationPlanId,
        userId,
      }),
  },
  {
    label: 'Timeline',
    to: ({ proposalId, renovationPlanId, userId }: ProposalRouteParams) =>
      AUTH_PATHS.getProposalDetailsTimelinePath({
        proposalId,
        renovationPlanId,
        userId,
      }),
  },
  {
    label: 'Payment Schedule',
    to: ({ proposalId, renovationPlanId, userId }: ProposalRouteParams) =>
      AUTH_PATHS.getProposalDetailsPaymentSchedulePath({
        proposalId,
        renovationPlanId,
        userId,
      }),
  },
  {
    label: 'Discounts',
    to: ({ proposalId, renovationPlanId, userId }: ProposalRouteParams) =>
      AUTH_PATHS.getProposalDetailsDiscountPath({
        proposalId,
        renovationPlanId,
        userId,
      }),
  },
];

export const ALERTS = {
  publishProposal: 'Successfully published proposal',
  unPublishProposal: 'Successfully unpublished proposal',
  updateProposal: 'Successfully updated proposal',
};
