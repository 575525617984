import { ProjectFlags } from 'common/apollo/models/projectFlags';

export function isNoDeposit(flags: ProjectFlags[] = []) {
  return flags.some(
    (flag) =>
      flag === ProjectFlags.NO_DEPOSIT_FLOW ||
      flag === ProjectFlags.CONTRACTOR_CHOICE_SPEED_FLOW,
  );
}

export function isPersonalizedMatch(flags: ProjectFlags[] = []) {
  return flags.includes(ProjectFlags.PERSONALIZED_MATCH);
}

export function isTailoredTOF(flags: ProjectFlags[] = []) {
  return flags.includes(ProjectFlags.TAILORED_TOF);
}

export function isTailoredTOFSWAE(flags: ProjectFlags[] = []) {
  return flags.includes(ProjectFlags.TAILORED_TOF_SWAE);
}

export function isTailoredTOFLP(flags: ProjectFlags[] = []) {
  return flags.includes(ProjectFlags.TAILORED_TOF_LP);
}
