import { useEffect, useState, useRef } from 'react';

import { copyToClipboard } from 'common/utils/clipboard';

type Props = {
  onSuccess?: (value: string) => void;
  value: string;
  valueTransformer?: (value: string) => string;
};

const COPY_TIMEOUT = 1000;

export const useCopy = ({ onSuccess, value, valueTransformer }: Props) => {
  const [didCopy, setDidCopy] = useState(false);
  const resetDidCopyId = useRef<ReturnType<typeof setTimeout>>();

  const handleCopy = async () => {
    try {
      const shareValue = valueTransformer ? valueTransformer(value) : value;
      await copyToClipboard(shareValue);

      setDidCopy(true);
      onSuccess && onSuccess(shareValue);
    } finally {
      if (resetDidCopyId.current) {
        clearTimeout(resetDidCopyId.current!);
      }

      resetDidCopyId.current = setTimeout(
        () => setDidCopy(false),
        COPY_TIMEOUT,
      );
    }
  };

  useEffect(
    () => () => {
      if (!resetDidCopyId.current) return;

      clearTimeout(resetDidCopyId.current!);
    },
    [],
  );

  return {
    didCopy,
    handleCopy,
  };
};
