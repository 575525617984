import { gql } from '@apollo/client/core';

export const DELETE_PROJECT_ORDER_ITEM = gql`
  mutation DeleteProjectOrderItem($input: OrderItemCancelInput!) {
    orderItemCancel(input: $input) {
      id
      status
    }
  }
`;
