import { useMutation } from '@apollo/client';

import { ProjectPlanLink } from 'common/apollo/models/projectPlanLink';

import { useAlert } from 'common/hooks/useAlert';

import { UPDATE_PROJECT_PLAN_ROOMS_LINKS } from 'ProjectProfile/common/apollo/gql/updateProjectPlanRoomsLinks';
import { UpdateProjectPlanRoomsLinksRequest } from 'ProjectProfile/common/requests/updateProjectPlanRoomsLinksRequest';
import { UpdateProjectPlanRoomsResponse } from 'ProjectProfile/common/responses/updateProjectPlanRoomsLinksResponse';

type Props = {
  onSuccessCallback?: () => void;
  pricingEngineIds: string[];
  values: {
    canvasLinks: ProjectPlanLink[];
    pricingEngineId: string;
  }[];
};

export const useProjectPlanRoomsLinksUpdate = () => {
  const { onCompleted, onError } = useAlert();

  const [updateProjectPlanLinks] = useMutation<
    UpdateProjectPlanRoomsResponse,
    UpdateProjectPlanRoomsLinksRequest
  >(UPDATE_PROJECT_PLAN_ROOMS_LINKS, {
    onCompleted: () => onCompleted('Changes saved'),
    onError: (error) => onError(error.message),
  });

  const handleSubmit = async ({ onSuccessCallback, values }: Props) => {
    const response = await updateProjectPlanLinks({
      variables: {
        input: values,
      },
    });

    if (response && onSuccessCallback) onSuccessCallback();
  };

  return {
    handleSubmit,
  };
};
