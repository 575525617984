import { useContext } from 'react';
import { useQuery } from '@apollo/client';

import { useParams } from 'react-router-dom';

import {
  PricingEngineGet,
  PricingEngineGetVariables,
} from 'PricingEngine/PricingEngineProvider/__generated__/pricingEngine.graphql.generated';

import { UnitsQueryResponse } from 'PricingEngine/PricingEngineProvider/pricingEngine.types';
import {
  GetTagGroupsResponse,
  GetTagGroupsRequest,
} from 'PricingEngine/TagGroup/tagGroup.types';

import { LaborVersion } from 'common/apollo/enums/LaborVersion';

import { useAlert } from 'common/hooks/useAlert';

import { PricingEngineContext } from 'PricingEngine/PricingEngineProvider/PricingEngineProvider';

import { ProposalLaborVersionRequest } from 'PricingEngine/PricingEngineProvider/apollo/requests/ProposalLaborVersionRequest';
import { ProposalLaborVersionResponse } from 'PricingEngine/PricingEngineProvider/apollo/responses/ProposalLaborVersionResponse';

import {
  GET_PRICING_ENGINE,
  GET_PRICING_ENGINE_UNITS,
  GET_TAG_GROUPS,
  PROPOSAL_LABOR_VERSION,
} from 'PricingEngine/PricingEngineProvider/pricingEngine.graphql';

export const usePricingEngineFetch = (pricingEngineId: string) => {
  const {
    data,
    error,
    loading: isLoading,
  } = useQuery<PricingEngineGet, PricingEngineGetVariables>(
    GET_PRICING_ENGINE,
    {
      skip: !Number(pricingEngineId),
      variables: {
        id: pricingEngineId,
      },
    },
  );

  const pricingEngine = data?.pricingEngine;

  return {
    error,
    isLoading,
    pricingEngine,
  };
};

export const usePricingEngineUnitsFetch = () => {
  const {
    data: units,
    error,
    loading,
  } = useQuery<UnitsQueryResponse>(GET_PRICING_ENGINE_UNITS);

  const laborUnits = units?.laborUnits?.[0]?.items || [];
  const vifStages = units?.vifStages?.[0]?.items || [];
  const verifiedStates = units?.verifiedStates?.[0]?.items || [];
  const productSkuStatuses = units?.productSkuStatuses?.[0]?.items || [];
  const productSkuUnits = units?.productSkuUnits?.[0]?.items || [];
  const productSkuProcuredBys = units?.productSkuProcuredBys?.[0]?.items || [];

  return {
    error,
    laborUnits,
    loading,
    productSkuProcuredBys,
    productSkuStatuses,
    productSkuUnits,
    verifiedStates,
    vifStages,
  };
};

export const usePricingEngineTradesFetch = (tagGroupCategoryIds: number[]) => {
  const { data, error, loading } = useQuery<
    GetTagGroupsResponse,
    GetTagGroupsRequest
  >(GET_TAG_GROUPS, {
    variables: {
      tagGroupCategoryIds,
    },
  });

  const tradesTagGroups = data?.tagGroups || [];

  return {
    error,
    loading,
    tradesTagGroups,
  };
};

export const usePricingEngine = () => useContext(PricingEngineContext);

export const usePricingEngineUnits = () =>
  useContext(PricingEngineContext).units;

export const useIsLaborV2Proposal = () => {
  const { onError } = useAlert();

  const { proposalId } = useParams<{
    proposalId: string;
  }>();

  const { data, loading } = useQuery<
    ProposalLaborVersionResponse,
    ProposalLaborVersionRequest
  >(PROPOSAL_LABOR_VERSION, {
    fetchPolicy: 'cache-first',
    onError: ({ message }) => onError(message),
    variables: { proposalId },
  });

  const isLaborV2Proposal = data?.proposal.laborVersion === LaborVersion.V2;

  return {
    isLaborV2Proposal,
    loading,
  };
};
