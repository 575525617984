import { SelectType } from 'common/types/select';

export const getDictionaryLabel = <Value = string>(
  dictionary: Array<SelectType<Value>>,
  key?: Value | null,
) => {
  const dictionaryElement = dictionary.find(({ value }) => value === key);
  return (dictionaryElement && dictionaryElement.label) || key;
};

export const mapToDictionaryItem = <T = string>(
  item: { [key: string]: any },
  { labelKey, valueKey }: { labelKey: string; valueKey: string } = {
    labelKey: 'name',
    valueKey: 'id',
  },
): SelectType<T> => ({ label: item[labelKey] || '-', value: item[valueKey] });
