import { Grid } from '@material-ui/core';

import { RoomCarousel } from 'ProjectProfile/common/components/RoomCarousel/RoomCarousel';
import { useGetProjectPlanRoomsRenders } from 'ProjectProfile/common/hooks/useProjectPlanRoomsRenders';

import { ConfiguratorRendersSkeleton } from './ConfiguratorRendersSkeleton';
import { Title } from './configuratorRenders.styles';

export const ConfiguratorRenders = () => {
  const { data, isLoading } = useGetProjectPlanRoomsRenders();

  return (
    <>
      <Title>Configurator Renders</Title>

      {isLoading && <ConfiguratorRendersSkeleton />}

      <Grid container spacing={4}>
        {data.map((room) => (
          <Grid item key={room.id} md={6} sm={6} xs={12}>
            <RoomCarousel items={room.renders} label={room.name} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
