import styled from '@emotion/styled';

export const OrderConflictWrapper = styled.div<{ leftAlign?: boolean }>`
  display: flex;
  justify-content: ${({ leftAlign: left }) => (left ? 'flex-start' : 'center')};
  margin-top: 14px;
`;

export const OrderConflictText = styled.p`
  margin-left: 4px;
`;
