import { useCallback, useState } from 'react';

import { useProfileSidebarSection } from 'common/shared/Sidebar/Profile/profile.hooks';

import { useGetProject } from 'common/hooks/Project/useGetProject';

export const useProjectSidebar = (projectId: string) => {
  const [isEditInProgress, setIsEditInProgress] = useState(false);
  const {
    address,
    error,
    isLoading,
    isPostDesignContractorAssignment,
    marketCode,
    projectType,
    secondaryContactEmail,
    serviceableStatus,
    user,
    userId,
  } = useGetProject(projectId);

  const toggleIsEditInProgress = useCallback(
    () => setIsEditInProgress((status) => !status),
    [setIsEditInProgress],
  );

  const { initialValues, loadingSubmit, onSubmit, userData } =
    useProfileSidebarSection({
      address,
      onEditComplete: toggleIsEditInProgress,
      renovationPlanId: projectId,
      user,
      userId,
    });

  return {
    error,
    initialValues,
    isEditInProgress,
    isLoading,
    isPostDesignContractorAssignment,
    loadingSubmit,
    marketCode,
    onSubmit,
    projectType,
    secondaryContactEmail,
    serviceableStatus,
    toggleIsEditInProgress,
    userData,
    userId,
  };
};
