import { getIn, useField, useFormikContext } from 'formik';

import _ from 'lodash-es';

import { useHasFormError } from 'common/hooks/useHasFormError';
import { shouldValidateField } from 'common/utils/validations';

import { TextField } from 'common/components/TextField/TextField';
import { debouncedChange } from 'common/shared/Question/common/utils/debounceChange';

export type Props = {
  id: string;
  isDisabled: boolean;
  isRequired: boolean;
  label: string;
  name: string;
  onChange?: (value: string) => void;
  placeholder?: string;
};

export const Textarea = ({
  id,
  isDisabled,
  isRequired,
  label,
  name,
  onChange,
  placeholder,
}: Props) => {
  const { validateForm, values } = useFormikContext();

  const [field, , { setTouched, setValue }] = useField({
    name,
    validate: shouldValidateField(isRequired),
  });

  const handleChange = async (event: any) => {
    setValue(event.target.value);
    setTouched(true, true);

    const newValues = _.set({ ...(values as any) }, name, event.target.value);

    const errors = await validateForm(newValues);

    if (
      errors &&
      (errors[name as keyof typeof errors] || getIn(errors, name))
    ) {
      return;
    }

    // we don't want to send a call to the backend for every key
    // pressed. Only do it once after 5 seconds of no changes
    if (onChange) {
      debouncedChange(event.target.value, onChange);
    }
  };

  // if the user unfocuses from the text element, call the onChange
  // right away
  const handleBlur = async () => {
    const errors = await validateForm();

    if (
      (errors && errors[name as keyof typeof errors]) ||
      getIn(errors, name)
    ) {
      return;
    }

    debouncedChange.flush();
  };

  const error = useHasFormError(name);

  return (
    <TextField
      {...field}
      disabled={isDisabled}
      extraLabel={!isRequired && 'Optional'}
      fluid
      hasError={!!error}
      helperText={error}
      id={id}
      label={label}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={placeholder}
      required={isRequired}
      rows={10}
      type="textarea"
    />
  );
};
