import { useQuery } from '@apollo/client';

import { useParams } from 'react-router-dom';

import { useAlert } from 'common/hooks/useAlert';

import { GetProjectPlanRoomsRequest } from 'common/apollo/requests/getProjectPlanRoomsRequest';
import { GetProjectPlanRoomsResponse } from 'common/apollo/responses/getProjectPlanRoomsResponse';

import { GET_PROJECT_PLAN_ROOMS_RENDERS } from 'ProjectProfile/common/apollo/gql/getProjectPlanRoomsRenders';
import { QueryParams } from 'ProjectProfile/common/types/queryParams';

export const useGetProjectPlanRoomsRenders = () => {
  const { projectId } = useParams<QueryParams>();
  const { onError } = useAlert();

  const { data, loading } = useQuery<
    GetProjectPlanRoomsResponse,
    GetProjectPlanRoomsRequest
  >(GET_PROJECT_PLAN_ROOMS_RENDERS, {
    onError: (error) => onError(error.message),
    skip: !projectId,
    variables: {
      input: {
        projectId,
      },
    },
  });

  const isLoading = !data && loading;

  return {
    data: data?.projectPlanRooms || [],
    isLoading,
  };
};
