import { ReactNode } from 'react';

import { ClassNames } from '@emotion/react';

import { TableProps } from '@material-ui/core/Table';

import {
  contentCardStyles,
  Table,
  ContentCard,
} from './ResponsiveTable.styles';

export type Props = TableProps & {
  children: ReactNode;
  className?: string;
};

export const ResponsiveTable = ({ children, className, ...props }: Props) => (
  <ClassNames>
    {({ css }) => (
      <ContentCard css={css([contentCardStyles, className])}>
        <Table data-testid="table" {...props}>
          {children}
        </Table>
      </ContentCard>
    )}
  </ClassNames>
);
