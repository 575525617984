import { gql } from '@apollo/client';

export const UPDATE_POST_DESIGN_CONTRACTOR_ASSIGNMENT = gql`
  mutation UpdatePostDesignContractorAssignment(
    $input: ProjectPostDesignContractorAssignmentFlagUpdateInput!
  ) {
    projectPostDesignContractorAssignmentFlagUpdate(input: $input) {
      id
      isPostDesignContractorAssignment
    }
  }
`;
