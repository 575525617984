import { gql } from '@apollo/client';

export const GET_REFERRAL_CAMPAIGNS = gql`
  query GetReferralCampaigns {
    referralCampaigns {
      id
      name
      codePrefix
    }
  }
`;

export const UPDATE_USER_REFERRED_BY = gql`
  mutation UserReferralCampaignUpdate(
    $input: UserReferralCampaignUpdateInput!
  ) {
    userReferralCampaignUpdate(input: $input) {
      id
    }
  }
`;
