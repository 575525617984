import Grid from '@material-ui/core/Grid';

import { HOA } from 'common/apollo/models/projectBuildingInfo';

import { Container } from 'ProjectProfile/common/components/Container/Container';
import { RoleLabelType } from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';

import { Section } from 'ProjectProfile/common/components/Section/Section';
import { EmptyCopy } from 'ProjectProfile/projectProfile.consts';

type Props = {
  hoa: HOA;
  isLoading: boolean;
  roles: RoleLabelType[];
};

export const Hoa = ({ hoa: { owner, type }, isLoading, roles }: Props) => (
  <Container isLoading={isLoading} roles={roles} title="HOA">
    <Grid container spacing={4}>
      <Grid item md={6} sm={12}>
        <Section emptyValue={EmptyCopy.OPTIONAL_FIELD} title="Type">
          {type}
        </Section>
      </Grid>
      {(owner || []).map(
        ({ firstNameAndMi, lastName, trustIndicator }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item key={index} md={10} sm={12}>
            <Section title="Owner">
              {firstNameAndMi} {lastName} {trustIndicator}
            </Section>
          </Grid>
        ),
      )}
    </Grid>
  </Container>
);
