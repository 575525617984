import { ClassNames } from '@emotion/react';

import { ExpandLess, ExpandMore } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

import { useCollapseProps } from 'common/components/Collapse/Collapse.hooks';

import { EditButton } from 'ProjectProfile/common/components/EditButton/EditButton';

import { RoleLabelType } from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';
import { TopContainer } from 'ProjectProfile/common/components/Container/container.styles';
import { RoleLabel } from 'ProjectProfile/common/components/RoleLabel/RoleLabel';

import {
  titleStyles,
  expandIconStyles,
  Trigger,
  IconWrapper,
  ButtonWrapper,
} from './CollapseButton.styles';

type Props = {
  className?: string;
  onEditClick?: () => void;
  roles?: RoleLabelType[];
  title?: string;
};

export const CollapseButton = ({
  className,
  onEditClick,
  roles,
  title,
}: Props) => {
  const { isOpen, onCollapseToggle } = useCollapseProps();

  return (
    <ClassNames>
      {({ css }) => (
        <ButtonWrapper>
          <TopContainer>
            <Trigger
              css={css([titleStyles, className])}
              onClick={onCollapseToggle}
            >
              {title}
            </Trigger>
            {roles &&
              roles.map((role) => <RoleLabel key={role} labelType={role} />)}
          </TopContainer>
          <IconWrapper>
            {onEditClick && (
              <EditButton
                onClick={onEditClick}
                segmentEventProps={{ ...(title && { name: title }) }}
              />
            )}
            <IconButton onClick={onCollapseToggle}>
              {isOpen ? (
                <ExpandLess css={expandIconStyles} />
              ) : (
                <ExpandMore css={expandIconStyles} />
              )}
            </IconButton>
          </IconWrapper>
        </ButtonWrapper>
      )}
    </ClassNames>
  );
};
