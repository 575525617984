import { TypographyProps } from '@material-ui/core/Typography';

import { TypographyCustomType } from './typographyCustom.types';

import { MuiTypography } from './Typography.styles';
import { isVariantType } from './typography.guard';

export type Props = Omit<TypographyProps, 'variant'> & {
  type?: TypographyProps['variant'] | TypographyCustomType;
};

export const Typography = ({ children, type, ...rest }: Props) => {
  const variant = isVariantType(type) ? type : undefined;

  return (
    <MuiTypography type={type} variant={variant} {...rest}>
      {children}
    </MuiTypography>
  );
};
