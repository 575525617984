import styled from '@emotion/styled';

import { DialogContent as MuiDialogContent } from '@material-ui/core';

export const DialogContent = styled(MuiDialogContent)`
  padding-bottom: 40px;
  .MuiMenuItem-root {
    min-height: auto;
  }
`;
