import { Field, FieldProps } from 'formik';

import { useHasFormError } from 'common/hooks/useHasFormError';

import { DatePicker, Props as BaseProps } from './DatePicker';

export type Props = Omit<BaseProps, 'value' | 'onChange'> & {
  onChange?: (date: Date | null) => void;
};

const FormDatePickerBase = ({
  field,
  form: { setFieldValue },
  onChange,
  onError,
  ...props
}: Props & FieldProps) => {
  const errorMessage = useHasFormError(field.name);

  const handleChange = (date: Date | null) => {
    setFieldValue(field.name, date);
    if (onChange) onChange(date);
  };

  return (
    <DatePicker
      {...props}
      {...field}
      errorMessage={errorMessage}
      onChange={handleChange}
    />
  );
};

export const FormDatePicker = (props: Props) => (
  <Field {...props} component={FormDatePickerBase} />
);
