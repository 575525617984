import { SelectType } from 'common/types/select';
import { RoomType } from 'common/apollo/enums/RoomType';

export const ROOM_TYPE_DICTIONARY = {
  [RoomType.BATHROOM]: 'Bathroom',
  [RoomType.KITCHEN]: 'Kitchen',
};

export const ROOM_TYPE_OPTIONS: SelectType<RoomType>[] = [
  {
    label: ROOM_TYPE_DICTIONARY[RoomType.BATHROOM],
    value: RoomType.BATHROOM,
  },
  { label: ROOM_TYPE_DICTIONARY[RoomType.KITCHEN], value: RoomType.KITCHEN },
];
