import { ListItem } from '@material-ui/core';

import {
  Link,
  Props as LinkProps,
} from 'common/components/Navigation/Link/Link';

import { useSidebarContentListItem } from './sidebarContentListItem.hooks';
import { sidebarContentListItemStyles } from './SidebarContent.styles';

type Props = LinkProps & {
  disabled?: boolean;
  label: string;
  to: string;
};

export const SidebarContentListItem = ({
  activeClassName = 'isActive',
  disabled,
  label,
  to,
  ...rest
}: Props) => {
  const { handleClick } = useSidebarContentListItem({ name: label });

  return (
    <ListItem
      css={sidebarContentListItemStyles}
      disableGutters
      disabled={disabled}
    >
      <Link
        activeClassName={disabled ? '' : activeClassName}
        onClick={handleClick}
        to={to}
        {...rest}
      >
        {label}
      </Link>
    </ListItem>
  );
};
