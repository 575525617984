import { ReactComponent as WarningRedIcon } from 'common/assets/icons/warning-red.svg';
import {
  OrderConflictText,
  OrderConflictWrapper,
} from 'Project/Procurement/common/components/OrderConflictWarning/orderConflictWarning.styles';

type Props = {
  leftAlign?: boolean;
};

export const OrderConflictWarning = ({ leftAlign }: Props) => (
  <OrderConflictWrapper leftAlign={leftAlign}>
    <WarningRedIcon />
    <OrderConflictText>Resolve Order Conlict</OrderConflictText>
  </OrderConflictWrapper>
);
