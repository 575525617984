import { Formik } from 'formik';

import { ErrorSegment } from 'common/components/ErrorHandlers/ErrorSegment/ErrorSegment';
import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';

import { OrderInfo } from 'Project/Procurement/CreateOrder/OrderInfo/OrderInfo';
import { OrderFooter } from 'Project/Procurement/CreateOrder/OrderFooter/OrderFooter';
import { OrderMaterials } from 'Project/Procurement/CreateOrder/OrderMaterials/OrderMaterials';

import {
  initialValues,
  validationSchema,
} from 'Project/Procurement/CreateOrder/createOrder.consts';

import { useCreateOrder } from 'Project/Procurement/CreateOrder/createOrder.hooks';

import { Wrapper } from 'Project/Procurement/CreateOrder/createOrder.styles';

const CreateOrder = () => {
  const {
    error,
    expectedMaterialsCost,
    handleCreateOrder,
    handleRemoveMaterial,
    handleUpdateMaterial,
    isLoading,
    materials,
    vendors,
  } = useCreateOrder();

  if (isLoading) return <LoadingSpinnerWithContainer />;
  if (error) return <ErrorSegment />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleCreateOrder}
      validationSchema={validationSchema}
    >
      <Wrapper>
        <OrderInfo
          expectedMaterialsCost={expectedMaterialsCost}
          vendors={vendors}
        />
        <OrderMaterials
          materials={materials}
          onMaterialRemove={handleRemoveMaterial}
          onMaterialUpdate={handleUpdateMaterial}
        />
        <OrderFooter materials={materials} />
      </Wrapper>
    </Formik>
  );
};

export default CreateOrder;
