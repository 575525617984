/* eslint-disable @typescript-eslint/naming-convention */
export const BULK_EDIT_MODAL = 'BulkEditModal';

export enum BULK_EDIT_FORM_FIELDS {
  CONFIRMED_AT = 'confirmedAt',
  ETA = 'eta',
  PALLET = 'pallet',
  RECEIVED_AT = 'receivedAt',
  SHIPPED_AT = 'shippedAt',
}
