import { ReactComponent as BathroomIcon } from 'common/assets/icons/bathroom.svg';

import { Section } from 'ProjectProfile/common/components/Section/Section';

import { EmptyContent, noRoomAdded, Wrapper } from './rooms.styles';

export const RoomsEmptyState = () => (
  <Section>
    <Wrapper>
      <BathroomIcon css={noRoomAdded} />
      <EmptyContent>No room is added yet</EmptyContent>
    </Wrapper>
  </Section>
);
