/* eslint-disable no-prototype-builtins */
import { SelectMultipleType } from 'common/types/selectMultiple';

export const isSelectMultipleType = <T>(
  option: any,
): option is SelectMultipleType<T> =>
  option &&
  option.hasOwnProperty('value') &&
  option.hasOwnProperty('label') &&
  option.hasOwnProperty('selected');
