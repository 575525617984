export type PaymentScheduleRouteParams = {
  paymentScheduleTermId: string;
  renovationPlanId: string;
  userId: string;
};

const getPaymentSchedulePath = ({
  renovationPlanId = ':renovationPlanId',
  userId = ':userId',
} = {}) =>
  `/users/${userId}/renovation-plans/${renovationPlanId}/paymentSchedule`;

const getPaymentScheduleTermPath = ({
  paymentScheduleTermId = ':paymentScheduleTermId',
  renovationPlanId = ':renovationPlanId',
  userId = ':userId',
} = {}) =>
  `/users/${userId}/renovation-plans/${renovationPlanId}/paymentSchedule/paymentScheduleTerm/${paymentScheduleTermId}`;

export const PAYMENT_SCHEDULE_PATHS = {
  getPaymentSchedulePath,
  getPaymentScheduleTermPath,
};
