import { Grid } from '@material-ui/core';

import { ReadMore } from 'common/components/ReadMore/ReadMore';

import { CollapsibleContainer } from 'ProjectProfile/common/components/CollapsibleContainer/CollapsibleContainer';
import { SectionOutlined } from 'ProjectProfile/common/components/Section/SectionOutlined';
import {
  Header,
  Layout,
  Title,
} from 'ProjectProfile/common/components/Layout/Layout';
import { ShareButton } from 'ProjectProfile/common/components/ShareButton/ShareButton';

import { DesignerNotes } from 'ProjectProfile/common/components/DesignerNotes/DesignerNotes';

import { RoleLabelType } from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';
import { EmptyCopy } from 'ProjectProfile/projectProfile.consts';

import { useNotes } from './notes.hooks';

const Notes = () => {
  const { answers, importances } = useNotes();

  const {
    ALTERATION_AGREEMENT_HIGHLIGHTS,
    BEFORE_DESIGN,
    BUILDING_INFORMATION_NOTES,
    CLIENT_NOTES,
    DESIGN_OPS_NOTES,
    IMPORTANT_PROJECT_CALL_OUTS,
    LIST_OF_ALL_TO_VIF,
    NEEDS_VS_NICE_TO_HAVES,
    POST_SITE_SURVEY_NOTES,
    PRICING_UPDATES_NOTES,
    PROJECT_EXTENSION_NOTES,
  } = answers;

  return (
    <Layout>
      <Header>
        <Title>View All Notes</Title>
        <ShareButton />
      </Header>

      <CollapsibleContainer title="Project Information">
        <Grid container spacing={4}>
          <Grid item md={10} sm={12} xs={12}>
            <SectionOutlined
              isImportant={importances.IMPORTANT_PROJECT_CALL_OUTS}
              roles={[RoleLabelType.PROJECT_PLANNER, RoleLabelType.DESIGNER]}
              title="Important project call-outs"
            >
              <ReadMore>{IMPORTANT_PROJECT_CALL_OUTS}</ReadMore>
            </SectionOutlined>
          </Grid>
          <Grid item md={10} sm={12} xs={12}>
            <SectionOutlined
              isImportant={importances.CLIENT_NOTES}
              roles={[RoleLabelType.PROJECT_PLANNER]}
              title="Homeowner Notes"
            >
              <ReadMore>{CLIENT_NOTES}</ReadMore>
            </SectionOutlined>
          </Grid>
          <Grid item md={10} sm={12} xs={12}>
            <SectionOutlined
              isImportant={importances.PROJECT_EXTENSION_NOTES}
              roles={[RoleLabelType.PROJECT_PLANNER]}
              title="Project extension notes"
            >
              <ReadMore>{PROJECT_EXTENSION_NOTES}</ReadMore>
            </SectionOutlined>
          </Grid>
        </Grid>
      </CollapsibleContainer>

      <CollapsibleContainer title="Building Information">
        <Grid container spacing={4}>
          <Grid item md={10} sm={12} xs={12}>
            <SectionOutlined
              isImportant={importances.BUILDING_INFORMATION_NOTES}
              roles={[RoleLabelType.PROJECT_PLANNER]}
              title="Building information notes"
            >
              <ReadMore>{BUILDING_INFORMATION_NOTES}</ReadMore>
            </SectionOutlined>
          </Grid>
          <Grid item md={10} sm={12} xs={12}>
            <SectionOutlined
              emptyValue={EmptyCopy.OPTIONAL_FIELD}
              isImportant={importances.ALTERATION_AGREEMENT_HIGHLIGHTS}
              roles={[RoleLabelType.PROJECT_PLANNER]}
              title="Alteration agreement highlights"
            >
              <ReadMore emptyValue={EmptyCopy.OPTIONAL_FIELD}>
                {ALTERATION_AGREEMENT_HIGHLIGHTS}
              </ReadMore>
            </SectionOutlined>
          </Grid>
          <Grid item md={10} sm={12} xs={12}>
            <SectionOutlined
              emptyValue={EmptyCopy.OPTIONAL_FIELD}
              isImportant={importances.DESIGN_OPS_NOTES}
              title="Notes from Project Planner to Design Ops"
            >
              <ReadMore>{DESIGN_OPS_NOTES}</ReadMore>
            </SectionOutlined>
          </Grid>
          <Grid item md={10} sm={12} xs={12}>
            <SectionOutlined
              isImportant={importances.POST_SITE_SURVEY_NOTES}
              roles={[RoleLabelType.DESIGN_OPS]}
              title="Post site-survey notes"
            >
              <ReadMore>{POST_SITE_SURVEY_NOTES}</ReadMore>
            </SectionOutlined>
          </Grid>
          <Grid item md={10} sm={12} xs={12}>
            <SectionOutlined
              isImportant={importances.PRICING_UPDATES_NOTES}
              roles={[RoleLabelType.DESIGN_OPS]}
              title="Pricing update notes"
            >
              <ReadMore>{PRICING_UPDATES_NOTES}</ReadMore>
            </SectionOutlined>
          </Grid>
          <Grid item md={10} sm={12} xs={12}>
            <SectionOutlined
              isImportant={importances.LIST_OF_ALL_TO_VIF}
              roles={[RoleLabelType.DESIGN_OPS]}
              title="List of All to VIF"
            >
              <ReadMore>{LIST_OF_ALL_TO_VIF}</ReadMore>
            </SectionOutlined>
          </Grid>
        </Grid>
      </CollapsibleContainer>

      <CollapsibleContainer title="Pre-Design">
        <Grid container spacing={4}>
          <Grid item md={10} sm={12} xs={12}>
            <SectionOutlined
              isImportant={importances.BEFORE_DESIGN}
              roles={[RoleLabelType.PROJECT_PLANNER]}
              title="Before Design"
            >
              <ReadMore>{BEFORE_DESIGN}</ReadMore>
            </SectionOutlined>
          </Grid>
          <Grid item md={10} sm={12} xs={12}>
            <SectionOutlined
              isImportant={importances.NEEDS_VS_NICE_TO_HAVES}
              roles={[RoleLabelType.PROJECT_PLANNER]}
              title="Needs vs. Nice-to-Haves"
            >
              <ReadMore>{NEEDS_VS_NICE_TO_HAVES}</ReadMore>
            </SectionOutlined>
          </Grid>
          <Grid item md={10} sm={12} xs={12}>
            <DesignerNotes />
          </Grid>
        </Grid>
      </CollapsibleContainer>
    </Layout>
  );
};

export default Notes;
