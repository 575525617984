import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';
import { ProjectPlanQuestions } from 'common/apollo/models/projectPlanQuestion';

export const normalizeQuestionsSelection = (values: {
  [key: string]: string | string[];
}) =>
  Object.values(values).reduce<string[]>(
    (acc, value) => (value ? acc.concat(value) : acc),
    [],
  );

export const mapQuestionToFormSelectOptions = (
  identifier: PropertyGroupIdentifier,
  questions: ProjectPlanQuestions[],
) => {
  const question = questions.find((answer) => answer.identifier === identifier);

  if (!question) return [];

  return question.answers.map(({ id, label }) => ({
    label,
    value: id,
  }));
};
