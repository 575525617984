import { gql } from '@apollo/client';

export const UPDATE_PROJECT_PLAN_ROOMS_LINKS = gql`
  mutation UpdateProjectPlanRoomsLinks(
    $input: [ProjectPlanRoomsLinksUpdateInput!]!
  ) {
    projectPlanRoomsLinksUpdate(input: $input) {
      id
      room {
        id
        roomType
        name
      }
      canvasLinks {
        name
        url
      }
    }
  }
`;
