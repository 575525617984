import styled from '@emotion/styled';

export const EmptyState = styled.p`
  align-items: center;
  background: ${({ theme }) => theme.color.concrete};
  border: 1px solid ${({ theme }) => theme.color.alto};
  display: flex;
  font: 400 16px/24px ${({ theme }) => theme.font.inter};
  justify-content: center;
  margin: 30px;
  min-height: 120px;
`;
