import styled from '@emotion/styled';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  width: 100%;

  > span:last-of-type {
    padding-left: 15px;
  }
`;
