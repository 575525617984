import { useQuery } from '@apollo/client';

import { GET_TASKS } from 'common/apollo/queries/clientTasks.graphql';
import { mapClientTasks } from 'common/utils/mapClientTasks';
import { GetTasksRequest } from 'common/apollo/requests/getTasksRequest';
import { GetTasksResponse } from 'common/apollo/responses/getTasksResponse';

export const useGetTasks = (projectId: string) => {
  const {
    data,
    loading: isLoading,
    refetch,
  } = useQuery<GetTasksResponse, GetTasksRequest>(GET_TASKS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        projectId,
      },
    },
  });

  const adminStages = data?.adminGetStages || [];
  const phasesList = mapClientTasks(adminStages || []);

  return {
    adminStages,
    isLoading,
    phasesList,
    refetch,
  };
};
