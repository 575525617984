import { ClickAwayListener } from '@material-ui/core';

import { SearchInput } from 'common/components/SearchInput/SearchInput';
import { TextField } from 'common/components/TextField/TextField';

import { ReactComponent as CloseIcon } from 'common/assets/icons/close.svg';

import { ReactComponent as SearchIcon } from 'common/assets/icons/search.svg';

import {
  Wrapper,
  ListWrapper,
  ListItem,
  Spinner,
  NoResults,
  IconButton,
} from 'ProjectProfile/ProjectInformation/v2/RefererField/refererField.styles';

import { useReferer } from 'ProjectProfile/ProjectInformation/v2/RefererField/refererField.hooks';

export const RefererField = () => {
  const {
    handleBlur,
    handleClearSelection,
    handleFocus,
    handleSearchChange,
    handleSelect,
    hasSelectedUser,
    isLoading,
    selectedUser,
    selectedValue,
    showList,
    users,
  } = useReferer();

  if (hasSelectedUser) {
    return (
      <TextField
        defaultValue={`${selectedUser?.name} - ${selectedUser?.email}`}
        endAdornment={
          <IconButton onClick={handleClearSelection} type="button">
            <CloseIcon />
          </IconButton>
        }
        readOnly
      />
    );
  }

  return (
    <ClickAwayListener onClickAway={handleBlur}>
      <Wrapper>
        <SearchInput
          endAdornment={<SearchIcon />}
          id="refererId"
          onChange={handleSearchChange}
          onFocus={handleFocus}
          placeholder="Search name or email"
          startAdornment={null}
        />

        {showList && (
          <ListWrapper>
            {isLoading ? (
              <ListItem>
                <Spinner size={20} />
              </ListItem>
            ) : (
              users.map(({ email, id, name }) => (
                <ListItem
                  isSelected={selectedValue === id}
                  key={id}
                  onClick={() => handleSelect(id)}
                >
                  {name}&nbsp;-&nbsp;{email}
                </ListItem>
              ))
            )}

            {!isLoading && !users.length && (
              <NoResults>No results found</NoResults>
            )}
          </ListWrapper>
        )}
      </Wrapper>
    </ClickAwayListener>
  );
};
