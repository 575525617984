import styled from '@emotion/styled';
import { css } from '@emotion/react';

import selectIcon from 'common/assets/icons/select.svg';
import checkIcon from 'common/assets/icons/checkmark.svg';
import closeIcon from 'common/assets/icons/close.svg';

export const TextInput = styled.input<{
  addedStyles?: Record<string, string>;
  hasError?: boolean;
  isClearable?: boolean;
}>`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.gray6};
  font: 400 16px/24px ${({ theme }) => theme.font.inter};
  outline: 0;
  padding: ${({ addedStyles }) =>
    addedStyles?.padding ? addedStyles.padding : '12px 16px'};
  padding-right: ${({ isClearable }) => (isClearable ? '60px' : '16px')};
  text-overflow: ellipsis;
  width: 100%;

  &:focus {
    border: 1px solid ${({ theme }) => theme.color.orange6};
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.color.orange6};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.gray1};
    border-color: ${({ theme }) => theme.color.gray6};
    color: ${({ theme }) => theme.color.gray11};
    cursor: not-allowed;
  }

  ${({ hasError, theme }) =>
    hasError &&
    css`
      background-color: ${theme.color.red2};
      border-color: ${theme.color.red6};
      color: ${theme.color.red11};
    `}
`;

export const SelectWrapper = styled.div<{
  addedStyles?: Record<string, string>;
}>`
  min-width: 263px;
  position: relative;

  &:focus-within ${TextInput} {
    border-radius: 4px 4px 0 0;
  }

  &:after {
    background: url(${selectIcon}) 100% no-repeat;
    content: ' ';
    height: 18px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: ${({ addedStyles }) =>
      addedStyles?.wrapperIconTop ? addedStyles.wrapperIconTop : '17px'};
    width: 18px;
  }
`;

export const OptionsList = styled.ul`
  background: ${({ theme }) => theme.color.white};
  border-radius: 0 0 4px 4px;
  border: 1px solid ${({ theme }) => theme.color.orange6};
  border-top: 0;
  max-height: 600px;
  overflow: auto;
  padding: 8px 0;
  position: absolute;
  width: 100%;
  z-index: 5;
`;

type OptionProps = {
  isHighlighted?: boolean;
  isSelected?: boolean;
};

export const Option = styled.li<OptionProps>`
  background: ${({ isHighlighted, theme }) =>
    isHighlighted ? theme.color.orange2 : 'transparent'};
  color: ${({ theme }) => theme.color.black};
  cursor: pointer;
  font: 400 14px/20px ${({ theme }) => theme.font.inter};
  padding: 10px 30px 10px 10px;
  position: relative;

  &:after {
    background: url(${checkIcon}) 100% no-repeat;
    content: '';
    display: ${({ isSelected }) => (isSelected ? 'block' : 'none')};
    height: 18px;
    position: absolute;
    right: 12px;
    top: 10px;
    width: 18px;
  }
`;

export const ClearButton = styled.button`
  background: url(${closeIcon}) 100% no-repeat;
  border: 0;
  cursor: pointer;
  height: 18px;
  position: absolute;
  right: 32px;
  top: 16px;
  width: 16px;
`;
