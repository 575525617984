import { useQuery } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';

import { GetProjectPlanRoomsQuestionsResponse } from 'common/apollo/responses/getProjectPlanRoomsQuestionsResponse';
import { GetProjectPlanRoomsQuestionsRequest } from 'common/apollo/requests/getProjectPlanRoomsQuestionsRequest';

import { GET_PROJECT_PLAN_ROOMS_QUESTIONS } from 'ProjectProfile/common/apollo/gql/getProjectPlanRoomsQuestions';
import { ProjectPlanRoomsQuestionsRoomInput } from 'ProjectProfile/common/types/projectPlanRoomsQuestionsRoomInput';

type Props = {
  roomsQuestionsInput: ProjectPlanRoomsQuestionsRoomInput[];
};

export const useProjectPlanRoomsQuestions = ({
  roomsQuestionsInput,
}: Props) => {
  const { onError } = useAlert();

  const { data, loading } = useQuery<
    GetProjectPlanRoomsQuestionsResponse,
    GetProjectPlanRoomsQuestionsRequest
  >(GET_PROJECT_PLAN_ROOMS_QUESTIONS, {
    onError: (error) => onError(error.message),
    skip: !roomsQuestionsInput.length,
    variables: {
      input: {
        rooms: roomsQuestionsInput,
      },
    },
  });

  const isLoading = !data && loading;

  return {
    data: data?.projectPlanRoomsQuestions || [],
    isLoading,
  };
};
