import { useReducer, useCallback } from 'react';

import {
  fetchInit,
  fetchError,
  fetchSuccess,
  setPopperVisibility,
} from './copyToClipboardButton.actions';

import { reducer, State, initialState } from './copyToClipboardButton.reducer';

export const useCopyToClipboard = (): [
  State,
  {
    setInitFetch: (asyncFetchCopyText: () => Promise<string>) => void;
    setPopperClose: () => void;
    setPopperOpen: () => void;
  },
] => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setInitFetch = useCallback(
    async (asyncFetchCopyText: () => Promise<string>) => {
      dispatch(fetchInit());
      const copyText = await asyncFetchCopyText();

      // TODO: Handle success/error in snackbar when common component is ready
      return copyText
        ? dispatch(fetchSuccess(copyText))
        : dispatch(fetchError());
    },
    [],
  );

  const setPopperOpen = useCallback(() => {
    dispatch(setPopperVisibility(true));
  }, []);

  const setPopperClose = useCallback(() => {
    dispatch(setPopperVisibility(false));
    dispatch(fetchSuccess(''));
  }, []);

  return [state, { setInitFetch, setPopperClose, setPopperOpen }];
};
