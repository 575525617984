import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useToggle } from 'common/hooks/useToggle';

import { QueryParams } from 'ProjectProfile/common/types/queryParams';
import { useGetProjectPlanQuestions } from 'ProjectProfile/common/hooks/useProjectPlanQuestions';
import { normalizeProjectPlanQuestions } from 'ProjectProfile/common/utils/normalizeProjectPlanQuestions';
import { Importances } from 'ProjectProfile/common/types/importance';
import { UpdateProjectPlanQuestionsResponse } from 'ProjectProfile/common/responses/updateProjectPlanQuestionsResponse';
import { getImportances } from 'ProjectProfile/common/utils/questionAnswer';

import { ProjectPlanQuestions } from 'common/apollo/models/projectPlanQuestion';

import { identifiers, openQuestionsIdentifiers } from './buildingInfo.consts';

export const useBuildingInfo = () => {
  const { projectId } = useParams<QueryParams>();
  const { isOn: isOpen, toggleOff: close, toggleOn: open } = useToggle();

  const { data, isLoading } = useGetProjectPlanQuestions({
    identifiers,
    projectId,
  });

  const answers = normalizeProjectPlanQuestions(data);

  const [projectPlanQuestions, setProjectPlanQuestions] =
    useState<ProjectPlanQuestions[]>(data);

  const [importances, setImportances] = useState<Importances>(
    getImportances(openQuestionsIdentifiers, projectPlanQuestions),
  );

  useEffect(() => {
    if (data.length) {
      setProjectPlanQuestions(data);
    }
  }, [data]);

  useEffect(() => {
    setImportances(
      getImportances(openQuestionsIdentifiers, projectPlanQuestions),
    );
  }, [projectPlanQuestions, isLoading]);

  const handleSuccessfulSubmit = (
    responseData: UpdateProjectPlanQuestionsResponse,
  ) => {
    setProjectPlanQuestions(responseData.projectPlanQuestionsUpdate);
    close();
  };

  return {
    answers,
    close,
    data,
    handleSuccessfulSubmit,
    importances,
    isLoading,
    isOpen,
    open,
  };
};
