import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mq } from 'common/utils/mediaQueries';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.white};
  height: 100%;
  max-width: 100vw;
  padding: 32px;

  ${mq.small(css`
    min-width: 500px;
  `)};
`;

export const Title = styled.h2`
  font: 500 20px/28px ${({ theme }) => theme.font.inter};
`;

export const Footer = styled.div`
  column-gap: 16px;
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;
