import { BatchColumnWidthMap } from 'Project/Procurement/Warehouse/warehouse.consts';

export const TABLE_HEAD_CONFIG = [
  {
    content: '',
    isSticky: true,
    width: BatchColumnWidthMap.ACTION_MENU,
  },
  {
    content: 'Checkbox',
    isSticky: true,
    width: BatchColumnWidthMap.CHECKBOX,
  },
  {
    content: 'Batch',
    isSticky: true,
    width: BatchColumnWidthMap.BATCH,
  },
  {
    content: 'Units',
    width: BatchColumnWidthMap.UNITS,
  },
  {
    content: 'Received On',
    width: BatchColumnWidthMap.RECEIVED_ON,
  },
  {
    content: 'Pallet',
    width: BatchColumnWidthMap.PALLET,
  },
  {
    content: 'Shipped On',
    width: BatchColumnWidthMap.SHIPPED_ON,
  },
  {
    content: 'ETA to Jobsite',
    width: BatchColumnWidthMap.ETA,
  },
  {
    content: 'Contractor Conf.',
    width: BatchColumnWidthMap.CONFIRMED_BY,
  },
  {
    content: 'From Inventory',
    width: BatchColumnWidthMap.INVENTORY_UNITS,
  },
  {
    content: '',
    width: BatchColumnWidthMap.ACTION_MENU,
  },
];
