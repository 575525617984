import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { Form, Formik } from 'formik';

import { SectionOutlined } from 'ProjectProfile/common/components/Section/SectionOutlined';

import { ModalCloseButton } from 'common/components/Modals/ModalCloseButton';
import { Button } from 'common/components/Buttons/Button/Button';
import { FormTextArea } from 'common/_components/Form/FormTextarea/FormTextarea';

import {
  DialogActions,
  DialogContent,
  FieldWrapper,
} from 'ProjectProfile/common/components/EditForm/editForm.styles';

import { RoleLabelType } from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';

import { EmptyCopy } from 'ProjectProfile/projectProfile.consts';

import { validationSchema } from './projectCallOuts.consts';
import { useProjectCallOuts } from './projectCallOuts.hooks';

type Props = {
  isLoading?: boolean;
  placeholder?: string;
  value: string | null;
};

export const ProjectCallOuts = ({
  isLoading = false,
  placeholder,
  value,
}: Props) => {
  const { handleSubmit, isOn, toggleOff, toggleOn } = useProjectCallOuts();

  return (
    <>
      <SectionOutlined
        emptyValue={EmptyCopy.OPTIONAL_FIELD}
        onEditClick={toggleOn}
        roles={[RoleLabelType.PROJECT_PLANNER, RoleLabelType.DESIGNER]}
        title="Important Project Call-Outs"
      >
        {isLoading || value}
      </SectionOutlined>

      <Dialog fullWidth maxWidth="md" open={isOn}>
        <DialogTitle>Important Project Call-Outs</DialogTitle>
        <ModalCloseButton onClick={toggleOff} />

        <Formik
          initialValues={{ projectCallOuts: value }}
          onSubmit={({ projectCallOuts }) => handleSubmit(projectCallOuts)}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <DialogContent>
                <FieldWrapper>
                  <FormTextArea
                    disabled={isSubmitting}
                    id="projectCallOuts"
                    label="Important Project Call-Outs"
                    name="projectCallOuts"
                    placeholder={placeholder}
                    rows={5}
                  />
                </FieldWrapper>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
