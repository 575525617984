import styled from '@emotion/styled';
import { css } from '@emotion/react';

import IconButton from '@material-ui/core/IconButton';

import { FormTextField } from 'common/components/Form/TextField/FormTextField';

import { mq } from 'common/utils/mediaQueries';

type Props = {
  size?: string;
};

export const Title = styled.div`
  font: 600 14px/18px ${({ theme }) => theme.font.inter};
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const LinkInput = styled(FormTextField)`
  width: 100%;
`;

export const DeleteLinkButton = styled(IconButton)`
  margin: 0 0 0 5px;
  width: 46px;
  height: 46px;
`;

export const LinkInputWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Wrapper = styled.section`
  margin-bottom: 40px;
`;

export const Header = styled.header<Props>`
  align-items: center;
  display: flex;
  margin-right: -14px;
  justify-content: space-between;
  padding-bottom: 20px;

  ${({ size }) =>
    size === 'md' &&
    mq.medium(css`
      width: calc(50% - 14px);
    `)}
`;
