import { useEffect } from 'react';

import { BaseFilter } from 'common/components/Filters/filters.types';
import {
  extractTarget,
  useResizeObserver,
} from 'common/hooks/useResizeObserver';
import { useToggle } from 'common/hooks/useToggle';

export const useFilters = () => {
  const { isOn: isOpen, toggle } = useToggle();

  const [entry, containerRef] = useResizeObserver<HTMLDivElement>({
    observeParent: false,
  });
  const { offsetHeight, offsetLeft, offsetTop } =
    extractTarget<HTMLDivElement>(entry);
  const topOffset = offsetHeight + offsetTop;
  const leftOffset = offsetLeft;
  const buttonWidth = containerRef.current?.offsetWidth || 100;

  const handleClickAway = () => {
    if (!isOpen) return;

    toggle();
  };

  return {
    buttonWidth,
    containerRef,
    handleClickAway,
    isOpen,
    leftOffset,
    toggle,
    topOffset,
  };
};

export const useSortFilters = (allFilters: BaseFilter[]) => {
  useEffect(() => {
    const filtersOrder = ['Trade', 'Sub Trade'];
    allFilters.sort((a, b) =>
      filtersOrder.findIndex((orderElement) => orderElement === a.label) >
      filtersOrder.findIndex((orderElement) => orderElement === b.label)
        ? 1
        : -1,
    );
  }, [allFilters]);
};
