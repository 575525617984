import { gql } from '@apollo/client';

export const GET_SIDEBAR_PROPOSAL = gql`
  query SidebarProposalGet($id: ID!) {
    proposal(id: $id) {
      id
      name
      createdAt
      updatedAt
      publishedAt
      acceptedAt
      status
      type
      renovationPlan {
        id
        projectType
        address {
          city
          zip
          state
          street
          street_2
        }
        user {
          id
          firstName
          lastName
          email
          phoneNumber
        }
      }
      requireAcceptance
      requirePayment
      pricingEngines {
        id
        name
      }
    }
  }
`;
