import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { useAlert } from 'common/hooks/useAlert';
import { useToggle } from 'common/hooks/useToggle';

import { UPDATE_POST_DESIGN_CONTRACTOR_ASSIGNMENT } from 'ProjectProfile/common/apollo/gql/updatePostDesignContractorAssignment';
import { UpdatePostDesignContractorAssignmentResponse } from 'ProjectProfile/common/responses/updatePostDesignContractorAssignmentResponse';
import { UpdatePostDesignContractorAssignmentRequest } from 'ProjectProfile/common/requests/updatePostDesignContractorAssignmentRequest';

export const usePostDSOExperience = () => {
  const { isOn, toggleOff, toggleOn } = useToggle();
  const { projectId } = useParams<{ projectId: string }>();
  const { onCompleted, onError } = useAlert();

  const [projectPostDesignContractorAssignmentFlagUpdate] = useMutation<
    UpdatePostDesignContractorAssignmentResponse,
    UpdatePostDesignContractorAssignmentRequest
  >(UPDATE_POST_DESIGN_CONTRACTOR_ASSIGNMENT, {
    onCompleted: () => {
      toggleOff();
      onCompleted('Changes saved');
    },
    onError: (error) => onError(error.message),
  });

  const handleSubmit = async (formValue: boolean) => {
    await projectPostDesignContractorAssignmentFlagUpdate({
      variables: {
        input: {
          projectId,
          value: formValue,
        },
      },
    });
  };

  return {
    handleSubmit,
    isOn,
    toggleOff,
    toggleOn,
  };
};
