import { Form, Formik } from 'formik';

import { Checkbox } from 'common/components/Form/Checkboxes/Checkbox';
import { FormTextField } from 'common/components/Form/TextField/FormTextField';
import { FormDatePicker } from 'common/components/Form/DatePicker/FormDatePicker';

import {
  BatchColumnWidthMap,
  WarehouseZIndexMap,
} from 'Project/Procurement/Warehouse/warehouse.consts';

import {
  ButtonWrapper,
  CancelButton,
  Cell,
  Row,
  SaveButton,
  Wrapper,
  stickyColumnStyle,
} from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/batchRow.styles';

import { BatchInput } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/batchRow.types';
import { validationBatchSchema } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/batchRow.consts';

type Props = {
  confirmedAt: string | null;
  eta: string | null;
  handleToggleBatch?: (batchId: string) => void;
  id: string;
  inventoryUnits: number;
  isSelected?: boolean;
  number: number;
  onCancelEdit: () => void;
  onSave: (input: BatchInput) => void;
  pallet: string | null;
  receivedAt: string | null;
  shippedAt: string | null;
  units: number;
  unitsRemaining: number;
};

export const BatchRowEditable = ({
  confirmedAt,
  eta,
  handleToggleBatch,
  id,
  inventoryUnits,
  isSelected,
  number,
  onCancelEdit,
  onSave,
  pallet,
  receivedAt,
  shippedAt,
  units,
  unitsRemaining,
}: Props) => (
  <Formik
    initialValues={{
      confirmedAt,
      eta,
      inventoryUnits: Math.max(inventoryUnits, 0),
      pallet: pallet || '',
      receivedAt,
      shippedAt,
      units: Math.max(units, 1),
    }}
    onSubmit={onSave}
    validationSchema={validationBatchSchema(unitsRemaining)}
  >
    <Wrapper isSelected={isSelected}>
      <Form>
        <Row>
          <Cell
            cellWidth={BatchColumnWidthMap.ACTION_MENU}
            css={stickyColumnStyle}
            isSticky
            zIndex={WarehouseZIndexMap.batchRowCell}
          />
          <Cell
            cellWidth={BatchColumnWidthMap.CHECKBOX}
            css={stickyColumnStyle}
            isSticky
            zIndex={WarehouseZIndexMap.batchRowCell}
          >
            {handleToggleBatch && (
              <Checkbox
                checked={isSelected}
                onChange={() => handleToggleBatch(id)}
                title="selected"
              />
            )}
          </Cell>
          <Cell
            cellWidth={BatchColumnWidthMap.BATCH}
            css={stickyColumnStyle}
            isSticky
            zIndex={WarehouseZIndexMap.batchRowCell}
          >
            {number}
          </Cell>
          <Cell cellWidth={BatchColumnWidthMap.UNITS}>
            <FormTextField autoFocus label="Units" name="units" type="number" />
          </Cell>
          <Cell cellWidth={BatchColumnWidthMap.RECEIVED_ON}>
            <FormDatePicker clearable label="Received On" name="receivedAt" />
          </Cell>
          <Cell cellWidth={BatchColumnWidthMap.PALLET}>
            <FormTextField label="Pallet" name="pallet" />
          </Cell>
          <Cell cellWidth={BatchColumnWidthMap.SHIPPED_ON}>
            <FormDatePicker clearable label="Shipped On" name="shippedAt" />
          </Cell>
          <Cell cellWidth={BatchColumnWidthMap.ETA}>
            <FormDatePicker clearable label="ETA" name="eta" />
          </Cell>
          <Cell cellWidth={BatchColumnWidthMap.CONFIRMED_BY}>
            <FormDatePicker clearable label="Confirmed By" name="confirmedAt" />
          </Cell>
          <Cell cellWidth={BatchColumnWidthMap.INVENTORY_UNITS}>
            <FormTextField
              label="Inventory Units"
              name="inventoryUnits"
              type="number"
            />
          </Cell>
          <Cell cellWidth={BatchColumnWidthMap.ACTION_MENU}>
            <ButtonWrapper>
              <CancelButton onClick={onCancelEdit}>Cancel</CancelButton>
              <SaveButton type="submit">Save</SaveButton>
            </ButtonWrapper>
          </Cell>
        </Row>
      </Form>
    </Wrapper>
  </Formik>
);
