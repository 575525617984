import { createMuiTheme } from '@material-ui/core/styles';

import { THEME } from 'common/consts/theme';

import { muiTypography } from './muiTypography';

// TODO: Update this once we have standardized designs
export const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      contained: { borderRadius: 0, boxShadow: 'none' },
      containedPrimary: {
        color: THEME.color.white,
      },
      outlined: { borderRadius: 0, boxShadow: 'none' },
      outlinedPrimary: {
        backgroundColor: THEME.color.white,
      },
    },
    MuiInput: {
      root: {
        backgroundColor: THEME.color.white,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiTypography: muiTypography,
  },
  palette: {
    background: {
      default: THEME.color.gray,
    },
    primary: {
      contrastText: THEME.color.black,
      dark: THEME.color.primaryOrangeDark,
      light: THEME.color.primaryOrangeLight,
      main: THEME.color.primaryOrange,
    },
    secondary: {
      contrastText: THEME.color.black,
      dark: THEME.color.darkGray3,
      light: THEME.color.gray,
      main: THEME.color.darkGray2,
    },
  },
});
