import { createContext, ReactNode, useMemo } from 'react';

import { FetchResult, NetworkStatus, useQuery, gql } from '@apollo/client';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { identifyUser } from 'common/utils/segment';

// Components
import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';

// Types
import { User } from 'common/apollo/models/user';

export const meQuery = gql`
  query meQuery {
    me {
      id: adminId
      email
      permissions
      name
    }
  }
`;

type Props = {
  children: ReactNode;
};

type AuthenticationState = {
  currentUser?: User | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  networkStatus: number;
  refetch: () => Promise<FetchResult<any>> | void;
};

const initialState = {
  currentUser: null,
  isAuthenticated: false,
  isLoading: true,
  networkStatus: NetworkStatus.loading,
  refetch: () => undefined,
};

export const AuthenticationContext =
  createContext<AuthenticationState>(initialState);

export const AuthenticationProvider = ({ children }: Props) => {
  const ldClient = useLDClient();
  const {
    data: currentUser,
    loading,
    networkStatus,
    refetch,
  } = useQuery(meQuery, {
    notifyOnNetworkStatusChange: true,
  });

  if (currentUser?.me) {
    const { email, id, name } = currentUser.me;

    ldClient?.identify({
      email,
      key: id,
      name,
    });

    identifyUser(id, {
      email,
      name,
    });
  }

  const authenticationValue = useMemo(
    () => ({
      currentUser: currentUser?.me,
      isAuthenticated: !!currentUser,
      isLoading: loading,
      networkStatus,
      refetch,
    }),
    [currentUser, loading, refetch, networkStatus],
  );

  const isLoading = loading && networkStatus === NetworkStatus.loading;

  if (isLoading) return <LoadingSpinnerWithContainer />;

  return (
    <AuthenticationContext.Provider value={authenticationValue}>
      {children}
    </AuthenticationContext.Provider>
  );
};
