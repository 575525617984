import { gql } from '@apollo/client';

export const GET_KEY_DATES = gql`
  query AdminStages($input: AdminGetStagesInput!) {
    adminGetStages(input: $input) {
      id
      identifier
      adminKeyDates {
        id
        source
        type
        value
      }
      consumerKeyDates {
        id
        source
        type
        value
      }
    }
  }
`;
