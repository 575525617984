import { useFlags } from 'launchdarkly-react-client-sdk';

import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';
import { StageIdentifier } from 'common/apollo/enums/StageIdentifier';
import { FeatureFlags } from 'common/enums/FeatureFlags';

import { getModalId } from 'ClientTasks/TargetDate/common/targetDateModal.utils';
import { FULL_HISTORY_MODAL } from 'ClientTasks/TargetDateHistoryModal/targetDateHistoryModal.consts';
import { useGetKeyDateChangeEvents } from 'ClientTasks/TargetDateHistoryModal/targetDateHistoryModal.hooks';

export const useTargetDateHistory = (stageName: StageIdentifier) => {
  const { isLoading, keyDateChangeEvents } =
    useGetKeyDateChangeEvents(stageName);
  const modalId = getModalId(FULL_HISTORY_MODAL, stageName);
  const flags = useFlags();
  const breakgroundEnabled = flags[FeatureFlags.TARGET_DATE_MGMT_BREAKGROUND];

  const setDialogOpen = useSetModalOpen();

  const handleViewFullHistoryClick = () => {
    setDialogOpen(modalId, true);
  };

  return {
    breakgroundEnabled,
    handleViewFullHistoryClick,
    isLoading,
    keyDateChangeEvents,
  };
};
