import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { LOG_IN_URL } from 'common/consts/urls';
import { useAlert } from 'common/hooks/useAlert';

import { AuthReferral } from 'common/apollo/enums/AuthReferral';

import { GetLogInAsUserLinkResponse } from 'common/apollo/responses/getLogInAsUserLinkResponse';
import { GetLogInAsUserLinkRequest } from 'common/apollo/requests/getLogInAsUserRequest';

import { GET_LOG_IN_AS_USER_LINK } from 'common/hooks/LoginAsUser/loginAsUser.graphql';
import { getErrorMessage } from 'common/utils/error';
import { SEND_USER_LOGIN_LINK } from 'common/apollo/mutations/sendUserLoginLink';
import { SendUserLoginLinkRequest } from 'common/apollo/requests/sendUserLoginLinkRequest';

export const prepareLoginAsUserLink = (
  authToken: string,
  redirectPath?: string,
  renovationPlanId?: string,
) => {
  const url = new URL(LOG_IN_URL);
  url.searchParams.append('authToken', authToken);
  redirectPath && url.searchParams.append('redirectPath', redirectPath);
  renovationPlanId &&
    url.searchParams.append('renovationPlanId', renovationPlanId);

  return url.toString();
};

export const useSendLoginLink = () => {
  const { onCompleted, onError } = useAlert();
  const [sendLink] = useMutation<void, SendUserLoginLinkRequest>(
    SEND_USER_LOGIN_LINK,
  );

  const sendUserLoginLink = useCallback(
    async (email: string) => {
      try {
        await sendLink({
          variables: { input: { email, redirectPath: '' } },
        });
        onCompleted('Email with link has been sent to the homeowner.');
      } catch (error) {
        onError(getErrorMessage(error));

        throw error;
      }
    },
    [onCompleted, onError, sendLink],
  );

  return { sendUserLoginLink };
};

export const useFetchLoginLink = () => {
  const { onError } = useAlert();

  const [login] = useMutation<
    GetLogInAsUserLinkResponse,
    GetLogInAsUserLinkRequest
  >(GET_LOG_IN_AS_USER_LINK);

  const fetchLoginLink = useCallback(
    async (id: string, referral: AuthReferral, renovationPlanId: string) => {
      try {
        const { data } = await login({
          variables: { id, referral, renovationPlanId },
        });

        if (!data || !data?.loginAsUser.token) {
          throw new Error('Unable to get login link token');
        }

        return prepareLoginAsUserLink(
          data.loginAsUser.token,
          data.loginAsUser.redirectPath,
          renovationPlanId,
        );
      } catch (error) {
        onError(getErrorMessage(error));

        throw error;
      }
    },
    [login, onError],
  );

  return { fetchLoginLink };
};

export const useLoginAsUser = () => {
  const { fetchLoginLink } = useFetchLoginLink();

  const loginAsUser = useCallback(
    async (id: string, renovationPlanId: string) => {
      const windowRef = window.open();

      if (!windowRef) return;

      windowRef.document.write('Please wait while we redirect you');

      const url = await fetchLoginLink(
        id,
        AuthReferral.ADMIN,
        renovationPlanId,
      );

      windowRef.location.href = url!;
    },
    [fetchLoginLink],
  );

  return { loginAsUser };
};
