import SearchIcon from '@material-ui/icons/Search';

import { SearchInput } from 'common/components/Form/SearchInput/SearchInput';
import { FiltersMenu } from 'common/components/FiltersMenu/FiltersMenu';
import { BaseFilter } from 'common/components/Filters/filters.types';
import { ReactComponent as PencilIcon } from 'common/assets/icons/pencil.svg';
import { TextFieldSize } from 'common/enums/TextField';
import { Checkbox } from 'common/components/Form/Checkboxes/Checkbox';
import {
  ActionsWrapper,
  BulkEditButton,
  Header,
  SearchWrapper,
  SelectAllCheckboxWrapper,
} from 'Project/Procurement/Warehouse/WarehouseHeader/warehouseHeader.styles';
import { WarehouseZIndexMap } from 'Project/Procurement/Warehouse/warehouse.consts';

type Props = {
  allFilters: BaseFilter[];
  areAllBatchesSelected: boolean;
  clearAllFilters: () => void;
  handleSelectFilter: (selectedFilter: BaseFilter) => void;
  numberOfBatches: number;
  onShowBulkEditModal: () => void;
  onToggleSelectAllBatches: () => void;
  selectedBatchCount: number;
  selectedFilters: BaseFilter[];
  setSearchQuery: (search: string) => void;
};

export const WarehouseHeader = ({
  allFilters,
  areAllBatchesSelected,
  clearAllFilters,
  handleSelectFilter,
  numberOfBatches,
  onShowBulkEditModal,
  onToggleSelectAllBatches,
  selectedBatchCount,
  selectedFilters,
  setSearchQuery,
}: Props) => (
  <Header zIndex={WarehouseZIndexMap.warehouseHeaderHeader}>
    <ActionsWrapper>
      <SearchWrapper>
        <SearchInput
          endAdornment={false}
          onSearchChange={setSearchQuery}
          placeholder="Search by Product, SKU #"
          roundedBorders
          size={TextFieldSize.LARGE}
          startAdornment={<SearchIcon color="disabled" />}
        />
      </SearchWrapper>
      <BulkEditButton
        color="secondary"
        disabled={!selectedBatchCount}
        onClick={onShowBulkEditModal}
        size="medium"
        variant="contained"
      >
        <PencilIcon />
        Bulk Edit
      </BulkEditButton>
      <FiltersMenu
        allFilters={allFilters}
        clearAllFilters={clearAllFilters}
        handleSelectFilter={handleSelectFilter}
        selectedFilters={selectedFilters}
        size="medium"
      />
      <SelectAllCheckboxWrapper>
        <Checkbox
          aria-label="selectAllBatches"
          checked={areAllBatchesSelected}
          label={`Select All Batches (${numberOfBatches})`}
          labelPlacement="end"
          name="selectAllBatches"
          onChange={onToggleSelectAllBatches}
        />
      </SelectAllCheckboxWrapper>
    </ActionsWrapper>
  </Header>
);
