import styled from '@emotion/styled';

import addCircleBlue from 'common/assets/icons/add-circle-blue-outline.svg';
import dangerIcon from 'common/assets/icons/danger.svg';
import checkIcon from 'common/assets/icons/check-mark-green.svg';
import completedIcon from 'common/assets/icons/check-mark-gray.svg';

import { THEME } from 'common/consts/theme';

export enum Variant {
  addConfirm = 'addConfirm',
  completed = 'completed',
  danger = 'danger',
  info = 'info',
  secondary = 'secondary',
  success = 'success',
}

type Props = {
  variant?: keyof typeof Variant;
};

const StyleByVariantMap = {
  [Variant.success]: `
    background: ${THEME.color.green2};
    border: 1px solid ${THEME.color.green5};
    color: ${THEME.color.green10};

    &:before {
      background: url(${checkIcon}) 100% no-repeat;
      content: '';
      display: inline-block;
      height: 20px;
      margin-right: 10px;
      width: 20px;
    }
  `,
  [Variant.danger]: `
    background: ${THEME.color.red4};
    border: 1px solid ${THEME.color.red9};
    color: ${THEME.color.red9};
    padding-left: 10px;

    &:before {
      background: url(${dangerIcon}) 100% no-repeat;
      content: '';
      display: inline-block;
      height: 19px;
      margin-right: 10px;
      width: 19px;
    }
  `,
  [Variant.secondary]: `
    background: ${THEME.color.gray1};
    border: 1px solid ${THEME.color.gray9};
    color: ${THEME.color.gray9};
  `,
  [Variant.completed]: `
    border: 1px solid ${THEME.color.gray7};
    background: ${THEME.color.gray3};
    color: ${THEME.color.gray11};
    &:before {
      background: url(${completedIcon}) 100% no-repeat;
      content: '';
      display: inline-block;
      height: 20px;
      margin-right: 10px;
      width: 20px;
    }
  `,
  [Variant.info]: `
    border: 1px solid ${THEME.color.slate6};
    color: ${THEME.color.slate6};
    background: ${THEME.color.slate2};
  `,
  [Variant.addConfirm]: `
    color: ${THEME.color.blue11};
    &:before {
      background: url(${addCircleBlue}) 100% no-repeat;
      content: '';
      display: inline-block;
      height: 20px;
      margin-right: 10px;
      width: 20px;
    }
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  `,
};

export const Tag = styled.div<Props>`
  align-items: center;
  border-radius: 50px;
  display: inline-flex;
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
  justify-content: center;
  letter-spacing: 1px;
  padding: 2px 8px;
  position: relative;
  text-align: center;
  ${({ variant }) =>
    variant && variant in Variant
      ? StyleByVariantMap[variant as Variant]
      : StyleByVariantMap[Variant.secondary]}
`;
