import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

// Styles
import { ContainerGrid } from './LoadingSpinnerWithContainer.styles';

export const LoadingSpinnerWithContainer = () => (
  <ContainerGrid alignItems="center" container justify="center">
    <Grid item>
      <CircularProgress color="primary" data-testid="loading-spinner" />
    </Grid>
  </ContainerGrid>
);
