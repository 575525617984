import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

export const WrapperInner = styled.div`
  display: flex;
  gap: 16px;
`;

export const Label = styled.p`
  ${({ theme }) => theme.typography.header6};
  color: ${({ theme }) => theme.color.gray11};
`;

export const ControlWrapper = styled.div`
  width: 100%;
`;

export const Control = styled.input`
  ${({ theme }) => css`
    appearance: none;
    display: none;
    margin: 0;
    outline: 0;

    &:checked + label {
      background-color: ${theme.color.orange1};
      border-color: ${theme.color.orange5};
      color: ${theme.color.black};
    }

    &[disabled] + label {
      background-color: ${theme.color.gray1};
      border-color: ${theme.color.gray5};
      color: ${theme.color.gray11};
      cursor: not-allowed;
    }
  `}
`;

Control.defaultProps = { type: 'radio' };

export const ControlLabel = styled.label<{ hasError?: boolean }>`
  ${({ hasError, theme }) => css`
    ${theme.typography.body2};
    background-color: ${theme.color.white};
    border-radius: 4px;
    border: 1px solid ${hasError ? theme.color.red8 : theme.color.gray6};
    color: ${theme.color.gray11};
    cursor: pointer;
    display: inline-block;
    height: 100%;
    padding: 16px;
    text-align: center;
    width: 100%;
  `};
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.red11};
`;
