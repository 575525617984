import { ReactNode } from 'react';

import { ClassNames } from '@emotion/react';
import { Collapse } from '@material-ui/core';

import { useCollapseProps } from './Collapse.hooks';

type Props = {
  children?: ReactNode;
  className?: string;
  collapsedHeight?: number;
  unmountOnExit?: boolean;
};

export const CollapseContent = ({
  children = null,
  className,
  collapsedHeight = 0,
  unmountOnExit = false,
}: Props) => {
  const { isOpen } = useCollapseProps();

  return (
    <ClassNames>
      {({ css }) => (
        <Collapse
          classes={{
            wrapper: css(className),
          }}
          collapsedHeight={`${collapsedHeight}px`}
          in={isOpen}
          timeout="auto"
          unmountOnExit={unmountOnExit}
        >
          {children}
        </Collapse>
      )}
    </ClassNames>
  );
};
