import { useField } from 'formik';

import { PropertyType } from 'common/apollo/enums/PropertyType';

import { DualAnswer, Option } from 'common/shared/Question/question.types';

import { QuestionTooltip } from 'common/shared/Question/common/QuestionTooltip/QuestionTooltip';

import { useHasFormError } from 'common/hooks/useHasFormError';
import { shouldValidateDualFields } from 'common/utils/validations';

import { Select } from 'common/components/Select/Select';
import { SelectEvent } from 'common/components/Select/select.types';

import {
  ControlLabel,
  ControlWrapper,
  ErrorMessage,
  ExtraLabel,
  Label,
  LabelWrapper,
  Wrapper,
  WrapperInner,
} from 'common/shared/Question/DualDropdown/dualDropdown.styles';

export type Props = {
  id: string;
  infoDescription?: string;
  infoHeader?: string;
  isDisabled: boolean;
  isRequired: boolean;
  label: string;
  name: string;
  onChange?: (value: DualAnswer) => void;
  options: Option[];
  placeholder?: string;
};

export const DualDropdown = ({
  id,
  infoDescription,
  infoHeader,
  isDisabled,
  isRequired,
  label,
  name,
  onChange,
  options,
  placeholder,
}: Props) => {
  const [{ value }, , { setValue }] = useField({
    name,
    validate: shouldValidateDualFields(isRequired),
  });

  const error = useHasFormError(name);

  const handleChange = (
    { value: newValue }: SelectEvent,
    type: PropertyType,
  ) => {
    const newAnswer: DualAnswer = {
      desired: type === PropertyType.DESIRED ? newValue : value.desired,
      existing: type === PropertyType.EXISTING ? newValue : value.existing,
    };

    setValue(newAnswer);

    onChange?.(newAnswer);
  };

  const existingQuestions = options.filter(
    (option: Option) => option.type === PropertyType.EXISTING,
  );

  const desiredQuestions = options.filter(
    (option: Option) => option.type === PropertyType.DESIRED,
  );

  const showTooltip = !!infoHeader && !!infoDescription;

  return (
    <Wrapper>
      <LabelWrapper>
        <Label>{label}</Label>

        {showTooltip && (
          <QuestionTooltip
            infoDescription={infoDescription || ''}
            infoHeader={infoHeader || ''}
          />
        )}

        {!isRequired && <ExtraLabel>Optional</ExtraLabel>}
      </LabelWrapper>

      <WrapperInner>
        <ControlWrapper>
          <ControlLabel htmlFor={id + PropertyType.EXISTING}>
            Existing
          </ControlLabel>

          <Select
            disabled={isDisabled}
            hasError={!value?.existing && !!error}
            id={id + PropertyType.EXISTING}
            isClearable={!isRequired}
            onChange={(e) => handleChange(e, PropertyType.EXISTING)}
            options={existingQuestions}
            placeholder={placeholder}
            value={value?.existing}
          />
        </ControlWrapper>

        <ControlWrapper>
          <ControlLabel htmlFor={id + PropertyType.DESIRED}>
            Desired
          </ControlLabel>

          <Select
            disabled={isDisabled}
            hasError={!value?.desired && !!error}
            id={id + PropertyType.DESIRED}
            isClearable={!isRequired}
            onChange={(e) => handleChange(e, PropertyType.DESIRED)}
            options={desiredQuestions}
            placeholder={placeholder}
            value={value?.desired}
          />
        </ControlWrapper>
      </WrapperInner>

      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};
