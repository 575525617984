import { useState, ChangeEvent } from 'react';
import { useQuery } from '@apollo/client';

import { useField } from 'formik';

import { useToggle } from 'common/hooks/useToggle';

import {
  GetUsers,
  GetUsersVariables,
} from 'Users/__generated__/users.graphql.generated';

import { GET_USERS } from 'Users/users.graphql';

export const useReferer = () => {
  const [{ value }, , { setValue }] = useField('referrerId');
  const [searchValue, setSearchValue] = useState('');
  const { isOn: showList, toggleOff, toggleOn } = useToggle(false);

  const { data: usersData, loading: isLoading } = useQuery<
    GetUsers,
    GetUsersVariables
  >(GET_USERS, {
    variables: {
      input: {
        limit: 20,
        offset: 0,
        searchValue,
      },
    },
  });

  const users = usersData?.users || [];

  const handleSelect = (id: string) => {
    setValue(id);

    toggleOff();
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    toggleOn();
  };

  const handleBlur = () => {
    toggleOff();
  };

  const handleFocus = () => {
    if (!searchValue) return;

    toggleOn();
  };

  const handleClearSelection = () => {
    setValue('');
  };

  const selectedValue = value;

  const selectedUser = users.find((user) => user.id === selectedValue);

  const hasSelectedUser = !!selectedUser;

  return {
    handleBlur,
    handleClearSelection,
    handleFocus,
    handleSearchChange,
    handleSelect,
    hasSelectedUser,
    isLoading,
    searchValue,
    selectedUser,
    selectedValue,
    setSearchValue,
    showList,
    users,
  };
};
