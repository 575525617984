import { useState, useRef, useEffect, MutableRefObject } from 'react';

import 'intersection-observer';

export const useIntersectionObserver = <T extends HTMLElement>({
  onChange,
  root = null,
  rootMargin = '0px',
  threshold = 0,
}: IntersectionObserverInit & {
  onChange?: (entry: IntersectionObserverEntry) => void;
} = {}): [
  IntersectionObserverEntry | undefined,
  MutableRefObject<T | null>,
] => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const nodeRef = useRef<T | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(
      ([intersectionEntry]) => {
        setEntry(intersectionEntry);
        onChange?.(intersectionEntry);
      },
      { root, rootMargin, threshold },
    );

    const { current: currentObserver } = observer;

    if (nodeRef.current) currentObserver.observe(nodeRef.current);

    return () => currentObserver.disconnect();
  }, [nodeRef, onChange, root, rootMargin, threshold]);

  return [entry, nodeRef];
};
