import { ProjectMaterialStatus } from 'common/apollo/enums/ProjectMaterialStatus';

import { Variant } from 'common/components/Badge/Badge';

export const PROJECT_MATERIAL_STATUS_DICTIONARY = {
  [ProjectMaterialStatus.COMPLETED]: 'Completed',
  [ProjectMaterialStatus.ORDERED]: 'Ordered',
  [ProjectMaterialStatus.OUT_OF_STOCK]: 'Out of stock',
  [ProjectMaterialStatus.SHIPPED]: 'Shipped',
  [ProjectMaterialStatus.TBD]: 'TBD',
  [ProjectMaterialStatus.TO_ORDER]: 'To Order',
  [ProjectMaterialStatus.VOID]: 'Void',
  [ProjectMaterialStatus.REMOVED]: 'Removed',
};

export const PROJECT_MATERIAL_STATUS_COLOR_VARIANT: {
  [key in ProjectMaterialStatus]: Variant;
} = {
  [ProjectMaterialStatus.COMPLETED]: 'orange',
  [ProjectMaterialStatus.ORDERED]: 'purple',
  [ProjectMaterialStatus.OUT_OF_STOCK]: 'green',
  [ProjectMaterialStatus.SHIPPED]: 'blue',
  [ProjectMaterialStatus.TBD]: 'gray',
  [ProjectMaterialStatus.TO_ORDER]: 'yellow',
  [ProjectMaterialStatus.VOID]: 'blue',
  [ProjectMaterialStatus.REMOVED]: 'red',
};

const PROJECT_MATERIAL_STATUS_OPTIONS = [
  {
    label: PROJECT_MATERIAL_STATUS_DICTIONARY[ProjectMaterialStatus.COMPLETED],
    value: ProjectMaterialStatus.COMPLETED,
  },
  {
    label: PROJECT_MATERIAL_STATUS_DICTIONARY[ProjectMaterialStatus.ORDERED],
    value: ProjectMaterialStatus.ORDERED,
  },
  {
    label:
      PROJECT_MATERIAL_STATUS_DICTIONARY[ProjectMaterialStatus.OUT_OF_STOCK],
    value: ProjectMaterialStatus.OUT_OF_STOCK,
  },
  {
    label: PROJECT_MATERIAL_STATUS_DICTIONARY[ProjectMaterialStatus.SHIPPED],
    value: ProjectMaterialStatus.SHIPPED,
  },
  {
    label: PROJECT_MATERIAL_STATUS_DICTIONARY[ProjectMaterialStatus.TBD],
    value: ProjectMaterialStatus.TBD,
  },
  {
    label: PROJECT_MATERIAL_STATUS_DICTIONARY[ProjectMaterialStatus.TO_ORDER],
    value: ProjectMaterialStatus.TO_ORDER,
  },
];

export const PROJECT_MATERIAL_STATUS_FILTER_OPTIONS = [
  ...PROJECT_MATERIAL_STATUS_OPTIONS,
  {
    label: PROJECT_MATERIAL_STATUS_DICTIONARY[ProjectMaterialStatus.VOID],
    value: ProjectMaterialStatus.VOID,
  },
  {
    label: PROJECT_MATERIAL_STATUS_DICTIONARY[ProjectMaterialStatus.REMOVED],
    value: ProjectMaterialStatus.REMOVED,
  },
];
export const PROJECT_MATERIAL_STATUS_UPDATE_OPTIONS = [
  ...PROJECT_MATERIAL_STATUS_OPTIONS,
  {
    label: PROJECT_MATERIAL_STATUS_DICTIONARY[ProjectMaterialStatus.REMOVED],
    value: ProjectMaterialStatus.REMOVED,
  },
];
