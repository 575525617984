import { gql } from '@apollo/client';

export const GET_USER_DETAILS = gql`
  fragment UserRenovationPlanFields on RenovationPlan {
    id
    address {
      zip
      city
      state
      street
      street_2
    }
    projectType
  }

  query UserDetailsGet($id: ID!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      phoneNumber
      hasRenovationPlan @client
      currentRenovationPlan @client {
        ...UserRenovationPlanFields
      }
      renovationPlans {
        ...UserRenovationPlanFields
      }
      currentProject @client {
        id
      }
      projects {
        id
      }
    }
  }
`;

export const GET_USER_BASIC_DETAILS = gql`
  query UserBasicDetailsGet($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
    }
  }
`;
