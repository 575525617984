import { SelectType } from 'common/types/select';

import { floatToCurrencyString } from 'common/utils/currencyFormatter';

import {
  Section,
  SectionContent,
  SectionHeader,
  SectionSubtitle,
  SectionTitle,
} from 'Project/Procurement/common/components/Layout/Order';

import { OrderInfoForm } from 'Project/Procurement/CreateOrder/OrderInfo/OrderInfoForm/OrderInfoForm';

type Props = { expectedMaterialsCost: number; vendors: SelectType[] };

export const OrderInfo = ({ expectedMaterialsCost, vendors }: Props) => (
  <Section>
    <SectionHeader>
      <SectionTitle>Order Info</SectionTitle>
    </SectionHeader>

    <SectionSubtitle>
      Expected Cost: {floatToCurrencyString(expectedMaterialsCost)}
    </SectionSubtitle>

    <SectionContent>
      <OrderInfoForm vendors={vendors} />
    </SectionContent>
  </Section>
);
