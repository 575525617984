import { useMutation } from '@apollo/client';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useAlert } from 'common/hooks/useAlert';

import { useModal } from 'common/components/Modal/Modal';

import { ProjectMaterialStatus } from 'common/apollo/enums/ProjectMaterialStatus';

import { UpdateProjectProcurementMaterialRequest } from 'Project/Procurement/common/apollo/requests/updateProjectProcurementMaterialRequest';
import { UpdateProjectProcurementMaterialResponse } from 'Project/Procurement/common/apollo/responses/updateProjectProcurementMaterialResponse';

import { Material } from 'Project/Procurement/Materials/materials.types';

import { UPDATE_PROJECT_MATERIAL } from 'Project/Procurement/Materials/UpdateMaterial/updateMaterial.graphql';

import { UpdateMaterialFormValues } from 'Project/Procurement/Materials/UpdateMaterial/updateMaterial.types';
import { FeatureFlags } from 'common/enums/FeatureFlags';

export const useUpdateMaterial = (material: Material | null) => {
  const { onCompleted, onError } = useAlert();
  const { onClose: handleClose } = useModal();

  const [updateMaterial] = useMutation<
    UpdateProjectProcurementMaterialResponse,
    UpdateProjectProcurementMaterialRequest
  >(UPDATE_PROJECT_MATERIAL, {
    onCompleted: () => {
      handleClose();
      onCompleted('Material successfully updated');
    },
    onError: (error) => onError(error.message),
  });

  const handleSubmit = ({
    pricingEngineId,
    ...values
  }: UpdateMaterialFormValues) => {
    const input = Object.entries({
      ...values,
      ...(material?.generated ? {} : { pricingEngineId }),
    }).reduce(
      (acc, [key, value]) => (value ? { ...acc, [key]: value } : acc),
      {},
    );

    updateMaterial({
      variables: {
        input: {
          ...input,
          projectMaterialId: material?.id || '',
        },
      },
    });
  };

  const initialValues = {
    amount: material?.amount || 0,
    pricingEngineId: material?.roomId || '',
    status: material?.status || ('' as ProjectMaterialStatus),
  };

  const flags = useFlags();
  const postDsoEnabled = flags[FeatureFlags.POST_DSO];

  return { handleClose, handleSubmit, initialValues, postDsoEnabled };
};
