import { Grid } from '@material-ui/core';

import { Container } from 'ProjectProfile/common/components/Container/Container';
import { Section } from 'ProjectProfile/common/components/Section/Section';
import { SectionOutlined } from 'ProjectProfile/common/components/Section/SectionOutlined';

import { ClientDetailsEdit } from 'ProjectProfile/ProjectInformation/v1/ClientDetails/ClientDetailsEdit';

import { RoleLabelType } from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';

import { ReferredBy } from 'ProjectProfile/ProjectInformation/v1/ClientDetails/ReferredBy/ReferredBy';

import { useClientDetails } from './clientDetails.hooks';

export const ClientDetails = () => {
  const {
    answers,
    handleSuccessfulSubmit,
    hasReferrer,
    importances,
    isLoading,
    isOn: isOpen,
    referrer,
    toggleOff: close,
    toggleOn: open,
  } = useClientDetails();

  const {
    CLIENT_NOTES,
    DECISION_MAKERS,
    FLEXIBILITY,
    INVOLVEMENT,
    IS_THE_CLIENT_LIVING_THROUGH_THE_RENOVATION,
    OWNERSHIP_STATUS,
    PAYMENT_PREFERENCE,
    PREVIOUS_RENOVATION_EXPERIENCE,
    PROJECT_BUDGET,
    PROJECT_EXTENSION_NOTES,
    RENOVATION_TIMELINE,
    TIMELINE_PREFERENCES,
  } = answers;

  return (
    <>
      <Container
        onEditClick={open}
        roles={[RoleLabelType.PROJECT_PLANNER]}
        title="Homeowner Details"
      >
        <Grid container spacing={4}>
          <Grid item md={6} sm={12}>
            <Section isLoading={isLoading} title="Budget">
              {PROJECT_BUDGET}
            </Section>
          </Grid>
          <Grid item md={6} sm={12}>
            <Section isLoading={isLoading} title="Budget flexibility">
              {FLEXIBILITY}
            </Section>
          </Grid>
          <Grid item md={6} sm={12}>
            <Section
              isLoading={isLoading}
              title="Homeowner living through renovation?"
            >
              {IS_THE_CLIENT_LIVING_THROUGH_THE_RENOVATION}
            </Section>
          </Grid>
          <Grid item md={6} sm={12}>
            <ReferredBy hasReferrer={hasReferrer} referrer={referrer} />
          </Grid>

          <Grid item md={6} sm={12}>
            <Section isLoading={isLoading} title="Homeowner Involvement">
              {INVOLVEMENT}
            </Section>
          </Grid>
          <Grid item md={6} sm={12}>
            <Section isLoading={isLoading} title="Renovation TIMELINE">
              {RENOVATION_TIMELINE}
            </Section>
          </Grid>

          <Grid item md={6} sm={12}>
            <Section
              isImportant={importances.PREVIOUS_RENOVATION_EXPERIENCE}
              isLoading={isLoading}
              title="Previous renovation experience?"
            >
              {PREVIOUS_RENOVATION_EXPERIENCE}
            </Section>
          </Grid>
          <Grid item md={6} sm={12}>
            <Section
              isImportant={importances.DECISION_MAKERS}
              isLoading={isLoading}
              title="Decision Makers"
            >
              {DECISION_MAKERS}
            </Section>
          </Grid>

          <Grid item md={6} sm={12}>
            <Section isLoading={isLoading} title="Payment Preference">
              {PAYMENT_PREFERENCE}
            </Section>
          </Grid>
          <Grid item md={6} sm={12}>
            <Section isLoading={isLoading} title="Ownership Status">
              {OWNERSHIP_STATUS}
            </Section>
          </Grid>

          <Grid item md={10} sm={10} xs={12}>
            <SectionOutlined
              isImportant={importances.TIMELINE_PREFERENCES}
              isLoading={isLoading}
              title="TIMELine preferences"
            >
              {TIMELINE_PREFERENCES}
            </SectionOutlined>
          </Grid>
          <Grid item md={10} sm={10} xs={12}>
            <SectionOutlined
              isImportant={importances.CLIENT_NOTES}
              isLoading={isLoading}
              title="Homeowner notes"
            >
              {CLIENT_NOTES}
            </SectionOutlined>
          </Grid>
          <Grid item md={10} sm={10} xs={12}>
            <SectionOutlined
              isImportant={importances.PROJECT_EXTENSION_NOTES}
              isLoading={isLoading}
              title="Project extension notes"
            >
              {PROJECT_EXTENSION_NOTES}
            </SectionOutlined>
          </Grid>
        </Grid>
      </Container>

      {isOpen && (
        <ClientDetailsEdit
          close={close}
          handleSuccessfulSubmit={handleSuccessfulSubmit}
        />
      )}
    </>
  );
};
