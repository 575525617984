import styled from '@emotion/styled';

export const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.alto};
  display: grid;
  grid-template-rows: min-content auto min-content;
  padding: 16px;
  row-gap: 6px;
`;

export const Title = styled.div`
  align-items: center;
  display: flex;
  font: 400 12px/16px ${({ theme }) => theme.font.inter};
  justify-content: space-between;
  text-transform: capitalize;

  > svg {
    cursor: pointer;
  }
`;

export const Name = styled.span`
  font: 400 16px/24px ${({ theme }) => theme.font.inter};
`;
