import styled from '@emotion/styled';

import MuiDialogTitle, {
  DialogTitleProps,
} from '@material-ui/core/DialogTitle';

const DialogTitleStyled = styled(MuiDialogTitle)`
  margin-right: 30px;

  h2 {
    font-size: 20px;
  }
`;

export const DialogTitle = (props: DialogTitleProps) => (
  <DialogTitleStyled {...props} />
);
