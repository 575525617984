import { useParams } from 'react-router-dom';

import { QueryParams } from 'ProjectProfile/common/types/queryParams';

import { useToggle } from 'common/hooks/useToggle';

import { useProjectPlanLinks } from 'ProjectProfile/common/hooks/useProjectPlanLinks';
import { useProjectPlanRooms } from 'ProjectProfile/common/hooks/useProjectPlanRooms';
import { useProjectPlanRoomsLinks } from 'ProjectProfile/common/hooks/useProjectPlanRoomsLinks';

const useRoomsLinks = () => {
  const { projectId } = useParams<QueryParams>();

  const { data, isLoading: isLoadingProjectPlanRooms } = useProjectPlanRooms({
    projectId,
  });

  const pricingEngineIds = (data || []).map(({ id }) => String(id));

  const { isLoadingPlanRoomsLinks, projectPlanRoomsLinks } =
    useProjectPlanRoomsLinks({
      pricingEngineIds,
    });

  return {
    isLoadingRoomLinks: isLoadingPlanRoomsLinks && isLoadingProjectPlanRooms,
    projectPlanRoomsLinks,
  };
};

export const useLinks = () => {
  const { projectId } = useParams<QueryParams>();
  const { isOn, toggleOff, toggleOn } = useToggle(false);

  const { isLoadingRoomLinks, projectPlanRoomsLinks } = useRoomsLinks();
  const { isLoadingProjectPlanLinks, projectPlanLinks } = useProjectPlanLinks({
    projectId,
  });

  return {
    isLoading: isLoadingRoomLinks || isLoadingProjectPlanLinks,
    isOn,
    projectId,
    projectPlanLinks,
    projectPlanRoomsLinks,
    toggleOff,
    toggleOn,
  };
};
