import { Button } from 'common/components/Button/Button';

import { useProposalMaterialsQuantitiesError } from 'PricingEngineSidebar/Proposal/ProposalAction/ValidationErrorModal/validationErrorModal.hooks';

import { GetProposalSideBardActionDataPricingEngine } from 'PricingEngineSidebar/Proposal/ProposalAction/__generated__/proposalAction.graphql.generated';

import { ProposalRoomNumberError } from 'PricingEngineSidebar/Proposal/ProposalAction/ValidationErrorModal/ProposalRoomNumberError/ProposalRoomNumberError';

import { MaterialsQuantityError } from 'PricingEngineSidebar/Proposal/ProposalAction/ValidationErrorModal/MaterialsQuantityError/MaterialsQuantityError';

import {
  ButtonsWrapper,
  ErrorContent,
  Header,
  Wrapper,
} from './validationErrorModal.styles';

type Props = {
  affectedPricingEngines: GetProposalSideBardActionDataPricingEngine[];
  numberOfPricingEngines: number;
  publishAction: () => void;
};

export const ValidationErrorModal = ({
  affectedPricingEngines,
  numberOfPricingEngines,
  publishAction,
}: Props) => {
  const {
    materialsQuantityValidation,
    onModalCloseButton,
    roomNumberValidation,
  } = useProposalMaterialsQuantitiesError(
    numberOfPricingEngines,
    affectedPricingEngines,
  );

  return (
    <Wrapper>
      <Header>Validation error</Header>
      <ErrorContent>
        {materialsQuantityValidation && (
          <MaterialsQuantityError
            affectedPricingEngines={affectedPricingEngines}
          />
        )}
        {materialsQuantityValidation && roomNumberValidation && <hr />}
        {roomNumberValidation && (
          <ProposalRoomNumberError roomsNumber={numberOfPricingEngines} />
        )}
      </ErrorContent>

      <ButtonsWrapper>
        <Button
          color="primary"
          onClick={onModalCloseButton}
          small
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            publishAction();
          }}
          small
          variant="contained"
        >
          Publish Anyway
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
};
