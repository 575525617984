import { ReactNode } from 'react';

import MuiTooltip, { TooltipProps } from '@material-ui/core/Tooltip';

import { Content } from 'common/components/Tooltip/tooltip.styles';
import { getTooltipStyles } from 'common/components/Tooltip/tooltip.utils';

type Props = {
  backgroundColor?: string;
  children: ReactNode;
  color?: string;
  content: string | string[] | ReactNode;
  useShadow?: boolean;
} & Omit<TooltipProps, 'title' | 'classes'>;

export const Tooltip = ({
  backgroundColor,
  color,
  content,
  useShadow,
  ...props
}: Props) => {
  const styles = getTooltipStyles({ backgroundColor, color, useShadow });

  let inner: ReactNode;
  if (Array.isArray(content)) {
    inner = (content as string[]).map((value) => (
      <Content key={value as string}>{value}</Content>
    ));
  } else {
    inner = <Content>{content}</Content>;
  }

  return (
    <MuiTooltip
      {...props}
      classes={{ arrow: styles.arrow, tooltip: styles.tooltip }}
      title={<>{inner}</>}
    />
  );
};
