import { ChangeEvent, useRef } from 'react';
import * as React from 'react';
import Downshift, { DownshiftState, StateChangeOptions } from 'downshift';

import { TagGroupType } from 'common/apollo/enums/TagGroupType';
import { TextFieldSize } from 'common/enums/TextField';
import { SelectType } from 'common/types/select';
import {
  SelectControlMap,
  SelectMenuMap,
} from 'common/components/Form/Select/select.consts';

import { SelectWrapper } from './Select.styles';
import { getSelectedItem, getOptionValue, stateReducer } from './select.utils';

export type Props<Option> = {
  className?: string;
  currentLabel?: string;
  customStateReducer?:
    | ((
        state: DownshiftState<any>,
        changes: StateChangeOptions<any>,
      ) => StateChangeOptions<any>)
    | undefined;
  disablePortal?: boolean;
  errorMessage?: string;
  helperText?: string;
  id?: string;
  innerRef?: React.RefObject<HTMLInputElement>;

  inputRef?: React.RefObject<HTMLInputElement>;
  isClearable?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  label?: string;
  name?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (value: Option) => void;
  onSelect?: (value: Option) => void;
  onSelectControlChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  openMenuOnFocus?: boolean;
  options?: Array<SelectType<Option> | Option>;

  placeholder?: string;
  selectionType?: TagGroupType;
  size?: TextFieldSize;
  value?: Option;
};

export const Select = <Option,>({
  currentLabel,
  customStateReducer,
  disablePortal,
  innerRef,
  inputRef,
  isDisabled,
  onChange,
  onSelect,
  options = [],
  selectionType,
  value,
  ...props
}: Props<Option>) => {
  const defaultSelectRef = useRef<HTMLInputElement>(null);
  const selectRef = innerRef || defaultSelectRef;

  const handleChange = (selected: SelectType<Option>) => {
    const selectedValue = getOptionValue(selected);
    onChange?.(selectedValue);
  };

  const handleSelect = (selected: SelectType<Option>) => {
    const selectedValue = getOptionValue(selected);
    onSelect?.(selectedValue);
  };

  const Control = SelectControlMap()[selectionType ?? TagGroupType.SINGLE];
  const Menu = SelectMenuMap()[selectionType ?? TagGroupType.SINGLE];

  return (
    <Downshift
      {...(currentLabel ? { inputValue: currentLabel } : {})}
      itemToString={(item) => item?.label || item || ''}
      onChange={handleChange}
      onSelect={handleSelect}
      selectedItem={getSelectedItem(options, value)}
      stateReducer={(state, changes) => {
        if (customStateReducer) {
          return customStateReducer(state, changes);
        }

        return stateReducer(state, changes, selectionType);
      }}
    >
      {(downshift) => (
        <SelectWrapper {...downshift.getRootProps()} isDisabled={isDisabled}>
          <Control
            downshift={downshift}
            inputRef={inputRef}
            isDisabled={isDisabled}
            options={options}
            selectRef={selectRef}
            {...props}
          />
          <Menu
            disablePortal={disablePortal}
            downshift={downshift}
            options={options}
            selectRef={selectRef}
          />
        </SelectWrapper>
      )}
    </Downshift>
  );
};
