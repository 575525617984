import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Paper } from '@material-ui/core';

import { Button } from 'common/components/Buttons/Button/Button';

export const textFieldStyles = css`
  margin-right: 4px;

  input {
    font-size: 13px;
  }
`;

export const iconStyles = css`
  font-size: 22px;
`;

export const PopperContent = styled(Paper)`
  padding: 4px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const CopyButton = styled(Button)`
  && {
    padding: 11px;
  }
  min-width: 48px;
`;
