/* eslint-disable jsx-a11y/control-has-associated-label */
import { Button } from 'common/components/Button/Button';
import { IconButton } from 'common/_components/IconButton/IconButton';
import { Modal } from 'common/components/Modal/Modal';

import { ReactComponent as TrashIcon } from 'common/assets/icons/delete-outline.svg';
import { ReactComponent as EditIcon } from 'common/assets/icons/edit-outline.svg';

import { OrderMaterial } from 'Project/Procurement/common/types/orderMaterial';

import {
  Section,
  SectionAction,
  SectionContent,
  SectionHeader,
  SectionTitle,
} from 'Project/Procurement/common/components/Layout/Order';

import { useOrderMaterials } from 'Project/Procurement/CreateOrder/OrderMaterials/orderMaterials.hooks';

import {
  ActionWrapper,
  EmptyState,
  SizeWrapper,
  Table,
  TableWrapper,
} from 'Project/Procurement/CreateOrder/OrderMaterials/orderMaterials.styles';

import { UpdateMaterialUnits } from 'Project/Procurement/CreateOrder/OrderMaterials/UpdateMaterialUnits/UpdateMaterialUnits';

type Props = {
  materials: OrderMaterial[];
  onMaterialRemove: (id: string) => void;
  onMaterialUpdate: (id: string, amount: number) => void;
};

export const OrderMaterials = ({
  materials,
  onMaterialRemove,
  onMaterialUpdate,
}: Props) => {
  const {
    handleCloseUpdateMaterialUnitsModal,
    handleCopySkuInfo,
    handleCopyTable,
    handleOpenUpdateMaterialModal,
    isOrderMaterialUnitsUpdateModalOpen,
    selectedMaterial,
  } = useOrderMaterials(materials);

  return (
    <Section>
      {materials.length ? (
        <>
          <SectionHeader>
            <SectionTitle>SKUS in Order</SectionTitle>

            <SectionAction>
              <Button onClick={handleCopyTable} small>
                Copy Table
              </Button>

              <Button onClick={handleCopySkuInfo} small>
                Copy Sku Info
              </Button>
            </SectionAction>
          </SectionHeader>

          <SectionContent>
            <TableWrapper>
              <Table>
                <thead>
                  <tr>
                    <th />
                    <th>Subcategory</th>
                    <th>Product</th>
                    <th>Color</th>
                    <th>Finish</th>
                    <th>Size</th>
                    <th>Pattern</th>
                    <th>SKU ID</th>
                    <th>Vendor SKU ID</th>
                    <th>Total Units</th>
                    <th>Units Shipped</th>
                    <th>Units Received</th>
                  </tr>
                </thead>

                <tbody>
                  {materials.map(
                    ({
                      color,
                      finish,
                      id,
                      name,
                      pattern,
                      size,
                      skuId,
                      subcategory,
                      totalUnits,
                      unitsReceived,
                      unitsShipped,
                      vendorSkuId,
                    }) => (
                      <tr key={id}>
                        <td>
                          <ActionWrapper>
                            <IconButton onClick={() => onMaterialRemove(id)}>
                              <TrashIcon />
                            </IconButton>

                            <IconButton
                              onClick={() => handleOpenUpdateMaterialModal(id)}
                            >
                              <EditIcon />
                            </IconButton>
                          </ActionWrapper>
                        </td>
                        <td>{subcategory}</td>
                        <td>{name}</td>
                        <td>{color}</td>
                        <td>{finish}</td>
                        <td>
                          <SizeWrapper>{size}</SizeWrapper>
                        </td>
                        <td>{pattern}</td>
                        <td>{skuId}</td>
                        <td>{vendorSkuId}</td>
                        <td>{totalUnits}</td>
                        <td>{unitsShipped}</td>
                        <td>{unitsReceived}</td>
                      </tr>
                    ),
                  )}
                </tbody>
              </Table>
            </TableWrapper>
          </SectionContent>

          <Modal
            onClose={handleCloseUpdateMaterialUnitsModal}
            open={isOrderMaterialUnitsUpdateModalOpen}
          >
            <UpdateMaterialUnits
              material={selectedMaterial}
              onSubmit={onMaterialUpdate}
            />
          </Modal>
        </>
      ) : (
        <EmptyState>No materials found</EmptyState>
      )}
    </Section>
  );
};
