import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled.p`
  ${({ theme }) => theme.typography.header6};
  color: ${({ theme }) => theme.color.gray11};
`;

export const ControlWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const ControlLabel = styled.label`
  ${({ theme }) => theme.typography.header6};
  cursor: pointer;
  color: ${({ theme }) => theme.color.gray12};
`;

export const Control = styled.input`
  ${({ theme }) => css`
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid ${theme.color.gray6};
    box-shadow: 0 0 0 4px;
    color: transparent;
    cursor: pointer;
    height: 20px;
    margin: 0;
    position: relative;
    width: 20px;

    &:focus {
      background-color: ${theme.color.gray2};
      color: ${theme.color.gray2};
      outline: 0;
    }

    &:hover {
      background-color: ${theme.color.gray2};
      color: ${theme.color.gray2};
    }

    &:active {
      background-color: ${theme.color.orange4};
      color: ${theme.color.orange4};
    }

    &[disabled] {
      background-color: transparent;
      border-color: ${theme.color.gray6};
      color: transparent;
      cursor: not-allowed;
      outline: 0;
    }

    &:checked {
      border-color: ${theme.color.orange8};
    }

    &:checked::before {
      background-color: ${theme.color.orange8};
      border-radius: 50%;
      content: '';
      display: block;
      height: 8px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
    }

    &:checked:focus,
    &:checked:hover,
    &:checked:active {
      background-color: ${theme.color.orange2};
      box-shadow: 0 0 0 4px ${theme.color.orange2};
    }

    &:checked[disabled] {
      background: transparent;
      border-color: ${theme.color.gray6};
      color: transparent;
    }

    &:checked[disabled]::before {
      background-color: ${theme.color.gray6};
    }

    &[disabled]:focus,
    &[disabled]:hover,
    &[disabled]:active {
      box-shadow: none;
    }

    &[disabled] + label {
      color: ${theme.color.gray11};
      cursor: not-allowed;
    }
  `}
`;

Control.defaultProps = { type: 'radio' };

export const ErrorMessage = styled.p`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.red11};
`;

export const Description = styled.p`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.gray11};
`;
