import styled from '@emotion/styled';
import { css } from '@emotion/react';
import MuiTextField from '@material-ui/core/TextField';

import InputAdornment, {
  InputAdornmentProps,
} from '@material-ui/core/InputAdornment';

import { TextFieldSize } from 'common/enums/TextField';
import { skipForwardProps } from 'common/utils/styles';

type Props = {
  multiline?: boolean;
  roundedBorders?: boolean;
  sizeCustom: TextFieldSize;
};

const textFieldStyles = css`
  fieldset {
    border-radius: 0;
  }

  &&.MuiInputBase-input {
    height: auto;
  }
`;

const mediumLabelStyles = css`
  label {
    &.MuiInputLabel-outlined {
      transform: translate(8px, 12px) scale(1);
    }
    &.MuiInputLabel-shrink {
      transform: translate(0px, 0px) scale(0.75);

      &.MuiInputLabel-filled {
        transform: translate(8px, -8px) scale(0.7);
      }
      &.MuiInputLabel-outlined {
        transform: translate(14px, -6px) scale(0.75);
      }
    }
  }
`;

const largeLabelStyles = css`
  label {
    &.MuiInputLabel-outlined {
      transform: translate(8px, 12px) scale(1);
    }
    &.MuiInputLabel-shrink {
      transform: translate(0px, 0px) scale(0.75);

      &.MuiInputLabel-filled {
        transform: translate(8px, -8px) scale(0.7);
      }
      &.MuiInputLabel-outlined {
        transform: translate(14px, -6px) scale(0.75);
      }
    }
  }
`;

const extraLargeFieldStyles = css`
  .MuiInputBase-root {
    box-sizing: border-box;
    display: flex;
    height: 56px;
    padding: 0;
  }
  input {
    padding: 16px;
  }

  label {
    &.MuiInputLabel-outlined {
      transform: translate(16px, 20px) scale(1);
    }
    &.MuiInputLabel-shrink {
      transform: translate(0px, 0px) scale(0.75);

      &.MuiInputLabel-filled {
        transform: translate(8px, -8px) scale(0.7);
      }
      &.MuiInputLabel-outlined {
        transform: translate(14px, -6px) scale(0.75);
      }
    }
  }
`;

const largeFieldStyles = css`
  .MuiInputBase-root {
    box-sizing: border-box;
    display: flex;
    height: 40px;
    padding: 0;
  }
  input {
    padding: 10px;
  }

  ${largeLabelStyles}
`;

const mediumFieldStyles = css`
  .MuiInputBase-root {
    box-sizing: border-box;
    display: flex;
    height: 36px;
    padding: 0;
  }
  input {
    padding: 6px 6px 7px 6px;
  }

  ${mediumLabelStyles}
`;

const smallFieldStyles = css`
  label {
    &.MuiFormLabel-root {
      font-size: 14px;
      transform: translate(2px, 10px) scale(1);

      &.MuiInputLabel-filled {
        transform: translate(8px, 4px) scale(1);
      }
      &.MuiInputLabel-outlined {
        transform: translate(14px, 2px) scale(1);
      }
    }
    &.MuiInputLabel-shrink {
      transform: translate(0px, 0px) scale(0.75);

      &.MuiInputLabel-filled {
        transform: translate(8px, -8px) scale(0.7);
      }
      &.MuiInputLabel-outlined {
        transform: translate(14px, -6px) scale(0.75);
      }
    }
  }

  .MuiInputBase-root,
  .MuiInputBase-input {
    box-sizing: border-box;
    display: flex;
    font-size: 14px;
    height: 18px;
    padding: 0px;
    input {
      padding: 0px 6px;
    }
  }
`;

const multilineStyles = css`
  .MuiInputBase-root {
    box-sizing: border-box;
    display: flex;
    height: auto;
    min-height: 36px;
    padding: 0;
    textarea {
      padding: 8px;
    }
  }

  ${largeLabelStyles}
`;

const roundedBordersStyles = css`
  fieldset {
    border-radius: 4px;
  }
`;

const fieldStyle = ({ multiline, sizeCustom }: Props) => {
  if (multiline) {
    return multilineStyles;
  }

  switch (sizeCustom) {
    case TextFieldSize.EXTRA_LARGE: {
      return extraLargeFieldStyles;
    }
    case TextFieldSize.LARGE: {
      return largeFieldStyles;
    }

    case TextFieldSize.MEDIUM: {
      return mediumFieldStyles;
    }

    default:
      return smallFieldStyles;
  }
};

export const StyledTextField = styled(MuiTextField, {
  shouldForwardProp: skipForwardProps(['sizeCustom', 'roundedBorders']),
})<Props>`
  ${textFieldStyles}
  ${({ roundedBorders }) => roundedBorders && roundedBordersStyles}
  ${({ multiline, sizeCustom }) => fieldStyle({ multiline, sizeCustom })}
`;

export const StyledInputAdornment = styled(InputAdornment)<InputAdornmentProps>`
  p {
    max-width: 40px;
    font-size: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  margin-${({ position }) => (position === 'start' ? 'left' : 'right')}: 8px;
`;
