import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Typography } from 'common/components/Typography/Typography';
import { TextField } from 'common/components/Form/TextField/TextField';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 11.5px 16px;
  background-color: ${({ theme }) => theme.color.gray2};
`;

export const DialogTitle = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.gray5};
  padding: 20px 30px;
`;

export const InputTitle = styled(Typography)`
  color: ${({ theme }) => theme.color.black};
  display: inline;
  padding-bottom: 4px;
`;

export const OptionalTitle = styled(Typography)`
  display: inline;
  margin-left: 5px;
  padding-bottom: 4px;
`;

export const AdditionalNotes = styled(TextField)`
  padding-bottom: 30px;
`;

type WrapperProps = {
  isBordered?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  padding: 22px 30px 30px 30px;
  ${({ isBordered, theme }) => css`
    ${isBordered &&
    css`
      border-bottom: 1px solid ${theme.color.gray5};
    `}
  `}
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateInputRow = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 1fr 1fr;
`;

export const InputRow = styled.div`
  padding-top: 25px;
`;

export const InputWrapper = styled.div``;

type StatusProps = {
  isLate: boolean;
  isNotifying: boolean;
};

export const Status = styled.div<StatusProps>`
  border: 1px solid ${({ theme }) => theme.color.green8};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.green3};

  ${({ isLate, theme }) => css`
    ${isLate &&
    css`
      border: 1px solid ${theme.color.red8};
      background-color: ${theme.color.red4};
    `}
  `}

  ${({ isNotifying, theme }) => css`
    ${isNotifying &&
    css`
      border: 1px solid ${theme.color.yellow6};
      background-color: ${theme.color.yellow2};
    `}
  `}
`;

export const StatusMessageWrapper = styled.div`
  font: normal 14px/20px ${({ theme }) => theme.font.inter};
  padding: 16px;
  svg {
    margin-right: 10px;
  }
`;

export const TargetDatesWrapper = styled.div`
  display: grid;
  font: normal 16px/24px ${({ theme }) => theme.font.inter};
  grid-column-gap: 10px;
  grid-template-columns: 1fr 1fr;
  padding-top: 24px;
`;

export const CurrentTargetDate = styled.div``;

export const OriginalTargetDate = styled.div`
  color: ${({ theme }) => theme.color.gray11};
`;
