import { AUTH_PATHS } from 'common/routes/routerPaths';
import { FeatureFlags } from 'common/enums/FeatureFlags';

export const NAVIGATION_MENU_ITEMS = [
  {
    links: [
      { label: 'Users', to: AUTH_PATHS.USERS },
      { label: 'Projects', to: AUTH_PATHS.PROJECTS },
    ],
    title: 'Proposal',
  },
  {
    links: [
      {
        label: 'Team Members',
        to: AUTH_PATHS.TEAM_MEMBERS,
      },
      { label: 'Contractors', to: AUTH_PATHS.CONTRACTORS },
      {
        label: 'Schedule Templates',
        to: AUTH_PATHS.SCHEDULE_TEMPLATES,
      },
      {
        label: 'All Schedules',
        to: AUTH_PATHS.ALL_SCHEDULES,
      },
      {
        label: 'Content Management',
        to: AUTH_PATHS.WORK_ORDER_CONTENT,
      },
      {
        label: 'User Accounts',
        to: AUTH_PATHS.USER_ACCOUNTS,
      },
      {
        label: 'Organizations',
        to: AUTH_PATHS.ORGANIZATIONS,
      },
      {
        label: 'Materials Database',
        to: AUTH_PATHS.MATERIALS_CATALOG,
      },
      {
        featureFlag: FeatureFlags.ADMIN_PAYOUTS,
        label: 'Payouts',
        to: AUTH_PATHS.PAYOUTS,
      },
    ],
    title: 'Operations',
  },
];
