import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { isFunction } from 'common/guards/function';

import { CollapseWrapper } from './Collapse.styles';

type CollapseContentState = {
  hasBottomBorder?: boolean;
  isOpen?: boolean;
  lightMode?: boolean;
  onCollapseToggle?: () => void;
};

export const CollapseContentContext = createContext<CollapseContentState>({
  hasBottomBorder: false,
  isOpen: false,
  lightMode: false,
  onCollapseToggle: () => {},
});

export type Props = {
  children: ((context: CollapseContentState) => ReactNode) | ReactNode;
  className?: string;
  hasBottomBorder?: boolean;
  isOpen?: boolean;
  lightMode?: boolean;
};

export const Collapse = ({
  children,
  className,
  hasBottomBorder = false,
  isOpen = false,
  lightMode = false,
  ...rest
}: Props) => {
  const [isItemOpen, setIsItemOpen] = useState(isOpen);

  useEffect(() => {
    setIsItemOpen(isOpen);
  }, [isOpen]);

  const onCollapseToggle = useCallback(() => {
    setIsItemOpen((expanded) => !expanded);
  }, [setIsItemOpen]);

  const value = useMemo(
    () => ({
      isOpen: isItemOpen,
      lightMode,
      onCollapseToggle,
    }),
    [isItemOpen, lightMode, onCollapseToggle],
  );

  return (
    <CollapseContentContext.Provider value={value}>
      <CollapseWrapper
        className={className}
        hasBottomBorder={hasBottomBorder}
        isOpen={isItemOpen}
        lightMode={lightMode}
        {...rest}
      >
        {!isFunction(children) ? children : children(value)}
      </CollapseWrapper>
    </CollapseContentContext.Provider>
  );
};
