import { useField, FieldValidator } from 'formik';

import { useHasFormError } from 'common/hooks/useHasFormError';

import { Props as SelectProps, Select } from './Select';

type BaseProps<Option> = Omit<SelectProps<Option>, 'name'>;

export type Props<Option> = BaseProps<Option> & {
  name: string;
  validate?: FieldValidator;
};

export const FormSelect = <Option,>({
  name,
  onChange,
  validate,
  ...props
}: Props<Option>) => {
  const [{ onBlur, value }, , { setValue }] = useField({ name, validate });
  const errorMessage = useHasFormError(name);

  const handleChange = (selected: Option) => {
    setValue(selected ?? '');
    onChange?.(selected);
  };

  return (
    <Select
      errorMessage={errorMessage}
      name={name}
      value={value}
      {...props}
      onBlur={onBlur}
      onChange={handleChange}
    />
  );
};
