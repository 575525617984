import styled from '@emotion/styled';

export const FieldsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  row-gap: 16px;
`;

export const FormCheckboxWrapper = styled.div`
  .MuiFormControl-root {
    margin-left: -11px;
  }
`;
