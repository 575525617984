import styled from '@emotion/styled';

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const Title = styled.div`
  font: 600 14px/18px ${({ theme }) => theme.font.inter};
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const LookWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 10px;
  padding: 24px;
`;

export const RoomWrapper = styled.div`
  padding: 30px;
`;
