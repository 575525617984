import { gql } from '@apollo/client';

export const GET_PROJECT_PLAN_ROOMS_QUESTIONS = gql`
  query GetProjectPlanRoomsQuestions($input: ProjectPlanRoomsQuestionsInput!) {
    projectPlanRoomsQuestions(input: $input) {
      id
      answers {
        id
        label
        isSelected
        type
      }
      identifier
      room {
        id
        name
        roomType
      }
      responseText
    }
  }
`;
