import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Link } from 'common/components/Navigation/Link/Link';
import { THEME } from 'common/consts/theme';

export const NavigationListItem = styled.li`
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    padding-bottom: 33px;
  }
`;

export const linkStyles = css`
  padding: 3px 0;

  &:first-of-type {
    padding-top: 11px;
  }

  ${Link} {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    font-family: ${THEME.font.roboto};
    text-transform: none;

    &.active {
      color: ${THEME.color.primaryOrange};
    }
  }
`;
