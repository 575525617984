import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';
import { ProjectProfileSubSection } from 'ProjectProfile/projectProfile.consts';

export const getSectionByPropertyGroupIndentifier = (
  identifier: PropertyGroupIdentifier,
): ProjectProfileSubSection => {
  switch (identifier) {
    case PropertyGroupIdentifier.CLIENT_NOTES:
    case PropertyGroupIdentifier.DECISION_MAKERS:
    case PropertyGroupIdentifier.PREVIOUS_RENOVATION_EXPERIENCE:
    case PropertyGroupIdentifier.TIMELINE_PREFERENCES:
    case PropertyGroupIdentifier.FLEXIBILITY:
    case PropertyGroupIdentifier.INVOLVEMENT:
    case PropertyGroupIdentifier.IS_THE_CLIENT_LIVING_THROUGH_THE_RENOVATION:
    case PropertyGroupIdentifier.PAYMENT_PREFERENCE:
    case PropertyGroupIdentifier.PROJECT_BUDGET:
    case PropertyGroupIdentifier.RENOVATION_TIMELINE:
      return ProjectProfileSubSection.CLIENT_DETAILS;

    case PropertyGroupIdentifier.ELECTRICAL_PANEL_LOCATION:
    case PropertyGroupIdentifier.IS_SCOPE_VERIFICATION_REQUIRED:
    case PropertyGroupIdentifier.IS_THERE_SPACE_IN_THE_SUB_E_PANEL_FOR_ADDITIONAL_BREAKERS:
    case PropertyGroupIdentifier.PHOTO_OF_ELECTRICAL_PANEL:
    case PropertyGroupIdentifier.WHAT_IS_TOTAL_AMPERAGE_OF_ELECTRICAL_SERVICE:
    case PropertyGroupIdentifier.LIST_OF_ALL_TO_VIF:
    case PropertyGroupIdentifier.POST_SITE_SURVEY_NOTES:
    case PropertyGroupIdentifier.PRICING_UPDATES_NOTES:
    case PropertyGroupIdentifier.SERVICE_ENTRANCE:
    case PropertyGroupIdentifier.SUPER_CONTACT_INFO:
      return ProjectProfileSubSection.SITE_SURVEY;

    case PropertyGroupIdentifier.BUILDING_AGE:
    case PropertyGroupIdentifier.BUILDING_TYPE:
    case PropertyGroupIdentifier.ALTERATION_AGREEMENT_REQUIRED:
    case PropertyGroupIdentifier.INSURANCE:
    case PropertyGroupIdentifier.ALTERATION_AGREEMENT_HIGHLIGHTS:
    case PropertyGroupIdentifier.BUILDING_INFORMATION_NOTES:
    case PropertyGroupIdentifier.DESIGN_OPS_NOTES:
      return ProjectProfileSubSection.BUILDING_INFORMATION;

    case PropertyGroupIdentifier.CONCEPT_BOARD_NOTES:
    case PropertyGroupIdentifier.MATERIAL_PRICE_NOTES:
    case PropertyGroupIdentifier.BEFORE_DESIGN:
    case PropertyGroupIdentifier.NEEDS_VS_NICE_TO_HAVES:
      return ProjectProfileSubSection.PRE_KICKOFF;

    case PropertyGroupIdentifier.EXCEPTIONS:
    case PropertyGroupIdentifier.IMPORTANT_PROJECT_CALL_OUTS:
    default:
      return ProjectProfileSubSection.SUMMARY;
  }
};
