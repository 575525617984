import { dateTimeToUSFormat } from 'common/utils/date';
import { projectStatusesDictionary } from 'common/consts/statusList';
import { ProjectStatus } from 'common/apollo/enums/ProjectStatus';

import {
  BoldText,
  NoUpdatesMessage,
  StatusDate,
  StatusHistoryWrapper,
  StatusMessage,
  StatusRow,
} from './projectSidebarStatus.styles';
import { StatusHistory } from './projectSidebarStatus.types';

type Props = {
  statusHistory?: StatusHistory[] | null;
};

export const ProjectStatusHistory = ({ statusHistory }: Props) => (
  <StatusHistoryWrapper>
    {statusHistory?.length ? (
      statusHistory.map((status) => (
        <StatusRow key={status.id}>
          <StatusMessage
            completed={status.newValue === ProjectStatus.COMPLETED}
          >
            Updated from{' '}
            <BoldText>{projectStatusesDictionary[status.oldValue]}</BoldText> to{' '}
            <BoldText>{projectStatusesDictionary[status.newValue]}</BoldText>
          </StatusMessage>
          <StatusDate>{dateTimeToUSFormat(status.createdAt)}</StatusDate>
        </StatusRow>
      ))
    ) : (
      <NoUpdatesMessage>No Updates</NoUpdatesMessage>
    )}
  </StatusHistoryWrapper>
);
