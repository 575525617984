import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 14px;
  padding-bottom: 32px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Button = styled.button`
  ${({ theme }) => theme.typography.body2}
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.color.gray7};
  border-radius: 4px;
  padding: 6px 0;
  cursor: pointer;

  &:disabled {
    background-color: ${({ theme }) => theme.color.gray3};
  }
`;
