import { ChangeEvent, useCallback } from 'react';

import { Field, FieldProps } from 'formik';

import { useHasFormError } from 'common/hooks/useHasFormError';

import { TextField, Props as TextFieldProps } from './TextField';

export type Props = TextFieldProps;

const TextFieldWrapper = ({
  field: { name, onChange: fieldOnChange, ...fieldRest },
  onChange,
  ...props
}: FieldProps & Props) => {
  const errorMessage = useHasFormError(name);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      fieldOnChange(event);

      if (onChange) onChange(event);
    },
    [fieldOnChange, onChange],
  );

  return (
    <TextField
      {...fieldRest}
      {...props}
      errorMessage={errorMessage}
      name={name}
      onChange={handleChange}
    />
  );
};

export const FormTextField = (props: Props) => (
  <Field component={TextFieldWrapper} {...props} />
);
