import {
  RoleLabelString,
  RoleLabelType,
} from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';
import { RoleLabelStyled } from 'ProjectProfile/common/components/RoleLabel/roleLabel.styles';

type Props = {
  labelType: RoleLabelType;
};

export const RoleLabel = ({ labelType }: Props) => (
  <RoleLabelStyled className="role-label" labelType={labelType}>
    {RoleLabelString.get(labelType)}
  </RoleLabelStyled>
);
