import { OrderDeliverTo } from 'common/apollo/enums/OrderDeliverTo';
import { OrderItemStatus } from 'common/apollo/enums/OrderItemStatus';
import { OrderReason } from 'common/apollo/enums/OrderReason';
import { OrderStatus } from 'common/apollo/enums/OrderStatus';
import { OrderTiming } from 'common/apollo/enums/OrderTiming';
import { ShipmentStatus } from 'common/apollo/enums/ShipmentStatus';
import { ShippingMethod } from 'common/apollo/enums/ShippingMethod';

import { Variant } from 'common/components/Badge/Badge';

export const ORDER_STATUS_DICTIONARY = {
  [OrderStatus.ORDERED]: 'Ordered',
  [OrderStatus.CANCELED]: 'Canceled',
  [OrderStatus.DRAFT]: 'Draft',
  [OrderStatus.SCHEDULED]: 'Scheduled',
  [OrderStatus.SHIPPED]: 'Shipped',
};

export const ORDER_STATUS_COLOR_VARIANT: { [key in OrderStatus]: Variant } = {
  [OrderStatus.ORDERED]: 'orange',
  [OrderStatus.CANCELED]: 'blue',
  [OrderStatus.DRAFT]: 'green',
  [OrderStatus.SCHEDULED]: 'yellow',
  [OrderStatus.SHIPPED]: 'gray',
};

export const ORDER_STATUS_OPTIONS = [
  {
    label: ORDER_STATUS_DICTIONARY[OrderStatus.DRAFT],
    value: OrderStatus.DRAFT,
  },
  {
    label: ORDER_STATUS_DICTIONARY[OrderStatus.SCHEDULED],
    value: OrderStatus.SCHEDULED,
  },
  {
    label: ORDER_STATUS_DICTIONARY[OrderStatus.ORDERED],
    value: OrderStatus.ORDERED,
  },
  {
    label: ORDER_STATUS_DICTIONARY[OrderStatus.SHIPPED],
    value: OrderStatus.SHIPPED,
  },
  {
    label: ORDER_STATUS_DICTIONARY[OrderStatus.CANCELED],
    value: OrderStatus.CANCELED,
  },
];

export const ORDER_DELIVER_TO_DICTIONARY = {
  [OrderDeliverTo.JOBSITE]: 'Jobsite',
  [OrderDeliverTo.WH_LA_6910_TUJUNGA]: 'WH - LA - 6910 Tujunga Ave',
  [OrderDeliverTo.WH_NY_51_COFFEY]: 'WH - NY - 51 Coffey',
  [OrderDeliverTo.WH_NY_TRIPLE_CROWN]: 'WH - NY - Triple Crown',
  [OrderDeliverTo.WH_NY_XPDEL]: 'WH - NY - XPDEL',
};

export const ORDER_DELIVER_TO_OPTIONS = [
  {
    label: ORDER_DELIVER_TO_DICTIONARY[OrderDeliverTo.JOBSITE],
    value: OrderDeliverTo.JOBSITE,
  },
  {
    label: ORDER_DELIVER_TO_DICTIONARY[OrderDeliverTo.WH_NY_51_COFFEY],
    value: OrderDeliverTo.WH_NY_51_COFFEY,
  },
  {
    label: ORDER_DELIVER_TO_DICTIONARY[OrderDeliverTo.WH_NY_TRIPLE_CROWN],
    value: OrderDeliverTo.WH_NY_TRIPLE_CROWN,
  },
  {
    label: ORDER_DELIVER_TO_DICTIONARY[OrderDeliverTo.WH_NY_XPDEL],
    value: OrderDeliverTo.WH_NY_XPDEL,
  },
  {
    label: ORDER_DELIVER_TO_DICTIONARY[OrderDeliverTo.WH_LA_6910_TUJUNGA],
    value: OrderDeliverTo.WH_LA_6910_TUJUNGA,
  },
];

export const ORDER_TIMING_DICTIONARY = {
  [OrderTiming.POST_BREAK_GROUND]: 'Post Break Ground',
  [OrderTiming.STANDARD]: 'Standard',
};

export const ORDER_TIMING_OPTIONS = [
  {
    label: ORDER_TIMING_DICTIONARY[OrderTiming.POST_BREAK_GROUND],
    value: OrderTiming.POST_BREAK_GROUND,
  },
  {
    label: ORDER_TIMING_DICTIONARY[OrderTiming.STANDARD],
    value: OrderTiming.STANDARD,
  },
];

export const ORDER_SHIPPING_METHOD_DICTIONARY = {
  [ShippingMethod.AMAZON]: 'Amazon',
  [ShippingMethod.BLOCK_DELIVERY]: 'Block',
  [ShippingMethod.FEDEX]: 'Fedex',
  [ShippingMethod.PICKUP]: 'Pickup',
  [ShippingMethod.TRUCK]: 'Truck',
  [ShippingMethod.UPS]: 'Ups',
};

export const ORDER_SHIPPING_METHOD_OPTIONS = [
  {
    label: ORDER_SHIPPING_METHOD_DICTIONARY[ShippingMethod.AMAZON],
    value: ShippingMethod.AMAZON,
  },
  {
    label: ORDER_SHIPPING_METHOD_DICTIONARY[ShippingMethod.BLOCK_DELIVERY],
    value: ShippingMethod.BLOCK_DELIVERY,
  },
  {
    label: ORDER_SHIPPING_METHOD_DICTIONARY[ShippingMethod.FEDEX],
    value: ShippingMethod.FEDEX,
  },
  {
    label: ORDER_SHIPPING_METHOD_DICTIONARY[ShippingMethod.PICKUP],
    value: ShippingMethod.PICKUP,
  },
  {
    label: ORDER_SHIPPING_METHOD_DICTIONARY[ShippingMethod.TRUCK],
    value: ShippingMethod.TRUCK,
  },
  {
    label: ORDER_SHIPPING_METHOD_DICTIONARY[ShippingMethod.UPS],
    value: ShippingMethod.UPS,
  },
];

export const ORDER_ITEM_STATUS_DICTIONARY = {
  [OrderItemStatus.CANCELED]: 'Canceled',
  [OrderItemStatus.ORDERED]: 'Ordered',
};

export const ORDER_ITEM_STATUS_COLOR_VARIANT: {
  [key in OrderItemStatus]: Variant;
} = {
  [OrderItemStatus.ORDERED]: 'purple',
  [OrderItemStatus.CANCELED]: 'blue',
};

export const ORDER_REASON_DICTIONARY = {
  [OrderReason.STANDARD]: 'Standard (Default)',
  [OrderReason.VIF]: 'VIF',
  [OrderReason.SALES_ERROR]: 'Sales Error',
  [OrderReason.SITE_SURVEY_ERROR]: 'Site Survey Error',
  [OrderReason.DESIGN_ERROR]: 'Design Error',
  [OrderReason.P_A_ERROR]: 'P&A Error',
  [OrderReason.PROCUREMENT_ERROR]: 'Procurement Error',
  [OrderReason.PROJECT_OPS_ERROR]: 'Project Ops Error',
  [OrderReason.FINANCE_ERROR]: 'Finance Error',
  [OrderReason.VENDOR_ERROR]: 'Vendor Error',
  [OrderReason.CONTRACTOR_ERROR]: 'Contractor Error',
  [OrderReason.CLIENT_REQUEST_MATERIALS_CHANGE]:
    'Homeowner Request - Materials Change',
};

export const ORDER_REASON_OPTIONS = [
  {
    label: ORDER_REASON_DICTIONARY[OrderReason.STANDARD],
    value: OrderReason.STANDARD,
  },
  {
    label: ORDER_REASON_DICTIONARY[OrderReason.VIF],
    value: OrderReason.VIF,
  },
  {
    label: ORDER_REASON_DICTIONARY[OrderReason.SALES_ERROR],
    value: OrderReason.SALES_ERROR,
  },
  {
    label: ORDER_REASON_DICTIONARY[OrderReason.SITE_SURVEY_ERROR],
    value: OrderReason.SITE_SURVEY_ERROR,
  },
  {
    label: ORDER_REASON_DICTIONARY[OrderReason.DESIGN_ERROR],
    value: OrderReason.DESIGN_ERROR,
  },
  {
    label: ORDER_REASON_DICTIONARY[OrderReason.P_A_ERROR],
    value: OrderReason.P_A_ERROR,
  },
  {
    label: ORDER_REASON_DICTIONARY[OrderReason.PROCUREMENT_ERROR],
    value: OrderReason.PROCUREMENT_ERROR,
  },
  {
    label: ORDER_REASON_DICTIONARY[OrderReason.PROJECT_OPS_ERROR],
    value: OrderReason.PROJECT_OPS_ERROR,
  },
  {
    label: ORDER_REASON_DICTIONARY[OrderReason.FINANCE_ERROR],
    value: OrderReason.FINANCE_ERROR,
  },
  {
    label: ORDER_REASON_DICTIONARY[OrderReason.VENDOR_ERROR],
    value: OrderReason.VENDOR_ERROR,
  },
  {
    label: ORDER_REASON_DICTIONARY[OrderReason.CONTRACTOR_ERROR],
    value: OrderReason.CONTRACTOR_ERROR,
  },
  {
    label: ORDER_REASON_DICTIONARY[OrderReason.CLIENT_REQUEST_MATERIALS_CHANGE],
    value: OrderReason.CLIENT_REQUEST_MATERIALS_CHANGE,
  },
];

export const validShippedShipmentStates = [
  ShipmentStatus.RECEIVED,
  ShipmentStatus.MISSING,
  ShipmentStatus.REFUSED,
  ShipmentStatus.SHIPPED,
];
