import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { mq } from 'common/utils/mediaQueries';
import { THEME } from 'common/consts/theme';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const Layout = styled.section`
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.alto};
  margin: 24px;
  padding: 30px 15px;

  ${mq.large(css`
    margin: 47px;
    padding: 54px 35px;
  `)}
`;

export const Title = styled.h2`
  font: normal 24px/32px ${THEME.font.roboto};
`;
