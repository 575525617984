import { Link as NavLine } from 'react-router-dom';
import styled from '@emotion/styled';

export const LinkExternal = styled.a`
  color: ${({ theme }) => theme.color.mariner};
  font: 400 14px/24px ${({ theme }) => theme.font.inter};
  word-break: break-word;

  @media print {
    color: ${({ theme }) => theme.color.black};
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const Link = LinkExternal.withComponent(NavLine);
