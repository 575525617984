import { DeliveryBatch } from 'common/apollo/models/deliveryBatch';

import { ORDER_DELIVER_TO_DICTIONARY } from 'Project/Procurement/common/consts/order.consts';

import { OrderItemRow } from 'Project/Procurement/Warehouse/WarehouseTable/OrderItemRow/OrderItemRow';
import {
  Table,
  TableWrapper,
} from 'Project/Procurement/Warehouse/WarehouseTable/warehouseTable.styles';
import { WarehouseTableHeader } from 'Project/Procurement/Warehouse/WarehouseTable/WarehouseTableHeader/WarehouseTableHeader';

type Props = {
  areAllBatchesSelected: boolean;
  batchesPerItem: DeliveryBatch[][];
  expandedItems: string[];
  handleToggleAllBatchesForOrderItem: (orderItemId: string) => void;
  handleToggleBatch: (batchId: string) => void;
  onCollapseAll: () => void;
  onCollapseToggle: (orderItemId: string) => void;
  onEditAllBatchesForOrderItem: (orderItemId: string) => void;
  onExpandAll: () => void;
  onToggleSelectAllBatches: () => void;
  selectedBatches: string[];
  selectedOrderItems: string[];
};

export const WarehouseTable = ({
  areAllBatchesSelected,
  batchesPerItem,
  expandedItems,
  handleToggleAllBatchesForOrderItem,
  handleToggleBatch,
  onCollapseAll,
  onCollapseToggle,
  onEditAllBatchesForOrderItem,
  onExpandAll,
  onToggleSelectAllBatches,
  selectedBatches,
  selectedOrderItems,
}: Props) => (
  <Table>
    <WarehouseTableHeader
      areAllBatchesExpanded={batchesPerItem.length === expandedItems.length}
      areAllBatchesSelected={areAllBatchesSelected}
      onCollapseAll={onCollapseAll}
      onExpandAll={onExpandAll}
      onToggleSelectAllBatches={onToggleSelectAllBatches}
    />
    <TableWrapper>
      {batchesPerItem.map((batches) => {
        const [{ orderItem }] = batches;
        return (
          <OrderItemRow
            batches={batches}
            deliverTo={
              ORDER_DELIVER_TO_DICTIONARY[orderItem.requestedDeliverTo]
            }
            handleToggleAllBatchesForOrderItem={
              handleToggleAllBatchesForOrderItem
            }
            handleToggleBatch={handleToggleBatch}
            isOpen={expandedItems.includes(orderItem.id)}
            key={orderItem.id}
            onCollapseToggle={onCollapseToggle}
            onEditAllBatchesForOrderItem={onEditAllBatchesForOrderItem}
            orderItem={orderItem}
            selectedBatches={selectedBatches}
            selectedOrderItems={selectedOrderItems}
          />
        );
      })}
    </TableWrapper>
  </Table>
);
