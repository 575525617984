import { useFormikContext } from 'formik';

import { SkuVendorStockStatus } from 'common/apollo/enums/SkuVendorStockStatus';

import { FormValue } from '../editMaterialsLeadTime.types';

export const useEditMaterialsLeadTimeRow = (index: number) => {
  const { setFieldValue, values } = useFormikContext<FormValue>();

  const handleStockStatusUpdate = (status: SkuVendorStockStatus) => {
    if (status === SkuVendorStockStatus.ON_BACK_ORDER) return;
    if (status === SkuVendorStockStatus.DISCONTINUED) {
      setFieldValue(`materials.${index}.minLeadTimeWeeks`, '');
      setFieldValue(`materials.${index}.maxLeadTimeWeeks`, '');
    }
    setFieldValue(`materials.${index}.backInStockDate`, null);
  };

  const isBackInStockDisabled =
    values.materials[index].stockStatus !== SkuVendorStockStatus.ON_BACK_ORDER;

  return { handleStockStatusUpdate, isBackInStockDisabled };
};
