import { ErrorSegment } from 'common/components/ErrorHandlers/ErrorSegment/ErrorSegment';
import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';

import { EmptyState } from 'Project/Procurement/common/components/Layout/Layout';

import { Order } from 'Project/Procurement/Orders/Order/Order';

import {
  Wrapper,
  WrapperInner,
} from 'Project/Procurement/Orders/orders.styles';
import { useOrders } from 'Project/Procurement/Orders/orders.hooks';

const Orders = () => {
  const { error, isLoading, orders, postDsoEnabled, projectId } = useOrders();

  if (isLoading) return <LoadingSpinnerWithContainer />;
  if (error) return <ErrorSegment />;

  return (
    <Wrapper>
      <WrapperInner>
        {orders.map((order) => (
          <Order
            {...order}
            hasOrderConflicts={postDsoEnabled && order.hasOrderConflicts}
            key={order.id}
            projectId={projectId}
          />
        ))}

        {!orders.length && <EmptyState> No Orders Found</EmptyState>}
      </WrapperInner>
    </Wrapper>
  );
};

export default Orders;
