import Grid from '@material-ui/core/Grid';

import { CollapsibleContainer } from 'ProjectProfile/common/components/CollapsibleContainer/CollapsibleContainer';
import { Section } from 'ProjectProfile/common/components/Section/Section';
import { SectionOutlined } from 'ProjectProfile/common/components/Section/SectionOutlined';

import { RoleLabelType } from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';

import { ConfiguratorRenders } from './ConfiguratorRenders/ConfiguratorRenders';
import { InspirationsLink } from './InspirationsLink/InspirationsLink';
import { PreKickoffEdit } from './PreKickoffEdit/PreKickoffEdit';

import { usePreKickoff } from './preKickoff.hooks';

export const PreKickoff = () => {
  const { answers, close, handleSuccessfulSubmit, importances, isOpen, open } =
    usePreKickoff();

  const {
    BEFORE_DESIGN,
    CONCEPT_BOARD_NOTES,
    MATERIAL_PRICE_NOTES,
    NEEDS_VS_NICE_TO_HAVES,
  } = answers;

  return (
    <>
      <CollapsibleContainer
        onEditClick={open}
        roles={[RoleLabelType.PROJECT_PLANNER]}
        title="Pre-Kickoff"
      >
        <Grid container spacing={4}>
          <Grid item md={12} sm={12}>
            <InspirationsLink />
          </Grid>
          <Grid item md={6} sm={12}>
            <Section
              isImportant={importances.CONCEPT_BOARD_NOTES}
              title="Collection and Looks Notes"
            >
              {CONCEPT_BOARD_NOTES}
            </Section>
          </Grid>
          <Grid item md={6} sm={12}>
            <Section
              isImportant={importances.MATERIAL_PRICE_NOTES}
              title="Material price notes"
            >
              {MATERIAL_PRICE_NOTES}
            </Section>
          </Grid>
          <Grid item md={12} sm={12}>
            <ConfiguratorRenders />
          </Grid>
          <Grid item md={10} sm={12} xs={12}>
            <SectionOutlined
              isImportant={importances.BEFORE_DESIGN}
              title="Before Design"
            >
              {BEFORE_DESIGN}
            </SectionOutlined>
          </Grid>
          <Grid item md={10} sm={12} xs={12}>
            <SectionOutlined
              isImportant={importances.NEEDS_VS_NICE_TO_HAVES}
              title="Needs vs. Nice-to-Haves"
            >
              {NEEDS_VS_NICE_TO_HAVES}
            </SectionOutlined>
          </Grid>
        </Grid>
      </CollapsibleContainer>

      {isOpen && (
        <PreKickoffEdit
          close={close}
          handleSuccessfulSubmit={handleSuccessfulSubmit}
        />
      )}
    </>
  );
};
