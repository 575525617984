import { gql } from '@apollo/client';

export const GET_PROJECT_PLAN_SECONDARY_CONTACT = gql`
  query ProjectPlanSecondaryContact($input: ProjectPlanSecondaryContactInput!) {
    projectPlanSecondaryContact(input: $input) {
      email
      firstName
      lastName
      relationship
      phoneNumber
    }
  }
`;
