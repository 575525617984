import { isRouteMatch } from 'common/utils/route';

import { sidebarRoutes } from 'Router/routerConfig';

export const routeHaveSubSidebar = (currentPath: string) =>
  sidebarRoutes.some((route) => isRouteMatch(route, currentPath));

type Link = {
  label: string;
  to: string;
};

type NavigationMenuItems = {
  links: Link[];
  title: string;
};

export const navigationLabel = (
  menuItems: NavigationMenuItems[],
  path: string,
) =>
  menuItems
    .map(({ links }) => links)
    .flat()
    .find((el) => path.includes(el.to))?.label;
