import { Delete as DeleteIcon } from '@material-ui/icons';

import { PricingEngine } from 'common/apollo/models/pricingEngine';

import { MenuButton } from 'common/components/Buttons/MenuButton/MenuButton';
import { MenuButtonItem } from 'common/components/Buttons/MenuButton/MenuButtonItem';

import {
  Wrapper,
  InnerWrapper,
} from 'PricingEngineSidebar/Proposal/PricingEngines/pricingEngines.styles';
import { usePricingEngines } from 'PricingEngineSidebar/Proposal/PricingEngines/pricingEngines.hooks';

type Props = {
  pricingEngines: PricingEngine[];
};

export const PricingEngines = ({ pricingEngines }: Props) => {
  const {
    handleRedirectToPricingEngine,
    handleRedirectToProjectOverview,
    handleRemovePricingEngine,
    isActive,
    isProjectOverviewActive,
  } = usePricingEngines();

  return (
    <Wrapper>
      <MenuButton
        isDisabled={isProjectOverviewActive}
        onClick={handleRedirectToProjectOverview}
        title="Project Overview"
      />

      {pricingEngines.map(({ id, name }) => (
        <InnerWrapper key={id}>
          <MenuButton
            isDisabled={isActive(id)}
            onClick={handleRedirectToPricingEngine(id)}
            title={name}
          >
            <MenuButtonItem onClick={handleRemovePricingEngine(id)}>
              <DeleteIcon /> Remove room
            </MenuButtonItem>
          </MenuButton>
        </InnerWrapper>
      ))}
    </Wrapper>
  );
};
