import sortBy from 'lodash-es/sortBy';

import { DeliveryBatch } from 'common/apollo/models/deliveryBatch';

import { FilterableDeliveryBatchAttribute } from 'common/components/Filters/enums/FilterableDeliveryBatchAttribute';
import { FilterType } from 'common/components/Filters/enums/FilterType';
import { BaseFilter } from 'common/components/Filters/filters.types';

import { ORDER_DELIVER_TO_DICTIONARY } from 'Project/Procurement/common/consts/order.consts';

const FilterFunctionsMap = {
  [FilterableDeliveryBatchAttribute.DESTINATION]: (
    deliveryBatch: DeliveryBatch,
    filter: BaseFilter,
  ) =>
    filter.selectedOptions.some(
      (option) => option.value === deliveryBatch.orderItem.requestedDeliverTo,
    ),

  [FilterableDeliveryBatchAttribute.HAS_RECEIVED]: (
    deliveryBatch: DeliveryBatch,
    filter: BaseFilter,
  ) =>
    filter.selectedOptions.some((option) =>
      option.value === 'received'
        ? deliveryBatch.receivedAt !== null
        : deliveryBatch.receivedAt === null,
    ),
  [FilterableDeliveryBatchAttribute.HAS_SHIPPED]: (
    deliveryBatch: DeliveryBatch,
    filter: BaseFilter,
  ) =>
    filter.selectedOptions.some((option) =>
      option.value === 'shipped'
        ? deliveryBatch.shippedAt !== null
        : deliveryBatch.shippedAt === null,
    ),
  [FilterableDeliveryBatchAttribute.HAS_CONFIRMED]: (
    deliveryBatch: DeliveryBatch,
    filter: BaseFilter,
  ) =>
    filter.selectedOptions.some((option) =>
      option.value === 'confirmed'
        ? deliveryBatch.confirmedAt !== null
        : deliveryBatch.confirmedAt === null,
    ),
  [FilterableDeliveryBatchAttribute.PALLET]: (
    deliveryBatch: DeliveryBatch,
    filter: BaseFilter,
  ) =>
    filter.selectedOptions.some(
      (option) => option.value === deliveryBatch.pallet,
    ),

  [FilterableDeliveryBatchAttribute.ROOM]: (
    deliveryBatch: DeliveryBatch,
    filter: BaseFilter,
  ) =>
    filter.selectedOptions.some((option) =>
      option.value === 'Other'
        ? !deliveryBatch.orderItem.projectMaterial?.pricingEngine?.name
        : option.value ===
          deliveryBatch.orderItem.projectMaterial?.pricingEngine?.name,
    ),

  [FilterableDeliveryBatchAttribute.VENDOR]: (
    deliveryBatch: DeliveryBatch,
    filter: BaseFilter,
  ) =>
    filter.selectedOptions.some(
      (option) =>
        option.value === deliveryBatch.orderItem.skuVendor.vendor.name,
    ),
};

export const doesBatchPassFilters = (
  deliveryBatch: DeliveryBatch | null,
  filters: BaseFilter[],
) =>
  deliveryBatch !== null &&
  filters.every((filter) =>
    FilterFunctionsMap[filter.attribute as keyof typeof FilterFunctionsMap](
      deliveryBatch,
      filter,
    ),
  );

const getFilterForAttribute = (
  filters: BaseFilter[],
  attribute: FilterableDeliveryBatchAttribute,
) => filters.find((filter) => filter.attribute === attribute);

export const generateFilters = (
  batches: DeliveryBatch[],
  selectedFilters: BaseFilter[],
): BaseFilter[] => [
  generateVendorFilter(batches, selectedFilters),
  generateHasReceivedFilter(selectedFilters),
  generateRoomFilter(batches, selectedFilters),
  generateDestinationFilter(batches, selectedFilters),
  generateHasShippedFilter(selectedFilters),
  generateHasConfirmedFilter(selectedFilters),
  generatePalletFilter(batches, selectedFilters),
];

const generateDestinationFilter = (
  batches: DeliveryBatch[],
  selectedFilters: BaseFilter[],
): BaseFilter => {
  const destinations = batches
    .map((batch) => batch.orderItem.requestedDeliverTo)
    .filter((destination, index, self) => self.indexOf(destination) === index);

  const optionsArr = destinations.map((destination) => ({
    label: ORDER_DELIVER_TO_DICTIONARY[destination],
    value: destination,
  }));

  const options = sortBy(optionsArr, [
    (option) => option.value.toLocaleLowerCase(),
  ]);

  const selectedFilter = getFilterForAttribute(
    selectedFilters,
    FilterableDeliveryBatchAttribute.DESTINATION,
  );

  return {
    attribute: FilterableDeliveryBatchAttribute.DESTINATION,
    label: 'Destination',
    options,
    selectedOptions: selectedFilter ? selectedFilter.selectedOptions : [],
    type: FilterType.CHECKBOX,
  };
};

const generateHasReceivedFilter = (
  selectedFilters: BaseFilter[],
): BaseFilter => {
  const options = [
    { label: 'Not Received Batches', value: 'not received' },
    { label: 'Received Batches', value: 'received' },
  ];

  const selectedFilter = getFilterForAttribute(
    selectedFilters,
    FilterableDeliveryBatchAttribute.HAS_RECEIVED,
  );

  return {
    attribute: FilterableDeliveryBatchAttribute.HAS_RECEIVED,
    label: 'Received at Warehouse',
    options,
    selectedOptions: selectedFilter ? selectedFilter.selectedOptions : [],
    type: FilterType.CHECKBOX,
  };
};

const generateHasShippedFilter = (
  selectedFilters: BaseFilter[],
): BaseFilter => {
  const options = [
    { label: 'Not Shipped Batches', value: 'not shipped' },
    { label: 'Shipped Batches', value: 'shipped' },
  ];

  const selectedFilter = getFilterForAttribute(
    selectedFilters,
    FilterableDeliveryBatchAttribute.HAS_SHIPPED,
  );

  return {
    attribute: FilterableDeliveryBatchAttribute.HAS_SHIPPED,
    label: 'Shipped from Warehouse',
    options,
    selectedOptions: selectedFilter ? selectedFilter.selectedOptions : [],
    type: FilterType.CHECKBOX,
  };
};

const generateHasConfirmedFilter = (
  selectedFilters: BaseFilter[],
): BaseFilter => {
  const options = [
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Not Confirmed', value: 'not confirmed' },
  ];

  const selectedFilter = getFilterForAttribute(
    selectedFilters,
    FilterableDeliveryBatchAttribute.HAS_CONFIRMED,
  );

  return {
    attribute: FilterableDeliveryBatchAttribute.HAS_CONFIRMED,
    label: 'Confirmed by Contractor',
    options,
    selectedOptions: selectedFilter ? selectedFilter.selectedOptions : [],
    type: FilterType.CHECKBOX,
  };
};

const generatePalletFilter = (
  batches: DeliveryBatch[],
  selectedFilters: BaseFilter[],
): BaseFilter => {
  const pallets = batches
    .map((batch) => batch.pallet || '')
    .filter((pallet, index, self) => pallet && self.indexOf(pallet) === index);

  const optionsArr = pallets.map((pallet) => ({
    label: pallet,
    value: pallet,
  }));

  const options = sortBy(optionsArr, [
    (option) => option.value.toLocaleLowerCase(),
  ]);

  const selectedFilter = getFilterForAttribute(
    selectedFilters,
    FilterableDeliveryBatchAttribute.PALLET,
  );

  return {
    attribute: FilterableDeliveryBatchAttribute.PALLET,
    label: 'Pallet',
    options,
    selectedOptions: selectedFilter ? selectedFilter.selectedOptions : [],
    type: FilterType.CHECKBOX,
  };
};

const generateVendorFilter = (
  batches: DeliveryBatch[],
  selectedFilters: BaseFilter[],
): BaseFilter => {
  const vendors = batches
    .map((batch) => batch.orderItem.skuVendor.vendor.name)
    .filter((vendor, index, self) => vendor && self.indexOf(vendor) === index);

  const optionsArr = vendors.map((vendor) => ({
    label: vendor,
    value: vendor,
  }));

  const options = sortBy(optionsArr, [
    (option) => option.value.toLocaleLowerCase(),
  ]);

  const selectedFilter = getFilterForAttribute(
    selectedFilters,
    FilterableDeliveryBatchAttribute.VENDOR,
  );

  return {
    attribute: FilterableDeliveryBatchAttribute.VENDOR,
    label: 'Vendor',
    options,
    selectedOptions: selectedFilter ? selectedFilter.selectedOptions : [],
    type: FilterType.CHECKBOX,
  };
};

const generateRoomFilter = (
  batches: DeliveryBatch[],
  selectedFilters: BaseFilter[],
): BaseFilter => {
  const rooms = batches
    .map(
      (batch) =>
        batch.orderItem.projectMaterial?.pricingEngine?.name || 'Other',
    )
    .filter((room, index, self) => room && self.indexOf(room) === index);

  const optionsArr = rooms.map((room) => ({
    label: room,
    value: room,
  }));

  const options = sortBy(optionsArr, [
    (option) => option.value.toLocaleLowerCase(),
  ]);

  const selectedFilter = getFilterForAttribute(
    selectedFilters,
    FilterableDeliveryBatchAttribute.ROOM,
  );

  return {
    attribute: FilterableDeliveryBatchAttribute.ROOM,
    label: 'Room',
    options,
    selectedOptions: selectedFilter ? selectedFilter.selectedOptions : [],
    type: FilterType.CHECKBOX,
  };
};

const SegmentFilters = {
  [FilterableDeliveryBatchAttribute.DESTINATION]: 'Destination',
  [FilterableDeliveryBatchAttribute.HAS_RECEIVED]: 'Received at Warehouse',
  [FilterableDeliveryBatchAttribute.HAS_SHIPPED]: 'Shipped from Warehouse',
  [FilterableDeliveryBatchAttribute.HAS_CONFIRMED]: 'Confirmed by Contractor',
  [FilterableDeliveryBatchAttribute.ROOM]: 'Room',
  [FilterableDeliveryBatchAttribute.VENDOR]: 'Vendor',
};

export const generateSegmentData = (selectedFilters: BaseFilter[]) =>
  selectedFilters
    .filter(
      (filter) =>
        SegmentFilters[filter.attribute as keyof typeof SegmentFilters],
    )
    .reduce(
      (acc, filter) => ({
        ...acc,
        [SegmentFilters[filter.attribute as keyof typeof SegmentFilters]]:
          filter.selectedOptions.map((option) => option.label).join(','),
      }),
      {},
    );
