import { useParams } from 'react-router-dom';

import { useProjectPlanRooms } from 'ProjectProfile/common/hooks/useProjectPlanRooms';
import { useProjectPlanRoomsQuestions } from 'ProjectProfile/common/hooks/useProjectPlanRoomsQuestions';
import { QueryParams } from 'ProjectProfile/common/types/queryParams';

import { mapProjectPlanRoomsAndQuestions } from 'ProjectProfile/common/utils/projectPlanRooms';

import {
  groupRoomsQuestions,
  mapRoomsToRoomsQuestionInput,
} from './rooms.utils';

export const useRooms = () => {
  const { projectId } = useParams<QueryParams>();

  const { data: dataRooms, isLoading: isLoadingRooms } = useProjectPlanRooms({
    projectId,
  });

  const roomsQuestionsInput = mapRoomsToRoomsQuestionInput(dataRooms);

  const { data: dataRoomsQuestions, isLoading: isLoadingRoomsQuestions } =
    useProjectPlanRoomsQuestions({
      roomsQuestionsInput,
    });

  const isLoading = isLoadingRooms || isLoadingRoomsQuestions;
  const mappedRoomsAndRoomsQuestions = mapProjectPlanRoomsAndQuestions(
    dataRooms,
    dataRoomsQuestions,
  );
  const roomsQuestions = groupRoomsQuestions(mappedRoomsAndRoomsQuestions);

  return {
    hasRoomQuestions: !!Object.keys(roomsQuestions).length && !isLoading,
    isLoading,
    roomsQuestions,
  };
};
