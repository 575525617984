import { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export const useDebounce = (callback: (value: string) => void, time = 300) => {
  const subjectRef = useRef<Subject<string> | null>(null);
  const getSubject = useCallback(() => {
    if (subjectRef.current) return subjectRef.current;

    subjectRef.current = new Subject<string>();
    return subjectRef.current;
  }, []);

  const handleChange = useCallback(
    (
      event?: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      if (!event || !event.target) return;

      getSubject().next(event.target.value);
    },
    [getSubject],
  );

  useEffect(() => {
    const subject = getSubject();

    if (!subject) return;

    const subscription$: Subscription = subject
      .pipe(debounceTime(time), distinctUntilChanged())
      .subscribe(callback);

    return () => subscription$.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, time]);

  return handleChange;
};
