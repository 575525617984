import { List, ListItem } from '@material-ui/core';

import { Collapse } from 'common/components/Collapse/Collapse';
import { CollapseContent } from 'common/components/Collapse/CollapseContent';
import { RightChevronCollapseButton } from 'common/components/Collapse/CollapseButtons/RightChevronCollapseButton/RightChevronCollapseButton';
import { Link } from 'common/components/Navigation/Link/Link';

import { FeatureFlag } from 'common/components/FeatureFlag/FeatureFlag';
import { FeatureFlags } from 'common/enums/FeatureFlags';

import { linkStyles, NavigationListItem } from './NavigationMenuSection.styles';

type LinkType = {
  featureFlag?: FeatureFlags;
  label: string;
  to: string;
};

type Props = {
  links: LinkType[];
  title: string;
};

export const NavigationMenuSection = ({ links, title }: Props) => (
  <NavigationListItem>
    <Collapse isOpen>
      <RightChevronCollapseButton title={title} />
      <CollapseContent>
        <List disablePadding>
          {links.map(({ featureFlag, label, to }) =>
            featureFlag ? (
              <FeatureFlag id={featureFlag} key={label}>
                <ListItem css={linkStyles}>
                  <Link to={to}>{label}</Link>
                </ListItem>
              </FeatureFlag>
            ) : (
              <ListItem css={linkStyles} key={label}>
                <Link to={to}>{label}</Link>
              </ListItem>
            ),
          )}
        </List>
      </CollapseContent>
    </Collapse>
  </NavigationListItem>
);
