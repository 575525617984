import { Grid } from '@material-ui/core';

import { Skeleton } from 'ProjectProfile/common/components/Skeleton/Skeleton';

export const ConfiguratorRendersSkeleton = () => (
  <Grid container spacing={4}>
    <Grid item md={6} sm={6} xs={12}>
      <Skeleton fullWidth height="320" />
    </Grid>
    <Grid item md={6} sm={6} xs={12}>
      <Skeleton fullWidth height="320" />
    </Grid>
  </Grid>
);
