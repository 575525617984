import { TypePolicies } from '@apollo/client';

import { relayStylePagination } from '@apollo/client/utilities';

import { Sku } from './Sku';
import { SkuSet } from './SkuSet';
import { Product } from './Product';

export const typePolicies: TypePolicies = {
  AuthUser: {
    keyFields: ['adminId'],
  },
  ContractorPreferencePauseWorkOrdersUntil: {
    keyFields: ['contractorOrganizationId'],
  },
  KeyDate: {
    keyFields: ['id', 'value'],
  },
  MaterialsCategory: {
    fields: {
      materials: { merge: (_, incoming) => incoming },
    },
  },
  PricingEngine: {
    fields: {
      costs: {
        merge: false,
      },
      questions: {
        merge: (_, incoming) => incoming,
      },
    },
  },
  PricingEngineMaterialsCategory: {
    fields: {
      linesOfWork: { merge: false },
      materials: { merge: false },
    },
  },
  PricingEngineSkuSet: {
    keyFields: ['skus'],
  },
  Product,
  ProjectPlanRoomLinks: {
    fields: {
      canvasLinks: {
        merge: (_, incoming) => incoming,
      },
      room: {
        merge: (_, incoming) => incoming,
      },
    },
  },
  ProjectPlanRoomQuestion: {
    keyFields: ['room', ['id']],
  },
  Query: {
    fields: {
      contractorRecommendations: {
        merge: (_, incoming) => incoming,
      },
      products: relayStylePagination(),
    },
  },
  Question: {
    fields: {
      answers: { merge: (_, incoming) => incoming },
    },
  },
  Revision: {
    fields: {
      permits: {
        merge: (_, incoming) => incoming,
      },
      permitsUpdates: {
        merge: (_, incoming) => incoming,
      },
      pricingEngines: { merge: (_, incoming) => incoming },
      pricingEnginesUpdates: {
        merge: (_, incoming) => incoming,
      },
    },
  },
  RevisionCost: {
    merge: (_, incoming) => incoming,
  },
  Shipment: {
    fields: {
      items: {
        merge: (_, incoming) => incoming,
      },
    },
  },
  Sku,
  SkuSet,
  SkuUnitProperties: {
    merge: false,
  },
  UnitPackage: {
    merge: false,
  },
};
