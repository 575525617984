import { useState, useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { useQueryParam } from 'common/hooks/useQueryParam';

import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';
import { ADD_NEW_USER_MODAL } from 'Users/AddNewUserModal/addNewUserModal.consts';

import {
  GetTeamMembers,
  GetTeamMembersVariables,
  GetUsers,
  GetUsersVariables,
} from 'Users/__generated__/users.graphql.generated';

import { GET_USERS, GET_TEAM_MEMBERS } from 'Users/users.graphql';
import { RenovationPlanStatus } from '__generated/apolloTypes';
import { mapToDictionaryItem } from 'common/utils/dictionary';
import {
  useLoginAsUser,
  useFetchLoginLink,
} from 'common/hooks/LoginAsUser/loginAsUser.hooks';

const API_DATA_LIMIT = 20;

const SEARCH_PARAMS = {
  member: 'primaryMember',
  search: 'searchValue',
  status: 'planStatus',
};

const useUsersFilters = () => {
  const {
    queryParamValue: searchValueParam,
    removeLocationParam: removeSearchValue,
    swapLocationParamValue: changeSearchValue,
  } = useQueryParam(SEARCH_PARAMS.search);

  const {
    queryParamValue: primaryMemberParam,
    removeLocationParam: removePrimaryMemberParam,
    swapLocationParamValue: changePrimaryMemberParam,
  } = useQueryParam(SEARCH_PARAMS.member);

  const {
    queryParamValue: renovationPlanStatusParam,
    removeLocationParam: removeRenovationPlanStatusParam,
    swapLocationParamValue: changeRenovationPlanStatusParam,
  } = useQueryParam(SEARCH_PARAMS.status);

  const [searchValue, setSearchValue] = useState<string | null>(
    searchValueParam,
  );

  const [status, setStatus] = useState<RenovationPlanStatus | null>(
    renovationPlanStatusParam as RenovationPlanStatus,
  );

  const [primaryMember, setPrimaryMember] = useState<string | null>(
    primaryMemberParam,
  );

  const handleSearchUpdate = (value: string | null) => {
    value ? changeSearchValue(value) : removeSearchValue();

    setSearchValue(value);
  };

  const handlePrimaryMemberUpdate = (id: string | null) => {
    id ? changePrimaryMemberParam(id) : removePrimaryMemberParam();

    setPrimaryMember(id);
  };

  const handleStatusUpdate = (value: RenovationPlanStatus | null) => {
    value
      ? changeRenovationPlanStatusParam(value)
      : removeRenovationPlanStatusParam();

    setStatus(value);
  };

  return {
    handlePrimaryMemberUpdate,
    handleSearchUpdate,
    handleStatusUpdate,
    primaryMember,
    searchValue,
    status,
  };
};

const useGetTeamMembers = () => {
  const { data, loading } = useQuery<GetTeamMembers, GetTeamMembersVariables>(
    GET_TEAM_MEMBERS,
  );

  const teamMembers = (data?.teamMembers || []).map((teamMember) =>
    mapToDictionaryItem(teamMember),
  );

  return {
    loadingTeamMembers: loading,
    teamMembers,
  };
};

export const useUsers = () => {
  const {
    handlePrimaryMemberUpdate,
    handleSearchUpdate,
    handleStatusUpdate,
    primaryMember,
    searchValue,
    status,
  } = useUsersFilters();

  const { teamMembers } = useGetTeamMembers();

  const {
    data,
    fetchMore,
    loading: isLoading,
  } = useQuery<GetUsers, GetUsersVariables>(GET_USERS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        limit: API_DATA_LIMIT,
        offset: 0,
        planStatus: status,
        primaryMember,
        searchValue,
      },
    },
  });

  const users = useMemo(() => data?.users || [], [data]);

  const fetchMoreUsers = () => {
    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          users: [...prev.users, ...fetchMoreResult.users],
        };
      },
      variables: {
        input: {
          limit: API_DATA_LIMIT,
          offset: users.length,
          planStatus: status,
          primaryMember,
          searchValue,
        },
      },
    });
  };

  const { loginAsUser } = useLoginAsUser();
  const { fetchLoginLink } = useFetchLoginLink();

  const setDialogOpen = useSetModalOpen();
  const handleAddUserClick = () => {
    setDialogOpen(ADD_NEW_USER_MODAL, true);
  };

  return {
    fetchLoginLink,
    fetchMoreUsers,
    handleAddUserClick,
    handlePrimaryMemberUpdate,
    handleSearchUpdate,
    handleStatusUpdate,
    isLoading,
    loginAsUser,
    primaryMember,
    searchValue,
    status,
    teamMembers,
    users,
  };
};
