import { object, string } from 'yup';

export const MATERIALS_LONGEST_LEAD_TIME_CONFIRM_DIALOG =
  'MATERIALS_LONGEST_LEAD_TIME_CONFIRM_DIALOG';

export const MATERIALS_LONGEST_LEAD_TIME_CONFIRM_INITIAL_VALUES = {
  date: null,
  days: 0,
  notes: '',
};

export const validationSchema = object().shape({
  date: string().required('Required'),
});
