export type UserItemRouteParams = {
  edit?: string;
  userId: string;
};

export const getUserPlanInformationPath = (userId = ':userId') =>
  `/users/${userId}/plan-information`;

export const getUserPath = (userId = ':userId') => `/users/${userId}`;

export const USER_PATHS = {
  getUserPath,
  getUserPlanInformationPath,
};
