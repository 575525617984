import {
  calculateDaysLate,
  dateTimeToUSFormatWOSeconds,
  dateToUSFormat,
  getDaysLateStr,
} from 'common/utils/date';

import { KeyDateChangeEvent } from 'common/apollo/models/keyDateChangeEvent';
import { KeyDateSource } from 'common/apollo/enums/KeyDateSource';

import { filterRecentHistory } from 'ClientTasks/TargetDate/TargetDateHistoryList/TargetDateHistoryList.utils';

export const getDesignApprovalHistory = (dataList: KeyDateChangeEvent[]) => {
  const history: Array<Record<string, string>> = [];

  const filteredHistory = filterRecentHistory(dataList);

  filteredHistory.forEach((entry, index) => {
    if (entry) {
      const targetDateFormat = dateToUSFormat(entry.date);

      const updatedDateFormat = dateTimeToUSFormatWOSeconds(entry.updatedAt);
      const [updatedDateStr, time, period] = updatedDateFormat.split(' ');
      const user = entry.user ? entry.user.name : 'System';

      let targetDateStr = '';
      let setTargetDateStr = '';
      if (entry.source === KeyDateSource.ORIGINAL) {
        targetDateStr = `Original Target Date: ${targetDateFormat}`;
        setTargetDateStr = `Set ${updatedDateStr} at ${time} ${period} by ${user}`;
      } else {
        targetDateStr = `Updated Target Date: ${targetDateFormat}`;
        setTargetDateStr = `Shifted ${updatedDateStr} at ${time}${period} by ${user}`;
      }

      const numDaysLate = calculateDaysLate(entry.date);
      const daysLateStr = getDaysLateStr(numDaysLate);

      history.unshift({
        daysLateText: daysLateStr,
        setTargetDateText: setTargetDateStr,
        targetDate: targetDateFormat,
        targetDateText: targetDateStr,
      });
    }
  });
  return history;
};
