import { Suspense } from 'react';

import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, Global } from '@emotion/react';
import { createBrowserHistory } from 'history';
import {
  ThemeProvider as MaterialThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import { ErrorBoundary } from '@sentry/react';

import { FeatureFlagsProvider } from 'common/hooks/FeatureFlagsProvider/FeatureFlagsProvider';
import { apolloClient } from 'common/apollo/config/initializeApolloClient';

import globalStyles from 'common/styles/global';
import { AuthenticationProvider } from 'common/hooks/AuthenticationProvider/AuthenticationProvider';

import { theme as muiTheme } from 'common/styles/core/muiTheme';
import { THEME } from 'common/consts/theme';

import { ErrorPage } from 'common/components/ErrorHandlers/ErrorPage/ErrorPage';
import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';

import { initDDRum } from 'common/utils/initializeDatadogRum';
import { initSentry } from 'common/utils/initSentry';

import { Router } from 'Router/Router';

const history = createBrowserHistory();

if (process.env.NODE_ENV === 'production') {
  initDDRum();
  initSentry({ history });
}

export const App = () => (
  <FeatureFlagsProvider>
    <ErrorBoundary fallback={<ErrorPage />}>
      <StylesProvider injectFirst>
        <MaterialThemeProvider theme={muiTheme}>
          <ThemeProvider theme={THEME}>
            <ApolloProvider client={apolloClient}>
              <SnackbarProvider maxSnack={5}>
                <AuthenticationProvider>
                  <Global styles={globalStyles} />
                  <Suspense fallback={<LoadingSpinnerWithContainer />}>
                    <Router history={history} />
                  </Suspense>
                </AuthenticationProvider>
              </SnackbarProvider>
            </ApolloProvider>
          </ThemeProvider>
        </MaterialThemeProvider>
      </StylesProvider>
    </ErrorBoundary>
  </FeatureFlagsProvider>
);
