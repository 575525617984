import pluralize from 'pluralize';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import { Checkbox } from 'common/components/Form/Checkboxes/Checkbox';

import { BatchColumnWidthMap } from 'Project/Procurement/Warehouse/warehouse.consts';
import {
  ActionWrapper,
  Cell,
  iconStyles,
  Row,
  Wrapper,
  stickyColumnStyle,
} from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/batchRow.styles';

import { Badge } from 'common/components/Badge/Badge';

type Props = {
  confirmedAt: string | null;
  eta: string | null;
  handleToggleBatch: (batchId: string) => void;
  id: string;
  inventoryUnits: number;
  isSelected: boolean;
  number: number;
  onDelete: () => void;
  onEdit: () => void;
  pallet: string | null;
  receivedAt: string | null;
  shippedAt: string | null;
  units: number;
};

export const BatchRowBase = ({
  confirmedAt,
  eta,
  handleToggleBatch,
  id,
  inventoryUnits,
  isSelected,
  number,
  onDelete,
  onEdit,
  pallet,
  receivedAt,
  shippedAt,
  units,
}: Props) => (
  <Wrapper isSelected={isSelected}>
    <Row>
      <Cell
        cellWidth={BatchColumnWidthMap.ACTION_MENU}
        css={stickyColumnStyle}
        onClick={onEdit}
      >
        <EditIcon css={iconStyles} />
      </Cell>
      <Cell cellWidth={BatchColumnWidthMap.CHECKBOX} css={stickyColumnStyle}>
        <Checkbox
          checked={isSelected}
          onChange={() => handleToggleBatch(id)}
          title="selected"
        />
      </Cell>
      <Cell cellWidth={BatchColumnWidthMap.BATCH} css={stickyColumnStyle}>
        {number}
      </Cell>
      <Cell cellWidth={BatchColumnWidthMap.UNITS}>{units}</Cell>
      <Cell cellWidth={BatchColumnWidthMap.RECEIVED_ON}>{receivedAt}</Cell>
      <Cell cellWidth={BatchColumnWidthMap.PALLET}>{pallet}</Cell>
      <Cell cellWidth={BatchColumnWidthMap.SHIPPED_ON}>{shippedAt}</Cell>
      <Cell cellWidth={BatchColumnWidthMap.ETA}>{eta}</Cell>
      <Cell cellWidth={BatchColumnWidthMap.CONFIRMED_BY}>
        {confirmedAt && <Badge variant="green">{confirmedAt}</Badge>}
      </Cell>
      <Cell cellWidth={BatchColumnWidthMap.INVENTORY_UNITS}>
        {`${inventoryUnits} ${pluralize('unit', inventoryUnits)}`}
      </Cell>
    </Row>
    <ActionWrapper>
      <Cell cellWidth={BatchColumnWidthMap.DELETE} onClick={onDelete}>
        <DeleteIcon css={iconStyles} />
      </Cell>
    </ActionWrapper>
  </Wrapper>
);
