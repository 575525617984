import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';

import { ProposalType } from 'common/apollo/enums/ProposalType';

import { useProposalsFetch } from 'Proposals/proposals.hooks';
import { FormValues } from 'ProjectProfile/ProjectInformation/v2/projectInformation.types';
import {
  ProposalDuplicate,
  ProposalDuplicateVariables,
} from 'common/apollo/mutations/__generated__/ProposalDuplicate';
import {
  DUPLICATE_PROPOSAL,
  PUBLISH_PROPOSAL,
  UNPUBLISH_PROPOSAL,
} from 'common/apollo/mutations/proposal.graphql';
import { useAlert } from 'common/hooks/useAlert';
import { ProposalDuplicateType } from 'common/apollo/enums/ProposalDuplicateType';
import {
  ProposalPublish,
  ProposalPublishVariables,
} from 'common/apollo/mutations/__generated__/ProposalPublish';
import { USER_PROPOSALS } from 'Proposals/proposals.graphql';
import {
  ProposalUnPublish,
  ProposalUnPublishVariables,
} from 'common/apollo/mutations/__generated__/proposal.graphql.generated';

export const useGenerateSalesProposal = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const { onCompleted, onError } = useAlert();

  const {
    dirty,
    isSubmitting,
    isValid,
    setFieldTouched,
    submitForm,
    validateForm,
  } = useFormikContext<FormValues>();

  const setFieldsTouched = (fields: any, prefix = '') => {
    if (typeof fields === 'string') {
      setFieldTouched(`${prefix}`, true);
    } else if (typeof fields === 'object') {
      if (Array.isArray(fields)) {
        fields.forEach((f, i) => {
          setFieldsTouched(f, `${prefix}[${i}]`);
        });
      } else {
        Object.keys(fields).forEach((f) => {
          const strPrefix = prefix ? `${prefix}.` : '';
          setFieldsTouched(fields[f], `${strPrefix}${f}`);
        });
      }
    }
  };

  const { proposals, refetch: refetchProposals } = useProposalsFetch(projectId);

  const salesProposal = useMemo(() => {
    if (!proposals) {
      return false;
    }

    return proposals.find((p) => p.type === ProposalType.SALES);
  }, [proposals]);

  const looksProposal = useMemo(() => {
    if (!proposals) {
      return false;
    }

    return proposals.find((p) => p.type === ProposalType.LOOKS);
  }, [proposals]);

  const [duplicate, { loading: isDuplicating }] = useMutation<
    ProposalDuplicate,
    ProposalDuplicateVariables
  >(DUPLICATE_PROPOSAL, {
    onError: (error: ApolloError) => onError(error.message),
  });

  const [publish, { loading: isPublishing }] = useMutation<
    ProposalPublish,
    ProposalPublishVariables
  >(PUBLISH_PROPOSAL, {
    onCompleted: () => {
      onCompleted('Finished Publishing Sales Proposal');
    },
    onError: (error: ApolloError) => {
      onError(error.message);
    },
    refetchQueries: [
      {
        query: USER_PROPOSALS,
        variables: {
          renovationPlanId: projectId,
        },
      },
    ],
  });

  const [unPublish, { loading: isUnPublishing }] = useMutation<
    ProposalUnPublish,
    ProposalUnPublishVariables
  >(UNPUBLISH_PROPOSAL, {
    onCompleted: () => onCompleted('Proposal has been unpublished'),
    onError: (error: ApolloError) => onError(error.message),
  });

  const duplicateLooksProposal = () => {
    if (!looksProposal) {
      throw new Error('No looks proposal');
    }

    if (salesProposal) {
      return Promise.resolve(salesProposal.id);
    }

    return duplicate({
      variables: {
        input: {
          name: 'Sale Proposal',
          proposalId: looksProposal.id,
          type: ProposalDuplicateType.SALES,
        },
      },
    }).then((proposal) => Promise.resolve(proposal.data?.proposalDuplicate.id));
  };

  const continueGeneratingSalesProposal = async () => {
    const proposalId = await duplicateLooksProposal();
    if (!proposalId) {
      onError('No Sales Proposal Id!');
      return;
    }

    publish({
      variables: {
        input: {
          proposalId,
        },
      },
    });
  };

  const generateSalesProposal = async () => {
    const errors = await validateForm();
    if (errors && Object.keys(errors).length) {
      onError(`Project Profiles Information is incomplete`);
      setFieldsTouched(errors);
      return;
    }

    if (dirty) {
      const salesProposalLength = proposals.filter(
        (p) => p.type === ProposalType.SALES,
      ).length;
      submitForm().then(async () => {
        const result = await refetchProposals({
          renovationPlanId: projectId,
        });

        const newSalesProposalLength = result.data.proposals.filter(
          (p) => p.type === ProposalType.SALES,
        ).length;

        // if saving made it so that there's another sales proposal,
        // then it will automatically try to publish, so return here
        if (newSalesProposalLength > salesProposalLength) {
          return;
        }

        continueGeneratingSalesProposal();
      });
    } else {
      return continueGeneratingSalesProposal();
    }
  };

  const unPublishSalesProposal = () => {
    if (!salesProposal) return;

    unPublish({
      variables: {
        input: {
          proposalId: salesProposal.id,
        },
      },
    });
  };

  const canPublishSalesProposal = useMemo(
    () => (!salesProposal || !salesProposal.publishedAt) && isValid,
    [isValid, salesProposal],
  );

  return {
    canPublishSalesProposal,
    canUnPublishSalesProposal: !!salesProposal && salesProposal.publishedAt,
    generateSalesProposal,
    isLoading: isSubmitting || isPublishing || isDuplicating || isUnPublishing,
    unPublishSalesProposal,
  };
};
