import { Formik } from 'formik';

import { Dialog } from 'common/components/Modals/Dialog/Dialog';
import { DialogTitle } from 'common/components/Modals/Dialog/DialogTitle';
import { DialogActions } from 'common/components/Modals/Dialog/DialogActions';
import { SingleCheckbox } from 'common/shared/Question/Checkbox/SingleCheckbox/SingleCheckbox';
import { useUpdateProjectFlagsDialog } from 'common/shared/Sidebar/UpdateProjectFlagsDialog/updateProjectFlagsDialog.hooks';

import { Wrapper } from 'common/shared/Sidebar/UpdateProjectFlagsDialog/updateProjectFlagsDialog.styles';
import { Typography } from 'common/components/Typography/Typography';
import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';

type Props = {
  isContractorChoice: boolean;
  isNoDeposit: boolean;
  projectId: string;
};

export const DIALOG_ID = 'UPDATE_PROJECT_FLAGS';
const DIALOG_TITLE = 'Update Project Flags';

export const UpdateProjectFlagsDialog = ({
  isContractorChoice,
  isNoDeposit,
  projectId,
}: Props) => {
  const { handleSubmit, initialValues, isLoading, onClose } =
    useUpdateProjectFlagsDialog(projectId);

  return (
    <Dialog id={DIALOG_ID} isClosable onClose={onClose}>
      <DialogTitle>{DIALOG_TITLE}</DialogTitle>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ submitForm }) => (
          <Wrapper>
            {!isContractorChoice && (
              <>
                <Typography type="body2">
                  Will update to contractor choice and set contractor
                  recommendations. If a contractor is already matched, will
                  unassign the contractor and create a contractor proposal
                  assigned to that contractor. To send work orders for the newly
                  added contractor recommendations, see the team page.
                </Typography>

                <SingleCheckbox
                  id="isContractorChoice"
                  isDisabled={isLoading}
                  isRequired={false}
                  name="isContractorChoice"
                  option={{ label: 'Contractor Choice', value: '1' }}
                />
              </>
            )}

            {!isNoDeposit && (
              <>
                <Typography type="body2">
                  Will update to no deposit. If a sales proposal exists and it
                  is not accepted, it will remove the deposit from the sales
                  proposal. If the homeowner has already set their availability,
                  this will accept the sales proposal.
                </Typography>

                <SingleCheckbox
                  id="isNoDeposit"
                  isDisabled={isLoading}
                  isRequired={false}
                  name="isNoDeposit"
                  option={{ label: 'No Deposit', value: '1' }}
                />
              </>
            )}

            <SingleCheckbox
              id="agreement"
              isRequired
              name="agreement"
              option={{
                label: 'I understand that this action cannot be undone.',
                value: '1',
              }}
            />

            {isLoading ? (
              <LoadingSpinnerWithContainer />
            ) : (
              <DialogActions
                acceptLabel="Submit"
                cancelLabel="Cancel"
                isDisabled={isLoading}
                onAccept={submitForm}
                onCancel={onClose}
              />
            )}
          </Wrapper>
        )}
      </Formik>
    </Dialog>
  );
};
