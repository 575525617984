import { gql } from '@apollo/client';

export const GET_KEY_DATE_CHANGE_EVENTS = gql`
  query GetKeyDateChangeEvents($input: AdminGetStagesInput!) {
    adminGetStages(input: $input) {
      id
      identifier
      keyDateChangeEvents {
        id
        type
        date
        updatedAt
        reason {
          id
          content
        }
        reasonNote
        user {
          id
          consumerId
          contractorId
          adminId
          name
        }
        source
      }
    }
  }
`;
