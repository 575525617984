import { ProjectPlanRoomQuestion } from 'common/apollo/models/projectPlanRoomQuestion';
import { ProjectPlanRoom } from 'common/apollo/models/projectPlanRoom';

import { roomTypeToIdentifiersMap } from './rooms.consts';
import { RoomsQuestions } from './rooms.types';

export const mapRoomsToRoomsQuestionInput = (
  projectPlanRooms: ProjectPlanRoom[] = [],
) =>
  projectPlanRooms.map((room) => ({
    identifiers: roomTypeToIdentifiersMap[room.roomType],
    pricingEngineId: room.id,
  }));

const cleanUpArray = (arrayOfString: string[] = []) =>
  Array.from(new Set(arrayOfString)).filter(Boolean);

export const groupRoomsQuestions = (
  roomsQuestions: ProjectPlanRoomQuestion[] = [],
) =>
  roomsQuestions.reduce((acc, question) => {
    if (!question.room) return acc;

    const { id: pricingEngineId } = question.room;
    const allAnswers = acc[pricingEngineId]?.answers || [];
    const allResponseTexts = acc[pricingEngineId]?.responseTexts || [];

    return {
      ...acc,
      [pricingEngineId]: {
        ...question.room,
        answers: [...allAnswers, ...question.answers],
        responseTexts: cleanUpArray([
          ...allResponseTexts,
          question.responseText || '',
        ]),
      },
    };
  }, {} as RoomsQuestions);
