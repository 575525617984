export type ProjectGalleryRouteParams = {
  pricingEngineId?: string;
  renovationPlanId: string;
  userId: string;
};

const getProjectGalleryPath = () =>
  '/users/:userId/project-gallery/:renovationPlanId';

const getProjectGalleryRoomPath = () =>
  '/users/:userId/project-gallery/:renovationPlanId/room/:pricingEngineId';

const getProjectGalleryUrl = ({
  pricingEngineId,
  renovationPlanId,
  userId,
}: ProjectGalleryRouteParams) =>
  `/users/${userId}/project-gallery/${renovationPlanId}${
    pricingEngineId ? `/room/${pricingEngineId}` : ''
  }`;

export const PROJECT_GALLERY_PATHS = {
  getProjectGalleryPath,
  getProjectGalleryRoomPath,
  getProjectGalleryUrl,
};
