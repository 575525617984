import { gql } from '@apollo/client';

export const DICTIONARY_ITEM_FIELDS = gql`
  fragment DictionaryItemFields on Dictionary {
    items {
      value
      label
    }
  }
`;
