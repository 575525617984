import { Form, Formik } from 'formik';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Grid } from '@material-ui/core';

import { ModalCloseButton } from 'common/components/Modals/ModalCloseButton';
import { Button } from 'common/components/Buttons/Button/Button';
import { FormTextArea } from 'common/_components/Form/FormTextarea/FormTextarea';
import { FormSelect } from 'common/components/Form/Select/FormSelect';

import { TextFieldSize } from 'common/enums/TextField';

import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

import {
  DialogActions,
  DialogContent,
} from 'ProjectProfile/common/components/EditForm/editForm.styles';
import { FlagToggle } from 'ProjectProfile/common/components/FlagToggle/FlagToggle';
import { UpdateProjectPlanQuestionsResponse } from 'ProjectProfile/common/responses/updateProjectPlanQuestionsResponse';

import { Answers } from 'ProjectProfile/common/types/answer';

import { useClientDetailsEdit } from './clientDetails.hooks';
import { validationSchema } from './clientDetails.consts';

type Props = {
  close: () => void;
  handleSuccessfulSubmit: (data: UpdateProjectPlanQuestionsResponse) => void;
};

export const ClientDetailsEdit = ({ close, handleSuccessfulSubmit }: Props) => {
  const { importances, initialValues, options, placeholders, submit } =
    useClientDetailsEdit();

  const handleSubmit = async (values: Answers) => {
    submit(values, importances, handleSuccessfulSubmit);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onBackdropClick={close}
      onEscapeKeyDown={close}
      open
    >
      <DialogTitle>Homeowner Details</DialogTitle>
      <ModalCloseButton onClick={close} />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogContent scrollable>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12}>
                  <FormSelect
                    id={PropertyGroupIdentifier.PROJECT_BUDGET}
                    isDisabled={isSubmitting}
                    label="Budget"
                    name={PropertyGroupIdentifier.PROJECT_BUDGET}
                    options={options.PROJECT_BUDGET}
                    placeholder="Budget"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <FormSelect
                    id={PropertyGroupIdentifier.FLEXIBILITY}
                    isDisabled={isSubmitting}
                    label="Budget Flexibility"
                    name={PropertyGroupIdentifier.FLEXIBILITY}
                    options={options.FLEXIBILITY}
                    placeholder="Budget Flexibility"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <FormSelect
                    id={
                      PropertyGroupIdentifier.IS_THE_CLIENT_LIVING_THROUGH_THE_RENOVATION
                    }
                    isDisabled={isSubmitting}
                    label="Homeowner Living Through Renovation?"
                    name={
                      PropertyGroupIdentifier.IS_THE_CLIENT_LIVING_THROUGH_THE_RENOVATION
                    }
                    options={
                      options.IS_THE_CLIENT_LIVING_THROUGH_THE_RENOVATION
                    }
                    placeholder="Homeowner Living Through Renovation?"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <FormSelect
                    id={PropertyGroupIdentifier.INVOLVEMENT}
                    isDisabled={isSubmitting}
                    label="Homeowner Involvement"
                    name={PropertyGroupIdentifier.INVOLVEMENT}
                    options={options.INVOLVEMENT}
                    placeholder="Homeowner Involvement"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <FormSelect
                    id={PropertyGroupIdentifier.RENOVATION_TIMELINE}
                    isDisabled={isSubmitting}
                    label="Renovation Timeline"
                    name={PropertyGroupIdentifier.RENOVATION_TIMELINE}
                    options={options.RENOVATION_TIMELINE}
                    placeholder="Renovation Timeline"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <FormSelect
                    id={PropertyGroupIdentifier.PAYMENT_PREFERENCE}
                    isDisabled={isSubmitting}
                    label="Payment Preference"
                    name={PropertyGroupIdentifier.PAYMENT_PREFERENCE}
                    options={options.PAYMENT_PREFERENCE}
                    placeholder="Payment Preference"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <FormSelect
                    id={PropertyGroupIdentifier.OWNERSHIP_STATUS}
                    isDisabled={isSubmitting}
                    label="Ownership Status"
                    name={PropertyGroupIdentifier.OWNERSHIP_STATUS}
                    options={options.OWNERSHIP_STATUS}
                    placeholder="Ownership Status"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
                <Grid item md={12} sm={12}>
                  <FormTextArea
                    disabled={isSubmitting}
                    id={PropertyGroupIdentifier.PREVIOUS_RENOVATION_EXPERIENCE}
                    label="Previous Renovation Experience"
                    name={
                      PropertyGroupIdentifier.PREVIOUS_RENOVATION_EXPERIENCE
                    }
                    placeholder={placeholders.PREVIOUS_RENOVATION_EXPERIENCE}
                    rows={5}
                  />
                  <FlagToggle
                    disabled={isSubmitting}
                    isOn={importances.PREVIOUS_RENOVATION_EXPERIENCE.isOn}
                    onToggle={importances.PREVIOUS_RENOVATION_EXPERIENCE.toggle}
                  />
                </Grid>
                <Grid item md={12} sm={12}>
                  <FormTextArea
                    disabled={isSubmitting}
                    id={PropertyGroupIdentifier.DECISION_MAKERS}
                    label="Decision Makers"
                    name={PropertyGroupIdentifier.DECISION_MAKERS}
                    placeholder={placeholders.DECISION_MAKERS}
                    rows={5}
                  />
                  <FlagToggle
                    disabled={isSubmitting}
                    isOn={importances.DECISION_MAKERS.isOn}
                    onToggle={importances.DECISION_MAKERS.toggle}
                  />
                </Grid>
                <Grid item md={12} sm={12}>
                  <FormTextArea
                    disabled={isSubmitting}
                    id={PropertyGroupIdentifier.TIMELINE_PREFERENCES}
                    label="Timeline Preferences"
                    name={PropertyGroupIdentifier.TIMELINE_PREFERENCES}
                    placeholder={placeholders.TIMELINE_PREFERENCES}
                    rows={5}
                  />
                  <FlagToggle
                    disabled={isSubmitting}
                    isOn={importances.TIMELINE_PREFERENCES.isOn}
                    onToggle={importances.TIMELINE_PREFERENCES.toggle}
                  />
                </Grid>
                <Grid item md={12} sm={12}>
                  <FormTextArea
                    disabled={isSubmitting}
                    id={PropertyGroupIdentifier.CLIENT_NOTES}
                    label="Homeowner Notes"
                    name={PropertyGroupIdentifier.CLIENT_NOTES}
                    placeholder={placeholders.CLIENT_NOTES}
                    rows={5}
                  />
                  <FlagToggle
                    disabled={isSubmitting}
                    isOn={importances.CLIENT_NOTES.isOn}
                    onToggle={importances.CLIENT_NOTES.toggle}
                  />
                </Grid>
                <Grid item md={12} sm={12}>
                  <FormTextArea
                    disabled={isSubmitting}
                    id={PropertyGroupIdentifier.PROJECT_EXTENSION_NOTES}
                    label="Project Extension Notes"
                    name={PropertyGroupIdentifier.PROJECT_EXTENSION_NOTES}
                    placeholder={placeholders.PROJECT_EXTENSION_NOTES}
                    rows={5}
                  />
                </Grid>
                <FlagToggle
                  disabled={isSubmitting}
                  isOn={importances.PROJECT_EXTENSION_NOTES.isOn}
                  onToggle={importances.PROJECT_EXTENSION_NOTES.toggle}
                />
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
