import { gql } from '@apollo/client';

export const GET_PROJECT_PROCUREMENT_ORDERS = gql`
  query GetProjectProcurementOrders($projectId: ID!) {
    project(id: $projectId) {
      id
      orders {
        blockRequestedDeliveryDate
        id
        number
        status
        timing
        requestedDeliverTo
        items {
          id
          revisionActionItem {
            id
          }
          skuVendor {
            id
            vendorSku
            vendor {
              id
              name
            }
          }
        }
        shipments {
          id
          status
        }
      }
    }
  }
`;
