import { gql } from '@apollo/client';

export const GET_PROJECT_PLAN_ROOMS_LINKS = gql`
  query GetProjectPlanRoomsLinks($input: ProjectPlanRoomsLinksInput!) {
    projectPlanRoomsLinks(input: $input) {
      id
      room {
        id
        name
        roomType
      }
      canvasLinks {
        name
        url
      }
    }
  }
`;
