import {
  init,
  reactRouterV5BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react';

import { History } from 'history';

type Props = {
  history: History;
};

const apiUrlRegex = /^https:\/\/api(-dev)?\.blockrenovation\.com\/graphql/;

export const initSentry = ({ history }: Props) => {
  init({
    dsn: 'https://8aed337d2c0c61fef03d7c4032d68f81@o168701.ingest.us.sentry.io/4507525246222336',
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [
      reactRouterV5BrowserTracingIntegration({ history }),
      replayIntegration({
        blockAllMedia: false,
        maskAllText: false,
        networkDetailAllowUrls: [apiUrlRegex],
      }),
    ],
    release: process.env.REACT_APP_VERSION,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    tracePropagationTargets: [apiUrlRegex],
    tracesSampleRate: 1.0,
  });
};
