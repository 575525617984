import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { TextField } from 'common/components/Form/TextField/TextField';
import { rgba } from 'common/utils/colors';

type ControlProps = {
  hasError?: boolean;
};

export const Wrapper = styled.div<ControlProps>`
  ${({ hasError, theme }) => css`
    align-items: center;
    border: 1px solid ${hasError ? theme.color.red : theme.color.celeste};
    border-radius: 4px;
    color: ${hasError ? theme.color.red : theme.color.darkGray};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 12px 0 12px;

    &::placeholder {
      color: ${hasError ? theme.color.red : theme.color.darkGray};
      transition: all 0.125s ease;
    }

    &:focus {
      border-color: ${theme.color.primary};
      color: ${theme.color.black};
    }

    &:focus::placeholder {
      color: ${theme.color.black};
    }

    &.active {
      border-color: ${theme.color.primary};
      border-bottom-color: ${theme.color.celeste};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      color: ${theme.color.black};
    }

    &:hover {
      border-color: ${theme.color.black};
    }

    &[disabled] {
      background-color: ${theme.color.concrete};
      border-color: ${theme.color.celeste};
      color: ${rgba(theme.color.darkGray, 0.5)};
      cursor: not-allowed;
    }

    &[disabled]::placeholder {
      color: ${rgba(theme.color.darkGray, 0.5)};
    }
  `}
`;

export const TagSearchField = styled(TextField)`
  flex: 0.5;
  flex-grow: 1;
`;

export const IconWrapper = styled.div`
  padding: 0 4px 0 4px;
`;

export const TagsWrapper = styled.div`
  max-width: 240px;
`;

export const TagLabel = styled.label`
  color: ${({ theme }) => theme.color.black};
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
`;

export const SelectedTag = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.lightGray};
  display: inline-flex;
  margin: 4px;
  padding: 2px 4px 2px 8px;
`;
