import { ChangeEvent, FormEvent, useMemo, useState } from 'react';

import { DataProxy, FetchResult, useMutation } from '@apollo/client';

import { isAfter, isBefore, isValid } from 'date-fns';

import { ProposalQaType } from 'common/apollo/enums/ProposalQaType';

import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';
import { useAlert } from 'common/hooks/useAlert';

import { RequestProposalQaRequest } from 'PricingEngineSidebar/Proposal/ProposalAction/ProposalQaRequest/apollo/requests/RequestProposalQaRequest';
import { RequestProposalQaResponse } from 'PricingEngineSidebar/Proposal/ProposalAction/ProposalQaRequest/apollo/responses/RequestProposalQaResponse';
import { GET_PROPOSAL_SIDEBAR_ACTION_DATA } from 'PricingEngineSidebar/Proposal/ProposalAction/proposalAction.graphql';
import { REQUEST_PROPOSAL_QA } from 'PricingEngineSidebar/Proposal/ProposalAction/ProposalQaRequest/proposalQaRequest.graphql';
import { REQUEST_PROPOSAL_QA_MODAL } from 'PricingEngineSidebar/Proposal/ProposalAction/proposalAction.consts';

import {
  GetProposalSidebarActionData,
  GetProposalSidebarActionDataVariables,
} from 'PricingEngineSidebar/Proposal/ProposalAction/__generated__/proposalAction.graphql.generated';

import { getLatestProposalQaCompletedAt } from './proposalQaRequest.utils';

export const useProposalQaRequest = (
  latestDesignQaCompletedAt: Date | null,
  latestProcurementQaCompletedAt: Date | null,
) => {
  const setDialogOpen = useSetModalOpen();
  const { onError } = useAlert();

  const [selectedQaTypes, setSelectedQaTypes] = useState([
    ProposalQaType.DESIGN,
    ProposalQaType.PROCUREMENT,
  ]);

  const [requestDate, setRequestDate] = useState<Date | null>(null);
  const [requestedAtValidationError, setRequestedAtValidationError] = useState<
    string | null
  >(null);
  const [qaFormConfirmed, setQaFormConfirmed] = useState<boolean>(false);

  const latestQaCompletedAt = useMemo(
    () =>
      getLatestProposalQaCompletedAt(
        latestDesignQaCompletedAt,
        latestProcurementQaCompletedAt,
      ),
    [latestDesignQaCompletedAt, latestProcurementQaCompletedAt],
  );

  const handleQaTypeChange = (event: FormEvent<HTMLInputElement>) => {
    const { checked: isSelected, id: selectedQaType } = event.currentTarget;

    setSelectedQaTypes((selectedType) =>
      isSelected
        ? selectedType.concat(
            ProposalQaType[selectedQaType as keyof typeof ProposalQaType],
          )
        : selectedType.filter(
            (qaType) =>
              qaType !==
              ProposalQaType[selectedQaType as keyof typeof ProposalQaType],
          ),
    );
  };

  const onChangeQaFormConfirmation = (event: ChangeEvent<HTMLInputElement>) => {
    setQaFormConfirmed(event.target.checked);
  };

  const onModalCloseButton = () =>
    setDialogOpen(REQUEST_PROPOSAL_QA_MODAL, false);

  const validateRequestedAtTimestamp = (date: Date | null) => {
    setRequestDate(date);
    if (date && !isValid(date))
      setRequestedAtValidationError('Invalid date format');
    else if (
      !date ||
      (!!date && !latestQaCompletedAt && isBefore(date, new Date())) ||
      (!!date &&
        latestQaCompletedAt &&
        date &&
        isAfter(date, latestQaCompletedAt) &&
        isBefore(date, new Date()))
    ) {
      setRequestedAtValidationError(null);
    } else {
      setRequestedAtValidationError(
        'Date must be between latest QA completion date and current date',
      );
    }
  };

  const [requestProposalQa] = useMutation<
    RequestProposalQaResponse,
    RequestProposalQaRequest
  >(REQUEST_PROPOSAL_QA, {
    onCompleted: () => setDialogOpen(REQUEST_PROPOSAL_QA_MODAL, false),
    onError: (error) => onError(error.message),
  });

  const handleRequestProposalQa = (proposalId: string) =>
    requestProposalQa({
      update: (
        proxy: DataProxy,
        result: FetchResult<RequestProposalQaResponse>,
      ) => {
        const data = proxy.readQuery<
          GetProposalSidebarActionData,
          GetProposalSidebarActionDataVariables
        >({
          query: GET_PROPOSAL_SIDEBAR_ACTION_DATA,
          variables: {
            proposalId,
          },
        });

        const requestedProposalQa = result.data?.requestProposalQa;

        proxy.writeQuery({
          data: {
            ...data,
            proposal: {
              ...data?.proposal,
              latestDesignQaEntry:
                requestedProposalQa?.find(
                  (proposalQa) => proposalQa.qaType === ProposalQaType.DESIGN,
                ) || null,
              latestProcurementQaEntry:
                requestedProposalQa?.find(
                  (proposalQa) =>
                    proposalQa.qaType === ProposalQaType.PROCUREMENT,
                ) || null,
            },
          },
          query: GET_PROPOSAL_SIDEBAR_ACTION_DATA,
          variables: {
            proposalId,
          },
        });
      },
      variables: {
        input: {
          proposalId,
          proposalQaTypes: selectedQaTypes,
          requestedAt: requestDate,
        },
      },
    });

  return {
    handleQaTypeChange,
    handleRequestProposalQa,
    onChangeQaFormConfirmation,
    onModalCloseButton,
    qaFormConfirmed,
    requestDate,
    requestedAtValidationError,
    selectedQaTypes,
    setDialogOpen,
    validateRequestedAtTimestamp,
  };
};
