import { SkuUnit } from 'common/apollo/enums/SkuUnit';

import { pluralize } from 'common/utils/pluralize';

import {
  UnitsWrapper,
  UnitsValue,
  UnitsIndicator,
} from 'Project/Procurement/common/components/Units/units.styles';

type Props = {
  amount: number;
  boxCount: number;
  indicator?: boolean;
  unitType?: SkuUnit;
};

const isUndefined = (value: any): value is undefined =>
  typeof value == 'undefined';

export const Units = ({ amount, boxCount, indicator, unitType }: Props) => (
  <UnitsWrapper>
    {!isUndefined(indicator) && <UnitsIndicator solidBackground={indicator} />}

    <UnitsValue>
      {unitType === SkuUnit.SQ_FOOT ? (
        <>
          <span>{amount} SQFT</span>
          <span>
            {boxCount} {pluralize('Box', boxCount)}
          </span>
        </>
      ) : (
        <span>
          {amount} {pluralize('Unit', amount)}
        </span>
      )}
    </UnitsValue>
  </UnitsWrapper>
);
