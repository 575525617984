import styled from '@emotion/styled';
import { css } from '@emotion/react';

import CircularProgress from '@material-ui/core/CircularProgress';

export const SpinnerWrapper = styled.div<{ visible: boolean }>`
  height: 1px;
  position: relative;
  visibility: hidden;

  ${({ visible }) =>
    visible &&
    css`
      height: initial;
      padding: 25px 0;
      visibility: visible;
    `};
`;

export const Spinner = styled(CircularProgress)`
  display: block;
  margin: 0 auto;
`;

export const EmptyState = styled.div`
  grid-column: 1 / -1;
  padding: 20px;
  text-align: center;
`;
