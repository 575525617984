import { CopyToClipboardButton } from 'common/components/Buttons/CopyToClipboardButton/CopyToClipboardButton';

import { LinkContainer } from 'ProjectProfile/common/components/Link/LinkContainer/LinkContainer';
import { LinkItem } from 'ProjectProfile/common/components/Link/LinkItem/LinkItem';
import { OutlinedLink } from 'ProjectProfile/common/components/Link/Link';

import { CopyIcon, Title, Wrapper } from './inspirationsLink.styles';
import { useInspirationLink } from './inspirationsLink.hooks';

export const InspirationsLink = () => {
  const { directInspirationLink, inspirationLink } = useInspirationLink();

  return (
    <Wrapper>
      <Title>
        Homeowner Inspiration
        <CopyToClipboardButton
          aria-label="Copy log in link"
          color="secondary"
          copyText={directInspirationLink}
        >
          <CopyIcon />
        </CopyToClipboardButton>
      </Title>

      <LinkContainer>
        <LinkItem>
          <OutlinedLink color="primary" href={inspirationLink} target="_blank">
            View all saved inspiration
          </OutlinedLink>
        </LinkItem>
      </LinkContainer>
    </Wrapper>
  );
};
