import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { UPDATE_PROJECT_PROCUREMENT_DELIVERY_BATCHES } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/graphql/updateBatches.graphql';
import { UpdateProjectProcurementDeliveryBatchesRequest } from 'common/apollo/requests/updateProjectProcurementDeliveryBatchesRequest';
import { UpdateProjectProcurementDeliveryBatchesResponse } from 'common/apollo/responses/updateProjectProcurementDeliveryBatchesResponse';

import { GET_PROJECT_PROCUREMENT_DELIVERY_BATCHES } from 'Project/Procurement/Warehouse/warehouse.graphql';

import { DELETE_PROJECT_PROCUREMENT_DELIVERY_BATCHES } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/graphql/deleteBatch.graphql';
import { DeleteProjectProcurementDeliveryBatchesResponse } from 'common/apollo/responses/deleteProjectProcurementDeliveryBatchesResponse';
import { DeleteProjectProcurementDeliveryBatchesRequest } from 'common/apollo/requests/deleteProjectProcurementDeliveryBatchesRequest';
import { BatchInput } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/batchRow.types';
import { GetProjectProcurementDeliveryBatchesResponse } from 'Project/Procurement/common/apollo/responses/getProjectProcurementDeliveryBatchesResponse';
import { GetProjectProcurementDeliveryBatchesRequest } from 'Project/Procurement/common/apollo/requests/getProjectProcurementDeliveryBatchesRequest';
import { batchInputMapper } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/batchRow.utils';

export const useBatch = (batchId: string) => {
  const { projectId } = useParams<{ projectId: string }>();

  const [isEditing, setIsEditing] = useState(false);

  const [updateDeliveryBatches] = useMutation<
    UpdateProjectProcurementDeliveryBatchesResponse,
    UpdateProjectProcurementDeliveryBatchesRequest
  >(UPDATE_PROJECT_PROCUREMENT_DELIVERY_BATCHES);

  const [deleteDeliveryBatches] = useMutation<
    DeleteProjectProcurementDeliveryBatchesResponse,
    DeleteProjectProcurementDeliveryBatchesRequest
  >(DELETE_PROJECT_PROCUREMENT_DELIVERY_BATCHES);

  const onEdit = () => {
    setIsEditing(true);
  };

  const onCancelEdit = () => {
    setIsEditing(false);
  };

  const onDelete = () => {
    if (!batchId) return;
    deleteDeliveryBatches({
      update: (cache) => {
        const store = cache.readQuery({
          query: GET_PROJECT_PROCUREMENT_DELIVERY_BATCHES,
          variables: {
            projectId,
          },
        }) as GetProjectProcurementDeliveryBatchesResponse;

        const deliveryBatches = [...store.project.deliveryBatches];

        const index = deliveryBatches.findIndex(
          (batch) => batch.id === batchId,
        );

        if (index === -1) return;

        deliveryBatches.splice(index, 1);

        cache.writeQuery<
          GetProjectProcurementDeliveryBatchesResponse,
          GetProjectProcurementDeliveryBatchesRequest
        >({
          data: {
            ...store,
            project: {
              ...store.project,
              deliveryBatches,
            },
          },
          query: GET_PROJECT_PROCUREMENT_DELIVERY_BATCHES,
          variables: {
            projectId,
          },
        });
      },
      variables: {
        input: {
          deliveryBatchesIds: [batchId],
        },
      },
    });
  };

  const onSave = (input: BatchInput) => {
    if (!batchId) return;

    updateDeliveryBatches({
      variables: {
        input: {
          deliveryBatches: [
            {
              ...batchInputMapper(input),
              id: batchId,
            },
          ],
        },
      },
    });

    setIsEditing(false);
  };

  return {
    isEditing,
    onCancelEdit,
    onDelete,
    onEdit,
    onSave,
  };
};
