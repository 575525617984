import { useCallback, useState } from 'react';

export const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => setState((isOn) => !isOn), []);
  const toggleOff = useCallback(() => setState(false), []);
  const toggleOn = useCallback(() => setState(true), []);

  return {
    isOff: !state,
    isOn: state,
    state,
    toggle,
    toggleOff,
    toggleOn,
  };
};
