import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { KeyDateSource } from 'common/apollo/enums/KeyDateSource';
import { GetKeyDateChangeEventsResponse } from 'common/apollo/responses/getKeyDateChangeEventsResponse';
import { GetKeyDateChangeEventsRequest } from 'common/apollo/requests/getKeyDateChangeEventsRequest';
import { StageIdentifier } from 'common/apollo/enums/StageIdentifier';

import { GET_KEY_DATE_CHANGE_EVENTS } from 'ClientTasks/TargetDateHistoryModal/targetDateHistoryModal.graphql';

export const useGetKeyDateChangeEvents = (stageIdentifier: StageIdentifier) => {
  const { projectId } = useParams<{ projectId: string }>();
  const {
    data,
    loading: isLoading,
    refetch,
  } = useQuery<GetKeyDateChangeEventsResponse, GetKeyDateChangeEventsRequest>(
    GET_KEY_DATE_CHANGE_EVENTS,
    {
      variables: {
        input: {
          projectId,
        },
      },
    },
  );

  const keyDateChangeEvents =
    data?.adminGetStages.find((stage) => stage.identifier === stageIdentifier)
      ?.keyDateChangeEvents || [];

  const originalKeyDate = keyDateChangeEvents.find(
    (keyDate) => keyDate.source === KeyDateSource.ORIGINAL,
  );

  const filterKeyDateChangeEvents = keyDateChangeEvents.filter(
    (keyDate) =>
      keyDate.date !== originalKeyDate?.date ||
      keyDate.source === KeyDateSource.CONFIRMED,
  );

  if (originalKeyDate) filterKeyDateChangeEvents.push(originalKeyDate);

  return {
    isLoading,
    keyDateChangeEventRefetch: refetch,
    keyDateChangeEvents: filterKeyDateChangeEvents,
  };
};
