import Downshift, { StateChangeOptions, DownshiftState } from 'downshift';

import { TagGroupType } from 'common/apollo/enums/TagGroupType';
import { isSelectType } from 'common/guards/select';
import { SelectType } from 'common/types/select';

export const getOptionValue = <Option>(selected: SelectType<Option>) =>
  isSelectType(selected) ? selected.value : selected;

export const getSelectedItem = <Option>(
  options?: Array<SelectType<Option> | Option>,
  item?: Option,
) =>
  (options || []).find((option) =>
    isSelectType(option) ? item === option.value : item === option,
  ) || null;

export const isOptionSelected = <Option>(
  option: SelectType<Option> | Option,
  selectedItem: SelectType<Option> | Option,
) => {
  if (!selectedItem) return false;

  return isSelectType<Option>(selectedItem) && isSelectType<Option>(option)
    ? selectedItem.label === option.label && selectedItem.value === option.value
    : selectedItem === option;
};

export const stateReducer = <Option>(
  state: DownshiftState<Option>,
  changes: StateChangeOptions<Option>,
  selectionType?: TagGroupType,
) => {
  if (changes.type === Downshift.stateChangeTypes.keyDownEscape) {
    return {
      ...changes,
      isOpen: false,
    };
  }
  /// Don't close the menu on item click for multi select
  if (
    changes.type === Downshift.stateChangeTypes.clickItem &&
    selectionType === TagGroupType.MULTI
  ) {
    return {
      ...changes,
      highlightedIndex: state.highlightedIndex,
      isOpen: state.isOpen,
    };
  }

  return changes;
};
