import { Fragment } from 'react';
import { Form, Formik, FieldArray } from 'formik';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { Grid, Button as AddLinkButton } from '@material-ui/core';

import { ReactComponent as TrashIcon } from 'common/assets/icons/delete-small.svg';

import { ModalCloseButton } from 'common/components/Modals/ModalCloseButton';
import { Button } from 'common/components/Buttons/Button/Button';
import { FormSelect } from 'common/components/Form/Select/FormSelect';

import {
  DialogActions,
  DialogContent,
} from 'ProjectProfile/common/components/EditForm/editForm.styles';

import { TextFieldSize } from 'common/enums/TextField';

import { validationSchema } from './canvasLinks.consts';

import { useCanvasLinksEdit } from './canvasLinks.hooks';

import { LinkInput, DeleteLinkButton, LinkInputWrapper } from '../links.styles';

type Props = {
  close: () => void;
};

export const CanvasLinksEdit = ({ close }: Props) => {
  const { emptyLink, initialValues, selectOptions, submit } =
    useCanvasLinksEdit();

  const handleSubmit = (values: any) => submit(values, close);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onBackdropClick={close}
      onEscapeKeyDown={close}
      open
    >
      <DialogTitle>Canvas Links</DialogTitle>
      <ModalCloseButton onClick={close} />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, resetForm, values }) => (
          <Form>
            <FieldArray name="projectPlanRoomsLinks">
              {({ insert, push, remove }) => (
                <DialogContent>
                  <Grid container spacing={2}>
                    {values.projectPlanRoomsLinks.length ? (
                      values.projectPlanRoomsLinks.map((_, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={index}>
                          <Grid item md={5} sm={12} xs={12}>
                            <FormSelect
                              id={`projectPlanRoomsLinks.${index}.pricingEngineId`}
                              isDisabled={isSubmitting}
                              label="Canvas Link Room name"
                              name={`projectPlanRoomsLinks.${index}.pricingEngineId`}
                              options={selectOptions}
                              placeholder="Canvas Link Room name"
                              size={TextFieldSize.EXTRA_LARGE}
                            />
                          </Grid>
                          <Grid item md={7} sm={12} xs={12}>
                            <LinkInputWrapper>
                              <LinkInput
                                disabled={isSubmitting}
                                id={`projectPlanRoomsLinks.${index}.url`}
                                label="Canvas URL"
                                name={`projectPlanRoomsLinks.${index}.url`}
                                placeholder="Canvas URL"
                                size={TextFieldSize.EXTRA_LARGE}
                              />

                              <DeleteLinkButton onClick={() => remove(index)}>
                                <TrashIcon />
                              </DeleteLinkButton>
                            </LinkInputWrapper>
                          </Grid>
                        </Fragment>
                      ))
                    ) : (
                      <Grid item md={12} sm={12}>
                        No Canvas link is added yet, please add one.
                      </Grid>
                    )}
                    <Grid item md={12} sm={12}>
                      <AddLinkButton
                        color="secondary"
                        onClick={() => push(emptyLink)}
                        size="small"
                        type="button"
                        variant="outlined"
                      >
                        Add Helpful link
                      </AddLinkButton>
                    </Grid>
                  </Grid>
                </DialogContent>
              )}
            </FieldArray>
            <DialogActions>
              <Button
                color="primary"
                disabled={isSubmitting}
                onClick={() => resetForm()}
                variant="text"
              >
                disCard changes
              </Button>
              <Button
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
