import { ReactNode } from 'react';

import { TextFieldProps } from '@material-ui/core/TextField';

import { TextFieldSize, TextFieldVariant } from 'common/enums/TextField';

import { StyledInputAdornment, StyledTextField } from './TextField.styles';

export type Props = {
  endAdornment?: ReactNode;
  errorMessage?: string;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isMultiline?: boolean;
  isRequired?: boolean;
  roundedBorders?: boolean;
  size?: TextFieldSize;
  startAdornment?: ReactNode;
  variant?: TextFieldVariant;
} & Omit<TextFieldProps, 'size' | 'variant'>;

// TODO: Revisit custom size and variant after updatng material-ui
export const TextField = ({
  InputProps,
  endAdornment,
  errorMessage,
  helperText,
  id,
  isDisabled = false,
  isFullWidth = false,
  isMultiline = false,
  isRequired = false,
  name,
  roundedBorders = false,
  size = TextFieldSize.MEDIUM,
  startAdornment,
  variant = TextFieldVariant.OUTLINED,
  ...props
}: Props) => {
  const inputPropsFromProps = {
    ...InputProps,
    endAdornment: endAdornment ? (
      <StyledInputAdornment position="end">{endAdornment}</StyledInputAdornment>
    ) : undefined,
    startAdornment: startAdornment ? (
      <StyledInputAdornment position="start">
        {startAdornment}
      </StyledInputAdornment>
    ) : undefined,
  };

  return (
    <StyledTextField
      {...props}
      InputProps={inputPropsFromProps}
      disabled={isDisabled}
      error={!!errorMessage}
      fullWidth={isFullWidth}
      helperText={errorMessage || helperText}
      id={id || name}
      multiline={isMultiline}
      required={isRequired}
      roundedBorders={roundedBorders}
      sizeCustom={size}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      variant={variant as any}
    />
  );
};
