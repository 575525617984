import { SkuVendorStockStatus } from 'common/apollo/enums/SkuVendorStockStatus';
import { RoomType } from 'common/apollo/enums/RoomType';

import { isUndefined } from 'common/guards/isUndefined';

import { Tooltip } from 'common/components/Tooltip/Tooltip';

import { getStockStatusOptions } from 'common/shared/SkuStockStatus/skuStockStatus.utils';

import {
  Action,
  Description,
  DescriptionWrapper,
  Wrapper,
} from 'common/shared/SkuStockStatus/skuStockStatus.styles';

type Props = {
  backInStockDate: string | null;
  hasWriteAccess?: boolean;
  leadTimeUpdatedAt: string | null;
  maxLeadTimeWeeks: number | null;
  minLeadTimeWeeks: number | null;
  onStatusUpdate?: () => void;
  roomType: RoomType;
  stockStatus: SkuVendorStockStatus | null;
};

export const SkuStockStatus = ({
  backInStockDate,
  hasWriteAccess,
  leadTimeUpdatedAt,
  maxLeadTimeWeeks,
  minLeadTimeWeeks,
  onStatusUpdate,
  roomType,
  stockStatus,
}: Props) => {
  const {
    action,
    description,
    icon: Icon,
    tooltip,
  } = getStockStatusOptions({
    backInStockDate,
    leadTimeUpdatedAt,
    maxLeadTimeWeeks,
    minLeadTimeWeeks,
    roomType,
    stockStatus,
  });

  const checkWriteAccess = isUndefined(hasWriteAccess) ? true : hasWriteAccess;

  const showAction = checkWriteAccess && onStatusUpdate && action;

  return (
    <Wrapper>
      <Tooltip arrow content={tooltip} placement="top">
        <Icon />
      </Tooltip>

      <DescriptionWrapper>
        <Description>{description}</Description>

        {showAction && <Action onClick={onStatusUpdate}>{action}</Action>}
      </DescriptionWrapper>
    </Wrapper>
  );
};
