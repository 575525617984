import {
  Wrapper,
  Label,
  Control,
  Description,
} from 'common/_components/Form/Textarea/textarea.styles';

type TextAreaProps = {
  description?: string;
  fluid?: boolean;
  hasError?: boolean;
  id: string;
  label?: string;
  readonly?: boolean;
  value?: string;
};

type Props = TextAreaProps &
  Exclude<Omit<React.HTMLProps<HTMLTextAreaElement>, 'as'>, TextAreaProps>;

export const Textarea = ({
  description,
  disabled,
  fluid,
  hasError,
  id,
  label,
  readonly,
  ...rest
}: Props) => (
  <Wrapper fluid={fluid}>
    {label && (
      <Label disabled={disabled} hasError={hasError} htmlFor={id}>
        {label}
      </Label>
    )}

    <Control
      disabled={disabled}
      hasError={hasError}
      id={id}
      readOnly={readonly}
      {...rest}
    />

    {description && !hasError && <Description>{description}</Description>}
  </Wrapper>
);
