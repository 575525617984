import { ErrorSegment } from 'common/components/ErrorHandlers/ErrorSegment/ErrorSegment';
import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';

import { OrderShipments } from 'Project/Procurement/EditOrder/OrderShipments/OrderShipments';
import { OrderMaterials } from 'Project/Procurement/EditOrder/OrderMaterials/OrderMaterials';
import { OrderInfo } from 'Project/Procurement/EditOrder/OrderInfo/OrderInfo';

import { useEditOrder } from 'Project/Procurement/EditOrder/editOrder.hooks';

import { Wrapper } from 'Project/Procurement/EditOrder/editOrder.styles';

const EditOrder = () => {
  const {
    error,
    expectedMaterialsCost,
    initialValues,
    isLoading,
    materials,
    orderNumber,
    shipments,
  } = useEditOrder();

  if (isLoading) return <LoadingSpinnerWithContainer />;
  if (error) return <ErrorSegment />;

  return (
    <Wrapper>
      <OrderInfo
        expectedMaterialsCost={expectedMaterialsCost}
        initialValues={initialValues}
      />
      <OrderMaterials
        materials={materials}
        orderNumber={orderNumber}
        shipments={shipments}
      />
      <OrderShipments shipments={shipments} />
    </Wrapper>
  );
};

export default EditOrder;
