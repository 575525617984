import styled from '@emotion/styled';

export const TextAreaField = styled.textarea`
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.color.gray6};
  display: block;
  font: 400 16px/24px ${({ theme }) => theme.font.inter};
  height: 100%;
  padding: 16px 8px;
  width: 100%;

  &:focus {
    outline-color: ${({ theme }) => theme.color.primary};
  }
`;
