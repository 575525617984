import { Formik } from 'formik';

import { Dialog } from 'common/components/Modals/Dialog/Dialog';
import { DialogContent } from 'common/components/Modals/Dialog/DialogContent';
import { DialogTitle } from 'common/components/Modals/Dialog/DialogTitle';
import {
  MATERIALS_LONGEST_LEAD_TIME_CONFIRM_DIALOG,
  MATERIALS_LONGEST_LEAD_TIME_CONFIRM_INITIAL_VALUES,
  validationSchema,
} from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeConfirm/materialsLongestLeadTimeConfirm.consts';
import { MaterialsLongestLeadTimeConfirmForm } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeConfirm/MaterialsLongestLeadTimeConfirmForm/MaterialsLongestLeadTimeConfirmForm';

export const MaterialsLongestLeadTimeConfirmModal = () => (
  <Dialog id={MATERIALS_LONGEST_LEAD_TIME_CONFIRM_DIALOG} maxWidth="md">
    <DialogTitle>Confirm Materials Longest Lead Time</DialogTitle>
    <DialogContent>
      <Formik
        initialValues={MATERIALS_LONGEST_LEAD_TIME_CONFIRM_INITIAL_VALUES}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        <MaterialsLongestLeadTimeConfirmForm />
      </Formik>
    </DialogContent>
  </Dialog>
);
