import { Form } from 'formik';

import { SelectType } from 'common/types/select';

import { FormTextField } from 'common/components/Form/TextField/FormTextField';
import { FormSelect } from 'common/components/Form/Select/FormSelect';
import { FormDatePicker } from 'common/components/Form/DatePicker/FormDatePicker';
import { FormCurrencyField } from 'common/components/Form/CurrencyField/FormCurrencyField';

import { Wrapper } from 'Project/Procurement/EditOrder/OrderInfo/OrderInfoForm/orderInfoForm.styles';

import {
  ORDER_DELIVER_TO_OPTIONS,
  ORDER_REASON_OPTIONS,
  ORDER_SHIPPING_METHOD_OPTIONS,
  ORDER_STATUS_OPTIONS,
  ORDER_TIMING_OPTIONS,
} from 'Project/Procurement/common/consts/order.consts';

type Props = { isDisabled: boolean; vendors: SelectType[] };

export const OrderInfoForm = ({ isDisabled, vendors }: Props) => (
  <Form>
    <Wrapper>
      <FormSelect
        id="vendorId"
        isDisabled
        label="Vendor"
        name="vendorId"
        options={vendors}
      />
      <FormDatePicker
        id="dateOrdered"
        isDisabled={isDisabled}
        label="Date Ordered"
        name="dateOrdered"
      />
      <FormSelect
        id="status"
        isDisabled={isDisabled}
        label="Status"
        name="status"
        options={ORDER_STATUS_OPTIONS}
      />
      <FormDatePicker
        id="initialRequestedDeliveryDate"
        isDisabled={isDisabled}
        label="Initial Requested Delivery Date"
        name="initialRequestedDeliveryDate"
      />
      <FormSelect
        id="requestedDeliverTo"
        isDisabled={isDisabled}
        label="Deliver To"
        name="requestedDeliverTo"
        options={ORDER_DELIVER_TO_OPTIONS}
      />
      <FormDatePicker
        id="blockRequestedDeliveryDate"
        isDisabled={isDisabled}
        label="Block Requested Delivery Date"
        name="blockRequestedDeliveryDate"
      />
      <FormSelect
        id="timing"
        isDisabled={isDisabled}
        label="Order Timing"
        name="timing"
        options={ORDER_TIMING_OPTIONS}
      />
      <FormDatePicker
        id="confirmedDeliveryDate"
        isDisabled={isDisabled}
        label="Confirmed Delivery Date"
        name="confirmedDeliveryDate"
      />
      <FormSelect
        id="reason"
        isDisabled={isDisabled}
        label="Reason for Order"
        name="reason"
        options={ORDER_REASON_OPTIONS}
      />
      <FormCurrencyField
        id="subtotal"
        isDisabled={isDisabled}
        label="Order Subtotal"
        name="subtotal"
      />
      <FormTextField
        id="number"
        isDisabled={isDisabled}
        label="Order Number"
        name="number"
      />
      <FormCurrencyField
        id="shippingTotal"
        isDisabled={isDisabled}
        label="Shipping Cost"
        name="shippingTotal"
      />
      <FormTextField
        id="webOrderNumber"
        isDisabled={isDisabled}
        label="Web Order Number"
        name="webOrderNumber"
      />
      <FormCurrencyField
        id="taxTotal"
        isDisabled={isDisabled}
        label="Taxes Cost"
        name="taxTotal"
      />
      <FormCurrencyField
        allowNegative
        id="refundAmount"
        isDisabled={isDisabled}
        label="Refund Amount"
        name="refundAmount"
      />
      <FormSelect
        id="requestedShippingMethod"
        isDisabled={isDisabled}
        label="Block Requested Shipping Method"
        name="requestedShippingMethod"
        options={ORDER_SHIPPING_METHOD_OPTIONS}
      />
      <FormTextField
        id="comments"
        isDisabled={isDisabled}
        isMultiline
        label="Block Comments"
        name="comments"
        rows={8}
      />
      <FormTextField
        id="vendorComments"
        isDisabled={isDisabled}
        isMultiline
        label="Vendor Comments"
        name="vendorComments"
        rows={8}
      />
    </Wrapper>
  </Form>
);
