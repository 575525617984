import { gql } from '@apollo/client';

export const GET_PROJECT_ORDER = gql`
  query GetProjectOrder($id: ID!) {
    order(id: $id) {
      id
      number
      webOrderNumber
      status
      timing
      dateOrdered
      initialRequestedDeliveryDate
      blockRequestedDeliveryDate
      confirmedDeliveryDate
      reason
      requestedShippingMethod
      requestedDeliverTo
      comments
      vendorComments
      subtotal
      taxTotal
      shippingTotal
      refundAmount
      items {
        id
        amount
        boxCount
        status
        skuVendor {
          id
          vendorSku
          unitCost
          vendor {
            id
            name
          }
        }
        projectMaterial {
          id
          name
          amount
          status
          category {
            id
            name
          }
          sku {
            id
            sku
            width
            depth
            height
            length
            thickness
            unit {
              type
            }
            color {
              description
            }
            finish {
              description
            }
            pattern {
              description
            }
            product {
              brand {
                name
                line {
                  name
                }
              }
            }
          }

          pricingEngineSku {
            id
            unitCost
          }
        }
        revisionActionItem {
          id
          revision {
            id
          }
        }
      }
      shipments {
        id
        deliverTo
        estimatedDeliveryDate
        actualDeliveryDate
        number
        shippingMethod
        shippingTax
        shippingCost
        status
        trackingLink
        trackingNumber
        type
        items {
          id
          totalUnits
          unitsReceived
          orderItem {
            id
            amount
            boxCount
            skuVendor {
              id
              vendorSku
            }
            projectMaterial {
              id
              name
              category {
                id
                name
              }
              sku {
                id
                sku
                width
                depth
                height
                length
                thickness
                unit {
                  type
                }
                color {
                  description
                }
                finish {
                  description
                }
                pattern {
                  description
                }
              }
            }
          }
        }
      }
    }
  }
`;
