import { object, string } from 'yup';

export const validationSchema = () =>
  object().shape({
    exceptions: string().max(
      MAX_EXCEPTIONS_LENGTH,
      `Max ${MAX_EXCEPTIONS_LENGTH} digits`,
    ),
  });

export const MAX_EXCEPTIONS_LENGTH = 265;
