import Collapse from '@material-ui/core/Collapse';

import { SelectType } from 'common/types/select';

import { STOCK_STATUS_OPTIONS } from 'common/consts/stockStatus';

import { Button } from 'common/components/Button/Button';
import { Select } from 'common/components/Form/Select/Select';

import { PROJECT_MATERIAL_STATUS_FILTER_OPTIONS } from 'Project/Procurement/common/consts/material.consts';

import { useMaterialsHeader } from 'Project/Procurement/Materials/MaterialsHeader/materialsHeader.hooks';

import {
  ActionWrapper,
  FilterCount,
  Filters,
  Header,
} from 'Project/Procurement/Materials/MaterialsHeader/materialsHeader.styles';

type Props = {
  createOrder: () => void;
  editLeadTime: () => void;
  hasMaterialsSelected: boolean;
  pricingEngines: SelectType[];
  subcategories: SelectType[];
  vendors: SelectType[];
};

export const MaterialsHeader = ({
  createOrder,
  editLeadTime,
  hasMaterialsSelected,
  pricingEngines,
  subcategories,
  vendors,
}: Props) => {
  const mappedPricingEngines = pricingEngines.map(({ label }) => label);

  const {
    filtersCount,
    handleRoomFilterChange,
    handleStatusFilterChange,
    handleStockStatusFilterChange,
    handleSubcategoryFilterChange,
    handleVendorFilterChange,
    isFiltersOpen,
    roomName,
    status,
    stockStatus,
    subcategoryId,
    toggleFilters,
    vendorId,
  } = useMaterialsHeader();

  return (
    <Header>
      <ActionWrapper>
        <Button onClick={toggleFilters} small>
          Filter
          {!!filtersCount && <FilterCount> {filtersCount}</FilterCount>}
        </Button>

        {hasMaterialsSelected && (
          <>
            <Button
              color="secondary"
              onClick={editLeadTime}
              small
              variant="contained"
            >
              Bulk Update
            </Button>
            <Button onClick={createOrder} small>
              New Order
            </Button>
          </>
        )}
      </ActionWrapper>

      <Collapse in={isFiltersOpen}>
        <Filters>
          <Select
            disablePortal={false}
            isClearable
            label="Vendor"
            onChange={handleVendorFilterChange}
            options={vendors}
            placeholder="Vendor"
            value={vendorId || ''}
          />
          <Select
            disablePortal={false}
            isClearable
            label="Stock Status"
            onChange={handleStockStatusFilterChange}
            options={STOCK_STATUS_OPTIONS}
            placeholder="Stock Status"
            value={stockStatus || ''}
          />
          <Select
            disablePortal={false}
            isClearable
            label="Subcategory"
            onChange={handleSubcategoryFilterChange}
            options={subcategories}
            placeholder="Subcategory"
            value={subcategoryId || ''}
          />
          <Select
            disablePortal={false}
            isClearable
            label="Room"
            onChange={handleRoomFilterChange}
            options={mappedPricingEngines}
            placeholder="Room"
            value={roomName || ''}
          />
          <Select
            disablePortal={false}
            isClearable
            label="Status"
            onChange={handleStatusFilterChange}
            options={PROJECT_MATERIAL_STATUS_FILTER_OPTIONS}
            placeholder="Status"
            value={status || ''}
          />
        </Filters>
      </Collapse>
    </Header>
  );
};
