import Switch from '@material-ui/core/Switch';

import {
  FlagLabel,
  Wrapper,
} from 'ProjectProfile/common/components/FlagToggle/flagToggle.styles';

type Props = {
  disabled: boolean;
  isOn: boolean;
  onToggle?: () => void;
};
export const FlagToggle = ({ disabled, isOn, onToggle }: Props) => (
  <Wrapper>
    <Switch checked={isOn} color="primary" onChange={onToggle} />
    <FlagLabel>Flag as important information</FlagLabel>
  </Wrapper>
);
