import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { THEME } from 'common/consts/theme';

export const TableRow = styled.tr``;

export const TableCell = styled.td`
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.color.lightGray};
`;

export const ExpandableTableCell = styled.td`
  padding: 0;
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

export const IconButton = styled.button`
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.color.gray};
  cursor: pointer;
  height: 32px;
  outline: 0;
`;

export const SizeWrapper = styled.p`
  white-space: nowrap;
`;

export const linkStyles = css`
  color: ${THEME.color.mariner};
  font: 400 14px/24px ${THEME.font.inter};
  word-break: break-word;

  @media print {
    color: ${THEME.color.black};
  }

  &:hover {
    text-decoration: underline;
  }
`;
