const getMaterialsCatalogBulkUploadAddProductsPath = () =>
  `/materials-catalog/bulk-upload/add-products`;

const getMaterialsCatalogBulkUploadEditProductsPath = () =>
  `/materials-catalog/bulk-upload/edit-products`;

const getMaterialsCatalogBulkUploadPath = () =>
  `/materials-catalog/bulk-upload`;

const getMaterialsCatalogProductPath = (productId = ':productId') =>
  `/materials-catalog/product/${productId}`;

const getMaterialsCatalogProductSkuPath = (
  productId = ':productId',
  skuId: string,
) => `/materials-catalog/product/${productId}?skuId=${skuId}`;

export const MATERIALS_CATALOG_PATHS = {
  getMaterialsCatalogBulkUploadAddProductsPath,
  getMaterialsCatalogBulkUploadEditProductsPath,
  getMaterialsCatalogBulkUploadPath,
  getMaterialsCatalogProductPath,
  getMaterialsCatalogProductSkuPath,
};
