import { ACTION_TYPES, Action } from './copyToClipboardButton.actions';

export type State = {
  copyText: string;
  error: boolean;
  isPopperOpen: boolean;
  loading: boolean;
};

export const initialState: State = {
  copyText: '',
  error: false,
  isPopperOpen: false,
  loading: false,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ACTION_TYPES.fetchActionInit:
      return {
        ...state,
        copyText: '',
        loading: true,
      };
    case ACTION_TYPES.fetchActionSuccess:
      return {
        ...state,
        copyText: action.payload,
        loading: false,
      };
    case ACTION_TYPES.fetchActionError:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case ACTION_TYPES.setPopperOpen:
      return {
        ...state,
        isPopperOpen: action.payload,
      };
  }
};
