import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

export const identifiers = [
  PropertyGroupIdentifier.BUILDING_AGE,
  PropertyGroupIdentifier.BUILDING_INFORMATION_NOTES,
  PropertyGroupIdentifier.BUILDING_TYPE,
  PropertyGroupIdentifier.DESIGN_OPS_NOTES,
  PropertyGroupIdentifier.INSURANCE,
  PropertyGroupIdentifier.ALTERATION_AGREEMENT_HIGHLIGHTS,
  PropertyGroupIdentifier.ALTERATION_AGREEMENT_REQUIRED,
];

export const openQuestionsIdentifiers = [
  PropertyGroupIdentifier.ALTERATION_AGREEMENT_HIGHLIGHTS,
  PropertyGroupIdentifier.BUILDING_INFORMATION_NOTES,
  PropertyGroupIdentifier.DESIGN_OPS_NOTES,
];
