import { gql } from '@apollo/client';

export const GET_PROJECT_MARKET = gql`
  query ProjectMarketGet($id: ID!) {
    project(id: $id) {
      id
      serviceableStatus
      market {
        code
      }
    }
  }
`;
