import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { THEME } from 'common/consts/theme';

export const Trigger = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.color.darkGray};
  cursor: pointer;
  padding: 0;
  text-align: left;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const expandIconStyles = css`
  cursor: pointer;
  height: 24px;
  width: 24px;
`;
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    &:hover {
      svg {
        fill: ${({ theme }) => theme.color.black};
        transform: scale(1.1);
      }
    }
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const titleStyles = css`
  color: ${THEME.color.black};
  font: normal 24px/32px ${THEME.font.inter};
  text-transform: uppercase;
`;
