import { DeliveryBatch } from 'common/apollo/models/deliveryBatch';
import { OrderItem } from 'common/apollo/models/orderItem';
import { useToggle } from 'common/hooks/useToggle';

const handleCalculateProgress = (units: number, total: number) =>
  Math.min((units / total) * 100, 100);

const isString = (value: string | null): value is string => !!value;

export const useOrderItemRow = (
  orderItem: OrderItem,
  batches: DeliveryBatch[],
) => {
  const { isOn, toggleOff, toggleOn } = useToggle();
  const { amount: totalUnits } = orderItem;

  const totalUnitsInBatches = batches.reduce(
    (previousValue, currentValue) => previousValue + currentValue.units,
    0,
  );
  const unitsRemaining = totalUnits - totalUnitsInBatches;

  const currentDate = new Date();
  const unitsReceived = batches
    .filter(
      (batch) =>
        batch.receivedAt !== null && new Date(batch.receivedAt) < currentDate,
    )
    .reduce(
      (previousValue, currentValue) => previousValue + currentValue.units,
      0,
    );
  const unitsReceivedLabel = `${unitsReceived} of ${totalUnits}`;
  const unitsReceivedValue = handleCalculateProgress(unitsReceived, totalUnits);

  const unitsShipped = batches
    .filter(
      (batch) =>
        batch.shippedAt !== null && new Date(batch.shippedAt) < currentDate,
    )
    .reduce(
      (previousValue, currentValue) => previousValue + currentValue.units,
      0,
    );
  const unitsShippedLabel = `${unitsShipped} of ${totalUnits}`;
  const unitsShippedValue = handleCalculateProgress(unitsShipped, totalUnits);

  const handleAddBatch = (
    id: string,
    isOpen: boolean,
    onCollapseToggle: (orderItemString: string) => void,
  ) => {
    if (!isOpen) {
      onCollapseToggle(id);
    }
    toggleOn();
  };

  const shipments = batches[0].shipments || [];
  const shipmentEtas = shipments
    .map((shipment) => shipment.estimatedDeliveryDate)
    .filter(isString)
    .map((estimatedDeliveryDate) => new Date(estimatedDeliveryDate))
    .filter(
      (date, index, self) =>
        self.findIndex(
          (existingDate) => existingDate.getTime() === date.getTime(),
        ) === index,
    )
    .sort((a, b) => a.getTime() - b.getTime());

  return {
    handleAddBatch,
    isOn,
    shipmentEtas,
    toggleOff,
    toggleOn,
    totalUnits,
    unitsReceivedLabel,
    unitsReceivedValue,
    unitsRemaining,
    unitsShippedLabel,
    unitsShippedValue,
  };
};
