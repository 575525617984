import {
  Button,
  Field,
  FieldLabel,
  fieldStyles,
  Footer,
  Form,
  FormRow,
  TextField,
} from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeSet/materialsLongestLeadTimeSet.styles';
import { FormDatePicker as DatePicker } from 'common/components/Form/DatePicker/FormDatePicker';
import { TextArea } from 'MaterialsLongestLeadTime/common/components/TextArea/TextArea';
import { useMaterialsLongestLeadTimeSetForm } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeSet/MaterialsLongestLeadTimeSetForm/materialsLongestLeadTimeSetForm.hooks';

export const MaterialsLongestLeadTimeSetForm = () => {
  const { disableSubmitButton, handleSubmit } =
    useMaterialsLongestLeadTimeSetForm();

  return (
    <Form onSubmit={handleSubmit}>
      <FormRow>
        <Field>
          <FieldLabel>Set Material Longest Lead Time (Date)</FieldLabel>
          <DatePicker
            css={fieldStyles}
            id="date"
            name="date"
            placeholder="MM/DD/YYYY"
          />
        </Field>
        <Field>
          <FieldLabel>Set Material Longest Lead Time (Days)</FieldLabel>
          <TextField
            id="days"
            isFullWidth
            name="days"
            placeholder="Number of days"
            type="number"
          />
        </Field>
      </FormRow>
      <Field>
        <FieldLabel>Additional Notes (Optional)</FieldLabel>
        <TextArea
          id="notes"
          name="notes"
          placeholder="Add notes here"
          rows={10}
        />
      </Field>
      <Footer>
        <Button
          color="primary"
          disabled={disableSubmitButton}
          type="submit"
          variant="contained"
        >
          Set Longest Lead Time
        </Button>
      </Footer>
    </Form>
  );
};
