import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mq } from 'common/utils/mediaQueries';

export const Content = styled.div`
  margin: 0 auto;
  max-width: 480px;
  padding-bottom: 24px;
`;

export const Footer = styled.div`
  column-gap: 16px;
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const Header = styled.div`
  align-items: center;
  display: grid;
  font: 500 20px/28px ${({ theme }) => theme.font.inter};
  grid-template-columns: 1fr 20px;
  margin-bottom: 8px;
  position: relative;
  padding-bottom: 24px;
`;

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.white};
  height: 100%;
  max-width: 100vw;
  padding: 32px;

  ${mq.small(css`
    min-width: 500px;
  `)};
`;
