import { useField } from 'formik';

import { useHasFormError } from 'common/hooks/useHasFormError';
import { shouldValidateField } from 'common/utils/validations';

import { TextField } from 'common/components/TextField/TextField';

export type Props = {
  id: string;
  isDisabled: boolean;
  isRequired: boolean;
  label: string;
  name: string;
  placeholder?: string;
};

export const Number = ({
  id,
  isDisabled,
  isRequired,
  label,
  name,
  placeholder,
}: Props) => {
  const [field] = useField({
    name,
    validate: shouldValidateField(isRequired),
  });

  const error = useHasFormError(name);

  return (
    <TextField
      {...field}
      disabled={isDisabled}
      extraLabel={!isRequired && 'Optional'}
      fluid
      hasError={!!error}
      helperText={error}
      id={id}
      label={label}
      placeholder={placeholder}
      required={isRequired}
      type="number"
    />
  );
};
