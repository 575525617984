import { useParams } from 'react-router-dom';

import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

import { QueryParams } from 'ProjectProfile/common/types/queryParams';
import { useProjectPlanRoomsQuestionsUpdate } from 'ProjectProfile/common/hooks/useProjectPlanRoomsQuestionsUpdate';

import { DesignerNotesEditFormResponse } from './designerNotesEdit.types';
import { mapFormValuesToResponses } from './designerNotesEdit.utils';
import { useDesignerNotes } from '../designerNotes.hooks';

export const useDesignerNotesEdit = () => {
  const { projectId } = useParams<QueryParams>();
  const { handleSubmit } = useProjectPlanRoomsQuestionsUpdate();
  const { isLoading, roomsQuestions } = useDesignerNotes([
    PropertyGroupIdentifier.DESIGNER_NOTES,
  ]);

  const formValues = roomsQuestions.map(({ responseText, room }) => ({
    pricingEngineId: room?.id || '',
    room: room?.name || undefined,
    value: responseText,
  }));

  const submit = (
    formResponse: DesignerNotesEditFormResponse,
    close: () => void,
  ) => {
    const responses = mapFormValuesToResponses(formResponse);

    handleSubmit({
      identifiers: [PropertyGroupIdentifier.DESIGNER_NOTES],
      onSuccessCallback: close,
      rooms: responses,
    });
  };

  return {
    initialValues: {
      designerNotesEdit: formValues,
    },
    isLoading,
    projectId,
    roomsQuestions,
    submit,
  };
};
