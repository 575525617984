import { useParams } from 'react-router-dom';

import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';

import { UPP_EDIT_CLICKED } from 'common/consts/segmentEvents';
import { useAuth } from 'common/hooks/AuthenticationProvider/authenticationProvider.hooks';
import { sendEvent } from 'common/utils/segment';

import { QueryParams } from 'ProjectProfile/common/types/queryParams';

type Props = {
  disableSegment?: boolean;
  onClick: () => void;
  segmentEventProps?: {
    [key: string]: string;
  };
};

export const EditButton = ({
  disableSegment = false,
  onClick,
  segmentEventProps,
}: Props) => {
  const { currentUser } = useAuth();
  const { projectId } = useParams<QueryParams>();

  const handleEditClick = () => {
    if (!disableSegment) {
      sendEvent(UPP_EDIT_CLICKED, {
        adminUserId: currentUser?.id,
        renovationPlanId: projectId,
        ...(segmentEventProps && { segmentEventProps }),
      });
    }

    onClick();
  };

  return (
    <IconButton onClick={handleEditClick}>
      <CreateIcon />
    </IconButton>
  );
};
