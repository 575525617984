import { Button } from 'common/components/Button/Button';

import { dateToUSFormat } from 'common/utils/date';
import { pluralize } from 'common/utils/pluralize';

import { SkuUnit } from 'common/apollo/enums/SkuUnit';

import { Badge } from 'common/components/Badge/Badge';

import { OrderShipment } from 'Project/Procurement/common/types/orderShipment';

import {
  PROJECT_SHIPMENT_DELIVER_TO_DICTIONARY,
  PROJECT_SHIPMENT_SHIPPING_METHOD_DICTIONARY,
  PROJECT_SHIPMENT_STATUS_COLOR_VARIANT,
  PROJECT_SHIPMENT_STATUS_DICTIONARY,
  PROJECT_SHIPMENT_TYPE_DICTIONARY,
} from 'Project/Procurement/common/consts/shipment.consts';

import {
  Section,
  SectionAction,
  SectionContent,
  SectionHeader,
} from 'Project/Procurement/common/components/Layout/Order';

import {
  ContentWrapper,
  Label,
  SectionHeaderContent,
  SizeWrapper,
  Table,
  Value,
} from 'Project/Procurement/EditOrder/OrderShipments/Shipment/shipment.styles';

type Props = {
  onEdit: () => void;
  shipment: OrderShipment;
};

export const Shipment = ({
  onEdit,
  shipment: {
    actualDeliveryDate,
    deliverTo,
    estimatedDeliveryDate,
    items,
    number,
    shippingMethod,
    status,
    trackingNumber,
    type,
  },
}: Props) => (
  <Section>
    <SectionHeader>
      <SectionHeaderContent>
        <Badge variant={PROJECT_SHIPMENT_STATUS_COLOR_VARIANT[status]}>
          {PROJECT_SHIPMENT_STATUS_DICTIONARY[status]}
        </Badge>

        <ContentWrapper>
          <Label>Shipment Type</Label>
          <Value>{PROJECT_SHIPMENT_TYPE_DICTIONARY[type]}</Value>
        </ContentWrapper>

        <ContentWrapper>
          <Label>Shipment #</Label>
          <Value>{number}</Value>
        </ContentWrapper>

        <ContentWrapper>
          <Label>Tracking #</Label>
          <Value>{trackingNumber}</Value>
        </ContentWrapper>

        <ContentWrapper>
          <Label>Est Delivery Date</Label>
          <Value>
            {estimatedDeliveryDate && dateToUSFormat(estimatedDeliveryDate)}
          </Value>
        </ContentWrapper>

        <ContentWrapper>
          <Label>Actual Delivery Date</Label>
          <Value>
            {actualDeliveryDate && dateToUSFormat(actualDeliveryDate)}
          </Value>
        </ContentWrapper>

        <ContentWrapper>
          <Label>Shipment Method</Label>
          <Value>
            {PROJECT_SHIPMENT_SHIPPING_METHOD_DICTIONARY[shippingMethod]}
          </Value>
        </ContentWrapper>

        <ContentWrapper>
          <Label>Destination</Label>
          <Value>{PROJECT_SHIPMENT_DELIVER_TO_DICTIONARY[deliverTo]}</Value>
        </ContentWrapper>
      </SectionHeaderContent>

      <SectionAction>
        <Button onClick={onEdit} small>
          Edit
        </Button>
      </SectionAction>
    </SectionHeader>

    <SectionContent>
      <Table>
        <thead>
          <tr>
            <th>Subcategory</th>
            <th>Product</th>
            <th>Color</th>
            <th>Finish</th>
            <th>Size</th>
            <th>Pattern</th>
            <th>SKU ID</th>
            <th>Vendor SKU ID</th>
            <th>Total Units</th>
            <th>Units Received</th>
          </tr>
        </thead>

        <tbody>
          {items.map(
            ({
              id,
              orderItem: {
                color,
                finish,
                name,
                pattern,
                size,
                skuId,
                subcategory,
                unitType,
                vendorSkuId,
              },
              totalUnits,
              unitsReceived,
            }) => (
              <tr key={id}>
                <td>{subcategory}</td>
                <td>{name}</td>
                <td>{color}</td>
                <td>{finish}</td>
                <td>
                  <SizeWrapper>{size}</SizeWrapper>
                </td>
                <td>{pattern}</td>
                <td>{skuId}</td>
                <td>{vendorSkuId}</td>
                <td>
                  {`${totalUnits} ${pluralize(
                    unitType === SkuUnit.SQ_FOOT ? 'Box' : 'Unit',
                    totalUnits,
                  )}`}
                </td>
                <td>{unitsReceived}</td>
              </tr>
            ),
          )}
        </tbody>
      </Table>
    </SectionContent>
  </Section>
);
