import { TypePolicy } from '@apollo/client';

export const Sku: TypePolicy = {
  fields: {
    vendors: {
      merge: (_, incoming, { readField }) => {
        const vendors = [...incoming].sort((a, b) =>
          (readField('isDefault', a) ?? false) >
          (readField('isDefault', b) ?? false)
            ? -1
            : 1,
        );

        return vendors;
      },
    },
  },
};
