import { OrderReason } from 'common/apollo/enums/OrderReason';
import { ProjectMaterialStatus } from 'common/apollo/enums/ProjectMaterialStatus';
import { Order } from 'common/apollo/models/order';
import { OrderItem } from 'common/apollo/models/orderItem';
import { Shipment } from 'common/apollo/models/shipment';
import { ShipmentItem } from 'common/apollo/models/shipmentItem';

import { getSkuSizeWithFraction } from 'common/utils/skuSize';

import { OrderShipment } from 'Project/Procurement/common/types/orderShipment';

import { initialValues } from 'Project/Procurement/EditOrder/editOrder.consts';

export const getInitialValues = (order?: Order) =>
  order
    ? {
        blockRequestedDeliveryDate: order.blockRequestedDeliveryDate,
        comments: order.comments || '',
        confirmedDeliveryDate: order.confirmedDeliveryDate || null,
        dateOrdered: order.dateOrdered,
        initialRequestedDeliveryDate: order.initialRequestedDeliveryDate,
        number: order.number,
        reason: order.reason || OrderReason.STANDARD,
        refundAmount: order.refundAmount || 0,
        requestedDeliverTo: order.requestedDeliverTo,
        requestedShippingMethod: order.requestedShippingMethod,
        shippingTotal: order.shippingTotal || 0,
        status: order.status,
        subtotal: order.subtotal || 0,
        taxTotal: order.taxTotal || 0,
        timing: order.timing,
        vendorComments: order.vendorComments || '',
        vendorId: order.items[0].skuVendor.vendor.id,
        webOrderNumber: order.webOrderNumber,
      }
    : initialValues;

const normalizeOrderItemData = ({
  amount,
  boxCount,
  id,
  projectMaterial,
  revisionActionItem,
  skuVendor,
  status,
}: OrderItem) => ({
  boxCount,
  brandLineName: projectMaterial?.sku.product.brand?.line?.name || '',
  brandName: projectMaterial?.sku.product.brand?.name || '',
  color: projectMaterial?.sku.color.description || '',
  finish: projectMaterial?.sku.finish.description || '',
  generated: projectMaterial?.generated || false,
  id,
  name: projectMaterial?.name || '',
  pattern: projectMaterial?.sku.pattern.description || '',
  revisionActionItem,
  size: projectMaterial ? getSkuSizeWithFraction(projectMaterial?.sku) : '',
  skuId: projectMaterial?.sku.sku || '',
  status,
  subcategory: projectMaterial?.category.name || '',
  subcategoryId: projectMaterial?.category.id || '',
  totalUnits: amount,
  unitType: projectMaterial?.sku.unit?.type,
  unitsReceived: 1,
  unitsShipped: 1,
  vendorSkuId: skuVendor.vendorSku || '',
});

export const normalizeOrderMaterialsData = (items: OrderItem[] = []) =>
  items.map(normalizeOrderItemData);

const normalizeShipmentItemData = ({
  id,
  orderItem: { amount, boxCount, id: orderItemId, projectMaterial, skuVendor },
  totalUnits,
  unitsReceived,
}: ShipmentItem) => ({
  id,
  orderItem: {
    boxCount,
    color: projectMaterial?.sku.color.description || '',
    finish: projectMaterial?.sku.finish.description || '',
    id: orderItemId,
    name: projectMaterial?.name || '',
    pattern: projectMaterial?.sku.pattern.description || '',
    size: projectMaterial ? getSkuSizeWithFraction(projectMaterial?.sku) : '',
    skuId: projectMaterial?.sku.sku || '',
    subcategory: projectMaterial?.category.name || '',
    subcategoryId: projectMaterial?.category.id || '',
    unitType: projectMaterial?.sku.unit?.type,
    unitsAvailable: amount,
    vendorSkuId: skuVendor.vendorSku || '',
  },
  totalUnits,
  unitsReceived,
});

export const normalizeOrderShipmentsData = (
  shipments: Shipment[] = [],
): OrderShipment[] =>
  shipments.map(
    ({
      actualDeliveryDate,
      deliverTo,
      estimatedDeliveryDate,
      id,
      items,
      number,
      shippingCost,
      shippingMethod,
      shippingTax,
      status,
      trackingLink,
      trackingNumber,
      type,
    }) => ({
      actualDeliveryDate,
      deliverTo,
      estimatedDeliveryDate,
      id,
      items: items.map(normalizeShipmentItemData),
      number,
      shippingCost,
      shippingMethod,
      shippingTax,
      status,
      trackingLink,
      trackingNumber,
      type,
    }),
  );

export const calculateExpectedMaterialsCost = (items: OrderItem[] = []) =>
  items.reduce((acc, { boxCount, projectMaterial, skuVendor }) => {
    if (!projectMaterial) return acc;

    const shouldExcludeMaterial = [
      ProjectMaterialStatus.OUT_OF_STOCK,
      ProjectMaterialStatus.TBD,
      ProjectMaterialStatus.VOID,
    ].includes(projectMaterial.status);

    if (shouldExcludeMaterial) return acc;

    const hasPricingEngineSku = !!projectMaterial.pricingEngineSku;

    const unitCost = hasPricingEngineSku
      ? projectMaterial.pricingEngineSku!.unitCost
      : skuVendor.unitCost;

    const total = Math.floor(boxCount * unitCost);

    return acc + total;
  }, 0);
