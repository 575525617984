import { useState, useCallback, MouseEvent } from 'react';

export const useMenuButton = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = () => setMenuOpen(true);
  const handleMenuClose = useCallback(() => setMenuOpen(false), []);

  const handleIconClick = useCallback(
    (e: MouseEvent<SVGSVGElement, MouseEvent>) => {
      e.stopPropagation();

      handleMenuOpen();
    },
    [],
  );

  return {
    handleIconClick,
    handleMenuClose,
    isMenuOpen,
  };
};
