import { useFlags } from 'launchdarkly-react-client-sdk';

import { FeatureFlags } from 'common/enums/FeatureFlags';

export const useStatusBreakGround = () => {
  const flags = useFlags();
  const removeBreakGroundSidebar =
    flags[FeatureFlags.ADMIN_SIDEBAR_REMOVE_BREAKGROUND];
  return removeBreakGroundSidebar;
};
