import styled from '@emotion/styled';

import CircularProgress from '@material-ui/core/CircularProgress';

import checkIcon from 'common/assets/icons/checkmark.svg';

export const Wrapper = styled.div`
  position: relative;
`;

export const Label = styled.label`
  ${({ theme }) => theme.typography.header6};
  color: ${({ theme }) => theme.color.gray11};
  text-transform: capitalize;
`;

export const ListWrapper = styled.ul`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 0 0 4px 4px;
  border-top: 0;
  border: 1px solid ${({ theme }) => theme.color.orange6};
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 200px;
  overflow-y: auto;
  padding: 8px 0;
  position: absolute;
  top: 50px;
  width: 100%;
`;

export const ListItem = styled.li<{ isSelected?: boolean }>`
  color: ${({ theme }) => theme.color.black};
  cursor: pointer;
  font: 400 14px/20px ${({ theme }) => theme.font.inter};
  padding: 10px 30px 10px 10px;
  position: relative;

  &:after {
    background: url(${checkIcon}) 100% no-repeat;
    content: '';
    display: ${({ isSelected }) => (isSelected ? 'block' : 'none')};
    height: 18px;
    position: absolute;
    right: 12px;
    top: 10px;
    width: 18px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.orange2};
  }
`;

export const NoResults = styled.li`
  color: ${({ theme }) => theme.color.black};
  font: 400 14px/20px ${({ theme }) => theme.font.inter};
  padding: 10px 30px 10px 10px;
  position: relative;
`;

export const Spinner = styled(CircularProgress)`
  display: block;
  margin: 0 auto;
`;

export const IconButton = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  height: 12px;
  margin: 0;
  padding: 0;

  path {
    fill: ${({ theme }) => theme.color.black};
  }
`;
