import { pluralize } from 'common/utils/pluralize';
import { dateTimeToUSFormat } from 'common/utils/date';

import { Material } from 'Project/Procurement/Materials/materials.types';

import { Footer } from 'Project/Procurement/Materials/MaterialsFooter/materialsFooter.styles';

type Props = {
  materials: Material[];
  materialsGeneratedAtDate?: Date | null;
  proposalName?: string;
};

export const MaterialsFooter = ({
  materials,
  materialsGeneratedAtDate,
  proposalName,
}: Props) => {
  const hasGeneratedMaterials = materialsGeneratedAtDate && proposalName;

  return (
    <Footer>
      <p>
        {materials.length} {pluralize('SKU', materials.length)}
      </p>

      {hasGeneratedMaterials && (
        <p>
          Generated from <strong>{proposalName}</strong> on,{' '}
          <strong>{dateTimeToUSFormat(materialsGeneratedAtDate!)}</strong>
        </p>
      )}
    </Footer>
  );
};
