import { gql } from '@apollo/client';

export const UPDATE_PROPOSAL_NAME = gql`
  mutation ProposalNameUpdate($input: ProposalNameUpdateInput!) {
    proposalNameUpdate(input: $input) {
      id
      name
    }
  }
`;
