import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Button } from '@material-ui/core';

import { rgba } from 'common/utils/colors';

import { ArrowType } from './LayoutSidebarToggle.types';

type WithVisibility = {
  isOpen?: boolean;
};

type Props = {
  arrow: ArrowType;
};

export const ToggleWrapper = styled.div<WithVisibility>`
  border-bottom: 1px solid ${({ theme: { color } }) => rgba(color.black, 0.1)};
  height: 51px;
  margin: 0px auto;
  padding: 0;
  text-align: left;
  transition: opacity 0.3s;
  width: 270px;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      opacity: 0;
      pointer-events: none;
    `};
`;

export const ToggleButton = styled(Button)<Props>`
  height: 100%;
  justify-content: left;
  margin: 0;
  padding: 0;
  text-transform: none;
  width: 100%;
  padding-left: ${({ arrow }) => (arrow === ArrowType.LEFT ? '20px' : '28px')};
`;
