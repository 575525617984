import styled from '@emotion/styled';

import { css } from '@emotion/react';

import { mq } from 'common/utils/mediaQueries';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const WrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mq.medium(css`
    flex-direction: row;
  `)};
`;

export const LabelWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
`;

export const Label = styled.p`
  ${({ theme }) => theme.typography.header5};
  color: ${({ theme }) => theme.color.gray12};
`;

export const ControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const ControlLabel = styled.label`
  ${({ theme }) => theme.typography.header6};
  color: ${({ theme }) => theme.color.gray11};
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.red11};
`;

export const ExtraLabel = styled.span`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.gray11};
  margin-left: auto;
`;
