import { useQuery } from '@apollo/client';

import { GetProjectTypeRequest } from 'common/apollo/requests/getProjectTypeRequest';
import { GetProjectTypeResponse } from 'common/apollo/responses/getProjectTypeResponse';

import { GET_PROJECT_TYPE } from 'common/apollo/queries/getProjectType.graphql';

export const useGetProjectType = (projectId?: string) => {
  const { data, loading } = useQuery<
    GetProjectTypeResponse,
    GetProjectTypeRequest
  >(GET_PROJECT_TYPE, {
    skip: !Number(projectId),
    variables: { id: projectId! },
  });

  const projectType = data?.project?.projectType;
  const isContractorChoice = data?.project?.isContractorChoice;
  const projectFlags = data?.project?.projectFlags;
  const hasPlanner = !!data?.project?.plannerId;

  return {
    hasPlanner,
    isContractorChoice,
    loading,
    projectFlags,
    projectType,
  };
};
