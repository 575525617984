/* eslint-disable jsx-a11y/control-has-associated-label */
import { FormDatePicker } from 'common/components/Form/DatePicker/FormDatePicker';
import { FormSelect } from 'common/components/Form/Select/FormSelect';
import { FormTextField } from 'common/components/Form/TextField/FormTextField';
import { TextFieldSize } from 'common/enums/TextField';

import { STOCK_STATUS_OPTIONS } from 'common/consts/stockStatus';

import { useEditMaterialsLeadTimeRow } from './editMaterialsLeadTimeRow.hooks';

type Props = { index: number; productName: string; vendorName: string };

export const EditMaterialsLeadTimeRow = ({
  index,
  productName,
  vendorName,
}: Props) => {
  const { handleStockStatusUpdate, isBackInStockDisabled } =
    useEditMaterialsLeadTimeRow(index);

  return (
    <tr>
      <td>{productName}</td>
      <td>{vendorName}</td>
      <td>
        <FormSelect
          name={`materials[${index}].stockStatus`}
          onChange={handleStockStatusUpdate}
          options={STOCK_STATUS_OPTIONS}
          placeholder="N/A"
          size={TextFieldSize.SMALL}
        />
      </td>
      <td>
        <FormTextField
          endAdornment="weeks"
          inputProps={{
            min: 1,
            step: 1,
          }}
          name={`materials[${index}].minLeadTimeWeeks`}
          size={TextFieldSize.SMALL}
          type="number"
        />
      </td>
      <td>
        <FormTextField
          endAdornment="weeks"
          inputProps={{
            min: 1,
            step: 1,
          }}
          name={`materials[${index}].maxLeadTimeWeeks`}
          size={TextFieldSize.SMALL}
          type="number"
        />
      </td>
      <td>
        <FormDatePicker
          isDisabled={isBackInStockDisabled}
          name={`materials[${index}].backInStockDate`}
          size={TextFieldSize.SMALL}
        />
      </td>
    </tr>
  );
};
