import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.red11};
`;

export const ControlLabel = styled.label`
  ${({ theme }) => css`
    ${theme.typography.body2};
    align-items: center;
    background-color: ${theme.color.orange1};
    border-radius: 4px;
    border: 1px solid ${theme.color.orange5};
    color: ${theme.color.black};
    gap: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 16px;

    &:has(input[disabled]) {
      background-color: ${theme.color.gray1};
      border-color: ${theme.color.gray5};
      color: ${theme.color.gray11};
      cursor: not-allowed;
    }
  `}
`;

export const Control = styled.input`
  ${({ theme }) => css`
    background-color: ${theme.color.white};
    border-radius: 4px;
    border: 1px solid ${theme.color.gray6};
    cursor: pointer;
    height: 20px;
    margin: 0;
    min-width: 20px;
    outline: 4px solid transparent;
    transition: all 0.125s ease;

    &[disabled] {
      background-color: transparent;
      border-color: ${theme.color.gray8};
      cursor: not-allowed;
      outline: 0;
    }

    &:checked {
      background: ${theme.color.orange8};
      border-color: ${theme.color.orange8};
    }

    &:checked::before {
      content: '';
      display: block;
      border: solid ${theme.color.white};
      border-width: 0 0 1px 1px;
      height: 6px;
      transform: translate(3px, 4px) rotate(-50deg);
      width: 12px;
    }
  `}
`;

Control.defaultProps = { type: 'checkbox' };
