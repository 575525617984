import { Flag } from '@material-ui/icons';

import { ChangeLogType } from 'common/apollo/enums/ChangeLogType';
import {
  Content,
  FlagWrapper,
  Header,
  Inner,
  Outer,
} from 'ProjectProfile/ChangeLog/ChangeLogFlag/changeLogFlag.styles';
import { ProjectPlanChangeLogDifference } from 'ProjectProfile/common/utils/normalizeChangeLog';

type Props = {
  difference: ProjectPlanChangeLogDifference;
};

const ChangeLogFlag = ({ difference }: Props) => (
  <Outer>
    <Header>
      {difference.type === ChangeLogType.FLAG && (
        <FlagWrapper>
          <Flag />
        </FlagWrapper>
      )}
      {difference.question.label}
    </Header>
    <Inner>
      {difference.newValue.map((n) => (
        <Content key={n}>{n}</Content>
      ))}
    </Inner>
  </Outer>
);

export default ChangeLogFlag;
