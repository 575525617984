import { ProposalStatus } from 'common/apollo/enums/ProposalStatus';
import { ProposalType } from 'common/apollo/enums/ProposalType';
import { Proposal } from 'common/models/proposal';

export const hasAcceptedDesignOrContractorProposal = (proposals: Proposal[]) =>
  proposals.some(
    (proposal) =>
      proposal.status === ProposalStatus.ACCEPTED &&
      (proposal.type === ProposalType.DESIGN ||
        proposal.type === ProposalType.CONTRACTOR),
  );
