import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { mq } from 'common/utils/mediaQueries';

export const Content = styled.div<{ isEmpty: boolean }>`
  font: normal 14px/20px ${({ theme }) => theme.font.inter};
  letter-spacing: 0.2px;
  text-transform: capitalize;
  white-space: pre-wrap;

  ${mq.large(css`
    font-size: 16px;
    line-height: 22px;
  `)}

  p,
  div {
    margin-bottom: 10px;
  }

  small {
    color: ${({ theme }) => theme.color.gray};
    font: 400 12px/18px ${({ theme }) => theme.font.inter};
  }

  ${({ isEmpty, theme }) =>
    isEmpty
      ? css`
          color: ${theme.color.gray11};
        `
      : ''};
`;

export const Image = styled.img`
  height: fit-content;
  margin-right: 25px;
  width: 85px;
`;

export const Outer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .MuiIconButton-root {
    margin: -15px 0 0 20px;
  }
`;

export const Root = styled.div`
  display: flex;
  position: relative;

  svg {
    transition: all 0.3s ease;
  }

  &:hover {
    .MuiIconButton-root svg {
      fill: ${({ theme }) => theme.color.black};
      transform: scale(1.1);
    }
  }
`;

export const Title = styled.div`
  font: 600 14px/18px ${({ theme }) => theme.font.inter};
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  position: relative;
  max-width: 400px;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const FlagWrapper = styled.div`
  position: absolute;
  top: -2px;

  right: 0;

  ${mq.large(css`
    right: 0px;
  `)}
`;
