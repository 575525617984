import { useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GetProjectProcurementOrderRequest } from 'Project/Procurement/common/apollo/requests/getProjectProcurementOrderRequest';
import { GetProjectProcurementOrderResponse } from 'Project/Procurement/common/apollo/responses/getProjectProcurementOrderResponse';

import { GET_PROJECT_ORDER } from 'Project/Procurement/EditOrder/editOrder.graphql';

import {
  calculateExpectedMaterialsCost,
  getInitialValues,
  normalizeOrderMaterialsData,
  normalizeOrderShipmentsData,
} from 'Project/Procurement/EditOrder/editOrder.utils';

export const useEditOrder = () => {
  const { orderId } = useParams<{ orderId: string; projectId: string }>();

  const {
    data,
    error,
    loading: isLoading,
  } = useQuery<
    GetProjectProcurementOrderResponse,
    GetProjectProcurementOrderRequest
  >(GET_PROJECT_ORDER, {
    variables: { id: orderId },
  });

  const initialValues = getInitialValues(data?.order);
  const materials = normalizeOrderMaterialsData(data?.order.items);
  const shipments = normalizeOrderShipmentsData(data?.order.shipments);
  const orderNumber = data?.order.number || '';
  const expectedMaterialsCost = useMemo(
    () => calculateExpectedMaterialsCost(data?.order.items),
    [data?.order],
  );

  return {
    error,
    expectedMaterialsCost,
    initialValues,
    isLoading,
    materials,
    orderNumber,
    shipments,
  };
};
