import {
  PropertyProjectUpdateAnswerInput,
  PropertyProjectUpdateOpenAnswerInput,
} from 'Project/ProjectOverview/common/types/propertyProjectUpdateAnswer';

export const normalizeQuestionsSelection = (
  values: {
    [key: string]: string | string[];
  },
  openQuestions: string[] | undefined,
): PropertyProjectUpdateAnswerInput[] =>
  Object.entries(values).reduce<{ answerIds: string[]; questionId: string }[]>(
    (acc, [questionId, answerIdsOrResponseText]) => {
      if (openQuestions?.includes(questionId)) {
        return acc;
      }

      if (!answerIdsOrResponseText) {
        return acc.concat({
          answerIds: [],
          questionId,
        });
      }

      return acc.concat({
        answerIds: Array.isArray(answerIdsOrResponseText)
          ? answerIdsOrResponseText
          : [answerIdsOrResponseText],
        questionId,
      });
    },
    [],
  );

export const normalizeOpenQuestionsSelection = (
  values: {
    [key: string]: string | string[];
  },
  openQuestions: string[] | undefined,
): PropertyProjectUpdateOpenAnswerInput[] =>
  Object.entries(values).reduce<{ questionId: string; responseText: string }[]>(
    (acc, [questionId, responseText]) => {
      if (!openQuestions?.includes(questionId)) {
        return acc;
      }

      return acc.concat({
        questionId,
        responseText: String(responseText),
      });
    },
    [],
  );
