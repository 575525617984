import { useParams } from 'react-router-dom';

import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';

import { SidebarContentWrapper } from 'common/components/Sidebar/SidebarContentWrapper';
import { SidebarContent } from 'common/components/Sidebar/SidebarContent';

import { useProjectSidebar } from './projectSidebar.hooks';

const ProjectSidebar = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const {
    initialValues,
    isEditInProgress,
    isLoading,
    loadingSubmit,
    marketCode,
    onSubmit,
    projectType,
    secondaryContactEmail,
    serviceableStatus,
    toggleIsEditInProgress,
    userData,
    userId,
  } = useProjectSidebar(projectId);

  if (isLoading) return <LoadingSpinnerWithContainer />;

  return (
    <SidebarContentWrapper>
      <SidebarContent
        initialValues={initialValues}
        isEditInProgress={isEditInProgress}
        loadingSubmit={loadingSubmit}
        marketCode={marketCode}
        onSubmit={onSubmit}
        projectType={projectType}
        renovationPlanId={projectId}
        secondaryContactEmail={secondaryContactEmail}
        serviceableStatus={serviceableStatus}
        toggleIsEditInProgress={toggleIsEditInProgress}
        userData={userData}
        userId={userId}
      />
    </SidebarContentWrapper>
  );
};

export default ProjectSidebar;
