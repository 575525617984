import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { THEME } from 'common/consts/theme';

import { Button } from 'common/components/Buttons/Button/Button';

export const editIconStyles = css`
  height: 24px;
  padding: 5px;
  width: 24px;

  svg {
    height: inherit;
    width: inherit;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 29px 0 15px;

  font-size: 14px;
  line-height: 1.43;
  color: ${({ theme }) => theme.color.textLight};

  span {
    word-break: break-word;
  }
`;

export const ContentTwoColumnsItem = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-gap: 14px;
`;

export const titleStyles = css`
  color: ${THEME.color.black};
`;

export const ProjectWrapper = styled.div`
  display: flex;
  font: 500 14px/20px ${({ theme }) => theme.font.inter};

  + div {
    padding-top: 16px;
  }

  > span:last-of-type {
    margin-left: auto;
  }
`;

export const LoginAsUserButton = styled(Button)`
  && {
    padding: 11px;
  }
`;

export const ServiceableStatusExternalLink = styled.a<{
  isServiceable: boolean;
}>`
  color: ${({ isServiceable, theme }) =>
    isServiceable ? theme.color.green : theme.color.red};
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
`;
