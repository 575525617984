import { gql } from '@apollo/client';

export const GET_PROJECT_BUILDING_INFO = gql`
  query GetProjectBuildingInfo($input: ProjectBuildingInfoInput!) {
    projectBuildingInfo(input: $input) {
      id
      constructionInfo {
        energyType
        waterType
        heatingType
        frameType
        sewerType
        coolingType
        heatingFuel
      }
      propertyInfo {
        beds
        bathFixtures
        bathsPartial
        bathsTotal
        bathsFull
        unitsCount
        floors
        livingSize
        levels
        yearBuilt
        foundationType
        roomsTotal
      }
      hoa {
        type
        owner {
          lastName
          firstNameAndMi
          trustIndicator
        }
      }
    }
  }
`;
