import {
  Children,
  MouseEvent,
  ReactElement,
  ReactNode,
  cloneElement,
  isValidElement,
  useState,
} from 'react';

import { Menu, PopoverProps } from '@material-ui/core';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

type Props = {
  children: ReactNode;
  disablePortal?: boolean;
  iconButtonProps?: IconButtonProps;
} & Omit<PopoverProps, 'open'>;

type ClickableElement = {
  onClick: () => void;
};

export const ContextMenu = ({ children, iconButtonProps, ...props }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="Open menu"
        {...iconButtonProps}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        {...props}
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {Children.map(
          children as ReactElement<ClickableElement>[],
          (child: ReactElement<ClickableElement>) => {
            if (!isValidElement(child)) return child;

            return cloneElement(child, {
              onClick: () => {
                child.props.onClick?.();
                handleClose();
              },
            });
          },
        )}
      </Menu>
    </div>
  );
};
