import { useParams } from 'react-router-dom';

import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

import { mapProjectPlanRoomsAndQuestions } from 'ProjectProfile/common/utils/projectPlanRooms';
import { useProjectPlanRooms } from 'ProjectProfile/common/hooks/useProjectPlanRooms';
import { useProjectPlanRoomsQuestions } from 'ProjectProfile/common/hooks/useProjectPlanRoomsQuestions';
import { QueryParams } from 'ProjectProfile/common/types/queryParams';

export const useDesignerNotes = (identifiers: PropertyGroupIdentifier[]) => {
  const { projectId } = useParams<QueryParams>();

  const { data: rooms, isLoading: isLoadingProjectPlanRooms } =
    useProjectPlanRooms({
      projectId,
    });

  const roomsQuestionsInput = rooms.map((room) => ({
    identifiers,
    pricingEngineId: room.id,
  }));

  const {
    data: roomsQuestions,
    isLoading: isLoadingProjectPlanRoomsQuestions,
  } = useProjectPlanRoomsQuestions({
    roomsQuestionsInput,
  });

  return {
    isLoading: isLoadingProjectPlanRooms || isLoadingProjectPlanRoomsQuestions,
    roomsQuestions: mapProjectPlanRoomsAndQuestions(rooms, roomsQuestions),
  };
};
