import LinkIcon from '@material-ui/icons/Link';

import { useToggle } from 'common/hooks/useToggle';

import { EditButton } from 'ProjectProfile/common/components/EditButton/EditButton';
import {
  OutlinedLink,
  OutlinedNoLink,
} from 'ProjectProfile/common/components/Link/Link';
import { LinkContainer } from 'ProjectProfile/common/components/Link/LinkContainer/LinkContainer';
import { LinkItem } from 'ProjectProfile/common/components/Link/LinkItem/LinkItem';

import { SiteSurveyLinksEdit } from 'ProjectProfile/PreDesign/Links/SiteSurvey/SiteSurveyLinksEdit';
import {
  Header,
  Title,
  Wrapper,
} from 'ProjectProfile/PreDesign/Links/links.styles';

type Props = {
  siteSurveyDrawings: string | null;
  siteSurveyPhotos: string | null;
};

export const SiteSurveyLinks = ({
  siteSurveyDrawings,
  siteSurveyPhotos,
}: Props) => {
  const { isOn: isOpen, toggleOff: close, toggleOn: open } = useToggle();

  return (
    <Wrapper>
      <Header>
        <Title>Site Survey</Title>
        <EditButton
          onClick={open}
          segmentEventProps={{ name: 'Site Survey Links' }}
        />
      </Header>

      <LinkContainer>
        <LinkItem>
          {siteSurveyPhotos ? (
            <OutlinedLink color="primary" href={siteSurveyPhotos}>
              <LinkIcon /> Site survey Photos
            </OutlinedLink>
          ) : (
            <OutlinedNoLink>No Site survey Photos link</OutlinedNoLink>
          )}
        </LinkItem>
        <LinkItem>
          {siteSurveyDrawings ? (
            <OutlinedLink color="primary" href={siteSurveyDrawings}>
              <LinkIcon /> Site survey drawings
            </OutlinedLink>
          ) : (
            <OutlinedNoLink>No Site survey drawings link</OutlinedNoLink>
          )}
        </LinkItem>
      </LinkContainer>

      {isOpen && <SiteSurveyLinksEdit close={close} />}
    </Wrapper>
  );
};
