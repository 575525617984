import { object, string, array, number, mixed } from 'yup';

import {
  POSITIVE_NUMBER,
  REQUIRED_VALIDATION,
} from 'common/consts/validationMessages';

import { SkuVendorStockStatus } from 'common/apollo/enums/SkuVendorStockStatus';

export const validationSchema = object().shape({
  materials: array().of(
    object().shape({
      maxLeadTimeWeeks: mixed().when('stockStatus', {
        is: SkuVendorStockStatus.DISCONTINUED,
        otherwise: number()
          .min(1, POSITIVE_NUMBER)
          .nullable()
          .required(REQUIRED_VALIDATION),
        then: number().nullable(),
      }),
      minLeadTimeWeeks: mixed().when('stockStatus', {
        is: SkuVendorStockStatus.DISCONTINUED,
        otherwise: number()
          .min(1, POSITIVE_NUMBER)
          .nullable()
          .required(REQUIRED_VALIDATION),
        then: number().nullable(),
      }),
      stockStatus: string().trim().required(REQUIRED_VALIDATION),
    }),
  ),
});
