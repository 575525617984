import { ReactNode } from 'react';

import { EditButton } from 'ProjectProfile/common/components/EditButton/EditButton';
import { Skeleton } from 'ProjectProfile/common/components/Skeleton/Skeleton';

import { RoleLabelType } from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';
import { RoleLabel } from 'ProjectProfile/common/components/RoleLabel/RoleLabel';
import { EmptyCopy } from 'ProjectProfile/projectProfile.consts';
import { RoleLabelContainer } from 'ProjectProfile/common/components/RoleLabel/roleLabel.styles';
import { Flag } from 'ProjectProfile/common/components/Flag/Flag';

import {
  Content,
  FlagWrapper,
  IconWrapper,
  Root,
  Title,
  TopContainer,
  Wrapper,
} from './sectionOutlined.styles';

type Props = {
  children: ReactNode;
  emptyValue?: string;
  isImportant?: boolean;
  isLoading?: boolean;
  onEditClick?: () => void;
  roles?: RoleLabelType[];
  title: string;
};

export const SectionOutlined = ({
  children,
  emptyValue = EmptyCopy.REQUIRED_FIELD,
  isImportant = false,
  isLoading = false,
  onEditClick,
  roles,
  title,
}: Props) => (
  <Root>
    <Wrapper>
      <TopContainer>
        {title && <Title>{title}</Title>}
        {roles && (
          <RoleLabelContainer>
            {roles.map((role) => (
              <RoleLabel key={role} labelType={role} />
            ))}
          </RoleLabelContainer>
        )}
        {isImportant && (
          <FlagWrapper>
            <Flag />
          </FlagWrapper>
        )}
      </TopContainer>

      <Content isEmpty={!children}>
        {isLoading ? (
          <>
            <Skeleton width="80%" />
            <Skeleton width="60%" />
          </>
        ) : (
          children || emptyValue
        )}
      </Content>
    </Wrapper>

    {onEditClick && (
      <IconWrapper>
        <EditButton
          onClick={onEditClick}
          segmentEventProps={{ ...(title && { name: title }) }}
        />
      </IconWrapper>
    )}
  </Root>
);
