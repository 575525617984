import {
  MouseEvent,
  ReactNode,
  createContext,
  useContext,
  useMemo,
} from 'react';

import { PopperPlacementType } from '@material-ui/core/Popper';

import { MenuButtonContent } from 'common/components/Buttons/MenuButton/MenuButtonContent';
import { useMenuButton } from 'common/components/Buttons/MenuButton/menuButton.hooks';

type MenuButtonContextValue = {
  handleIconClick: (event: MouseEvent<SVGSVGElement, MouseEvent>) => void;
  handleMenuClose: () => void;
  isMenuOpen: boolean;
};

const MenuButtonContext = createContext<MenuButtonContextValue>(
  {} as MenuButtonContextValue,
);

export const useMenuButtonContext = () => useContext(MenuButtonContext);

export type Props = {
  children?: ReactNode;
  disablePortal?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  popperPlacement?: PopperPlacementType;
  title: string;
};

export const MenuButton = ({ children, ...rest }: Props) => {
  const { handleIconClick, handleMenuClose, isMenuOpen } = useMenuButton();

  const value = useMemo(
    () => ({
      handleIconClick,
      handleMenuClose,
      isMenuOpen,
    }),
    [handleIconClick, handleMenuClose, isMenuOpen],
  );

  return (
    <MenuButtonContext.Provider value={value}>
      <MenuButtonContent {...rest}>{children}</MenuButtonContent>
    </MenuButtonContext.Provider>
  );
};
