import { WarehouseColumnWidthMap } from 'Project/Procurement/Warehouse/warehouse.consts';

export const TABLE_HEAD_CONFIG = [
  {
    content: '',
    isSticky: true,
    width: WarehouseColumnWidthMap.ACTION_MENU,
  },
  {
    content: 'Product',
    isSticky: true,
    width: WarehouseColumnWidthMap.PRODUCT,
  },
  {
    content: 'Units Received',
    width: WarehouseColumnWidthMap.UNITS_RECEIVED,
  },
  {
    content: 'Units Shipped',
    width: WarehouseColumnWidthMap.UNITS_SHIPPED,
  },
  {
    content: 'SKU ID',
    width: WarehouseColumnWidthMap.SKU_ID,
  },
  {
    content: 'Shipment Type',
    width: WarehouseColumnWidthMap.SHIPMENT_TYPE,
  },
  {
    content: 'Room',
    width: WarehouseColumnWidthMap.ROOM,
  },
  {
    content: 'Color',
    width: WarehouseColumnWidthMap.COLOR,
  },
  {
    content: 'Finish',
    width: WarehouseColumnWidthMap.FINISH,
  },
  {
    content: 'Size',
    width: WarehouseColumnWidthMap.SIZE,
  },
  {
    content: 'Expand',
    width: WarehouseColumnWidthMap.EXPAND,
  },
];
