import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { GetProjectKeyDateResponse } from 'common/apollo/responses/getProjectKeyDateResponse';
import { GetProjectKeyDateRequest } from 'common/apollo/requests/getProjectKeyDateRequest';
import { ProjectKeyDateType } from 'common/apollo/enums/ProjectKeyDateType';
import { ProjectKeyDateInputType } from 'common/apollo/enums/ProjectKeyDateInputType';
import { InitialBreakGroundDateUpdateResponse } from 'common/apollo/responses/initialBreakGroundDateUpdateResponse';
import { InitialBreakGroundUpdateRequest } from 'common/apollo/requests/initialBreakGroundDateUpdateRequest';

import {
  GET_PROJECT_KEY_DATE,
  INITIAL_BREAK_GROUND_DATE_UPDATE,
} from './breakGroundDate.graphql';
import { mapProjectKeyDateInputTypeDictionary } from './breakGroundDate.utils';
import {
  breakGroundComponent,
  BreakGroundStates,
} from './breakGroundDate.consts';

export const useBreakGroundDate = (renovationPlanId: string) => {
  const projectId =
    useParams<{ projectId: string }>().projectId || renovationPlanId;

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [editDate, setEditDate] = useState<boolean>(false);

  const {
    data,
    loading: isLoading,
    refetch,
  } = useQuery<GetProjectKeyDateResponse, GetProjectKeyDateRequest>(
    GET_PROJECT_KEY_DATE,
    {
      variables: {
        input: {
          projectId,
          type: ProjectKeyDateType.BREAK_GROUND,
        },
      },
    },
  );

  const [initialBreakGroundDateUpdate] = useMutation<
    InitialBreakGroundDateUpdateResponse,
    InitialBreakGroundUpdateRequest
  >(INITIAL_BREAK_GROUND_DATE_UPDATE);

  const historyItems = data?.projectKeyDate?.validHistoryItems || [];
  const initialDate = historyItems.find(
    (item) => item.inputType === ProjectKeyDateInputType.INITIAL,
  )?.date;

  const isLocked =
    !!historyItems.length &&
    historyItems.some(
      (item) => item.inputType !== ProjectKeyDateInputType.INITIAL,
    );

  useEffect(() => {
    if (initialDate) {
      setSelectedDate(new Date(initialDate));
    }
  }, [initialDate]);

  const hasInitialDate = !!initialDate;
  const likeliestDate = data?.projectKeyDate?.date;
  const likeliestDateType = mapProjectKeyDateInputTypeDictionary(
    data?.projectKeyDate?.sourceType,
  );
  const showDateInput = editDate || !hasInitialDate;

  const handleDateChange = async (date: Date | null) => {
    if (!date) return;

    setSelectedDate(date);
    await initialBreakGroundDateUpdate({
      variables: {
        input: {
          date,
          projectId,
        },
      },
    });
    await refetch({
      input: {
        projectId,
        type: ProjectKeyDateType.BREAK_GROUND,
      },
    });
    setEditDate(false);
  };

  const handleEditClick = () => {
    setEditDate(true);
  };

  const handleCloseClick = () => {
    setEditDate(false);
  };

  const breakGroundToRender = () => {
    if (!initialDate && !historyItems.length) {
      return breakGroundComponent[BreakGroundStates.INITIAL];
    }
    if (hasInitialDate && !isLocked) {
      return breakGroundComponent[BreakGroundStates.EDITABLE];
    }
    if (hasInitialDate && isLocked) {
      return breakGroundComponent[BreakGroundStates.DISABLED];
    }
    if (!initialDate && isLocked) {
      return breakGroundComponent[BreakGroundStates.LIKELIEST_ONLY];
    }

    return breakGroundComponent[BreakGroundStates.INITIAL];
  };

  return {
    BreakGroundDateComponent: breakGroundToRender(),
    handleCloseClick,
    handleDateChange,
    handleEditClick,
    initialDate,
    isLoading,
    likeliestDate,
    likeliestDateType,
    selectedDate,
    showDateInput,
  };
};
