import * as React from 'react';

import { Close as CloseIcon } from '@material-ui/icons';

import { IconButtonStyled } from './ModalCloseButton.styles';

type Props = {
  className?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const ModalCloseButton = ({ className, onClick }: Props) => (
  <IconButtonStyled aria-label="Close" className={className} onClick={onClick}>
    <CloseIcon />
  </IconButtonStyled>
);
