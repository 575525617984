import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { THEME } from 'common/consts/theme';

export const titleStyles = css`
  color: ${THEME.color.black};
`;

export const Content = styled.div`
  padding-top: 20px;
`;
