import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';

import { ProjectType as ProjectTypeEnum } from 'common/apollo/enums/ProjectType';
import { ModalCloseButton } from 'common/components/Modals/ModalCloseButton';
import { FormSelect } from 'common/components/Form/Select/FormSelect';
import { TextFieldSize } from 'common/enums/TextField';
import { projectTypeDictionary } from 'common/consts/projectTypeList';

import { Section } from 'ProjectProfile/common/components/Section/Section';

import { useProjectTypeDialog } from './projectType.hooks';
import { DialogContent } from './projectType.styles';

interface Props {
  type: ProjectTypeEnum;
}

export const ProjectType = ({ type }: Props) => {
  const {
    closeDialog,
    handleSubmit,
    isDialogOpen,
    openDialog,
    selectOptions,
    validationSchema,
  } = useProjectTypeDialog();

  return (
    <>
      <Section onEditClick={openDialog} title="Project Type">
        {projectTypeDictionary[type]}
      </Section>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={isDialogOpen}
      >
        <DialogTitle>Project Type</DialogTitle>
        <ModalCloseButton onClick={closeDialog} />

        <Formik
          initialValues={{ projectType: type }}
          onSubmit={({ projectType }) => handleSubmit(projectType)}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <DialogContent>
                <FormSelect
                  id="projectType"
                  isDisabled={isSubmitting}
                  label="Project Type"
                  name="projectType"
                  options={selectOptions}
                  placeholder="Project Type"
                  size={TextFieldSize.EXTRA_LARGE}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
