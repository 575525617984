import Grid from '@material-ui/core/Grid';

import { Container } from 'ProjectProfile/common/components/Container/Container';
import { RoleLabelType } from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';
import { Section } from 'ProjectProfile/common/components/Section/Section';
import { EmptyCopy } from 'ProjectProfile/projectProfile.consts';

import {
  usePrimaryContactInfo,
  useSecondaryContactInfo,
} from './contactInfo.hooks';
import { SecondaryContactEdit } from './SecondaryContactEdit';

export const ContactInfo = () => {
  const { isPrimaryContactLoading, primaryContact } = usePrimaryContactInfo();

  const {
    hasData,
    isSecondaryContactEditOpen,
    isSecondaryContactLoading,
    secondaryContact,
    secondaryContactEditClose,
    secondaryContactEditOpen,
  } = useSecondaryContactInfo();

  return (
    <Container roles={[RoleLabelType.PROJECT_PLANNER]} title="Contact Info">
      <Grid container spacing={5}>
        <Grid item md={6} sm={12}>
          <Section isLoading={isPrimaryContactLoading} title="Primary Contact">
            <>
              <p>
                {primaryContact.firstName} {primaryContact.lastName}
              </p>
              <p>
                {primaryContact.email}{' '}
                {primaryContact.email && <small>Primary e-mail</small>}
              </p>
              <p>
                {primaryContact.phoneNumber}{' '}
                {primaryContact.phoneNumber && <small>Primary phone</small>}
              </p>
            </>
          </Section>
        </Grid>
        <Grid item md={6} sm={12}>
          <Section
            emptyValue={EmptyCopy.OPTIONAL_FIELD}
            isLoading={isSecondaryContactLoading}
            onEditClick={secondaryContactEditOpen}
            title="Secondary Contact"
          >
            {hasData && (
              <>
                <p>
                  {secondaryContact.name}{' '}
                  <small>{secondaryContact.relationship}</small>
                </p>
                <p>
                  {secondaryContact.email}{' '}
                  {secondaryContact.email && <small>Primary e-mail</small>}
                </p>
                <p>
                  {secondaryContact.phoneNumber}{' '}
                  {secondaryContact.phoneNumber && <small>Primary phone</small>}
                </p>
              </>
            )}
          </Section>
        </Grid>
      </Grid>
      {isSecondaryContactEditOpen && (
        <SecondaryContactEdit
          close={secondaryContactEditClose}
          primaryContact={primaryContact.email}
        />
      )}
    </Container>
  );
};
