import styled from '@emotion/styled';

import MuiDialogActions, {
  DialogActionsProps,
} from '@material-ui/core/DialogActions';

import { Button } from 'common/components/Buttons/Button/Button';

type Props = DialogActionsProps & {
  acceptLabel?: string;
  cancelLabel?: string;
  isAcceptLoading?: boolean;
  isDisabled?: boolean;
  onAccept?: () => void;
  onCancel?: () => void;
};

const DialogActionsStyled = styled(MuiDialogActions)`
  padding: 20px 9px 14px 24px;
`;

export const DialogActions = ({
  acceptLabel = 'Submit',
  cancelLabel = 'Cancel',
  children,
  isAcceptLoading,
  isDisabled,
  onAccept,
  onCancel,
  ...rest
}: Props) => (
  <DialogActionsStyled {...rest}>
    {children}

    {onCancel && (
      <Button
        aria-labelledby="cancel-btn"
        disabled={isDisabled}
        onClick={onCancel}
      >
        {cancelLabel}
      </Button>
    )}

    {onAccept && (
      <Button
        aria-label={acceptLabel}
        color="primary"
        disabled={isDisabled}
        isLoading={isAcceptLoading}
        onClick={onAccept}
        role="button"
      >
        {acceptLabel}
      </Button>
    )}
  </DialogActionsStyled>
);
