import {
  CurrencyField,
  EventType,
} from 'common/components/Form/CurrencyField/CurrencyField';
import { RangeFilter } from 'common/components/Filters/filters.types';
import { RangeTextField } from 'common/components/FiltersMenu/RangeFilter/rangeFilter.styles';

type Props = {
  placeholder: string;
  rangeFilter: RangeFilter;
  updateValue: (val: number) => void;
  value: number | null;
};

export const RangeFilterTextField = ({
  placeholder,
  rangeFilter,
  updateValue,
  value,
}: Props) =>
  rangeFilter.suffix === '$' ? (
    <CurrencyField
      allowNegative={false}
      onChange={(e: EventType, val: number) => updateValue(val)}
      placeholder={placeholder}
      value={value ?? 0}
    />
  ) : (
    <RangeTextField
      endAdornment={rangeFilter.suffix}
      onChange={(e) => updateValue(+e.target.value)}
      placeholder={placeholder}
      type="number"
      value={value ?? ''}
    />
  );
