import { useEffect, useReducer, useRef, useState } from 'react';

import axios, { AxiosProgressEvent } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import {
  getFileTypeErrorText,
  noFileSelectedMessage,
  noPresignedDataFoundMessage,
  UploadStatus,
} from 'common/hooks/FileUpload/fileUpload.consts';
import {
  FileState,
  ValidateFileInput,
} from 'common/hooks/FileUpload/fileUpload.types';

import {
  handleUploadCancelAction,
  handleUploadClearAction,
  handleUploadErrorAction,
  handleUploadLoadingAction,
  handleUploadProgressAction,
  handleUploadSuccessAction,
  storeFileDataAction,
  storePresignedDataAction,
} from 'common/hooks/FileUpload/fileUpload.actions';
import {
  initialState,
  reducer,
} from 'common/hooks/FileUpload/fileUpload.reducer';
import { FileUploadSpec } from 'common/apollo/models/fileUploadSpec';

type Config = {
  allowedMimeTypes?: string[];
  onUploadCancel?: () => void;
  onUploadError?: (error: string) => void;
  onUploadProgress?: (progress: number) => void;
  onUploadSuccess?: (fileUploadsList: FileState[]) => void;
};

export const useFileUpload = ({
  allowedMimeTypes,
  onUploadCancel,
  onUploadError,
  onUploadProgress,
  onUploadSuccess,
}: Config) => {
  const [fileUploadsList, dispatch] = useReducer(reducer, initialState);
  const prevUploadListRef = useRef<FileState[]>([]);

  const controller = new AbortController();
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    const allFilesUploaded = fileUploadsList.every(
      (file) => file.status === UploadStatus.Uploaded,
    );

    const allPreviousStateFilesUploaded = prevUploadListRef.current.every(
      (file) => file.status === UploadStatus.Uploaded,
    );

    if (allFilesUploaded && !allPreviousStateFilesUploaded) {
      onUploadSuccess?.(fileUploadsList);
    }
  }, [fileUploadsList, onUploadSuccess]);

  useEffect(() => {
    const fileUploading = fileUploadsList.some(
      (file) => file.progress && file.progress > 0,
    );

    if (!fileUploading) {
      return;
    }

    const totalProgress = fileUploadsList.reduce(
      (total, file) => total + file.progress,
      0,
    );
    const progress = totalProgress / fileUploadsList.length;

    onUploadProgress?.(progress);
  }, [fileUploadsList, onUploadProgress]);

  useEffect(() => {
    prevUploadListRef.current = fileUploadsList;
  });

  const validateFile = ({ type }: ValidateFileInput) => {
    const validationErrors: string[] = [];
    setErrors([]);

    const hasInValidMimeType =
      allowedMimeTypes &&
      allowedMimeTypes.length > 0 &&
      !allowedMimeTypes.includes(type || '');

    if (hasInValidMimeType) {
      const errorMessage = getFileTypeErrorText(allowedMimeTypes);
      validationErrors.push(errorMessage);
      onUploadError?.(errorMessage);
    }

    return validationErrors;
  };

  const mapFileToFormData = ({
    fileBlob,
    fileDetails,
    presignedData,
  }: FileState) => {
    if (!presignedData || !fileDetails) {
      setErrors([noPresignedDataFoundMessage]);
      return;
    }

    const formData = new FormData();

    formData.append('Content-Type', fileDetails.contentType);

    presignedData?.presignedFields.forEach(({ key, value }) => {
      formData.append(key, value);
    });

    formData.append('file', fileBlob || '');

    return formData;
  };

  const validateAndStoreFileData = (file: File) => {
    if (!file) {
      setErrors([noFileSelectedMessage]);
      return;
    }

    const errorsList = validateFile({
      type: file?.type || '',
    });

    if (errorsList.length) {
      setErrors(errorsList);
      return;
    }

    const id = uuidv4();

    dispatch(storeFileDataAction(file, id));

    return id;
  };

  const storePresignedFileData = (data: FileUploadSpec, fileId: string) => {
    if (!data) {
      onUploadError?.(noFileSelectedMessage);
      dispatch(handleUploadErrorAction(fileId, noFileSelectedMessage));
      return;
    }

    dispatch(storePresignedDataAction(data, fileId));
  };

  const sendFileToS3 = async (fileId: string) => {
    const currentFile = fileUploadsList.find((file) => file.id === fileId);
    if (!currentFile) {
      onUploadError?.(noFileSelectedMessage);
      setErrors([noFileSelectedMessage]);
      return;
    }

    if (!currentFile?.presignedData) {
      onUploadError?.(noPresignedDataFoundMessage);
      setErrors([noPresignedDataFoundMessage]);
      dispatch(handleUploadErrorAction(fileId, noPresignedDataFoundMessage));
      return;
    }

    const formData = mapFileToFormData(currentFile);

    dispatch(handleUploadLoadingAction(fileId, true));

    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const progressValue = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 1),
        );

        dispatch(handleUploadProgressAction(fileId, progressValue));
      },
      signal: controller.signal,
    };

    await axios
      .post(currentFile.presignedData.presignedUrl || '', formData, axiosConfig)
      .then(() => {
        dispatch(handleUploadSuccessAction(fileId));
      })
      .catch((error) => {
        setErrors([error.message]);
        onUploadError?.(error.message);
        dispatch(handleUploadErrorAction(fileId, UploadStatus.UploadError));
      })
      .finally(() => {
        dispatch(handleUploadLoadingAction(fileId, false));
      });
  };

  const cancelUpload = (fileId: string) => {
    controller.abort();
    onUploadCancel?.();
    dispatch(handleUploadCancelAction(fileId));
    dispatch(handleUploadLoadingAction(fileId, false));
  };

  const retry = async (fileId: string) => {
    const currentFile = fileUploadsList.find((file) => file.id === fileId);

    if (
      !currentFile?.presignedData ||
      (currentFile?.status !== UploadStatus.UploadError &&
        currentFile?.status !== UploadStatus.Canceled)
    )
      return;

    await sendFileToS3(fileId);
  };

  const clear = (fileId: string) => {
    dispatch(handleUploadClearAction(fileId));
  };

  return {
    cancelUpload,
    clear,
    errors,
    fileUploadsList,
    retry,
    sendFileToS3,
    storePresignedFileData,
    validateAndStoreFileData,
  };
};
