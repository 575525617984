import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ApolloError, useMutation } from '@apollo/client';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { StageIdentifier } from 'common/apollo/enums/StageIdentifier';
import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';
import { useAlert } from 'common/hooks/useAlert';

import { getModalId } from 'ClientTasks/TargetDate/common/targetDateModal.utils';

import { CONFIRM_TARGET_DATE_MODAL } from 'ClientTasks/TargetDate/ConfirmTargetDateModal/confirmTargetDateModal.consts';
import { ConfirmKeyDateResponse } from 'common/apollo/responses/confirmKeyDateResponse';

import { ConfirmKeyDateRequest } from 'common/apollo/requests/confirmKeyDateRequest';

import { CONFIRM_KEY_DATE } from 'ClientTasks/TargetDate/ConfirmTargetDateModal/confirmTargetDateModal.graphql';
import { useGetKeyDateChangeEvents } from 'ClientTasks/TargetDateHistoryModal/targetDateHistoryModal.hooks';
import { useTargetDate } from 'ClientTasks/TargetDate/targetDate.hooks';
import { KeyDateType } from 'common/apollo/enums/KeyDateType';

export const useConfirmDate = (stageIdentifier: StageIdentifier) => {
  const { projectId } = useParams<{ projectId: string }>();
  const setDialogOpen = useSetModalOpen();
  const { onError } = useAlert();
  const { refetchKeyDates } = useTargetDate(stageIdentifier);
  const modalId = getModalId(CONFIRM_TARGET_DATE_MODAL, stageIdentifier);
  const [targetDate, setTargetDate] = useState<Date | null>(null);
  const [note, setNote] = useState<string | null>(null);

  const { keyDateChangeEventRefetch } =
    useGetKeyDateChangeEvents(stageIdentifier);

  const [confirmBreakgroundDate] = useMutation<
    ConfirmKeyDateResponse,
    ConfirmKeyDateRequest
  >(CONFIRM_KEY_DATE, {
    onCompleted: () => {
      setDialogOpen(modalId, false);
      keyDateChangeEventRefetch();
      refetchKeyDates();
    },
    onError: (error: ApolloError) => onError(error.message),
  });

  const handleSave = async () => {
    if (!targetDate) return;

    targetDate.setHours(0, 0, 0, 0);

    const input = {
      note,
      reasonId: null,
      renovationPlanId: projectId,
      type: KeyDateType.BREAK_GROUND,
      value: targetDate,
    };

    await confirmBreakgroundDate({
      variables: {
        input,
      },
    });
  };

  const handleCloseDialog = () => {
    setDialogOpen(modalId, false);
  };

  const handleConfirmBreakgroundDate = () => {
    setDialogOpen(modalId, true);
  };

  const handleSetDate = (date: MaterialUiPickersDate) => {
    if (!date) return;
    setTargetDate(date);
  };

  const handleSetNote = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setNote(value);
  };

  const isValid = !!targetDate;

  return {
    handleCloseDialog,
    handleConfirmBreakgroundDate,
    handleSave,
    handleSetDate,
    handleSetNote,
    isValid,
    note,
    targetDate,
  };
};
