import Grid from '@material-ui/core/Grid';

import { Container } from 'ProjectProfile/common/components/Container/Container';
import { Section } from 'ProjectProfile/common/components/Section/Section';
import { SectionOutlined } from 'ProjectProfile/common/components/Section/SectionOutlined';

import { RoleLabelType } from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';

import { EmptyCopy } from 'ProjectProfile/projectProfile.consts';

import { useBuildingInfo } from './buildingInfo.hooks';

import { BuildingAge } from './BuildingAge/BuildingAge';
import { BuildingType } from './BuildingType/BuildingType';
import { BuildingInfoEdit } from './BuildingInfoEdit/BuildingInfoEdit';

export const BuildingInfo = () => {
  const { answers, close, handleSuccessfulSubmit, importances, isOpen, open } =
    useBuildingInfo();

  const {
    ALTERATION_AGREEMENT_HIGHLIGHTS,
    ALTERATION_AGREEMENT_REQUIRED,
    BUILDING_AGE,
    BUILDING_INFORMATION_NOTES,
    BUILDING_TYPE,
    DESIGN_OPS_NOTES,
    INSURANCE,
  } = answers;

  return (
    <>
      <BuildingInfoEdit
        handleSuccessfulSubmit={handleSuccessfulSubmit}
        isOpen={isOpen}
        onClose={close}
      />

      <Container
        onEditClick={open}
        roles={[RoleLabelType.PROJECT_PLANNER]}
        title="Building Information"
      >
        <Grid container spacing={4}>
          <Grid item md={6} sm={12}>
            <BuildingType value={BUILDING_TYPE} />
          </Grid>
          <Grid item md={6} sm={12}>
            <BuildingAge value={BUILDING_AGE} />
          </Grid>
          <Grid item md={10} sm={12}>
            <SectionOutlined
              isImportant={importances.BUILDING_INFORMATION_NOTES}
              title="Building information notes"
            >
              {BUILDING_INFORMATION_NOTES}
            </SectionOutlined>
          </Grid>
          <Grid item md={6} sm={12}>
            <Section
              emptyValue={EmptyCopy.OPTIONAL_FIELD}
              title="Insurance requirements"
            >
              {INSURANCE}
            </Section>
          </Grid>
          <Grid item md={6} sm={12}>
            <Section
              emptyValue={EmptyCopy.OPTIONAL_FIELD}
              title="Alteration Agreement"
            >
              {ALTERATION_AGREEMENT_REQUIRED}
            </Section>
          </Grid>
          <Grid item md={10} sm={12}>
            <SectionOutlined
              emptyValue={EmptyCopy.OPTIONAL_FIELD}
              isImportant={importances.ALTERATION_AGREEMENT_HIGHLIGHTS}
              title="Alteration agreement highlights"
            >
              {ALTERATION_AGREEMENT_HIGHLIGHTS}
            </SectionOutlined>
          </Grid>
          <Grid item md={10} sm={12}>
            <SectionOutlined
              emptyValue={EmptyCopy.OPTIONAL_FIELD}
              isImportant={importances.DESIGN_OPS_NOTES}
              title="Notes from Project Planner to Design Ops"
            >
              {DESIGN_OPS_NOTES}
            </SectionOutlined>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
