import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useMutation } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';

import { useModal } from 'common/components/Modal/Modal';

import { GET_PROJECT_ORDER } from 'Project/Procurement/EditOrder/editOrder.graphql';

import { OrderMaterial } from 'Project/Procurement/common/types/orderMaterial';

import { CreateProjectProcurementShipmentRequest } from 'Project/Procurement/common/apollo/requests/createProjectProcurementShipmentRequest';
import { CreateProjectProcurementShipmentResponse } from 'Project/Procurement/common/apollo/responses/createProjectProcurementShipmentResponse';

import { CreateShipmentFormValues } from 'Project/Procurement/EditOrder/CreateShipment/createShipment.types';

import { CREATE_SHIPMENT } from 'Project/Procurement/EditOrder/CreateShipment/createShipment.graphql';

import { generateInitialValues } from 'Project/Procurement/EditOrder/CreateShipment/createShipment.utils';
import { GET_PROJECT_PROCUREMENT_DELIVERY_BATCHES } from 'Project/Procurement/Warehouse/warehouse.graphql';

export const useCreateShipment = (
  materials: OrderMaterial[],
  shipmentsLength: number,
  orderNumber: string,
) => {
  const { orderId, projectId } = useParams<{
    orderId: string;
    projectId: string;
  }>();
  const { onError } = useAlert();
  const { onClose: handleClose } = useModal();
  const [items, setItems] = useState(materials);

  const [createShipment] = useMutation<
    CreateProjectProcurementShipmentResponse,
    CreateProjectProcurementShipmentRequest
  >(CREATE_SHIPMENT, {
    awaitRefetchQueries: true,
    onCompleted: () => handleClose(),
    onError: (error) => onError(error.message),
    refetchQueries: [
      { query: GET_PROJECT_ORDER, variables: { id: orderId } },
      {
        query: GET_PROJECT_PROCUREMENT_DELIVERY_BATCHES,
        variables: { projectId },
      },
    ],
  });

  const handleSubmit = (values: CreateShipmentFormValues) =>
    createShipment({
      variables: {
        input: {
          ...values,
          number: shipmentsLength
            ? `${orderNumber}-${shipmentsLength}`
            : orderNumber,
        },
      },
    });

  const initialValues = useMemo(
    () => generateInitialValues(materials),
    [materials],
  );

  const handleRemoveItem = (id: string) => {
    setItems((prev) => prev.filter((item) => item.id !== id));
  };

  return {
    handleClose,
    handleRemoveItem,
    handleSubmit,
    initialValues,
    items,
  };
};
