/* eslint-disable typescript-sort-keys/string-enum */
export enum PropertyIdentifier {
  ADD_ON_DESIGN_SERVICES = 'ADD_ON_DESIGN_SERVICES',
  ADD_ON_PERMITTING_AND_APPROVAL_SERVICES = 'ADD_ON_PERMITTING_AND_APPROVAL_SERVICES',
  ADD_ON_PROCUREMENT_SERVICES = 'ADD_ON_PROCUREMENT_SERVICES',
  ADD_ON_PROJECT_MANAGEMENT_SERVICES = 'ADD_ON_PROJECT_MANAGEMENT_SERVICES',
  ARCHITECT_PLANS_NO = 'ARCHITECT_PLANS_NO',
  ARCHITECT_PLANS_YES = 'ARCHITECT_PLANS_YES',
  BATHROOM_LAYOUT_V2_POWDER_ROOM_D = 'BATHROOM_LAYOUT_V2_POWDER_ROOM_D',
  BATHROOM_LAYOUT_V2_POWDER_ROOM_E = 'BATHROOM_LAYOUT_V2_POWDER_ROOM_E',
  BATHROOM_LAYOUT_V2_TUB_SEPARATE_SHOWER_D = 'BATHROOM_LAYOUT_V2_TUB_SEPARATE_SHOWER_D',
  BATHROOM_LAYOUT_V2_TUB_SEPARATE_SHOWER_E = 'BATHROOM_LAYOUT_V2_TUB_SEPARATE_SHOWER_E',

  IS_PROJECT_EXTENSIONS_CONTAINER_YES = 'IS_PROJECT_EXTENSIONS_CONTAINER_YES',
  IS_PROJECT_EXTENSIONS_CONTAINER_NO = 'IS_PROJECT_EXTENSIONS_CONTAINER_NO',

  PROPERTY_OWNERSHIP_YES = 'PROPERTY_OWNERSHIP_YES',
  PROPERTY_OWNERSHIP_CLOSE = 'PROPERTY_OWNERSHIP_CLOSE',
  PROPERTY_OWNERSHIP_NO = 'PROPERTY_OWNERSHIP_NO',

  DESIGN_PACKAGE_TEST_FUNNEL_PLUS = 'DESIGN_PACKAGE_TEST_FUNNEL_PLUS',
  DESIGN_PACKAGE_TEST_FUNNEL_SELECT = 'DESIGN_PACKAGE_TEST_FUNNEL_SELECT',

  // Bathroom sizes
  UP_TO_20 = 'UP_TO_20',
  BATHROOM_LAYOUT_V2_TUB_WITH_SHOWER_E = 'BATHROOM_LAYOUT_V2_TUB_WITH_SHOWER_E',
  FROM_41_TO_60 = 'FROM_41_TO_60',
  FROM_61_TO_80_PLUS = 'FROM_61_TO_80_PLUS',

  // Bathroom layout
  WALK_IN_SHOWER = 'WALK_IN_SHOWER',
  TUB_SHOWER = 'TUB_SHOWER',
  SEPARATE_SHOWER_TUB = 'SEPARATE_SHOWER_TUB',
  POWDER_ROOM = 'POWDER_ROOM',

  // Bathroom vanity
  SINGLE_VANITY = 'SINGLE_VANITY',
  DOUBLE_VANITY = 'DOUBLE_VANITY',

  // Kitchen sizes
  LESS_THAN_70 = 'LESS_THAN_70',
  FROM_71_TO_150 = 'FROM_71_TO_150',
  FROM_151_TO_230 = 'FROM_151_TO_230',
  FROM_231_TO_310 = 'FROM_231_TO_310',
  FROM_311_PLUS = 'FROM_311_PLUS',

  // Kitchen layout
  GALLEY = 'GALLEY',
  G_SHAPE = 'G_SHAPE',
  L_SHAPE = 'L_SHAPE',
  U_SHAPE = 'U_SHAPE',
  SINGLE_WALL = 'SINGLE_WALL',

  // Kitchen island
  NO_ISLAND = 'NO_ISLAND',
  ISLAND = 'ISLAND',

  // Room size v2
  ROOM_SIZE_UP_TO_20_D = 'ROOM_SIZE_UP_TO_20_D',
  ROOM_SIZE_21_40_D = 'ROOM_SIZE_21_40_D',
  ROOM_SIZE_41_60_D = 'ROOM_SIZE_41_60_D',
  ROOM_SIZE_61_80_D = 'ROOM_SIZE_61_80_D',
  ROOM_SIZE_81_100_D = 'ROOM_SIZE_81_100_D',
  ROOM_SIZE_101_120_D = 'ROOM_SIZE_101_120_D',
  ROOM_SIZE_121_140_D = 'ROOM_SIZE_121_140_D',
  ROOM_SIZE_301_400_D = 'ROOM_SIZE_301_400_D',
  ROOM_SIZE_401_500_D = 'ROOM_SIZE_401_500_D',

  ROOM_SIZE_UP_TO_20_E = 'ROOM_SIZE_UP_TO_20_E',
  ROOM_SIZE_21_40_E = 'ROOM_SIZE_21_40_E',
  ROOM_SIZE_41_60_E = 'ROOM_SIZE_41_60_E',
  ROOM_SIZE_61_80_E = 'ROOM_SIZE_61_80_E',
  ROOM_SIZE_81_100_E = 'ROOM_SIZE_81_100_E',
  ROOM_SIZE_101_120_E = 'ROOM_SIZE_101_120_E',
  ROOM_SIZE_121_140_E = 'ROOM_SIZE_121_140_E',
  ROOM_SIZE_301_400_E = 'ROOM_SIZE_301_400_E',
  ROOM_SIZE_401_500_E = 'ROOM_SIZE_401_500_E',

  // ROOM_INFO_V2 Bathrooms
  ROOM_GOALS_REFRESH = 'ROOM_GOALS_REFRESH',
  ROOM_GOALS_DESIGNER = 'ROOM_GOALS_DESIGNER',
  ROOM_GOALS_FUNCTION = 'ROOM_GOALS_FUNCTION',
  ROOM_GOALS_VALUE = 'ROOM_GOALS_VALUE',
  BATHROOM_LAYOUT_V2_WALK_IN_E = 'BATHROOM_LAYOUT_V2_WALK_IN_E',
  ELECTRIC_RANGE_CONFIG_NO = 'ELECTRIC_RANGE_CONFIG_NO',
  ELECTRIC_RANGE_CONFIG_YES = 'ELECTRIC_RANGE_CONFIG_YES',
  ROOM_TYPE_BATHROOM = 'ROOM_TYPE_BATHROOM',
  FLEXIBILITY_LOW = 'FLEXIBILITY_LOW',
  BATHROOM_LAYOUT_V2_TUB_WITH_SHOWER_D = 'BATHROOM_LAYOUT_V2_TUB_WITH_SHOWER_D',
  ROOM_TYPE_KITCHEN = 'ROOM_TYPE_KITCHEN',
  FLOOR_CONFIG_YES = 'FLOOR_CONFIG_YES',
  FRIDGE_CONFIG_INSTALL_ICE = 'FRIDGE_CONFIG_INSTALL_ICE',
  BATHROOM_VANITY_TYPE_V2_DOUBLE_E = 'BATHROOM_VANITY_TYPE_V2_DOUBLE_E',
  BATHROOM_VANITY_TYPE_V2_SINGLE_D = 'BATHROOM_VANITY_TYPE_V2_SINGLE_D',
  BATHROOM_VANITY_TYPE_V2_DOUBLE_D = 'BATHROOM_VANITY_TYPE_V2_DOUBLE_D',
  PONY_WALL_DEMO_YES = 'PONY_WALL_DEMO_YES',
  PONY_WALL_DEMO_NO = 'PONY_WALL_DEMO_NO',
  WASHLET_KEEP = 'WASHLET_KEEP',
  WASHLET_REPLACE = 'WASHLET_REPLACE',
  WASHLET_INSTALL = 'WASHLET_INSTALL',
  HEATED_FLOOR_NONE = 'HEATED_FLOOR_NONE',
  HEATED_FLOOR_REPLACE = 'HEATED_FLOOR_REPLACE',
  HEATED_FLOOR_INSTALL = 'HEATED_FLOOR_INSTALL',

  // ROOM_INFO_V2 Kitchens
  SOFFIT_DEMO_YES = 'SOFFIT_DEMO_YES',
  SOFFIT_DEMO_NO = 'SOFFIT_DEMO_NO',
  DESIGN_PACKAGE_TEST_FUNNEL_ESSENTIALS = 'DESIGN_PACKAGE_TEST_FUNNEL_ESSENTIALS',
  FLOOR_CONFIG_NO = 'FLOOR_CONFIG_NO',
  GAS_RANGE = 'GAS_RANGE',
  ELECTRIC_RANGE = 'ELECTRIC_RANGE',
  GAS_RANGE_CONFIG_YES = 'GAS_RANGE_CONFIG_YES',
  GAS_RANGE_CONFIG_NO = 'GAS_RANGE_CONFIG_NO',
  ROOM_TYPE_OTHER = 'ROOM_TYPE_OTHER',
  LESS_THAN_40 = 'LESS_THAN_40',
  RANGE_HOOD_CONFIG_KEEP = 'RANGE_HOOD_CONFIG_KEEP',
  RANGE_HOOD_CONFIG_REPLACE = 'RANGE_HOOD_CONFIG_REPLACE',
  MARKET_ATX = 'MARKET_ATX',
  MARKET_BOS = 'MARKET_BOS',
  MARKET_CHI = 'MARKET_CHI',
  BATHROOM_VANITY_TYPE_V2_SINGLE_E = 'BATHROOM_VANITY_TYPE_V2_SINGLE_E',
  MARKET_LA = 'MARKET_LA',
  UNDER_CABINET_LIGHTING_YES = 'UNDER_CABINET_LIGHTING_YES',
  UNDER_CABINET_LIGHTING_NO = 'UNDER_CABINET_LIGHTING_NO',
  DISHWASHER_CONFIG_KEEP = 'DISHWASHER_CONFIG_KEEP',
  DISHWASHER_CONFIG_REPLACE = 'DISHWASHER_CONFIG_REPLACE',
  DISHWASHER_CONFIG_INSTALL = 'DISHWASHER_CONFIG_INSTALL',
  DISHWASHER_REMOVE = 'DISHWASHER_REMOVE',
  MICROWAVE_CONFIG_KEEP = 'MICROWAVE_CONFIG_KEEP',
  MICROWAVE_CONFIG_REPLACE = 'MICROWAVE_CONFIG_REPLACE',
  MICROWAVE_CONFIG_INSTALL = 'MICROWAVE_CONFIG_INSTALL',
  MICROWAVE_REMOVE = 'MICROWAVE_REMOVE',
  COUNTERTOP_LENGTH_20 = 'COUNTERTOP_LENGTH_20',
  COUNTERTOP_LENGTH_40 = 'COUNTERTOP_LENGTH_40',
  COUNTERTOP_LENGTH_60 = 'COUNTERTOP_LENGTH_60',
  COUNTERTOP_LENGTH_80 = 'COUNTERTOP_LENGTH_80',

  RENOVATION_TIMELINE_0_3_MONTHS = 'RENOVATION_TIMELINE_0_3_MONTHS',
  RENOVATION_TIMELINE_3_6_MONTHS = 'RENOVATION_TIMELINE_3_6_MONTHS',
  RENOVATION_TIMELINE_MORE_THAN_6_MONTHS = 'RENOVATION_TIMELINE_MORE_THAN_6_MONTHS',
  RENOVATION_TIMELINE_NOT_SURE = 'RENOVATION_TIMELINE_NOT_SURE',

  DESIGN_PACKAGE_DIRECT = 'DESIGN_PACKAGE_DIRECT',
  DESIGN_PACKAGE_ESSENTIALS = 'DESIGN_PACKAGE_ESSENTIALS',
  DESIGN_PACKAGE_SELECT = 'DESIGN_PACKAGE_SELECT',
  DESIGN_PACKAGE_PLUS = 'DESIGN_PACKAGE_PLUS',

  SERVICEABLE_ZIP = 'SERVICEABLE_ZIP',
  FRIDGE_CONFIG_KEEP = 'FRIDGE_CONFIG_KEEP',

  RANGE_HOOD_CONFIG_INSTALL = 'RANGE_HOOD_CONFIG_INSTALL',
  NON_SERVICEABLE_ZIP = 'NON_SERVICEABLE_ZIP',
  FRIDGE_CONFIG_REPLACE_ICE = 'FRIDGE_CONFIG_REPLACE_ICE',
  MARKET_DC = 'MARKET_DC',
  MARKET_MIA = 'MARKET_MIA',
  MARKET_NY = 'MARKET_NY',
  MARKET_PHL = 'MARKET_PHL',
  FRIDGE_REPLACE_NO_ICE = 'FRIDGE_REPLACE_NO_ICE',
  MARKET_SD = 'MARKET_SD',
  MARKET_SEA = 'MARKET_SEA',
  MARKET_SF = 'MARKET_SF',

  BATHROOM_LAYOUT_V2_WALK_IN_D = 'BATHROOM_LAYOUT_V2_WALK_IN_D',
  FLEXIBILITY_MED = 'FLEXIBILITY_MED',
  FLEXIBILITY_HIGH = 'FLEXIBILITY_HIGH',

  MATCHING_WIN_RATE = 'MATCHING_WIN_RATE',
  MATCHING_COMPETITIVENESS = 'MATCHING_COMPETITIVENESS',
  MATCHING_NEW_CONTRACTOR = 'MATCHING_NEW_CONTRACTOR',
  MATCHING_SCOPE = 'MATCHING_SCOPE',
  MATCHING_PROJECT_VALUE = 'MATCHING_PROJECT_VALUE',
  MATCHING_MIN_SCORE = 'MATCHING_MIN_SCORE',
  MATCHING_MAX_ACCEPTED_WORK_ORDERS_30_DAYS = 'MATCHING_MAX_ACCEPTED_WORK_ORDERS_30_DAYS',
  MATCHING_NEW_MAX_WORK_ORDERS = 'MATCHING_NEW_MAX_WORK_ORDERS',
  MATCHING_NEW_MAX_AGE = 'MATCHING_NEW_MAX_AGE',
  MATCHING_WHALE_MIN_VALUE = 'MATCHING_WHALE_MIN_VALUE',
  MATCHING_WHALE = 'MATCHING_WHALE',
  MATCHING_LOCALIZED_MULTIPLIER = 'MATCHING_LOCALIZED_MULTIPLIER',
  MATCHING_SAME_BUILDING = 'MATCHING_SAME_BUILDING',
  MATCHING_SAME_BUILDING_MULTIPLIER = 'MATCHING_SAME_BUILDING_MULTIPLIER',
  MATCHING_LOCALIZED_EXPERIENCE = 'MATCHING_LOCALIZED_EXPERIENCE',

  // OTHER ROOM TYPES
  SPACE_ADDITION = 'SPACE_ADDITION',
  SPACE_ATTIC = 'SPACE_ATTIC',
  SPACE_BASEMENT = 'SPACE_BASEMENT',
  SPACE_BEDROOM = 'SPACE_BEDROOM',
  SPACE_DECK = 'SPACE_DECK',
  SPACE_FOYER = 'SPACE_FOYER',
  SPACE_GARAGE = 'SPACE_GARAGE',
  SPACE_HOUSE_EXTERIOR = 'SPACE_HOUSE_EXTERIOR',
  SPACE_LAUNDRY = 'SPACE_LAUNDRY',
  SPACE_LIVING_SPACE = 'SPACE_LIVING_SPACE',
  SPACE_ROOFING = 'SPACE_ROOFING',
  SPACE_YARD = 'SPACE_YARD',
  SPACE_SOMETHING_ELSE = 'SPACE_SOMETHING_ELSE',

  // PROJECT_FLAGS
  CONTRACTOR_CHOICE = 'CONTRACTOR_CHOICE',
  CONTRACTOR_CHOICE_SPEED_FLOW = 'CONTRACTOR_CHOICE_SPEED_FLOW',
  NO_DEPOSIT_FLOW = 'NO_DEPOSIT_FLOW',
  TAILORED_TOF = 'TAILORED_TOF',
  TAILORED_TOF_SWAE = 'TAILORED_TOF_SWAE',
  TAILORED_TOF_LP = 'TAILORED_TOF_LP',

  // CONTRACTOR_PREFERENCE_WORK_ORDER_QUANTITY
  CP_WOQ_1_5 = 'CP_WOQ_1_5',
  CP_WOQ_5_10 = 'CP_WOQ_5_10',
  CP_WOQ_10_15 = 'CP_WOQ_10_15',
  CP_WOQ_DO_NOT_LIMIT = 'CP_WOQ_DO_NOT_LIMIT',

  // CONTRACTOR_PREFERENCE_SCOPE
  CP_S_KITCHEN = 'CP_S_KITCHEN',
  CP_S_BATHROOM = 'CP_S_BATHROOM',
  CP_S_ADDITION = 'CP_S_ADDITION',
  CP_S_ATTIC = 'CP_S_ATTIC',
  CP_S_BASEMENT = 'CP_S_BASEMENT',
  CP_S_BEDROOM = 'CP_S_BEDROOM',
  CP_S_DECK = 'CP_S_DECK',
  CP_S_FOYER = 'CP_S_FOYER',
  CP_S_GARAGE = 'CP_S_GARAGE',
  CP_S_LAUNDRY = 'CP_S_LAUNDRY',
  CP_S_LIVING_SPACE = 'CP_S_LIVING_SPACE',
  CP_S_ROOFING = 'CP_S_ROOFING',
  CP_S_YARD = 'CP_S_YARD',
  CP_S_SOMETHING_ELSE = 'CP_S_SOMETHING_ELSE',
  CP_S_HOUSE_EXTERIOR = 'CP_S_HOUSE_EXTERIOR',
  CP_S_WHOLE_HOME = 'CP_S_WHOLE_HOME',

  // CONTRACTOR_PREFERENCE_TOTAL_PROJECT_VALUE
  CP_TPV_LESS_THAN_10K = 'CP_TPV_LESS_THAN_10K',
  CP_TPV_10K_25K = 'CP_TPV_10K_25K',
  CP_TPV_25L_75K = 'CP_TPV_25L_75K',
  CP_TPV_75K_150K = 'CP_TPV_75K_150K',
  CP_TPV_GREATER_THAN_150K = 'CP_TPV_GREATER_THAN_150K',

  DESIGN_HELP_LOT = 'DESIGN_HELP_LOT',
  DESIGN_HELP_LITTLE = 'DESIGN_HELP_LITTLE',
  DESIGN_HELP_NONE = 'DESIGN_HELP_NONE',

  PROJECT_INVOLVEMENT_LOT = 'PROJECT_INVOLVEMENT_LOT',
  PROJECT_INVOLVEMENT_LITTLE = 'PROJECT_INVOLVEMENT_LITTLE',
  PROJECT_INVOLVEMENT_NONE = 'PROJECT_INVOLVEMENT_NONE',

  MATERIAL_PREFERENCE_NONE = 'MATERIAL_PREFERENCE_NONE',
  MATERIAL_PREFERENCE_PROCURE = 'MATERIAL_PREFERENCE_PROCURE',
  MATERIAL_PREFERENCE_STORAGE = 'MATERIAL_PREFERENCE_STORAGE',

  PRIORITY_SPEED = 'PRIORITY_SPEED',
  PRIORITY_QUALITY = 'PRIORITY_QUALITY',
  PRIORITY_PRICE = 'PRIORITY_PRICE',

  SPEED_FLEXIBILITY_LOW = 'SPEED_FLEXIBILITY_LOW',
  SPEED_FLEXIBILITY_MED = 'SPEED_FLEXIBILITY_MED',
  SPEED_FLEXIBILITY_HIGH = 'SPEED_FLEXIBILITY_HIGH',

  QUALITY_FLEXIBILITY_LOW = 'QUALITY_FLEXIBILITY_LOW',
  QUALITY_FLEXIBILITY_MED = 'QUALITY_FLEXIBILITY_MED',
  QUALITY_FLEXIBILITY_HIGH = 'QUALITY_FLEXIBILITY_HIGH',
}
