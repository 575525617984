import { gql } from '@apollo/client';

export const GET_RENOVATION_PLAN_ONBOARDING_PLANNER = gql`
  query GetRenovationPlanOnboardingPlanner($renovationPlanId: ID!) {
    onboardingPlanner(renovationPlanId: $renovationPlanId) {
      id
      sections {
        id
        title
        showContractor
        showHomeowner
        questions {
          id
          title
          label
          description
          type
          systemType
          identifier
          isSingleSelect
          order
          required
          presentationType
          projectOpenAnswer(renovationPlanId: $renovationPlanId)
          selectedAnswers(renovationPlanId: $renovationPlanId)
          answers {
            id
            label
            isSelected(input: { renovationPlanId: $renovationPlanId })
            identifier
          }
        }
      }
    }
  }
`;
