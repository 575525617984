import { MenuItem } from '@material-ui/core';

import { DeliveryBatch } from 'common/apollo/models/deliveryBatch';
import { OrderItem } from 'common/apollo/models/orderItem';

import { ContextMenu } from 'common/components/Buttons/ContextMenu/ContextMenu';

import { CollapseContent } from 'common/components/Collapse/CollapseContent';
import { ExpandedIcon } from 'common/components/Icons/Icons';

import { BatchHeader } from 'Project/Procurement/Warehouse/WarehouseTable/BatchHeader/BatchHeader';
import { AddBatchRow } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/AddBatchRow';
import { BatchRow } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/BatchRow';
import { useOrderItemRow } from 'Project/Procurement/Warehouse/WarehouseTable/OrderItemRow/orderItemRow.hooks';

import {
  Cell,
  ItemCollapse,
  ProgressBar,
  ProgressBarWrapper,
  Row,
  ShipmentEta,
  ShipmentEtaStatus,
  ShipmentEtasWrapper,
  Wrapper,
} from 'Project/Procurement/Warehouse/WarehouseTable/OrderItemRow/orderItemRow.styles';

import { WarehouseZIndexMap } from 'Project/Procurement/Warehouse/warehouse.consts';

type Props = {
  batches: DeliveryBatch[];
  deliverTo: string;
  handleToggleAllBatchesForOrderItem: (orderItemId: string) => void;
  handleToggleBatch: (batchId: string) => void;
  isOpen: boolean;
  onCollapseToggle: (orderItemId: string) => void;
  onEditAllBatchesForOrderItem: (orderItemId: string) => void;
  orderItem: OrderItem;
  selectedBatches: string[];
  selectedOrderItems: string[];
};

export const OrderItemRow = ({
  batches,
  deliverTo,
  handleToggleAllBatchesForOrderItem,
  handleToggleBatch,
  isOpen,
  onCollapseToggle,
  onEditAllBatchesForOrderItem,
  orderItem,
  selectedBatches,
  selectedOrderItems,
}: Props) => {
  const { id, projectMaterial } = orderItem;

  const {
    handleAddBatch,
    isOn,
    shipmentEtas,
    toggleOff,
    toggleOn,
    unitsReceivedLabel,
    unitsReceivedValue,
    unitsRemaining,
    unitsShippedLabel,
    unitsShippedValue,
  } = useOrderItemRow(orderItem, batches);

  const currentTime = new Date().getTime();
  return (
    <ItemCollapse isOpen={isOpen}>
      <Wrapper onClick={() => onCollapseToggle(id)}>
        <Row>
          <Cell
            isSticky
            onClick={(e) => {
              e.stopPropagation();
            }}
            zIndex={WarehouseZIndexMap.orderItemRow}
          >
            <ContextMenu>
              <MenuItem
                onClick={() => handleAddBatch(id, isOpen, onCollapseToggle)}
              >
                Add Batch
              </MenuItem>
              <MenuItem onClick={() => onEditAllBatchesForOrderItem(id)}>
                Edit All Batches
              </MenuItem>
            </ContextMenu>
          </Cell>
          <Cell isSticky zIndex={WarehouseZIndexMap.orderItemRow}>
            {projectMaterial?.name || ''}
          </Cell>
          <Cell>
            <ProgressBarWrapper value={unitsReceivedValue}>
              {unitsReceivedLabel}
              <ProgressBar value={unitsReceivedValue} />
            </ProgressBarWrapper>
          </Cell>
          <Cell>
            <ProgressBarWrapper value={unitsShippedValue}>
              {unitsShippedLabel}
              <ProgressBar value={unitsShippedValue} />
            </ProgressBarWrapper>
          </Cell>
          <Cell>{projectMaterial?.sku.sku || ''}</Cell>
          <Cell>{deliverTo}</Cell>
          <Cell>{projectMaterial?.pricingEngine?.name || ''}</Cell>
          <Cell>{projectMaterial?.sku.color.description || ''}</Cell>
          <Cell>{projectMaterial?.sku.finish.description || ''}</Cell>
          <Cell>{projectMaterial?.sku.size.description || ''}</Cell>
          <Cell>
            <ExpandedIcon open={isOpen} width="80px" />
          </Cell>
        </Row>
      </Wrapper>
      <CollapseContent>
        {shipmentEtas.length > 0 && (
          <ShipmentEtasWrapper>
            ETA to Warehouse:
            {shipmentEtas.map((shipmentEta) => {
              const delivered = shipmentEta.getTime() <= currentTime;
              return (
                <ShipmentEta delivered={delivered} key={shipmentEta.getTime()}>
                  <ShipmentEtaStatus delivered={delivered} size="12px" />
                  {shipmentEta.toLocaleDateString()}
                </ShipmentEta>
              );
            })}
          </ShipmentEtasWrapper>
        )}
        <BatchHeader
          handleToggleAllBatchesForOrderItem={
            handleToggleAllBatchesForOrderItem
          }
          orderItem={orderItem}
          selectedOrderItems={selectedOrderItems}
        />
        {batches.map((batch, index) => (
          <BatchRow
            confirmedAt={batch.confirmedAt}
            eta={batch.eta}
            handleToggleBatch={handleToggleBatch}
            id={batch.id}
            inventoryUnits={batch.inventoryUnits}
            isSelected={selectedBatches.includes(batch.id)}
            key={batch.id}
            number={index + 1}
            pallet={batch.pallet}
            receivedAt={batch.receivedAt}
            shippedAt={batch.shippedAt}
            units={batch.units}
            unitsRemaining={unitsRemaining}
          />
        ))}
        <AddBatchRow
          isAdding={isOn}
          onAdd={toggleOn}
          onCancelAdd={toggleOff}
          orderItem={orderItem}
          unitsRemaining={unitsRemaining}
        />
      </CollapseContent>
    </ItemCollapse>
  );
};
