import { ChangeEvent } from 'react';

import { useField } from 'formik';

import { shouldValidateField } from 'common/utils/validations';

import { useHasFormError } from 'common/hooks/useHasFormError';

import { Option } from 'common/shared/Question/question.types';

import {
  Control,
  ControlLabel,
  ControlWrapper,
  ErrorMessage,
  Label,
  Wrapper,
  WrapperInner,
} from 'common/shared/Question/Confirm/confirm.styles';

export type Props = {
  hasError?: boolean;
  id: string;
  isDisabled: boolean;
  isRequired: boolean;
  label: string;
  name: string;
  onChange?: (value: string) => void;
  options: Option[];
};

export const Confirm = ({
  hasError,
  isDisabled,
  isRequired,
  label,
  name,
  onChange,
  options,
}: Props) => {
  const [field, , { setValue }] = useField({
    name,
    validate: shouldValidateField(isRequired),
  });

  const error = useHasFormError(name);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    onChange?.(value);

    setValue(value);
  };

  return (
    <Wrapper>
      <Label>{label}</Label>

      <WrapperInner>
        {options.map((option) => (
          <ControlWrapper key={option.value}>
            <Control
              checked={!!field.value && field.value.includes(option.value)}
              disabled={isDisabled}
              id={option.value}
              onChange={handleChange}
              required={isRequired}
              value={option.value}
            />

            <ControlLabel hasError={hasError} htmlFor={option.value}>
              {option.label}
            </ControlLabel>
          </ControlWrapper>
        ))}
      </WrapperInner>

      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};
