/* eslint-disable jsx-a11y/control-has-associated-label */
import { TableSortLabel } from '@material-ui/core';

import { Checkbox } from 'common/components/Checkbox/Checkbox';

import { Modal } from 'common/components/Modal/Modal';
import { ErrorSegment } from 'common/components/ErrorHandlers/ErrorSegment/ErrorSegment';
import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';

import { EmptyState } from 'Project/Procurement/common/components/Layout/Layout';

import { MaterialsFooter } from 'Project/Procurement/Materials/MaterialsFooter/MaterialsFooter';
import { MaterialsHeader } from 'Project/Procurement/Materials/MaterialsHeader/MaterialsHeader';
import { UpdateMaterial } from 'Project/Procurement/Materials/UpdateMaterial/UpdateMaterial';
import { UpdateMaterialNotes } from 'Project/Procurement/Materials/UpdateMaterialNotes/UpdateMaterialNotes';
import { MaterialTableRow } from 'Project/Procurement/Materials/MaterialTableRow/MaterialTableRow';

import {
  Content,
  Wrapper,
  Table,
  TableHead,
  TableHeaderCell,
} from 'Project/Procurement/Materials/materials.styles';
import { useMaterials } from 'Project/Procurement/Materials/materials.hooks';
import { StockStatusModal } from 'Project/Procurement/common/components/StockStatusModal/StockStatusModal';
import { useHasCatalogAccess } from 'MaterialsCatalog/common/hooks/useHasCatalogAccess';
import { MaterialsLongestLeadTime } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTime';

const Materials = () => {
  const {
    error,
    handleCloseMaterialNotesUpdateModal,
    handleCloseMaterialStockStatusUpdateModal,
    handleCloseMaterialUpdateModal,
    handleCreateOrder,
    handleEditLeadTime,
    handleOpenMateriaStockStatusUpdateModal,
    handleOpenMaterialNotesUpdateModal,
    handleOpenMaterialUpdateModal,
    handleToggleSort,
    isAllSelected,
    isLoading,
    isMaterialNotesUpdateModalOpen,
    isMaterialStockStatusUpdateModalOpen,
    isMaterialUpdateModalOpen,
    materials,
    materialsGeneratedAtDate,
    postDsoEnabled,
    pricingEngines,
    projectId,
    proposalName,
    selected,
    selectedMaterial,
    setIsAllSelected,
    setIsSelected,
    sortColumn,
    subcategories,
    vendors,
  } = useMaterials();

  const { hasWriteAccess } = useHasCatalogAccess();

  if (isLoading) return <LoadingSpinnerWithContainer />;
  if (error) return <ErrorSegment />;

  return (
    <Wrapper>
      <MaterialsLongestLeadTime />
      <MaterialsHeader
        createOrder={handleCreateOrder}
        editLeadTime={handleEditLeadTime}
        hasMaterialsSelected={!!selected.length}
        pricingEngines={pricingEngines}
        subcategories={subcategories}
        vendors={vendors}
      />

      <Content>
        {materials.length ? (
          <Table>
            <TableHead>
              <tr>
                <th>
                  <Checkbox
                    checked={isAllSelected}
                    onChange={setIsAllSelected}
                  />
                </th>
                <th />
                <TableHeaderCell minWidth="200px">Status</TableHeaderCell>
                <TableHeaderCell minWidth="125px">Added</TableHeaderCell>
                <th>
                  <TableSortLabel
                    active={sortColumn?.column === 'roomName'}
                    direction={sortColumn?.direction}
                    onClick={() => handleToggleSort('roomName')}
                  >
                    Room
                  </TableSortLabel>
                </th>
                <th>
                  <TableSortLabel
                    active={sortColumn?.column === 'subcategory'}
                    direction={sortColumn?.direction}
                    onClick={() => handleToggleSort('subcategory')}
                  >
                    Subcategory
                  </TableSortLabel>
                </th>
                <TableHeaderCell minWidth="200px">Product</TableHeaderCell>
                <th>SKU ID</th>
                <th>Color</th>
                <th>Finish</th>
                <th>Size</th>
                <th>Pattern</th>
                <th>Vendor</th>
                <th>Vendor SKU ID</th>
                <th>
                  <TableSortLabel
                    active={sortColumn?.column === 'stockStatus'}
                    direction={sortColumn?.direction}
                    onClick={() => handleToggleSort('stockStatus')}
                  >
                    Stock Status
                  </TableSortLabel>
                </th>
                <th>Total Units</th>
                <th>Units Ordered</th>
                <th>Notes</th>
                <th />
              </tr>
            </TableHead>

            <tbody>
              {materials.map((material) => (
                <MaterialTableRow
                  hasWriteAccess={hasWriteAccess}
                  isChecked={selected.includes(material.id)}
                  key={material.id}
                  material={material}
                  onChecked={setIsSelected}
                  onOpenMaterialNotesUpdateModal={
                    handleOpenMaterialNotesUpdateModal
                  }
                  onOpenMaterialStockStatusUpdateModal={
                    handleOpenMateriaStockStatusUpdateModal
                  }
                  onOpenMaterialUpdateModal={handleOpenMaterialUpdateModal}
                  postDsoEnabled={postDsoEnabled}
                  projectId={projectId}
                />
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyState> No Materials Found</EmptyState>
        )}
      </Content>

      <MaterialsFooter
        materials={materials}
        materialsGeneratedAtDate={materialsGeneratedAtDate}
        proposalName={proposalName}
      />

      <Modal
        onClose={handleCloseMaterialNotesUpdateModal}
        open={isMaterialNotesUpdateModalOpen}
      >
        <UpdateMaterialNotes material={selectedMaterial} />
      </Modal>

      <Modal
        onClose={handleCloseMaterialUpdateModal}
        open={isMaterialUpdateModalOpen}
      >
        <UpdateMaterial
          material={selectedMaterial}
          pricingEngines={pricingEngines}
        />
      </Modal>

      <Modal
        onClose={handleCloseMaterialStockStatusUpdateModal}
        open={isMaterialStockStatusUpdateModalOpen}
      >
        <StockStatusModal projectMaterialId={selectedMaterial?.id} />
      </Modal>
    </Wrapper>
  );
};

export default Materials;
