import { useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';

import { useModal } from 'common/components/Modal/Modal';

import { UpdateProjectProcurementShipmentRequest } from 'Project/Procurement/common/apollo/requests/updateProjectProcurementShipmentRequest';
import { UpdateProjectProcurementShipmentResponse } from 'Project/Procurement/common/apollo/responses/updateProjectProcurementShipmentResponse';

import { OrderShipment } from 'Project/Procurement/common/types/orderShipment';

import { EditShipmentFormValues } from 'Project/Procurement/EditOrder/EditShipment/editShipment.types';

import { EDIT_SHIPMENT } from 'Project/Procurement/EditOrder/EditShipment/editShipment.graphql';

import { generateInitialValues } from 'Project/Procurement/EditOrder/EditShipment/editShipment.utils';

export const useEditShipment = (shipment: OrderShipment | null) => {
  const { onError } = useAlert();
  const { onClose } = useModal();

  const [items, setItems] = useState(shipment?.items || []);

  const [editShipment] = useMutation<
    UpdateProjectProcurementShipmentResponse,
    UpdateProjectProcurementShipmentRequest
  >(EDIT_SHIPMENT, {
    onCompleted: () => onClose(),
    onError: (e) => onError(e.message),
  });

  const handleSubmit = (values: EditShipmentFormValues) =>
    editShipment({
      variables: {
        input: {
          ...values,
          orderItemsIds: items.map(({ orderItem: { id } }) => id),
          shipmentId: shipment?.id || '',
        },
      },
    });

  const initialValues = useMemo(
    () => generateInitialValues(shipment),
    [shipment],
  );

  const handleRemoveItem = (id: string) => {
    setItems((prev) => prev.filter((item) => item.id !== id));
  };

  return {
    handleRemoveItem,
    handleSubmit,
    initialValues,
    items,
    onClose,
  };
};
