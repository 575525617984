import Switch from '@material-ui/core/Switch';

import { FilterItem } from 'common/components/FiltersMenu/common/filterMenuItem.styles';
import {
  ToggleLabel,
  Wrapper,
} from 'common/components/FiltersMenu/ToggleFilter/toggleFilter.styles';

type Props = {
  isOn: boolean;
  label: string;
  onToggle: () => void;
};
export const ToggleFilterMenuItem = ({ isOn, label, onToggle }: Props) => (
  <FilterItem>
    <Wrapper>
      <ToggleLabel>{label}</ToggleLabel>
      <Switch checked={isOn} color="primary" onChange={onToggle} />
    </Wrapper>
  </FilterItem>
);
