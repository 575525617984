import { css, SerializedStyles } from '@emotion/react';

type MediaQueries = {
  large: (cssHelper: SerializedStyles) => SerializedStyles;
  medium: (cssHelper: SerializedStyles) => SerializedStyles;
  mobileMax: (cssHelper: SerializedStyles) => SerializedStyles;
  small: (cssHelper: SerializedStyles) => SerializedStyles;
  xLarge: (cssHelper: SerializedStyles) => SerializedStyles;
  xxLarge: (cssHelper: SerializedStyles) => SerializedStyles;
};

export const breakpoints = {
  large: 992,
  medium: 768,
  mobileMax: 991,
  small: 576,
  xLarge: 1200,
  xxLarge: 1280,
};

type Breakpoint = keyof typeof breakpoints;

export const mq = Object.keys(breakpoints).reduce<MediaQueries>(
  (acc, label) => {
    const prefix = 'min-width:';
    const suffix = 'px';

    acc[label as Breakpoint] = (cls: any) => css`
      @media (${prefix + breakpoints[label as Breakpoint] + suffix}) {
        ${cls};
      }
    `;

    return acc;
  },
  {} as MediaQueries,
);
