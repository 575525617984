import { useQuery } from '@apollo/client';

import { GET_USER_BASIC_DETAILS } from 'common/apollo/queries/user.graphql';

import {
  UserBasicDetailsGet,
  UserBasicDetailsGetVariables,
} from 'common/apollo/queries/__generated__/UserBasicDetailsGet';

export const useUserFullAddress = ({
  city,
  state,
  street,
  street2,
  zip,
}: {
  city?: string | null;
  state?: string | null;
  street?: string | null;
  street2?: string | null;
  zip?: string | null;
} = {}) => [street, street2, city, state, zip].filter(Boolean).join(', ');

export const useUserFullName = ({
  firstName,
  lastName,
}: {
  firstName?: string | null;
  lastName?: string | null;
}) => `${firstName || ''} ${lastName || ''}`.trim();

export const useGetUser = (userId: string) => {
  const { data, loading: loadingUser } = useQuery<
    UserBasicDetailsGet,
    UserBasicDetailsGetVariables
  >(GET_USER_BASIC_DETAILS, {
    variables: {
      id: userId,
    },
  });

  const user = data?.user || {
    firstName: '',
    id: userId,
    lastName: '',
  };

  return {
    loadingUser,
    user,
  };
};
