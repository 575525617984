import MuiSwitch, { SwitchProps } from '@material-ui/core/Switch';
import FormControlLabel, {
  FormControlLabelProps,
} from '@material-ui/core/FormControlLabel';

type Props = SwitchProps & Omit<FormControlLabelProps, 'control'>;

const SimpleSwitch = ({ value, ...rest }: SwitchProps) => (
  <MuiSwitch {...rest} value={`${value}`} />
);

export const Switch = ({ color = 'primary', label, ...rest }: Props) => {
  if (label) {
    return (
      <FormControlLabel
        control={<SimpleSwitch color={color} />}
        label={label}
        {...rest}
      />
    );
  }

  return <SimpleSwitch {...rest} />;
};
