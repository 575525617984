import styled from '@emotion/styled';

import { NavLink } from 'react-router-dom';

import { rgba } from 'common/utils/colors';

export const Section = styled.section`
  border-bottom: 1px solid ${({ theme }) => rgba(theme.color.black, 0.12)};

  & + & {
    border-top: 1px solid ${({ theme }) => rgba(theme.color.black, 0.12)};
  }
`;

export const Link = styled(NavLink)`
  color: ${({ theme }) => theme.color.blue11};
  cursor: pointer;
  display: inline-flex;
  font: normal 14px/20px ${({ theme }) => theme.font.inter};
  margin-left: 20px;
  margin-top: 16px;

  &:hover {
    text-decoration: underline;
  }
`;

export const SectionHeader = styled.header`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px 24px;
  gap: 16px;
`;

export const SectionTitle = styled.h2`
  font: 500 20px/28px ${({ theme }) => theme.font.inter};
`;

export const SectionContent = styled.div`
  overflow: auto;
`;

export const SectionAction = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

export const SectionSubtitle = styled.h3`
  font: 500 16px/20px ${({ theme }) => theme.font.inter};
  padding: 0 24px 16px 24px;
`;
