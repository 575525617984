import { ChangeEvent } from 'react';

import { useField } from 'formik';

import { useHasFormError } from 'common/hooks/useHasFormError';
import { shouldValidateField } from 'common/utils/validations';

import { Option } from 'common/shared/Question/question.types';

import {
  Wrapper,
  ErrorMessage,
  ControlLabel,
  Control,
} from 'common/shared/Question/Checkbox/SingleCheckbox/singleCheckbox.styles';

export type Props = {
  id?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  name: string;
  onChange?: (value: string) => void;
  option?: Option;
};

export const SingleCheckbox = ({
  isDisabled,
  isRequired,
  name,
  onChange,
  option,
}: Props) => {
  const [field, , { setValue }] = useField({
    name,
    validate: shouldValidateField(isRequired),
  });

  const error = useHasFormError(name);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked: isChecked, value } = e.target;

    onChange?.(isChecked ? value : '');

    setValue(isChecked ? value : '');
  };

  if (!option) return null;

  const { label, value } = option;

  return (
    <Wrapper>
      <ControlLabel>
        {label}
        <Control
          {...field}
          checked={!!field.value && field.value.includes(value)}
          disabled={isDisabled}
          onChange={handleChange}
          value={value}
        />
      </ControlLabel>

      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};
