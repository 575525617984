import { useQuery } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';

import { GetProjectPlanRoomsRequest } from 'common/apollo/requests/getProjectPlanRoomsRequest';
import { GetProjectPlanRoomsResponse } from 'common/apollo/responses/getProjectPlanRoomsResponse';

import { GET_PROJECT_PLAN_ROOMS } from 'ProjectProfile/common/apollo/gql/getProjectPlanRooms';

type Props = {
  projectId: string;
};

export const useProjectPlanRooms = ({ projectId }: Props) => {
  const { onError } = useAlert();

  const { data, loading } = useQuery<
    GetProjectPlanRoomsResponse,
    GetProjectPlanRoomsRequest
  >(GET_PROJECT_PLAN_ROOMS, {
    onError: (error) => onError(error.message),
    skip: !projectId,
    variables: {
      input: {
        projectId,
      },
    },
  });

  const isLoading = !data && loading;

  return {
    data: data?.projectPlanRooms || [],
    isLoading,
  };
};
