import { Add } from '@material-ui/icons';

import { Proposal as ProposalModel } from 'common/models/proposal';

import { EditableTypography } from 'common/components/Typography/EditableTypography/EditableTypography';
import { SidebarElement } from 'common/components/Sidebar/SidebarContent.styles';
import { Typography } from 'common/components/Typography/Typography';
import { ReactComponent as DuplicateIcon } from 'common/assets/icons/duplicate-bold.svg';

import { PricingEngines } from 'PricingEngineSidebar/Proposal/PricingEngines/PricingEngines';
import { Status } from 'PricingEngineSidebar/Proposal/Status/Status';

import { ProposalAction } from 'PricingEngineSidebar/Proposal/ProposalAction/ProposalAction';
import { useProposalAction } from 'PricingEngineSidebar/Proposal/ProposalAction/proposalAction.hooks';

import { useProposal } from 'PricingEngineSidebar/Proposal/proposal.hooks';

import { ProposalLinks } from 'PricingEngineSidebar/Proposal/ProposalLinks';

import {
  EditWrapper,
  IconWrapper,
  noRoomInfoStyles,
  sidebarElementStyles,
  duplicateIconStyles,
  AddButtonWrapper,
  addButtonStyles,
  AddButtonText,
} from './Proposal.styles';

type Props = {
  proposal: ProposalModel;
  renovationPlanId: string;
  userId: string;
};

export const Proposal = ({ proposal, renovationPlanId, userId }: Props) => {
  const { handleAddRoom, updateProposalName } = useProposal();
  const { duplicateProposal } = useProposalAction();

  const hasPricingEngines = !!proposal?.pricingEngines.length;

  const isPublished = !!proposal?.publishedAt;

  if (!proposal) return null;

  return (
    <>
      <SidebarElement>
        <EditWrapper>
          <EditableTypography
            maxWidth={154}
            onSave={updateProposalName}
            text={proposal.name}
            type="h5"
          />
          <IconWrapper>
            <DuplicateIcon
              css={duplicateIconStyles}
              onClick={duplicateProposal}
            />
          </IconWrapper>
        </EditWrapper>
        <Status
          acceptedAt={proposal.acceptedAt}
          publishedAt={proposal.publishedAt}
          status={proposal.status}
        />
        <ProposalAction />
        <PricingEngines pricingEngines={proposal.pricingEngines} />
        <ProposalLinks renovationPlanId={renovationPlanId} userId={userId} />
      </SidebarElement>

      {!isPublished && (
        <SidebarElement css={sidebarElementStyles}>
          {!hasPricingEngines && (
            <Typography css={noRoomInfoStyles}>
              To begin, add a room to your proposal
            </Typography>
          )}

          <AddButtonWrapper onClick={handleAddRoom}>
            <Add css={addButtonStyles} fontSize="small" />
            <AddButtonText>Add Room</AddButtonText>
          </AddButtonWrapper>
        </SidebarElement>
      )}
    </>
  );
};
