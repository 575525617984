import { Field, FieldProps } from 'formik';

import { useHasFormError } from 'common/hooks/useHasFormError';

// Components
import {
  CurrencyField,
  EventType,
  Props as CurrencyFieldProps,
} from './CurrencyField';

export type Props = Partial<CurrencyFieldProps> & {
  onChange?: (event: EventType, value: number) => void;
};

const CurrencyFieldWrapper = ({
  field: { name, onBlur, value },
  form: { setFieldValue },
  onChange,
  ...props
}: FieldProps & Props) => {
  const errorMessage = useHasFormError(name);

  const handleChange = (event: EventType, newValue: number) => {
    if (onChange) onChange(event, newValue);
    setFieldValue(name, newValue);
  };

  return (
    <CurrencyField
      {...props}
      errorMessage={errorMessage}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      value={value}
    />
  );
};

export const FormCurrencyField = (props: Props) => (
  <Field component={CurrencyFieldWrapper} {...props} />
);
