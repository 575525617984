import { gql } from '@apollo/client';

export const GET_PROJECT_ORDER_MATERIALS = gql`
  query GetProjectOrderMaterials($ids: [ID!]!) {
    projectMaterials(ids: $ids) {
      id
      amount
      name
      boxCount
      category {
        id
        name
      }
      sku {
        id
        sku
        name
        width
        depth
        height
        length
        thickness
        vendor {
          id
          vendorSku
          unitCost
          link
          vendor {
            id
            name
          }
        }
        unit {
          type
        }
        color {
          description
        }
        finish {
          description
        }
        pattern {
          description
        }
        product {
          brand {
            name
            line {
              name
            }
          }
        }
      }
      pricingEngineSku {
        id
        unitCost
      }
    }

    projectMaterialsVendors(ids: $ids) {
      id
      name
    }
  }
`;

export const CREATE_PROJECT_ORDER = gql`
  mutation CreateProjectOrder($input: OrderCreateInput!) {
    orderCreate(input: $input) {
      id
    }
  }
`;
