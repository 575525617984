import { ReactNode } from 'react';

import { EditButton } from 'ProjectProfile/common/components/EditButton/EditButton';
import { Skeleton } from 'ProjectProfile/common/components/Skeleton/Skeleton';
import { RoleLabelType } from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';
import { RoleLabel } from 'ProjectProfile/common/components/RoleLabel/RoleLabel';
import { Flag } from 'ProjectProfile/common/components/Flag/Flag';

import { EmptyCopy } from 'ProjectProfile/projectProfile.consts';

import {
  Content,
  FlagWrapper,
  Image,
  Outer,
  Root,
  Title,
  TopContainer,
  Wrapper,
} from './section.styles';

type Props = {
  children: ReactNode;
  emptyValue?: string;
  imageUrl?: string;
  isImportant?: boolean;
  isLoading?: boolean;
  onEditClick?: () => void;
  roles?: RoleLabelType[];
  title?: string;
};

export const Section = ({
  children,
  emptyValue = EmptyCopy.REQUIRED_FIELD,
  imageUrl,
  isImportant = false,
  isLoading = false,
  onEditClick,
  roles,
  title,
}: Props) => (
  <Root>
    {imageUrl && <Image alt={title} src={imageUrl} />}
    <Wrapper>
      <Outer>
        <TopContainer>
          {title && <Title>{title}</Title>}
          {roles &&
            roles.map((role) => <RoleLabel key={role} labelType={role} />)}
          {isImportant && (
            <FlagWrapper>
              <Flag />
            </FlagWrapper>
          )}
        </TopContainer>

        {onEditClick && (
          <EditButton
            onClick={onEditClick}
            segmentEventProps={{ ...(title && { name: title }) }}
          />
        )}
      </Outer>
      <Content isEmpty={!children}>
        {isLoading ? <Skeleton width="80%" /> : children || emptyValue}
      </Content>
    </Wrapper>
  </Root>
);
