import styled from '@emotion/styled';

type Props = {
  circle?: boolean;
  fullWidth?: boolean;
  height?: string | number;
  width?: string | number;
};

const size = (value: number | string) =>
  Number.isInteger(value) ? `${value}px` : value;

export const Skeleton = styled.div<Props>`
  height: ${({ height = 16 }) => size(height)};
  width: ${({ fullWidth = false, width = 200 }) =>
    fullWidth ? '100%' : size(width)};
  background-color: ${({ theme }) => theme.color.alto};
  border-radius: ${({ circle, height, width }) =>
    (width && height && circle && '50%') || '2px'};
  cursor: progress;
`;
