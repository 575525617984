import { useMutation } from '@apollo/client';

import { useParams } from 'react-router-dom';

import { useAlert } from 'common/hooks/useAlert';

import {
  ProjectPlanQuestionsUpdateOpenQuestionsInput,
  ProjectPlanQuestionsUpdateQuestionsInput,
  UpdateProjectPlanQuestionsRequest,
} from 'common/apollo/requests/updateProjectPlanQuestionsRequest';
import { UpdateProjectPlanQuestionsResponse } from 'ProjectProfile/common/responses/updateProjectPlanQuestionsResponse';

import { UPDATE_PROJECT_PLAN_QUESTIONS } from 'ProjectProfile/common/apollo/gql/updateProjectPlanQuestions';

type Props = {
  onSuccessCallback?: (response: UpdateProjectPlanQuestionsResponse) => void;
  openQuestions?: ProjectPlanQuestionsUpdateOpenQuestionsInput[];
  questions?: ProjectPlanQuestionsUpdateQuestionsInput[];
};

export const useProjectPlanQuestionsUpdate = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { onCompleted, onError } = useAlert();

  const [updateProjectPlanQuestion] = useMutation<
    UpdateProjectPlanQuestionsResponse,
    UpdateProjectPlanQuestionsRequest
  >(UPDATE_PROJECT_PLAN_QUESTIONS, {
    onCompleted: () => onCompleted('Changes saved'),
    onError: (error) => onError(error.message),
  });

  const handleSubmit = async ({
    onSuccessCallback,
    openQuestions,
    questions,
  }: Props) => {
    const response = await updateProjectPlanQuestion({
      variables: {
        input: {
          openQuestions,
          projectId,
          questions,
        },
      },
    });

    if (response && response.data && onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  };

  return {
    handleSubmit,
  };
};
