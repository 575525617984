import { useMutation } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';

import {
  ProjectPlanRoomsQuestionsRoomUpdateInput,
  UpdateProjectPlanRoomsQuestionsRequest,
} from 'common/apollo/requests/updateProjectPlanQuestionsRequest';
import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

import { UpdateProjectPlanRoomsQuestionsResponse } from 'ProjectProfile/common/responses/updateProjectPlanRoomsQuestionsResponse';
import { UPDATE_PROJECT_PLAN_ROOMS_QUESTIONS } from 'ProjectProfile/common/apollo/gql/updateProjectPlanRoomsQuestions';
import { GET_PROJECT_PLAN_ROOMS_QUESTIONS } from 'ProjectProfile/common/apollo/gql/getProjectPlanRoomsQuestions';
import { GetProjectPlanRoomsQuestionsResponse } from 'common/apollo/responses/getProjectPlanRoomsQuestionsResponse';

type Props = {
  identifiers: PropertyGroupIdentifier[];
  onSuccessCallback?: () => void;
  rooms: ProjectPlanRoomsQuestionsRoomUpdateInput[];
};

export const useProjectPlanRoomsQuestionsUpdate = () => {
  const { onCompleted, onError } = useAlert();

  const [updateProjectPlanRoomsQuestions] = useMutation<
    UpdateProjectPlanRoomsQuestionsResponse,
    UpdateProjectPlanRoomsQuestionsRequest
  >(UPDATE_PROJECT_PLAN_ROOMS_QUESTIONS, {
    onCompleted: () => onCompleted('Changes saved'),
    onError: (error) => onError(error.message),
  });

  const handleSubmit = async ({
    identifiers,
    onSuccessCallback,
    rooms,
  }: Props) => {
    const roomsQuestionsInput = rooms.map(({ pricingEngineId }) => ({
      identifiers,
      pricingEngineId,
    }));

    const response = await updateProjectPlanRoomsQuestions({
      update: (cache, { data }) => {
        const store = cache.readQuery({
          query: GET_PROJECT_PLAN_ROOMS_QUESTIONS,
          variables: {
            input: {
              rooms: roomsQuestionsInput,
            },
          },
        }) as GetProjectPlanRoomsQuestionsResponse;

        const hasRoomQuestion = store.projectPlanRoomsQuestions.some(
          ({ id }) => id === data?.projectPlanRoomsQuestionsUpdate[0].id,
        );

        if (hasRoomQuestion) return;

        cache.writeQuery({
          data: {
            ...store,
            projectPlanRoomsQuestions: [
              ...store.projectPlanRoomsQuestions,
              data,
            ],
          },
          query: GET_PROJECT_PLAN_ROOMS_QUESTIONS,
          variables: {
            input: {
              rooms: roomsQuestionsInput,
            },
          },
        });
      },
      variables: {
        input: {
          rooms,
        },
      },
    });

    if (response && onSuccessCallback) onSuccessCallback();
  };

  return {
    handleSubmit,
  };
};
