import { dateToUSFormat } from 'common/utils/date';

import { LinkExternal } from 'common/components/Navigation/Link/LinkExternal';
import { Badge } from 'common/components/Badge/Badge';

import { Shipment as ShipmentModel } from 'common/apollo/models/shipment';

import {
  PROJECT_SHIPMENT_DELIVER_TO_DICTIONARY,
  PROJECT_SHIPMENT_SHIPPING_METHOD_DICTIONARY,
  PROJECT_SHIPMENT_STATUS_COLOR_VARIANT,
  PROJECT_SHIPMENT_STATUS_DICTIONARY,
} from 'Project/Procurement/common/consts/shipment.consts';

import {
  ContentWrapper,
  Label,
  ShipmentWrapper,
  Value,
} from 'Project/Procurement/Materials/MaterialTableRow/Shipment/shipment.styles';

type Props = { shipment: ShipmentModel };

export const Shipment = ({
  shipment: {
    deliverTo,
    estimatedDeliveryDate,
    items,
    number,
    shippingMethod,
    status: shipmentStatus,
    trackingLink,
    trackingNumber,
  },
}: Props) => (
  <ShipmentWrapper>
    <div>
      <Badge variant={PROJECT_SHIPMENT_STATUS_COLOR_VARIANT[shipmentStatus]}>
        {PROJECT_SHIPMENT_STATUS_DICTIONARY[shipmentStatus]}
      </Badge>
    </div>

    <ContentWrapper>
      <Label>Shipment</Label>
      <Value>{number}</Value>
    </ContentWrapper>

    <ContentWrapper>
      <Label>Tracking</Label>
      <LinkExternal href={trackingLink} rel="noreferrer" target="_blank">
        {trackingNumber}
      </LinkExternal>
    </ContentWrapper>

    <ContentWrapper>
      <Label>Vendor</Label>
      <Value>{items[0].orderItem.skuVendor.vendor.name}</Value>
    </ContentWrapper>

    <ContentWrapper>
      <Label>Est. Delivery Date</Label>
      <Value>
        {estimatedDeliveryDate && dateToUSFormat(estimatedDeliveryDate)}
      </Value>
    </ContentWrapper>

    <ContentWrapper>
      <Label>Destination</Label>
      <Value>{PROJECT_SHIPMENT_DELIVER_TO_DICTIONARY[deliverTo]}</Value>
    </ContentWrapper>

    <ContentWrapper>
      <Label>Shipping Method</Label>
      {PROJECT_SHIPMENT_SHIPPING_METHOD_DICTIONARY[shippingMethod]}
    </ContentWrapper>
  </ShipmentWrapper>
);
