import { MouseEvent, useEffect } from 'react';
import { useFormikContext } from 'formik';

import { ApolloError, DataProxy, useMutation } from '@apollo/client';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useAlert } from 'common/hooks/useAlert';
import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';
import { PricingEngine } from 'common/apollo/models/pricingEngine';

import { DIALOG_ID } from 'ProjectProfile/ProjectInformation/v2/DeleteSpaceDialog/DeleteSpaceDialog';
import { REMOVE_PRICING_ENGINE } from 'PricingEngineSidebar/Proposal/PricingEngines/pricingEngines.graphql';
import { updateProposalCacheOnPricingEngineDelete } from 'PricingEngineSidebar/pricingEngineSidebar.state';

import {
  PricingEngineRemove,
  PricingEngineRemoveVariables,
} from 'PricingEngineSidebar/Proposal/PricingEngines/__generated__/pricingEngines.graphql.generated';
import { USER_PROPOSALS } from 'Proposals/proposals.graphql';
import { FormValues } from 'ProjectProfile/ProjectInformation/v2/projectInformation.types';
import { getRoomValues } from 'ProjectProfile/ProjectInformation/v2/projectInformation.utils';
import { DualAnswer } from 'common/shared/Question/question.types';
import { usePricingEngineUpdate } from 'ProjectProfile/common/hooks/usePricingEngineUpdate';
import { DESCRIPTION_MIN_CHARS } from 'ProjectProfile/ProjectInformation/v2/projectInformation.hooks';
import { FeatureFlags } from 'common/enums/FeatureFlags';

export const useSpace = (
  projectId: string,
  proposalId: string,
  room: PricingEngine,
  rooms: PricingEngine[],
  index: number,
) => {
  const {
    dirty,
    resetForm,
    setFieldValue,
    validateForm,
    values: formValues,
  } = useFormikContext<FormValues>();
  const setDialogOpen = useSetModalOpen();

  const { handleSubmit: handlePricingEngineSubmit } = usePricingEngineUpdate();

  const { onCompleted, onError } = useAlert();

  const [removePricingEngine] = useMutation<
    PricingEngineRemove,
    PricingEngineRemoveVariables
  >(REMOVE_PRICING_ENGINE, {
    onCompleted: () => onCompleted('Successfully removed '),
    onError: (error: ApolloError) => onError(error.message),
    refetchQueries: [
      {
        query: USER_PROPOSALS,
        variables: {
          renovationPlanId: projectId,
        },
      },
    ],
  });

  const onDeleteSpaceClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    setDialogOpen(`${DIALOG_ID}${room.id}`, true);
  };

  const deleteSpace = () => {
    removePricingEngine({
      update: (proxy: DataProxy) => {
        updateProposalCacheOnPricingEngineDelete(proxy, room.id, proposalId);
      },
      variables: { input: { pricingEngineId: room.id } },
    });
  };

  const cancelDeleteSpace = () => {
    setDialogOpen(`${DIALOG_ID}${room.id}`, false);
  };

  const handleDescriptionChange = async (
    value: string | string[] | DualAnswer,
  ) => {
    const normalizedValue = String(value);
    setFieldValue(`rooms[${index}].description`, value);

    if (normalizedValue.length < DESCRIPTION_MIN_CHARS) {
      return;
    }

    await handlePricingEngineSubmit({
      request: {
        input: {
          description: normalizedValue,
          pricingEngineId: room.id,
        },
      },
    });
  };

  const handleNiceToHaveDescriptionChange = async (
    value: string | string[] | DualAnswer,
  ) => {
    const normalizedValue = String(value);
    setFieldValue(`rooms[${index}].niceToHaveDescription`, value);

    await handlePricingEngineSubmit({
      request: {
        input: {
          niceToHaveDescription: normalizedValue,
          pricingEngineId: room.id,
        },
      },
    });
  };

  const handleNameChange = async (value: string | string[] | DualAnswer) => {
    const normalizedValue = String(value);
    await handlePricingEngineSubmit({
      request: {
        input: {
          name: normalizedValue,
          pricingEngineId: room.id,
        },
      },
    });
  };

  useEffect(() => {
    if (!rooms) {
      return;
    }

    const roomValues = getRoomValues(rooms);
    const oldRooms = formValues.rooms.filter((oldRoom) =>
      roomValues.some((newRoom) => oldRoom.id === newRoom.id),
    );
    const newerRooms = roomValues.filter(
      (newRoom) =>
        !formValues.rooms.some((oldRoom) => oldRoom.id === newRoom.id),
    );

    const updatedRooms = [...oldRooms, ...newerRooms];

    if (dirty) {
      setFieldValue('rooms', updatedRooms);
    } else {
      resetForm({ values: { ...formValues, rooms: updatedRooms } });
      validateForm();
    }

    // intentionally not adding formValues to dependencies, otherwise
    // we would be stuck in a dependency loop

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm, rooms, validateForm]);

  const flags = useFlags();
  const niceToHaveDescriptionEnabled =
    flags[FeatureFlags.PLANNER_ROOM_NICE_TO_HAVES];

  return {
    cancelDeleteSpace,
    deleteSpace,
    handleDescriptionChange,
    handleNameChange,
    handleNiceToHaveDescriptionChange,
    niceToHaveDescriptionEnabled,
    onDeleteSpaceClick,
  };
};
