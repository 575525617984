import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Paper } from '@material-ui/core';

import { THEME } from 'common/consts/theme';

import { Popper, Props as PopperProps } from 'common/components/Popper/Popper';

type SelectPopperMenuProps = {
  width?: number;
};

type SelectWrapperProps = {
  isDisabled?: boolean;
};

export const SelectPopper = styled(Popper)<PopperProps>`
  z-index: 99999;

  ${({ disablePortal }) =>
    disablePortal &&
    css`
      right: 0px;
    `}

  ${({ theme }) => css`
    &.active {
      border: 1px solid ${theme.color.primary};
      border-radius: 4px;
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  `}
`;

export const SelectPopperMenu = styled(Paper)<SelectPopperMenuProps>`
  max-height: 300px;
  overflow: auto;

  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
`;

export const SelectWrapper = styled.div<SelectWrapperProps>`
  width: 100%;
  position: relative;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
    `}
`;

export const clickableStyles = css`
  cursor: pointer;
`;

export const clearIconStyles = css`
  color: ${THEME.color.darkGray};
  height: 14px;
  width: 14px;
  &:hover {
    color: ${THEME.color.darkGray2};
  }
`;
