import { FeatureFlag } from 'common/components/FeatureFlag/FeatureFlag';
import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';
import { FeatureFlags } from 'common/enums/FeatureFlags';

import { useMaterialsLongestLeadTime } from 'MaterialsLongestLeadTime/materialsLongestLeadTime.hooks';
import { MaterialsLongestLeadTimeFormVariant } from 'MaterialsLongestLeadTime/materialsLongestLeadTime.consts';
import { MaterialsLongestLeadTimeSet } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeSet/MaterialsLongestLeadTimeSet';

import { MaterialsLongestLeadTimeConfirm } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeConfirm/MaterialsLongestLeadTimeConfirm';

import { DateLabel, Wrapper } from './materialsLongestLeadTime.styles';
import { MaterialsLongestLeadTimeUpdate } from './MaterialsLongestLeadTimeUpdate/MaterialsLongestLeadTimeUpdate';

export const MaterialsLongestLeadTime = () => {
  const { formVariant, isLoading, materialsllt } =
    useMaterialsLongestLeadTime();

  if (isLoading) return <LoadingSpinnerWithContainer />;

  return (
    <FeatureFlag id={FeatureFlags.MATERIALS_LONGEST_LEAD_TIME}>
      <Wrapper>
        {materialsllt && (
          <DateLabel>Longest Lead Time: {materialsllt}</DateLabel>
        )}
        {formVariant === MaterialsLongestLeadTimeFormVariant.SHIFT_LLT && (
          <MaterialsLongestLeadTimeUpdate />
        )}
        {formVariant === MaterialsLongestLeadTimeFormVariant.SET_LLT && (
          <MaterialsLongestLeadTimeSet />
        )}
        {formVariant === MaterialsLongestLeadTimeFormVariant.CONFIRM_LLT && (
          <MaterialsLongestLeadTimeConfirm />
        )}
      </Wrapper>
    </FeatureFlag>
  );
};
