import { useParams } from 'react-router-dom';

import { usePricingEngine } from 'PricingEngine/PricingEngineProvider/pricingEngine.hooks';

export const useProjectId = () => {
  const { projectId: paramsProjectId, renovationPlanId } = useParams<{
    projectId: string;
    renovationPlanId: string;
  }>();
  const { pricingEngine } = usePricingEngine();

  const proposalProjectId = pricingEngine?.proposal?.renovationPlan.id;

  return paramsProjectId || proposalProjectId || renovationPlanId;
};
