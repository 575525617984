import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';

import { MATERIALS_LONGEST_LEAD_TIME_UPDATE_DIALOG } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeUpdate/materialsLongestLeadTimeUpdate.consts';

export const useMaterialsLongestLeadTimeUpdate = () => {
  const setModalOpen = useSetModalOpen();

  const handleMaterialsLongestLeadTimeUpdateClick = () => {
    setModalOpen(MATERIALS_LONGEST_LEAD_TIME_UPDATE_DIALOG, true);
  };

  return {
    handleMaterialsLongestLeadTimeUpdateClick,
  };
};
