import { useMutation, useQuery } from '@apollo/client';

import { useModal } from 'common/components/Modal/Modal';

import { useAlert } from 'common/hooks/useAlert';

import { SkuVendorStockStatus } from 'common/apollo/enums/SkuVendorStockStatus';

import { GetProjectProcurementMaterialsLeadTimeRequest } from 'Project/Procurement/common/apollo/requests/getProjectProcurementMaterialsLeadTimeRequest';
import { GetProjectProcurementMaterialsLeadTimeResponse } from 'Project/Procurement/common/apollo/responses/GetProjectProcurementMaterialsLeadTimeResponse';
import { UpdateProjectProcurementMaterialsLeadTimeResponse } from 'Project/Procurement/common/apollo/responses/updateProjectProcurementMaterialsLeadTimeResponse';
import { UpdateProjectProcurementMaterialsLeadTimeRequest } from 'Project/Procurement/common/apollo/requests/updateProjectProcurementMaterialsLeadTimeRequest';

import { GET_PROJECT_MATERIALS_LEAD_TIME } from 'Project/Procurement/common/apollo/gql/getProjectMaterialsLeadTime';
import { UPDATE_MATERIALS_LEAD_TIME } from 'Project/Procurement/common/apollo/gql/updateMaterialsLeadTime';

import { FormValue } from './stockStatusModal.types';

const useEditMaterialLeadTime = () => {
  const { onClose: handleClose } = useModal();
  const { onError } = useAlert();
  const [updateMaterialLeadTime] = useMutation<
    UpdateProjectProcurementMaterialsLeadTimeResponse,
    UpdateProjectProcurementMaterialsLeadTimeRequest
  >(UPDATE_MATERIALS_LEAD_TIME, {
    onCompleted: () => handleClose(),
    onError: (e) => onError(e.message),
  });

  const handleSubmit = ({
    backInStockDate,
    maxLeadTimeWeeks,
    minLeadTimeWeeks,
    ...rest
  }: FormValue) =>
    updateMaterialLeadTime({
      variables: {
        input: {
          leadTimes: [
            {
              backInStockDate: backInStockDate || null,
              maxLeadTimeWeeks: +maxLeadTimeWeeks || null,
              minLeadTimeWeeks: +minLeadTimeWeeks || null,
              ...rest,
            },
          ],
        },
      },
    });

  return { handleSubmit };
};

const useGetMaterial = (projectMaterialId?: string) => {
  const { onError } = useAlert();

  const {
    data,
    error,
    loading: isLoading,
  } = useQuery<
    GetProjectProcurementMaterialsLeadTimeResponse,
    GetProjectProcurementMaterialsLeadTimeRequest
  >(GET_PROJECT_MATERIALS_LEAD_TIME, {
    onError: (e) => onError(e.message),
    skip: !projectMaterialId,
    variables: {
      ids: projectMaterialId ? [projectMaterialId] : [],
    },
  });

  const material = data?.projectMaterials[0];

  const initialValues = {
    backInStockDate: material?.sku.vendor.backInStockDate || null,
    maxLeadTimeWeeks: material?.sku.vendor.maxLeadTimeWeeks || '',
    minLeadTimeWeeks: material?.sku.vendor.minLeadTimeWeeks || '',
    skuVendorId: material?.sku.vendor.id || '',
    stockStatus:
      material?.sku.vendor.stockStatus || ('' as SkuVendorStockStatus),
  };

  return { error, initialValues, isLoading, material };
};

export const useStockStatusModal = (projectMaterialId?: string) => {
  const { onClose: handleClose } = useModal();

  const { handleSubmit } = useEditMaterialLeadTime();
  const { initialValues, isLoading, material } =
    useGetMaterial(projectMaterialId);

  return {
    handleClose,
    handleSubmit,
    initialValues,
    isLoading,
    material,
  };
};
