import {
  Header,
  Layout,
  Title,
} from 'ProjectProfile/common/components/Layout/Layout';

import { ShareButton } from 'ProjectProfile/common/components/ShareButton/ShareButton';

import { ClientDetails } from 'ProjectProfile/ProjectInformation/v1/ClientDetails/ClientDetails';
import { ContactInfo } from 'ProjectProfile/ProjectInformation/v1/ContactInfo/ContactInfo';
import { Summary } from 'ProjectProfile/ProjectInformation/v1/Summary/Summary';

export const ProjectInformation = () => (
  <Layout>
    <Header>
      <Title>Project Information</Title>
      <ShareButton />
    </Header>

    <Summary />
    <ClientDetails />
    <ContactInfo />
  </Layout>
);
