import { gql } from '@apollo/client';

export const UPDATE_PRICING_ENGINE = gql`
  mutation RenovationPlannerPricingEngineUpdate(
    $input: RenovationPlannerPricingEngineUpdateInput!
  ) {
    renovationPlannerPricingEngineUpdate(input: $input) {
      id
      name
      roomType
      description
      selectedAnswers {
        id
        propertyGroupId
      }
      userMedia(mediaTypes: [IMAGE, DOCUMENT, VIDEO]) {
        id
        fileName
        fileUrl
        mediaType
      }
    }
  }
`;
