import Grid from '@material-ui/core/Grid';

import { ConstructionInfo as ConstructionInfoType } from 'common/apollo/models/projectBuildingInfo';

import { Container } from 'ProjectProfile/common/components/Container/Container';
import { RoleLabelType } from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';
import { Section } from 'ProjectProfile/common/components/Section/Section';
import { EmptyCopy } from 'ProjectProfile/projectProfile.consts';

type Props = {
  constructionInfo: ConstructionInfoType;
  isLoading: boolean;
  roles: RoleLabelType[];
};

export const ConstructionInfo = ({
  constructionInfo: {
    coolingType,
    energyType,
    frameType,
    heatingFuel,
    heatingType,
    sewerType,
    waterType,
  },
  isLoading,
  roles,
}: Props) => (
  <Container
    isLoading={isLoading}
    roles={roles}
    title="Construction Information"
  >
    <Grid container spacing={4}>
      <Grid item md={6} sm={12}>
        <Section emptyValue={EmptyCopy.OPTIONAL_FIELD} title="Electrical">
          {energyType}
        </Section>
      </Grid>
      <Grid item md={6} sm={12}>
        <Section emptyValue={EmptyCopy.OPTIONAL_FIELD} title="Frame Type">
          {frameType}
        </Section>
      </Grid>
      <Grid item md={6} sm={12}>
        <Section
          emptyValue={EmptyCopy.OPTIONAL_FIELD}
          title="Ultilities water source"
        >
          {waterType}
        </Section>
      </Grid>
      <Grid item md={6} sm={12}>
        <Section
          emptyValue={EmptyCopy.OPTIONAL_FIELD}
          title="Ultilities sewage usage"
        >
          {sewerType}
        </Section>
      </Grid>
      <Grid item md={6} sm={12}>
        <Section emptyValue={EmptyCopy.OPTIONAL_FIELD} title="Heating Type">
          {heatingType}
        </Section>
      </Grid>
      <Grid item md={6} sm={12}>
        <Section emptyValue={EmptyCopy.OPTIONAL_FIELD} title="Cooling detail">
          {coolingType}
        </Section>
      </Grid>
      <Grid item md={6} sm={12}>
        <Section emptyValue={EmptyCopy.OPTIONAL_FIELD} title="Heating fuel">
          {heatingFuel}
        </Section>
      </Grid>
    </Grid>
  </Container>
);
