import { array, object, string } from 'yup';

import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

export const questionIdentifiers = [
  PropertyGroupIdentifier.ALTERATION_AGREEMENT_REQUIRED,
  PropertyGroupIdentifier.INSURANCE,
];

export const openQuestionsIdentifiers = [
  PropertyGroupIdentifier.ALTERATION_AGREEMENT_HIGHLIGHTS,
  PropertyGroupIdentifier.BUILDING_INFORMATION_NOTES,
  PropertyGroupIdentifier.DESIGN_OPS_NOTES,
];

export const identifiers = [
  ...questionIdentifiers,
  ...openQuestionsIdentifiers,
];

export const validationSchema = object().shape({
  [PropertyGroupIdentifier.ALTERATION_AGREEMENT_HIGHLIGHTS]: string(),
  [PropertyGroupIdentifier.BUILDING_INFORMATION_NOTES]: string(),
  [PropertyGroupIdentifier.DESIGN_OPS_NOTES]: string(),
  [PropertyGroupIdentifier.INSURANCE]: array().of(string()),
  [PropertyGroupIdentifier.SERVICE_ENTRANCE]: string(),
});
