import { FormTextField } from 'common/components/Form/TextField/FormTextField';

export const formInitialValues = {
  city: '',
  email: '',
  firstName: '',
  lastName: '',
  state: '',
  street: '',
  street_2: '',
  zip: '',
};

export const EMAIL_VALIDATION = 'Email format required';
export const REQUIRED_FIELD_VALIDATION = 'This field is required';
export const DUPLICATE_SECONDARY_EMAIL =
  'Duplicative primary contact email, please update the secondary contact email or use a different email';

export const formInputs = [
  {
    component: FormTextField,
    name: 'firstName',
    placeholder: 'First Name',
  },
  {
    component: FormTextField,
    name: 'lastName',
    placeholder: 'Last Name',
  },
  {
    component: FormTextField,
    name: 'email',
    placeholder: 'Email',
  },
  {
    component: FormTextField,
    name: 'phoneNumber',
    placeholder: 'Phone Number',
  },
  {
    component: FormTextField,
    name: 'street',
    placeholder: 'Address Line 1',
  },
  {
    component: FormTextField,
    name: 'street_2',
    placeholder: 'Address Line 2',
  },
  {
    component: FormTextField,
    name: 'city',
    placeholder: 'City',
  },
  {
    component: FormTextField,
    name: 'state',
    placeholder: 'State',
  },
  {
    component: FormTextField,
    name: 'zip',
    placeholder: 'Zip',
  },
];
