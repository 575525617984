import { gql } from '@apollo/client';

import { DICTIONARY_ITEM_FIELDS } from 'common/apollo/fragments/dictionaryItem.graphql';

export const GET_PRICING_ENGINE = gql`
  query PricingEngineGet($id: ID!) {
    pricingEngine(id: $id) {
      id
      name
      roomType
      proposal {
        id
        laborVersion
        renovationPlan {
          id
          renovationInfo {
            address {
              zip
            }
          }
          projectType
        }
        status
        type
      }

      costs {
        construction {
          total
        }
        materials {
          total
        }
        services {
          total
        }
        total {
          total
        }
      }
    }
  }
`;

export const GET_PRICING_ENGINE_UNITS = gql`
  ${DICTIONARY_ITEM_FIELDS}

  query PricingEngineUnitsGet {
    laborUnits: dictionaries(types: [LABOR_UNITS_KITCHEN]) {
      ...DictionaryItemFields
    }
    productSkuStatuses: dictionaries(types: [PRODUCT_SKU_STATUSES]) {
      ...DictionaryItemFields
    }
    productSkuUnits: dictionaries(types: [PRODUCT_SKU_UNITS]) {
      ...DictionaryItemFields
    }
    productSkuProcuredBys: dictionaries(types: [PRODUCT_SKU_PROCURED_BYS]) {
      ...DictionaryItemFields
    }
    vifStages: dictionaries(types: [VIF_STAGES]) {
      ...DictionaryItemFields
    }
    verifiedStates: dictionaries(types: [VERIFIED_STATES]) {
      ...DictionaryItemFields
    }
  }
`;

export const GET_TAG_GROUPS = gql`
  query GetTagGroups($tagGroupCategoryIds: [ID!]!) {
    tagGroups(tagGroupCategoryIds: $tagGroupCategoryIds) {
      id
      name
      displayName
      description
      selectionType
      tags {
        id
        name
        displayName
        defaultOrder
      }
    }
  }
`;

export const PROPOSAL_LABOR_VERSION = gql`
  query ProposalLaborVersionGet($proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      laborVersion
    }
  }
`;
