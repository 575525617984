import styled from '@emotion/styled';

export const ShareTooltip = styled.div`
  display: flex;
  align-items: center;

  .MuiSvgIcon-root {
    margin-right: 4px;
  }
`;
