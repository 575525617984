import { RefObject } from 'react';

import { ControllerStateAndHelpers } from 'downshift';

import { SelectType } from 'common/types/select';
import { isSelectType } from 'common/guards/select';
import { SelectMultipleType } from 'common/types/selectMultiple';

import { isOptionSelected } from '../select.utils';
import { SelectPopper, SelectPopperMenu } from '../Select.styles';

import { SelectOption } from './SelectOption';

type Props<Option> = {
  disablePortal?: boolean;
  downshift: ControllerStateAndHelpers<any>;
  options: Array<SelectMultipleType<Option> | SelectType<Option> | Option>;
  selectRef: RefObject<HTMLInputElement>;
};

export const SelectMenu = <Option,>({
  disablePortal = true,
  downshift,
  options,
  selectRef,
}: Props<Option>) => {
  const { getItemProps, getMenuProps, highlightedIndex, isOpen, selectedItem } =
    downshift;

  const menuProps = getMenuProps({}, { suppressRefError: true });
  const menuWidth =
    (!disablePortal && selectRef.current?.offsetWidth) || undefined;

  return (
    <SelectPopper
      anchorEl={selectRef.current}
      disablePortal={disablePortal}
      open={isOpen}
      placement="bottom-start"
      role="select-menu"
    >
      <SelectPopperMenu component="div" square width={menuWidth} {...menuProps}>
        {options.map((item, index: number) => {
          const itemProps = getItemProps({
            index,
            isSelected: isOptionSelected(item, selectedItem),
            item,
          });
          const isFocused = highlightedIndex === index;
          const itemLabel = isSelectType<Option>(item) ? item.label : item;
          const itemKey = isSelectType<Option>(item)
            ? `${item.label}-${item.value}`
            : item;

          return (
            <SelectOption {...itemProps} isFocused={isFocused} key={itemKey}>
              {itemLabel}
            </SelectOption>
          );
        })}
      </SelectPopperMenu>
    </SelectPopper>
  );
};
