import ChangeLogItem from 'ProjectProfile/ChangeLog/ChangeLogItem/ChangeLogItem';
import {
  Header,
  Layout,
  Title,
} from 'ProjectProfile/common/components/Layout/Layout';

import { useChangeLog } from './changeLog.hooks';

const ChangeLog = () => {
  const { projectPlanChangeLog } = useChangeLog();

  return (
    <Layout>
      <Header>
        <Title>Change Log</Title>
      </Header>

      {projectPlanChangeLog &&
        projectPlanChangeLog.map((change) => (
          <ChangeLogItem
            changes={change}
            key={`${change.date.toISOString()}-${change.type}-${
              change.changes[0].id
            }`}
          />
        ))}
    </Layout>
  );
};

export default ChangeLog;
