import { useParams } from 'react-router-dom';

import { QueryParams } from 'ProjectProfile/common/types/queryParams';
import { useProjectPlanLinksUpdate } from 'ProjectProfile/common/hooks/useProjectPlanLinksUpdate';
import { useProjectPlanLinks } from 'ProjectProfile/common/hooks/useProjectPlanLinks';

import { ProjectPlanLinksInput } from 'ProjectProfile/PreDesign/Links/links.types';

export const useSiteSurveyLinksEdit = () => {
  const { projectId } = useParams<QueryParams>();
  const { handleSubmit } = useProjectPlanLinksUpdate();
  const { projectPlanLinks } = useProjectPlanLinks({
    projectId,
  });

  const initialValues = {
    siteSurveyDrawings: projectPlanLinks.siteSurveyDrawings || '',
    siteSurveyPhotos: projectPlanLinks.siteSurveyPhotos || '',
  };

  const submit = (values: ProjectPlanLinksInput, close: () => void) =>
    handleSubmit({
      onSuccessCallback: close,
      values,
    });

  return {
    initialValues,
    submit,
  };
};
