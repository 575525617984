import { FormTextField } from 'common/components/Form/TextField/FormTextField';

import { ReactComponent as TrashIcon } from 'common/assets/icons/delete-small.svg';

import { SkuUnit } from 'common/apollo/enums/SkuUnit';

import { pluralize } from 'common/utils/pluralize';

import {
  Name,
  Title,
  Wrapper,
} from 'Project/Procurement/EditOrder/CreateShipment/ShipmentItem/shipmentItem.styles';

type Props = {
  boxCount: number;
  index: number;
  name: string;
  onRemove: () => void;
  unitType?: SkuUnit;
  unitsAvailable: number;
};

export const ShipmentItem = ({
  boxCount,
  index,
  name,
  onRemove,
  unitType,
  unitsAvailable,
}: Props) => (
  <Wrapper>
    <Title>
      {unitType === SkuUnit.SQ_FOOT
        ? `${unitsAvailable}SF / ${boxCount} ${pluralize('box', boxCount)} `
        : `${unitsAvailable} ${pluralize('unit', unitsAvailable)} `}
      Available
      <TrashIcon onClick={onRemove} />
    </Title>

    <Name>{name}</Name>

    <FormTextField
      inputProps={{
        min: 1,
        step: 1,
      }}
      label="Units In Shipment"
      name={`items[${index}].quantity`}
      type="number"
    />
  </Wrapper>
);
