import styled from '@emotion/styled';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
  width: 100%;
`;

export const DateLabel = styled.div`
  font: 400 14px/20px ${({ theme }) => theme.font.inter};
  margin-right: 16px;
`;
