import { Dialog, DialogTitle } from '@material-ui/core';

import { Form, Formik } from 'formik';

import { Button } from 'common/components/Buttons/Button/Button';
import { FormSelect } from 'common/components/Form/Select/FormSelect';
import { ModalCloseButton } from 'common/components/Modals/ModalCloseButton';

import { FormTextArea } from 'common/_components/Form/FormTextarea/FormTextarea';

import { TextFieldSize } from 'common/enums/TextField';
import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

import { MultiCheckbox } from 'common/shared/Question/Checkbox/MultiCheckbox/MultiCheckbox';

import {
  DialogActions,
  DialogContent,
} from 'ProjectProfile/common/components/EditForm/editForm.styles';
import { BuildingInformationAnswers } from 'ProjectProfile/BuildingInformation/BuildingInfo/buildingInfo.types';
import { FlagToggle } from 'ProjectProfile/common/components/FlagToggle/FlagToggle';
import { UpdateProjectPlanQuestionsResponse } from 'ProjectProfile/common/responses/updateProjectPlanQuestionsResponse';

import { useBuildingInfoEdit } from './buildingInfoEdit.hooks';
import { validationSchema } from './buildingInfoEdit.consts';

import {
  FieldsContainer,
  FormCheckboxWrapper,
} from './buildingInfoEdit.styles';

type Props = {
  handleSuccessfulSubmit: (data: UpdateProjectPlanQuestionsResponse) => void;
  isOpen: boolean;
  onClose: () => void;
};

export const BuildingInfoEdit = ({
  handleSuccessfulSubmit,
  isOpen = false,
  onClose,
}: Props) => {
  const { importances, initialValues, options, placeholders, submit } =
    useBuildingInfoEdit();

  const handleSubmit = (values: BuildingInformationAnswers) =>
    submit(values, importances, handleSuccessfulSubmit);

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen}>
      <ModalCloseButton onClick={onClose} />
      <DialogTitle>Building Information</DialogTitle>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogContent scrollable>
              <FieldsContainer>
                <FormTextArea
                  disabled={isSubmitting}
                  id={PropertyGroupIdentifier.BUILDING_INFORMATION_NOTES}
                  label="Building Information Notes"
                  name={PropertyGroupIdentifier.BUILDING_INFORMATION_NOTES}
                  placeholder={placeholders.BUILDING_INFORMATION_NOTES}
                  rows={5}
                />
                <FlagToggle
                  disabled={isSubmitting}
                  isOn={importances.BUILDING_INFORMATION_NOTES.isOn}
                  onToggle={importances.BUILDING_INFORMATION_NOTES.toggle}
                />
                <FormCheckboxWrapper>
                  <MultiCheckbox
                    id={PropertyGroupIdentifier.INSURANCE}
                    isDisabled={isSubmitting}
                    isRequired={false}
                    label="Insurance Requirements"
                    name={PropertyGroupIdentifier.INSURANCE}
                    options={options.INSURANCE}
                  />
                </FormCheckboxWrapper>
                <FormSelect
                  id={PropertyGroupIdentifier.ALTERATION_AGREEMENT_REQUIRED}
                  isDisabled={isSubmitting}
                  name={PropertyGroupIdentifier.ALTERATION_AGREEMENT_REQUIRED}
                  options={options.ALTERATION_AGREEMENT_REQUIRED}
                  placeholder="Alteration Agreement"
                  size={TextFieldSize.EXTRA_LARGE}
                />
                <FormTextArea
                  disabled={isSubmitting}
                  id={PropertyGroupIdentifier.ALTERATION_AGREEMENT_HIGHLIGHTS}
                  label="Alteration Agreement Highlights"
                  name={PropertyGroupIdentifier.ALTERATION_AGREEMENT_HIGHLIGHTS}
                  placeholder={placeholders.ALTERATION_AGREEMENT_HIGHLIGHTS}
                  rows={5}
                />
                <FlagToggle
                  disabled={isSubmitting}
                  isOn={importances.ALTERATION_AGREEMENT_HIGHLIGHTS.isOn}
                  onToggle={importances.ALTERATION_AGREEMENT_HIGHLIGHTS.toggle}
                />
                <FormTextArea
                  disabled={isSubmitting}
                  id={PropertyGroupIdentifier.DESIGN_OPS_NOTES}
                  label="Notes from Project Planner to Design Ops"
                  name={PropertyGroupIdentifier.DESIGN_OPS_NOTES}
                  placeholder={placeholders.DESIGN_OPS_NOTES}
                  rows={5}
                />
                <FlagToggle
                  disabled={isSubmitting}
                  isOn={importances.DESIGN_OPS_NOTES.isOn}
                  onToggle={importances.DESIGN_OPS_NOTES.toggle}
                />
              </FieldsContainer>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
