import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
} from 'react';

import { useLayout } from 'common/components/Layout/layoutProvider.hooks';

type LayoutInfoState = {
  isSidebarOpen: boolean;
  isSidebarPresent: boolean;

  isSubSidebarPresent: boolean;
  pathname: string;
};

type LayoutActionableState = {
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  setSidebarPresent: Dispatch<SetStateAction<boolean>>;
  setSubSidebarPresent: Dispatch<SetStateAction<boolean>>;
};

export const LayoutInfoContext = createContext<LayoutInfoState>({
  isSidebarOpen: false,
  isSidebarPresent: true,
  isSubSidebarPresent: true,
  pathname: '',
});

export const LayoutActionableContext = createContext<LayoutActionableState>({
  setSidebarOpen: () => {},
  setSidebarPresent: () => {},
  setSubSidebarPresent: () => {},
});

type Props = {
  children: ReactNode;
};

export const LayoutProvider = ({ children }: Props) => {
  const {
    isSidebarOpen,
    isSidebarPresent,
    isSubSidebarPresent,
    pathname,
    setSidebarOpen,
    setSidebarPresent,
    setSubSidebarPresent,
  } = useLayout();

  const actionableValue = {
    setSidebarOpen,
    setSidebarPresent,
    setSubSidebarPresent,
  };

  const infoValue = useMemo(
    () => ({
      isSidebarOpen,
      isSidebarPresent,
      isSubSidebarPresent,
      pathname,
    }),
    [isSidebarOpen, isSidebarPresent, isSubSidebarPresent, pathname],
  );

  return (
    <LayoutInfoContext.Provider value={infoValue}>
      <LayoutActionableContext.Provider value={actionableValue}>
        {children}
      </LayoutActionableContext.Provider>
    </LayoutInfoContext.Provider>
  );
};
