import { gql } from '@apollo/client';

export const FILE_PRESIGN = gql`
  mutation PrepareFileUpload($input: PrepareFileInput!) {
    prepareFileUpload(input: $input) {
      contentType
      fileUuid
      fileUrl
      originalName
      presignedUrl
      presignedFields {
        key
        value
      }
    }
  }
`;
