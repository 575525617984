import { useParams } from 'react-router-dom';

import { useProjectPlanRoomsLinksUpdate } from 'ProjectProfile/common/hooks/useProjectPlanRoomsLinksUpdate';
import { useProjectPlanRooms } from 'ProjectProfile/common/hooks/useProjectPlanRooms';
import { useProjectPlanRoomsLinks } from 'ProjectProfile/common/hooks/useProjectPlanRoomsLinks';

import { emptyLink } from './canvasLinks.consts';

type Link = {
  name: string;
  pricingEngineId: string;
  url: string;
};

export const useCanvasLinksEdit = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { handleSubmit } = useProjectPlanRoomsLinksUpdate();

  const { data } = useProjectPlanRooms({ projectId });

  const pricingEngineIds = (data || []).map(({ id }) => String(id));

  const rooms = (data || []).reduce(
    (acc: { [key: string]: string }, { id, name }) => ({
      ...acc,
      [id]: name,
    }),
    {},
  );

  const { projectPlanRoomsLinks } = useProjectPlanRoomsLinks({
    pricingEngineIds,
  });

  const planRooms = (data || []).map((room) => ({
    label: room.name,
    value: room.id,
  }));

  const planRoomsLinks = (projectPlanRoomsLinks! || [])
    .map(({ canvasLinks, room }) =>
      (canvasLinks || []).map(({ url }) => ({
        name: room.name,
        pricingEngineId: room.id,
        url,
      })),
    )
    .flat();

  const submit = async (
    values: { projectPlanRoomsLinks: Link[] },
    close: () => void,
  ) => {
    const formValues = pricingEngineIds.map((pricingEngineId) => ({
      canvasLinks: (values.projectPlanRoomsLinks || [])
        .filter(({ pricingEngineId: id }) => id === pricingEngineId)
        .map(({ url }) => ({ name: rooms[pricingEngineId], url })),
      pricingEngineId,
    }));

    await handleSubmit({
      onSuccessCallback: close,
      pricingEngineIds,
      values: formValues,
    });
  };

  const initialValues = {
    projectPlanRoomsLinks: planRoomsLinks || [emptyLink],
  };

  return {
    emptyLink,
    initialValues,
    selectOptions: planRooms,
    submit,
  };
};
