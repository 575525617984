import { SelectType } from 'common/types/select';
import { SimpleTypeOrNull } from 'common/types/primitive';
import { isSelectType } from 'common/guards/select';

import { CurrencyField } from 'common/components/Form/CurrencyField/CurrencyField';
import {
  FormCurrencyField,
  Props as CurrencyFieldProps,
} from 'common/components/Form/CurrencyField/FormCurrencyField';

import { TextField } from 'common/components/Form/TextField/TextField';
import { Select } from 'common/components/Form/Select/Select';

import {
  FormSelect,
  Props as SelectProps,
} from 'common/components/Form/Select/FormSelect';
import {
  FormTextField,
  Props as TextFieldProps,
} from 'common/components/Form/TextField/FormTextField';

import {
  FormDatePicker,
  Props as DatePickerProps,
} from 'common/components/Form/DatePicker/FormDatePicker';
import { DatePicker } from 'common/components/Form/DatePicker/DatePicker';

import { pluralize } from './pluralize';
import { dateToUSFormat } from './date';
import { floatToCurrencyString } from './currencyFormatter';

const UNKNOWN = 'unknown';

const placeholderProps = {
  isDisabled: true,
  onChange: () => {},
};

type TextFieldConfigType = {
  componentProps: Partial<TextFieldProps>;
  name: string;
};

type CurrncyFieldConfigType = {
  componentProps: Partial<CurrencyFieldProps>;
  name: string;
};

type SelectConfigType<T extends SimpleTypeOrNull> = {
  componentProps: Partial<SelectProps<T>>;
  name: string;
  options: Array<SelectType<T> | T>;
};

type DatePickerConfigType = {
  componentProps: Partial<DatePickerProps>;
  name: string;
};

export const getUnitPerQuantity = <T extends string>(
  unitsAll: Array<SelectType<T>>,
  unitType: T,
  unitQuantity: number,
) => {
  const { label = UNKNOWN } =
    unitsAll.find(({ value }) => value === unitType) || {};
  return pluralize(label, unitQuantity);
};

const getTextFieldConfig = <T extends string | number>({
  componentProps,
  name,
}: TextFieldConfigType) => ({
  component: FormTextField,
  componentProps,
  name,
  render: ({ isEditMode, value }: { isEditMode: boolean; value: T | null }) =>
    !isEditMode && (
      <TextField {...placeholderProps} {...componentProps} value={value} />
    ),
});

const getTextFieldToTextConfig = <T extends string | number>({
  componentProps,
  name,
}: TextFieldConfigType) => ({
  component: FormTextField,
  componentProps,
  name,
  render: ({ isEditMode, value }: { isEditMode: boolean; value: T | null }) =>
    !isEditMode && value,
});

const getSelectConfig = <T extends SimpleTypeOrNull>({
  componentProps,
  name,
  options,
}: SelectConfigType<T>) => ({
  component: FormSelect,
  componentProps,
  name,
  options,
  render: ({ isEditMode, value }: { isEditMode: boolean; value?: T | null }) =>
    !isEditMode && (
      <Select
        {...placeholderProps}
        {...componentProps}
        options={options}
        value={value as T}
      />
    ),
});

const getSelectToTextConfig = <T extends SimpleTypeOrNull>({
  componentProps,
  name,
  options,
}: SelectConfigType<T>) => ({
  component: FormSelect,
  componentProps,
  name,
  options,
  render: ({
    isEditMode,
    value,
  }: {
    isEditMode: boolean;
    value?: T | null;
  }) => {
    if (isEditMode) return;

    const selectedOption =
      options.length &&
      options.find((option) =>
        isSelectType(option) ? option.value === value : option === value,
      );

    if (selectedOption) {
      return isSelectType(selectedOption)
        ? selectedOption.label
        : selectedOption;
    }

    return value;
  },
});

const getCurrencyFieldConfig = ({
  componentProps,
  name,
}: CurrncyFieldConfigType) => ({
  component: FormCurrencyField,
  componentProps,
  name,
  render: ({ isEditMode, value }: { isEditMode: boolean; value: number }) =>
    !isEditMode && (
      <CurrencyField {...placeholderProps} {...componentProps} value={value} />
    ),
});

const getCurrencyFieldToTextConfig = ({
  componentProps,
  name,
}: CurrncyFieldConfigType) => ({
  component: FormCurrencyField,
  componentProps,
  name,
  render: ({ isEditMode, value }: { isEditMode: boolean; value: number }) =>
    !isEditMode && floatToCurrencyString(value),
});

const getDatePickerConfig = ({
  componentProps,
  name,
}: DatePickerConfigType) => ({
  component: FormDatePicker,
  componentProps,
  name,
  render: ({ isEditMode, value }: { isEditMode: boolean; value: string }) =>
    !isEditMode && (
      <DatePicker {...placeholderProps} {...componentProps} value={value} />
    ),
});

const getDatePickerToTextConfig = ({
  componentProps,
  name,
}: DatePickerConfigType) => ({
  component: FormDatePicker,
  componentProps,
  name,
  render: ({
    isEditMode,
    value,
  }: {
    isEditMode: boolean;
    value: Date | string;
  }) => !isEditMode && dateToUSFormat(value),
});

export const configPerInput = {
  currencyField: getCurrencyFieldConfig,
  currencyFieldToText: getCurrencyFieldToTextConfig,
  datePicker: getDatePickerConfig,
  datePickerToText: getDatePickerToTextConfig,
  select: getSelectConfig,
  selectToText: getSelectToTextConfig,
  textField: getTextFieldConfig,
  textFieldToText: getTextFieldToTextConfig,
};
