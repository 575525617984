import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

export const identifiers = [
  PropertyGroupIdentifier.ALTERATION_AGREEMENT_HIGHLIGHTS,
  PropertyGroupIdentifier.BEFORE_DESIGN,
  PropertyGroupIdentifier.BUILDING_INFORMATION_NOTES,
  PropertyGroupIdentifier.CLIENT_NOTES,
  PropertyGroupIdentifier.IMPORTANT_PROJECT_CALL_OUTS,
  PropertyGroupIdentifier.LIST_OF_ALL_TO_VIF,
  PropertyGroupIdentifier.NEEDS_VS_NICE_TO_HAVES,
  PropertyGroupIdentifier.POST_SITE_SURVEY_NOTES,
  PropertyGroupIdentifier.PRICING_UPDATES_NOTES,
  PropertyGroupIdentifier.PROJECT_EXTENSION_NOTES,
  PropertyGroupIdentifier.DESIGN_OPS_NOTES,
];
