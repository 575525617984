import { object, string } from 'yup';

import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

export const validationSchema = object().shape({
  [PropertyGroupIdentifier.BEFORE_DESIGN]: string(),
  [PropertyGroupIdentifier.CONCEPT_BOARD_NOTES]: string(),
  [PropertyGroupIdentifier.MATERIAL_PRICE_NOTES]: string(),
  [PropertyGroupIdentifier.NEEDS_VS_NICE_TO_HAVES]: string(),
});
