import { gql } from '@apollo/client';

export const UPDATE_PROJECT_MATERIAL_NOTES = gql`
  mutation ProjectMaterialUpdate($input: ProjectMaterialUpdateInput!) {
    projectMaterialUpdate(input: $input) {
      id
      notes
    }
  }
`;
