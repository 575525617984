import { KeyDateChangeEvent } from 'common/apollo/models/keyDateChangeEvent';
import { KeyDateSource } from 'common/apollo/enums/KeyDateSource';

export const filterRecentHistory = (dataList: KeyDateChangeEvent[]) => {
  const latestKeyEvents = dataList.filter(
    (entry) => entry.source === KeyDateSource.LATEST,
  );

  let mostRecent: KeyDateChangeEvent | undefined;
  if (latestKeyEvents.length !== 0)
    mostRecent = latestKeyEvents.reduce((a, b) =>
      a.updatedAt > b.updatedAt ? a : b,
    );

  const originalDate = dataList.find(
    (keyDate) => keyDate.source === KeyDateSource.ORIGINAL,
  );

  const recentHistory = [mostRecent, originalDate];

  return recentHistory;
};
