import { gql } from '@apollo/client';

export const UPDATE_PROJECT_PROCUREMENT_DELIVERY_BATCHES = gql`
  mutation DeliveryBatchesUpdate($input: DeliveryBatchUpdateInput!) {
    deliveryBatchesUpdate(input: $input) {
      id
      eta
      inventoryUnits
      pallet
      confirmedAt
      shippedAt
      receivedAt
      units
    }
  }
`;
