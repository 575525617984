import { useQuery } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';

import { GetProjectPlanBuildingInfoRequest } from 'ProjectProfile/common/requests/getProjectPlanBuildingInfoRequest';
import { GetProjectPlanBuildingInfoResponse } from 'ProjectProfile/common/responses/getProjectPlanBuildingInfoResponse';

import { GET_PROJECT_BUILDING_INFO } from 'ProjectProfile/common/apollo/gql/getProjectPlanBuildingInfo';
import { ProjectBuildingInfo } from 'common/apollo/models/projectBuildingInfo';

type Props = {
  projectId: string;
};

export const useGetProjectPlanPropertyInfo = ({ projectId }: Props) => {
  const { onError } = useAlert();
  const { data, loading: isLoading } = useQuery<
    GetProjectPlanBuildingInfoResponse,
    GetProjectPlanBuildingInfoRequest
  >(GET_PROJECT_BUILDING_INFO, {
    onError: (error) => onError(error.message),
    variables: {
      input: {
        projectId,
      },
    },
  });

  const projectBuildingInfo = {
    constructionInfo: {},
    hoa: {},
    id: 0,
    propertyInfo: {},
  };

  return {
    data: (data?.projectBuildingInfo ||
      projectBuildingInfo) as ProjectBuildingInfo,
    isLoading,
  };
};
