import { datadogRum } from '@datadog/browser-rum';

export const initDDRum = () => {
  datadogRum.init({
    allowedTracingUrls: [
      'https://api-dev.blockrenovation.com/*',
      'https://api-rc.blockrenovation.com/*',
      'https://api.blockrenovation.com/*',
    ],
    applicationId: '505c1b9d-a865-41ca-93c3-379b0b7fb53f',
    clientToken: 'pub3cf820e4fa8684a32a9b18b2ab17d52e',
    enableExperimentalFeatures: ['feature_flags'],
    env: process.env.REACT_APP_ENVIRONMENT,
    service: 'block-admin',
    sessionReplaySampleRate: 100,
    sessionSampleRate: 100,
    site: 'datadoghq.com',
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    version: process.env.REACT_APP_VERSION,
  });
};
