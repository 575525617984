import { useProjectInformationQuestion } from 'ProjectProfile/ProjectInformation/v2/ProjectInformationQuestion/projectInformationQuestion.hooks';
import { Question as QuestionModel } from 'common/apollo/models/question';
import { Question } from 'common/shared/Question/Question';

type Props = {
  question: QuestionModel;
};

export const ProjectInformationQuestion = ({ question }: Props) => {
  const { handleChange } = useProjectInformationQuestion(question);

  return (
    <Question
      {...question}
      label={question.required ? `${question.label}*` : question.label}
      name={`questions.${question.id}`}
      onChange={handleChange}
      requireTouched={false}
    />
  );
};
