export type ProposalRouteParams = {
  proposalId: string;
  renovationPlanId: string;
  userId: string;
};

export const PROPOSAL_ROOT_ID_SECTIONS = {
  discounts: 'discountsSection',
  paymentSchedule: 'paymentSheduleSection',
  permits: 'permitsSection',
  timeline: 'timelineSection',
};

const getProposalsPath = ({
  renovationPlanId = ':renovationPlanId',
  userId = ':userId',
} = {}) => `/users/${userId}/renovation-plans/${renovationPlanId}/proposals`;

const getProposalDetailsPath = ({
  proposalId = ':proposalId',
  renovationPlanId = ':renovationPlanId',
  userId = ':userId',
} = {}) =>
  `/users/${userId}/renovation-plans/${renovationPlanId}/proposals/${proposalId}`;

const getProposalDetailsPermitsPath = ({
  proposalId = ':proposalId',
  renovationPlanId = ':renovationPlanId',
  userId = ':userId',
} = {}) =>
  `/users/${userId}/renovation-plans/${renovationPlanId}/proposals/${proposalId}#${PROPOSAL_ROOT_ID_SECTIONS.permits}`;

const getProposalDetailsTimelinePath = ({
  proposalId = ':proposalId',
  renovationPlanId = ':renovationPlanId',
  userId = ':userId',
} = {}) =>
  `/users/${userId}/renovation-plans/${renovationPlanId}/proposals/${proposalId}#${PROPOSAL_ROOT_ID_SECTIONS.timeline}`;

const getProposalDetailsPaymentSchedulePath = ({
  proposalId = ':proposalId',
  renovationPlanId = ':renovationPlanId',
  userId = ':userId',
} = {}) =>
  `/users/${userId}/renovation-plans/${renovationPlanId}/proposals/${proposalId}#${PROPOSAL_ROOT_ID_SECTIONS.paymentSchedule}`;

const getProposalDetailsDiscountPath = ({
  proposalId = ':proposalId',
  renovationPlanId = ':renovationPlanId',
  userId = ':userId',
} = {}) =>
  `/users/${userId}/renovation-plans/${renovationPlanId}/proposals/${proposalId}#${PROPOSAL_ROOT_ID_SECTIONS.discounts}`;

const getProposalScopeOfWorkPath = ({
  proposalId = ':proposalId',
  renovationPlanId = ':renovationPlanId',
  userId = ':userId',
} = {}) =>
  `/users/${userId}/renovation-plans/${renovationPlanId}/proposals/${proposalId}/scope-of-work`;

export const PROPOSAL_PATHS = {
  getProposalDetailsDiscountPath,
  getProposalDetailsPath,
  getProposalDetailsPaymentSchedulePath,
  getProposalDetailsPermitsPath,
  getProposalDetailsTimelinePath,
  getProposalScopeOfWorkPath,
  getProposalsPath,
};
