import { GetAdminKeyDatesResponse } from 'common/apollo/responses/getAdminKeyDatesResponse';
import { KeyDateType } from 'common/apollo/enums/KeyDateType';

import { MaterialsLongestLeadTimeFormVariant } from 'MaterialsLongestLeadTime/materialsLongestLeadTime.consts';
import { KeyDateSource } from 'common/apollo/enums/KeyDateSource';
import { TaskIdentifier } from 'common/apollo/enums/TaskIdentifier';
import { ClientPhase } from 'common/types/clientTasks.types';

export const getMaterialsLongestLeadTime = (
  data: GetAdminKeyDatesResponse | undefined,
): string => {
  if (!data) return '';

  const date = data?.adminKeyDates?.find(
    (keyDate) =>
      keyDate.type === KeyDateType.LONGEST_LEAD_TIME &&
      keyDate.source === KeyDateSource.LATEST,
  );

  return date?.value || '';
};

export const getMaterialsLongestLeadTimeFormVariant = (
  keyDates: GetAdminKeyDatesResponse | undefined,
  phasesList: ClientPhase[],
): MaterialsLongestLeadTimeFormVariant | undefined => {
  if (!keyDates) return;

  const tasksList = phasesList?.length
    ? phasesList.flatMap((phase) => phase.tasks)
    : [];

  const showComplete = tasksList.some(
    (task) =>
      (task.identifier === TaskIdentifier.PREPARE_COI ||
        task.identifier === TaskIdentifier.CONFIRM_APPROVAL_PACKAGE) &&
      task.isCompleted,
  );

  const isLLTcompleted = keyDates.adminKeyDates.some(
    (keyDate) =>
      keyDate.type === KeyDateType.LONGEST_LEAD_TIME &&
      keyDate.source === KeyDateSource.CONFIRMED,
  );

  const hasLLTdata = keyDates.adminKeyDates.some(
    (keyDate) => keyDate.type === KeyDateType.LONGEST_LEAD_TIME,
  );

  if (isLLTcompleted) {
    return;
  }

  if (hasLLTdata && showComplete) {
    return MaterialsLongestLeadTimeFormVariant.CONFIRM_LLT;
  }

  if (hasLLTdata) {
    return MaterialsLongestLeadTimeFormVariant.SHIFT_LLT;
  }

  return MaterialsLongestLeadTimeFormVariant.SET_LLT;
};
