import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ApolloError, useMutation } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';
import { AUTH_PATHS } from 'common/routes/routerPaths';

import { UPDATE_PROPOSAL_NAME } from './proposal.graphql';

import {
  ProposalNameUpdate,
  ProposalNameUpdateVariables,
} from './__generated__/proposal.graphql.generated';

import { ALERTS } from './proposal.consts';

const useUpdateProposal = (proposalId: string) => {
  const { onCompleted, onError } = useAlert();

  const [update, { loading }] = useMutation<
    ProposalNameUpdate,
    ProposalNameUpdateVariables
  >(UPDATE_PROPOSAL_NAME, {
    onCompleted: () => onCompleted(ALERTS.updateProposal),
    onError: (error: ApolloError) => onError(error.message),
  });

  const updateProposal = useCallback(
    (input: { name: string }) => {
      update({
        variables: {
          input: { proposalId, ...input },
        },
      });
    },
    [proposalId, update],
  );

  return { loading, updateProposal };
};

export const useProposal = () => {
  const { proposalId } = useParams<{ proposalId: string }>();
  const { push } = useHistory();

  const { updateProposal } = useUpdateProposal(proposalId);

  const updateProposalName = (name: string) => updateProposal({ name });

  const handleAddRoom = () => {
    push(AUTH_PATHS.getPricingEngineAddRoomPath(proposalId));
  };

  return {
    handleAddRoom,
    updateProposalName,
  };
};
