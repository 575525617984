import { gql } from '@apollo/client';

export const DELETE_PROJECT_PROCUREMENT_DELIVERY_BATCHES = gql`
  mutation DeliveryBatchesDelete($input: DeliveryBatchDeleteInput!) {
    deliveryBatchesDelete(input: $input) {
      id
      eta
      pallet
      confirmedAt
      shippedAt
      receivedAt
      units
    }
  }
`;
