import { gql } from '@apollo/client';

export const UPDATE_PROJECT_PLAN_QUESTIONS = gql`
  mutation UpdateProjectPlanQuestions(
    $input: ProjectPlanQuestionsUpdateInput!
  ) {
    projectPlanQuestionsUpdate(input: $input) {
      id
      answers {
        id
        label
        isSelected
        type
      }
      identifier
      isImportant
      responseText
    }
  }
`;
