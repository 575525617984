import { useCallback, useState } from 'react';

// Helper hook to handle sorting a table of T items
export const useTableSort = <T>() => {
  const [sortColumn, setSortColumn] = useState<{
    column: keyof T;
    direction: 'asc' | 'desc';
  } | null>(null);

  const sortItems = useCallback(
    (items: T[]) => {
      const sortedItems =
        sortColumn !== null
          ? items.sort((a, b) => {
              const valueA = String(a[sortColumn.column]);
              const valueB = String(b[sortColumn.column]);

              const asc = sortColumn.direction === 'asc';
              if (valueA < valueB) {
                return asc ? -1 : 1;
              }
              if (valueA > valueB) {
                return asc ? 1 : -1;
              }
              return 0;
            })
          : items;
      return sortedItems;
    },
    [sortColumn],
  );

  const handleToggleSort = (column: keyof T) => {
    if (sortColumn?.column === column && sortColumn.direction === 'desc') {
      setSortColumn(null);
    } else if (sortColumn?.column === column) {
      setSortColumn({ column, direction: 'desc' });
    } else {
      setSortColumn({ column, direction: 'asc' });
    }
  };

  return {
    handleToggleSort,
    sortColumn,
    sortItems,
  };
};
