import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { rgba } from 'common/utils/colors';

import { mq } from 'common/utils/mediaQueries';

export const Table = styled.table`
  border-bottom: 1px solid ${({ theme }) => rgba(theme.color.black, 0.12)};
  border-collapse: collapse;
  font: 400 14px/24px ${({ theme }) => theme.font.inter};
  letter-spacing: 0;
  text-align: left;
  width: 100%;

  th,
  td {
    padding: 16px;

    > * {
      vertical-align: middle;
    }
  }

  tr + tr {
    border-top: 1px solid ${({ theme }) => rgba(theme.color.black, 0.12)};
  }
`;

export const TableHead = styled.thead`
  background: ${({ theme }) => theme.color.concrete};
  border-bottom: 1px solid ${({ theme }) => rgba(theme.color.black, 0.12)};

  ${mq.large(css`
    white-space: nowrap;
  `)};

  th {
    font-weight: 500;
  }
`;
