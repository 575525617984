export enum OrderReason {
  CLIENT_REQUEST_MATERIALS_CHANGE = 'CLIENT_REQUEST_MATERIALS_CHANGE',
  CONTRACTOR_ERROR = 'CONTRACTOR_ERROR',
  DESIGN_ERROR = 'DESIGN_ERROR',
  FINANCE_ERROR = 'FINANCE_ERROR',
  PROCUREMENT_ERROR = 'PROCUREMENT_ERROR',
  PROJECT_OPS_ERROR = 'PROJECT_OPS_ERROR',
  P_A_ERROR = 'P_A_ERROR',
  SALES_ERROR = 'SALES_ERROR',
  SITE_SURVEY_ERROR = 'SITE_SURVEY_ERROR',
  STANDARD = 'STANDARD',
  VENDOR_ERROR = 'VENDOR_ERROR',
  VIF = 'VIF',
}
