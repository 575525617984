import { useCallback, useRef, useMemo, FocusEvent, KeyboardEvent } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import { TAB_KEY } from 'common/consts/keyboard';

import { TextFieldVariant } from 'common/enums/TextField';
import {
  IconWrapper,
  SelectedTag,
  TagLabel,
  TagSearchField,
  TagsWrapper,
  Wrapper,
} from 'common/components/Form/Select/components/selectMultipleControl.styles';

import { SelectType } from 'common/types/select';
import { isSelectType } from 'common/guards/select';

import { Props } from 'common/components/Form/Select/components/SelectControl';
import { isSelectMultipleType } from 'common/guards/selectMultiple';

export const SelectMultipleControl = <Option,>({
  InputProps,
  downshift,
  errorMessage,
  id,
  inputRef: _inputRef,
  isClearable,
  isDisabled,
  isReadOnly = true,
  isRequired,
  label,
  name,
  onBlur,
  onFocus,
  onKeyUp,
  onSelectControlChange,
  openMenuOnFocus,
  options,
  placeholder,
  selectRef,
  ...props
}: Props<Option>) => {
  const defaultInputRef = useRef<HTMLInputElement>();
  const inputRef = _inputRef || defaultInputRef;

  const {
    getInputProps,
    getLabelProps,
    inputValue,
    isOpen,
    openMenu,
    selectHighlightedItem,
    selectItem,
  } = downshift;

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (openMenuOnFocus && !isOpen) openMenu();
      onFocus?.(event);
    },
    [isOpen, openMenu, openMenuOnFocus, onFocus],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const keyCode = event.keyCode || event.which;

      if (keyCode !== TAB_KEY) return;
      selectHighlightedItem();
      InputProps?.onKeyDown?.(event);
    },
    [selectHighlightedItem, InputProps],
  );

  const handleClick = useCallback(() => {
    if (!isOpen) openMenu();
  }, [isOpen, openMenu]);

  const value = isReadOnly ? inputValue : undefined;
  const inputProps = useMemo(
    () =>
      getInputProps({
        ...(id && { id }),
        ...(!isDisabled && {
          onBlur,
          onClick: handleClick,
          onFocus: handleFocus,
          onKeyDown: handleKeyDown,
        }),
        ...(onSelectControlChange && { onChange: onSelectControlChange }),
        disableUnderline: true,
        disabled: isDisabled,
        name,
        placeholder,
        readOnly: isReadOnly,
        ref: null,
        ...(InputProps?.color && { color: InputProps.color }),
        ...(isReadOnly && { value }),
        ...InputProps,
      }),
    [
      getInputProps,
      id,
      isDisabled,
      onBlur,
      handleClick,
      handleFocus,
      handleKeyDown,
      onSelectControlChange,
      name,
      placeholder,
      isReadOnly,
      InputProps,
      value,
    ],
  );

  const labelProps = useMemo(
    () =>
      getLabelProps({
        disabled: isDisabled,
        ...(id && { htmlFor: id }),
      }),
    [getLabelProps, isDisabled, id],
  );

  const itemToString = (item: Option | SelectType<Option> | null): string => {
    if (item === null) {
      return '';
    }
    return isSelectType(item) ? item.label : '';
  };

  const renderTag = (tag: Option | SelectType<Option>) => (
    <SelectedTag
      key={itemToString(tag)}
      onClick={() => {
        // Toggle selected item to clear it and then clear selection input
        selectItem(tag);
      }}
    >
      <TagLabel>{itemToString(tag)}</TagLabel>
      <CloseIcon />
    </SelectedTag>
  );

  return (
    <Wrapper className={isOpen ? 'active' : undefined} ref={selectRef}>
      <IconWrapper>
        <SearchIcon color="disabled" />
      </IconWrapper>
      <TagsWrapper>
        {options
          ?.filter((option) => isSelectMultipleType(option) && option.selected)
          .map((option) => renderTag(option))}
      </TagsWrapper>
      <TagSearchField
        InputLabelProps={labelProps}
        InputProps={inputProps}
        errorMessage={errorMessage}
        inputRef={inputRef}
        isDisabled={isDisabled}
        isRequired={isRequired}
        label={label}
        variant={TextFieldVariant.STANDARD}
        {...props}
      />
    </Wrapper>
  );
};
