import { Formik } from 'formik';

import { SkuVendorStockStatus } from 'common/apollo/enums/SkuVendorStockStatus';

import { FormTextField } from 'common/components/Form/TextField/FormTextField';
import { FormSelect } from 'common/components/Form/Select/FormSelect';
import { FormDatePicker } from 'common/components/Form/DatePicker/FormDatePicker';
import { CloseButton } from 'common/components/Form/CloseButton/CloseButton';

import { Button } from 'common/components/Button/Button';

import { STOCK_STATUS_OPTIONS } from 'common/consts/stockStatus';

import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';

import { useStockStatusModal } from './stockStatusModal.hooks';

import {
  ClearSelectionButton,
  Content,
  Footer,
  Header,
  SubHeader,
  Subtitle,
  Wrapper,
} from './stockStatusModal.styles';

import { validationSchema } from './stockStatusModal.consts';

type Props = { projectMaterialId?: string };

export const StockStatusModal = ({ projectMaterialId }: Props) => {
  const { handleClose, handleSubmit, initialValues, isLoading, material } =
    useStockStatusModal(projectMaterialId);

  return (
    <Wrapper>
      {isLoading ? (
        <LoadingSpinnerWithContainer />
      ) : (
        <>
          <Header>
            Update Stock Status <CloseButton onClick={handleClose} />
          </Header>
          <SubHeader>
            <Subtitle>
              Last Updated: {material?.sku.vendor.leadTimeUpdatedAt || 'N/A'}
            </Subtitle>
            <Subtitle>Product: {material?.sku.name}</Subtitle>
            <Subtitle>Vendor: {material?.sku.vendor.vendor.name}</Subtitle>
          </SubHeader>

          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ dirty, isSubmitting, setFieldValue, submitForm, values }) => (
              <>
                <Content>
                  <FormSelect
                    id="stockStatus"
                    label="Stock Status"
                    name="stockStatus"
                    onChange={(status: SkuVendorStockStatus) => {
                      if (status === SkuVendorStockStatus.ON_BACK_ORDER) return;
                      if (status === SkuVendorStockStatus.DISCONTINUED) {
                        setFieldValue('minLeadTimeWeeks', '');
                        setFieldValue('maxLeadTimeWeeks', '');
                      }

                      setFieldValue('backInStockDate', null);
                    }}
                    options={STOCK_STATUS_OPTIONS}
                    placeholder="N/A"
                  />

                  <FormTextField
                    id="minLeadTimeWeeks"
                    inputProps={{
                      min: 1,
                      step: 1,
                    }}
                    label="Lead Time Min in Weeks"
                    name="minLeadTimeWeeks"
                    type="number"
                  />

                  <FormTextField
                    id="maxLeadTimeWeeks"
                    inputProps={{
                      min: 1,
                      step: 1,
                    }}
                    label="Lead Time Max in Weeks"
                    name="maxLeadTimeWeeks"
                    type="number"
                  />

                  <FormDatePicker
                    id="backInStockDate"
                    isDisabled={
                      values.stockStatus !== SkuVendorStockStatus.ON_BACK_ORDER
                    }
                    label="Restock Date"
                    name="backInStockDate"
                  />

                  <ClearSelectionButton
                    onClick={() => {
                      setFieldValue('minLeadTimeWeeks', '');
                      setFieldValue('maxLeadTimeWeeks', '');
                      setFieldValue('backInStockDate', null);
                      setFieldValue('stockStatus', '');
                    }}
                  >
                    Clear
                  </ClearSelectionButton>
                </Content>

                <Footer>
                  <Button aria-label="Cancel" onClick={handleClose} small>
                    Cancel
                  </Button>

                  <Button
                    aria-label="Update"
                    color="secondary"
                    disabled={!dirty || isSubmitting}
                    onClick={submitForm}
                    small
                    variant="contained"
                  >
                    Update Status
                  </Button>
                </Footer>
              </>
            )}
          </Formik>
        </>
      )}
    </Wrapper>
  );
};
