import { ProjectType } from 'common/apollo/enums/ProjectType';

export const projectTypeDictionary: Record<ProjectType, string> = {
  [ProjectType.CORE]: 'Core',
  [ProjectType.DIRECT]: 'Direct',
  [ProjectType.BLOCK_2_0]: '2.0',
};

export const projectTypeList = [
  {
    label: projectTypeDictionary[ProjectType.CORE],
    value: ProjectType.CORE,
  },
  {
    label: projectTypeDictionary[ProjectType.DIRECT],
    value: ProjectType.DIRECT,
  },
  {
    label: projectTypeDictionary[ProjectType.BLOCK_2_0],
    value: ProjectType.BLOCK_2_0,
  },
];
