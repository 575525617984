import { gql } from '@apollo/client';

export const CONFIRM_KEY_DATE = gql`
  mutation confirmKeyDate($input: KeyDateConfirmInput!) {
    keyDateConfirm(input: $input) {
      id
      value
    }
  }
`;
