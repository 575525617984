import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { rgba } from 'common/utils/colors';

import { mq } from 'common/utils/mediaQueries';

export const TableWrapper = styled.div`
  overflow: auto;
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-top: 1px solid ${({ theme }) => rgba(theme.color.black, 0.12)};
  font: 400 14px/24px ${({ theme }) => theme.font.inter};
  letter-spacing: 0.6px;
  text-align: left;
  width: 100%;

  th,
  td {
    padding: 16px;
  }

  tr + tr {
    border-top: 1px solid ${({ theme }) => rgba(theme.color.black, 0.12)};
  }

  thead {
    background: ${({ theme }) => theme.color.concrete};
    border-bottom: 1px solid ${({ theme }) => rgba(theme.color.black, 0.12)};

    ${mq.large(css`
      white-space: nowrap;
    `)};

    th {
      font-weight: 500;
    }
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const EmptyState = styled.p`
  align-items: center;
  background: ${({ theme }) => theme.color.concrete};
  border: 1px solid ${({ theme }) => theme.color.alto};
  display: flex;
  font: 400 16px/24px ${({ theme }) => theme.font.inter};
  justify-content: center;
  margin: 30px;
  min-height: 120px;
`;

export const SizeWrapper = styled.p`
  white-space: nowrap;
`;
