import { RefObject } from 'react';

import { ControllerStateAndHelpers } from 'downshift';

import { isSelectType } from 'common/guards/select';
import { SelectType } from 'common/types/select';
import { Checkbox } from 'common/components/Form/Checkboxes/Checkbox';
import { SelectMultipleType } from 'common/types/selectMultiple';
import { isSelectMultipleType } from 'common/guards/selectMultiple';

import { isOptionSelected } from '../select.utils';
import { SelectPopper, SelectPopperMenu } from '../Select.styles';

import { SelectOption } from './SelectOption';

type Props<Option> = {
  disablePortal?: boolean;
  downshift: ControllerStateAndHelpers<any>;
  options: Array<SelectMultipleType<Option> | SelectType<Option> | Option>;
  selectRef: RefObject<HTMLInputElement>;
};

export const SelectMultipleMenu = <Option,>({
  disablePortal = true,
  downshift,
  options,
  selectRef,
}: Props<Option>) => {
  const { getItemProps, getMenuProps, highlightedIndex, isOpen, selectedItem } =
    downshift;

  const menuProps = getMenuProps({}, { suppressRefError: true });

  return (
    <SelectPopper
      anchorEl={selectRef.current}
      className={isOpen ? 'active' : undefined}
      disablePortal={disablePortal}
      open={isOpen}
      placement="bottom-start"
      role="select-menu"
    >
      <SelectPopperMenu component="div" square {...menuProps}>
        {options.map((item, index: number) => {
          const itemProps = getItemProps({
            index,
            isSelected: isOptionSelected(item, selectedItem),
            item,
          });
          const isFocused = highlightedIndex === index;
          const itemSelected = isSelectMultipleType<Option>(item)
            ? item.selected
            : isFocused;

          const itemLabel = isSelectType<Option>(item) ? item.label : item;
          const itemKey = isSelectType<Option>(item)
            ? `${item.label}-${item.value}`
            : item;

          return (
            <SelectOption {...itemProps} isFocused={isFocused} key={itemKey}>
              <Checkbox checked={itemSelected} />
              {itemLabel}
            </SelectOption>
          );
        })}
      </SelectPopperMenu>
    </SelectPopper>
  );
};
