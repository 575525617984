import { css } from '@emotion/react';

import { THEME } from 'common/consts/theme';

export const datePickerStyles = css`
  width: 100%;
  input {
    padding: 16px;
    font: normal 16px/24px ${THEME.font.inter};
  }
`;
