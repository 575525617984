import { defaultOptionalPropertyGroupIdentifiers } from 'common/consts/defaultOptionalPropertyGroupIdentifiers';
import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';
import { ProjectPlanQuestions } from 'common/apollo/models/projectPlanQuestion';
import { ProjectPlanQuestionsUpdateQuestionsInput } from 'common/apollo/requests/updateProjectPlanQuestionsRequest';

import { Answers } from 'ProjectProfile/common/types/answer';
import { EmptyCopy } from 'ProjectProfile/projectProfile.consts';
import {
  Importances,
  ImportancesToggles,
} from 'ProjectProfile/common/types/importance';
import { useToggle } from 'common/hooks/useToggle';

export const findAnswer = (
  identifier: PropertyGroupIdentifier,
  questions: ProjectPlanQuestions[],
) => {
  const question = questions.find((answer) => answer.identifier === identifier);

  return (
    question?.responseText ||
    question?.answers.find(({ isSelected }) => isSelected)?.label ||
    ''
  );
};

export const findImportance = (
  identifier: PropertyGroupIdentifier,
  questions: ProjectPlanQuestions[],
) => {
  const question = questions.find((answer) => answer.identifier === identifier);

  return question?.isImportant || false;
};

export const getImportances = (
  identifiers: PropertyGroupIdentifier[],
  questions: ProjectPlanQuestions[],
): Importances => {
  const rtrn: Importances = {} as Importances;
  identifiers.forEach((identifier) => {
    rtrn[identifier] = findImportance(identifier, questions);
  });

  return rtrn;
};

export const getImportancesToggles = (
  identifiers: PropertyGroupIdentifier[],
  questions: ProjectPlanQuestions[],
): ImportancesToggles => {
  const rtrn: ImportancesToggles = {} as ImportancesToggles;

  identifiers.forEach((identifier) => {
    rtrn[identifier] = useToggle(findImportance(identifier, questions));
  });

  return rtrn;
};

export const answersToFormikInitialValues = (
  identifier: PropertyGroupIdentifier,
  questions: ProjectPlanQuestions[],
) => {
  const question = questions.find((answer) => answer.identifier === identifier);

  if (!question) return undefined;

  if (question.responseText) return question.responseText;

  const answers = question.answers
    .filter(({ isSelected }) => isSelected)
    .map(({ id }) => id);

  return answers.length <= 1 ? answers.join() : answers;
};

export const multiAnswersToFormikInitialValues = (
  identifier: PropertyGroupIdentifier,
  questions: ProjectPlanQuestions[],
) => {
  const question = questions.find((answer) => answer.identifier === identifier);

  if (!question) return [];

  return question.answers
    .filter(({ isSelected }) => isSelected)
    .map(({ id }) => id);
};

export const findAnswerId = (
  identifier: PropertyGroupIdentifier,
  questions: ProjectPlanQuestions[],
) => {
  const question = questions.find((answer) => answer.identifier === identifier);

  return question?.answers.find(({ isSelected }) => isSelected)?.id || '';
};

export const mapQuestionAnswer = (
  identifier: PropertyGroupIdentifier,
  questions: ProjectPlanQuestions[],
) => {
  const question = questions.find((answer) => answer.identifier === identifier);

  return question?.answers.map(({ id, label }) => ({
    label,
    value: question.responseText || id,
  }));
};

export const mapQuestionToPlaceholder = (
  identifier: PropertyGroupIdentifier,
  questions: ProjectPlanQuestions[],
) =>
  questions.find((answer) => answer.identifier === identifier)?.placeholder ||
  defaultOptionalPropertyGroupIdentifiers.includes(identifier)
    ? EmptyCopy.OPTIONAL_FIELD
    : EmptyCopy.REQUIRED_FIELD;

export const formikValuesToOpenQuestionsData = (
  answers: Answers,
  identifiers: PropertyGroupIdentifier[],
  importances?: ImportancesToggles,
) =>
  identifiers.map((identifier) => ({
    identifier,
    isImportant:
      (importances &&
        importances[identifier] &&
        importances[identifier].isOn) ||
      false,
    value: answers[identifier] || '',
  }));

const findAnswersIds = (
  identifier: PropertyGroupIdentifier,
  questions: ProjectPlanQuestions[],
  answers: Answers,
) => {
  const question = questions.find((answer) => answer.identifier === identifier);

  return (
    question?.answers
      ?.filter(({ id }) => id === answers[identifier])
      ?.map(({ id }) => id) || []
  );
};

export const formikValuesToQuestionsData = (
  answers: Answers,
  questions: ProjectPlanQuestions[],
  identifiers: PropertyGroupIdentifier[],
) =>
  identifiers.reduce((acc, identifier) => {
    const answerIds = findAnswersIds(identifier, questions, answers);

    acc.push({
      answerIds,
      identifier,
    });

    return acc;
  }, [] as ProjectPlanQuestionsUpdateQuestionsInput[]);
