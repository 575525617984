import * as React from 'react';

import { useCallback } from 'react';
import { ClassNames } from '@emotion/react';

import MuiDialog, { DialogProps } from '@material-ui/core/Dialog';

import {
  useIsModalOpen,
  useSetModalOpen,
} from 'common/hooks/ModalProvider/modal.hooks';

import { ModalCloseButton } from '../ModalCloseButton';

type DialogPropsLimited = Omit<DialogProps, 'open'>;

export type Props = DialogPropsLimited & {
  id: string;
  isClosable?: boolean;
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const Dialog = ({
  children,
  className,
  id,
  isClosable = true,
  onClose,
  ...rest
}: Props) => {
  const isOpen = useIsModalOpen(id);
  const setDialogOpen = useSetModalOpen();

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setDialogOpen(id, false);

      if (onClose) onClose(event);
    },
    [id, setDialogOpen, onClose],
  );

  return (
    <ClassNames>
      {({ css }) => (
        <MuiDialog
          classes={{ paper: css(className) }}
          onClose={handleClose}
          open={isOpen}
          {...rest}
        >
          {isClosable && <ModalCloseButton onClick={handleClose} />}
          {children}
        </MuiDialog>
      )}
    </ClassNames>
  );
};
