import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { rgba } from 'common/utils/colors';

import { THEME, DRAWER_WIDTH_OPEN } from 'common/consts/theme';

import { Link } from 'common/components/Navigation/Link/Link';

type WithVisibility = {
  isOpen?: boolean;
};

export const SidebarContentBase = styled.div<WithVisibility>`
  width: ${DRAWER_WIDTH_OPEN};
  display: flex;
  flex-direction: column;
  flex: 1;
  opacity: 1;
  transition: opacity 0.5s;
  overflow: auto;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      opacity: 0;
      pointer-events: none;
    `};

  @media print {
    display: none;
  }
`;

export const SidebarElement = styled.div`
  border-bottom: 1px solid ${({ theme }) => rgba(theme.color.black, 0.12)};
  display: grid;
  padding: 24px;
`;

export const sidebarContentListItemStyles = css`
  padding: 3px 0;

  &[disabled] {
    pointer-events: none;
  }

  ${Link} {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    font-family: ${THEME.font.roboto};
    text-transform: none;

    &.isActive {
      color: ${THEME.color.primaryOrange};
    }
  }
`;
