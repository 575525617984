import { REQUIRED_VALIDATION } from 'common/consts/validationMessages';
import { DualAnswer } from 'common/shared/Question/question.types';

export const validateField = (value: string | string[]) =>
  (Array.isArray(value) && !value.length) || !value
    ? REQUIRED_VALIDATION
    : undefined;

export const shouldValidateField = (required = true) =>
  required ? validateField : undefined;

export const shouldValidateDualFields = (required = true) =>
  required ? validateDualFields : undefined;

export const validateDualFields = (value: DualAnswer) =>
  !(value.desired && value.existing) ? REQUIRED_VALIDATION : undefined;

export const PHONE_NUMBER_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
