import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { mq } from 'common/utils/mediaQueries';

export const Header = styled.div`
  display: flex;

  svg {
    transition: all 0.3s ease;
  }

  &:hover svg {
    fill: ${({ theme }) => theme.color.black};
    transform: scale(1.1);
  }
`;

export const Inner = styled.div`
  margin-top: 32px;
`;

export const Root = styled.section<{ hasRoleLabel: boolean }>`
  background: ${({ theme }) => theme.color.sauvignon};
  border-radius: 10px;
  padding: 30px 20px;
  margin-bottom: 30px;
  position: relative;

  ${mq.large(css`
    padding: 30px;
  `)}

  > .MuiIconButton-root {
    position: absolute;
    top: ${({ hasRoleLabel }) => (hasRoleLabel ? '55px' : '20px')};
    right: 18px;

    ${mq.large(css`
      top: 16px;
    `)}
  }
`;

export const Title = styled.h2`
  text-transform: uppercase;
  font: normal 24px/32px ${({ theme }) => theme.font.inter};
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${mq.large(css`
    align-items: center;
    flex-direction: row;
  `)}
  margin-bottom: 10px;
`;
