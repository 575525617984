import { ShipmentDeliverTo } from 'common/apollo/enums/ShipmentDeliverTo';
import { ShippingMethod } from 'common/apollo/enums/ShippingMethod';
import { ShipmentType } from 'common/apollo/enums/ShipmentType';

import { OrderMaterial } from 'Project/Procurement/common/types/orderMaterial';

export const generateInitialValues = (materials: OrderMaterial[]) => ({
  deliverTo: '' as ShipmentDeliverTo,
  estimatedDeliveryDate: new Date().toISOString(),
  items: materials.map(({ boxCount: quantity, id: orderItemId }) => ({
    orderItemId,
    quantity,
  })),
  shippingCost: 0,
  shippingMethod: '' as ShippingMethod,
  shippingTax: 0,
  trackingLink: '',
  trackingNumber: '',
  type: ShipmentType.STANDARD,
});
