import LinkIcon from '@material-ui/icons/Link';

import { useToggle } from 'common/hooks/useToggle';

import { EditButton } from 'ProjectProfile/common/components/EditButton/EditButton';
import { LinkContainer } from 'ProjectProfile/common/components/Link/LinkContainer/LinkContainer';
import { LinkItem } from 'ProjectProfile/common/components/Link/LinkItem/LinkItem';
import {
  OutlinedLink,
  OutlinedNoLink,
} from 'ProjectProfile/common/components/Link/Link';

import {
  Header,
  Title,
  Wrapper,
} from 'ProjectProfile/PreDesign/Links/links.styles';

import { SalesLinksEdit } from './SalesLinksEdit';

type Props = {
  kickOffDeck: string | null;
  salesProposalSlides: string | null;
};

export const SalesLinks = ({ kickOffDeck, salesProposalSlides }: Props) => {
  const { isOn: isOpen, toggleOff: close, toggleOn: open } = useToggle();

  return (
    <Wrapper>
      <Header>
        <Title>Sales</Title>
        <EditButton
          onClick={open}
          segmentEventProps={{ name: 'Sales Links' }}
        />
      </Header>

      <LinkContainer>
        <LinkItem>
          {salesProposalSlides ? (
            <OutlinedLink color="primary" href={salesProposalSlides}>
              <LinkIcon />
              Sales proposal slides
            </OutlinedLink>
          ) : (
            <OutlinedNoLink>No Sales proposal slides link</OutlinedNoLink>
          )}
        </LinkItem>
        <LinkItem>
          {kickOffDeck ? (
            <OutlinedLink color="primary" href={kickOffDeck}>
              <LinkIcon /> Kickoff deck
            </OutlinedLink>
          ) : (
            <OutlinedNoLink>No Kickoff deck link</OutlinedNoLink>
          )}
        </LinkItem>
      </LinkContainer>

      {isOpen && <SalesLinksEdit close={close} />}
    </Wrapper>
  );
};
