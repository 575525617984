import { PricingEngineSkuProcuredBy } from 'common/apollo/enums/PricingEngineSkuProcuredBy';
import { AuthUserPermission } from 'common/enums/AuthUserPermission';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** DateTime - ISO format */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  examples__JSON: any;
};

export type AcceptContractorWorkOrderInput = {
  id: Scalars['ID'];
};

export type AcceptWorkOrderInput = {
  id: Scalars['ID'];
};

export type ActiveJob = {
  __typename?: 'ActiveJob';
  id: Scalars['ID'];
  street: Maybe<Scalars['String']>;
  streetSecond: Maybe<Scalars['String']>;
  customer: User;
  imgUrl: Maybe<Scalars['String']>;
};

export type ActiveWorkOrderInput = {
  id: Scalars['ID'];
};

export type Address = Node & {
  __typename?: 'Address';
  street: Maybe<Scalars['String']>;
  street_2: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  zip: Scalars['String'];
  city: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
};

export type AdminAnswerInput = {
  questionId: Scalars['ID'];
  answerIds?: Maybe<Array<Scalars['ID']>>;
  content?: Maybe<Scalars['String']>;
};

export type Answer = Node & {
  __typename?: 'Answer';
  id: Scalars['ID'];
  label: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  isDefault: Maybe<Scalars['Boolean']>;
  isSelected: Scalars['Boolean'];
};

export type PricingEngineHistoryItem = {
  __typename?: 'PricingEngineHistoryItem';
  id: Scalars['ID'];
  group: PricingEngineHistoryItemGroup;
  oldValue: Maybe<Scalars['String']>;
  newValue: Maybe<Scalars['String']>;
  category: MaterialsCategory;
};

export type AnswerisSelectedArgs = {
  input: IsSelectedInput;
};

export type AuthenticateInput = {
  email: Scalars['String'];
  redirectPath?: Maybe<Scalars['String']>;
};

export type Authentication = {
  __typename?: 'Authentication';
  token: Maybe<Scalars['String']>;
  status: Maybe<AuthenticationStatus>;
};

export enum AuthenticationStatus {
  INACTIVE = 'INACTIVE',
}

export enum BuildTiming {
  CONSECUTIVE = 'CONSECUTIVE',
  SIMULTANEOUS = 'SIMULTANEOUS',
}

export type BuildTimingInput = {
  pricingEngineId: Scalars['ID'];
  order: Scalars['Int'];
  buildTiming: BuildTiming;
};

export type BuildUpdate = {
  __typename?: 'BuildUpdate';
  completedDate: Scalars['DateTime'];
  id: Scalars['ID'];
  isLiked: Scalars['Boolean'];
  mediaFiles: Maybe<Array<File>>;
  title: Scalars['String'];
};

export enum CacheControlScope {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum CalculationCategory {
  BLOCK_SERVICES = 'BLOCK_SERVICES',
  BONUS = 'BONUS',
  CHANGE_ORDERS = 'CHANGE_ORDERS',
  CONTRACTOR_CHANGE_ORDERS = 'CONTRACTOR_CHANGE_ORDERS',
  CONSTRUCTION = 'CONSTRUCTION',
  DISCOUNTS = 'DISCOUNTS',
  MATERIALS = 'MATERIALS',
  MATERIALS_TAX = 'MATERIALS_TAX',
  PERMITS = 'PERMITS',
  SHIPPING = 'SHIPPING',
  WORK_ORDER_POST_ADJUSTMENT = 'WORK_ORDER_POST_ADJUSTMENT',
}

export enum CalculationPhase {
  FIXED = 'FIXED',
  ESTIMATE = 'ESTIMATE',
  FINAL = 'FINAL',
}

export enum CalculationType {
  MANUAL = 'MANUAL',
  PERCENTAGE = 'PERCENTAGE',
  REMAINING_BALANCE = 'REMAINING_BALANCE',
}

export type ChangeOrder = {
  __typename?: 'ChangeOrder';
  id: Scalars['ID'];
  approvedAmount: Scalars['Int'];
  approvedBy: TeamMember;
  changeOrderType: ChangeOrderType;
  chargeTo: ChangeOrderChargeTo;
  comment: Maybe<Scalars['String']>;
  description: Scalars['String'];
  payTo: ChangeOrderPayTo;
  pricingEngine: PricingEngine;
  requestedDate: Scalars['DateTime'];
  status: ChangeOrderStatus;
  title: string | null;
  errorOwner: Maybe<ChangeOrderErrorOwner>;
  scheduleImpactUnit: Maybe<DateUnit>;
  scheduleImpactUnitQuantity: Maybe<Scalars['Int']>;
};

export enum ChangeOrderChargeTo {
  BLOCK = 'BLOCK',
  CLIENT = 'CLIENT',
  CONTRACTOR = 'CONTRACTOR',
  VENDOR = 'VENDOR',
}

export type ChangeOrderCreateInput = {
  approvedAmount: Scalars['Int'];
  approvedById: Scalars['ID'];
  changeOrderType: ChangeOrderType;
  chargeTo: ChangeOrderChargeTo;
  description: Scalars['String'];
  payTo: ChangeOrderPayTo;
  pricingEngineId: Scalars['ID'];
  requestedDate: Scalars['DateTime'];
  status: ChangeOrderStatus;
  errorOwner?: Maybe<ChangeOrderErrorOwner>;
  comment?: Maybe<Scalars['String']>;
  scheduleImpactUnit?: Maybe<DateUnit>;
  scheduleImpactUnitQuantity?: Maybe<Scalars['Int']>;
};

export enum ChangeOrderErrorOwner {
  CLIENT_OPS_ERROR = 'CLIENT_OPS_ERROR',
  CONSTRUCTION_SUPERVISOR_ERROR = 'CONSTRUCTION_SUPERVISOR_ERROR',
  CONTRACTOR_ERROR = 'CONTRACTOR_ERROR',
  DESIGN_ERROR = 'DESIGN_ERROR',
  PROCUREMENT_ERROR = 'PROCUREMENT_ERROR',
  P_A_ERROR = 'P_A_ERROR',
  SALES_ERROR = 'SALES_ERROR',
  SITE_SURVEY_ERROR = 'SITE_SURVEY_ERROR',
  VENDOR_ERROR = 'VENDOR_ERROR',
}

export enum PricingEngineHistoryItemGroup {
  LABOR = 'LABOR',
  MATERIAL = 'MATERIAL',
}

export enum LineOfWorkOrigin {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

export enum ChangeOrderPayTo {
  BLOCK = 'BLOCK',
  CLIENT = 'CLIENT',
  CONTRACTOR = 'CONTRACTOR',
  VENDOR = 'VENDOR',
}

export type ChangeOrderSetApprovedInput = {
  changeOrderId: Scalars['ID'];
};

export type ChangeOrderSetVoidInput = {
  changeOrderId: Scalars['ID'];
};

export type ChangeOrdersInput = {
  projectId: Scalars['ID'];
};

export type ChangeOrdersSummaryCalculations = {
  __typename?: 'ChangeOrdersSummaryCalculations';
  blockContingencyRemaining: Scalars['Int'];
  blockMargin: Scalars['Int'];
  clientTotal: Scalars['Int'];
  payToContractorTotal: Scalars['Int'];
  projectContingency: Scalars['Int'];
};

export type ChangeOrdersSummaryCalculationsInput = {
  projectId: Scalars['ID'];
};

export enum ChangeOrderStatus {
  VOID = 'VOID',
  APPROVED = 'APPROVED',
}

export enum ChangeOrderType {
  BUILD_MANUAL_DISCREPANCY = 'BUILD_MANUAL_DISCREPANCY',
  CLIENT_OPS_ERROR = 'CLIENT_OPS_ERROR',
  CLIENT_REQUEST = 'CLIENT_REQUEST',
  DESIGN_ERROR = 'DESIGN_ERROR',
  FIELD_CONDITION = 'FIELD_CONDITION',
  INCORRECT_EXPECTATIONS = 'INCORRECT_EXPECTATIONS',
  INCORRECT_SOW = 'INCORRECT_SOW',
  NPS_CLIENT_EXPERIENCE = 'NPS_CLIENT_EXPERIENCE',
  PROCUREMENT_VENDOR_MISTAKE = 'PROCUREMENT_VENDOR_MISTAKE',
  P_A_ADJUSTMENT = 'P_A_ADJUSTMENT',
  REIMBURSEMENT = 'REIMBURSEMENT',
  REIMBURSEMENT_BUDGET = 'REIMBURSEMENT_BUDGET',
  REIMBURSEMENT_UN_BUDGET = 'REIMBURSEMENT_UN_BUDGET',
  SALES_ERROR = 'SALES_ERROR',
}

export type CheckoutInvoice = {
  __typename?: 'CheckoutInvoice';
  id: Scalars['ID'];
  customerId: Scalars['ID'];
  customerName: Maybe<Scalars['String']>;
  dueDate: Maybe<Scalars['DateTime']>;
  paymentStatus: Maybe<CheckoutPaymentStatus>;
  paymentCreatedAt: Maybe<Scalars['DateTime']>;
  hasProcessingFee: Scalars['Boolean'];
  items: Array<CheckoutInvoiceItem>;
  lines: Maybe<Array<Maybe<CheckoutInvoiceLine>>>;
  number: Maybe<Scalars['String']>;
  paidDate: Maybe<Scalars['DateTime']>;
  status: Maybe<Scalars['String']>;
  fee: Maybe<Scalars['Int']>;
  subtotal: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  generatedStripeInvoiceId: Maybe<Scalars['String']>;
};

export type CheckoutInvoiceItem = {
  __typename?: 'CheckoutInvoiceItem';
  id: Scalars['ID'];
  amount: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type CheckoutInvoiceLine = {
  __typename?: 'CheckoutInvoiceLine';
  id: Scalars['ID'];
  amount: Maybe<Scalars['Int']>;
  description: Maybe<Scalars['String']>;
};

export type CheckoutInvoicePayByAchTransferInput = {
  invoiceId: Scalars['ID'];
  sourceId: Scalars['ID'];
};

export type CheckoutInvoicePayByCardInput = {
  invoiceId: Scalars['ID'];
  sourceId: Scalars['ID'];
  shouldSaveCard: Scalars['Boolean'];
};

export type CheckoutInvoicePaymentRequestInput = {
  invoiceId: Scalars['ID'];
};

export type CheckoutLinkBankAccountInput = {
  accountId: Scalars['String'];
  accountName?: Maybe<Scalars['String']>;
  accountSubtype?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  customerId: Scalars['String'];
  plaidAccountId?: Maybe<Scalars['String']>;
  plaidPublicToken: Scalars['String'];
};

export type CheckoutPaymentMethod =
  | CheckoutPaymentMethodBankAccount
  | CheckoutPaymentMethodCard;

export type CheckoutPaymentMethodBankAccount = {
  __typename?: 'CheckoutPaymentMethodBankAccount';
  id: Scalars['ID'];
  accountName: Maybe<Scalars['String']>;
  accountSubtype: Maybe<Scalars['String']>;
  accountType: Maybe<Scalars['String']>;
  bankName: Maybe<Scalars['String']>;
  category: CheckoutPaymentMethodCategory;
  last4Digits: Maybe<Scalars['String']>;
};

export type CheckoutPaymentMethodCard = {
  __typename?: 'CheckoutPaymentMethodCard';
  id: Scalars['ID'];
  brand: Scalars['String'];
  last4Digits: Scalars['String'];
  category: CheckoutPaymentMethodCategory;
};

export enum CheckoutPaymentMethodCategory {
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  CARD = 'CARD',
}

export type CheckoutPaymentMethodDeleteInput = {
  customerId: Scalars['ID'];
  sourceId: Scalars['ID'];
};

export type CheckoutPaymentMethodDeleteResponse = {
  __typename?: 'CheckoutPaymentMethodDeleteResponse';
  id: Scalars['ID'];
};

export type CheckoutPaymentMethodsInput = {
  customerId: Scalars['ID'];
  category?: Maybe<CheckoutPaymentMethodCategory>;
};

export enum CheckoutPaymentStatus {
  SUCCEEDED = 'SUCCEEDED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export type CheckoutWireTransferInformation = {
  __typename?: 'CheckoutWireTransferInformation';
  id: Scalars['ID'];
  accountNumber: Scalars['String'];
  routingNumber: Scalars['String'];
  swiftCode: Scalars['String'];
};

export type CheckoutWireTransferInformationInput = {
  customerId: Scalars['ID'];
};

export type Condition = Node & {
  __typename?: 'Condition';
  id: Scalars['ID'];
  question: Question;
  answer: Answer;
};

export type Configurator = Node & {
  __typename?: 'Configurator';
  id: Scalars['ID'];
  roomType: RoomType;
  estimate: ConfiguratorEstimate;
  renders: Array<ConfiguratorRender>;
  categories: Array<ConfiguratorCategory>;
};

export type ConfiguratorAnswer = Node & {
  __typename?: 'ConfiguratorAnswer';
  id: Scalars['ID'];
  selected: Scalars['Boolean'];
  answer: Answer;
};

export type ConfiguratorAnswerselectedArgs = {
  id: Scalars['ID'];
};

export type ConfiguratorCategory = Node & {
  __typename?: 'ConfiguratorCategory';
  id: Scalars['ID'];
  category: Maybe<MaterialsCategory>;
  subCategories: Array<ConfiguratorSubCategory>;
  questions: Array<ConfiguratorQuestion>;
};

export type ConfiguratorEstimate = {
  __typename?: 'ConfiguratorEstimate';
  materials: ConfiguratorEstimateMaterialsCost;
};

export type ConfiguratorEstimateMaterialsCost = {
  __typename?: 'ConfiguratorEstimateMaterialsCost';
  listPrice: Maybe<Scalars['Int']>;
  total: Maybe<Scalars['Int']>;
};

export type ConfiguratorProduct = Node & {
  __typename?: 'ConfiguratorProduct';
  id: Scalars['ID'];
  skus: Array<ConfiguratorSku>;
  product: Product;
};

export type ConfiguratorQuestion = Node & {
  __typename?: 'ConfiguratorQuestion';
  id: Scalars['ID'];
  question: Question;
  answers: Array<ConfiguratorAnswer>;
};

export type ConfiguratorRender = Node & {
  __typename?: 'ConfiguratorRender';
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  renderDefault: RenderDefault;
};

export type ConfiguratorSku = Node & {
  __typename?: 'ConfiguratorSku';
  id: Scalars['ID'];
  selected: Scalars['Boolean'];
  sku: Sku;
};

export type ConfiguratorSkuselectedArgs = {
  id: Scalars['ID'];
};

export type ConfiguratorSkuSet = Node & {
  __typename?: 'ConfiguratorSkuSet';
  id: Scalars['ID'];
  skuSet: Maybe<SkuSet>;
  variants: Array<ConfiguratorSkuSetVariant>;
};

export type ConfiguratorSkuSetVariant = Node & {
  __typename?: 'ConfiguratorSkuSetVariant';
  id: Scalars['ID'];
  selected: Scalars['Boolean'];
  skuSetVariant: SkuSetVariant;
};

export type ConfiguratorSkuSetVariantselectedArgs = {
  id: Scalars['ID'];
};

export type ConfiguratorSubCategory = Node & {
  __typename?: 'ConfiguratorSubCategory';
  id: Scalars['ID'];
  category: Maybe<MaterialsCategory>;
  products: Array<ConfiguratorProduct>;
  skuSets: Array<ConfiguratorSkuSet>;
  questions: Array<ConfiguratorQuestion>;
};

export type ConsumerConfiguratorProposal = IConsumerProposal & {
  __typename?: 'ConsumerConfiguratorProposal';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  status: ProposalStatus;
  type: ProposalType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  publishedAt: Maybe<Scalars['DateTime']>;
  renovationPlan: RenovationPlan;
  pricingEngines: Array<PricingEngine>;
};

export type ConsumerDesignProposal = IConsumerProposal & {
  __typename?: 'ConsumerDesignProposal';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  status: ProposalStatus;
  type: ProposalType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  publishedAt: Maybe<Scalars['DateTime']>;
  isPermitRequired: Scalars['Boolean'];
  expiresAt: Maybe<Scalars['DateTime']>;
  acceptedAt: Maybe<Scalars['DateTime']>;
  details: ConsumerProposalDetails;
  renovationPlan: RenovationPlan;
  pricingEngines: Array<PricingEngine>;
};

export type ConsumerLegacyPayment = {
  __typename?: 'ConsumerLegacyPayment';
  id: Scalars['ID'];
  statementFiles: Array<File>;
};

export type ConsumerPaymentSchedule = {
  __typename?: 'ConsumerPaymentSchedule';
  id: Scalars['ID'];
  calculations: PaymentScheduleCalculation;
  paymentScheduleTerms: Array<ConsumerPaymentScheduleTerm>;
};

export type ConsumerPaymentScheduleTerm = {
  __typename?: 'ConsumerPaymentScheduleTerm';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  paidDate: Maybe<Scalars['DateTime']>;
  availabilityDate: Maybe<Scalars['DateTime']>;
  status: ConsumerPaymentScheduleTermStatus;
  dueDate: Maybe<Scalars['DateTime']>;
  items: Array<ConsumerPaymentScheduleTermItem>;
  invoices: Array<ConsumerPaymentScheduleTermInvoice>;
  calculations: PaymentScheduleTermCalculation;
  financingAmount: Scalars['Int'];
  totalRemaining: Scalars['Int'];
};

export type ConsumerPaymentScheduleTermInvoice = {
  __typename?: 'ConsumerPaymentScheduleTermInvoice';
  id: Scalars['ID'];
  stripeInvoiceId: Scalars['String'];
  generatedStripeInvoiceId: Maybe<Scalars['String']>;
  status: ConsumerPaymentScheduleTermInvoiceStatus;
  amount: Scalars['Int'];
};

export enum ConsumerPaymentScheduleTermInvoiceStatus {
  OPEN = 'OPEN',
  PAID = 'PAID',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  VOID = 'VOID',
}

export type ConsumerPaymentScheduleTermItem = {
  __typename?: 'ConsumerPaymentScheduleTermItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  isPending: Scalars['Boolean'];
  amount: Scalars['Int'];
};

export enum ConsumerPaymentScheduleTermStatus {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  PAID = 'PAID',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  VOID = 'VOID',
  OVERDUE = 'OVERDUE',
}

export type ConsumerPricingEngineConstructionCost = {
  __typename?: 'ConsumerPricingEngineConstructionCost';
  subtotal: Scalars['Int'];
};

export type ConsumerPricingEngineCost = {
  __typename?: 'ConsumerPricingEngineCost';
  construction: ConsumerPricingEngineConstructionCost;
  materials: ConsumerPricingEngineMaterialsCost;
  services: ConsumerPricingEngineServicesCost;
};

export type ConsumerPricingEngineMaterialsCost = {
  __typename?: 'ConsumerPricingEngineMaterialsCost';
  isPending: Scalars['Boolean'];
  calculationPhase: CalculationPhase;
  listPrice: Maybe<Scalars['Int']>;
  tradeDiscount: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type ConsumerPricingEngineServicesCost = {
  __typename?: 'ConsumerPricingEngineServicesCost';
  subtotal: Scalars['Int'];
};

export type ConsumerProposal =
  | ConsumerConfiguratorProposal
  | ConsumerSalesProposal
  | ConsumerDesignProposal;

export type ConsumerProposalConstructionCost = {
  __typename?: 'ConsumerProposalConstructionCost';
  subtotal: Scalars['Int'];
};

export type ConsumerProposalCost = {
  __typename?: 'ConsumerProposalCost';
  calculationPhase: CalculationPhase;
  discounts: Array<ConsumerProposalCostDiscount>;
  construction: ConsumerProposalConstructionCost;
  materials: ConsumerProposalMaterialCost;
  services: ConsumerProposalServicesCost;
  permits: ConsumerProposalPermitCost;
  total: ConsumerProposalTotalCost;
};

export type ConsumerProposalCostDiscount = {
  __typename?: 'ConsumerProposalCostDiscount';
  amount: Scalars['Int'];
  name: Scalars['String'];
};

export type ConsumerProposalDetails = {
  __typename?: 'ConsumerProposalDetails';
  depositAmount: Scalars['Int'];
  financings: Array<ProposalFinancing>;
  timeline: Maybe<Scalars['String']>;
  paymentSchedule: Maybe<Scalars['String']>;
  proposalPermits: Array<ProposalPermit>;
  costs: ConsumerProposalCost;
};

export type ConsumerProposalMaterialCost = {
  __typename?: 'ConsumerProposalMaterialCost';
  isPending: Scalars['Boolean'];
  listPrice: Maybe<Scalars['Int']>;
  shipping: Maybe<Scalars['Int']>;
  tradeDiscount: Maybe<Scalars['Int']>;
  tax: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type ConsumerProposalPermitCost = {
  __typename?: 'ConsumerProposalPermitCost';
  total: Scalars['Int'];
};

export type ConsumerProposalServicesCost = {
  __typename?: 'ConsumerProposalServicesCost';
  subtotal: Scalars['Int'];
};

export type ConsumerProposalTotalCost = {
  __typename?: 'ConsumerProposalTotalCost';
  subtotal: Scalars['Int'];
  discount: Scalars['Int'];
  total: Scalars['Int'];
};

export type ConsumerSalesProposal = IConsumerProposal & {
  __typename?: 'ConsumerSalesProposal';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  status: ProposalStatus;
  type: ProposalType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  publishedAt: Maybe<Scalars['DateTime']>;
  isPermitRequired: Scalars['Boolean'];
  expiresAt: Maybe<Scalars['DateTime']>;
  acceptedAt: Maybe<Scalars['DateTime']>;
  details: ConsumerProposalDetails;
  renovationPlan: RenovationPlan;
  pricingEngines: Array<PricingEngine>;
  paymentScheduleTermId: Maybe<Scalars['ID']>;
};

export type ConsumerSummaryProposal = {
  __typename?: 'ConsumerSummaryProposal';
  id: Scalars['ID'];
  materials: Array<ConsumerSummaryProposalMaterial>;
  options: Array<ConsumerSummaryProposalOption>;
  labor: Array<ConsumerSummaryProposalLabor>;
  designFiles: Array<File>;
};

export type ConsumerSummaryProposalLabor = {
  __typename?: 'ConsumerSummaryProposalLabor';
  roomName: Scalars['String'];
  linesOfWork: Array<LineOfWorkPricingEngine>;
};

export type ConsumerSummaryProposalLaborlinesOfWorkArgs = {
  level?: Maybe<LineOfWorkLevel>;
  isOptional?: Maybe<Scalars['Boolean']>;
};

export type ConsumerSummaryProposalMaterial = {
  __typename?: 'ConsumerSummaryProposalMaterial';
  roomName: Scalars['String'];
  materialsCategory: Array<PricingEngineMaterialsCategory>;
};

export type ConsumerSummaryProposalOption = {
  __typename?: 'ConsumerSummaryProposalOption';
  roomName: Scalars['String'];
  linesOfWork: Array<LineOfWorkPricingEngine>;
};

export type ConsumerSummaryProposalRoom = {
  __typename?: 'ConsumerSummaryProposalRoom';
  name: Scalars['String'];
  categories: Array<PricingEngineMaterialsCategory>;
  linesOfWork: Array<LineOfWorkPricingEngine>;
};

export type ConsumerSummaryProposalRoomlinesOfWorkArgs = {
  level?: Maybe<LineOfWorkLevel>;
  isOptional?: Maybe<Scalars['Boolean']>;
};

export type ConsumerSummaryProposalV2 = {
  __typename?: 'ConsumerSummaryProposalV2';
  id: Scalars['ID'];
  rooms: Array<ConsumerSummaryProposalRoom>;
  designFiles: Array<File>;
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  name: Scalars['String'];
  role: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type Contractor = Node & {
  __typename?: 'Contractor';
  id: Scalars['ID'];
  avatarUrl: Maybe<Scalars['String']>;
  bio: Maybe<Scalars['String']>;
  city: Scalars['String'];
  companyName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  profileLink: Maybe<Scalars['String']>;
  role: TeamMemberRole;
  state: Scalars['String'];
  street: Scalars['String'];
  street_2: Maybe<Scalars['String']>;
  streetSecond: Maybe<Scalars['String']>;
  zip: Scalars['String'];
  files: Maybe<Array<Maybe<File>>>;
  performanceTier: PerformanceTier;
};

export type ContractorCreateInput = {
  avatarUrl?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  companyName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  profileLink?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  street_2?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type ContractorMember = {
  __typename?: 'ContractorMember';
  avatarUrl: Maybe<Scalars['String']>;
  bio: Maybe<Scalars['String']>;
  companyName: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  profileLink: Maybe<Scalars['String']>;
};

export type ContractorPasswordResetInput = {
  id: Scalars['ID'];
  password: Scalars['String'];
};

export type ContractorProfile = Node & {
  __typename?: 'ContractorProfile';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  street: Scalars['String'];
  streetSecond: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type ContractorProfileUpdateInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  street: Scalars['String'];
  streetSecond?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type ContractorPunchListItemInput = {
  id: Scalars['ID'];
};

export type ContractorPunchListItemsInput = {
  jobId: Scalars['ID'];
};

export type ContractorUpdateInput = {
  avatarUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  permissions: Array<AuthUserPermission>;
};

export type Cost = {
  __typename?: 'Cost';
  discount: Scalars['Int'];
  subtotal: Scalars['Int'];
  total: Scalars['Int'];
};

export enum DateUnit {
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
}

export type DeclineContractorWorkOrderInput = {
  id: Scalars['ID'];
  reasons?: Maybe<Array<Scalars['ID']>>;
  note?: Maybe<Scalars['String']>;
};

export type DeclineWorkOrderInput = {
  id: Scalars['ID'];
  reasons?: Maybe<Array<Scalars['ID']>>;
  note?: Maybe<Scalars['String']>;
};

export type Dictionary = {
  __typename?: 'Dictionary';
  items: Array<DictionaryItem>;
  type: DictionaryType;
};

export type DictionaryItem = {
  __typename?: 'DictionaryItem';
  value: Scalars['String'];
  label: Scalars['String'];
};

export enum DictionaryType {
  LABOR_UNITS_KITCHEN = 'LABOR_UNITS_KITCHEN',
  LABOR_UNITS_BATHROOM = 'LABOR_UNITS_BATHROOM',
  PRODUCT_SKU_STATUSES = 'PRODUCT_SKU_STATUSES',
  PRODUCT_SKU_UNITS = 'PRODUCT_SKU_UNITS',
  PRODUCT_SKU_PROCURED_BYS = 'PRODUCT_SKU_PROCURED_BYS',
  PRODUCT_SKU_PACKAGING_UNITS = 'PRODUCT_SKU_PACKAGING_UNITS',
  VIF_STAGES = 'VIF_STAGES',
  VERIFIED_STATES = 'VERIFIED_STATES',
  CHANGE_ORDER_TYPE = 'CHANGE_ORDER_TYPE',
  CHANGE_ORDER_PAY_TO = 'CHANGE_ORDER_PAY_TO',
  CHANGE_ORDER_CHARGE_TO = 'CHANGE_ORDER_CHARGE_TO',
  DATE_UNITS = 'DATE_UNITS',
}

export type Discount = ProposalDiscount | MasterDiscount;

export enum DiscountDistribution {
  EACH_ROOM = 'EACH_ROOM',
  EACH_ROOM_AFTER_SECOND_ROOM = 'EACH_ROOM_AFTER_SECOND_ROOM',
}

export enum DiscountRoomType {
  ALL = 'ALL',
  KITCHEN = 'KITCHEN',
  BATHROOM = 'BATHROOM',
}

export enum DiscountType {
  BLOCK_2_0_PROMOTION = 'BLOCK_2_0_PROMOTION',
  CONTRACTOR_PROMOTION = 'CONTRACTOR_PROMOTION',
  SINGLE_ROOM = 'SINGLE_ROOM',
  MULTI_ROOM = 'MULTI_ROOM',
  ROOM = 'ROOM',
  PROPOSAL = 'PROPOSAL',
  PROPOSAL_ROOM = 'PROPOSAL_ROOM',
  VALUE_ADDED = 'VALUE_ADDED',
}

export type fake__color = {
  red255?: Maybe<Scalars['Int']>;
  green255?: Maybe<Scalars['Int']>;
  blue255?: Maybe<Scalars['Int']>;
};

export enum fake__imageCategory {
  abstract = 'abstract',
  animals = 'animals',
  business = 'business',
  cats = 'cats',
  city = 'city',
  food = 'food',
  nightlife = 'nightlife',
  fashion = 'fashion',
  people = 'people',
  nature = 'nature',
  sports = 'sports',
  technics = 'technics',
  transport = 'transport',
}

export enum fake__Locale {
  az = 'az',
  cz = 'cz',
  de = 'de',
  de_AT = 'de_AT',
  de_CH = 'de_CH',
  en = 'en',
  en_AU = 'en_AU',
  en_BORK = 'en_BORK',
  en_CA = 'en_CA',
  en_GB = 'en_GB',
  en_IE = 'en_IE',
  en_IND = 'en_IND',
  en_US = 'en_US',
  en_au_ocker = 'en_au_ocker',
  es = 'es',
  es_MX = 'es_MX',
  fa = 'fa',
  fr = 'fr',
  fr_CA = 'fr_CA',
  ge = 'ge',
  id_ID = 'id_ID',
  it = 'it',
  ja = 'ja',
  ko = 'ko',
  nb_NO = 'nb_NO',
  nep = 'nep',
  nl = 'nl',
  pl = 'pl',
  pt_BR = 'pt_BR',
  ru = 'ru',
  sk = 'sk',
  sv = 'sv',
  tr = 'tr',
  uk = 'uk',
  vi = 'vi',
  zh_CN = 'zh_CN',
  zh_TW = 'zh_TW',
}

export enum fake__loremSize {
  word = 'word',
  words = 'words',
  sentence = 'sentence',
  sentences = 'sentences',
  paragraph = 'paragraph',
  paragraphs = 'paragraphs',
}

export type fake__options = {
  useFullAddress?: Maybe<Scalars['Boolean']>;
  minMoney?: Maybe<Scalars['Float']>;
  maxMoney?: Maybe<Scalars['Float']>;
  decimalPlaces?: Maybe<Scalars['Int']>;
  imageWidth?: Maybe<Scalars['Int']>;
  imageHeight?: Maybe<Scalars['Int']>;
  imageCategory?: Maybe<fake__imageCategory>;
  randomizeImageUrl?: Maybe<Scalars['Boolean']>;
  emailProvider?: Maybe<Scalars['String']>;
  passwordLength?: Maybe<Scalars['Int']>;
  loremSize?: Maybe<fake__loremSize>;
  dateFormat?: Maybe<Scalars['String']>;
  baseColor?: Maybe<fake__color>;
  minNumber?: Maybe<Scalars['Float']>;
  maxNumber?: Maybe<Scalars['Float']>;
  precisionNumber?: Maybe<Scalars['Float']>;
};

export enum fake__Types {
  zipCode = 'zipCode',
  city = 'city',
  streetName = 'streetName',
  streetAddress = 'streetAddress',
  secondaryAddress = 'secondaryAddress',
  county = 'county',
  country = 'country',
  countryCode = 'countryCode',
  state = 'state',
  stateAbbr = 'stateAbbr',
  latitude = 'latitude',
  longitude = 'longitude',
  colorName = 'colorName',
  productCategory = 'productCategory',
  productName = 'productName',
  money = 'money',
  productMaterial = 'productMaterial',
  product = 'product',
  companyName = 'companyName',
  companyCatchPhrase = 'companyCatchPhrase',
  companyBS = 'companyBS',
  dbColumn = 'dbColumn',
  dbType = 'dbType',
  dbCollation = 'dbCollation',
  dbEngine = 'dbEngine',
  pastDate = 'pastDate',
  futureDate = 'futureDate',
  recentDate = 'recentDate',
  financeAccountName = 'financeAccountName',
  financeTransactionType = 'financeTransactionType',
  currencyCode = 'currencyCode',
  currencyName = 'currencyName',
  currencySymbol = 'currencySymbol',
  bitcoinAddress = 'bitcoinAddress',
  internationalBankAccountNumber = 'internationalBankAccountNumber',
  bankIdentifierCode = 'bankIdentifierCode',
  hackerAbbr = 'hackerAbbr',
  hackerPhrase = 'hackerPhrase',
  imageUrl = 'imageUrl',
  avatarUrl = 'avatarUrl',
  email = 'email',
  url = 'url',
  domainName = 'domainName',
  ipv4Address = 'ipv4Address',
  ipv6Address = 'ipv6Address',
  userAgent = 'userAgent',
  colorHex = 'colorHex',
  macAddress = 'macAddress',
  password = 'password',
  lorem = 'lorem',
  firstName = 'firstName',
  lastName = 'lastName',
  fullName = 'fullName',
  jobTitle = 'jobTitle',
  phoneNumber = 'phoneNumber',
  number = 'number',
  uuid = 'uuid',
  word = 'word',
  words = 'words',
  locale = 'locale',
  filename = 'filename',
  mimeType = 'mimeType',
  fileExtension = 'fileExtension',
  semver = 'semver',
}

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  archived: Scalars['Boolean'];
  name: Scalars['String'];
  mimeType: Scalars['String'];
  url: Scalars['String'];
  thumbnailUrl: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  type: Maybe<Scalars['String']>;
  captureDate: Maybe<Scalars['DateTime']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
};

export type FileMyDocumentRemoveInput = {
  id: Scalars['ID'];
};

export type FileMyDocumentRemoveResult = {
  __typename?: 'FileMyDocumentRemoveResult';
  id: Maybe<Scalars['ID']>;
};

export type FileMyDocumentUploadInput = {
  file: Scalars['Upload'];
  projectId: Scalars['ID'];
};

export type FileRemoveInput = {
  id: Scalars['ID'];
};

export type FileRemoveResult = {
  __typename?: 'FileRemoveResult';
  id: Maybe<Scalars['ID']>;
};

export type FileUpdateNameInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FileUpdateNameResult = {
  __typename?: 'FileUpdateNameResult';
  id: Maybe<Scalars['ID']>;
};

export type Financing = {
  __typename?: 'Financing';
  id: Scalars['ID'];
  name: Scalars['String'];
  interestRate: Scalars['Float'];
  offerDuration: Scalars['Int'];
  disclaimer: Scalars['String'];
};

export type FinancingProvider = {
  __typename?: 'FinancingProvider';
  id: Scalars['ID'];
  name: Scalars['String'];
  terms: Array<FinancingProviderTerm>;
};

export type FinancingProviderTerm = {
  __typename?: 'FinancingProviderTerm';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FinancingProviderTermsInput = {
  financingProviderId: Scalars['ID'];
};

export type GetMilestonesDueDatesInput = {
  id: Scalars['ID'];
  dueDate: Scalars['String'];
};

export type IConsumerProposal = {
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  status: ProposalStatus;
  type: ProposalType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  publishedAt: Maybe<Scalars['DateTime']>;
  renovationPlan: RenovationPlan;
  pricingEngines: Array<PricingEngine>;
};

export type Invoice = {
  __typename?: 'Invoice';
  url: Scalars['String'];
};

export type InvoiceGenerateInput = {
  proposalId: Scalars['ID'];
};

export type IsSelectedInput = {
  renovationPlanId?: Maybe<Scalars['ID']>;
  pricingEngineId?: Maybe<Scalars['ID']>;
};

export type IssueWorkOrderInput = {
  priceAdjustment: Scalars['Int'];
  contractorId: Scalars['ID'];
  proposalId: Scalars['ID'];
  note: Scalars['String'];
};

export type Job = {
  __typename?: 'Job';
  id: Scalars['ID'];
  street: Maybe<Scalars['String']>;
  streetSecond: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  zip: Maybe<Scalars['String']>;
  workOrder: Maybe<Link>;
  hasMorePhotosAndVideos: Scalars['Boolean'];
  customer: User;
  contacts: Array<Contact>;
  drawingsAndRenders: Array<File>;
  jobResources: Array<JobResource>;
  mediaFiles: Array<File>;
  procurementSchedules: Array<Link>;
  cutSheets: Array<File>;
  milestones: Maybe<Array<Milestone>>;
  punchListItems: Maybe<Array<PunchListItem>>;
  milestone: Maybe<Milestone>;
  jobFinances: Maybe<JobFinances>;
};

export type JobmediaFilesArgs = {
  amount?: Maybe<Scalars['Int']>;
};

export type JobmilestonesArgs = {
  incompleted?: Maybe<Scalars['Boolean']>;
};

export type JobpunchListItemsArgs = {
  incompleted?: Maybe<Scalars['Boolean']>;
};

export type JobmilestoneArgs = {
  id?: Maybe<Scalars['ID']>;
  upcoming?: Maybe<Scalars['Boolean']>;
};

export type JobContext = {
  __typename?: 'JobContext';
  buildingAge: Scalars['String'];
  buildingType: Scalars['String'];
  permittingAndApprovals: Scalars['String'];
};

export type JobFinances = {
  __typename?: 'JobFinances';
  id: Scalars['ID'];
  name: Scalars['String'];
  terms: Array<JobFinancesTerm>;
  calculations: PayoutScheduleCalculation;
  changeOrders: Array<JobFinancesChangeOrder>;
  permits: Array<JobFinancesPermit>;
};

export type JobFinancesChangeOrder = {
  __typename?: 'JobFinancesChangeOrder';
  id: Scalars['ID'];
  description: Scalars['String'];
  approvedAmount: Scalars['Int'];
};

export type JobFinancesPermit = {
  __typename?: 'JobFinancesPermit';
  id: Scalars['ID'];
  description: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type JobFinancesTerm = {
  __typename?: 'JobFinancesTerm';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  paidDate: Maybe<Scalars['DateTime']>;
  status: JobFinancesTermStatus;
  items: Array<JobFinancesTermItem>;
  calculations: PayoutScheduleTermCalculation;
};

export type JobFinancesTermInput = {
  jobFinancesTermId: Scalars['ID'];
};

export type JobFinancesTermItem = {
  __typename?: 'JobFinancesTermItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  amount: Scalars['Int'];
};

export enum JobFinancesTermStatus {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  PAID = 'PAID',
  VOID = 'VOID',
}

export type JobResource = {
  __typename?: 'JobResource';
  id: Scalars['ID'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type JobUploadMedia = {
  jobId: Scalars['ID'];
  file: Scalars['Upload'];
  captureDate?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type KeyDate = {
  __typename?: 'KeyDate';
  completedDate: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  dueDate: Scalars['DateTime'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export enum LaborUnit {
  HOUR = 'HOUR',
  SQ_FT = 'SQ_FT',
  DOOR_DRAWER = 'DOOR_DRAWER',
  OTHER = 'OTHER',
  LFT = 'LFT',
}

export type Legend = {
  __typename?: 'Legend';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type LineOfWork = {
  __typename?: 'LineOfWork';
  id: Scalars['ID'];
  name: Scalars['String'];
  level: LineOfWorkLevel;
  laborUnit: LaborUnit;
  internalCost: Scalars['Int'];
  rawMaterialsCost: Scalars['Int'];
  category: Maybe<MaterialsCategory>;
  buildingApprovalDescription: Maybe<Scalars['String']>;
};

export type LineOfWorkCommon = LineOfWork | LineOfWorkPricingEngine;

export type LineOfWorkCostTotal = {
  __typename?: 'LineOfWorkCostTotal';
  laborCost: Scalars['Int'];
  internalCost: Scalars['Int'];
  rawMaterialsCost: Scalars['Int'];
  materialsCost: Scalars['Int'];
  total: Scalars['Int'];
};

export type LineOfWorkCustomInput = {
  name: Scalars['String'];
  laborCost: Scalars['Int'];
  internalCost: Scalars['Int'];
};

export type LineOfWorkInput = {
  name: Scalars['String'];
  laborCost: Scalars['Int'];
  internalCost: Scalars['Int'];
};

export enum LineOfWorkLaborType {
  STANDARD = 'STANDARD',
  OTHER = 'OTHER',
}

export enum LineOfWorkLevel {
  BASE = 'BASE',
  CUSTOM = 'CUSTOM',
}

export type LineOfWorkNonCustomInput = {
  internalCost: Scalars['Int'];
};

export type LineOfWorkPricingEngine = {
  __typename?: 'LineOfWorkPricingEngine';
  id: Scalars['ID'];
  title: Maybe<Scalars['String']>;
  name: Scalars['String'];
  lineOfWork: LineOfWork;
  total: LineOfWorkCostTotal;
  laborHours: Scalars['Float'];
  vifStage: VifStage;
  verified: Maybe<VerifyState>;
  isOptional: Scalars['Boolean'];
};

export type LineOfWorkPricingEngineAddInput = {
  id: Scalars['ID'];
  pricingEngineId: Scalars['ID'];
};

export type LineOfWorkPricingEngineBatchAddInput = {
  lineOfWorkIds: Array<Scalars['ID']>;
  pricingEngineId: Scalars['ID'];
};

export type LineOfWorkPricingEngineCreateInput = {
  categoryId: Scalars['ID'];
  pricingEngineId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  materialsCost: Scalars['Int'];
  lineOfWork: LineOfWorkInput;
  laborHours: Scalars['Float'];
  tagId?: Scalars['String'];
  tagGroupId?: Scalars['String'];
  vifStage: VifStage;
  verified?: Maybe<VerifyState>;
};

export type LineOfWorkPricingEngineCustomUpdateInput = {
  id: Scalars['ID'];
  categoryId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  materialsCost: Scalars['Int'];
  lineOfWork: LineOfWorkCustomInput;
  laborHours?: Maybe<Scalars['Float']>;
  tagId?: Scalars['String'];
  tagGroupId?: Scalars['String'];
  vifStage: VifStage;
  verified?: Maybe<VerifyState>;
};

export type LineOfWorkPricingEngineIsOptionalUpdateInput = {
  id: Scalars['ID'];
  isOptional: Scalars['Boolean'];
};

export type LineOfWorkPricingEngineNonCustomUpdateInput = {
  id: Scalars['ID'];
  categoryId: Scalars['String'];
  lineOfWork: LineOfWorkNonCustomInput;
  laborHours?: Maybe<Scalars['Float']>;
  vifStage: VifStage;
  verified?: Maybe<VerifyState>;
};

export type LineOfWorkPricingEngineRemoveInput = {
  id: Scalars['ID'];
};

export type Link = {
  __typename?: 'Link';
  id: Maybe<Scalars['ID']>;
  url: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type LinkRemoveInput = {
  id: Scalars['ID'];
};

export type LinkRemoveResult = {
  __typename?: 'LinkRemoveResult';
  id: Maybe<Scalars['ID']>;
};

export type LinkUpdateInput = {
  id: Scalars['ID'];
  url: Scalars['String'];
  name: Scalars['String'];
};

export type MakeAnswerSelectionInput = {
  configuratorId: Scalars['ID'];
  questionId: Scalars['ID'];
  answerIds: Array<Scalars['ID']>;
};

export type MakeSelectionInput = {
  configuratorId: Scalars['ID'];
  categoryId: Scalars['ID'];
  skuId?: Maybe<Scalars['ID']>;
  skuSetVariantId?: Maybe<Scalars['ID']>;
};

export type MarketplaceCheckoutSession = {
  __typename?: 'MarketplaceCheckoutSession';
  sessionId: Scalars['ID'];
  marketplaceSku: MarketplaceSku;
};

export type MarketplaceCheckoutSessionInput = {
  skuId: Scalars['ID'];
  utmParams?: Maybe<UtmParams>;
  anonymousId: Scalars['String'];
};

export type MarketplaceSku = {
  __typename?: 'MarketplaceSku';
  id: Scalars['ID'];
  productId: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Int'];
  imageUrl: Maybe<Scalars['String']>;
};

export type MarketplaceSkuInput = {
  skuId: Scalars['ID'];
};

export type MasterDiscount = {
  __typename?: 'MasterDiscount';
  id: Scalars['ID'];
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  distribution: DiscountDistribution;
  name: Scalars['String'];
  roomType: DiscountRoomType;
  discountType: DiscountType;
};

export type Material = SkuSet | Product;

export type MaterialsCategoriesFilterInput = {
  catalogItemsOnly?: Maybe<Scalars['Boolean']>;
  parentCategoryId?: Maybe<Scalars['ID']>;
  pricingEngineId?: Maybe<Scalars['ID']>;
  projectCompatibleItemsOnly?: Maybe<Scalars['Boolean']>;
  roomType?: Maybe<RoomType>;
  laborType?: Maybe<Array<LineOfWorkLaborType>>;
};

export type MaterialsCategory = {
  __typename?: 'MaterialsCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  roomType: RoomType;
  imageUrl: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  externalName: Maybe<Scalars['String']>;
  clientOrder: Maybe<Scalars['Int']>;
  categoryParent: Maybe<MaterialsCategory>;
  categoryChildren: Array<MaterialsCategory>;
  materials: Array<Material>;
  linesOfWork: Array<LineOfWork>;
  renderDefault: Maybe<RenderDefault>;
};

export type MaterialsCategorylinesOfWorkArgs = {
  laborType?: Maybe<Array<LineOfWorkLaborType>>;
};

export type MaterialsCreateBatchInput = {
  skuId: Scalars['ID'];
  materialsCategoryId: Scalars['ID'];
  setVariantId?: Maybe<Scalars['ID']>;
};

export type MaterialsPricingEngineCreateInput = {
  pricingEngineId: Scalars['ID'];
  materials: Array<MaterialsCreateBatchInput>;
};

export type MaterialsPricingEngineDeleteInput = {
  pricingEngineId: Scalars['ID'];
  pricingEngineSkuIds: Array<Scalars['ID']>;
};

export type MaterialSummaryCsv = {
  __typename?: 'MaterialSummaryCsv';
  content: Scalars['String'];
};

export type Milestone = {
  __typename?: 'Milestone';
  id: Scalars['ID'];
  order: Scalars['Int'];
  title: Scalars['String'];
  category: PROJECT_ITEM_CATEGORY;
  targetDate: Scalars['DateTime'];
  description: Scalars['String'];
  completed: Scalars['Boolean'];
  mediaFiles: Maybe<Array<File>>;
  completedReason: Maybe<Scalars['String']>;
};

export type MilestoneCompleteInput = {
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
};

export type MilestoneReason = {
  __typename?: 'MilestoneReason';
  id: Scalars['ID'];
  content: Scalars['String'];
};

export type MilestoneUpdateDueDateInput = {
  id: Scalars['ID'];
  dueDate: Scalars['String'];
  reasons?: Maybe<Array<Scalars['ID']>>;
  customReason?: Maybe<Scalars['String']>;
  /** Temporary solution to support mobile app */
  reason?: Maybe<Scalars['String']>;
};

export type MilestoneUploadMedia = {
  milestoneId: Scalars['ID'];
  file: Scalars['Upload'];
  captureDate?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptContractorWorkOrder: WorkOrder;
  acceptWorkOrder: WorkOrder;
  authenticate: Authentication;
  authenticateV2: Maybe<Scalars['Boolean']>;
  changeOrderCreate: ChangeOrder;
  changeOrderSetApproved: ChangeOrder;
  changeOrderSetVoid: ChangeOrder;
  checkoutInvoicePayByAchTransfer: CheckoutInvoice;
  checkoutInvoicePayByCard: CheckoutInvoice;
  checkoutInvoicePaymentRequest: CheckoutInvoice;
  checkoutLinkBankAccount: CheckoutPaymentMethod;
  checkoutPaymentMethodDelete: CheckoutPaymentMethodDeleteResponse;
  contractorCreate: Maybe<Contractor>;
  contractorPasswordReset: Maybe<Contractor>;
  contractorProfileUpdate: Maybe<ContractorProfile>;
  contractorUpdate: Maybe<Contractor>;
  declineContractorWorkOrder: WorkOrder;
  declineWorkOrder: WorkOrder;
  fileMyDocumentRemove: Maybe<FileMyDocumentRemoveResult>;
  fileMyDocumentUpload: File;
  fileRemove: Maybe<FileRemoveResult>;
  fileUpdateName: Maybe<FileUpdateNameResult>;
  invoiceGenerate: Invoice;
  issueWorkOrder: WorkOrder;
  lineOfWorkPricingEngineAdd: LineOfWorkPricingEngine;
  lineOfWorkPricingEngineBatchAdd: Array<LineOfWorkPricingEngine>;
  lineOfWorkPricingEngineCreate: LineOfWorkPricingEngine;
  lineOfWorkPricingEngineCustomUpdate: LineOfWorkPricingEngine;
  lineOfWorkPricingEngineIsOptionalUpdate: LineOfWorkPricingEngine;
  lineOfWorkPricingEngineNonCustomUpdate: LineOfWorkPricingEngine;
  lineOfWorkPricingEngineRemove: LineOfWorkPricingEngine;
  linkRemove: Maybe<LinkRemoveResult>;
  linkUpdate: Maybe<Link>;
  loginAsUser: Authentication;
  makeAnswerSelection: SelectionAnswerResponse;
  makeSelection: SelectionResponse;
  marketplaceCheckoutSession: MarketplaceCheckoutSession;
  materialsPricingEngineCreate: Array<PricingEngineMaterialsCategory>;
  materialsPricingEngineDelete: Array<PricingEngineMaterialsCategory>;
  milestoneComplete: Milestone;
  milestoneUpdateDueDate: Milestone;
  paymentScheduleTermAdminDepositCreate: PaymentScheduleTerm;
  paymentScheduleTermCreate: PaymentScheduleTerm;
  paymentScheduleTermDelete: PaymentScheduleTerm;
  paymentScheduleTermDepositCreate: ConsumerPaymentScheduleTerm;
  paymentScheduleTermItemCreate: PaymentScheduleTermItem;
  paymentScheduleTermItemDelete: Maybe<Scalars['Boolean']>;
  paymentScheduleTermItemUpdate: PaymentScheduleTermItem;
  paymentScheduleTermSetAvailable: PaymentScheduleTerm;
  paymentScheduleTermSetVoid: PaymentScheduleTerm;
  paymentScheduleTermUpdate: PaymentScheduleTerm;
  paymentScheduleTermsFromTemplateCreate: Project;
  paymentScheduleTermsOrderUpdate: Project;
  payoutScheduleCreate: PayoutSchedule;
  payoutScheduleDelete: PayoutSchedule;
  payoutScheduleTermCreate: PayoutScheduleTerm;
  payoutScheduleTermDelete: PayoutScheduleTerm;
  payoutScheduleTermItemCreate: PayoutScheduleTermItem;
  payoutScheduleTermItemDelete: Maybe<Scalars['Boolean']>;
  payoutScheduleTermItemUpdate: PayoutScheduleTermItem;
  payoutScheduleTermSetAvailable: PayoutScheduleTerm;
  payoutScheduleTermSetPaid: PayoutScheduleTerm;
  payoutScheduleTermSetVoided: PayoutScheduleTerm;
  payoutScheduleTermUpdate: PayoutScheduleTerm;
  payoutScheduleTermsFromTemplateCreate: PayoutSchedule;
  payoutScheduleUpdate: PayoutSchedule;
  permitCreate: Permit;
  plannerResponsesSend: PlannerConfigurator;
  pricingEngineCreate: PricingEngine;
  pricingEngineRemove: PricingEngine;
  pricingEngineSetCost: PricingEngine;
  pricingEngineSetRenderVisibility: PricingEngine;
  pricingEngineUpdate: PricingEngine;
  pricingEngineUserRemove: Maybe<PricingEngine>;
  productCreate: Product;
  productDelete: Scalars['ID'];
  productPricingEngineSetProperties: PricingEngineSku;
  productUpdate: Product;
  projectAddressUpdate: Project;
  projectBreakgroundDateUpdate: Project;
  projectBuildingApprovalFileArchive: Maybe<Scalars['Boolean']>;
  projectBuildingApprovalFileOrderUpdate: Maybe<Scalars['Boolean']>;
  projectDesignFileArchive: Maybe<Scalars['Boolean']>;
  projectDesignFileOrderUpdate: Maybe<Scalars['Boolean']>;
  projectExistingConditionsCreate: Maybe<Link>;
  projectExistingConditionsUpdate: Maybe<Link>;
  projectFinancingCreate: ProjectFinancing;
  projectFinancingUpdate: ProjectFinancing;
  projectMemberSet: ProjectMember;
  projectNameUpdate: Project;
  projectNoteUpdate: ProjectNote;
  projectPaymentPreferenceUpdate: Project;
  projectProcurementLinkCreate: Maybe<Link>;
  projectScheduleNotificationConfigTemplateCreate: ProjectScheduleNotificationConfigTemplate;
  projectScheduleNotificationConfigTemplateDelete: ProjectScheduleNotificationConfigTemplate;
  projectScheduleNotificationConfigTemplateUpdate: ProjectScheduleNotificationConfigTemplate;
  projectScheduleNotificationCreate: ProjectNotification;
  projectNotificationDelete: ProjectNotification;
  projectNotificationToggleActive: ProjectNotification;
  projectNotificationUpdate: ProjectNotification;
  projectStatusUpdate: Project;
  projectUploadFile: File;
  projectUploadFiles: Maybe<UploadedFiles>;
  projectWorkOrderLinkCreate: Maybe<Link>;
  propertyProjectUpdate: Project;
  proposalAccept: Proposal;
  proposalCreate: Proposal;
  proposalDetailsUpdate: Proposal;
  proposalDiscountCreate: ProposalDiscount;
  proposalDiscountRemove: Proposal;
  proposalDiscountSelectMasterDiscount: Proposal;
  proposalDiscountUpdate: ProposalDiscount;
  proposalDuplicate: Proposal;
  proposalFinancingToggle: ProposalFinancingToggle;
  proposalNameUpdate: Proposal;
  proposalPermitCreate: Proposal;
  proposalPermitRemove: ProposalPermit;
  proposalPermitRequired: Proposal;
  proposalPermitUpdate: ProposalPermit;
  proposalPermitsRemove: Proposal;
  proposalPublish: Proposal;
  proposalRemove: Proposal;
  proposalRequestNew: Maybe<Scalars['Boolean']>;
  proposalRequireAcceptanceUpdate: Proposal;
  proposalUnPublish: Proposal;
  proposalVersionClose: Proposal;
  punchListItemComplete: PunchListItem;
  punchListItemCreate: PunchListItem;
  renovationInfoUpdate: RenovationPlan;
  renovationPlanAddressUpdate: Address;
  renovationPlanAnswersUpdate: RenovationPlan;
  scheduleProjectItemComplete: ScheduleProjectItem;
  scheduleProjectItemCreate: ScheduleProjectItem;
  scheduleProjectItemCreateFromGeneric: ScheduleProjectItem;
  scheduleProjectItemEdit: ScheduleProjectItem;
  scheduleProjectItemIncomplete: ScheduleProjectItem;
  scheduleProjectItemRemove: Scalars['Boolean'];
  scheduleProjectItemShiftDueDate: ScheduleProjectItem;
  scheduleProjectItemUpdate: ScheduleProjectItem;
  scheduleProjectPublish: ScheduleProject;
  scheduleProjectRemove: ScheduleProject;
  scheduleTemplateApply: ScheduleProject;
  setPassword: Scalars['Boolean'];
  skuCreate: Sku;
  skuDelete: Scalars['ID'];
  skuSetPricingEngineSetProperties: PricingEngineSkuSet;
  skuSetPricingEngineSetVariant: PricingEngineSkuSet;
  skuUpdate: Sku;
  startingPointsGenerate: Section;
  teamMemberHubspotUpdate: Maybe<TeamMember>;
  teamMemberUpdate: Maybe<TeamMember>;
  toggleBuildUpdateLike: BuildUpdate;
  updatePerformanceTier: Maybe<Contractor>;
  updateProposalPricingEngineBuildTiming: Proposal;
  updateWorkOrder: WorkOrder;
  uploadMedia: File;
  uploadMilestoneMedia: File;
  uploadPunchListItemMedia: File;
  uploadUserMedia: File;
  userCreate: Authentication;
  userReferralCampaignValidate: UserReferralCampaign;
  userUpdate: User;
  verifyToken: Authentication;
  workOrderContentUpdate: WorkOrderContent;
  zipCodeValidate: ZipCode;
};

export type MutationpaymentScheduleArgs = {
  input: PaymentScheduleInput;
};

export type MutationacceptContractorWorkOrderArgs = {
  input?: Maybe<AcceptContractorWorkOrderInput>;
};

export type MutationacceptWorkOrderArgs = {
  input: AcceptWorkOrderInput;
};

export type MutationauthenticateArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  role?: Maybe<ROLE>;
};

export type MutationauthenticateV2Args = {
  input: AuthenticateInput;
};

export type MutationchangeOrderCreateArgs = {
  input: ChangeOrderCreateInput;
};

export type MutationchangeOrderSetApprovedArgs = {
  input: ChangeOrderSetApprovedInput;
};

export type MutationchangeOrderSetVoidArgs = {
  input: ChangeOrderSetVoidInput;
};

export type MutationcheckoutInvoicePayByAchTransferArgs = {
  input: CheckoutInvoicePayByAchTransferInput;
};

export type MutationcheckoutInvoicePayByCardArgs = {
  input: CheckoutInvoicePayByCardInput;
};

export type MutationcheckoutInvoicePaymentRequestArgs = {
  input: CheckoutInvoicePaymentRequestInput;
};

export type MutationcheckoutLinkBankAccountArgs = {
  input: CheckoutLinkBankAccountInput;
};

export type MutationcheckoutPaymentMethodDeleteArgs = {
  input: CheckoutPaymentMethodDeleteInput;
};

export type MutationcontractorCreateArgs = {
  input: ContractorCreateInput;
};

export type MutationcontractorPasswordResetArgs = {
  input: ContractorPasswordResetInput;
};

export type MutationcontractorProfileUpdateArgs = {
  input: ContractorProfileUpdateInput;
};

export type MutationcontractorUpdateArgs = {
  input: ContractorUpdateInput;
};

export type MutationdeclineContractorWorkOrderArgs = {
  input?: Maybe<DeclineContractorWorkOrderInput>;
};

export type MutationdeclineWorkOrderArgs = {
  input: DeclineWorkOrderInput;
};

export type MutationfileMyDocumentRemoveArgs = {
  input: FileMyDocumentRemoveInput;
};

export type MutationfileMyDocumentUploadArgs = {
  input: FileMyDocumentUploadInput;
};

export type MutationfileRemoveArgs = {
  input: FileRemoveInput;
};

export type MutationfileUpdateNameArgs = {
  input: FileUpdateNameInput;
};

export type MutationinvoiceGenerateArgs = {
  input?: Maybe<InvoiceGenerateInput>;
};

export type MutationissueWorkOrderArgs = {
  input?: Maybe<IssueWorkOrderInput>;
};

export type MutationlineOfWorkPricingEngineAddArgs = {
  input: LineOfWorkPricingEngineAddInput;
};

export type MutationlineOfWorkPricingEngineBatchAddArgs = {
  input: LineOfWorkPricingEngineBatchAddInput;
};

export type MutationlineOfWorkPricingEngineCreateArgs = {
  input: LineOfWorkPricingEngineCreateInput;
};

export type MutationlineOfWorkPricingEngineCustomUpdateArgs = {
  input: LineOfWorkPricingEngineCustomUpdateInput;
};

export type MutationlineOfWorkPricingEngineIsOptionalUpdateArgs = {
  input: LineOfWorkPricingEngineIsOptionalUpdateInput;
};

export type MutationlineOfWorkPricingEngineNonCustomUpdateArgs = {
  input: LineOfWorkPricingEngineNonCustomUpdateInput;
};

export type MutationlineOfWorkPricingEngineRemoveArgs = {
  input: LineOfWorkPricingEngineRemoveInput;
};

export type MutationlinkRemoveArgs = {
  input: LinkRemoveInput;
};

export type MutationlinkUpdateArgs = {
  input: LinkUpdateInput;
};

export type MutationloginAsUserArgs = {
  id: Scalars['ID'];
  referral: Scalars['String'];
};

export type MutationmakeAnswerSelectionArgs = {
  input: MakeAnswerSelectionInput;
};

export type MutationmakeSelectionArgs = {
  input: MakeSelectionInput;
};

export type MutationmarketplaceCheckoutSessionArgs = {
  input: MarketplaceCheckoutSessionInput;
};

export type MutationmaterialsPricingEngineCreateArgs = {
  input: MaterialsPricingEngineCreateInput;
};

export type MutationmaterialsPricingEngineDeleteArgs = {
  input: MaterialsPricingEngineDeleteInput;
};

export type MutationmilestoneCompleteArgs = {
  input?: Maybe<MilestoneCompleteInput>;
};

export type MutationmilestoneUpdateDueDateArgs = {
  input?: Maybe<MilestoneUpdateDueDateInput>;
};

export type MutationpaymentScheduleTermCreateArgs = {
  input: PaymentScheduleTermCreateInput;
};

export type MutationpaymentScheduleTermDeleteArgs = {
  input: PaymentScheduleTermDeleteInput;
};

export type MutationpaymentScheduleTermDepositCreateArgs = {
  input: PaymentScheduleTermDepositCreateInput;
};

export type MutationpaymentScheduleTermItemCreateArgs = {
  input: PaymentScheduleTermItemCreateInput;
};

export type MutationpaymentScheduleTermItemDeleteArgs = {
  input: PaymentScheduleTermItemDeleteInput;
};

export type MutationpaymentScheduleTermItemUpdateArgs = {
  input: PaymentScheduleTermItemUpdateInput;
};

export type MutationpaymentScheduleTermSetAvailableArgs = {
  input: PaymentScheduleTermSetAvailableInput;
};

export type MutationpaymentScheduleTermSetVoidArgs = {
  input: PaymentScheduleTermSetVoidInput;
};

export type MutationpaymentScheduleTermUpdateArgs = {
  input: PaymentScheduleTermUpdateInput;
};

export type MutationpaymentScheduleTermsFromTemplateCreateArgs = {
  input: PaymentScheduleTermsFromTemplateCreateInput;
};

export type MutationpaymentScheduleTermsOrderUpdateArgs = {
  input: PaymentScheduleTermsOrderUpdateInput;
};

export type MutationpayoutScheduleCreateArgs = {
  input?: Maybe<PayoutScheduleCreateInput>;
};

export type MutationpayoutScheduleDeleteArgs = {
  input?: Maybe<PayoutScheduleDeleteInput>;
};

export type MutationpayoutScheduleTermCreateArgs = {
  input?: Maybe<PayoutScheduleTermCreateInput>;
};

export type MutationpayoutScheduleTermDeleteArgs = {
  input?: Maybe<PayoutScheduleTermDeleteInput>;
};

export type MutationpayoutScheduleTermItemCreateArgs = {
  input: PayoutScheduleTermItemCreateInput;
};

export type MutationpayoutScheduleTermItemDeleteArgs = {
  input: PayoutScheduleTermItemDeleteInput;
};

export type MutationpayoutScheduleTermItemUpdateArgs = {
  input: PayoutScheduleTermItemUpdateInput;
};

export type MutationpayoutScheduleTermSetAvailableArgs = {
  input?: Maybe<PayoutScheduleTermSetAvailableInput>;
};

export type MutationpayoutScheduleTermSetPaidArgs = {
  input?: Maybe<PayoutScheduleTermSetPaidInput>;
};

export type MutationpayoutScheduleTermSetVoidedArgs = {
  input?: Maybe<PayoutScheduleTermSetVoidedInput>;
};

export type MutationpayoutScheduleTermUpdateArgs = {
  input?: Maybe<PayoutScheduleTermUpdateInput>;
};

export type MutationpayoutScheduleTermsFromTemplateCreateArgs = {
  input: PayoutScheduleTermsFromTemplateCreateInput;
};

export type MutationpayoutScheduleUpdateArgs = {
  input?: Maybe<PayoutScheduleUpdateInput>;
};

export type MutationpermitCreateArgs = {
  input: PermitCreateInput;
};

export type MutationplannerResponsesSendArgs = {
  input: PlannerResponsesInput;
};

export type MutationpricingEngineCreateArgs = {
  input: PricingEngineCreateInput;
};

export type MutationpricingEngineRemoveArgs = {
  input: PricingEngineRemoveInput;
};

export type MutationpricingEngineSetCostArgs = {
  input: PricingEngineCostInput;
};

export type MutationpricingEngineSetRenderVisibilityArgs = {
  input: PricingEngineRenderVisibilityInput;
};

export type MutationpricingEngineUpdateArgs = {
  input: PricingEngineUpdateInput;
};

export type MutationpricingEngineUserRemoveArgs = {
  input: PricingEngineUserRemoveInput;
};

export type MutationpricingEngineValidateDataArgs = {
  input: PricingEngineValidateDataInput;
};

export type MutationproductCreateArgs = {
  input: ProductCreateInput;
};

export type MutationproductDeleteArgs = {
  input: ProductDeleteInput;
};

export type MutationproductPricingEngineSetPropertiesArgs = {
  input: ProductPricingEngineInput;
};

export type MutationproductUpdateArgs = {
  input: ProductUpdateInput;
};

export type MutationprojectAddressUpdateArgs = {
  input: RenovationInfoAddressInput;
};

export type MutationprojectBreakgroundDateUpdateArgs = {
  input: ProjectBreakgroundDateInput;
};

export type MutationprojectBuildingApprovalFileArchiveArgs = {
  input: ProjectFileArchiveInput;
};

export type MutationprojectBuildingApprovalFileOrderUpdateArgs = {
  input: ProjectFileOrderInput;
};

export type MutationprojectDesignFileArchiveArgs = {
  input: ProjectFileArchiveInput;
};

export type MutationprojectDesignFileOrderUpdateArgs = {
  input: ProjectFileOrderInput;
};

export type MutationprojectExistingConditionsCreateArgs = {
  input: ProjectExistingConditionsCreateInput;
};

export type MutationprojectExistingConditionsUpdateArgs = {
  input: ProjectExistingConditionsUpdateInput;
};

export type MutationprojectFinancingCreateArgs = {
  input: ProjectFinancingCreateInput;
};

export type MutationprojectFinancingUpdateArgs = {
  input: ProjectFinancingUpdateInput;
};

export type MutationprojectMemberSetArgs = {
  input: ProjectMemberSetInput;
};

export type MutationprojectNameUpdateArgs = {
  input: ProjectNameUpdateInput;
};

export type MutationprojectNoteUpdateArgs = {
  input: ProjectNoteUpdateInput;
};

export type MutationprojectPaymentPreferenceUpdateArgs = {
  input: ProjectPaymentPreferenceUpdateInput;
};

export type MutationprojectProcurementLinkCreateArgs = {
  input: ProjectProcurementLinkCreate;
};

export type MutationprojectScheduleNotificationConfigTemplateCreateArgs = {
  input: ProjectScheduleNotificationConfigTemplateCreateInput;
};

export type MutationprojectScheduleNotificationConfigTemplateDeleteArgs = {
  input: ProjectScheduleNotificationConfigTemplateDeleteInput;
};

export type MutationprojectScheduleNotificationConfigTemplateUpdateArgs = {
  input: ProjectScheduleNotificationConfigTemplateUpdateInput;
};

export type MutationprojectScheduleNotificationCreateArgs = {
  input: ProjectScheduleNotificationCreateInput;
};

export type MutationprojectNotificationDeleteArgs = {
  input: ProjectNotificationDeleteInput;
};

export type MutationprojectNotificationToggleActiveArgs = {
  input: ProjectNotificationToggleActiveInput;
};

export type MutationprojectNotificationUpdateArgs = {
  input: ProjectNotificationUpdateInput;
};

export type MutationprojectStatusUpdateArgs = {
  input: ProjectStatusUpdateInput;
};

export type MutationprojectUploadFileArgs = {
  input: ProjectUploadFile;
};

export type MutationprojectUploadFilesArgs = {
  input: ProjectUploadFiles;
};

export type MutationprojectWorkOrderLinkCreateArgs = {
  input: ProjectWorkOrderLinkCreate;
};

export type MutationpropertyProjectUpdateArgs = {
  input: PropertyProjectUpdateInput;
};

export type MutationproposalAcceptArgs = {
  input: ProposalAcceptInput;
};

export type MutationproposalCreateArgs = {
  input: ProposalCreateInput;
};

export type MutationproposalDetailsUpdateArgs = {
  input: ProposalDetailsUpdateInput;
};

export type MutationproposalDiscountCreateArgs = {
  input: ProposalDiscountCreateInput;
};

export type MutationproposalDiscountRemoveArgs = {
  input: ProposalDiscountRemoveInput;
};

export type MutationproposalDiscountSelectMasterDiscountArgs = {
  input: ProposalDiscountSelectMasterDiscountInput;
};

export type MutationproposalDiscountUpdateArgs = {
  input: ProposalDiscountUpdateInput;
};

export type MutationproposalDuplicateArgs = {
  input: ProposalDuplicateInput;
};

export type MutationproposalFinancingToggleArgs = {
  input: ProposalFinancingToggleInput;
};

export type MutationproposalNameUpdateArgs = {
  input: ProposalNameUpdateInput;
};

export type MutationproposalPermitCreateArgs = {
  input: ProposalPermitBatchCreateInput;
};

export type MutationproposalPermitRemoveArgs = {
  input: ProposalPermitRemoveInput;
};

export type MutationproposalPermitRequiredArgs = {
  input: ProposalPermitRequiredInput;
};

export type MutationproposalPermitUpdateArgs = {
  input: ProposalPermitUpdateInput;
};

export type MutationproposalPermitsRemoveArgs = {
  input: ProposalPermitsRemoveInput;
};

export type MutationproposalPublishArgs = {
  input: ProposalPublishInput;
};

export type MutationproposalRemoveArgs = {
  input: ProposalRemoveInput;
};

export type MutationproposalRequestNewArgs = {
  input: ProposalRequestNewInput;
};

export type MutationproposalRequireAcceptanceUpdateArgs = {
  input: ProposalRequireAcceptanceUpdateInput;
};

export type MutationproposalUnPublishArgs = {
  input: ProposalUnPublishInput;
};

export type MutationproposalVersionCloseArgs = {
  input: ProposalVersionCloseInput;
};

export type MutationpunchListItemCompleteArgs = {
  input: PunchListCompleteInput;
};

export type MutationpunchListItemCreateArgs = {
  input: PunchListItemCreateInput;
};

export type MutationrenovationInfoUpdateArgs = {
  input: RenovationInfoUpdateInput;
};

export type MutationrenovationPlanAddressUpdateArgs = {
  input: RenovationPlanAddressUpdateInput;
};

export type MutationrenovationPlanAnswersUpdateArgs = {
  input: RenovationPlanAnswersUpdateInput;
};

export type MutationscheduleProjectItemCompleteArgs = {
  input?: Maybe<ScheduleProjectItemCompleteInput>;
};

export type MutationscheduleProjectItemCreateArgs = {
  input?: Maybe<ScheduleProjectItemCreateInput>;
};

export type MutationscheduleProjectItemCreateFromGenericArgs = {
  input?: Maybe<ScheduleProjectItemCreateFromGenericInput>;
};

export type MutationscheduleProjectItemEditArgs = {
  input?: Maybe<ScheduleProjectItemEditInput>;
};

export type MutationscheduleProjectItemIncompleteArgs = {
  input?: Maybe<ScheduleProjectItemIncompleteInput>;
};

export type MutationscheduleProjectItemRemoveArgs = {
  input?: Maybe<ScheduleProjectItemRemoveInput>;
};

export type MutationscheduleProjectItemShiftDueDateArgs = {
  input?: Maybe<ScheduleProjectItemShiftDueDateInput>;
};

export type MutationscheduleProjectItemUpdateArgs = {
  input?: Maybe<ScheduleProjectItemUpdateInput>;
};

export type MutationscheduleProjectPublishArgs = {
  input?: Maybe<ScheduleProjectPublishInput>;
};

export type MutationscheduleProjectRemoveArgs = {
  input?: Maybe<ScheduleProjectRemoveInput>;
};

export type MutationscheduleTemplateApplyArgs = {
  input?: Maybe<ScheduleTemplateApplyInput>;
};

export type MutationsetPasswordArgs = {
  input?: Maybe<SetPasswordInput>;
};

export type MutationskuCreateArgs = {
  input: SkuCreateInput;
};

export type MutationskuDeleteArgs = {
  input: SkuDeleteInput;
};

export type MutationskuSetPricingEngineSetPropertiesArgs = {
  input: SkuSetPricingEngineSetPropertiesInput;
};

export type MutationskuSetPricingEngineSetVariantArgs = {
  input: SkuSetPricingEngineSetVariantInput;
};

export type MutationskuUpdateArgs = {
  input: SkuUpdateInput;
};

export type MutationstartingPointsGenerateArgs = {
  input: StartingPointsGenerateInput;
};

export type MutationteamMemberHubspotUpdateArgs = {
  input: TeamMemberHubspotUpdateInput;
};

export type MutationteamMemberUpdateArgs = {
  input: TeamMemberUpdateInput;
};

export type MutationtoggleBuildUpdateLikeArgs = {
  input: ToggleBuildUpdateLikeInput;
};

export type MutationupdatePerformanceTierArgs = {
  input: UpdatePerformanceTierInput;
};

export type MutationupdateProposalPricingEngineBuildTimingArgs = {
  input: ProposalPricingEngineBuildTimingInput;
};

export type MutationupdateWorkOrderArgs = {
  input?: Maybe<UpdateWorkOrderInput>;
};

export type MutationuploadMediaArgs = {
  input: JobUploadMedia;
};

export type MutationuploadMilestoneMediaArgs = {
  input: MilestoneUploadMedia;
};

export type MutationuploadPunchListItemMediaArgs = {
  input: PunchListItemUploadMedia;
};

export type MutationuploadUserMediaArgs = {
  input: UploadUserMediaInput;
};

export type MutationuserCreateArgs = {
  input: UserCreateInput;
};

export type MutationuserReferralCampaignValidateArgs = {
  input: UserReferralCampaignValidateInput;
};

export type MutationuserUpdateArgs = {
  input: UserUpdateInput;
};

export type MutationverifyTokenArgs = {
  input: VerifyTokenInput;
};

export type MutationworkOrderContentUpdateArgs = {
  input?: Maybe<WorkOrderContentUpdateInput>;
};

export type MutationzipCodeValidateArgs = {
  input: ZipCodeValidateInput;
};

export type Node = {
  id: Scalars['ID'];
};

export enum NotificationContactPerson {
  CLIENT = 'CLIENT',
  CONTRACTOR = 'CONTRACTOR',
  OTHER = 'OTHER',
}

export enum NotificationContactType {
  FROM = 'FROM',
  TO = 'TO',
  REPLY_TO = 'REPLY_TO',
  CC = 'CC',
  BCC = 'BCC',
}

export type PastJob = {
  __typename?: 'PastJob';
  id: Scalars['ID'];
  street: Maybe<Scalars['String']>;
  streetSecond: Maybe<Scalars['String']>;
  customer: User;
  imgUrl: Maybe<Scalars['String']>;
};

export enum PaymentPreference {
  FINANCING = 'FINANCING',
  FULLY_FINANCED_AFTER_DESIGN = 'FULLY_FINANCED_AFTER_DESIGN',
  FULLY_FINANCED_BEFORE_DESIGN = 'FULLY_FINANCED_BEFORE_DESIGN',
  PARTIALLY_FINANCED_AFTER_DESIGN = 'PARTIALLY_FINANCED_AFTER_DESIGN',
  PARTIALLY_FINANCED_BEFORE_DESIGN = 'PARTIALLY_FINANCED_BEFORE_DESIGN',
  STANDARD = 'STANDARD',
  UNDECIDED = 'UNDECIDED',
  UPFRONT = 'UPFRONT',
  UPFRONT_AFTER_DESIGN = 'UPFRONT_AFTER_DESIGN',
  UPFRONT_BEFORE_DESIGN = 'UPFRONT_BEFORE_DESIGN',
}

export type PaymentSchedule = {
  __typename?: 'PaymentSchedule';
  id: Scalars['ID'];
  calculations: PaymentScheduleCalculation;
};

export type PaymentScheduleCalculation = {
  __typename?: 'PaymentScheduleCalculation';
  id: Scalars['ID'];
  total: Scalars['Int'];
  paid: Scalars['Int'];
  remaining: Scalars['Int'];
  categories: Array<PaymentScheduleCategoryCalculation>;
};

export type PaymentScheduleCategoryCalculation = {
  __typename?: 'PaymentScheduleCategoryCalculation';
  id: Scalars['ID'];
  category: CalculationCategory;
  total: Scalars['Int'];
  paid: Scalars['Int'];
  remaining: Scalars['Int'];
};

export type PaymentScheduleInput = {
  projectId: Scalars['ID'];
};

export type PaymentScheduleTemplate = {
  __typename?: 'PaymentScheduleTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  isDefault: Scalars['Boolean'];
};

export type PaymentScheduleTerm = {
  __typename?: 'PaymentScheduleTerm';
  id: Scalars['ID'];
  hasProcessingFee: Scalars['Boolean'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  isUpfrontPayment: Scalars['Boolean'];
  status: PaymentScheduleTermStatus;
  availabilityType: PaymentScheduleTermAvailabilityType;
  availabilityDate: Maybe<Scalars['DateTime']>;
  dueDate: Maybe<Scalars['DateTime']>;
  dueDays: Maybe<Scalars['Int']>;
  paidDate: Maybe<Scalars['DateTime']>;
  note: Maybe<Scalars['String']>;
  calculations: PaymentScheduleTermCalculation;
  financingAmount: Scalars['Int'];
  totalFinancingRemaining: Scalars['Int'];
  referenceItemId: Maybe<Scalars['String']>;
  items: Array<PaymentScheduleTermItem>;
  invoices: Array<PaymentScheduleTermInvoice>;
};

export enum PaymentScheduleTermAvailabilityType {
  NOT_SET = 'NOT_SET',
  MANUAL = 'MANUAL',
  REFERENCE = 'REFERENCE',
}

export type PaymentScheduleTermCalculation = {
  __typename?: 'PaymentScheduleTermCalculation';
  id: Scalars['ID'];
  subtotal: Scalars['Int'];
  paid: Scalars['Int'];
  refunded: Scalars['Int'];
  fees: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaymentScheduleTermCreateInput = {
  projectId: Scalars['ID'];
  name: Scalars['String'];
};

export type PaymentScheduleTermDeleteInput = {
  projectId: Scalars['ID'];
  paymentScheduleTermId: Scalars['ID'];
};

export type PaymentScheduleTermDepositCreateInput = {
  paymentScheduleTermId: Scalars['ID'];
  proposalId: Scalars['ID'];
};

export type PaymentScheduleTermInput = {
  paymentScheduleTermId: Scalars['ID'];
};

export type PaymentScheduleTermInvoice = {
  __typename?: 'PaymentScheduleTermInvoice';
  amount: Scalars['Int'];
  oldCheckoutUrl: Scalars['String'];
  id: Scalars['ID'];
  status: PaymentScheduleTermInvoiceStatus;
  stripeInvoiceId: Scalars['String'];
  stripeUrl: Scalars['String'];
};

export enum PaymentScheduleTermInvoiceStatus {
  OPEN = 'OPEN',
  PAID = 'PAID',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  VOID = 'VOID',
}

export enum PaymentScheduleTermPaymentType {
  DEPOSIT = 'DEPOSIT',
  BLOCK_SERVICES = 'BLOCK_SERVICES',
  MATERIALS_INVOICE = 'MATERIALS_INVOICE',
  PA_INVOICE = 'PA_INVOICE',
  BREAK_GROUND_INVOICE = 'BREAK_GROUND_INVOICE',
  FINAL_INVOICE_LESS_RETAINER = 'FINAL_INVOICE_LESS_RETAINER',
  FINAL_INVOICE = 'FINAL_INVOICE',
  FIRST_INVOICE = 'FIRST_INVOICE',
  RETAINER_INVOICE = 'RETAINER_INVOICE',
  OTHER_INVOICE = 'OTHER_INVOICE',
  CONTRACTOR_CHANGE_ORDER = 'CONTRACTOR_CHANGE_ORDER',
  PROGRESS_INVOICE = 'PROGRESS_INVOICE',
}
export enum PayoutScheduleTermPayoutType {
  DEPOSIT_PAYOUT = 'DEPOSIT_PAYOUT',
  FINAL_PAYOUT = 'FINAL_PAYOUT',
  FINAL_PAYOUT_LESS_RETAINER = 'FINAL_PAYOUT_LESS_RETAINER',
  OTHER_PAYOUT = 'OTHER_PAYOUT',
  PA_PAYOUT = 'PA_PAYOUT',
  RETAINER_PAYOUT = 'RETAINER_PAYOUT',
  ROUGH_IN_PAYOUT = 'ROUGH_IN_PAYOUT',
}

export type PaymentScheduleTermItem = {
  __typename?: 'PaymentScheduleTermItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  calculationType: CalculationType;
  calculationCategory: Maybe<CalculationCategory>;
  value: Maybe<Scalars['Int']>;
  cap: Maybe<Scalars['Int']>;
  amount: Scalars['Int'];
  isPending: Scalars['Boolean'];
  changeOrders: Array<{
    id: Scalars['ID'];
    title: Maybe<Scalars['String']>;
    status: ChangeOrderStatus;
  }>;
};

export type PaymentScheduleTermItemCreateInput = {
  paymentScheduleTermId: Scalars['ID'];
  name: Scalars['String'];
  calculationType: CalculationType;
  calculationCategory?: Maybe<CalculationCategory>;
  value?: Maybe<Scalars['Int']>;
  cap?: Maybe<Scalars['Int']>;
};

export type PaymentScheduleTermItemDeleteInput = {
  paymentScheduleTermId: Scalars['ID'];
  paymentScheduleTermItemId: Scalars['ID'];
};

export type PaymentScheduleTermItemUpdateInput = {
  paymentScheduleTermId: Scalars['ID'];
  paymentScheduleTermItemId: Scalars['ID'];
  name: Scalars['String'];
  calculationType: CalculationType;
  calculationCategory?: Maybe<CalculationCategory>;
  value?: Maybe<Scalars['Int']>;
  cap?: Maybe<Scalars['Int']>;
  changeOrderIds?: Maybe<Array<Scalars['ID']>>;
};

export type PaymentScheduleTermSetAvailableInput = {
  paymentScheduleTermId: Scalars['ID'];
  dueDate?: Maybe<Scalars['DateTime']>;
};

export type PaymentScheduleTermSetVoidInput = {
  paymentScheduleTermId: Scalars['ID'];
  reason: Scalars['String'];
};

export type PaymentScheduleTermsFromTemplateCreateInput = {
  projectId: Scalars['ID'];
  paymentScheduleTemplateId: Scalars['ID'];
};

export type PaymentScheduleTermsOrderUpdateInput = {
  projectId: Scalars['ID'];
  paymentScheduleTermIds: Array<Scalars['ID']>;
};

export enum PaymentScheduleTermStatus {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  PAID = 'PAID',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  VOID = 'VOID',
  OVERDUE = 'OVERDUE',
}

export type PaymentScheduleTermUpdateInput = {
  description?: Maybe<Scalars['String']>;
  hasProcessingFee: Scalars['Boolean'];
  name: Scalars['String'];
  paymentScheduleTermId: Scalars['ID'];
  financingAmount?: Maybe<Scalars['Int']>;
  paymentType: PaymentScheduleTermPaymentType | null;
};

export enum PayoutCalculationCategory {
  BONUS = 'BONUS',
  CHANGE_ORDERS = 'CHANGE_ORDERS',
  PERMITS_AND_APPROVALS = 'PERMITS_AND_APPROVALS',
  WORK_ORDER = 'WORK_ORDER',
}

export enum PayoutCalculationType {
  PERCENTAGE = 'PERCENTAGE',
  REMAINING_BALANCE = 'REMAINING_BALANCE',
  MANUAL = 'MANUAL',
}

export type PayoutSchedule = {
  __typename?: 'PayoutSchedule';
  id: Scalars['ID'];
  name: Scalars['String'];
  contractor: Contractor;
  terms: Array<PayoutScheduleTerm>;
  calculations: PayoutScheduleCalculation;
};

export type PayoutScheduleCalculation = {
  __typename?: 'PayoutScheduleCalculation';
  id: Scalars['ID'];
  total: Scalars['Int'];
  subtotal: Scalars['Int'];
  paid: Scalars['Int'];
  bonus: Scalars['Int'];
  totalRemaining: Scalars['Int'];
  subtotalRemaining: Scalars['Int'];
  workOrders: Scalars['Int'];
  changeOrders: Scalars['Int'];
  permits: Scalars['Int'];
};

export type PayoutScheduleCreateInput = {
  projectId: Scalars['ID'];
  name: Scalars['String'];
};

export type PayoutScheduleDeleteInput = {
  payoutScheduleId: Scalars['ID'];
  reason: Scalars['String'];
};

export type PayoutScheduleInput = {
  payoutScheduleId: Scalars['ID'];
};

export type PayoutScheduleTemplate = {
  __typename?: 'PayoutScheduleTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PayoutScheduleTerm = {
  __typename?: 'PayoutScheduleTerm';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  paidDate: Maybe<Scalars['DateTime']>;
  status: PayoutScheduleTermStatus;
  items: Array<PayoutScheduleTermItem>;
  calculations: PayoutScheduleTermCalculation;
};

export type PayoutScheduleTermCalculation = {
  __typename?: 'PayoutScheduleTermCalculation';
  id: Scalars['ID'];
  subtotal: Scalars['Int'];
};

export type PayoutScheduleTermCreateInput = {
  payoutScheduleId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type PayoutScheduleTermDeleteInput = {
  payoutScheduleTermId: Scalars['ID'];
};

export type PayoutScheduleTermInput = {
  payoutScheduleTermId: Scalars['ID'];
};

export type PayoutScheduleTermItem = {
  __typename?: 'PayoutScheduleTermItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  calculationType: Maybe<PayoutCalculationType>;
  calculationCategory: Maybe<PayoutCalculationCategory>;
  value: Maybe<Scalars['Int']>;
  cap: Maybe<Scalars['Int']>;
  amount: Scalars['Int'];
  changeOrder: Maybe<{
    id: Scalars['ID'];
    title: Maybe<Scalars['String']>;
    status: ChangeOrderStatus;
  }>;
};

export type PayoutScheduleTermItemCreateInput = {
  payoutScheduleTermId: Scalars['ID'];
  name: Scalars['String'];
  calculationCategory?: Maybe<PayoutCalculationCategory>;
  calculationType?: Maybe<PayoutCalculationType>;
  value?: Maybe<Scalars['Int']>;
  cap?: Maybe<Scalars['Int']>;
};

export type PayoutScheduleTermItemDeleteInput = {
  payoutScheduleTermId: Scalars['ID'];
  payoutScheduleTermItemId: Scalars['ID'];
};

export type PayoutScheduleTermItemUpdateInput = {
  payoutScheduleTermId: Scalars['ID'];
  payoutScheduleTermItemId: Scalars['ID'];
  name: Scalars['String'];
  calculationCategory?: Maybe<PayoutCalculationCategory>;
  calculationType?: Maybe<PayoutCalculationType>;
  value?: Maybe<Scalars['Int']>;
  cap?: Maybe<Scalars['Int']>;
  changeOrderId?: Maybe<Scalars['ID']>;
};

export type PayoutScheduleTermSetAvailableInput = {
  payoutScheduleTermId: Scalars['ID'];
};

export type PayoutScheduleTermSetPaidInput = {
  payoutScheduleTermId: Scalars['ID'];
};

export type PayoutScheduleTermSetVoidedInput = {
  payoutScheduleTermId: Scalars['ID'];
  note: Scalars['String'];
};

export type PayoutScheduleTermsFromTemplateCreateInput = {
  projectId: Scalars['ID'];
  payoutScheduleId: Scalars['ID'];
  payoutScheduleTemplateId: Scalars['ID'];
};

export enum PayoutScheduleTermStatus {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  PAID = 'PAID',
  VOID = 'VOID',
}

export type PayoutScheduleTermUpdateInput = {
  payoutScheduleTermId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  payoutType: PayoutScheduleTermPayoutType | null;
};

export type PayoutScheduleUpdateInput = {
  payoutScheduleId: Scalars['ID'];
  name: Scalars['String'];
};

export enum PerformanceTier {
  ASSOCIATE = 'ASSOCIATE',
  ELITE = 'ELITE',
  PRINCIPAL = 'PRINCIPAL',
}

export type Permit = {
  __typename?: 'Permit';
  id: Scalars['ID'];
  categoryId: Scalars['ID'];
  description: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Int'];
};

export type PermitCategory = {
  __typename?: 'PermitCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  permits: Array<Permit>;
};

export type PermitCreateInput = {
  categoryId: Scalars['ID'];
  description: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Int'];
};

export type PermitDeleteInput = {
  id: Scalars['ID'];
};

export type PermitUpdateInput = {
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  description: Scalars['String'];
  price: Scalars['Float'];
};

export type Planner = Node & {
  __typename?: 'Planner';
  id: Scalars['ID'];
  ctaText: Scalars['String'];
  token: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  sections: Array<Section>;
};

export type PlannerConfigurator = {
  __typename?: 'PlannerConfigurator';
  configuratorId: Scalars['ID'];
  renovationPlanId: Scalars['ID'];
  token: Maybe<Scalars['String']>;
  user: Maybe<User>;
};

export type PlannerResponse = {
  questionId: Scalars['ID'];
  responseText?: Maybe<Scalars['String']>;
  responseAddress?: Maybe<ResponseAddress>;
  answerIds?: Maybe<Array<Scalars['ID']>>;
};

export type PlannerResponsesInput = {
  addNewRoom: Scalars['Boolean'];
  token: Scalars['String'];
  responses: Array<PlannerResponse>;
  utmParams: UtmParams;
};

export enum PlanStatus {
  DASHBOARD = 'DASHBOARD',
  LEGACY_DASHBOARD = 'LEGACY_DASHBOARD',
  MY_PLAN = 'MY_PLAN',
  LEGACY_MY_PLAN = 'LEGACY_MY_PLAN',
}

export enum PresentationType {
  CHECKBOX = 'CHECKBOX',
  DROPDOWN = 'DROPDOWN',
  CONFIRM = 'CONFIRM',
  SECTION_NAME = 'SECTION_NAME',
  EMAIL = 'EMAIL',
  NUMBER = 'NUMBER',
  PHONE_NUMBER = 'PHONE_NUMBER',
  NAME = 'NAME',
  RADIO = 'RADIO',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  THUMBNAIL_LARGE = 'THUMBNAIL_LARGE',
  THUMBNAIL_SMALL = 'THUMBNAIL_SMALL',
  ZIP = 'ZIP',
  STARTING_POINTS = 'STARTING_POINTS',
  HDYH = 'HDYH',
  ADDRESS_AUTOCOMPLETE = 'ADDRESS_AUTOCOMPLETE',
  REFERRAL_CODE = 'REFERRAL_CODE',
  DUAL_DROPDOWN = 'DUAL_DROPDOWN',
}

export type PriceFixed = {
  __typename?: 'PriceFixed';
  fixed: Scalars['Int'];
};

export type PriceRange = {
  __typename?: 'PriceRange';
  min: Scalars['Int'];
  max: Scalars['Int'];
};

export type PriceVarying = PriceRange | PriceFixed;

export type PricingEngine = {
  __typename?: 'PricingEngine';
  id: Scalars['ID'];
  versionGroupId: Scalars['String'];
  name: Maybe<Scalars['String']>;
  roomType: RoomType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  proposal: Proposal;
  publishedAt: Maybe<Scalars['DateTime']>;
  costs: PricingEngineCost;
  /** TODO: Create ConsumerPricinEngine type */
  consumerCosts: ConsumerPricingEngineCost;
  notes: Maybe<Scalars['String']>;
  renders: Array<ConfiguratorRender>;
  showRender: Scalars['Boolean'];
  categories: Array<PricingEngineMaterialsCategory>;
  questions: Array<Question>;
  order: Scalars['Int'];
  buildTiming: BuildTiming;
  updates: Array<PricingEngineUpdate>;
};

export type PricingEngineConstructionCost = {
  __typename?: 'PricingEngineConstructionCost';
  labor: Scalars['Int'];
  allowances: Scalars['Int'];
  workOrder: Cost;
  margin: Cost;
  contingency: Cost;
  total: Scalars['Int'];
  discount: Scalars['Int'];
  subtotal: Scalars['Int'];
  priceAdjustment: Scalars['Int'];
};

export type PricingEngineConstructionSummaryCost = {
  __typename?: 'PricingEngineConstructionSummaryCost';
  labor: Scalars['Int'];
  allowances: Scalars['Int'];
  workOrder: Scalars['Int'];
  margin: Scalars['Int'];
  contingency: Scalars['Int'];
  constructionTotal: Scalars['Int'];
};

export type PricingEngineCost = {
  __typename?: 'PricingEngineCost';
  construction: PricingEngineConstructionCost;
  materials: PricingEngineMaterialsCost;
  services: PricingEngineServicesCost;
  total: PricingEngineTotalCost;
};

export type PricingEngineCostInput = {
  pricingEngineId: Scalars['ID'];
  costName: PricingEngineEditableCostName;
  costValue: Scalars['Float'];
};

export type PricingEngineCreateInput = {
  name: Scalars['String'];
  proposalId: Scalars['ID'];
  roomType: RoomType;
  answers?: Maybe<Array<AdminAnswerInput>>;
  templateId?: Maybe<Scalars['String']>;
};

export type PricingEngineDeleteInput = {
  pricingEngineId: Scalars['ID'];
};

export type PricingEngineDuplicateInput = {
  name: Scalars['String'];
  templateId?: Maybe<Scalars['String']>;
  proposalId: Scalars['String'];
};

export enum PricingEngineEditableCostName {
  CONTINGENCY = 'CONTINGENCY',
  MARGIN = 'MARGIN',
  PRICE_ADJUSTMENT = 'PRICE_ADJUSTMENT',
}

/** START - products */
export type PricingEngineMaterial = PricingEngineSkuSet | PricingEngineSku;

export type PricingEngineMaterialsCategory = {
  __typename?: 'PricingEngineMaterialsCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  externalName: Maybe<Scalars['String']>;
  clientOrder: Maybe<Scalars['Int']>;
  materialsCategory: Maybe<MaterialsCategory>;
  categoryChildren: Array<PricingEngineMaterialsCategory>;
  linesOfWork: Array<LineOfWorkCommon>;
  materials: Array<PricingEngineMaterial>;
};

export type PricingEngineMaterialsCategorylinesOfWorkArgs = {
  type?: Maybe<LineOfWorkLevel>;
  isOptional?: Maybe<Scalars['Boolean']>;
};

export type PricingEngineMaterialsCost = {
  __typename?: 'PricingEngineMaterialsCost';
  materials: Scalars['Int'];
  shipping: Scalars['Int'];
  tax: Scalars['Int'];
  total: Scalars['Int'];
};

export type PricingEngineMaterialsSummaryCost = {
  __typename?: 'PricingEngineMaterialsSummaryCost';
  materials: Scalars['Int'];
  shipping: Scalars['Int'];
  tax: Scalars['Int'];
  materialsTotal: Scalars['Int'];
};

export type PricingEngineRemoveInput = {
  pricingEngineId: Scalars['ID'];
};

export type PricingEngineRenderVisibilityInput = {
  pricingEngineId: Scalars['ID'];
  showRender: Scalars['Boolean'];
};

export type PricingEngineServicesCost = {
  __typename?: 'PricingEngineServicesCost';
  subtotal: Scalars['Int'];
  discount: Scalars['Int'];
  total: Scalars['Int'];
};

export type PricingEngineServicesSummaryCost = {
  __typename?: 'PricingEngineServicesSummaryCost';
  internal: Scalars['Int'];
};

export type PricingEngineSku = Node & {
  __typename?: 'PricingEngineSku';
  id: Scalars['ID'];
  unit: PricingEngineSkuProperties;
  totalPrice: Scalars['Int'];
  totalCost: Scalars['Int'];
  listPrice: Scalars['Int'];
  unitPrice: Scalars['Int'];
  status: SkuStatus;
  sku: Sku;
  category: PricingEngineMaterialsCategory;
  notes: Maybe<Scalars['String']>;
  configEnabled: Scalars['Boolean'];
};

export type PricingEngineSkuProperties = {
  __typename?: 'PricingEngineSkuProperties';
  quantity: Scalars['Int'];
  overage: Scalars['Int'];
  totalQuantity: Scalars['Int'];
};

export type PricingEngineSkuSet = Node & {
  __typename?: 'PricingEngineSkuSet';
  id: Scalars['ID'];
  totalPrice: Scalars['Int'];
  unitPrice: Scalars['Int'];
  listPrice: Scalars['Int'];
  quantity: Scalars['Int'];
  status: SkuStatus;
  skuSet: SkuSet;
  skus: Array<PricingEngineSku>;
};

export type PricingEngineSummary = {
  __typename?: 'PricingEngineSummary';
  markdown: Scalars['String'];
  costs: PricingEngineSummaryCost;
};

export type PricingEngineSummaryCost = {
  __typename?: 'PricingEngineSummaryCost';
  construction: PricingEngineConstructionSummaryCost;
  materials: PricingEngineMaterialsSummaryCost;
  services: PricingEngineServicesSummaryCost;
  total: PricingEngineTotalSummaryCost;
};

export type PricingEngineTotalCost = {
  __typename?: 'PricingEngineTotalCost';
  subtotal: Scalars['Int'];
  discount: Scalars['Int'];
  total: Scalars['Int'];
};

export type PricingEngineTotalSummaryCost = {
  __typename?: 'PricingEngineTotalSummaryCost';
  subtotal: Scalars['Int'];
  discount: Scalars['Int'];
  total: Scalars['Int'];
};

export type PricingEngineUpdate = {
  __typename?: 'PricingEngineUpdate';
  updatedAt: Maybe<Scalars['DateTime']>;
  labor: Array<PricingEngineUpdateLabor>;
  materials: Array<PricingEngineUpdateMaterial>;
};

export type PricingEngineUpdateInput = {
  pricingEngineId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AdminAnswerInput>>;
};

export type PricingEngineUpdateLabor = {
  __typename?: 'PricingEngineUpdateLabor';
  name: Scalars['String'];
  isNew: Scalars['Boolean'];
};

export type PricingEngineUpdateMaterial = {
  __typename?: 'PricingEngineUpdateMaterial';
  categoryName: Scalars['String'];
  productName: Scalars['String'];
  finish: Maybe<VariantType>;
  size: Maybe<VariantType>;
  pattern: Maybe<VariantType>;
  amount: Scalars['Int'];
  notes: Maybe<Scalars['String']>;
  isNew: Scalars['Boolean'];
};

export type PricingEngineUserRemoveInput = {
  pricingEngineId: Scalars['ID'];
};

export type PricingEngineValidateDataInput = {
  pricingEngineId: Scalars['ID'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  brand: Maybe<SkuBrand>;
  legend: Maybe<SkuLegend>;
  specUrl: Maybe<Scalars['String']>;
  manufacturerWebsite: Maybe<Scalars['String']>;
  catalogItem: Maybe<Scalars['Boolean']>;
  finishCatalog: Array<VariantType>;
  sizeCatalog: Array<VariantType>;
  patternCatalog: Array<VariantType>;
  category: MaterialsCategory;
  sku: Maybe<Sku>;
  skus: Array<Sku>;
};

export type ProductBrandInput = {
  name?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
};

export type ProductCreateInput = {
  name: Scalars['String'];
  roomType: RoomType;
  categoryId: Scalars['ID'];
  catalogItem?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<ProductBrandInput>;
  legend?: Maybe<Scalars['ID']>;
};

export type ProductDeleteInput = {
  id: Scalars['ID'];
};

export type ProductPricingEngineInput = {
  pricingEngineId: Scalars['ID'];
  pricingEngineSkuId: Scalars['ID'];
  pricingEngineSkuSetId?: Maybe<Scalars['ID']>;
  size?: Maybe<VariantTypeInput>;
  color?: Maybe<VariantTypeInput>;
  finish?: Maybe<VariantTypeInput>;
  pattern?: Maybe<VariantTypeInput>;
  quantity?: Maybe<Scalars['Int']>;
  overage?: Maybe<Scalars['Int']>;
  status?: Maybe<SkuStatus>;
  listPrice?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  procuredBy?: Maybe<PricingEngineSkuProcuredBy>;
};

export type ProductUpdateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  roomType: RoomType;
  categoryId: Scalars['ID'];
  catalogItem?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<ProductBrandInput>;
  legend?: Maybe<Scalars['ID']>;
};

export type Project = Node & {
  __typename?: 'Project';
  id: Scalars['ID'];
  address: Maybe<RenovationInfoAddress>;
  breakgroundDate: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  extensionNote: Maybe<Scalars['String']>;
  invoicePaymentDate: Maybe<Scalars['DateTime']>;
  serviceableStatus: ZipCodeStatus;
  projectStatus: ProjectStatus;
  existingConditionsLink: Maybe<Link>;
  procurementLinks: Array<Link>;
  workOrderLinks: Array<Link>;
  buildingApprovalFiles: Array<File>;
  designFiles: Array<File>;
  mediaFiles: Array<File>;
  members: Array<ProjectMember>;
  schedule: Maybe<ScheduleProject>;
  survey: Maybe<Survey>;
  user: User;
  proposal: Maybe<Proposal>;
  materials: Array<ProjectMaterial>;
  projectStatusChangeEvents: Maybe<Array<ProjectStatusChangeEvent>>;
  paymentScheduleTerms: Array<PaymentScheduleTerm>;
  paymentPreference: PaymentPreference;
  note: ProjectNote;
  hasLegacyProposals: Scalars['Boolean'];
  statementFiles: Array<File>;
  projectFinancing: Maybe<ProjectFinancing>;
  payoutSchedules: Array<PayoutSchedule>;
};

export type ProjectscheduleArgs = {
  input?: Maybe<ScheduleProjectFilterInput>;
};

export enum PROJECT_ITEM_CATEGORY {
  BUILDING_APPROVALS = 'BUILDING_APPROVALS',
  DESIGN = 'DESIGN',
  CONSTRUCTION = 'CONSTRUCTION',
  PROCUREMENT = 'PROCUREMENT',
  PRECON = 'PRECON',
  FINANCE = 'FINANCE',
}

export enum PROJECT_ITEM_SCHEDULE_REFERENCE {
  CLOSE_DATE = 'CLOSE_DATE',
  TARGET_BREAK_GROUND_DATE = 'TARGET_BREAK_GROUND_DATE',
  SCHEDULE_ITEM = 'SCHEDULE_ITEM',
  NONE = 'NONE',
}

export enum PROJECT_ITEM_STATUS {
  COMPLETED = 'COMPLETED',
  OVER_DUE = 'OVER_DUE',
  TO_DO = 'TO_DO',
}

export enum PROJECT_ITEM_TYPE {
  SCHEDULE = 'SCHEDULE',
  REVIEW = 'REVIEW',
  CONFIRM = 'CONFIRM',
  UPLOAD = 'UPLOAD',
  MILESTONE = 'MILESTONE',
  PROCUREMENT = 'PROCUREMENT',
  GENERIC = 'GENERIC',
}

export type ProjectBreakgroundDateInput = {
  id: Scalars['ID'];
  breakgroundDate?: Maybe<Scalars['DateTime']>;
};

export type ProjectDashboard = Node & {
  __typename?: 'ProjectDashboard';
  address: Maybe<Address>;
  buildUpdates: Array<BuildUpdate>;
  contractor: Maybe<ContractorMember>;
  documents: Array<File>;
  id: Scalars['ID'];
  keyDates: Array<KeyDate>;
  media: Array<File>;
  supportMembers: Array<SupportMember>;
};

export type ProjectExistingConditionsCreateInput = {
  projectId: Scalars['ID'];
  url: Scalars['String'];
};

export type ProjectExistingConditionsUpdateInput = {
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type ProjectFileArchiveInput = {
  id: Scalars['ID'];
  archived: Scalars['Boolean'];
};

export type ProjectFileOrderInput = {
  fromId: Scalars['ID'];
  toId: Scalars['ID'];
  fromOrdinal?: Maybe<Scalars['Int']>;
  toOrdinal?: Maybe<Scalars['Int']>;
};

export type ProjectFilter = {
  username?: Maybe<Scalars['String']>;
  teamMembers?: Maybe<Array<Scalars['ID']>>;
  projectStatus?: Maybe<ProjectStatus>;
  plannerId?: Maybe<Scalars['ID']>;
};

export type ProjectFinancing = {
  __typename?: 'ProjectFinancing';
  id: Scalars['ID'];
  amount: Scalars['Int'];
  totalApplied: Scalars['Int'];
  totalRemaining: Scalars['Int'];
  financingProvider: FinancingProvider;
  financingProviderTerm: FinancingProviderTerm;
};

export type ProjectFinancingCreateInput = {
  projectId: Scalars['ID'];
  financingProviderTermId: Scalars['ID'];
  amount: Scalars['Int'];
};

export type ProjectFinancingUpdateInput = {
  projectFinancingId: Scalars['ID'];
  financingProviderTermId: Scalars['ID'];
  amount: Scalars['Int'];
};

export type ProjectMaterial = Node & {
  __typename?: 'ProjectMaterial';
  id: Scalars['ID'];
  roomName: Scalars['String'];
  unit: PricingEngineSkuProperties;
  sku: Sku;
  category: PricingEngineMaterialsCategory;
};

/** TODO We need to reconsider this approach */
export type ProjectMember = TeamMember | Contractor;

export type ProjectMemberSetInput = {
  role: TeamMemberRole;
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type ProjectNameUpdateInput = {
  projectId: Scalars['ID'];
  name: Scalars['String'];
};

export type ProjectNote = {
  __typename?: 'ProjectNote';
  id: Scalars['ID'];
  extensionNote: Maybe<Scalars['String']>;
  salesNote: Maybe<Scalars['String']>;
  kickOffNote: Maybe<Scalars['String']>;
  surveyNote: Maybe<Scalars['String']>;
  preDesignNote: Maybe<Scalars['String']>;
  designNote: Maybe<Scalars['String']>;
  clientOpsNote: Maybe<Scalars['String']>;
  generalNote: Maybe<Scalars['String']>;
};

export type ProjectNoteUpdateInput = {
  projectId: Scalars['ID'];
  clientOpsNote?: Maybe<Scalars['String']>;
  designNote?: Maybe<Scalars['String']>;
  extensionNote?: Maybe<Scalars['String']>;
  generalNote?: Maybe<Scalars['String']>;
  kickOffNote?: Maybe<Scalars['String']>;
  preDesignNote?: Maybe<Scalars['String']>;
  salesNote?: Maybe<Scalars['String']>;
  surveyNote?: Maybe<Scalars['String']>;
};

export type ProjectPaymentPreferenceUpdateInput = {
  projectId: Scalars['ID'];
  paymentPreference: PaymentPreference;
};

export type ProjectProcurementLinkCreate = {
  projectId: Scalars['ID'];
  url: Scalars['String'];
  name: Scalars['String'];
};

export type ProjectScheduleFilter = {
  __typename?: 'ProjectScheduleFilter';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProjectScheduleFilterInput = {
  assigneeId?: Maybe<Scalars['ID']>;
  teamMemberId?: Maybe<Scalars['ID']>;
  category?: Maybe<PROJECT_ITEM_CATEGORY>;
};

export type ProjectScheduleFilters = {
  __typename?: 'ProjectScheduleFilters';
  assignment: Array<ProjectScheduleFilter>;
  teamMember: Array<ProjectScheduleFilter>;
  category: Array<PROJECT_ITEM_CATEGORY>;
};

export type ProjectScheduleItemsConnection = {
  __typename?: 'ProjectScheduleItemsConnection';
  totalCount: Scalars['Int'];
  data: Array<ScheduleProjectItem>;
};

export type ProjectScheduleItemsInput = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<ProjectScheduleFilterInput>;
};

export type ProjectNotification = {
  __typename?: 'ProjectNotification';
  id: Scalars['ID'];
  name: Scalars['String'];
  active: Scalars['Boolean'];
  trigger: ProjectNotificationTrigger;
  templateId: Scalars['ID'];
  days: Maybe<Scalars['Int']>;
  repeat: Maybe<Scalars['Boolean']>;
  contacts: Array<Maybe<ProjectNotificationContact>>;
};

export type ProjectScheduleNotificationConfigTemplate = {
  __typename?: 'ProjectScheduleNotificationConfigTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  trigger: ProjectScheduleNotificationConfigTemplateTrigger;
  templateId: Scalars['ID'];
  days: Maybe<Scalars['Int']>;
  repeat: Maybe<Scalars['Boolean']>;
  contacts: Array<Maybe<ProjectScheduleNotificationConfigTemplateContact>>;
};

export type ProjectScheduleNotificationConfigTemplateContact = {
  __typename?: 'ProjectScheduleNotificationConfigTemplateContact';
  type: NotificationContactType;
  person: Maybe<NotificationContactPerson>;
  emails: Maybe<
    Array<Maybe<ProjectScheduleNotificationConfigTemplateContactEmail>>
  >;
};

export type ProjectScheduleNotificationConfigTemplateContactEmail = {
  __typename?: 'ProjectScheduleNotificationConfigTemplateContactEmail';
  alias: Maybe<Scalars['String']>;
  address: Scalars['String'];
};

export type ProjectScheduleNotificationConfigTemplateContactEmailInput = {
  alias?: Maybe<Scalars['String']>;
  address: Scalars['String'];
};

export type ProjectScheduleNotificationConfigTemplateContactInput = {
  type: NotificationContactType;
  person?: Maybe<NotificationContactPerson>;
  emails?: Maybe<
    Array<Maybe<ProjectScheduleNotificationConfigTemplateContactEmailInput>>
  >;
};

export type ProjectScheduleNotificationConfigTemplateCreateInput = {
  scheduleGenericItemId: Scalars['ID'];
  name: Scalars['String'];
  trigger: ProjectScheduleNotificationConfigTemplateTrigger;
  templateId: Scalars['ID'];
  days?: Maybe<Scalars['Int']>;
  repeat?: Maybe<Scalars['Boolean']>;
  contacts: Array<Maybe<ProjectScheduleNotificationConfigTemplateContactInput>>;
};

export type ProjectScheduleNotificationConfigTemplateDeleteInput = {
  notificationConfigTemplateId: Scalars['ID'];
};

export type ProjectScheduleNotificationConfigTemplateInput = {
  notificationId: Scalars['ID'];
};

export type ProjectScheduleNotificationConfigTemplatesInput = {
  scheduleGenericItemId: Scalars['ID'];
};

export enum ProjectScheduleNotificationConfigTemplateTrigger {
  COMPLETION = 'COMPLETION',
  ADVANCE = 'ADVANCE',
  OVERDUE = 'OVERDUE',
  DUE_DATE_SHIFTED = 'DUE_DATE_SHIFTED',
}

export type ProjectScheduleNotificationConfigTemplateUpdateInput = {
  notificationConfigTemplateId: Scalars['ID'];
  name: Scalars['String'];
  trigger: ProjectScheduleNotificationConfigTemplateTrigger;
  templateId: Scalars['ID'];
  days?: Maybe<Scalars['Int']>;
  repeat?: Maybe<Scalars['Boolean']>;
  contacts: Array<Maybe<ProjectScheduleNotificationConfigTemplateContactInput>>;
};

export type ProjectNotificationContact = {
  __typename?: 'ProjectNotificationContact';
  type: NotificationContactType;
  person: Maybe<NotificationContactPerson>;
  emails: Maybe<Array<Maybe<ProjectNotificationContactEmail>>>;
};

export type ProjectNotificationContactEmail = {
  __typename?: 'ProjectNotificationContactEmail';
  alias: Maybe<Scalars['String']>;
  address: Scalars['String'];
};

export type ProjectNotificationContactEmailInput = {
  alias?: Maybe<Scalars['String']>;
  address: Scalars['String'];
};

export type ProjectNotificationContactInput = {
  type: NotificationContactType;
  person?: Maybe<NotificationContactPerson>;
  emails?: Maybe<Array<Maybe<ProjectNotificationContactEmailInput>>>;
};

export type ProjectScheduleNotificationCreateInput = {
  scheduleItemId: Scalars['ID'];
  name: Scalars['String'];
  trigger: ProjectNotificationTrigger;
  templateId: Scalars['ID'];
  days?: Maybe<Scalars['Int']>;
  repeat?: Maybe<Scalars['Boolean']>;
  contacts: Array<Maybe<ProjectNotificationContactInput>>;
};

export type ProjectTaskNotificationCreateInput = {
  taskId: Scalars['ID'];
  name: Scalars['String'];
  trigger: ProjectNotificationTrigger;
  templateId: Scalars['ID'];
  days?: Maybe<Scalars['Int']>;
  repeat?: Maybe<Scalars['Boolean']>;
  contacts: Array<Maybe<ProjectNotificationContactInput>>;
};

export type ProjectNotificationDeleteInput = {
  notificationId: Scalars['ID'];
};

export type ProjectNotificationInput = {
  notificationId: Scalars['ID'];
};

export type ProjectScheduleNotificationsInput = {
  scheduleItemId: Scalars['ID'];
  trigger?: Maybe<ProjectNotificationTrigger>;
  active?: Maybe<Scalars['Boolean']>;
};

export type ProjectScheduleNotificationTemplate = {
  __typename?: 'ProjectScheduleNotificationTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  templateId: Scalars['ID'];
};

export type ProjectTaskNotificationsInput = {
  taskId: Scalars['ID'];
  trigger?: Maybe<ProjectNotificationTrigger>;
  active?: Maybe<Scalars['Boolean']>;
};

export type ProjectNotificationToggleActiveInput = {
  notificationId: Scalars['ID'];
};

export enum ProjectNotificationTrigger {
  COMPLETION = 'COMPLETION',
  ADVANCE = 'ADVANCE',
  OVERDUE = 'OVERDUE',
  DUE_DATE_SHIFTED = 'DUE_DATE_SHIFTED',
  UNLOCK = 'UNLOCK',
}

export type ProjectNotificationUpdateInput = {
  notificationId: Scalars['ID'];
  name: Scalars['String'];
  trigger: ProjectNotificationTrigger;
  templateId: Scalars['ID'];
  days?: Maybe<Scalars['Int']>;
  repeat?: Maybe<Scalars['Boolean']>;
  contacts: Array<Maybe<ProjectNotificationContactInput>>;
};

export type ProjectsConnection = {
  __typename?: 'ProjectsConnection';
  totalCount: Scalars['Int'];
  edges: Array<Project>;
};

export enum ProjectStatus {
  ACTIVE = 'ACTIVE',
  ON_HOLD = 'ON_HOLD',
  LOST = 'LOST',
  COMPLETED = 'COMPLETED',
}

export type ProjectStatusChangeEvent = {
  __typename?: 'ProjectStatusChangeEvent';
  id: Scalars['ID'];
  oldValue: ProjectStatus;
  newValue: ProjectStatus;
  createdAt: Scalars['DateTime'];
};

export type ProjectStatusUpdateInput = {
  id: Scalars['ID'];
  status: ProjectStatus;
};

export type ProjectUploadFile = {
  projectId: Scalars['ID'];
  file: Scalars['Upload'];
  type: Scalars['String'];
  captureDate?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type ProjectUploadFiles = {
  projectId: Scalars['ID'];
  files: Array<Scalars['Upload']>;
  type: Scalars['String'];
  captureDate?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type ProjectWorkOrderLinkCreate = {
  projectId: Scalars['ID'];
  url: Scalars['String'];
  name: Scalars['String'];
};

export type PropertyProjectUpdateAnswerInput = {
  questionId: Scalars['ID'];
  answerIds: Array<Scalars['ID']>;
};

export type PropertyProjectUpdateInput = {
  projectId: Scalars['ID'];
  answerIds?: Maybe<Array<Scalars['ID']>>;
  answers?: Maybe<Array<PropertyProjectUpdateAnswerInput>>;
};

export type Proposal = {
  __typename?: 'Proposal';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  details: ProposalDetails;
  status: ProposalStatus;
  type: ProposalType;
  totalCost: Scalars['Int'];
  isPermitRequired: Maybe<Scalars['Boolean']>;
  isPublishDisabled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  publishedAt: Maybe<Scalars['DateTime']>;
  expiresAt: Maybe<Scalars['DateTime']>;
  acceptedAt: Maybe<Scalars['DateTime']>;
  requestedAt: Maybe<Scalars['DateTime']>;
  requireAcceptance: Scalars['Boolean'];
  renovationPlan: RenovationPlan;
  user: User;
  pricingEngines: Array<PricingEngine>;
  paymentScheduleTermId: Maybe<Scalars['ID']>;
  laborVersion: Scalars['Int'];
};

export type ProposalAcceptInput = {
  proposalId: Scalars['ID'];
};

export enum ProposalConstruction {
  SINGLE = 'SINGLE',
  RANGED = 'RANGED',
}

export type ProposalConstructionCost = {
  __typename?: 'ProposalConstructionCost';
  allowances: Scalars['Int'];
  contingency: Cost;
  discount: Scalars['Int'];
  labor: Scalars['Int'];
  margin: Cost;
  subtotal: Scalars['Int'];
  total: Scalars['Int'];
  workOrder: Cost;
};

export type ProposalCost = {
  __typename?: 'ProposalCost';
  discounts: Array<ProposalCostDiscount>;
  construction: ProposalConstructionCost;
  materials: ProposalMaterialCost;
  services: Cost;
  permits: ProposalPermitCost;
  total: ProposalTotalCost;
};

export type ProposalCostDiscount = {
  __typename?: 'ProposalCostDiscount';
  amount: Scalars['Int'];
  name: Scalars['String'];
};

export type ProposalCreateInput = {
  name: Scalars['String'];
  renovationPlanId: Scalars['ID'];
};

export type ProposalDetails = {
  __typename?: 'ProposalDetails';
  proposalFinancings: Array<ProposalFinancing>;
  proposalPermits: Array<ProposalPermit>;
  proposalDiscounts: Array<Discount>;
  constructionType: ProposalConstruction;
  depositAmount: Scalars['Int'];
  timeline: Maybe<Scalars['String']>;
  paymentSchedule: Maybe<Scalars['String']>;
  costs: ProposalCost;
};

export type ProposalDetailsUpdateInput = {
  proposalId: Scalars['ID'];
  constructionType: ProposalConstruction;
  expiresAt?: Maybe<Scalars['DateTime']>;
  timeline: Scalars['String'];
  paymentSchedule?: Maybe<Scalars['String']>;
  paymentScheduleTermId?: Maybe<Scalars['ID']>;
};

export type ProposalDiscount = {
  __typename?: 'ProposalDiscount';
  id: Scalars['ID'];
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  distribution: DiscountDistribution;
  name: Scalars['String'];
  roomType: DiscountRoomType;
  discountType: DiscountType;
  discount: Maybe<MasterDiscount>;
};

export type ProposalDiscountCreateInput = {
  amount: Scalars['Int'];
  distribution: DiscountDistribution;
  name: Scalars['String'];
  roomType: DiscountRoomType;
  discountType: DiscountType;
  proposalId: Scalars['ID'];
};

export type ProposalValueDiscountCreateInput = {
  valueAddedAmount: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  proposalId: Scalars['ID'];
};

export type ProposalDiscountRemoveInput = {
  proposalDiscountId: Scalars['ID'];
};

export type ProposalDiscountSelectMasterDiscountInput = {
  discountId: Scalars['ID'];
  proposalId: Scalars['ID'];
};

export type ProposalDiscountUpdateInput = {
  proposalDiscountId: Scalars['ID'];
  amount: Scalars['Int'];
  distribution: DiscountDistribution;
  name: Scalars['String'];
  roomType: DiscountRoomType;
  discountType: DiscountType;
  expiresAt?: Scalars['DateTime'];
};

export type ProposalValueDiscountUpdateInput = {
  proposalDiscountId: Scalars['ID'];
  valueAddedAmount: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
};

export type ProposalDuplicateInput = {
  proposalId: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<ProposalDuplicateType>;
  contractorOrganizationId?: Scalars['ID'];
};

export enum ProposalDuplicateType {
  CONTRACTOR = 'CONTRACTOR',
  DESIGN = 'DESIGN',
  SALES = 'SALES',
}

export type ProposalFinancing = {
  __typename?: 'ProposalFinancing';
  id: Scalars['ID'];
  financing: Financing;
};

export type ProposalFinancingToggle = {
  __typename?: 'ProposalFinancingToggle';
  financingId: Scalars['ID'];
};

export type ProposalFinancingToggleInput = {
  financingId: Scalars['ID'];
  proposalId: Scalars['ID'];
};

export type ProposalMaterialCost = {
  __typename?: 'ProposalMaterialCost';
  materials: Scalars['Int'];
  shipping: Scalars['Int'];
  tax: Scalars['Int'];
  total: Scalars['Int'];
};

export type ProposalNameUpdateInput = {
  proposalId: Scalars['ID'];
  name: Scalars['String'];
};

export type ProposalPermit = {
  __typename?: 'ProposalPermit';
  id: Scalars['ID'];
  permit: Permit;
  quantity: Scalars['Int'];
  totalPrice: Scalars['Int'];
  price: Scalars['Int'];
  description: Scalars['String'];
};

export type ProposalPermitBatchCreateInput = {
  proposalId: Scalars['ID'];
  permits: Array<ProposalPermitCreateInput>;
};

export type ProposalPermitCost = {
  __typename?: 'ProposalPermitCost';
  total: Scalars['Int'];
};

export type ProposalPermitCreateInput = {
  permitId: Scalars['ID'];
};

export type ProposalPermitRemoveInput = {
  id: Scalars['ID'];
};

export type ProposalPermitRequiredInput = {
  proposalId: Scalars['ID'];
  isPermitRequired?: Maybe<Scalars['Boolean']>;
};

export type ProposalPermitsRemoveInput = {
  proposalId: Scalars['ID'];
};

export type ProposalPermitUpdateInput = {
  id: Scalars['ID'];
  description: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
};

export type ProposalPricingEngineBuildTimingInput = {
  proposalId: Scalars['ID'];
  buildTimings: Array<BuildTimingInput>;
};

export type ProposalPublishInput = {
  proposalId: Scalars['ID'];
};

export type ProposalRemoveInput = {
  proposalId: Scalars['ID'];
};

export type ProposalRequestNewInput = {
  proposalId: Scalars['ID'];
};

export type ProposalRequireAcceptanceUpdateInput = {
  proposalId: Scalars['ID'];
  requireAcceptance: Scalars['Boolean'];
};

export enum ProposalResettableField {
  TIMELINE_MARKDOWN = 'TIMELINE_MARKDOWN',
  ESTIMATE_MARKDOWN = 'ESTIMATE_MARKDOWN',
}

export enum ProposalStatus {
  ACCEPTED = 'ACCEPTED',
  PROPOSED = 'PROPOSED',
  DRAFT = 'DRAFT',
  EXPIRED = 'EXPIRED',
  PUBLISHED = 'PUBLISHED',
  REQUESTED = 'REQUESTED',
}

export type ProposalTotalCost = {
  __typename?: 'ProposalTotalCost';
  subtotal: Scalars['Int'];
  discount: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ProposalType {
  CONFIGURATOR = 'CONFIGURATOR',
  CONTRACTOR = 'CONTRACTOR',
  DESIGN = 'DESIGN',
  SALES = 'SALES',
  LOOKS = 'LOOKS',
}

export type ProposalUnPublishInput = {
  proposalId: Scalars['ID'];
};

export type ProposalVersionCloseInput = {
  projectId: Scalars['ID'];
};

export type PunchListCompleteInput = {
  id: Scalars['ID'];
  completionNote?: Maybe<Scalars['String']>;
};

export type PunchListFile = {
  __typename?: 'PunchListFile';
  id: Scalars['ID'];
  name: Scalars['String'];
  mimeType: Scalars['String'];
  url: Scalars['String'];
  thumbnailUrl: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  captureDate: Maybe<Scalars['DateTime']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
};

export enum PunchListFileSource {
  CONTRACTOR = 'CONTRACTOR',
  CONSUMER = 'CONSUMER',
}

export type PunchListItem = {
  __typename?: 'PunchListItem';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  createdAt: Scalars['DateTime'];
  completedAt: Maybe<Scalars['DateTime']>;
  photos: Array<PunchListFile>;
  completionNote: Maybe<Scalars['String']>;
  milestone: Maybe<Milestone>;
};

export type PunchListItemphotosArgs = {
  input: PunchListItemPhotosInput;
};

export type PunchListItemCreateInput = {
  milestoneId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  description: Scalars['String'];
  photos?: Maybe<Array<Scalars['Upload']>>;
};

export type PunchListItemInput = {
  id: Scalars['ID'];
};

export type PunchListItemPhotosInput = {
  source: PunchListFileSource;
};

export type PunchListItemUploadMedia = {
  punchListItemId: Scalars['ID'];
  file: Scalars['Upload'];
  captureDate?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type PunchListItemUploadMediaInput = {
  punchListItemId: Scalars['ID'];
  file: Scalars['Upload'];
  captureDate?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export enum PunchListState {
  NOT_STARTED = 'NOT_STARTED',
  OPEN = 'OPEN',
}

export type Query = {
  __typename?: 'Query';
  activeJobs: Array<ActiveJob>;
  activeWorkOrder: WorkOrder;
  activeWorkOrders: Array<Maybe<WorkOrder>>;
  changeOrders: Array<ChangeOrder>;
  changeOrdersPricingEngines: Array<PricingEngine>;
  changeOrdersSummaryCalculations: Maybe<ChangeOrdersSummaryCalculations>;
  checkoutInvoice: CheckoutInvoice;
  checkoutPaymentMethods: Array<CheckoutPaymentMethod>;
  checkoutWireTransferInformation: Maybe<CheckoutWireTransferInformation>;
  configurator: Configurator;
  consumerLegacyPayment: ConsumerLegacyPayment;
  consumerPaymentSchedule: ConsumerPaymentSchedule;
  consumerSummaryProposal: ConsumerSummaryProposal;
  consumerSummaryProposalV2: ConsumerSummaryProposalV2;
  contractor: Maybe<Contractor>;
  contractorProfile: ContractorProfile;
  contractorPunchListItem: PunchListItem;
  contractorPunchListItems: Array<PunchListItem>;
  contractors: Array<Contractor>;
  dictionaries: Array<Dictionary>;
  fetchNewAcceptedProposal: Maybe<Proposal>;
  financingProviderTerms: Array<FinancingProviderTerm>;
  financingProviders: Array<FinancingProvider>;
  financings: Array<Financing>;
  getMilestonesDueDates: Array<Maybe<Milestone>>;
  hasNewAcceptedProposal: Maybe<Scalars['Boolean']>;
  job: Job;
  jobFinancesTerm: JobFinancesTerm;
  legends: Array<Legend>;
  marketplaceSku: MarketplaceSku;
  materialsCategories: Array<MaterialsCategory>;
  materialsCategory: MaterialsCategory;
  materialsSummaryCsv: MaterialSummaryCsv;
  me: User;
  milestoneReasons: Maybe<Array<MilestoneReason>>;
  pastJobs: Array<PastJob>;
  paymentSchedule: PaymentSchedule;
  paymentScheduleTemplates: Array<PaymentScheduleTemplate>;
  paymentScheduleTerm: PaymentScheduleTerm;
  payoutSchedule: PayoutSchedule;
  payoutScheduleTemplates: Array<PayoutScheduleTemplate>;
  payoutScheduleTerm: PayoutScheduleTerm;
  permitCategories: Array<PermitCategory>;
  planner: Planner;
  pricingEngine: PricingEngine;
  pricingEngineLinesOfWork: Array<LineOfWorkCommon>;
  pricingEngineLinesOfWorkTotal: LineOfWorkCostTotal;
  pricingEngineMaterialCategories: Array<PricingEngineMaterialsCategory>;
  pricingEngineSkus: Array<PricingEngineSku>;
  product: Product;
  productSkus: Array<Sku>;
  productVariant: Maybe<Product>;
  project: Maybe<Project>;
  projectDashboard: Maybe<ProjectDashboard>;
  projectMembers: Array<ProjectMember>;
  projectPlanners: Array<Planner>;
  projectScheduleFilters: Maybe<ScheduleFilters>;
  projectScheduleNotification: ProjectNotification;
  projectScheduleNotificationConfigTemplate: ProjectScheduleNotificationConfigTemplate;
  projectScheduleNotificationConfigTemplates: Array<
    Maybe<ProjectScheduleNotificationConfigTemplate>
  >;
  projectScheduleNotificationTemplates: Array<
    Maybe<ProjectScheduleNotificationTemplate>
  >;
  projectScheduleNotifications: Array<Maybe<ProjectNotification>>;
  projectTeamMembers: Array<TeamMember>;
  projects: ProjectsConnection;
  proposal: Proposal;
  proposalDiscount: ProposalDiscount;
  proposals: Array<Proposal>;
  punchListItem: PunchListItem;
  punchListItems: Maybe<Array<PunchListItem>>;
  punchListState: PunchListState;
  questionRoomType: Question;
  questionsBuildingInfo: Array<Question>;
  questionsConditional: Array<Question>;
  renovationPlan: Maybe<Project>;
  renovationPlanWorkOrders: Array<WorkOrder>;
  renovationPlans: Array<RenovationPlan>;
  scheduleGenericItems: Array<ScheduleGenericItem>;
  scheduleProjectItem: ScheduleProjectItem;
  scheduleProjectItems: ProjectScheduleItemsConnection;
  scheduleProjectItemsFilters: Maybe<ProjectScheduleFilters>;
  scheduleTemplates: Array<ScheduleTemplate>;
  skuSetVariant: Maybe<SkuSet>;
  teamMember: Maybe<TeamMember>;
  teamMembers: Array<TeamMember>;
  user: User;
  userReferralCampaign: Maybe<UserReferralCampaign>;
  users: Array<User>;
  userAccounts: UserAccountsPage;
  vendors: Array<Vendor>;
  workOrder: WorkOrder;
  workOrderContent: WorkOrderContent;
  workOrderPreview: WorkOrderPreview;
  workOrderProposal: WorkOrderProposal;
  workOrderReasons: Array<WorkOrderReason>;
};

export type QueryactiveWorkOrderArgs = {
  input: ActiveWorkOrderInput;
};

export type QuerychangeOrdersArgs = {
  input: ChangeOrdersInput;
};

export type QuerychangeOrdersPricingEnginesArgs = {
  projectId: Scalars['ID'];
};

export type QuerychangeOrdersSummaryCalculationsArgs = {
  input: ChangeOrdersSummaryCalculationsInput;
};

export type QuerycheckoutInvoiceArgs = {
  invoiceId: Scalars['ID'];
};

export type QuerycheckoutPaymentMethodsArgs = {
  input?: Maybe<CheckoutPaymentMethodsInput>;
};

export type QuerycheckoutWireTransferInformationArgs = {
  input?: Maybe<CheckoutWireTransferInformationInput>;
};

export type QueryconfiguratorArgs = {
  id: Scalars['ID'];
};

export type QueryconsumerSummaryProposalArgs = {
  proposalId: Scalars['ID'];
};

export type QueryconsumerSummaryProposalV2Args = {
  proposalId: Scalars['ID'];
};

export type QuerycontractorArgs = {
  id: Scalars['ID'];
};

export type QuerycontractorPunchListItemArgs = {
  input: ContractorPunchListItemInput;
};

export type QuerycontractorPunchListItemsArgs = {
  input: ContractorPunchListItemsInput;
};

export type QuerydictionariesArgs = {
  types: Array<DictionaryType>;
};

export type QueryfetchNewAcceptedProposalArgs = {
  id: Scalars['ID'];
};

export type QueryfinancingProviderTermsArgs = {
  input: FinancingProviderTermsInput;
};

export type QuerygetMilestonesDueDatesArgs = {
  input?: Maybe<GetMilestonesDueDatesInput>;
};

export type QueryhasNewAcceptedProposalArgs = {
  id: Scalars['ID'];
};

export type QueryjobArgs = {
  id: Scalars['ID'];
};

export type QueryjobFinancesTermArgs = {
  input: JobFinancesTermInput;
};

export type QuerylegendsArgs = {
  materialCategoryId: Scalars['ID'];
};

export type QuerymarketplaceSkuArgs = {
  input?: Maybe<MarketplaceSkuInput>;
};

export type QuerymaterialsCategoriesArgs = {
  input?: Maybe<MaterialsCategoriesFilterInput>;
};

export type QuerymaterialsCategoryArgs = {
  id: Scalars['ID'];
};

export type QuerymaterialsSummaryCsvArgs = {
  pricingEngineId: Scalars['ID'];
};

export type QuerypaymentScheduleArgs = {
  input: PaymentScheduleInput;
};

export type QuerypaymentScheduleTermArgs = {
  input: PaymentScheduleTermInput;
};

export type QuerypayoutScheduleArgs = {
  input: PayoutScheduleInput;
};

export type QuerypayoutScheduleTermArgs = {
  input: PayoutScheduleTermInput;
};

export type QueryplannerArgs = {
  token: Scalars['String'];
};

export type QuerypricingEngineArgs = {
  id: Scalars['ID'];
};

export type QuerypricingEngineLinesOfWorkArgs = {
  pricingEngineId: Scalars['ID'];
  type?: Maybe<LineOfWorkLevel>;
};

export type QuerypricingEngineLinesOfWorkTotalArgs = {
  pricingEngineId: Scalars['ID'];
  type: Array<LineOfWorkLevel>;
};

export type QuerypricingEngineMaterialCategoriesArgs = {
  pricingEngineId: Scalars['ID'];
};

export type QuerypricingEngineSkusArgs = {
  pricingEngineId: Scalars['ID'];
};

export type QueryproductArgs = {
  productId: Scalars['ID'];
};

export type QueryproductSkusArgs = {
  productId: Scalars['ID'];
};

export type QueryproductVariantArgs = {
  productId: Scalars['ID'];
  finish?: Maybe<VariantTypeInput>;
  size?: Maybe<VariantTypeInput>;
  pattern?: Maybe<VariantTypeInput>;
};

export type QueryprojectArgs = {
  id: Scalars['ID'];
};

export type QueryprojectScheduleFiltersArgs = {
  id: Scalars['ID'];
};

export type QueryprojectNotificationArgs = {
  input: ProjectNotificationInput;
};

export type QueryprojectScheduleNotificationConfigTemplateArgs = {
  input: ProjectScheduleNotificationConfigTemplateInput;
};

export type QueryprojectScheduleNotificationConfigTemplatesArgs = {
  input: ProjectScheduleNotificationConfigTemplatesInput;
};

export type QueryprojectScheduleNotificationsArgs = {
  input: ProjectScheduleNotificationsInput;
};

export type QueryprojectTeamMembersArgs = {
  projectId: Scalars['ID'];
};

export type QueryprojectsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<ProjectFilter>;
};

export type QueryproposalArgs = {
  id: Scalars['ID'];
};

export type QueryproposalDiscountArgs = {
  proposalDiscountId: Scalars['ID'];
};

export type QueryproposalsArgs = {
  renovationPlanId: Scalars['ID'];
};

export type QuerypunchListItemArgs = {
  input: PunchListItemInput;
};

export type QueryquestionsConditionalArgs = {
  answerIds: Array<Scalars['String']>;
};

export type QueryrenovationPlanArgs = {
  id: Scalars['ID'];
};

export type QueryrenovationPlanWorkOrdersArgs = {
  input: WorkOrdersInput;
};

export type QueryscheduleProjectItemArgs = {
  input: ScheduleProjectItemInput;
};

export type QueryscheduleProjectItemsArgs = {
  input?: Maybe<ProjectScheduleItemsInput>;
};

export type QueryskuSetVariantArgs = {
  skuSetId: Scalars['ID'];
  finish?: Maybe<VariantTypeInput>;
  size?: Maybe<VariantTypeInput>;
  pattern?: Maybe<VariantTypeInput>;
};

export type QueryteamMemberArgs = {
  id: Scalars['ID'];
};

export type QueryuserArgs = {
  id: Scalars['ID'];
};

export type QueryuserReferralCampaignArgs = {
  input?: Maybe<UserReferralCampaignInput>;
};

export type QueryusersArgs = {
  input: UsersInput;
};

export type QueryuserAccountsArgs = {
  input: UserAccountsInput;
};

export type QueryworkOrderArgs = {
  input: WorkOrderInput;
};

export type QueryworkOrderPreviewArgs = {
  input: WorkOrderPreviewInput;
};

export type QueryworkOrderProposalArgs = {
  input: WorkOrderProposalInput;
};

export type Question = Node & {
  __typename?: 'Question';
  id: Scalars['ID'];
  title: Maybe<Scalars['String']>;
  label: Scalars['String'];
  description: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  placeholder: Maybe<Scalars['String']>;
  presentationType: PresentationType;
  systemType: SystemType;
  isSingleSelect: Scalars['Boolean'];
  answers: Array<Answer>;
  openAnswer: Maybe<Scalars['String']>;
  hubspotProperty: Maybe<Scalars['String']>;
  hasImpactOnLabor: Scalars['Boolean'];
};

export type QuestionopenAnswerArgs = {
  pricingEngineId: Scalars['ID'];
};

export type RenderDefault = Node & {
  __typename?: 'RenderDefault';
  id: Scalars['ID'];
};

export type RenovationInfo = {
  __typename?: 'RenovationInfo';
  address: Maybe<RenovationInfoAddress>;
  description: Maybe<Scalars['String']>;
  otherNotes: Maybe<Scalars['String']>;
  renovationsCount: Maybe<Scalars['String']>;
};

export type RenovationInfoAddress = {
  __typename?: 'RenovationInfoAddress';
  city: Maybe<Scalars['String']>;
  street: Maybe<Scalars['String']>;
  street_2: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  zip: Maybe<Scalars['String']>;
};

export type RenovationInfoAddressInput = {
  projectId: Scalars['ID'];
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type RenovationInfoUpdateInput = {
  renovationPlanId: Scalars['ID'];
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  otherNotes?: Maybe<Scalars['String']>;
  renovationsCount?: Maybe<Scalars['String']>;
};

/** TODO: Create Consumer specific RenovationPlan type */
export type RenovationPlan = Node & {
  __typename?: 'RenovationPlan';
  mediaFiles: Array<File>;
  docFiles: Array<File>;
  renovationInfo: Maybe<RenovationInfo>;
  renovationProposal: Maybe<RenovationProposal>;
  id: Scalars['ID'];
  status: RenovationPlanStatus;
  proposalCurrent: Maybe<ConsumerProposal>;
  primaryMember: TeamMember;
  address: Address;
  user: User;
  planner: Maybe<Planner>;
};

/** TODO: Create Consumer specific RenovationPlan type */
export type RenovationPlanproposalCurrentArgs = {
  type?: Maybe<ProposalType>;
};

/** START - renovationPlan */
export type RenovationPlanAddressUpdateInput = {
  id: Scalars['ID'];
  zip: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  street_2?: Maybe<Scalars['String']>;
};

export type RenovationPlanAnswersUpdateInput = {
  renovationPlanId: Scalars['ID'];
  answers: Array<AdminAnswerInput>;
};

export enum RenovationPlanStatus {
  new = 'new',
  inProgress = 'inProgress',
  review = 'review',
  proposal = 'proposal',
  proposalExpired = 'proposalExpired',
  proposalRequested = 'proposalRequested',
  accepted = 'accepted',
}

export type RenovationProposal = {
  __typename?: 'RenovationProposal';
  id: Scalars['ID'];
  published: Scalars['Boolean'];
  publishDate: Maybe<Scalars['DateTime']>;
  expirationDate: Maybe<Scalars['DateTime']>;
  message: Scalars['String'];
  proposalInfo: Scalars['String'];
  timeline: Scalars['String'];
  deposit: Scalars['Int'];
  scopeOfWorkUrl: Scalars['String'];
};

export type ResponseAddress = {
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export enum ROLE {
  ADMIN = 'ADMIN',
  CONTRACTOR = 'CONTRACTOR',
  USER = 'USER',
}

export enum RoomType {
  BATHROOM = 'BATHROOM',
  KITCHEN = 'KITCHEN',
  OTHER = 'OTHER',
}

export enum SCHEDULE_ITEM_CHANGE_COLUMN {
  COMPLETED_DATE = 'COMPLETED_DATE',
  DUE_DATE = 'DUE_DATE',
}

export enum SCHEDULE_ITEM_CHANGE_EVENT {
  DELETE = 'DELETE',
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export type ScheduleDependencyItem = {
  __typename?: 'ScheduleDependencyItem';
  id: Scalars['ID'];
  title: Scalars['String'];
  dueDate: Scalars['DateTime'];
};

export type ScheduleEventMedia = {
  __typename?: 'ScheduleEventMedia';
  id: Scalars['ID'];
  name: Scalars['String'];
  mimeType: Scalars['String'];
  url: Scalars['String'];
  thumbnailUrl: Maybe<Scalars['String']>;
};

export type ScheduleFilters = {
  __typename?: 'ScheduleFilters';
  assignment: Array<ScheduleProjectFilter>;
};

export type ScheduleGenericItem = {
  __typename?: 'ScheduleGenericItem';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type ScheduleProject = {
  __typename?: 'ScheduleProject';
  id: Scalars['ID'];
  published: Scalars['Boolean'];
  items: Array<ScheduleProjectItem>;
};

export type ScheduleProjectFilter = {
  __typename?: 'ScheduleProjectFilter';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ScheduleProjectFilterInput = {
  incomplete?: Maybe<Scalars['Boolean']>;
  assigneeId?: Maybe<Scalars['ID']>;
};

export type ScheduleProjectItem = {
  __typename?: 'ScheduleProjectItem';
  id: Scalars['ID'];
  projectId: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  description: Scalars['String'];
  assigneeRole: TeamMemberRole;
  category: PROJECT_ITEM_CATEGORY;
  userFullName: Maybe<Scalars['String']>;
  contractorLastName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  type: PROJECT_ITEM_TYPE;
  reference: PROJECT_ITEM_SCHEDULE_REFERENCE;
  referenceItemId: Maybe<Scalars['ID']>;
  dueDays: Maybe<Scalars['Int']>;
  dueDate: Scalars['DateTime'];
  completedDate: Maybe<Scalars['DateTime']>;
  status: PROJECT_ITEM_STATUS;
  showInClientDashboard: Scalars['Boolean'];
  dependencies: Maybe<Array<ScheduleDependencyItem>>;
  changes: Maybe<Array<ScheduleProjectItemChangeEvent>>;
  hasAnyNotification: Maybe<Scalars['Boolean']>;
};

export type ScheduleProjectItemChangeEvent = {
  __typename?: 'ScheduleProjectItemChangeEvent';
  id: Scalars['ID'];
  type: SCHEDULE_ITEM_CHANGE_EVENT;
  fieldName: SCHEDULE_ITEM_CHANGE_COLUMN;
  oldValue: Maybe<Scalars['String']>;
  newValue: Maybe<Scalars['String']>;
  reason: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  eventMedia: Maybe<Array<ScheduleEventMedia>>;
  reasons: Maybe<Array<MilestoneReason>>;
};

export type ScheduleProjectItemCompleteInput = {
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  completedDate?: Maybe<Scalars['DateTime']>;
};

export type ScheduleProjectItemCreateFromGenericInput = {
  genericItemId: Scalars['ID'];
  scheduleId: Scalars['ID'];
  reference: PROJECT_ITEM_SCHEDULE_REFERENCE;
  referenceItemId?: Maybe<Scalars['ID']>;
  dueDays?: Maybe<Scalars['Int']>;
  dueDate?: Maybe<Scalars['String']>;
  showInClientDashboard: Scalars['Boolean'];
};

export type ScheduleProjectItemCreateInput = {
  scheduleId: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  assigneeRole: TeamMemberRole;
  category: PROJECT_ITEM_CATEGORY;
  type: PROJECT_ITEM_TYPE;
  reference: PROJECT_ITEM_SCHEDULE_REFERENCE;
  referenceItemId?: Maybe<Scalars['ID']>;
  dueDays?: Maybe<Scalars['Int']>;
  dueDate?: Maybe<Scalars['String']>;
  showInClientDashboard: Scalars['Boolean'];
};

export type ScheduleProjectItemEditInput = {
  id: Scalars['ID'];
  reason: Scalars['String'];
  completedDate: Scalars['DateTime'];
};

export type ScheduleProjectItemIncompleteInput = {
  id: Scalars['ID'];
};

export type ScheduleProjectItemInput = {
  id: Scalars['ID'];
};

export type ScheduleProjectItemRemoveInput = {
  id: Scalars['ID'];
};

export type ScheduleProjectItemShiftDueDateInput = {
  id: Scalars['ID'];
  reference: PROJECT_ITEM_SCHEDULE_REFERENCE;
  referenceItemId?: Maybe<Scalars['ID']>;
  dueDays?: Maybe<Scalars['Int']>;
  dueDate?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

export type ScheduleProjectItemUpdateInput = {
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  assigneeRole: TeamMemberRole;
  category: PROJECT_ITEM_CATEGORY;
  type: PROJECT_ITEM_TYPE;
  showInClientDashboard: Scalars['Boolean'];
};

export type ScheduleProjectPublishInput = {
  projectId: Scalars['ID'];
};

export type ScheduleProjectRemoveInput = {
  projectId: Scalars['ID'];
};

export type ScheduleTemplate = Node & {
  __typename?: 'ScheduleTemplate';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type ScheduleTemplateApplyInput = {
  projectId: Scalars['ID'];
  scheduleTemplateId: Scalars['ID'];
  targetBreakGroundDate: Scalars['DateTime'];
  closeDate: Scalars['DateTime'];
};

export type Section = Node & {
  __typename?: 'Section';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Maybe<Scalars['String']>;
  infoDescription: Maybe<Scalars['String']>;
  ctaText: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  questions: Array<Question>;
  conditions: Array<Condition>;
  effects: Array<SectionEffect>;
  internalName: Scalars['String'];
};

export enum SectionEffect {
  CREATE_USER = 'CREATE_USER',
  VALIDATE_ZIP = 'VALIDATE_ZIP',
  GENERATE_STARTING_POINTS = 'GENERATE_STARTING_POINTS',
  VALIDATE_REFERRAL_CODE = 'VALIDATE_REFERRAL_CODE',
}

export type SelectionAnswerResponse = {
  __typename?: 'SelectionAnswerResponse';
  estimate: ConfiguratorEstimate;
};

export type SelectionResponse = {
  __typename?: 'SelectionResponse';
  estimate: ConfiguratorEstimate;
  rendersChanged: Array<RenderDefault>;
};

export type SetPasswordInput = {
  newPassword: Scalars['String'];
};

export type Sku = {
  __typename?: 'Sku';
  id: Scalars['ID'];
  sku: Maybe<Scalars['String']>;
  inStock: Scalars['Boolean'];
  vendor: SkuVendor;
  manufacturerWebsite: Maybe<Scalars['String']>;
  pdpImageUrl: Maybe<Scalars['String']>;
  specUrl: Maybe<Scalars['String']>;
  product: Product;
  name: Scalars['String'];
  thumbnailUrl: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  unitPrice: Scalars['Int'];
  listPrice: Scalars['Int'];
  unit: Maybe<SkuUnitProperties>;
  finish: VariantType;
  size: VariantType;
  pattern: VariantType;
};

export type SkuBrand = {
  __typename?: 'SkuBrand';
  id: Scalars['ID'];
  name: Scalars['String'];
  line?: Maybe<SkuBrandLine>;
};

export type SkuBrandLine = {
  __typename?: 'SkuBrandLine';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Determine which props are required */
export type SkuCreateInput = {
  productId: Scalars['ID'];
  sku?: Maybe<Scalars['String']>;
  finish?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
  inStock?: Maybe<Scalars['Boolean']>;
  vendor: SkuVendorInput;
  unit?: Maybe<SkuUnitPropertiesInput>;
  manufacturerWebsite?: Maybe<Scalars['String']>;
  pdpImageUrl?: Maybe<Scalars['String']>;
  specUrl?: Maybe<Scalars['String']>;
};

export type SkuDeleteInput = {
  skuId: Scalars['ID'];
};

export type SkuLegend = {
  __typename?: 'SkuLegend';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SkuSet = {
  __typename?: 'SkuSet';
  id: Scalars['ID'];
  name: Scalars['String'];
  variant: Maybe<SkuSetVariant>;
  finishCatalog: Array<VariantType>;
  sizeCatalog: Array<VariantType>;
  patternCatalog: Array<VariantType>;
};

export type SkuSetPricingEngineSetPropertiesInput = {
  pricingEngineId: Scalars['ID'];
  skuSetVariantId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
  status?: Maybe<SkuStatus>;
};

export type SkuSetPricingEngineSetVariantInput = {
  pricingEngineId: Scalars['ID'];
  skuSetVariantId: Scalars['ID'];
  materialsCategoryId: Scalars['ID'];
  size?: Maybe<VariantTypeInput>;
  finish?: Maybe<VariantTypeInput>;
  pattern?: Maybe<VariantTypeInput>;
};

export type SkuSetVariant = {
  __typename?: 'SkuSetVariant';
  id: Scalars['ID'];
  price: Scalars['Float'];
  thumbnailUrl: Maybe<Scalars['String']>;
  size: VariantType;
  pattern: VariantType;
  inStock: Scalars['Boolean'];
  totalPrice: Scalars['Float'];
  listPrice: Scalars['Int'];
  unitPrice: Scalars['Int'];
  finish: VariantType;
  skus: Array<Sku>;
};

export enum SkuStatus {
  TBD = 'TBD',
  CONFIRMED = 'CONFIRMED',
  ALTERNATE = 'ALTERNATE',
}

export enum SkuUnit {
  EACH = 'EACH',
  SQ_FOOT = 'SQ_FOOT',
}

export enum SkuUnitPackaging {
  ITEM = 'ITEM',
  BOX = 'BOX',
}

export type SkuUnitProperties = {
  __typename?: 'SkuUnitProperties';
  package: UnitPackage;
  type: SkuUnit;
};

export type SkuUnitPropertiesInput = {
  type: SkuUnit;
  package: UnitPackgeInput;
};

export type SkuUpdateInput = {
  skuId: Scalars['ID'];
  sku?: Maybe<Scalars['String']>;
  finish?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
  inStock?: Maybe<Scalars['Boolean']>;
  vendor: SkuVendorInput;
  unit?: Maybe<SkuUnitPropertiesInput>;
  manufacturerWebsite?: Maybe<Scalars['String']>;
  pdpImageUrl?: Maybe<Scalars['String']>;
  specUrl?: Maybe<Scalars['String']>;
};

export type SkuVendor = {
  __typename?: 'SkuVendor';
  id: Maybe<Scalars['ID']>;
  sku: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  link: Scalars['String'];
};

export type SkuVendorInput = {
  id?: Maybe<Scalars['ID']>;
  sku?: Maybe<Scalars['String']>;
  link: Scalars['String'];
};

export type StartingPointsGenerateInput = {
  token: Scalars['String'];
  responses: Array<PlannerResponse>;
};

export type SupportMember = {
  __typename?: 'SupportMember';
  avatarUrl: Maybe<Scalars['String']>;
  bio: Maybe<Scalars['String']>;
  email: Scalars['String'];
  imageUrl: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  profileLink: Maybe<Scalars['String']>;
  role: Scalars['String'];
};

export type Survey = Node & {
  __typename?: 'Survey';
  id: Scalars['ID'];
};

export enum SystemType {
  ROOM_SELECTION = 'ROOM_SELECTION',
  ROOM_INFO = 'ROOM_INFO',
  ROOM_MEASUREMENTS = 'ROOM_MEASUREMENTS',
  ROOM_PHOTOS = 'ROOM_PHOTOS',
  SITE_SURVEY = 'SITE_SURVEY',
  BUILDING_INFO = 'BUILDING_INFO',
  PERSONAL_INFO = 'PERSONAL_INFO',
  STARTING_POINT = 'STARTING_POINT',
}

export enum TagGroupType {
  SINGLE = 'SINGLE',
  MULTI = 'MULTI',
}

export type TeamMember = Node & {
  __typename?: 'TeamMember';
  id: Scalars['ID'];
  role: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  meetingsLink: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  bio: Maybe<Scalars['String']>;
  hubspotId: Maybe<Scalars['Int']>;
  profileLink: Maybe<Scalars['String']>;
};

export type TeamMemberHubspotUpdateInput = {
  teamMemberId: Scalars['ID'];
};

export type TeamMemberRemoveFileInput = {
  teamMemberId: Scalars['ID'];
};

export enum TeamMemberRole {
  PROJECT_PLANNER = 'PROJECT_PLANNER',
  CLIENT_OPS = 'CLIENT_OPS',
  DESIGNER = 'DESIGNER',
  PROCUREMENT = 'PROCUREMENT',
  CONSTRUCTION_SUPERVISOR = 'CONSTRUCTION_SUPERVISOR',
  CONTRACTOR = 'CONTRACTOR',
  FINANCE = 'FINANCE',
  CONTRACTOR_OPS = 'CONTRACTOR_OPS',
  DESIGN_OPS = 'DESIGN_OPS',
  PA_SPECIALIST = 'PA_SPECIALIST',
}

export type TeamMemberUpdateInput = {
  bio?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  meetingsLink?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profileLink?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  hubspotId?: Maybe<Scalars['Int']>;
};

export type ToggleBuildUpdateLikeInput = {
  id: Scalars['ID'];
};

export enum TriggerEventType {
  STANDARD = 'STANDARD',
  DAYS_DEPENDANT = 'DAYS_DEPENDANT',
  REPEATABLE_DAYS_DEPENDANT = 'REPEATABLE_DAYS_DEPENDANT',
}

export type UnitPackage = {
  __typename?: 'UnitPackage';
  type: SkuUnitPackaging;
  cost: Scalars['Int'];
  price: Scalars['Int'];
  listPrice: Scalars['Int'];
  unitPrice: Scalars['Int'];
  itemCount: Scalars['Float'];
};

export type UnitPackgeInput = {
  type: SkuUnitPackaging;
  cost: Scalars['Int'];
  price: Scalars['Int'];
  listPrice: Scalars['Int'];
  unitPrice: Scalars['Int'];
  itemCount: Scalars['Float'];
};

export type UpdatePerformanceTierInput = {
  id: Scalars['ID'];
  performanceTier: PerformanceTier;
};

export type RematchWorkOrderInput = {
  priceAdjustment: Scalars['Int'];
  contractorId: Scalars['ID'];
  proposalId: Scalars['ID'];
  projectId: Scalars['ID'];
  note: Scalars['String'];
  rematchReasonIds: Array<Scalars['ID']>;
  rematchNote: Scalars['String'];
};

export type UpdateWorkOrderInput = {
  priceAdjustment: Scalars['Int'];
  contractorId: Scalars['ID'];
  newProposalId: Scalars['ID'];
  projectId: Scalars['ID'];
  note: Scalars['String'];
};

export type UploadedFiles = {
  __typename?: 'UploadedFiles';
  files: Array<File>;
};

export type UploadUserMediaInput = {
  file: Scalars['Upload'];
};

export type User = Node & {
  __typename?: 'User';
  currentProject: Maybe<Project>;
  currentRenovationPlan: Maybe<RenovationPlan>;
  customerStripeId: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  hasRenovationPlan: Scalars['Boolean'];
  id: Scalars['ID'];
  lastName: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  planStatus: PlanStatus;
  projects: Array<Project>;
  proposalStatus: Maybe<ProposalType>;
  referrals: Array<User>;
  referrer: Maybe<UserReferrer>;
  renovationPlans: Array<RenovationPlan>;
  type: Scalars['String'];
};

export type UserCreateInput = {
  token: Scalars['String'];
  responses: Array<PlannerResponse>;
  utmParams: UtmParams;
};

export type UserReferralCampaign = {
  __typename?: 'UserReferralCampaign';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  campaignId: Scalars['ID'];
  code: Scalars['String'];
  description: Maybe<Scalars['String']>;
  message: Maybe<Scalars['String']>;
  name: Scalars['String'];
  subtitle: Maybe<Scalars['String']>;
  terms: Scalars['String'];
  title: Maybe<Scalars['String']>;
};

export type UserReferralCampaignInput = {
  userId: Scalars['ID'];
};

export type UserReferralCampaignValidateInput = {
  code: Scalars['String'];
};

export type UserReferrer = {
  __typename?: 'UserReferrer';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Maybe<Scalars['String']>;
  code: Scalars['String'];
  campaignId: Scalars['ID'];
  campaignName: Scalars['String'];
  campaignDescription: Scalars['String'];
};

export type UsersInput = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  searchValue?: Maybe<Scalars['String']>;
  planStatus?: Maybe<RenovationPlanStatus>;
  primaryMember?: Maybe<Scalars['ID']>;
};

export type UserAccountsInput = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  searchValue?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isInitialized?: Maybe<Scalars['Boolean']>;
};

export type UserAccountsPage = {
  __typename?: 'UserAccountsPage';
  totalCount: Scalars['Int'];
  data: Array<UserAccount>;
};

export type UserAccount = {
  __typename?: 'UserAccount';
  id: Scalars['ID'];
  email: Scalars['String'];
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isInitialized: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  isContractor: Scalars['Boolean'];
  isConsumer: Scalars['Boolean'];
};

export type UserUpdateInput = {
  id: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type UtmParams = {
  utm_campaign?: Maybe<Scalars['String']>;
  utm_content?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
  utm_term?: Maybe<Scalars['String']>;
};

export type VariantType = {
  __typename?: 'VariantType';
  description: Scalars['String'];
  imageUrl: Maybe<Scalars['String']>;
};

export type VariantTypeInput = {
  description: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
};

export type Vendor = {
  __typename?: 'Vendor';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum VerifyState {
  VERIFIED = 'VERIFIED',
  NOT_VERIFIED = 'NOT_VERIFIED',
}

export type VerifyTokenInput = {
  authToken: Scalars['String'];
};

export enum VifStage {
  SITE_SURVEY = 'SITE_SURVEY',
  POST_DEMOLITION = 'POST_DEMOLITION',
  NO_VIF_NEEDED = 'NO_VIF_NEEDED',
}

export enum WORK_ORDER_STATUS {
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  DECLINED = 'DECLINED',
  ACCEPTED = 'ACCEPTED',
  SUPERSEDED = 'SUPERSEDED',
  REMATCHED = 'REMATCHED',
  WAITING_FOR_RESPONSE = 'WAITING_FOR_RESPONSE',
}

export type WorkOrder = {
  __typename?: 'WorkOrder';
  id: Scalars['ID'];
  status: WORK_ORDER_STATUS;
  expiresAt: Scalars['DateTime'];
  extensionNote: Maybe<Scalars['String']>;
  totalPrice: Scalars['Int'];
  previousPrice: Scalars['Int'];
  priceAdjustment: Scalars['Int'];
  anticipatedBreakgroundDate: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  contractor: Contractor;
  customer: WorkOrderCustomer;
  appendix: Maybe<Scalars['String']>;
  paymentTerms: Maybe<Scalars['String']>;
  existingConditions: Maybe<Link>;
  jobContext: Maybe<JobContext>;
  rooms: Array<WorkOrderRoom>;
  buildingAge: Maybe<Scalars['String']>;
  buildingType: Maybe<Scalars['String']>;
  note: Scalars['String'];
  buildTiming: Maybe<WorkOrderBuildTiming>;
  permitsTotal: Scalars['Int'];
  permits: Array<ProposalPermit>;
};

export type WorkOrderBuildTiming = {
  __typename?: 'WorkOrderBuildTiming';
  type: BuildTiming;
  phases: Array<WorkOrderBuildTimingPhase>;
};

export type WorkOrderBuildTimingPhase = {
  __typename?: 'WorkOrderBuildTimingPhase';
  rooms: Array<WorkOrderRoom>;
};

export type WorkOrderContent = {
  __typename?: 'WorkOrderContent';
  appendix: Maybe<Scalars['String']>;
  paymentTerms: Maybe<Scalars['String']>;
};

export type WorkOrderContentUpdateInput = {
  appendix?: Maybe<Scalars['String']>;
  paymentTerms?: Maybe<Scalars['String']>;
};

export type WorkOrderCustomer = {
  __typename?: 'WorkOrderCustomer';
  firstName: Scalars['String'];
  lastName: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  address: RenovationInfoAddress;
};

export type WorkOrderInput = {
  id: Scalars['ID'];
};

export type WorkOrderPreview = {
  __typename?: 'WorkOrderPreview';
  extensionNote: Maybe<Scalars['String']>;
  totalPrice: Scalars['Int'];
  subtotalPrice: Scalars['Int'];
  previousPrice: Scalars['Int'];
  priceAdjustment: Scalars['Int'];
  anticipatedBreakgroundDate: Maybe<Scalars['DateTime']>;
  contractor: Maybe<Contractor>;
  customer: WorkOrderCustomer;
  appendix: Maybe<Scalars['String']>;
  paymentTerms: Maybe<Scalars['String']>;
  existingConditions: Maybe<Link>;
  jobContext: Maybe<JobContext>;
  rooms: Array<WorkOrderRoom>;
  buildingAge: Maybe<Scalars['String']>;
  buildingType: Maybe<Scalars['String']>;
  buildTiming: Maybe<WorkOrderBuildTiming>;
  permitsTotal: Scalars['Int'];
  permits: Array<ProposalPermit>;
};

export type WorkOrderPreviewInput = {
  proposalId: Scalars['ID'];
};

export type WorkOrderProposal = {
  __typename?: 'WorkOrderProposal';
  proposalId: Scalars['ID'];
  totalPrice: Scalars['Int'];
};

export type WorkOrderProposalInput = {
  projectId: Scalars['ID'];
};

export type WorkOrderReason = {
  __typename?: 'WorkOrderReason';
  id: Scalars['ID'];
  content: Scalars['String'];
};

export type WorkOrderRoom = {
  __typename?: 'WorkOrderRoom';
  id: Scalars['ID'];
  roomName: Scalars['String'];
  roomType: RoomType;
  categories: Array<WorkOrderRoomCategory>;
  linesOfWork: Array<LineOfWorkPricingEngine>;
  bathroomLayout: Maybe<Scalars['String']>;
  updates: Maybe<Array<WorkOrderRoomUpdate>>;
};

export type WorkOrderRoomlinesOfWorkArgs = {
  level?: Maybe<LineOfWorkLevel>;
  isOptional?: Maybe<Scalars['Boolean']>;
};

export type WorkOrderRoomCategory = {
  __typename?: 'WorkOrderRoomCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  linesOfWork: Array<LineOfWorkPricingEngine>;
};

export type WorkOrderRoomCategorylinesOfWorkArgs = {
  isOptional?: Maybe<Scalars['Boolean']>;
};

export type WorkOrderRoomUpdate = {
  __typename?: 'WorkOrderRoomUpdate';
  categoryName: Scalars['String'];
  name: Scalars['String'];
  isNew: Scalars['Boolean'];
};

export type WorkOrdersInput = {
  renovationPlanId: Scalars['ID'];
};

export type ZipCode = Node & {
  __typename?: 'ZipCode';
  id: Scalars['ID'];
  geographicContext: Scalars['String'];
};

export enum ZipCodeStatus {
  SERVICEABLE = 'SERVICEABLE',
  ESCALATE = 'ESCALATE',
  NOT_SERVICEABLE = 'NOT_SERVICEABLE',
}

export type ZipCodeValidateInput = {
  code: Scalars['String'];
};

export enum PricingEngineMarginType {
  FIXED = 'FIXED',
  FIXED_WITH_PERCENTAGE = 'FIXED_WITH_PERCENTAGE',
}

export type RequestProposalQaInput = {
  proposalId: Scalars['ID'];
  proposalQaTypes: Array<ProposalQaType>;
  requestedAt: Scalars['DateTime'];
};

export type ProposalQa = {
  __typename?: 'ProposalQa';
  completedByUser: Maybe<UserAccount>;
  completedAt: Maybe<Scalars['DateTime']>;
  closingStatus: Maybe<ProposalQaClosingStatus>;
  id: Scalars['ID'];
  proposalId: Scalars['ID'];
  qaType: ProposalQaType;
  requestedAt: Maybe<Scalars['DateTime']>;
  startedAt: Maybe<Scalars['DateTime']>;
  startedByUser: Maybe<UserAccount>;
  status: ProposalQaStatus;
};

export enum ProposalQaType {
  DESIGN = 'DESIGN',
  PROCUREMENT = 'PROCUREMENT',
}

export enum ProposalQaClosingStatus {
  APPROVED = 'APPROVED',
  APPROVED_WITH_NOTES = 'APPROVED_WITH_NOTES',
  REVISE_AND_RESUBMIT = 'REVISE_AND_RESUBMIT',
}

export enum ProposalQaStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  REQUESTED = 'REQUESTED',
}

export type MutationcompleteProposalQaArgs = {
  input: CompleteProposalQaInput;
};

export type CompleteProposalQaInput = {
  closingStatus?: Maybe<ProposalQaClosingStatus>;
  proposalId: Scalars['ID'];
  proposalQaType: ProposalQaType;
};

export type StartProposalQaInput = {
  proposalId: Scalars['ID'];
  proposalQaType: ProposalQaType;
};
