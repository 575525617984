import { useState } from 'react';

import { OrderShipment as Shipment } from 'Project/Procurement/common/types/orderShipment';

import { useToggle } from 'common/hooks/useToggle';

export const useOrderShipments = () => {
  const [selectedShipment, setSelectedShipment] = useState<Shipment | null>(
    null,
  );

  const { isOn: isEditShipmentModalOpen, toggleOff, toggleOn } = useToggle();

  const handleEditShipment = (shipment: Shipment) => {
    setSelectedShipment(shipment);
    toggleOn();
  };

  const handleEditShipmentModalClose = () => {
    setSelectedShipment(null);
    toggleOff();
  };

  return {
    handleEditShipment,
    handleEditShipmentModalClose,
    isEditShipmentModalOpen,
    selectedShipment,
  };
};
