import { useToggle } from 'common/hooks/useToggle';

import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

import { useProjectPlanQuestionsUpdate } from 'ProjectProfile/common/hooks/useProjectPlanQuestionsUpdate';

export const useProjectCallOuts = () => {
  const { isOn, toggleOff, toggleOn } = useToggle();
  const { handleSubmit: onSave } = useProjectPlanQuestionsUpdate();

  const handleSubmit = (formValue: string | null) => {
    onSave({
      onSuccessCallback: toggleOff,
      openQuestions: [
        {
          identifier: PropertyGroupIdentifier.IMPORTANT_PROJECT_CALL_OUTS,
          value: `${formValue}`,
        },
      ],
    });
  };

  return {
    handleSubmit,
    isOn,
    toggleOff,
    toggleOn,
  };
};
