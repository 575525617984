import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from '@material-ui/core';

import { mq } from 'common/utils/mediaQueries';

export const OutlinedLink = styled(Link)`
  align-items: center;
  display: flex;
  background: ${({ theme }) => theme.color.white};
  border-radius: 10px;
  text-decoration: underline;
  justify-content: left;
  width: 100%;
  height: 64px;
  font: normal 600 14px/20px ${({ theme }) => theme.font.inter};
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 10px 10px 10px 5px;

  ${mq.medium(css`
    justify-content: center;
  `)}

  svg {
    margin: 0 5px 0;
  }
`;

export const OutlinedNoLink = styled.div`
  align-items: center;
  display: flex;
  background: ${({ theme }) => theme.color.white};
  border-radius: 10px;
  justify-content: center;
  width: 100%;
  height: 64px;
  font: normal 600 12px/20px ${({ theme }) => theme.font.inter};
  color: ${({ theme }) => theme.color.gray};
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 10px;
`;
