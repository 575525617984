import { useParams } from 'react-router-dom';

import { ApolloError, useMutation } from '@apollo/client';

import { USER_PROPOSALS } from 'Proposals/proposals.graphql';
import { Question } from 'common/apollo/models/question';
import { UPDATE_PROJECT_OVERVIEW_QUESTIONS } from 'common/apollo/mutations/updatePropertyProject';
import { UpdateProjectBuildingInfoQuestionsRequest } from 'common/apollo/requests/updateProjectBuildingInfoQuestionsRequest';
import { UpdateProjectBuildingInfoQuestionsResponse } from 'common/apollo/responses/updateProjectBuildingInfoQuestionsResponse';
import { useAlert } from 'common/hooks/useAlert';
import { DualAnswer } from 'common/shared/Question/question.types';

import {
  normalizeOpenQuestionsSelection,
  normalizeQuestionsSelection,
} from 'Project/ProjectOverview/common/utils/normalizeQuestionsSelection';

import { openQuestionTypes } from 'common/shared/Question/question.utils';

export const useProjectInformationQuestion = (question: Question) => {
  const { onError } = useAlert();
  const { projectId } = useParams<{ projectId: string }>();

  const [updateProjectInformation] = useMutation<
    UpdateProjectBuildingInfoQuestionsResponse,
    UpdateProjectBuildingInfoQuestionsRequest
  >(UPDATE_PROJECT_OVERVIEW_QUESTIONS, {
    awaitRefetchQueries: true,
    onError: (error: ApolloError) => {
      onError(error.toString());
    },
    refetchQueries: [
      {
        query: USER_PROPOSALS,
        variables: {
          renovationPlanId: projectId,
        },
      },
    ],
  });

  const handleChange = async (value: string | string[] | DualAnswer) => {
    const normalizedValue = value as string | string[];

    const isOpen = openQuestionTypes.includes(question.presentationType);

    const answers = !isOpen
      ? normalizeQuestionsSelection({ [question.id]: normalizedValue }, [])
      : [];
    const openAnswers = isOpen
      ? normalizeOpenQuestionsSelection({ [question.id]: normalizedValue }, [
          question.id,
        ])
      : [];

    await updateProjectInformation({
      variables: {
        input: {
          answers,
          openAnswers,
          projectId,
        },
      },
    });
  };

  return {
    handleChange,
  };
};
