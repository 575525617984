import styled from '@emotion/styled';

import { css } from '@emotion/react';

import { GridTableCell } from 'common/components/Tables/GridTable/GridTableCell';

import { GridTableHead } from 'common/components/Tables/GridTable/GridTableHead';

type Props = {
  isSticky?: boolean;
  zIndex?: string;
};

export const Header = styled(GridTableHead)<Props>`
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray2};
  border-color: ${({ theme }) => theme.color.gray7};
  border-style: solid;
  border-width: 1px 0;
  padding: 4px 16px;
  position: sticky;
  top: 74px;
  z-index: ${({ zIndex }) => `${zIndex}`};
`;

export const HeaderCell = styled(GridTableCell)<Props>`
  color: ${({ theme }) => theme.color.gray12};
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
  z-index: ${({ isSticky, zIndex }) => (isSticky && zIndex ? `${zIndex}` : '')};
`;

export const stickyColumnStyle = css`
  background: inherit;
  position: sticky;

  &:nth-of-type(1) {
    left: 0;
  }

  &:nth-of-type(2) {
    left: 70px;
  }
`;
