import { Formik } from 'formik';

import { CloseButton } from 'common/components/Form/CloseButton/CloseButton';
import { FormTextField } from 'common/_components/Form/FormTextField/FormTextField';

import { Button } from 'common/components/Button/Button';
import { useModal } from 'common/components/Modal/Modal';

import { OrderMaterial } from 'Project/Procurement/common/types/orderMaterial';

import {
  Content,
  Footer,
  Header,
  InputWrapper,
  Name,
  Title,
  Units,
  Wrapper,
} from 'Project/Procurement/CreateOrder/OrderMaterials/UpdateMaterialUnits/updateMaterialUnits.styles';

import { validationSchema } from 'Project/Procurement/CreateOrder/OrderMaterials/UpdateMaterialUnits/updateMaterialUnits.consts';

import { UpdateMaterialUnitsFormValues } from 'Project/Procurement/CreateOrder/OrderMaterials/UpdateMaterialUnits/updateMaterialUnits.types';

type Props = {
  material: OrderMaterial | null;
  onSubmit: (id: string, amount: number) => void;
};

export const UpdateMaterialUnits = ({ material, onSubmit }: Props) => {
  const { onClose: handleClose } = useModal();

  const handleSubmit = ({ amount }: UpdateMaterialUnitsFormValues) => {
    if (!material) return;

    onSubmit(material.id, amount);

    handleClose();
  };

  return (
    <Formik
      initialValues={{ amount: material?.totalUnits || 0 }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, isSubmitting, submitForm }) => (
        <Wrapper>
          <Header>
            Edit Number of Units <CloseButton onClick={handleClose} />
          </Header>

          <Content>
            <Title>
              Update the units of the selected material in this order.
            </Title>

            <InputWrapper>
              <Units>{material?.totalUnits} Available</Units>
              <Name>{material?.name}</Name>

              <FormTextField
                id="amount"
                label="Units In Order"
                name="amount"
                type="number"
              />
            </InputWrapper>
          </Content>

          <Footer>
            <Button onClick={handleClose} small>
              Cancel
            </Button>

            <Button
              color="secondary"
              disabled={!dirty || isSubmitting}
              onClick={submitForm}
              small
              variant="contained"
            >
              Update Units
            </Button>
          </Footer>
        </Wrapper>
      )}
    </Formik>
  );
};
