import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { Form, Formik } from 'formik';

import { ModalCloseButton } from 'common/components/Modals/ModalCloseButton';
import { Button } from 'common/components/Buttons/Button/Button';
import { FormCheckbox } from 'common/components/Form/Checkboxes/FormCheckbox';

import { Section } from 'ProjectProfile/common/components/Section/Section';

import {
  DialogActions,
  DialogContent,
  FieldWrapper,
} from 'ProjectProfile/common/components/EditForm/editForm.styles';

import { usePostDSOExperience } from './postDSOExperience.hooks';

type Props = {
  value: boolean;
};

export const PostDSOExperience = ({ value }: Props) => {
  const { handleSubmit, isOn, toggleOff, toggleOn } = usePostDSOExperience();

  return (
    <>
      <Section onEditClick={toggleOn} title="Post design sign-off work order">
        {value?.toString()}
      </Section>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={isOn}
      >
        <DialogTitle>Post design sign-off work order</DialogTitle>
        <ModalCloseButton onClick={toggleOff} />

        <Formik
          initialValues={{ isPostDSOExperience: value }}
          onSubmit={({ isPostDSOExperience }) =>
            handleSubmit(isPostDSOExperience)
          }
        >
          {({ isSubmitting }) => (
            <Form>
              <DialogContent>
                <FieldWrapper>
                  <FormCheckbox
                    label="Post design sign-off work order"
                    name="isPostDSOExperience"
                  />
                </FieldWrapper>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
