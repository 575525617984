import { useMutation } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';

import { useModal } from 'common/components/Modal/Modal';

import { UpdateProjectProcurementOrderItemUnitsRequest } from 'Project/Procurement/common/apollo/requests/updateProjectProcurementOrderItemUnitsRequest';
import { UpdateProjectProcurementOrderItemUnitsResponse } from 'Project/Procurement/common/apollo/responses/updateProjectProcurementOrderItemUnitsResponse';

import { UPDATE_PROJECT_ORDER_ITEM_UNITS } from 'Project/Procurement/EditOrder/OrderMaterials/UpdateMaterialUnits/updateMaterialUnits.graphql';

import { UpdateMaterialUnitsFormValues } from 'Project/Procurement/EditOrder/OrderMaterials/UpdateMaterialUnits/updateMaterialUnits.types';

export const useUpdateMaterialUnits = (orderItemId?: string) => {
  const { onError } = useAlert();
  const { onClose: handleClose } = useModal();

  const [updateUnits] = useMutation<
    UpdateProjectProcurementOrderItemUnitsResponse,
    UpdateProjectProcurementOrderItemUnitsRequest
  >(UPDATE_PROJECT_ORDER_ITEM_UNITS, {
    onCompleted: () => handleClose(),
    onError: (error) => onError(error.message),
  });

  const handleSubmit = ({ amount }: UpdateMaterialUnitsFormValues) =>
    updateUnits({
      variables: {
        input: {
          amount,
          orderItemId: orderItemId || '',
        },
      },
    });

  return {
    handleClose,
    handleSubmit,
  };
};
