import styled from '@emotion/styled';

export const Wrapper = styled.div<{ fluid?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
`;

export const LabelWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 9px;
`;

export const Label = styled.label`
  ${({ theme }) => theme.typography.header6};
  color: ${({ theme }) => theme.color.gray11};
`;

export const HelperWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HelperText = styled.p<{ hasError?: boolean }>`
  ${({ theme }) => theme.typography.body2};
  color: ${({ hasError, theme }) =>
    hasError ? theme.color.red11 : theme.color.gray11};
`;

export const ExtraLabel = styled.span`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.gray11};
  margin-left: auto;
`;
