import { Formik, Form } from 'formik';
import { number, object, string } from 'yup';

import { Button } from 'common/components/Buttons/Button/Button';
import { SidebarElement } from 'common/components/Sidebar/SidebarContent.styles';
import { UserProfile } from 'common/models/userProfile';

import {
  formInputs,
  formInitialValues,
  DUPLICATE_SECONDARY_EMAIL,
  EMAIL_VALIDATION,
  REQUIRED_FIELD_VALIDATION,
} from 'common/shared/Sidebar/Profile/ProfileEdit/profileEdit.consts';
import {
  buttonStyles,
  formStyles,
} from 'common/shared/Sidebar/Profile/ProfileEdit/ProfileEdit.styles';

type Props = {
  initialValues?: UserProfile;
  loadingSubmit?: boolean;
  onCancel?: () => void;
  onSubmit: (data: UserProfile) => void;
  secondaryContactEmail: string;
};

export const ProfileEdit = ({
  initialValues,
  loadingSubmit,
  onCancel,
  onSubmit,
  secondaryContactEmail,
}: Props) => {
  const validationSchema = object().shape({
    city: string().required(REQUIRED_FIELD_VALIDATION),
    email: string()
      .email(EMAIL_VALIDATION)
      .required(REQUIRED_FIELD_VALIDATION)
      .not([secondaryContactEmail], DUPLICATE_SECONDARY_EMAIL),
    firstName: string().required(REQUIRED_FIELD_VALIDATION),
    lastName: string().required(REQUIRED_FIELD_VALIDATION),
    state: string().required(REQUIRED_FIELD_VALIDATION),
    street: string().required(REQUIRED_FIELD_VALIDATION),
    zip: number().required(REQUIRED_FIELD_VALIDATION),
  });

  return (
    <SidebarElement>
      <Formik
        initialValues={{ ...formInitialValues, ...initialValues }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form css={formStyles}>
          {formInputs.map(({ component: Component, name, placeholder }) => (
            <Component
              isFullWidth
              key={name}
              name={name}
              placeholder={placeholder}
            />
          ))}
          <Button
            color="primary"
            css={buttonStyles}
            isLoading={loadingSubmit}
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
          <Button
            color="primary"
            css={buttonStyles}
            disabled={loadingSubmit}
            onClick={onCancel}
            variant="outlined"
          >
            Cancel
          </Button>
        </Form>
      </Formik>
    </SidebarElement>
  );
};
