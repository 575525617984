import styled from '@emotion/styled';

export const Header = styled.div`
  display: grid;
  font: 500 24px/32px ${({ theme }) => theme.font.inter};
  padding-bottom: 24px;
`;

export const Wrapper = styled.div`
  padding: 24px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
`;

export const ErrorContent = styled.div`
  padding: 10px;
  color: ${({ theme }) => theme.color.secondaryGray};
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
`;
