import { object, string, number } from 'yup';

import {
  NEGATIVE_NUMBER,
  REQUIRED_VALIDATION,
} from 'common/consts/validationMessages';

import { OrderDeliverTo } from 'common/apollo/enums/OrderDeliverTo';
import { OrderTiming } from 'common/apollo/enums/OrderTiming';
import { ShippingMethod } from 'common/apollo/enums/ShippingMethod';
import { OrderReason } from 'common/apollo/enums/OrderReason';

export const validationSchema = object().shape({
  blockRequestedDeliveryDate: string().required(REQUIRED_VALIDATION),
  dateOrdered: string().required(REQUIRED_VALIDATION),
  initialRequestedDeliveryDate: string().required(REQUIRED_VALIDATION),
  number: string().required(REQUIRED_VALIDATION),
  refundAmount: number()
    .notRequired()
    .when({
      is: (value: number) => value > 0,
      then: number().negative(NEGATIVE_NUMBER),
    }),
  requestedDeliverTo: string().required(REQUIRED_VALIDATION),
  requestedShippingMethod: string().required(REQUIRED_VALIDATION),
  timing: string().required(REQUIRED_VALIDATION),
  vendorId: string().required(REQUIRED_VALIDATION),
  webOrderNumber: string().required(REQUIRED_VALIDATION),
});

export const initialValues = {
  blockRequestedDeliveryDate: new Date().toISOString(),
  comments: '',
  confirmedDeliveryDate: null,
  dateOrdered: new Date().toISOString(),
  initialRequestedDeliveryDate: new Date().toISOString(),
  number: '',
  reason: OrderReason.STANDARD,
  refundAmount: 0,
  requestedDeliverTo: '' as OrderDeliverTo,
  requestedShippingMethod: '' as ShippingMethod,
  shippingTotal: 0,
  subtotal: 0,
  taxTotal: 0,
  timing: '' as OrderTiming,
  vendorComments: '',
  vendorId: '',
  webOrderNumber: '',
};
