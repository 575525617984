import { Question as QuestionModel } from 'common/apollo/models/question';

import { DualAnswer } from 'common/shared/Question/question.types';

import { questionsMap } from 'common/shared/Question/question.utils';

type Props = {
  disabled?: boolean;
  name?: string;
  onChange?: (value: string | string[] | DualAnswer) => void;
  requireTouched?: boolean;
} & QuestionModel;

export const Question = ({
  answers,
  description,
  disabled = false,
  id: questionId,
  infoDescription,
  infoHeader,
  isSingleSelect,
  label,
  name,
  onChange,
  placeholder,
  presentationType,
  requireTouched = true,
  required = false,
}: Props) => {
  const Component = questionsMap[presentationType];

  const handleChange = (value: string | string[] | DualAnswer) =>
    onChange?.(value);

  const options = answers.map(({ id: value, label: optionLabel, type }) => ({
    label: optionLabel || '',
    type,
    value,
  }));

  if (!Component) return null;

  return (
    <Component
      description={description || ''}
      id={questionId}
      infoDescription={infoDescription}
      infoHeader={infoHeader}
      isDisabled={disabled}
      isRequired={required}
      isSingleSelect={isSingleSelect}
      label={label}
      name={name || questionId}
      onChange={onChange && handleChange}
      options={options}
      placeholder={placeholder}
      requireTouched={requireTouched}
    />
  );
};
