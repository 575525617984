import { gql } from '@apollo/client';

export const GET_PROJECT_PROCUREMENT_DELIVERY_BATCHES = gql`
  query GetProjectProcurementDeliveryBatches($projectId: ID!) {
    project(id: $projectId) {
      id
      deliveryBatches {
        id
        eta
        inventoryUnits
        pallet
        confirmedAt
        shippedAt
        receivedAt
        units
        shipments {
          id
          estimatedDeliveryDate
        }
        orderItem {
          amount
          id
          requestedDeliverTo
          skuVendor {
            id
            vendor {
              id
              name
            }
            vendorSku
          }
          projectMaterial {
            id
            name
            pricingEngine {
              id
              name
            }
            sku {
              id
              sku
              color {
                description
              }
              finish {
                description
              }
              size {
                description
              }
            }
          }
        }
      }
    }
  }
`;
