import { FilterableSkuAttribute } from 'common/components/Filters/enums/FilterableSkuAttribute';
import { FilterType } from 'common/components/Filters/enums/FilterType';
import {
  BaseFilter,
  RangeFilter,
  Option,
} from 'common/components/Filters/filters.types';

type SkuFilter = BaseFilter | RangeFilter;

const SEARCH_URL_KEY = 'search';
const CATALOG_ONLY_KEY = 'catalogOnly';

// Covert BaseFilter/RangeFilter into key value pair where:
//    key = FilterableSkuAttribute string value
//    value =
//       - BaseFilter -> comma separated list of selected option values
//       - RangeFilter -> string of "minValue-maxValue"
export const convertFiltersToUrlParam = (
  updatedFilters: SkuFilter[],
): URLSearchParams => {
  const urlSearchParams = new URLSearchParams();

  updatedFilters.forEach((filter) => {
    const key = getFilterKey(filter);
    const value =
      filter.type === FilterType.RANGE
        ? convertRangeFilterToUrlString(filter as RangeFilter)
        : convertBaseFilterToUrlString(filter);

    !!value && urlSearchParams.set(key, value);
  });

  return urlSearchParams;
};

// Convert URLSearchParams into BaseFilter/RangeFilter array representing all currently selected filters
export const convertUrlSearchParamsToFilters = (
  searchParams: string,
  initialAllFilters: SkuFilter[],
): SkuFilter[] => {
  const selectedFilters = [] as SkuFilter[];
  const urlSearchParams = new URLSearchParams(searchParams);

  urlSearchParams.forEach((value, key) => {
    const correspondingFilter = initialAllFilters.find(
      (filter) =>
        filter.attribute ===
        FilterableSkuAttribute[
          key.toUpperCase() as keyof typeof FilterableSkuAttribute
        ],
    );

    if (correspondingFilter) {
      const addUrlParamToSelectedFilters =
        correspondingFilter.type === FilterType.RANGE
          ? addRangeFilterUrlParamToSelectedFilters
          : addBaseFilterUrlParamToSelectedFilters;

      addUrlParamToSelectedFilters(value, correspondingFilter, selectedFilters);
    }
  });

  return selectedFilters;
};

export const setSearchValueAndCatalogOnlyInUrlParams = (
  searchParams: string,
  searchValue: string,
  catalogOnly: boolean | null | undefined,
) => {
  const urlSearchParams = new URLSearchParams(searchParams);

  if (searchValue) {
    urlSearchParams.set(SEARCH_URL_KEY, searchValue);
  } else {
    urlSearchParams.delete(SEARCH_URL_KEY);
  }

  urlSearchParams.set(
    CATALOG_ONLY_KEY,
    catalogOnly !== false ? 'true' : 'false',
  );
  return urlSearchParams;
};

export const getSearchValueFromUrl = (searchParams: string) => {
  const urlSearchParams = new URLSearchParams(searchParams);
  const searchValue = urlSearchParams.get(SEARCH_URL_KEY);
  return searchValue ? replacePlusSignsWithSpace(searchValue) : '';
};

export const getCatalogOnlyFromUrl = (searchParams: string) => {
  const urlSearchParams = new URLSearchParams(searchParams);
  const catalogItemOnly = urlSearchParams.get(CATALOG_ONLY_KEY);

  return !catalogItemOnly || catalogItemOnly.toLowerCase() === 'true';
};

export const clearFilterFromUrl = (searchParams: string, filter: SkuFilter) => {
  const urlSearchParams = new URLSearchParams(searchParams);
  urlSearchParams.delete(getFilterKey(filter));
  return urlSearchParams;
};

const replaceWhitespace = (value: string): string => value.replace(/\s/g, '+');
const replaceWhitespaceAndLowercase = (value: string): string =>
  replaceWhitespace(value).toLowerCase();
const replacePlusSignsWithSpace = (value: string): string =>
  value.replace(/[+]/g, ' ');

const getFilterKey = (filter: SkuFilter): string =>
  FilterableSkuAttribute[filter.attribute].toLowerCase();

const convertBaseFilterToUrlString = (filter: BaseFilter): string =>
  filter.selectedOptions.reduce((accumulatedString, option) => {
    if (accumulatedString === '') {
      return replaceWhitespaceAndLowercase(option.value);
    }

    return `${accumulatedString},${replaceWhitespaceAndLowercase(
      option.value,
    )}`;
  }, '');

const convertRangeFilterToUrlString = (filter: RangeFilter): string =>
  `${filter.minValue || ''}-${filter.maxValue || ''}`;

// Expects value to be of the form "option1,option2,option3"
const addBaseFilterUrlParamToSelectedFilters = (
  value: string,
  correspondingFilter: SkuFilter,
  selectedFilters: SkuFilter[],
): void => {
  const selectedOptions = value
    .split(',')
    .map((paramOption) => {
      const valueWithWhitespace =
        replacePlusSignsWithSpace(paramOption).toLowerCase();
      return correspondingFilter.options.find(
        (option) => option.value.toLowerCase() === valueWithWhitespace,
      );
    })
    .filter(Boolean) as Option[];

  selectedFilters.push({
    ...correspondingFilter,
    selectedOptions,
  });
};

// Expects value to be of the form "minValue-maxValue"
const addRangeFilterUrlParamToSelectedFilters = (
  value: string,
  correspondingFilter: SkuFilter,
  selectedFilters: SkuFilter[],
): void => {
  const [minValue, maxValue] = value.split('-');

  selectedFilters.push({
    ...correspondingFilter,
    maxValue: parseInt(maxValue, 10) || null,
    minValue: parseInt(minValue, 10) || null,
  });
};
