import { ReactNode, useRef, MouseEvent } from 'react';

import { IconButton, ClickAwayListener } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { PopperPlacementType } from '@material-ui/core/Popper';

import { Props as ButtonProps } from 'common/components/Buttons/Button/Button';
import { Popper } from 'common/components/Popper/Popper';
import { TextField } from 'common/components/Form/TextField/TextField';

import { useCopyToClipboard } from './copyToClipboardButton.hooks';

import {
  PopperContent,
  iconStyles,
  textFieldStyles,
  CopyButton,
} from './CopyToClipboard.styles';

type Props = ButtonProps & {
  asyncGetCopyText?: () => Promise<string>;
  children: ReactNode;
  copyText?: string;
  popperPlacement?: PopperPlacementType;
};

export const CopyToClipboardButton = ({
  asyncGetCopyText,
  children,
  copyText: syncCopyText = '',
  onClick,
  popperPlacement = 'top-start',
  ...rest
}: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [
    { copyText: asyncCopyText, isPopperOpen, loading },
    { setInitFetch, setPopperClose, setPopperOpen },
  ] = useCopyToClipboard();

  const handleCopyText = () => {
    if (!inputRef.current) return;

    inputRef.current.select();
    document.execCommand('copy');
  };

  const handleOpenPopper = async (event: MouseEvent<HTMLButtonElement>) => {
    if (onClick) onClick(event);

    setPopperOpen();

    if (asyncGetCopyText && !asyncCopyText) setInitFetch(asyncGetCopyText);
  };

  return (
    <>
      <Popper
        anchorEl={buttonRef.current}
        disablePortal
        open={isPopperOpen}
        placement={popperPlacement}
      >
        <ClickAwayListener onClickAway={setPopperClose}>
          <PopperContent>
            <TextField
              css={textFieldStyles}
              inputRef={inputRef}
              value={asyncCopyText || syncCopyText}
            />
            <IconButton onClick={handleCopyText} size="small">
              <FileCopyIcon css={iconStyles} />
            </IconButton>
          </PopperContent>
        </ClickAwayListener>
      </Popper>
      <CopyButton
        {...rest}
        isLoading={loading}
        onClick={handleOpenPopper}
        ref={buttonRef}
      >
        {children}
      </CopyButton>
    </>
  );
};
