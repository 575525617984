import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { DownshiftState, StateChangeOptions } from 'downshift';

import { ProjectStatus } from 'common/apollo/enums/ProjectStatus';
import { useToggle } from 'common/hooks/useToggle';

import {
  ProjectStatusGet,
  ProjectStatusGetVariables,
  ProjectStatusUpdate,
  ProjectStatusUpdateVariables,
} from 'common/shared/Sidebar/StatusBreakGround/ProjectSidebarStatus/__generated__/projectSidebarStatus.graphql.generated';

import {
  GET_PROJECT_STATUS,
  UPDATE_PROJECT_STATUS,
} from './projectSidebarStatus.graphql';

const useGetProjectStatus = (id: string) => {
  const { data, loading } = useQuery<
    ProjectStatusGet,
    ProjectStatusGetVariables
  >(GET_PROJECT_STATUS, { variables: { id } });

  const status = data?.project?.projectStatus;
  const statusHistory = data?.project?.projectStatusChangeEvents;

  return {
    loading,
    status,
    statusHistory,
  };
};

export const useProjectSidebarStatus = (renovationPlanId: string) => {
  const projectId =
    useParams<{ projectId: string }>().projectId || renovationPlanId;

  const { loading, status, statusHistory } = useGetProjectStatus(projectId);

  const {
    isOn: isConfirmLostProjectModalOpen,
    toggleOff: handleCloseConfirmLostProjectModal,
    toggleOn: handleOpenConfirmLostProjectModal,
  } = useToggle();

  const [changeProjectStatus] = useMutation<
    ProjectStatusUpdate,
    ProjectStatusUpdateVariables
  >(UPDATE_PROJECT_STATUS);

  const checkProjectStatus = async (projectStatus: ProjectStatus) => {
    if (projectStatus === ProjectStatus.LOST) {
      return handleOpenConfirmLostProjectModal();
    }

    changeStatus(projectStatus);
  };

  const customStateReducer = (
    state: DownshiftState<any>,
    changes: StateChangeOptions<any>,
  ) => {
    if (state.inputValue === 'Active' && changes.inputValue === 'Lost') {
      changes.inputValue = 'Active';
      return changes;
    }

    return changes;
  };

  const changeStatus = async (projectStatus: ProjectStatus) => {
    await changeProjectStatus({
      variables: {
        input: {
          id: projectId,
          status: projectStatus,
        },
      },
    });
  };

  return {
    changeStatus,
    checkProjectStatus,
    customStateReducer,
    handleCloseConfirmLostProjectModal,
    isConfirmLostProjectModalOpen,
    loading,
    status,
    statusHistory,
  };
};
