import { Button } from 'common/components/Button/Button';

import { ReactComponent as UploadIcon } from 'common/assets/icons/upload-icon.svg';
import { ReactComponent as PencilIcon } from 'common/assets/icons/edit.svg';

import { Photo } from 'common/components/PhotoUpload/Photo/Photo';

import { usePhotoUpload } from 'common/components/PhotoUpload/photoUpload.hooks';

import {
  InnerWrapper,
  MobileActionWrapper,
  MobileUploadButtonWrapper,
  UploadProgress,
  UploadText,
  UploaderWrapper,
  Wrapper,
} from 'common/components/PhotoUpload/photoUpload.styles';
import { Lightbox } from 'common/components/Lightbox/Lightbox';
import { IMAGE_MIME_TYPES } from 'common/consts/mimeTypes';
import { PricingEngineMediaType } from 'common/apollo/enums/PricingEngineMediaType';

type Props = {
  allowedFileTypes?: string[];
  fieldName: string;
  uploadProgress?: number;
};

export const PhotoUpload = ({
  allowedFileTypes = IMAGE_MIME_TYPES,
  fieldName,
  uploadProgress,
}: Props) => {
  const {
    handleFileDragOver,
    handleFileDrop,
    handleInputChange,
    handlePhotoDelete,
    handleUploadButtonClick,
    inputRef,
    isEditMode,
    isEmpty,
    isMobile,
    lightBoxProps: { previewRender, ...lightBoxProps },
    photos,
    toggle,
  } = usePhotoUpload(allowedFileTypes, fieldName);

  const images =
    photos?.filter((p) => p.type === PricingEngineMediaType.IMAGE) || [];

  return (
    <Wrapper isEmpty={isEmpty}>
      <InnerWrapper onDragOver={handleFileDragOver} onDrop={handleFileDrop}>
        {isMobile && isEmpty && (
          <MobileUploadButtonWrapper onClick={handleUploadButtonClick}>
            <Button className="primary" fluid size="small" type="button">
              Upload Media
            </Button>
          </MobileUploadButtonWrapper>
        )}

        {!isMobile && (
          <UploaderWrapper onClick={handleUploadButtonClick}>
            {!isEmpty && (
              <Button className="primary" size="small" type="button">
                <UploadIcon />
              </Button>
            )}

            {isEmpty && (
              <UploadText>
                Drag and drop photo or <u>choose files</u>
              </UploadText>
            )}
          </UploaderWrapper>
        )}

        <input
          accept={allowedFileTypes.join(',')}
          hidden
          id="photo-upload-input"
          multiple
          onChange={handleInputChange}
          ref={inputRef}
          type="file"
        />

        <>
          {photos?.map((photo) => (
            <Photo
              alt={photo.alt}
              isEditMode={isEditMode}
              key={photo.id}
              onClick={() =>
                photo.type === PricingEngineMediaType.IMAGE
                  ? previewRender(images.indexOf(photo))
                  : window.open(photo.url)
              }
              onDelete={() => handlePhotoDelete(photo.id)}
              type={photo.type}
              url={photo.url}
            />
          ))}
        </>

        <Lightbox {...lightBoxProps} />
      </InnerWrapper>

      {isMobile && !isEmpty && (
        <MobileActionWrapper>
          <Button
            className="secondary"
            fluid
            onClick={handleUploadButtonClick}
            size="medium"
            type="button"
          >
            <UploadIcon />
            Upload Media
          </Button>

          <Button
            className="white"
            fluid
            onClick={toggle}
            size="medium"
            type="button"
          >
            {isEditMode ? <UploadIcon /> : <PencilIcon />}
            {isEditMode ? 'Done Editing' : 'Edit Media'}
          </Button>
        </MobileActionWrapper>
      )}

      <UploadProgress progress={uploadProgress} />
    </Wrapper>
  );
};
