import styled from '@emotion/styled';

export const Content = styled.div`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.color.gray11};
  grid-column-start: 2;
  text-align: left;
`;

export const Header = styled.div`
  ${({ theme }) => theme.typography.header6};
  grid-column-start: 2;
`;

export const Wrapper = styled.div`
  align-items: flex-start;
  color: ${({ theme }) => theme.color.gray12};
  display: grid;
  gap: 12px;
  max-width: 340px;
  text-align: left;
`;

export const Button = styled.button`
  align-items: center;
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  padding: 0;
  width: fit-content;

  &:hover {
    background-color: ${({ theme }) => theme.color.gray2};
    border-radius: 50%;
    outline: 4px solid ${({ theme }) => theme.color.gray2};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;
