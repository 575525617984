import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { ReactComponent as ChevronLeftIcon } from 'common/assets/icons/chevron-left-circle.svg';
import { ReactComponent as ChevronRightIcon } from 'common/assets/icons/chevron-right-circle.svg';

import { ProjectPlanRoomRender } from 'common/apollo/models/projectPlanRoomRender';

import {
  EmptyState,
  NextButton,
  Label,
  PrevButton,
  Root,
} from './roomCarousel.styles';

type Props = {
  items: ProjectPlanRoomRender[];
  label: string;
};

export const RoomCarousel = ({ items, label }: Props) => {
  if (!items.length) {
    return (
      <Root>
        <EmptyState>No render is added yet</EmptyState>
        <Label>{label}</Label>
      </Root>
    );
  }

  return (
    <Root>
      <Carousel
        infiniteLoop
        renderArrowNext={(clickHandler, hasNext) => (
          <NextButton disabled={!hasNext} onClick={clickHandler}>
            <ChevronRightIcon />
          </NextButton>
        )}
        renderArrowPrev={(clickHandler, hasPrev) => (
          <PrevButton disabled={!hasPrev} onClick={clickHandler}>
            <ChevronLeftIcon />
          </PrevButton>
        )}
        showStatus={false}
        showThumbs={false}
      >
        {items.map(({ id, imageUrl }) => (
          <img alt={imageUrl} key={id} src={imageUrl} />
        ))}
      </Carousel>
      <Label>{label}</Label>
    </Root>
  );
};
