import { object, string } from 'yup';

import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

export const SHORT_TEXT_MAX_LENGTH = 265;

export const questionIdentifiers = [
  PropertyGroupIdentifier.ELECTRICAL_PANEL_LOCATION,
  PropertyGroupIdentifier.IS_SCOPE_VERIFICATION_REQUIRED,
  PropertyGroupIdentifier.IS_THERE_SPACE_IN_THE_SUB_E_PANEL_FOR_ADDITIONAL_BREAKERS,
  PropertyGroupIdentifier.PHOTO_OF_ELECTRICAL_PANEL,
  PropertyGroupIdentifier.WHAT_IS_TOTAL_AMPERAGE_OF_ELECTRICAL_SERVICE,
];

export const openQuestionsIdentifiers = [
  PropertyGroupIdentifier.LIST_OF_ALL_TO_VIF,
  PropertyGroupIdentifier.POST_SITE_SURVEY_NOTES,
  PropertyGroupIdentifier.PRICING_UPDATES_NOTES,
  PropertyGroupIdentifier.SERVICE_ENTRANCE,
  PropertyGroupIdentifier.SUPER_CONTACT_INFO,
];

export const identifiers = [
  ...questionIdentifiers,
  ...openQuestionsIdentifiers,
];

export const validationSchema = object().shape({
  [PropertyGroupIdentifier.ELECTRICAL_PANEL_LOCATION]: string(),
  [PropertyGroupIdentifier.IS_SCOPE_VERIFICATION_REQUIRED]: string(),
  [PropertyGroupIdentifier.IS_THERE_SPACE_IN_THE_SUB_E_PANEL_FOR_ADDITIONAL_BREAKERS]:
    string(),
  [PropertyGroupIdentifier.LIST_OF_ALL_TO_VIF]: string(),
  [PropertyGroupIdentifier.PHOTO_OF_ELECTRICAL_PANEL]: string(),
  [PropertyGroupIdentifier.POST_SITE_SURVEY_NOTES]: string(),
  [PropertyGroupIdentifier.PRICING_UPDATES_NOTES]: string(),
  [PropertyGroupIdentifier.SUPER_CONTACT_INFO]: string().max(
    SHORT_TEXT_MAX_LENGTH,
    `Max ${SHORT_TEXT_MAX_LENGTH} digits`,
  ),
  [PropertyGroupIdentifier.WHAT_IS_TOTAL_AMPERAGE_OF_ELECTRICAL_SERVICE]:
    string(),
});
