import SearchIcon from '@material-ui/icons/Search';

import { BaseFilter } from 'common/components/Filters/filters.types';
import { useCheckboxFilter } from 'common/components/FiltersMenu/CheckboxItem/checkboxFilter.hooks';

import { List } from 'common/components/FiltersMenu/filtersMenu.styles';
import {
  AttributeItem,
  CheckboxSearchWrapper,
  Clear,
  FilterButton,
  FilterCollapse,
  FilterCounter,
  FilterItem,
  FilterTitleWrapper,
  Wrapper,
} from 'common/components/FiltersMenu/common/filterMenuItem.styles';
import { FilterWrapperInner } from 'common/components/FiltersMenu/CheckboxItem/checkboxFilter.styles';
import { SearchInput } from 'common/components/Form/SearchInput/SearchInput';
import { SEARCH_BAR_THRESHOLD } from 'common/components/FiltersMenu/CheckboxItem/checkboxFilter.consts';
import { CheckboxRow } from 'common/components/FiltersMenu/CheckboxItem/CheckboxRow';
import { ExpandedIcon } from 'common/components/Icons/Icons';

type Props = {
  filter: BaseFilter;
  handleFilterChange: (filter: BaseFilter) => void;
};

export const CheckboxFilterMenuItem = ({
  filter,
  handleFilterChange,
}: Props) => {
  const { label, options } = filter;
  const {
    clearSelections,
    containerRef,
    filteredOptions,
    isOpen,
    searchQuery,
    selectedOptions,
    setSearchQuery,
    toggle,
    toggleItem,
  } = useCheckboxFilter(filter, handleFilterChange);
  const showSearchBar = options.length > SEARCH_BAR_THRESHOLD;

  return (
    <FilterItem>
      <Wrapper ref={containerRef}>
        <FilterButton isSelected={isOpen} onClick={toggle} small type="button">
          <FilterTitleWrapper>
            {label}
            {!!selectedOptions.length && (
              <FilterCounter role="img">{selectedOptions.length}</FilterCounter>
            )}
          </FilterTitleWrapper>
          <FilterTitleWrapper>
            {!!selectedOptions.length && (
              <Clear
                onClick={(e) => {
                  e.stopPropagation();
                  clearSelections();
                }}
              >
                Clear
              </Clear>
            )}
            <ExpandedIcon open={isOpen} />
          </FilterTitleWrapper>
        </FilterButton>

        <FilterCollapse in={isOpen} unmountOnExit>
          <FilterWrapperInner>
            <List>
              {showSearchBar && (
                <AttributeItem>
                  <CheckboxSearchWrapper>
                    <SearchInput
                      endAdornment={undefined}
                      onSearchChange={(a) => setSearchQuery(a)}
                      placeholder="Search..."
                      startAdornment={<SearchIcon color="disabled" />}
                    />
                  </CheckboxSearchWrapper>
                </AttributeItem>
              )}
              {searchQuery
                ? filteredOptions.map((option) => (
                    <CheckboxRow
                      displayName={option.label}
                      handleChange={() => {
                        toggleItem(option.value);
                      }}
                      key={`${option.label}_${option.value}`}
                      selected={selectedOptions.includes(option.value)}
                    />
                  ))
                : filteredOptions.map((option) => (
                    <CheckboxRow
                      displayName={option.label}
                      handleChange={() => {
                        toggleItem(option.value);
                      }}
                      key={`${option.label}_${option.value}`}
                      selected={selectedOptions.includes(option.value)}
                    />
                  ))}
            </List>
          </FilterWrapperInner>
        </FilterCollapse>
      </Wrapper>
    </FilterItem>
  );
};
