import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { HorizontalAlignment, VerticalAlignment } from 'common/enums/Alignment';
import { CellType } from 'common/enums/GridCell';

import { CellProps } from './GridTableCell';

export type RowProps = {
  cellType?: CellType;
  columnsInPrintMode?: number;
  columnsWidth?: string;
  hAlignment?: HorizontalAlignment;
  vAlignment?: VerticalAlignment;
};

type TableProps = {
  columnGap?: string;
  hAlignment?: HorizontalAlignment;
  vAlignment?: VerticalAlignment;
};

const CELL_TYPE = {
  [CellType.HEAD]: { cellHeight: 56, cellPadding: 20 },
  [CellType.COMPACT]: { cellHeight: 38, cellPadding: 10 },
  [CellType.DEFAULT]: { cellHeight: 52, cellPadding: 16 },
};

const TEXT_ALIGNMENT = {
  [HorizontalAlignment.LEFT]: 'start',
  [HorizontalAlignment.CENTER]: 'center',
  [HorizontalAlignment.RIGHT]: 'end',
  [HorizontalAlignment.NORMAL]: 'initial',
};

const FLEX_ALIGNMENT = {
  [VerticalAlignment.TOP]: 'flex-start',
  [HorizontalAlignment.LEFT]: 'flex-start',
  [VerticalAlignment.CENTER]: 'center',
  [VerticalAlignment.BOTTOM]: 'flex-end',
  [HorizontalAlignment.RIGHT]: 'flex-end',
  [HorizontalAlignment.NORMAL]: 'normal',
};

export const Cell = styled.div<CellProps>`
  align-self: stretch;
  display: flex;
  font-size: ${({ fontSize = 14 }) => fontSize}px;
  font-weight: ${({ fontWeight = 'normal' }) => fontWeight};
  min-height: ${({ cellType = CellType.DEFAULT }) =>
    CELL_TYPE[cellType].cellHeight}px;
  padding: ${({ cellType = CellType.DEFAULT }) =>
      CELL_TYPE[cellType].cellPadding}px
    10px;
  word-break: break-word;
  ${({ vAlignment }) => css`
    align-items: ${vAlignment ? FLEX_ALIGNMENT[vAlignment] : 'inherit'};
  `};
  ${({ hAlignment }) => css`
    justify-content: ${hAlignment ? FLEX_ALIGNMENT[hAlignment] : 'inherit'};
    text-align: ${hAlignment ? TEXT_ALIGNMENT[hAlignment] : 'inherit'};
  `}
  &:focus {
    outline: solid 1px ${({ theme }) => theme.color.primaryOrange};
  }
  @media print {
    font-size: 12px;
    min-height: ${CELL_TYPE[CellType.COMPACT].cellHeight}px;
    padding: 10px;
  }
`;

export const Thead = styled.div<RowProps>`
  align-content: center;
  border: 1px solid ${({ theme }) => theme.color.alto};

  display: grid;
  grid-auto-flow: column;

  ${({ columnsWidth }) =>
    columnsWidth &&
    css`
      grid-template-columns: ${columnsWidth};
    `};

  ${({ columnsInPrintMode }) =>
    columnsInPrintMode &&
    css`
      @media print {
        grid-template-columns: repeat(${columnsInPrintMode}, 1fr);
      }
    `}

  ${({ vAlignment }) => css`
    align-items: ${vAlignment ? FLEX_ALIGNMENT[vAlignment] : 'inherit'};
  `};

  ${({ hAlignment }) => css`
    justify-content: ${hAlignment ? FLEX_ALIGNMENT[hAlignment] : 'inherit'};
  `};

  ${({ cellType }) =>
    cellType &&
    css`
      > ${Cell} {
        min-height: ${CELL_TYPE[cellType].cellHeight}px;
        padding: ${CELL_TYPE[cellType].cellPadding}px;
        @media print {
          min-height: ${CELL_TYPE[CellType.COMPACT].cellHeight}px;
          padding: 12px;
        }
      }
    `}
`;

export const Trow = styled(Thead)<RowProps>`
  border-top: 0;
`;

export const Table = styled.div<TableProps>`
  align-items: center;
  display: grid;
  justify-content: normal;
  ${({ columnGap = 0 }) =>
    columnGap &&
    css`
      ${Thead},
      ${Trow} {
        column-gap: ${columnGap};
        padding: 0 ${columnGap};
      }
    `};
  page-break-after: auto;
  @media print {
    overflow: visible;
  }
`;
