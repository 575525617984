import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as History } from 'common/assets/icons/history.svg';
import { Typography } from 'common/components/Typography/Typography';

type PropsForOutdated = {
  isOutdated?: boolean;
};

export const DateOverdueText = styled.span`
  color: ${({ theme }) => theme.color.red9};
  margin-right: 16px;
  width: 121px;
`;

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.gray7};
  height: 1px;
  margin-left: 16px;
  margin-right: 16px;
`;

export const HistoryIcon = styled(History)`
  margin-right: 9px;
`;

export const HistoryText = styled(Typography)<PropsForOutdated>`
  color: ${({ theme }) => theme.color.gray12};
  margin-right: 16px;

  ${({ isOutdated, theme }) => css`
    ${isOutdated &&
    css`
      color: ${theme.color.gray11};
      font-weight: 500;
    `}
  `}
`;

export const HistoryTextWrapper = styled.div``;

export const HistorySetShiftText = styled.div`
  color: ${({ theme }) => theme.color.gray11};
  text-align: right;
`;

export const HistoryExtraText = styled.div`
  display: flex;
`;

export const HistoryViewButton = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.color.blue11};
  display: flex;
  font: 14px/20px Inter;
  margin-right: 16px;
  position: absolute;
  right: 0;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const TableHeader = styled.span`
  font: 500 16px/24px ${({ theme }) => theme.font.inter};
`;

export const TableWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.gray6};
  border-radius: 4px;
`;

export const TableRowFiller = styled.div``;

export const TableRowWrapper = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.color.gray2};
  display: grid;
  grid-template-columns: 1fr 1fr 0.4fr 1fr;
  font: 14px/20px ${({ theme }) => theme.font.inter};
  justify-content: space-between;
  padding: 16px;
  position: relative;
  border-top: 1px solid ${({ theme }) => theme.color.gray7};
`;

export const TableRowHeader = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.color.gray2};
  display: flex;
  font: 14px/20px ${({ theme }) => theme.font.inter};
  justify-content: space-between;
  padding: 16px;
  position: relative;
`;
