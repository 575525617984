import { gql } from '@apollo/client';

export const GET_PROJECT_MATERIALS_LEAD_TIME = gql`
  query GetProjectMaterialsLeadTime($ids: [ID!]!) {
    projectMaterials(ids: $ids) {
      id
      sku {
        id
        name
        vendor {
          id
          vendorSku
          link
          backInStockDate
          maxLeadTimeWeeks
          minLeadTimeWeeks
          leadTimeUpdatedAt
          stockStatus
          vendor {
            id
            name
          }
        }
      }
    }
  }
`;
