import { LinkButton, Button } from 'common/components/Button/Button';

import {
  Content,
  Footer,
  Header,
  Wrapper,
} from 'PricingEngineSidebar/Proposal/ProposalAction/ErrorPublishingProposal/errorPublishingProposal.styles';

import { useErrorPublishingProposal } from 'PricingEngineSidebar/Proposal/ProposalAction/ErrorPublishingProposal/errorPublishingProposal.hooks';
import { CloseButton } from 'common/components/Form/CloseButton/CloseButton';
import { AUTH_PATHS } from 'common/routes/routerPaths';

type Props = {
  proposalId: string;
};

export const ErrorPublishingProposal = ({ proposalId }: Props) => {
  const { handleClose } = useErrorPublishingProposal();

  return (
    <Wrapper>
      <Header>
        More Information Required to Publish{' '}
        <CloseButton onClick={handleClose} />
      </Header>

      <Content>
        <p>
          To publish the proposal, please fill out all the required fields on
          the
          <b> Project Overview </b> page.
        </p>
      </Content>

      <Footer>
        <Button onClick={handleClose} small>
          Close
        </Button>

        <LinkButton
          color="secondary"
          onClick={handleClose}
          small
          to={AUTH_PATHS.getProjectOverviewPath(proposalId)}
          variant="contained"
        >
          Go to Project Overview
        </LinkButton>
      </Footer>
    </Wrapper>
  );
};
