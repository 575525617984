import { Field, FieldArray, Form, Formik } from 'formik';

import { Dialog, DialogTitle } from '@material-ui/core';

import { ModalCloseButton } from 'common/components/Modals/ModalCloseButton';
import { Button } from 'common/components/Buttons/Button/Button';
import { FormTextArea } from 'common/_components/Form/FormTextarea/FormTextarea';

import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

import {
  DialogActions,
  DialogContent,
} from 'ProjectProfile/common/components/EditForm/editForm.styles';

import { validationSchema } from './designerNotesEdit.consts';
import { useDesignerNotesEdit } from './designerNotesEdit.hooks';
import { RoomName, Section } from './designerNotesEdit.styles';
import { DesignerNotesEditFormResponse } from './designerNotesEdit.types';

type Props = {
  close: () => void;
};

export const DesignerNotesEdit = ({ close }: Props) => {
  const { initialValues, isLoading, submit } = useDesignerNotesEdit();

  const handleSubmit = (values: DesignerNotesEditFormResponse) =>
    submit(values, close);

  if (isLoading) return null;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onBackdropClick={close}
      onEscapeKeyDown={close}
      open
    >
      <DialogTitle>Designer Notes</DialogTitle>
      <ModalCloseButton onClick={close} />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values: { designerNotesEdit } }) => (
          <Form>
            <DialogContent scrollable>
              <FieldArray
                name="designerNotesEdit"
                render={() => (
                  <>
                    {designerNotesEdit.map(
                      ({ pricingEngineId, room }, index) => (
                        <Section key={pricingEngineId}>
                          <RoomName>{room}</RoomName>
                          <Field
                            name={`designerNotesEdit.${index}.pricingEngineId`}
                            type="hidden"
                          />
                          <FormTextArea
                            disabled={isSubmitting}
                            id={PropertyGroupIdentifier.DESIGNER_NOTES}
                            label="Designer Notes"
                            name={`designerNotesEdit.${index}.value`}
                            placeholder="Notes"
                            rows={5}
                          />
                        </Section>
                      ),
                    )}
                  </>
                )}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
