import { ProjectInformation } from 'ProjectProfile/ProjectInformation/v2/ProjectInformation';
import {
  Header,
  Layout,
  Title,
} from 'ProjectProfile/common/components/Layout/Layout';

const InternalNotes = () => (
  <Layout>
    <Header>
      <Title>Internal Notes</Title>
    </Header>
    <ProjectInformation isInternalNotes />
  </Layout>
);

export default InternalNotes;
