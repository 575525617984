import { ReactNode } from 'react';

import { MenuItem } from './MenuButton.styles';
import { useMenuButtonContext } from './MenuButton';

type Props = {
  children: ReactNode;
  onClick: () => void;
};

export const MenuButtonItem = ({ children, onClick }: Props) => {
  const { handleMenuClose } = useMenuButtonContext();

  const handleClick = () => {
    handleMenuClose();

    onClick();
  };

  return <MenuItem onClick={handleClick}>{children}</MenuItem>;
};
