import { forwardRef, Ref } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import MaterialSpinner from '@material-ui/core/CircularProgress';

import { SPINNER_SIZE } from 'common/consts/theme';

import { StyledButton, SpinnerContainer } from './Button.styles';

export type Props = ButtonProps & {
  isLoading?: boolean;
  small?: boolean;
  uppercase?: boolean;
};

export const Button = forwardRef(
  (
    { children, isLoading, small, uppercase, ...props }: Props,
    ref: Ref<HTMLButtonElement>,
  ) => (
    <StyledButton
      disabled={isLoading}
      isLoading={isLoading}
      ref={ref}
      small={small}
      uppercase={uppercase}
      {...props}
    >
      {children}
      {isLoading && (
        <SpinnerContainer small={small}>
          <MaterialSpinner
            color="primary"
            size={small ? SPINNER_SIZE.small : SPINNER_SIZE.large}
          />
        </SpinnerContainer>
      )}
    </StyledButton>
  ),
);
