import { CONTRACTOR_PATHS } from 'common/routes/paths/contractorPaths';
import { MATERIALS_CATALOG_PATHS } from 'common/routes/paths/materialsCatalogPaths';
import { NOTIFICATIONS_PATHS } from 'common/routes/paths/notificationPaths';
import { PAYMENT_SCHEDULE_PATHS } from 'common/routes/paths/paymentSchedulePaths';
import { PAYOUT_SCHEDULE_PATHS } from 'common/routes/paths/payoutSchedulePaths';
import { PRICING_ENGINE_PATHS } from 'common/routes/paths/pricingEnginePaths';
import { PROJECT_GALLERY_PATHS } from 'common/routes/paths/projectGalleryPaths';
import {
  PROJECT_PATHS,
  PROJECT_PROFILE_PATHS,
} from 'common/routes/paths/projectPaths';
import { PROPOSAL_PATHS } from 'common/routes/paths/proposalPaths';
import { TEAM_MEMBER_PATHS } from 'common/routes/paths/teamMemberPaths';
import { USER_PATHS } from 'common/routes/paths/userPaths';
import { WORK_ORDER_PATHS } from 'common/routes/paths/workOrderPaths';
import { PAYOUT_PATHS } from 'common/routes/paths/payoutPaths';
import { ORGANIZATION_PATHS } from 'common/routes/paths/organizationPaths';

export const AUTH_PATHS = {
  ALL_SCHEDULES: '/all-schedules',
  CONTRACTORS: '/contractors',
  CONTRACTOR_NEW: '/contractors/new',
  MATERIALS_CATALOG: '/materials-catalog',
  ORGANIZATIONS: '/organizations',
  PAYOUTS: '/payouts',
  PROJECTS: '/projects',
  PROJECTS_V2: '/projects-v2',
  SCHEDULE_TEMPLATES: '/schedule-templates',
  SCHEDULE_TEMPLATES_GENERIC_ITEMS: '/schedule-templates/generic-items',
  TEAM_MEMBERS: '/team-members',
  TEAM_MEMBERS_NEW: '/team-members/new',
  USERS: '/users',
  USER_ACCOUNTS: '/user-accounts',
  USER_ACCOUNTS_NEW: '/user-accounts/new',
  WORK_ORDER_CONTENT: '/work-orders-content',

  ...PROJECT_GALLERY_PATHS,
  ...PROJECT_PATHS,
  ...PROJECT_PROFILE_PATHS,
  ...PRICING_ENGINE_PATHS,
  ...PAYOUT_SCHEDULE_PATHS,
  ...PAYMENT_SCHEDULE_PATHS,
  ...PROPOSAL_PATHS,
  ...NOTIFICATIONS_PATHS,
  ...CONTRACTOR_PATHS,
  ...USER_PATHS,
  ...WORK_ORDER_PATHS,
  ...TEAM_MEMBER_PATHS,
  ...MATERIALS_CATALOG_PATHS,
  ...PAYOUT_PATHS,
  ...ORGANIZATION_PATHS,
};

export const BASE_PATH = '/';

export const PUBLIC_PATHS = {
  LOG_IN: '/login',
  RESET_PASSWORD: '/reset-password',
};
