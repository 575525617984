import { useContext, useCallback } from 'react';

import { ModalContextStatic, ModalContextDynamic } from './ModalProvider';

export const useIsModalOpen = (id: string) => {
  const registry = useContext(ModalContextDynamic);
  return !!registry[id];
};

export const useSetModalOpen = () => {
  const setIsModalOpen = useContext(ModalContextStatic);

  const setVisibility = useCallback(
    (id: string, isOpen: boolean) =>
      setIsModalOpen((currentRegistry) => ({
        ...currentRegistry,
        [id]: isOpen,
      })),
    [setIsModalOpen],
  );

  return setVisibility;
};

export const useModalActions = (dialogId: string) => {
  const setModalState = useSetModalOpen();

  const handleModalOpen = useCallback(() => {
    setModalState(dialogId, true);
  }, [dialogId, setModalState]);

  const handleModalClose = useCallback(() => {
    setModalState(dialogId, false);
  }, [dialogId, setModalState]);

  return { handleModalClose, handleModalOpen };
};
