import { gql } from '@apollo/client';

export const GET_PROJECT_KEY_DATE = gql`
  query ProjectKeyDate($input: ProjectKeyDateInput!) {
    projectKeyDate(input: $input) {
      id
      type
      date
      sourceType
      validHistoryItems {
        id
        keyDateType
        inputType
        date
      }
    }
  }
`;

export const INITIAL_BREAK_GROUND_DATE_UPDATE = gql`
  mutation InitialBreakGroundDateUpdate(
    $input: InitialBreakGroundDateUpdateInput!
  ) {
    initialBreakGroundDateUpdate(input: $input) {
      id
    }
  }
`;
