import { markdown2HTML } from 'common/utils/markdown2HTML';

import { Wrapper } from 'common/components/Markdown/markdown.styles';

type Props = {
  markdown: string;
};

export const Markdown = ({ markdown }: Props) => (
  <Wrapper
    dangerouslySetInnerHTML={{
      __html: markdown2HTML(markdown),
    }}
  />
);
