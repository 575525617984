import { makeStyles, createStyles } from '@material-ui/core/styles';

import { THEME } from 'common/consts/theme';

export const getTooltipStyles = (styles?: {
  backgroundColor?: string;
  color?: string;
  useShadow?: boolean;
}) => {
  const { backgroundColor, color, useShadow } = styles || {};

  return makeStyles(() =>
    createStyles({
      arrow: { color: backgroundColor || THEME.color.black },
      tooltip: {
        background: backgroundColor || THEME.color.black,
        boxShadow: useShadow ? 'rgb(0 0 0 / 30%) 0px 0px 7px' : 'none',
        color: color || THEME.color.white,
        padding: '12px',
      },
    }),
  )();
};
