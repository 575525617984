import { SelectType } from 'common/types/select';

import {
  ClearButton,
  Option,
  OptionsList,
  SelectWrapper,
  TextInput,
} from './select.styles';
import { useSelect } from './select.hooks';
import { SelectEvent } from './select.types';

type Props = {
  disabled?: boolean;
  hasError?: boolean;
  id: string;
  isClearable?: boolean;
  onChange: (event: SelectEvent) => void;
  options: SelectType[];
  placeholder?: string;
  styles?: Record<string, string>;
  value: string;
};

export const Select = ({
  disabled,
  hasError,
  id,
  isClearable,
  onChange,
  options,
  placeholder,
  styles,
  value: initialValue,
  ...props
}: Props) => {
  // TODO split that on smaller hooks
  const {
    clearInputValue,
    filteredOptions,
    handleClose,
    handleKeyDown,
    handleOpen,
    handleOptionMouseOver,
    handleTextInputChange,
    inputRef,
    isOpen,
    listRef,
    onOptionClicked,
    selectedIndex,
    selectedOption,
    wrapperRef,
  } = useSelect(id, onChange, options, initialValue);

  return (
    <SelectWrapper
      addedStyles={styles}
      onKeyDown={handleKeyDown}
      ref={wrapperRef}
      {...props}
    >
      <TextInput
        addedStyles={styles}
        autoComplete="off"
        disabled={disabled}
        hasError={hasError}
        id={id}
        isClearable={isClearable}
        onBlur={handleClose}
        onChange={handleTextInputChange}
        onFocus={handleOpen}
        placeholder={placeholder}
        ref={inputRef}
        type="text"
      />
      {!!selectedOption && isClearable && !disabled && (
        <ClearButton onClick={clearInputValue} />
      )}
      {isOpen && (
        <OptionsList ref={listRef}>
          {!filteredOptions.length ? (
            <Option key={Math.random()}>No Values</Option>
          ) : (
            filteredOptions.map((option, index) => {
              const isSelected = option.value === selectedOption?.value;
              const isHighlighted = selectedIndex === index;

              return (
                <Option
                  isHighlighted={isHighlighted}
                  isSelected={isSelected}
                  key={Math.random()}
                  onMouseDown={onOptionClicked(option)}
                  onMouseEnter={handleOptionMouseOver(index)}
                >
                  {option.label}
                </Option>
              );
            })
          )}
        </OptionsList>
      )}
    </SelectWrapper>
  );
};
