import { useParams } from 'react-router-dom';

import { SidebarContentWrapper } from 'common/components/Sidebar/SidebarContentWrapper';
import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';

import { SidebarContent } from 'common/components/Sidebar/SidebarContent';

import { useUserSidebar } from 'UserSidebar/userSidebar.hooks';

const UserSidebar = () => {
  const { userId } = useParams<{
    userId: string;
  }>();

  const {
    initialValues,
    isEditInProgress,
    loadingFetch,
    loadingSubmit,
    marketCode,
    onSubmit,
    projectType,
    renovationPlanId,
    secondaryContactEmail,
    serviceableStatus,
    toggleIsEditInProgress,
    userData,
  } = useUserSidebar(userId);

  if (loadingFetch) return <LoadingSpinnerWithContainer />;

  return (
    <SidebarContentWrapper>
      <SidebarContent
        initialValues={initialValues}
        isEditInProgress={isEditInProgress}
        loadingSubmit={loadingSubmit}
        marketCode={marketCode}
        onSubmit={onSubmit}
        projectType={projectType}
        renovationPlanId={renovationPlanId}
        secondaryContactEmail={secondaryContactEmail}
        serviceableStatus={serviceableStatus}
        toggleIsEditInProgress={toggleIsEditInProgress}
        userData={userData}
        userId={userId}
      />
    </SidebarContentWrapper>
  );
};
export default UserSidebar;
