export enum FeatureFlags {
  ADMIN_LOOKS_FUNCTIONALITY = 'adminLooksFunctionality',
  ADMIN_PAYOUTS = 'adminPayouts',
  ADMIN_SIDEBAR_REMOVE_BREAKGROUND = 'adminSidebarRemoveBreakground',
  BREAK_GROUND_KEY_DATE_VALIDATION_MINIMUM_DAYS_FROM_NOW = 'breakGroundKeyDateValidationMinimumDaysFromNow',
  CHANGE_COPY_HOMEOWNER_LINK_TO_SEND_EMAIL = 'changeCopyHomeownerLinkToSendEmail',
  CONSOLIDATED_20_HO_EXPERIENCE = 'consolidated20HoExperience',
  CONTRACTOR_DESKTOP = 'contractorDesktop',
  CONTRACTOR_LED_CHANGE_ORDERS = 'contractorLedChangeOrders',
  CONTRACTOR_PROPOSAL_FEEDBACK = 'contractorProposalFeedback',
  DISABLE_PROPOSAL_GATING = 'disableProposalGating',
  ENABLE_CONTRACTOR_CHOICE = 'enableContractorChoice',
  ESTIMATE_MULTIPLIER_SLIDER = 'estimateMultiplierSlider',
  FEATURE_LINES_OF_WORK_AI_GENERATOR = 'featureLinesOfWorkAiGenerator',
  HOMEOWNER_ONBOARDING_V2 = 'homeownerOnboardingV2',
  HOMEOWNER_ONBOARDING_V2_ADMIN = 'homeownerOnboardingV2Admin',
  MATERIALS_LONGEST_LEAD_TIME = 'materialsLongestLeadTime',
  NON_BLOCK_PROCURED_MATERIALS = 'nonBlockProcuredMaterials',
  PLANNER_ROOM_NICE_TO_HAVES = 'plannerRoomNiceToHaves',
  POST_DSO = 'postDso',
  PROJECTS_V2 = 'projectsV2',
  PROPOSAL_QA = 'proposalQa',
  SALES_PROPOSAL_ROOM_ESTIMATES = 'salesProposalRoomEstimates',
  SITE_VISIT_SCHEDULING = 'siteVisitScheduling',
  SUPPLY_CHAIN_INTEGRATION = 'supplyChainIntegration',
  TARGET_DATE_MGMT_ADMIN = 'targetDateMgmtAdmin',
  TARGET_DATE_MGMT_BREAKGROUND = 'targetDateMgmtBreakground',
  TIERED_MARKUPS = 'tieredMarkups',
  UNCONFIRM_BREAK_GROUND = 'unconfirmBreakGround',
  WAREHOUSE_TAB_VISIBLE = 'warehouseTabVisible',
  WORK_ORDER_ADJUSTMENT_TAGGING = 'workOrderAdjustmentTagging',
  WORK_ORDER_USE_NEW_KEY_DATES = 'workOrderUseNewKeyDates',
}
