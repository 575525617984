import { ReactNode } from 'react';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { useSidebarHeaderProps } from './SidebarProvider.hooks';
import { ANCHOR_TYPE } from './sidebar.types';
import { Chevron, Wrapper, Content } from './SidebarHeader.styles';

type Props = {
  children?: ReactNode;
  className?: string;
};

export const SidebarHeader = ({ children, className }: Props) => {
  const { anchor, hasAnchor, isDev, isOpen, onOpenToggle } =
    useSidebarHeaderProps();

  return (
    <Wrapper className={className} isDev={isDev}>
      {hasAnchor && (
        <Chevron anchor={anchor} onClick={onOpenToggle} size="small">
          {anchor === ANCHOR_TYPE.left && (
            <>{isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}</>
          )}

          {anchor === ANCHOR_TYPE.right && (
            <>{isOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}</>
          )}
        </Chevron>
      )}

      <Content hasAnchor={hasAnchor} isOpen={isOpen}>
        {children}
      </Content>
    </Wrapper>
  );
};
