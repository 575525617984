import styled from '@emotion/styled';

export const StatusHistoryWrapper = styled.div`
  max-height: 185px;
  overflow: auto;
  padding: 30px 0 0;
`;

export const StatusRow = styled.div`
  padding: 12px 0;
`;

type StatusMessageProps = {
  completed: boolean;
};

export const StatusMessage = styled.h3<StatusMessageProps>`
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 4px;
  color: ${({ completed, theme }) =>
    completed ? theme.color.limeGreen : theme.color.darkGray3};
`;

export const StatusDate = styled.h3`
  color: ${({ theme }) => theme.color.darkGray};
  font-size: 12px;
  font-weight: normal;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const NoUpdatesMessage = styled.h4`
  color: ${({ theme }) => theme.color.darkGray};
  font-size: 12px;
  font-weight: normal;
`;
