import { Button } from 'common/components/Button/Button';
import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';
import { useGenerateSalesProposal } from 'ProjectProfile/ProjectInformation/v2/GenerateSalesProposal/generateSalesProposal.hooks';

import {
  LoadingWrapper,
  Wrapper,
} from 'ProjectProfile/ProjectInformation/v2/GenerateSalesProposal/generateSalesProposal.styles';

export const GenerateSalesProposal = () => {
  const {
    canPublishSalesProposal,
    canUnPublishSalesProposal,
    generateSalesProposal,
    isLoading,
    unPublishSalesProposal,
  } = useGenerateSalesProposal();

  return (
    <Wrapper>
      {canUnPublishSalesProposal ? (
        <Button
          color="danger"
          disabled={isLoading}
          onClick={unPublishSalesProposal}
          size="large"
          variant="contained"
        >
          Un-publish Sales Proposal
        </Button>
      ) : (
        <Button
          color="secondary"
          disabled={!canPublishSalesProposal || isLoading}
          onClick={generateSalesProposal}
          size="large"
          variant="contained"
        >
          Publish Sales Proposal
        </Button>
      )}
      {isLoading && (
        <LoadingWrapper>
          <LoadingSpinnerWithContainer />
        </LoadingWrapper>
      )}
    </Wrapper>
  );
};
