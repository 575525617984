import { ChangeEvent } from 'react';

import {
  TextField,
  Props as TextFieldProps,
} from 'common/components/Form/TextField/TextField';
import {
  CURRENCY_FORMAT_REGEX,
  CURRENCY_FORMAT_REGEX_NEGATIVE,
  CURRENCY_SYMBOL,
} from 'common/components/Form/CurrencyField/CurrencyField.const';
import { CurrencyMask } from 'common/components/Form/CurrencyField/CurrencyMask';
import { floatToCurrencyString } from 'common/utils/currencyFormatter';

export type EventType = ChangeEvent<
  HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
>;

export type Props = Omit<TextFieldProps, 'onChange'> & {
  allowNegative?: boolean;
  onChange: (event: EventType, value: number) => void;
  value: number;
};

export const CurrencyField = ({
  InputProps,
  allowNegative,
  onChange,
  value,
  ...rest
}: Props) => {
  const displayValue = floatToCurrencyString(value || 0, {
    hasCurrencyIndicator: false,
    skipFraction: false,
  });

  const regex = allowNegative
    ? CURRENCY_FORMAT_REGEX_NEGATIVE
    : CURRENCY_FORMAT_REGEX;

  const handleOnChange = (event: EventType) => {
    const currentValue = event.target.value;

    if (!regex.test(currentValue) || !currentValue) return;

    const newValue = currentValue.replace(/,/g, '');

    onChange(event, Math.round(+newValue * 100 || 0));
  };

  return (
    <TextField
      {...rest}
      InputProps={{
        ...InputProps,
        inputComponent: CurrencyMask as any,
      }}
      onChange={handleOnChange}
      placeholder="0.00"
      startAdornment={CURRENCY_SYMBOL}
      value={displayValue}
    />
  );
};
