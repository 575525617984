import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

import { useToggle } from 'common/hooks/useToggle';

import { DesignerNotesEdit } from 'ProjectProfile/common/components/DesignerNotes/DesignerNotesEdit/DesignerNotesEdit';
import { RoleLabelType } from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';
import { SectionOutlined } from 'ProjectProfile/common/components/Section/SectionOutlined';

import { useDesignerNotes } from './designerNotes.hooks';

import { Info, Value, Room, Wrapper } from './designerNotes.styles';

type Props = {
  editable?: boolean;
};

export const DesignerNotes = ({ editable = false }: Props) => {
  const { isOn: isOpen, toggleOff: close, toggleOn: open } = useToggle();
  const { isLoading, roomsQuestions } = useDesignerNotes([
    PropertyGroupIdentifier.DESIGNER_NOTES,
  ]);

  const isEditable = !!(editable && roomsQuestions.length);

  return (
    <>
      <SectionOutlined
        isLoading={isLoading}
        roles={[RoleLabelType.DESIGNER]}
        title="Designer Notes"
        {...(isEditable && { onEditClick: open })}
      >
        {roomsQuestions.length ? (
          roomsQuestions.map(({ id, responseText, room }) => (
            <Wrapper key={`${id}-${room?.id}`}>
              <Room>{room?.name}</Room>
              <Value>{responseText}</Value>
            </Wrapper>
          ))
        ) : (
          <Info>No Room is added yet</Info>
        )}
      </SectionOutlined>

      {isEditable && isOpen && <DesignerNotesEdit close={close} />}
    </>
  );
};
