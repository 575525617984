import { gql } from '@apollo/client';

export const GET_PROJECT_PLAN_CHANGE_LOG = gql`
  query GetProjectChangeLog($input: ProjectPlanChangeLogInput!) {
    projectPlanChangeLog(input: $input) {
      id
      createdAt
      updatedAt
      responseText
      type
      question {
        id
        label
        identifier
      }
      answers {
        id
        createdAt
        updatedAt
        answer {
          id
          label
          isSelected
          type
        }
      }
      authUser {
        adminId
        id
        firstName
        lastName
      }
    }
  }
`;
