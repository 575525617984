const gcd = (a: number, b: number): number => {
  if (b < 0.0000001) return a;

  return gcd(b, Math.floor(a % b));
};

export function convertFractionToNumber(value: string): number {
  const hasFraction = value.search('/') >= 0;
  const hasFractionWithNumber = value.search('-') >= 0;

  const [wholeNumber, fraction] = value.split('-');
  const fractions = (fraction || value).split('/');

  if (!hasFraction) return +wholeNumber;

  const numerator = parseFloat(fractions[0] || '0');
  const denominator = parseFloat(fractions[1] || '1');

  if (hasFraction && !hasFractionWithNumber) return numerator / denominator;

  return Number(wholeNumber) + numerator / denominator;
}

export function convertToFraction(
  value?: number | null,
  showIndicator?: boolean,
): string {
  if (!value) return '';

  const indicator = showIndicator ? '"' : '';

  const afterDecimal = value.toString().length - 2;

  const [wholeNumber, decimalNumber] = value.toString().split('.');

  if (!decimalNumber) return `${wholeNumber}${indicator}`;

  const denominatorNumber = Math.pow(10, afterDecimal);
  const numeratorNumber = Number(`0.${decimalNumber}`) * denominatorNumber;

  const divisor = gcd(numeratorNumber, denominatorNumber);

  const numerator = numeratorNumber / divisor;
  const denominator = denominatorNumber / divisor;

  if (!Number(wholeNumber)) {
    return `${Math.floor(numerator)}/${Math.floor(denominator)}${indicator}`;
  }

  return `${wholeNumber}-${Math.floor(numerator)}/${Math.floor(
    denominator,
  )}${indicator}`;
}

const allowedFractionDenominator = ['2', '4', '8', '16', '32', '64'];

export function validateFraction(value?: string) {
  if (!value) return true;

  const hasFraction = value.search('/') > 0;
  const hasFractionWithNumber = value.search('-') > 0;

  if (!hasFraction && !hasFractionWithNumber) return true;

  const [, denominator] = value.split('/');

  return allowedFractionDenominator.includes(denominator);
}
