import { DatePicker } from 'common/components/Form/DatePicker/DatePicker';

import { InputWrapper } from './breakGroundDate.styles';

type Props = {
  handleDateChange: (date: Date | null) => void;
  selectedDate: Date | null;
};

export const BreakGroundDateInitial = ({
  handleDateChange,
  selectedDate,
}: Props) => (
  <InputWrapper>
    <DatePicker
      label="Initial Sales Target Date"
      onChange={handleDateChange}
      value={selectedDate}
    />
  </InputWrapper>
);
