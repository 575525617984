import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AuthReferral } from 'common/apollo/enums/AuthReferral';

import { useGetProject } from 'common/hooks/Project/useGetProject';
import { useFetchLoginLink } from 'common/hooks/LoginAsUser/loginAsUser.hooks';

import { QueryParams } from 'ProjectProfile/common/types/queryParams';

export const useInspirationLink = () => {
  const [inspirationLink, setInspirationLink] = useState('');
  const [directInspirationLink, setDirectInspirationLink] = useState('');

  const { projectId } = useParams<QueryParams>();
  const { userId } = useGetProject(projectId);
  const { fetchLoginLink } = useFetchLoginLink();

  useEffect(() => {
    if (!userId) return;

    (async () => {
      const urlReferralAdmin = await fetchLoginLink(
        userId,
        AuthReferral.ADMIN,
        projectId,
      );
      setInspirationLink(`${urlReferralAdmin}/inspiration`);

      const urlReferralDirect = await fetchLoginLink(
        userId,
        AuthReferral.USER,
        projectId,
      );
      setDirectInspirationLink(`${urlReferralDirect}/inspiration`);
    })();
  }, [fetchLoginLink, userId, projectId]);

  return {
    directInspirationLink,
    inspirationLink,
  };
};
