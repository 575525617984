import styled from '@emotion/styled';

import { Button } from 'common/components/Button/Button';

type Props = {
  disabled?: boolean;
};

type HeaderProps = {
  zIndex: string;
};

export const ActionsWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const BulkEditButton = styled(Button)<Props>`
  margin-right: 24px;

  svg {
    color: ${({ disabled, theme }) => disabled && theme.color.gray11};
    height: 18px;
    width: 18px;
  }
`;

export const Header = styled.header<HeaderProps>`
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: space-between;
  padding: 16px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${({ zIndex }) => `${zIndex}`};
`;

export const SearchWrapper = styled.div`
  display: flex;
  margin: 0 24px;
  width: 420px;
`;

export const SelectAllCheckboxWrapper = styled.div`
  margin-left: 15px;

  .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 500;
  }
`;
