import styled from '@emotion/styled';

import { LinearProgress } from '@material-ui/core';

import { css } from '@emotion/react';

import { Collapse } from 'common/components/Collapse/Collapse';
import { GridTableRow } from 'common/components/Tables/GridTable/GridTableRow';
import { GridTableCell } from 'common/components/Tables/GridTable/GridTableCell';
import { CircleIcon } from 'common/components/Icons/Icons';

type ProgressBarProps = {
  value: number;
};

type ShipmentEtaProps = {
  delivered: boolean;
};

type CellProps = {
  isSticky?: boolean;
  zIndex?: string;
};

export const ItemCollapse = styled(Collapse)`
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.color.gray5};
  border-radius: 4px;

  &:hover,
  &:focus {
    border: 1px solid ${({ theme }) => theme.color.gray7};
  }
`;

export const Row = styled(GridTableRow)`
  align-items: center;
  border: none;
`;

export const Cell = styled(GridTableCell)<CellProps>`
  color: ${({ theme }) => theme.color.gray12};
  font: 400 14px/20px ${({ theme }) => theme.font.inter};
  background-color: ${({ theme }) => theme.color.gray1};
  z-index: ${({ isSticky, zIndex }) => (isSticky && zIndex ? `${zIndex}` : '')};

  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      left: 0;

      &:nth-of-type(1) {
        left: 0;
      }

      &:nth-of-type(2) {
        left: 68px;
      }
    `};
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.gray1};

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.color.gray3};
  }
`;

export const ShipmentEtasWrapper = styled.div`
  color: ${({ theme }) => theme.color.gray11};
  background-color: ${({ theme }) => theme.color.gray1};
  display: flex;
  font: 400 14px/20px ${({ theme }) => theme.font.inter};
  justify-content: flex-start;
  overflow: auto;
  padding: 0 0 16px 80px;
`;

export const ShipmentEta = styled.div<ShipmentEtaProps>`
  align-items: center;
  color: ${({ delivered, theme }) =>
    delivered ? theme.color.green12 : theme.color.gray11};
  display: flex;
  font: 400 12px/16px ${({ theme }) => theme.font.inter};
  margin-right: 24px;

  &:first-of-type {
    margin-left: 16px;
  }
`;

export const ShipmentEtaStatus = styled(CircleIcon)<ShipmentEtaProps>`
  background-color: ${({ delivered, theme }) =>
    delivered ? theme.color.green12 : theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray6};
  margin-right: 8px;
`;

export const ProgressBarWrapper = styled.div<ProgressBarProps>`
  color: ${({ theme, value }) => {
    if (value === 0) {
      return theme.color.gray12;
    }
    return value < 100 ? theme.color.yellow11 : theme.color.green12;
  }};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ProgressBar = styled(LinearProgress)<ProgressBarProps>`
  border: 1px solid ${({ theme }) => theme.color.gray6};
  border-radius: 99999px;
  height: 12px;
  margin-top: 4px;
  width: 80px;
  .MuiLinearProgress-barColorPrimary {
    background-color: ${({ theme, value }) =>
      value < 100 ? theme.color.yellow9 : theme.color.green8};
  }
  &.MuiLinearProgress-colorPrimary {
    background-color: ${({ theme }) => theme.color.white};
  }
`;

ProgressBar.defaultProps = {
  variant: 'determinate',
};
