import styled from '@emotion/styled';

type Props = {
  hasOrderConflicts?: boolean;
};

export const OuterWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ hasOrderConflicts }) =>
    hasOrderConflicts ? '40px' : '0px'};
`;

export const Wrapper = styled.div<{ hasOrderConflicts?: boolean }>`
  border: 1px solid
    ${({ hasOrderConflicts, theme }) =>
      hasOrderConflicts ? theme.color.red8 : theme.color.alto};
  display: grid;
  grid-template-rows: 1fr min-content;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  row-gap: 16px;
`;

export const Title = styled.h2`
  font: 500 20px/28px ${({ theme }) => theme.font.inter};
`;

export const StatusWrapper = styled.div`
  align-self: baseline;
  display: flex;
  justify-content: flex-end;
`;

export const OrderNumber = styled.span`
  font: 400 14px/28px ${({ theme }) => theme.font.inter};
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const Section = styled.div`
  column-gap: 5px;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 2.5px));
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.color.concrete};
  border-top: 1px solid ${({ theme }) => theme.color.alto};
  display: flex;
  justify-content: center;
  padding: 16px;
`;
