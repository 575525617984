import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

import { useProjectBuildingInformationUpdate } from 'ProjectProfile/BuildingInformation/common/hooks/useProjectBuildingInformationUpdate';
import { FormValues } from 'ProjectProfile/common/types/formValues';

type Props = {
  handleCallback?: () => void;
  questionId: string;
  values: FormValues;
};

export const useBuildingAgeEdit = (data: string | null) => {
  const { handleSubmit: submit, isLoading } =
    useProjectBuildingInformationUpdate({
      identifiers: [PropertyGroupIdentifier.BUILDING_AGE],
    });

  const handleSubmit = ({ handleCallback, questionId, values }: Props) =>
    submit({
      handleSuccess: handleCallback,
      questionId,
      values,
    });

  const initialValues = {
    [PropertyGroupIdentifier.BUILDING_AGE]: data || '',
  };

  return {
    handleSubmit,
    initialValues,
    isLoading,
  };
};
