import { Checkbox } from 'common/components/Form/Checkboxes/Checkbox';
import { AttributeItem } from 'common/components/FiltersMenu/common/filterMenuItem.styles';

type Props = {
  displayName: string;
  handleChange: () => void;
  selected: boolean;
};

export const CheckboxRow = ({ displayName, handleChange, selected }: Props) => (
  <AttributeItem key={displayName} onClick={handleChange}>
    <Checkbox checked={selected} onChange={handleChange} />
    {displayName}
  </AttributeItem>
);
