import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { rgba } from 'common/utils/colors';

type WrapperProps = {
  fluid?: boolean;
};

type LabelProps = {
  disabled?: boolean;
  hasError?: boolean;
};

type ControlProps = {
  hasError?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: ${({ fluid }) => (fluid ? 'block' : 'inline-block')};
`;

export const Label = styled.label<LabelProps>`
  ${({ hasError, theme }) => css`
    color: ${hasError ? theme.color.red : theme.color.black};
    font: 500 14px/20px ${theme.font.roboto};
    pointer-events: none;
    margin-bottom: 4px;
    display: block;
  `};

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${rgba(theme.color.darkGray, 0.5)};
    `};
`;

export const Control = styled.textarea<ControlProps>`
  ${({ hasError, theme }) => css`
    background: transparent;
    border: 1px solid ${hasError ? theme.color.red : theme.color.celeste};
    border-radius: 0;
    color: ${hasError ? theme.color.red : theme.color.darkGray};
    font: 400 14px/20px ${theme.font.roboto};
    height: auto;
    letter-spacing: 0.5px;
    min-width: 240px;
    outline: 0;
    padding: 14px 16px;
    transition: all 0.125s ease;
    width: 100%;
    resize: none;

    &::placeholder {
      color: ${hasError ? theme.color.red : theme.color.darkGray};
      transition: all 0.125s ease;
    }

    &:focus {
      border-color: ${theme.color.primary};
      color: ${theme.color.black};
    }

    &:focus::placeholder {
      color: ${theme.color.black};
    }

    &:hover {
      background-color: ${rgba(theme.color.mediumGray, 0.25)};
    }

    &[disabled] {
      background-color: ${theme.color.concrete};
      border-color: ${theme.color.celeste};
      color: ${rgba(theme.color.darkGray, 0.5)};
      cursor: not-allowed;
    }

    &[disabled]::placeholder {
      color: ${rgba(theme.color.darkGray, 0.5)};
    }

    &:read-only {
      background-color: ${theme.color.concrete};
      border-color: ${theme.color.celeste};
      color: ${theme.color.black};
      cursor: default;
    }
  `};
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.color.black};
    font: 400 12px/16px ${theme.font.roboto};

    margin-top: 4px;
    margin-left: 16px;

    textarea[disabled] + & {
      color: ${rgba(theme.color.darkGray, 0.5)};
    }
  `};
`;
