import styled from '@emotion/styled';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

export const FlagLabel = styled.label`
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
  color: ${({ theme }) => theme.color.gray12};
`;
