import { ShipmentDeliverTo } from 'common/apollo/enums/ShipmentDeliverTo';
import { ShipmentStatus } from 'common/apollo/enums/ShipmentStatus';
import { ShippingMethod } from 'common/apollo/enums/ShippingMethod';
import { ShipmentType } from 'common/apollo/enums/ShipmentType';

import { Variant } from 'common/components/Badge/Badge';

export const PROJECT_SHIPMENT_STATUS_DICTIONARY = {
  [ShipmentStatus.CANCELED]: 'Canceled',
  [ShipmentStatus.MISSING]: 'Missing',
  [ShipmentStatus.RECEIVED]: 'Received',
  [ShipmentStatus.REFUSED]: 'Refused',
  [ShipmentStatus.SHIPPED]: 'Shipped',
};

export const PROJECT_SHIPMENT_TYPE_DICTIONARY = {
  [ShipmentType.STANDARD]: 'Standard',
  [ShipmentType.RETURNS]: 'Returns',
};

export const PROJECT_SHIPMENT_STATUS_COLOR_VARIANT: {
  [key in ShipmentStatus]: Variant;
} = {
  [ShipmentStatus.CANCELED]: 'red',
  [ShipmentStatus.MISSING]: 'gray',
  [ShipmentStatus.RECEIVED]: 'orange',
  [ShipmentStatus.REFUSED]: 'yellow',
  [ShipmentStatus.SHIPPED]: 'blue',
};

export const PROJECT_SHIPMENT_DELIVER_TO_DICTIONARY = {
  [ShipmentDeliverTo.JOBSITE]: 'Jobsite',
  [ShipmentDeliverTo.VENDOR]: 'Vendor',
  [ShipmentDeliverTo.WH_LA_6910_TUJUNGA]: 'WH - LA - 6910 Tujunga Ave',
  [ShipmentDeliverTo.WH_NY_51_COFFEY]: 'WH - NY - 51 Coffey',
  [ShipmentDeliverTo.WH_NY_TRIPLE_CROWN]: 'WH - NY - Triple Crown',
  [ShipmentDeliverTo.WH_NY_XPDEL]: 'WH - NY - XPDEL',
};

export const PROJECT_SHIPMENT_DELIVER_TO_OPTIONS = [
  {
    label: PROJECT_SHIPMENT_DELIVER_TO_DICTIONARY[ShipmentDeliverTo.JOBSITE],
    value: ShipmentDeliverTo.JOBSITE,
  },
  {
    label: PROJECT_SHIPMENT_DELIVER_TO_DICTIONARY[ShipmentDeliverTo.VENDOR],
    value: ShipmentDeliverTo.VENDOR,
  },
  {
    label:
      PROJECT_SHIPMENT_DELIVER_TO_DICTIONARY[ShipmentDeliverTo.WH_NY_51_COFFEY],
    value: ShipmentDeliverTo.WH_NY_51_COFFEY,
  },
  {
    label:
      PROJECT_SHIPMENT_DELIVER_TO_DICTIONARY[
        ShipmentDeliverTo.WH_NY_TRIPLE_CROWN
      ],
    value: ShipmentDeliverTo.WH_NY_TRIPLE_CROWN,
  },
  {
    label:
      PROJECT_SHIPMENT_DELIVER_TO_DICTIONARY[ShipmentDeliverTo.WH_NY_XPDEL],
    value: ShipmentDeliverTo.WH_NY_XPDEL,
  },
  {
    label:
      PROJECT_SHIPMENT_DELIVER_TO_DICTIONARY[
        ShipmentDeliverTo.WH_LA_6910_TUJUNGA
      ],
    value: ShipmentDeliverTo.WH_LA_6910_TUJUNGA,
  },
];

export const PROJECT_SHIPMENT_STATUS_OPTIONS = [
  {
    label: PROJECT_SHIPMENT_STATUS_DICTIONARY[ShipmentStatus.RECEIVED],
    value: ShipmentStatus.RECEIVED,
  },
  {
    label: PROJECT_SHIPMENT_STATUS_DICTIONARY[ShipmentStatus.CANCELED],
    value: ShipmentStatus.CANCELED,
  },
  {
    label: PROJECT_SHIPMENT_STATUS_DICTIONARY[ShipmentStatus.MISSING],
    value: ShipmentStatus.MISSING,
  },
  {
    label: PROJECT_SHIPMENT_STATUS_DICTIONARY[ShipmentStatus.SHIPPED],
    value: ShipmentStatus.SHIPPED,
  },
  {
    label: PROJECT_SHIPMENT_STATUS_DICTIONARY[ShipmentStatus.REFUSED],
    value: ShipmentStatus.REFUSED,
  },
];

export const PROJECT_SHIPMENT_TYPE_OPTIONS = [
  {
    label: PROJECT_SHIPMENT_TYPE_DICTIONARY[ShipmentType.STANDARD],
    value: ShipmentType.STANDARD,
  },
  {
    label: PROJECT_SHIPMENT_TYPE_DICTIONARY[ShipmentType.RETURNS],
    value: ShipmentType.RETURNS,
  },
];

export const PROJECT_SHIPMENT_SHIPPING_METHOD_DICTIONARY = {
  [ShippingMethod.AMAZON]: 'Amazon',
  [ShippingMethod.BLOCK_DELIVERY]: 'Block',
  [ShippingMethod.FEDEX]: 'Fedex',
  [ShippingMethod.PICKUP]: 'Pickup',
  [ShippingMethod.TRUCK]: 'Truck',
  [ShippingMethod.UPS]: 'Ups',
};

export const PROJECT_SHIPMENT_SHIPPING_METHOD_OPTIONS = [
  {
    label: PROJECT_SHIPMENT_SHIPPING_METHOD_DICTIONARY[ShippingMethod.AMAZON],
    value: ShippingMethod.AMAZON,
  },
  {
    label:
      PROJECT_SHIPMENT_SHIPPING_METHOD_DICTIONARY[
        ShippingMethod.BLOCK_DELIVERY
      ],
    value: ShippingMethod.BLOCK_DELIVERY,
  },
  {
    label: PROJECT_SHIPMENT_SHIPPING_METHOD_DICTIONARY[ShippingMethod.FEDEX],
    value: ShippingMethod.FEDEX,
  },
  {
    label: PROJECT_SHIPMENT_SHIPPING_METHOD_DICTIONARY[ShippingMethod.PICKUP],
    value: ShippingMethod.PICKUP,
  },
  {
    label: PROJECT_SHIPMENT_SHIPPING_METHOD_DICTIONARY[ShippingMethod.TRUCK],
    value: ShippingMethod.TRUCK,
  },
  {
    label: PROJECT_SHIPMENT_SHIPPING_METHOD_DICTIONARY[ShippingMethod.UPS],
    value: ShippingMethod.UPS,
  },
];
