import { useLayoutEffect, useRef, useState, MutableRefObject } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import ResizeObserver from 'resize-observer-polyfill';

type Props = {
  observeParent?: boolean;
};

export const useResizeObserver = <T extends HTMLElement>({
  observeParent,
}: Props = {}): [
  ResizeObserverEntry | undefined,
  MutableRefObject<T | null>,
] => {
  const [entry, setEntry] = useState<ResizeObserverEntry>();
  const nodeRef = useRef<T | null>(null);

  useLayoutEffect(() => {
    if (!nodeRef.current || (observeParent && !nodeRef.current.parentElement)) {
      return;
    }

    const elementToObserve =
      observeParent && nodeRef.current.parentElement
        ? nodeRef.current.parentElement
        : nodeRef.current;

    const resizeObserver = new ResizeObserver(([resizeObserverEntry]) =>
      setEntry(resizeObserverEntry),
    );

    resizeObserver.observe(elementToObserve);

    return () => resizeObserver.unobserve(elementToObserve);
  }, [observeParent]);

  return [entry, nodeRef];
};

export const extractTarget = <T extends HTMLElement>(
  entry: ResizeObserverEntry | undefined,
) => {
  if (!entry)
    return { offsetHeight: 0, offsetLeft: 0, offsetTop: 0, offsetWidth: 0 };

  const { offsetHeight, offsetLeft, offsetTop, offsetWidth } =
    entry.target as T;

  return { offsetHeight, offsetLeft, offsetTop, offsetWidth };
};
