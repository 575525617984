import { useHistory } from 'react-router-dom';
import { List } from '@material-ui/core';

import { SidebarContentWrapper } from 'common/components/Sidebar/SidebarContentWrapper';

import {
  useCurrentUser,
  useRemoveAuthenticationToken,
} from 'common/hooks/AuthenticationProvider/authenticationProvider.hooks';

import { PUBLIC_PATHS } from 'common/routes/routerPaths';

import { NAVIGATION_MENU_ITEMS } from './navigationMenu.consts';
import { NavigationMenuSection } from './NavigationMenuSection';

import {
  NavigationContent,
  LogOutButton,
  CurrentUserEmail,
} from './NavigationMenu.styles';

export const NavigationMenu = () => {
  const history = useHistory();
  const removeAuthToken = useRemoveAuthenticationToken();
  const currentUser = useCurrentUser();

  const handleLogOut = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/admin/auth/logout`, {
      credentials: 'include',
      method: 'POST',
    });

    removeAuthToken();
    history.push(PUBLIC_PATHS.LOG_IN);
  };

  return (
    <SidebarContentWrapper>
      <NavigationContent>
        <List>
          {NAVIGATION_MENU_ITEMS.map(({ links, title }) => (
            <NavigationMenuSection key={title} links={links} title={title} />
          ))}
        </List>

        <CurrentUserEmail>{currentUser?.email}</CurrentUserEmail>
        <LogOutButton onClick={handleLogOut}>Log Out</LogOutButton>
      </NavigationContent>
    </SidebarContentWrapper>
  );
};
