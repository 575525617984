import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';

import { MATERIALS_LONGEST_LEAD_TIME_CONFIRM_DIALOG } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeConfirm/materialsLongestLeadTimeConfirm.consts';

export const useMaterialsLongestLeadTimeConfirm = () => {
  const setModalOpen = useSetModalOpen();

  const handleMaterialsLongestLeadTimeConfirmClick = () => {
    setModalOpen(MATERIALS_LONGEST_LEAD_TIME_CONFIRM_DIALOG, true);
  };

  return {
    handleMaterialsLongestLeadTimeConfirmClick,
  };
};
