export const WarehouseColumnWidthMap = {
  ACTION_MENU: 'minmax(80px, 1fr)',
  COLOR: 'minmax(100px, 1fr)',
  EXPAND: 'minmax(80px, 1fr)',
  FINISH: 'minmax(100px, 1fr)',
  PRODUCT: 'minmax(392px, 1fr)',
  ROOM: 'minmax(120px, 1fr)',
  SHIPMENT_TYPE: 'minmax(136px, 1fr)',
  SIZE: 'minmax(100px, 1fr)',
  SKU_ID: 'minmax(128px, 1fr)',
  UNITS_RECEIVED: 'minmax(132px, 1fr)',
  UNITS_SHIPPED: 'minmax(132px, 1fr)',
};

export const BatchColumnWidthMap = {
  ACTION_MENU: '64px',
  BATCH: '84px',
  CHECKBOX: '64px',
  CONFIRMED_BY: '164px',
  DELETE: '80px',
  ETA: '164px',
  INVENTORY_UNITS: '164px',
  PALLET: '164px',
  RECEIVED_ON: '164px',
  SHIPPED_ON: '164px',
  UNITS: '84px',
};

export const WarehouseZIndexMap = {
  batchHeaderCell: '2',
  batchRowCell: '2',
  orderItemRow: '1',
  warehouseHeaderHeader: '4',
  warehouseTableHeaderCell: '5',
  warehouseTableHeaderHeader: '3',
};
