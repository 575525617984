import { useMutation } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';

import { useModal } from 'common/components/Modal/Modal';

import { DeleteProjectProcurementOrderItemResponse } from 'Project/Procurement/common/apollo/responses/deleteProjectProcurementOrderItemResponse';
import { DeleteProjectProcurementOrderItemRequest } from 'Project/Procurement/common/apollo/requests/deleteProjectProcurementOrderItemRequest';

import { DELETE_PROJECT_ORDER_ITEM } from 'Project/Procurement/EditOrder/OrderMaterials/DeleteMaterial/deleteMaterial.graphql';

export const useDeleteMaterial = (orderItemId?: string) => {
  const { onError } = useAlert();
  const { onClose: handleClose } = useModal();

  const [deleteMaterial] = useMutation<
    DeleteProjectProcurementOrderItemResponse,
    DeleteProjectProcurementOrderItemRequest
  >(DELETE_PROJECT_ORDER_ITEM, {
    onCompleted: () => handleClose(),
    onError: (error) => onError(error.message),
  });

  const handleDeleteMaterial = () =>
    deleteMaterial({
      variables: {
        input: {
          orderItemId: orderItemId || '',
        },
      },
    });

  return {
    handleClose,
    handleDeleteMaterial,
  };
};
