import { ComponentType } from 'react';

import { Question } from 'common/models/question';

import { PresentationType } from 'common/apollo/enums/PresentationType';

import { Checkbox } from 'common/shared/Question/Checkbox/Checkbox';
import { Confirm } from 'common/shared/Question/Confirm/Confirm';
import { Dropdown } from 'common/shared/Question/Dropdown/Dropdown';
import { DualDropdown } from 'common/shared/Question/DualDropdown/DualDropdown';
import { Number } from 'common/shared/Question/Number/Number';
import { Radio } from 'common/shared/Question/Radio/Radio';
import { Text } from 'common/shared/Question/Text/Text';
import { Textarea } from 'common/shared/Question/Textarea/Textarea';

import {
  QuestionsSection,
  QuestionProps,
} from 'common/shared/Question/question.types';
import { Rank } from 'common/shared/Question/Rank/Rank';

export const openQuestionTypes = [
  PresentationType.NUMBER,
  PresentationType.TEXT,
  PresentationType.TEXTAREA,
];

export const questionsMap: {
  [key in PresentationType]: ComponentType<QuestionProps> | null;
} = {
  [PresentationType.DROPDOWN]: Dropdown,
  [PresentationType.DUAL_DROPDOWN]: DualDropdown,
  [PresentationType.CHECKBOX]: Checkbox,
  [PresentationType.RADIO]: Radio,
  [PresentationType.CONFIRM]: Confirm,
  [PresentationType.THUMBNAIL_SMALL]: Radio,
  [PresentationType.TEXTAREA]: Textarea,
  [PresentationType.TEXT]: Text,
  [PresentationType.NUMBER]: Number,
  [PresentationType.RANK]: Rank,
  [PresentationType.ADDRESS_AUTOCOMPLETE]: null,
  [PresentationType.PHONE_NUMBER]: null,
  [PresentationType.HDYH]: null,
  [PresentationType.REFERRAL_CODE]: null,
  [PresentationType.SECTION_NAME]: null,
  [PresentationType.STARTING_POINTS]: null,
  [PresentationType.THUMBNAIL_LARGE]: null,
  [PresentationType.ZIP]: null,
  [PresentationType.EMAIL]: null,
  [PresentationType.NAME]: null,
};

export const generateQuestionSections = (
  questions: Question[] = [],
  presentationTypes?: PresentationType[],
): QuestionsSection[] => {
  let currentSectionId = '';

  const sections = questions.reduce((acc, question) => {
    const isSectionNameQuestion =
      question.presentationType === PresentationType.SECTION_NAME;

    if (
      presentationTypes &&
      !presentationTypes.includes(question.presentationType)
    ) {
      return acc;
    }

    if (isSectionNameQuestion) currentSectionId = question.id;

    if (!currentSectionId) return acc;

    if (acc.has(currentSectionId)) {
      acc.get(currentSectionId)?.questions.push(question);

      return acc;
    }

    return acc.set(currentSectionId, {
      label: question.label,
      questions: [],
      sectionId: question.id,
    });
  }, new Map<string, QuestionsSection>());

  return Array.from(sections.values());
};
