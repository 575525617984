import { ProjectMaterial } from 'common/apollo/models/projectMaterial';

import { getSkuSizeWithFraction } from 'common/utils/skuSize';

export const normalizeProjectMaterialsData = (materials: ProjectMaterial[]) =>
  materials.map(
    ({
      amount: totalUnits,
      boxCount,
      category: { id: subcategoryId, name: subcategory },
      id,
      name,
      sku: {
        color: { description: color },
        depth,
        finish: { description: finish },
        height,
        length,
        pattern: { description: pattern },
        product: { brand },
        sku: skuId,
        thickness,
        unit,
        vendor: { vendorSku: vendorSkuId },
        width,
      },
    }) => ({
      boxCount,
      brandLineName: brand?.line?.name || '',
      brandName: brand?.name || '',
      color,
      finish,
      id,
      name,
      pattern,
      size: getSkuSizeWithFraction({ depth, height, length, thickness, width }),
      skuId,
      subcategory,
      subcategoryId,
      totalUnits,
      unitType: unit?.type,
      unitsReceived: 0,
      unitsShipped: 0,
      vendorSkuId,
    }),
  );

export const calculateExpectedMaterialsCost = (
  materials: ProjectMaterial[] = [],
) =>
  materials.reduce((acc, { boxCount, pricingEngineSku, sku }) => {
    const unitCost = pricingEngineSku
      ? pricingEngineSku.unitCost
      : sku.vendor.unitCost;

    const total = Math.floor(boxCount * unitCost);

    return acc + total;
  }, 0);
