export enum ProjectMaterialStatus {
  COMPLETED = 'COMPLETED',
  ORDERED = 'ORDERED',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  REMOVED = 'REMOVED',
  SHIPPED = 'SHIPPED',
  TBD = 'TBD',
  TO_ORDER = 'TO_ORDER',
  VOID = 'VOID',
}
