import { PropsWithoutRef, ReactNode } from 'react';

import { SidebarProvider } from './SidebarProvider';

import { VARIANT_TYPE } from './sidebar.types';

import { SubDrawer, DrawerType } from './Sidebar.styles';

type Props = PropsWithoutRef<DrawerType> & {
  children: ReactNode;
  onOpenToggle?: () => void;
};

export const SubSidebar = ({
  anchor,
  children,
  onOpenToggle,
  open,
  variant = VARIANT_TYPE.permanent,
  ...rest
}: Props) => (
  <SubDrawer anchor={anchor} open={open} variant={variant} {...rest}>
    <SidebarProvider
      anchor={anchor}
      onOpenToggle={onOpenToggle}
      open={open}
      variant={variant}
    >
      {children}
    </SidebarProvider>
  </SubDrawer>
);
