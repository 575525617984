import { gql } from '@apollo/client';

export const TARGET_DATE_SET = gql`
  mutation targetDateSet($input: KeyDateSetInput!) {
    keyDateSet(input: $input) {
      id
      value
    }
  }
`;
