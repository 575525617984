import { css } from '@emotion/react';

import { THEME } from 'common/consts/theme';

export default css`
  body,
  html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: ${THEME.font.roboto}, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    height: 100%;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    outline-width: 0;
    appearance: none;
  }

  input:focus,
  select:focus,
  button:focus,
  textarea:focus {
    outline-width: 1px;
  }

  section:focus {
    outline: none;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  .ReactModal__Overlay {
    z-index: 999999 !important;
  }
`;
