import { Formik } from 'formik';

import { FormTextField } from 'common/components/Form/TextField/FormTextField';
import { CloseButton } from 'common/components/Form/CloseButton/CloseButton';
import { Button } from 'common/components/Button/Button';
import { FormSelect } from 'common/components/Form/Select/FormSelect';

import { SelectType } from 'common/types/select';

import { PROJECT_MATERIAL_STATUS_UPDATE_OPTIONS } from 'Project/Procurement/common/consts/material.consts';

import { Material } from 'Project/Procurement/Materials/materials.types';

import { useUpdateMaterial } from 'Project/Procurement/Materials/UpdateMaterial/updateMaterial.hooks';

import {
  Content,
  Footer,
  Header,
  Wrapper,
} from 'Project/Procurement/Materials/UpdateMaterial/updateMaterial.styles';

type Props = {
  material: Material | null;
  pricingEngines: SelectType[];
};

export const UpdateMaterial = ({ material, pricingEngines }: Props) => {
  const { handleClose, handleSubmit, initialValues, postDsoEnabled } =
    useUpdateMaterial(material);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ dirty, isSubmitting, submitForm }) => (
        <Wrapper>
          <Header>
            Update Materials <CloseButton onClick={handleClose} />
          </Header>

          <Content>
            {!postDsoEnabled && (
              <FormTextField
                id="amount"
                label="Units In Order"
                name="amount"
                type="number"
              />
            )}

            {!postDsoEnabled && (
              <FormSelect
                id="pricingEngineId"
                isDisabled={!!material?.generated}
                label="Room"
                name="pricingEngineId"
                options={pricingEngines}
              />
            )}

            <FormSelect
              id="status"
              label="Status"
              name="status"
              options={PROJECT_MATERIAL_STATUS_UPDATE_OPTIONS}
            />
          </Content>

          <Footer>
            <Button aria-label="Cancel" onClick={handleClose} small>
              Cancel
            </Button>

            <Button
              aria-label="Update"
              color="secondary"
              disabled={!dirty || isSubmitting}
              onClick={submitForm}
              small
              variant="contained"
            >
              Update Materials
            </Button>
          </Footer>
        </Wrapper>
      )}
    </Formik>
  );
};
