import { BulkEditModal } from 'Project/Procurement/Warehouse/BulkEditModal/BulkEditModal';
import { useWarehouse } from 'Project/Procurement/Warehouse/warehouse.hooks';
import { WarehouseWrapper } from 'Project/Procurement/Warehouse/warehouse.styles';
import { WarehouseHeader } from 'Project/Procurement/Warehouse/WarehouseHeader/WarehouseHeader';
import { WarehouseTable } from 'Project/Procurement/Warehouse/WarehouseTable/WarehouseTable';

const Warehouse = () => {
  const {
    allFilters,
    areAllBatchesSelected,
    batchesPerItem,
    clearAllFilters,
    expandedItems,
    handleSelectFilter,
    handleToggleAllBatchesForOrderItem,
    handleToggleBatch,
    numberOfBatches,
    onCollapseAll,
    onCollapseToggle,
    onEditAllBatchesForOrderItem,
    onExpandAll,
    onShowBulkEditModal,
    onToggleSelectAllBatches,
    orderItemDeliveryBatchMap,
    selectedBatchObjects,
    selectedBatches,
    selectedFilters,
    selectedOrderItems,
    setSearchQuery,
  } = useWarehouse();

  return (
    <WarehouseWrapper>
      <WarehouseHeader
        allFilters={allFilters}
        areAllBatchesSelected={areAllBatchesSelected}
        clearAllFilters={clearAllFilters}
        handleSelectFilter={handleSelectFilter}
        numberOfBatches={numberOfBatches}
        onShowBulkEditModal={onShowBulkEditModal}
        onToggleSelectAllBatches={onToggleSelectAllBatches}
        selectedBatchCount={selectedBatches.length}
        selectedFilters={selectedFilters}
        setSearchQuery={setSearchQuery}
      />
      <WarehouseTable
        areAllBatchesSelected={areAllBatchesSelected}
        batchesPerItem={batchesPerItem}
        expandedItems={expandedItems}
        handleToggleAllBatchesForOrderItem={handleToggleAllBatchesForOrderItem}
        handleToggleBatch={handleToggleBatch}
        onCollapseAll={onCollapseAll}
        onCollapseToggle={onCollapseToggle}
        onEditAllBatchesForOrderItem={onEditAllBatchesForOrderItem}
        onExpandAll={onExpandAll}
        onToggleSelectAllBatches={onToggleSelectAllBatches}
        selectedBatches={selectedBatches}
        selectedOrderItems={selectedOrderItems}
      />
      <BulkEditModal
        batches={selectedBatchObjects}
        orderItemDeliveryBatchMap={orderItemDeliveryBatchMap}
      />
    </WarehouseWrapper>
  );
};

export default Warehouse;
