import { gql } from '@apollo/client';

export const GET_LOG_IN_AS_USER_LINK = gql`
  mutation GetLogInAsUserLink(
    $id: ID!
    $renovationPlanId: ID!
    $referral: AuthReferral!
  ) {
    loginAsUser(
      id: $id
      renovationPlanId: $renovationPlanId
      referral: $referral
    ) {
      token
      redirectPath
    }
  }
`;
