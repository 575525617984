import * as React from 'react';

import { HorizontalAlignment, VerticalAlignment } from 'common/enums/Alignment';
import { CellType } from 'common/enums/GridCell';

import { Cell } from './GridTable.styles';

export type CellProps = {
  cellType?: CellType;
  fontSize?: number;
  fontWeight?: number | string;
  hAlignment?: HorizontalAlignment;
  vAlignment?: VerticalAlignment;
  width?: string;
};

export type Props = CellProps &
  Exclude<Omit<React.HTMLProps<HTMLInputElement>, 'as'>, CellProps>;

export const GridTableCell = React.forwardRef(
  ({ children, ...rest }: Props, ref: React.Ref<HTMLDivElement>) => (
    <Cell role="cell" {...rest} ref={ref}>
      {children}
    </Cell>
  ),
);
