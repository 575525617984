import { DataProxy } from '@apollo/client';

import {
  SidebarProposalGet,
  SidebarProposalGetVariables,
} from './__generated__/pricingEngineSidebar.graphql.generated';

import { GET_SIDEBAR_PROPOSAL } from './pricingEngineSidebar.graphql';

type GetSidebarProposalProps = {
  proposalId: string;
  proxy: DataProxy;
};

type WriteSidebarProposalProps = {
  data: SidebarProposalGet | null;
  proposal: any;
  proposalId: string;
  proxy: DataProxy;
};

type PricingEngineCreateResponse = {
  id: string;
  name: string | null;
};

const readProposal = ({ proposalId, proxy }: GetSidebarProposalProps) =>
  proxy.readQuery<SidebarProposalGet, SidebarProposalGetVariables>({
    query: GET_SIDEBAR_PROPOSAL,
    variables: {
      id: proposalId,
    },
  });

const writeProposal = ({
  data,
  proposal,
  proposalId,
  proxy,
}: WriteSidebarProposalProps) =>
  proxy.writeQuery({
    data: {
      ...data,
      proposal: {
        ...proposal,
      },
    },
    query: GET_SIDEBAR_PROPOSAL,
    variables: {
      id: proposalId,
    },
  });

export const updateProposalCacheOnPricingEngineDelete = (
  proxy: DataProxy,
  pricingEngineId: string,
  proposalId: string,
) => {
  const data = readProposal({ proposalId, proxy });

  const proposal = data?.proposal;

  const pricingEngines = (proposal?.pricingEngines || []).filter(
    ({ id }) => id !== pricingEngineId,
  );

  writeProposal({
    data,
    proposal: { ...proposal, pricingEngines },
    proposalId,
    proxy,
  });
};

export const updateProposalCacheOnPricingEngineCreate = (
  proxy: DataProxy,
  proposalId: string,
  pricingEngine?: PricingEngineCreateResponse,
) => {
  if (!pricingEngine) return;

  const data = readProposal({ proposalId, proxy });

  const proposal = data?.proposal;

  const pricingEngines = [...(proposal?.pricingEngines || []), pricingEngine];

  writeProposal({
    data,
    proposal: { ...proposal, pricingEngines },
    proposalId,
    proxy,
  });
};
