import { List } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AUTH_PATHS } from 'common/routes/routerPaths';

import { useAuth } from 'common/hooks/AuthenticationProvider/authenticationProvider.hooks';

import { FeatureFlags } from 'common/enums/FeatureFlags';

import { SidebarContentListItem } from 'common/components/Sidebar/SidebarContentListItem';

import { Collapse } from 'common/components/Collapse/Collapse';
import { CollapseContent } from 'common/components/Collapse/CollapseContent';
import { RightChevronCollapseButton } from 'common/components/Collapse/CollapseButtons/RightChevronCollapseButton/RightChevronCollapseButton';
import { SCOPE_REVISION_REVISION_REDIRECT_CLICKED } from 'common/consts/segmentEvents';
import { sendEvent } from 'common/utils/segment';

type Props = {
  renovationPlanId: string;
  userId: string;
};

export const ProfileLinks = ({ renovationPlanId, userId }: Props) => {
  const { currentUser } = useAuth();

  const flags = useFlags();
  const postDsoEnabled = flags[FeatureFlags.POST_DSO];

  const handleScopeRevisionClick = () => {
    sendEvent(SCOPE_REVISION_REVISION_REDIRECT_CLICKED, {
      accessPoint: 'Navigation Menu',
      adminUserId: currentUser?.id,
      renovationPlanId,
    });
  };

  return (
    <Collapse isOpen>
      <RightChevronCollapseButton title="Menu" />
      <CollapseContent>
        <List>
          <SidebarContentListItem
            exact
            label="Project Profile"
            to={AUTH_PATHS.getProjectProfileInformationPath(renovationPlanId)}
          />
          <SidebarContentListItem
            exact
            label="Proposals"
            to={AUTH_PATHS.getProposalsPath({ renovationPlanId, userId })}
          />
          {postDsoEnabled && (
            <SidebarContentListItem
              exact
              label="Scope & Revision"
              onClick={handleScopeRevisionClick}
              to={AUTH_PATHS.getProjectScopeRevisionLaborMaterialsPath(
                renovationPlanId,
              )}
            />
          )}
          <SidebarContentListItem
            exact
            label="Team"
            to={AUTH_PATHS.getProjectTeamMembersPath(renovationPlanId)}
          />
          <SidebarContentListItem
            exact
            label="Project Statement"
            to={AUTH_PATHS.getProjectWorkOrderPath(renovationPlanId)}
          />
          <SidebarContentListItem
            exact
            label="Design"
            to={AUTH_PATHS.getProjectDesignPath(renovationPlanId)}
          />
          <SidebarContentListItem
            exact
            label="Procurement"
            to={AUTH_PATHS.getProjectProcurementMaterialsPath(renovationPlanId)}
          />
          <SidebarContentListItem
            exact
            label="Project Performance"
            to={AUTH_PATHS.getProjectPerformancePath(renovationPlanId)}
          />
          <SidebarContentListItem
            exact
            label="Change Orders & Financials"
            to={AUTH_PATHS.getProjectChangeOrdersPath(renovationPlanId)}
          />
          <SidebarContentListItem
            exact
            label="Schedules"
            to={AUTH_PATHS.getProjectSchedulePath(renovationPlanId)}
          />
          <SidebarContentListItem
            exact
            label="Photos & Videos"
            to={AUTH_PATHS.getProjectPhotosAndVideosPath(renovationPlanId)}
          />
          <SidebarContentListItem
            exact
            label="Project Gallery"
            to={AUTH_PATHS.getProjectGalleryUrl({ renovationPlanId, userId })}
          />
          <SidebarContentListItem
            exact
            label="Contractor Documents"
            to={AUTH_PATHS.getProjectContractorDocumentsPath(renovationPlanId)}
          />
          <SidebarContentListItem
            exact
            label="Permitting & Approvals"
            to={AUTH_PATHS.getProjectBuildingAndApprovalsPath(renovationPlanId)}
          />
          <SidebarContentListItem
            exact
            label="Payment Schedule"
            to={AUTH_PATHS.getPaymentSchedulePath({ renovationPlanId, userId })}
          />
          <SidebarContentListItem
            label="Payout Schedule"
            to={AUTH_PATHS.getPayoutScheduleEntryPath({
              renovationPlanId,
              userId,
            })}
          />
        </List>
      </CollapseContent>
    </Collapse>
  );
};
