import LinkIcon from '@material-ui/icons/Link';

import { useToggle } from 'common/hooks/useToggle';
import { ProjectPlanRoomLinks } from 'common/apollo/models/projectPlanRoomLinks';

import { EditButton } from 'ProjectProfile/common/components/EditButton/EditButton';
import { LinkContainer } from 'ProjectProfile/common/components/Link/LinkContainer/LinkContainer';
import { LinkItem } from 'ProjectProfile/common/components/Link/LinkItem/LinkItem';
import {
  OutlinedLink,
  OutlinedNoLink,
} from 'ProjectProfile/common/components/Link/Link';

import {
  Header,
  Title,
  Wrapper,
} from 'ProjectProfile/PreDesign/Links/links.styles';

import { CanvasLinksEdit } from 'ProjectProfile/PreDesign/Links/Canvas/CanvasLinksEdit';

type Props = {
  projectPlanRoomsLinks: ProjectPlanRoomLinks[];
};

export const CanvasLinks = ({ projectPlanRoomsLinks }: Props) => {
  const { isOn: isOpen, toggleOff: close, toggleOn: open } = useToggle();

  return (
    <Wrapper>
      <Header size="md">
        <Title>Canvas Links</Title>
        <EditButton
          onClick={open}
          segmentEventProps={{ name: 'Canvas Links' }}
        />
      </Header>

      <LinkContainer>
        {projectPlanRoomsLinks.length ? (
          projectPlanRoomsLinks.map(({ canvasLinks }) =>
            canvasLinks.map(({ name, url }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <LinkItem key={index}>
                <OutlinedLink color="primary" href={url} target="_blank">
                  <LinkIcon /> {name}
                </OutlinedLink>
              </LinkItem>
            )),
          )
        ) : (
          <LinkItem>
            <OutlinedNoLink>No Canvas link</OutlinedNoLink>
          </LinkItem>
        )}
      </LinkContainer>

      {isOpen && <CanvasLinksEdit close={close} />}
    </Wrapper>
  );
};
