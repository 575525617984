import { useMutation } from '@apollo/client';

import { DeliveryBatch } from 'common/apollo/models/deliveryBatch';
import { UpdateProjectProcurementDeliveryBatchesRequest } from 'common/apollo/requests/updateProjectProcurementDeliveryBatchesRequest';
import { UpdateProjectProcurementDeliveryBatchesResponse } from 'common/apollo/responses/updateProjectProcurementDeliveryBatchesResponse';

import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';

import { UPDATE_PROJECT_PROCUREMENT_DELIVERY_BATCHES } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/graphql/updateBatches.graphql';
import { BULK_EDIT_MODAL } from 'Project/Procurement/Warehouse/BulkEditModal/bulkEditModal.consts';
import { normalizeProcurementDeliveryBatchesData } from 'Project/Procurement/Warehouse/warehouse.utils';

export const useBulkEditModal = (batches: DeliveryBatch[]) => {
  const setDialogOpen = useSetModalOpen();

  const batchesPerItem = normalizeProcurementDeliveryBatchesData(batches, []);
  const selectionDescription = `${
    Object.keys(batchesPerItem).length
  } Materials • ${batches.length} Batches`;

  const [updateDeliveryBatches] = useMutation<
    UpdateProjectProcurementDeliveryBatchesResponse,
    UpdateProjectProcurementDeliveryBatchesRequest
  >(UPDATE_PROJECT_PROCUREMENT_DELIVERY_BATCHES);

  const handleOnSubmit = async ({
    confirmedAt,
    eta,
    pallet,
    receivedAt,
    shippedAt,
  }: {
    confirmedAt?: string | null;
    eta?: string | null;
    pallet?: string | null;
    receivedAt?: string | null;
    shippedAt?: string | null;
  }) => {
    const deliveryBatches = batches.map((batch) => ({
      confirmedAt: confirmedAt || batch.confirmedAt || null,
      eta: eta || batch.eta || null,
      id: batch.id,
      pallet: pallet || batch.pallet || null,
      receivedAt: receivedAt || batch.receivedAt || null,
      shippedAt: shippedAt || batch.shippedAt || null,
      units: batch.units,
    }));

    await updateDeliveryBatches({
      variables: {
        input: {
          deliveryBatches,
        },
      },
    });

    setDialogOpen(BULK_EDIT_MODAL, false);
  };

  const handleOnCancel = () => {
    setDialogOpen(BULK_EDIT_MODAL, false);
  };

  const initialValues = {
    confirmedAt: null,
    eta: null,
    pallet: '',
    receivedAt: null,
    shippedAt: null,
  };

  return {
    batchesPerItem,
    handleOnCancel,
    handleOnSubmit,
    initialValues,
    selectionDescription,
  };
};
