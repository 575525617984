import { useQuery } from '@apollo/client';

import { ProjectPlanChangeLog } from 'common/apollo/models/projectPlanChangeLog';

import { useAlert } from 'common/hooks/useAlert';
import { GET_PROJECT_PLAN_CHANGE_LOG } from 'ProjectProfile/common/apollo/gql/getProjectPlanChangeLog';
import { GetProjectPlanChangeLogRequest } from 'ProjectProfile/common/requests/getProjectPlanChangeLogRequest';
import { GetProjectPlanChangeLogResponse } from 'ProjectProfile/common/responses/getProjectPlanChangeLogResponse';

type Props = {
  projectId: string;
};

export const useProjectChangeLog = ({ projectId }: Props) => {
  const { onError } = useAlert();
  const { data, loading } = useQuery<
    GetProjectPlanChangeLogResponse,
    GetProjectPlanChangeLogRequest
  >(GET_PROJECT_PLAN_CHANGE_LOG, {
    onError: (error) => onError(error.message),
    variables: {
      input: {
        projectId,
      },
    },
  });

  const isLoading = !data && loading;

  const projectPlanChangeLog: ProjectPlanChangeLog[] = [];

  return {
    isLoading,
    projectPlanChangeLog: data?.projectPlanChangeLog || projectPlanChangeLog,
  };
};
