export const getOrganizationPath = (
  contractorOrganizationId = ':contractorOrganizationId',
) => `/organizations/${contractorOrganizationId}`;

export const getOrganizationDetailsPath = (
  contractorOrganizationId = ':contractorOrganizationId',
) => `/organizations/${contractorOrganizationId}/details`;

export const getOrganizationFeedbackPath = (
  contractorOrganizationId = ':contractorOrganizationId',
) => `/organizations/${contractorOrganizationId}/feedback`;

export const getOrganizationPreferencesPath = (
  contractorOrganizationId = ':contractorOrganizationId',
) => `/organizations/${contractorOrganizationId}/preferences`;

export const ORGANIZATION_PATHS = {
  getOrganizationDetailsPath,
  getOrganizationFeedbackPath,
  getOrganizationPath,
  getOrganizationPreferencesPath,
};
