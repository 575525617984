import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { mq } from 'common/utils/mediaQueries';
import { THEME } from 'common/consts/theme';

export const EmptyContent = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.gray};
  display: flex;
  font: 600 14px/18px ${({ theme }) => theme.font.inter};
  height: 85px;
  text-transform: uppercase;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-column-gap: 15px;

  ${mq.large(css`
    grid-template-columns: 85px 1fr;
    grid-column-gap: 25px;
  `)}

  > svg {
    height: 70px;
    width: 70px;

    ${mq.large(css`
      height: 85px;
      width: 85px;
    `)}
  }
`;

export const Answers = styled.div``;

export const Name = styled.div`
  font: 600 14px/18px ${({ theme }) => theme.font.inter};
  letter-spacing: 1px;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

export const noRoomAdded = css`
  path {
    fill: ${THEME.color.alto};
  }
`;
