import styled from '@emotion/styled';

export const ShipmentWrapper = styled.div`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.color.lightGray};
  column-gap: 16px;
  display: grid;
  grid-template-columns: 100px 80px 100px 80px 120px 100px 120px;
  padding-left: 16px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  row-gap: 5px;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.color.gray};
  font: 400 12px/16px ${({ theme }) => theme.font.inter};
`;

export const Value = styled.span`
  font: 400 14px/20px ${({ theme }) => theme.font.inter};
`;
