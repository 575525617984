import { DeliveryBatch } from 'common/apollo/models/deliveryBatch';

export const generateBatchListText = (
  orderItemBatches: DeliveryBatch[],
  orderItemDeliveryBatchMap: { [x: string]: DeliveryBatch[] },
) => {
  const batchListText = orderItemBatches.reduce(
    (previousValue, currentValue, index) =>
      `${previousValue}${index === 0 ? ' ' : ', '}${(
        orderItemDeliveryBatchMap[currentValue.orderItem.id].findIndex(
          (batch) => batch.id === currentValue.id,
        ) + 1
      ).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}`,
    '',
  );
  return `Batch ${batchListText}`;
};
