import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mq } from 'common/utils/mediaQueries';

import { DRAWER_WIDTH_OPEN, DRAWER_WIDTH_CLOSED } from 'common/consts/theme';

type ContentType = {
  hasMarginLeft: boolean;
  hasMarginLeftLarge: boolean;
  hasNoMargin: boolean;
};

export const Content = styled.div<ContentType>`
  height: 100%;
  transition: margin 0.3s ease-in-out;

  ${({ hasMarginLeft, hasMarginLeftLarge, hasNoMargin }) =>
    hasNoMargin
      ? '0'
      : hasMarginLeft
        ? mq.large(css`
            margin-left: ${hasMarginLeftLarge
              ? DRAWER_WIDTH_OPEN
              : DRAWER_WIDTH_CLOSED};
          `)
        : '0'}

  @media print {
    width: 100%;
    margin: 0;
  }
`;
