import { HTMLProps, ReactNode } from 'react';

import {
  Counter,
  ExtraLabel,
  HelperText,
  HelperWrapper,
  Input,
  InputWrapper,
  Label,
  LabelWrapper,
  Wrapper,
} from './textField.styles';

type TextFieldType =
  | 'text'
  | 'number'
  | 'email'
  | 'password'
  | 'phone'
  | 'textarea';

type AbstractProps = {
  endAdornment?: ReactNode;
  extraLabel?: ReactNode;
  fluid?: boolean;
  hasError?: boolean;
  helperText?: string;
  maxCharts?: number;
  showChartCounter?: boolean;
  small?: boolean;
  startAdornment?: ReactNode;
  type?: TextFieldType;
};

export type Props = AbstractProps &
  Exclude<Omit<HTMLProps<HTMLInputElement>, 'as' | 'type'>, AbstractProps>;

const DEFAULT_MAX_CHART = 240;

const toLength = (value?: string | number | readonly string[]) =>
  typeof value === 'string' ? value.length : String(value).length;

export const TextField = ({
  endAdornment,
  extraLabel,
  fluid,
  hasError,
  helperText,
  label,
  maxCharts = DEFAULT_MAX_CHART,
  showChartCounter,
  small,
  startAdornment,
  ...htmlAttrs
}: Props) => {
  const { disabled, id, type, value } = htmlAttrs;

  const hasHelper = !!helperText || !!showChartCounter;
  const hasLabel = !!label || !!extraLabel;

  const isTextarea = type === 'textarea';

  return (
    <Wrapper fluid={fluid}>
      {hasLabel && (
        <LabelWrapper>
          {label && <Label htmlFor={id}>{label}</Label>}

          {extraLabel && <ExtraLabel>{extraLabel}</ExtraLabel>}
        </LabelWrapper>
      )}

      <InputWrapper
        disabled={disabled}
        hasError={hasError}
        isTextarea={isTextarea}
        small={small}
      >
        {startAdornment}

        <Input as={isTextarea ? 'textarea' : 'input'} {...htmlAttrs} />

        {endAdornment}
      </InputWrapper>

      {hasHelper && (
        <HelperWrapper>
          {helperText && (
            <HelperText hasError={hasError}>{helperText}</HelperText>
          )}

          {showChartCounter && (
            <Counter>
              {toLength(value)}/{maxCharts}
            </Counter>
          )}
        </HelperWrapper>
      )}
    </Wrapper>
  );
};
