import { TextFieldSize } from 'common/enums/TextField';
import { configPerInput } from 'common/utils/tableEditable';
import { BatchInput } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/batchRow.types';

export const getBatchRowConfig = () => ({
  batch: configPerInput.textFieldToText({
    componentProps: {
      size: TextFieldSize.LARGE,
      type: 'text',
    },
    name: 'id',
  }),
  confirmed: configPerInput.textFieldToText({
    componentProps: {
      size: TextFieldSize.LARGE,
      type: 'text',
    },
    name: 'confirmed',
  }),
  eta: configPerInput.textFieldToText({
    componentProps: {
      size: TextFieldSize.LARGE,
      type: 'text',
    },
    name: 'eta',
  }),
  pallet: configPerInput.textFieldToText({
    componentProps: {
      size: TextFieldSize.LARGE,
      type: 'text',
    },
    name: 'pallet',
  }),
  receivedOn: configPerInput.textFieldToText({
    componentProps: {
      size: TextFieldSize.LARGE,
      type: 'text',
    },
    name: 'receivedOn',
  }),
  shippedOn: configPerInput.textFieldToText({
    componentProps: {
      size: TextFieldSize.LARGE,
      type: 'text',
    },
    name: 'shippedOn',
  }),
  units: configPerInput.textFieldToText({
    componentProps: {
      size: TextFieldSize.LARGE,
      type: 'text',
    },
    name: 'units',
  }),
});

export const batchInputMapper = (input: BatchInput) => ({
  ...input,
  confirmedAt: input.confirmedAt || null,
  eta: input.eta || null,
  receivedAt: input.receivedAt || null,
  shippedAt: input.shippedAt || null,
});
