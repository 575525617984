import { Form, Formik } from 'formik';

import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';
import { TextFieldSize } from 'common/enums/TextField';
import { Button } from 'common/components/Buttons/Button/Button';
import { FormSelect } from 'common/components/Form/Select/FormSelect';

import {
  DialogActions,
  DialogContent,
} from 'ProjectProfile/common/components/EditForm/editForm.styles';

import { FormSelectOption } from 'ProjectProfile/common/types/formSelectOption';

import { useBuildingTypeEdit } from './buildingTypeEdit.hooks';

type Props = {
  answerId: string;
  onClose: () => void;
  options: FormSelectOption[];
  questionId: string;
};

export const BuildingTypeEdit = ({
  answerId,
  onClose,
  options,
  questionId,
}: Props) => {
  const { handleSubmit, initialValues, isLoading } =
    useBuildingTypeEdit(answerId);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) =>
        handleSubmit({
          handleCallback: onClose,
          questionId,
          values,
        })
      }
    >
      <Form>
        <DialogContent>
          <FormSelect
            id={PropertyGroupIdentifier.BUILDING_TYPE}
            isDisabled={isLoading}
            name={PropertyGroupIdentifier.BUILDING_TYPE}
            options={options}
            placeholder="Building Type"
            size={TextFieldSize.EXTRA_LARGE}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={isLoading}
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Form>
    </Formik>
  );
};
