import { useMutation } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';

import { useModal } from 'common/components/Modal/Modal';

import { UpdateProjectProcurementMaterialNotesRequest } from 'Project/Procurement/common/apollo/requests/updateProjectProcurementMaterialNotesRequest';
import { UpdateProjectProcurementMaterialNotesResponse } from 'Project/Procurement/common/apollo/responses/updateProjectProcurementMaterialNotesResponse';

import { UPDATE_PROJECT_MATERIAL_NOTES } from 'Project/Procurement/Materials/UpdateMaterialNotes/updateMaterialNotes.graphql';

import { UpdateMaterialNotesFormValues } from 'Project/Procurement/Materials/UpdateMaterialNotes/updateMaterialNotes.types';

export const useUpdateMaterialNotes = (projectMaterialId?: string) => {
  const { onCompleted, onError } = useAlert();
  const { onClose: handleClose } = useModal();

  const [updateMaterial] = useMutation<
    UpdateProjectProcurementMaterialNotesResponse,
    UpdateProjectProcurementMaterialNotesRequest
  >(UPDATE_PROJECT_MATERIAL_NOTES, {
    onCompleted: () => {
      handleClose();
      onCompleted('Material successfully updated');
    },
    onError: (error) => onError(error.message),
  });

  const handleSubmit = (input: UpdateMaterialNotesFormValues) =>
    updateMaterial({
      variables: {
        input: {
          ...input,
          projectMaterialId: projectMaterialId || '',
        },
      },
    });

  return { handleClose, handleSubmit };
};
