import { useContext, useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useMatchMedia } from 'common/hooks/useMatchMedia';

import { routeHaveSubSidebar } from 'common/components/Layout/layout.utils';

import { pageEvent } from 'common/utils/segment';

import { LayoutInfoContext, LayoutActionableContext } from './LayoutProvider';

export const useLayoutState = () => useContext(LayoutInfoContext);

export const useLayoutActions = () => useContext(LayoutActionableContext);

export const useLayout = () => {
  const isLargeScreen = useMatchMedia();
  const { pathname } = useLocation();

  const [isSidebarOpen, setSidebarOpen] = useState(isLargeScreen);

  const [isSidebarPresent, setSidebarPresent] = useState(true);
  const [isSubSidebarPresent, setSubSidebarPresent] = useState(
    routeHaveSubSidebar(pathname),
  );

  useEffect(() => {
    setSubSidebarPresent(routeHaveSubSidebar(pathname));

    if (isLargeScreen) return;

    setSidebarOpen(false);
  }, [isLargeScreen, pathname]);

  useEffect(() => {
    pageEvent();
  }, [pathname]);

  return {
    isSidebarOpen,
    isSidebarPresent,
    isSubSidebarPresent,
    pathname: pathname || '',
    setSidebarOpen,
    setSidebarPresent,
    setSubSidebarPresent,
  };
};
