import { UserDetailsGet_user } from 'common/apollo/queries/__generated__/UserDetailsGet';

import { CHECKOUT_URL, STRIPE_INVOICES_URL } from 'common/consts/urls';
import { PaymentScheduleTermInvoice } from 'common/apollo/models/paymentScheduleTermInvoice';

export const resolvers = {
  PaymentScheduleTermInvoice: {
    oldCheckoutUrl: ({ stripeInvoiceId }: PaymentScheduleTermInvoice) =>
      `${CHECKOUT_URL}${stripeInvoiceId}`,
    stripeUrl: ({ stripeInvoiceId }: PaymentScheduleTermInvoice) =>
      `${STRIPE_INVOICES_URL}${stripeInvoiceId}`,
  },
  User: {
    currentProject: (user: UserDetailsGet_user) => user?.projects?.[0] || null,
    currentRenovationPlan: (user: UserDetailsGet_user) =>
      user?.renovationPlans?.[0] || null,
    hasRenovationPlan: (user: UserDetailsGet_user) =>
      !!user?.renovationPlans?.length,
  },
};
