import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Props } from './Collapse';

export const CollapseWrapper = styled.div<Props>`
  ${({ hasBottomBorder, lightMode, theme }) =>
    hasBottomBorder &&
    css`
      border-style: solid;
      border-color: ${lightMode ? theme.color.alto : theme.color.silverChalice};
      border-width: 0;
    `};

  ${({ hasBottomBorder, isOpen }) =>
    !isOpen &&
    hasBottomBorder &&
    css`
      border-bottom-width: 1px;
    `};

  @media print {
    border-width: 0;

    .MuiCollapse-wrapper {
      display: block;
    }

    .MuiCollapse-container {
      height: 100% !important;
      visibility: visible;
      overflow: visible;
    }
  }
`;
