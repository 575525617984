import { ChangeEvent } from 'react';

import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { MenuItem } from '@material-ui/core';

import { Link } from 'react-router-dom';

import { ProjectMaterialStatus } from 'common/apollo/enums/ProjectMaterialStatus';

import { SkuStockStatus } from 'common/shared/SkuStockStatus/SkuStockStatus';

import { useToggle } from 'common/hooks/useToggle';

import { Checkbox } from 'common/components/Checkbox/Checkbox';

import { Button } from 'common/components/Button/Button';
import { LinkExternal } from 'common/components/Navigation/Link/LinkExternal';
import { ContextMenu } from 'common/components/Buttons/ContextMenu/ContextMenu';
import { Badge } from 'common/components/Badge/Badge';

import {
  PROJECT_MATERIAL_STATUS_COLOR_VARIANT,
  PROJECT_MATERIAL_STATUS_DICTIONARY,
} from 'Project/Procurement/common/consts/material.consts';

import { Units } from 'Project/Procurement/common/components/Units/Units';

import { Material } from 'Project/Procurement/Materials/materials.types';

import {
  ExpandableTableCell,
  TableCell,
  TableRow,
  IconButton,
  SizeWrapper,
  linkStyles,
} from 'Project/Procurement/Materials/MaterialTableRow/materialTableRow.styles';

import { Shipment } from 'Project/Procurement/Materials/MaterialTableRow/Shipment/Shipment';
import { RevisionActionItem } from 'common/apollo/models/revisionActionItem';
import { AUTH_PATHS } from 'common/routes/routerPaths';
import { OrderConflictWarning } from 'Project/Procurement/common/components/OrderConflictWarning/OrderConflictWarning';

type Props = {
  hasWriteAccess: boolean;
  isChecked: boolean;
  material: Material;
  onChecked: (event: ChangeEvent<HTMLInputElement>) => void;
  onOpenMaterialNotesUpdateModal: (id: string) => void;
  onOpenMaterialStockStatusUpdateModal: (id: string) => void;
  onOpenMaterialUpdateModal: (id: string) => void;
  postDsoEnabled: boolean;
  projectId: string;
};

export const MaterialTableRow = ({
  hasWriteAccess,
  isChecked,
  material: {
    amount,
    backInStockDate,
    boxCount,
    categoryRoomType,
    color,
    finish,
    generated,
    id,
    leadTimeUpdatedAt,
    maxLeadTimeWeeks,
    minLeadTimeWeeks,
    name,
    notes,
    orderAmount,
    orderBoxCount,
    pattern,
    revisionActionItem,
    roomName,
    shipments,
    size,
    skuId,
    status,
    stockStatus,
    subcategory,
    unitType,
    vendorLink,
    vendorName,
    vendorSkuId,
  },
  onChecked,
  onOpenMaterialNotesUpdateModal,
  onOpenMaterialStockStatusUpdateModal,
  onOpenMaterialUpdateModal,
  postDsoEnabled,
  projectId,
}: Props) => {
  const { isOn: isOpen, toggle } = useToggle(false);

  const hasShipments = !!shipments.length;

  const isRemoved = status === ProjectMaterialStatus.REMOVED;

  const addedColumn = (gen: boolean, revActItem: RevisionActionItem) => {
    if (postDsoEnabled && revActItem && revActItem.id && revActItem.revision) {
      return (
        <TableCell>
          <Link
            css={linkStyles}
            to={AUTH_PATHS.getProjectScopeRevisionDetails(
              projectId,
              revActItem.revision.id,
            )}
          >
            View Revision
          </Link>
        </TableCell>
      );
    }
    return <TableCell>{gen ? 'Automatically' : 'Manually'}</TableCell>;
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Checkbox checked={isChecked} id={id} onChange={onChecked} />
        </TableCell>
        <TableCell>
          {hasShipments && (
            <IconButton onClick={toggle}>
              {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          <Badge variant={PROJECT_MATERIAL_STATUS_COLOR_VARIANT[status]}>
            {PROJECT_MATERIAL_STATUS_DICTIONARY[status]}
          </Badge>
          {postDsoEnabled && revisionActionItem && revisionActionItem.id && (
            <OrderConflictWarning />
          )}
        </TableCell>
        {addedColumn(generated, revisionActionItem)}
        <TableCell>{roomName}</TableCell>
        <TableCell>{subcategory}</TableCell>
        <TableCell>
          <LinkExternal href={vendorLink} rel="noreferrer" target="_blank">
            {name}
          </LinkExternal>
        </TableCell>
        <TableCell>{skuId}</TableCell>
        <TableCell>{color}</TableCell>
        <TableCell>{finish}</TableCell>
        <TableCell>
          <SizeWrapper>{size}</SizeWrapper>
        </TableCell>
        <TableCell>{pattern}</TableCell>
        <TableCell>{vendorName}</TableCell>
        <TableCell>{vendorSkuId}</TableCell>
        <TableCell>
          <SkuStockStatus
            backInStockDate={backInStockDate}
            hasWriteAccess={hasWriteAccess}
            leadTimeUpdatedAt={leadTimeUpdatedAt}
            maxLeadTimeWeeks={maxLeadTimeWeeks}
            minLeadTimeWeeks={minLeadTimeWeeks}
            onStatusUpdate={() => onOpenMaterialStockStatusUpdateModal(id)}
            roomType={categoryRoomType}
            stockStatus={stockStatus}
          />
        </TableCell>
        <TableCell>
          <Units amount={amount} boxCount={boxCount} unitType={unitType} />
        </TableCell>
        <TableCell>
          <Units
            amount={orderAmount}
            boxCount={orderBoxCount}
            indicator={orderAmount === amount}
            unitType={unitType}
          />
        </TableCell>
        <TableCell>
          <Button onClick={() => onOpenMaterialNotesUpdateModal(id)} small>
            {notes ? 'View' : 'Add'}
          </Button>
        </TableCell>
        <TableCell>
          {!isRemoved && (
            <ContextMenu>
              <MenuItem onClick={() => onOpenMaterialUpdateModal(id)}>
                Edit Material Details
              </MenuItem>
              {hasWriteAccess && (
                <MenuItem
                  onClick={() => onOpenMaterialStockStatusUpdateModal(id)}
                >
                  Update Stock Status
                </MenuItem>
              )}
            </ContextMenu>
          )}
        </TableCell>
      </TableRow>

      {hasShipments && (
        <TableRow>
          <ExpandableTableCell colSpan={2} />
          <ExpandableTableCell colSpan={15}>
            <Collapse in={isOpen} unmountOnExit>
              {shipments.map((shipment) => (
                <Shipment key={shipment.id} shipment={shipment} />
              ))}
            </Collapse>
          </ExpandableTableCell>
        </TableRow>
      )}
    </>
  );
};
