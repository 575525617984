import { gql } from '@apollo/client';

export const PROJECT_TYPE_UPDATE = gql`
  mutation projectTypeUpdate($input: ProjectTypeUpdateInput!) {
    projectTypeUpdate(input: $input) {
      id
      projectType
    }
  }
`;
