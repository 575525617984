import { useParams } from 'react-router-dom';

import { useGetProject } from 'common/hooks/Project/useGetProject';

export const usePostDSOExperienceMessage = () => {
  const { projectId, renovationPlanId } = useParams<{
    projectId: string;
    renovationPlanId: string;
  }>();
  const { isPostDesignContractorAssignment } = useGetProject(
    projectId || renovationPlanId || '',
  );
  return isPostDesignContractorAssignment;
};
