import { Button } from 'common/components/Button/Button';
import { CloseButton } from 'common/components/Form/CloseButton/CloseButton';
import {
  Content,
  Footer,
  Header,
  Wrapper,
} from 'common/shared/Sidebar/StatusBreakGround/ProjectSidebarStatus/ConfirmMarkProjectAsLost/confirmMarkProjectAsLost.styles';
import { useConfirmProjectMarkedAsLost } from 'common/shared/Sidebar/StatusBreakGround/ProjectSidebarStatus/ConfirmMarkProjectAsLost/confirmProjectMarkedAsLost.hooks';

export const ConfirmMarkProjectAsLost = () => {
  const { handleCloseConfirmLostProjectModal, handleConfirmMarkAsLost } =
    useConfirmProjectMarkedAsLost();
  return (
    <Wrapper>
      <Header>
        Confirm Status Change
        <CloseButton onClick={handleCloseConfirmLostProjectModal} />
      </Header>

      <Content>
        <p>
          Are you sure you would like to move this project to lost? Doing this
          will delete all incomplete contractor tasks for the project.
        </p>
      </Content>

      <Footer>
        <Button onClick={handleCloseConfirmLostProjectModal} small>
          Close
        </Button>

        <Button color="secondary" onClick={handleConfirmMarkAsLost} small>
          Confirm Mark as Lost
        </Button>
      </Footer>
    </Wrapper>
  );
};
