import styled from '@emotion/styled';

import { Badge } from 'common/components/Badge/Badge';

export const BadgesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SmallBadge = styled(Badge)`
  font-size: 11px;
  letter-spacing: -0.2px;
`;
