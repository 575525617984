import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isPropValid from '@emotion/is-prop-valid';
import {
  ArrowDropDown,
  ArrowRight,
  CloudDownload,
  Delete,
  Edit,
  Visibility,
  VisibilityOff,
  CheckRounded,
  CloseRounded,
} from '@material-ui/icons';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

import { ReactComponent as ChevronDownIcon } from 'common/assets/icons/chevron-down.svg';

import { ReactComponent as ExpandIcon } from 'common/assets/icons/expand.svg';

import { skipForwardProps } from 'common/utils/styles';

type ExpandArrowProps = {
  className?: string;
  isOpen?: boolean;
};

type CircleProps = {
  bgColor?: string;
  color?: string;
  hoverBgColor?: string;
  isDisabled?: boolean;
  padding?: string;
  size?: string;
};

type VisibilityProps = {
  className?: string;
  isVisible?: boolean;
};

export const EditIcon = styled(Edit)<SvgIconProps>`
  cursor: pointer;
  transition: fill 0.2s ease;

  &:hover {
    fill: ${({ theme }) => theme.color.primaryOrange} !important;
  }
`;

export const DownloadIcon = styled(CloudDownload)<SvgIconProps>`
  cursor: pointer;
  transition: fill 0.2s ease;

  &:hover {
    fill: ${({ theme }) => theme.color.primaryOrange} !important;
  }
`;

export const DeleteIcon = styled(Delete)<SvgIconProps>`
  cursor: pointer;
  transition: fill 0.2s ease;

  &:hover {
    fill: ${({ theme }) => theme.color.red} !important;
  }
`;

const circleIconStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s;
`;

export const CircleIcon = styled.div<CircleProps>`
  ${circleIconStyles}
  width: ${({ size = '20px' }) => size};
  height: ${({ size = '20px' }) => size};

  padding: ${({ padding = '2px' }) => padding};
  background-color: ${({ theme, bgColor = theme.color.primaryOrange }) =>
    bgColor};
  color: ${({ theme, color = theme.color.white }) => color};
  font-size: 12px;
`;

export const ActionConfirmIcon = styled(CheckRounded, {
  shouldForwardProp: skipForwardProps(['isDisabled']),
})<SvgIconProps & CircleProps>`
  ${circleIconStyles}
  width: ${({ size = '30px' }) => size};
  height: ${({ size = '30px' }) => size};
  background-color: ${({
    isDisabled,
    theme,
    bgColor = theme.color.primaryOrange,
  }) => (isDisabled ? theme.color.gray : bgColor)};
  color: ${({ theme, color = theme.color.white }) => color};
  padding: ${({ padding = '4px' }) => padding};

  &:hover {
    background-color: ${({
      theme,
      hoverBgColor = theme.color.primaryOrangeDark,
    }) => hoverBgColor}

`;

export const ActionCancelIcon = styled(CloseRounded, {
  shouldForwardProp: skipForwardProps(['isDisabled']),
})<SvgIconProps & CircleProps>`
  ${circleIconStyles}
  width: ${({ size = '30px' }) => size};
  height: ${({ size = '30px' }) => size};
  background-color: ${({
    isDisabled,
    theme,
    bgColor = theme.color.primaryOrange,
  }) => (isDisabled ? theme.color.gray : bgColor)};
  color: ${({ theme, color = theme.color.white }) => color};
  padding: ${({ padding = '4px' }) => padding};

  &:hover {
    background-color: ${({
      theme,
      hoverBgColor = theme.color.primaryOrangeDark,
    }) => hoverBgColor}
`;

export const ExpandArrowIcon = ({ className, isOpen }: ExpandArrowProps) =>
  isOpen ? (
    <ArrowDropDown className={className} />
  ) : (
    <ArrowRight className={className} />
  );

export const VisibilityIcon = ({ className, isVisible }: VisibilityProps) =>
  isVisible ? (
    <Visibility className={className} />
  ) : (
    <VisibilityOff className={className} />
  );

export const ExpandedIcon = styled(ChevronDownIcon, {
  shouldForwardProp: isPropValid,
})<{ open?: boolean }>`
  transition: transform 150ms ease;

  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `};
`;

export const BulkExpandIcon = styled(ExpandIcon)`
  cursor: pointer;
  transition: fill 0.2s ease;
`;
