import { gql } from '@apollo/client';

export const UPDATE_PROJECT_PLAN_SECONDARY_CONTACT = gql`
  mutation UpdateProjectPlanSecondaryContact(
    $input: ProjectPlanSecondaryContactUpdateInput!
  ) {
    projectPlanSecondaryContactUpdate(input: $input) {
      email
      firstName
      lastName
      phoneNumber
      relationship
    }
  }
`;
