import { ContextMenu } from 'common/components/Buttons/ContextMenu/ContextMenu';
import { MenuItem } from 'common/components/Buttons/MenuButton/MenuButton.styles';

import { CellType } from 'common/enums/GridCell';

import { TABLE_HEAD_CONFIG } from 'Project/Procurement/Warehouse/WarehouseTable/WarehouseTableHeader/warehouseTableHeader.consts';
import {
  Header,
  HeaderCell,
  stickyColumnStyle,
} from 'Project/Procurement/Warehouse/WarehouseTable/WarehouseTableHeader/warehouseTableHeader.styles';
import { BulkExpandIcon } from 'common/components/Icons/Icons';

import { WarehouseZIndexMap } from '../../warehouse.consts';

type Props = {
  areAllBatchesExpanded: boolean;
  areAllBatchesSelected: boolean;
  onCollapseAll: () => void;
  onExpandAll: () => void;
  onToggleSelectAllBatches: () => void;
};

export const WarehouseTableHeader = ({
  areAllBatchesExpanded,
  areAllBatchesSelected,
  onCollapseAll,
  onExpandAll,
  onToggleSelectAllBatches,
}: Props) => (
  <Header zIndex={WarehouseZIndexMap.warehouseTableHeaderHeader}>
    {TABLE_HEAD_CONFIG.map(({ content, isSticky, width }, index) =>
      index ? (
        <HeaderCell
          cellType={CellType.HEAD}
          css={isSticky && stickyColumnStyle}
          isSticky={isSticky}
          /* eslint-disable-next-line react/no-array-index-key */
          key={index}
          width={width}
          zIndex={WarehouseZIndexMap.warehouseTableHeaderCell}
        >
          {content !== 'Expand' ? (
            content
          ) : (
            <BulkExpandIcon
              onClick={areAllBatchesExpanded ? onCollapseAll : onExpandAll}
              width="80px"
            />
          )}
        </HeaderCell>
      ) : (
        <HeaderCell
          cellType={CellType.HEAD}
          css={isSticky && stickyColumnStyle}
          isSticky={isSticky}
          /* eslint-disable-next-line react/no-array-index-key */
          key={index}
        >
          <ContextMenu>
            <MenuItem onClick={onExpandAll}>Expand All</MenuItem>
            <MenuItem onClick={onCollapseAll}>Collapse All</MenuItem>
            <MenuItem onClick={onToggleSelectAllBatches}>
              {areAllBatchesSelected
                ? 'Deselect All Batches'
                : 'Select All Batches'}
            </MenuItem>
          </ContextMenu>
        </HeaderCell>
      ),
    )}
  </Header>
);
