import {
  Header,
  Layout,
  Title,
} from 'ProjectProfile/common/components/Layout/Layout';

import { ShareButton } from 'ProjectProfile/common/components/ShareButton/ShareButton';

import { PropertyInfo } from 'ProjectProfile/BuildingInformation/PropertyInfo/PropertyInfo';

import { SiteSurvey } from './SiteSurvey/SiteSurvey';
import { BuildingInfo } from './BuildingInfo/BuildingInfo';

const BuildingInformation = () => (
  <Layout>
    <Header>
      <Title>Building Information</Title>
      <ShareButton />
    </Header>
    <BuildingInfo />
    <SiteSurvey />
    <PropertyInfo />
  </Layout>
);

export default BuildingInformation;
