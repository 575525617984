import { SidebarContentWrapper } from 'common/components/Sidebar/SidebarContentWrapper';
import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';

import { SidebarElement } from 'common/components/Sidebar/SidebarContent.styles';
import { ProfileLinks } from 'common/shared/Sidebar/Profile/ProfileLinks';
import { Profile } from 'common/shared/Sidebar/Profile/Profile';
import { ProfileEdit } from 'common/shared/Sidebar/Profile/ProfileEdit/ProfileEdit';

import { usePricingEngineSidebar } from 'PricingEngineSidebar/pricingEngineSidebar.hooks';
import { Proposal } from 'PricingEngineSidebar/Proposal/Proposal';

const PricingEngineSidebar = () => {
  const {
    initialValues,
    isEditInProgress,
    isLoading,
    loadingSubmit,
    marketCode,
    onSubmit,
    projectType,
    proposal,
    renovationPlanId,
    secondaryContactEmail,
    serviceableStatus,
    toggleIsEditInProgress,
    userData,
    userId,
  } = usePricingEngineSidebar();

  if (isLoading) return <LoadingSpinnerWithContainer />;

  if (isEditInProgress) {
    return (
      <ProfileEdit
        initialValues={initialValues}
        loadingSubmit={loadingSubmit}
        onCancel={toggleIsEditInProgress}
        onSubmit={onSubmit}
        secondaryContactEmail={secondaryContactEmail}
      />
    );
  }

  return (
    <SidebarContentWrapper>
      <>
        <Profile
          marketCode={marketCode}
          onEdit={toggleIsEditInProgress}
          projectId={renovationPlanId}
          projectType={projectType}
          serviceableStatus={serviceableStatus}
          userId={userId}
          {...userData}
        />
        <Proposal
          proposal={proposal}
          renovationPlanId={renovationPlanId}
          userId={userId}
        />
        {renovationPlanId && (
          <SidebarElement>
            <ProfileLinks renovationPlanId={renovationPlanId} userId={userId} />
          </SidebarElement>
        )}
      </>
    </SidebarContentWrapper>
  );
};

export default PricingEngineSidebar;
