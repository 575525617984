import { css } from '@emotion/react';

import styled from '@emotion/styled';

import { mq } from 'common/utils/mediaQueries';

import {
  RoleLabelColors,
  RoleLabelType,
} from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';

export const RoleLabelStyled = styled.div<{ labelType: RoleLabelType }>`
  height: 24px;
  color: ${({ labelType }) => RoleLabelColors.get(labelType)?.color};
  background-color: ${({ labelType }) =>
    RoleLabelColors.get(labelType)?.backgroundColor};
  padding: 2px 10px;
  border: solid 1px;
  border-radius: 12px;
  border-color: ${({ labelType }) =>
    RoleLabelColors.get(labelType)?.borderColor};
  white-space: nowrap;
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
  display: flex;
  align-items: center;
  margin: 0 10px 10px 0;

  ${mq.large(css`
    margin: 0 0 0 12px;
  `)}
`;

export const RoleLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
