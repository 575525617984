import { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';

import { PUBLIC_PATHS } from 'common/routes/routerPaths';

import { useAlert } from 'common/hooks/useAlert';

import { useAuth } from 'common/hooks/AuthenticationProvider/authenticationProvider.hooks';

type Props = {
  children: ReactElement;
};

export const AuthGuard = ({ children }: Props) => {
  const { onError } = useAlert();
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) return children;

  onError('Please log in to continue.');

  return <Redirect to={PUBLIC_PATHS.LOG_IN} />;
};
