import { StageIdentifier } from 'common/apollo/enums/StageIdentifier';

import { SetTargetDateModal } from 'ClientTasks/TargetDate/SetTargetDateModal/SetTargetDateModal';

import { useTargetBreakGroundDate } from 'ProjectProfile/ProjectInformation/v1/Summary/TargetDateBreakground/TargetDateBreakground.hooks';

import {
  EmptyValue,
  Text,
  Wrapper,
} from 'ProjectProfile/ProjectInformation/v1/Summary/TargetDateBreakground/TargetDateBreakground.styles';

import { SectionOutlined } from 'ProjectProfile/common/components/Section/SectionOutlined';
import { EmptyCopy } from 'ProjectProfile/projectProfile.consts';
import { RoleLabelType } from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';

export const TargetDateBreakground = () => {
  const { clientViewValue, date, dateTitle, handleCloseDialog, isSet } =
    useTargetBreakGroundDate();

  return (
    <>
      <SectionOutlined
        onEditClick={isSet ? undefined : handleCloseDialog}
        roles={[RoleLabelType.PROJECT_PLANNER]}
        title="Target Break Ground Date"
      >
        <Wrapper>
          <Text>
            {dateTitle}:{' '}
            {date || <EmptyValue>{EmptyCopy.REQUIRED_FIELD}</EmptyValue>}
          </Text>
          <Text>
            Homeowner Sees:{' '}
            {clientViewValue || (
              <EmptyValue>{EmptyCopy.REQUIRED_FIELD}</EmptyValue>
            )}
          </Text>
        </Wrapper>
      </SectionOutlined>
      <SetTargetDateModal stageIdentifier={StageIdentifier.PERMITS} />
    </>
  );
};
