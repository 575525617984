import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { GetAdminKeyDatesResponse } from 'common/apollo/responses/getAdminKeyDatesResponse';
import { GetConsumerKeyDatesResponse } from 'common/apollo/responses/getConsumerKeyDatesResponse';
import { GetAdminConsumerKeyDatesRequest } from 'common/apollo/requests/getAdminConsumerKeyDatesRequest';
import { GET_ADMIN_KEY_DATES } from 'common/apollo/queries/getAdminKeyDates.graphql';
import { GET_CONSUMER_KEY_DATES } from 'common/apollo/queries/getConsumerKeyDates.graphql';
import { KeyDateSource } from 'common/apollo/enums/KeyDateSource';
import { StageIdentifier } from 'common/apollo/enums/StageIdentifier';
import { KeyDateType } from 'common/apollo/enums/KeyDateType';

import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';
import { dateToUSFormat } from 'common/utils/date';

import { getModalId } from 'ClientTasks/TargetDate/common/targetDateModal.utils';
import { SET_TARGET_DATE_MODAL } from 'ClientTasks/TargetDate/SetTargetDateModal/setTargetDateModal.consts';

export const useTargetBreakGroundDate = () => {
  const setDialogOpen = useSetModalOpen();

  const { projectId } = useParams<{ projectId: string }>();
  const { data: adminKeyDatesData } = useQuery<
    GetAdminKeyDatesResponse,
    GetAdminConsumerKeyDatesRequest
  >(GET_ADMIN_KEY_DATES, {
    variables: {
      input: {
        renovationPlanId: projectId,
      },
    },
  });

  const { data: consumerKeyDatesData } = useQuery<
    GetConsumerKeyDatesResponse,
    GetAdminConsumerKeyDatesRequest
  >(GET_CONSUMER_KEY_DATES, {
    variables: {
      input: {
        renovationPlanId: projectId,
      },
    },
  });

  const adminKeyDates = adminKeyDatesData?.adminKeyDates || [];
  const consumerKeyDates = consumerKeyDatesData?.consumerKeyDates || [];

  const breakGroundLatest = adminKeyDates.find(
    (keyDate) =>
      keyDate.source === KeyDateSource.LATEST &&
      keyDate.type === KeyDateType.BREAK_GROUND,
  );
  const breakGroundConfirmed = adminKeyDates.find(
    (keyDate) =>
      keyDate.source === KeyDateSource.CONFIRMED &&
      keyDate.type === KeyDateType.BREAK_GROUND,
  );

  const dateTitle = breakGroundConfirmed ? 'Confirmed Date' : 'Ideal Date';
  const dateValue =
    breakGroundConfirmed?.value || breakGroundLatest?.value || '';
  const date = dateValue ? dateToUSFormat(dateValue) : '';

  const consumerKeyDate = consumerKeyDates.find(
    (keyDate) =>
      keyDate.source === KeyDateSource.LATEST &&
      keyDate.type === KeyDateType.BREAK_GROUND,
  );
  const breakGroundConfirmedClientView = adminKeyDates.find(
    (keyDate) =>
      keyDate.source === KeyDateSource.CONFIRMED &&
      keyDate.type === KeyDateType.BREAK_GROUND,
  );
  const clientViewValue =
    breakGroundConfirmedClientView?.value || consumerKeyDate?.value || '';

  const isSet = !!breakGroundLatest;

  const modalId = getModalId(SET_TARGET_DATE_MODAL, StageIdentifier.PERMITS);
  const handleCloseDialog = () => {
    setDialogOpen(modalId, true);
  };

  return {
    clientViewValue,
    date,
    dateTitle,
    handleCloseDialog,
    isSet,
  };
};
