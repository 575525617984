export enum TaskIdentifier {
  CLIENT_SIGNOFF = 'CLIENT_SIGNOFF',
  CONFIRM_APPROVAL_PACKAGE = 'CONFIRM_APPROVAL_PACKAGE',
  CONTRACTOR_INTRO = 'CONTRACTOR_INTRO',
  CONTRACTOR_PERMITS_AND_APPROVALS = 'CONTRACTOR_PERMITS_AND_APPROVALS',
  LEAVE_FEEDBACK_BLOCK = 'LEAVE_FEEDBACK_BLOCK',
  LEAVE_FEEDBACK_CONTRACTOR = 'LEAVE_FEEDBACK_CONTRACTOR',
  MATERIALS_ORDER = 'MATERIALS_ORDER',
  PAY_INVOICE_FINAL = 'PAY_INVOICE_FINAL',
  PAY_INVOICE_MATERIALS = 'PAY_INVOICE_MATERIALS',
  PAY_INVOICE_SERVICES = 'PAY_INVOICE_SERVICES',
  PREPARE_APPROVAL_PACKAGE = 'PREPARE_APPROVAL_PACKAGE',
  PREPARE_BUILD_MANUAL = 'PREPARE_BUILD_MANUAL',
  PREPARE_COI = 'PREPARE_COI',
  PREPARE_DESIGN_PROPOSAL = 'PREPARE_DESIGN_PROPOSAL',
  PROJECT_PROFILE = 'PROJECT_PROFILE',
  PROPOSAL_DESIGN = 'PROPOSAL_DESIGN',
  PROPOSAL_SALES = 'PROPOSAL_SALES',
  SAVE_INSPIRATION_IMAGES = 'SAVE_INSPIRATION_IMAGES',
  SCHEDULE_BREAK_GROUND = 'SCHEDULE_BREAK_GROUND',
  SCHEDULE_BREAK_GROUND_PREP = 'SCHEDULE_BREAK_GROUND_PREP',
  SCHEDULE_DESIGN_PLUS = 'SCHEDULE_DESIGN_PLUS',
  SCHEDULE_DESIGN_SELECT = 'SCHEDULE_DESIGN_SELECT',
  SCHEDULE_DESIGN_SELECT_2 = 'SCHEDULE_DESIGN_SELECT_2',
  SCHEDULE_ISS = 'SCHEDULE_ISS',
  SCHEDULE_KICKOFF_ESSENTIAL = 'SCHEDULE_KICKOFF_ESSENTIAL',
  SCHEDULE_KICKOFF_PLUS = 'SCHEDULE_KICKOFF_PLUS',
  SCHEDULE_KICKOFF_SELECT = 'SCHEDULE_KICKOFF_SELECT',
  SCHEDULE_PROBE = 'SCHEDULE_PROBE',
  SCHEDULE_SITE_SURVEY = 'SCHEDULE_SITE_SURVEY',
  SUBMIT_APPROVAL_PACKAGE = 'SUBMIT_APPROVAL_PACKAGE',
  SUBMIT_PERMITS = 'SUBMIT_PERMITS',
  UPLOAD_ALTERATIONS_AGREEMENT = 'UPLOAD_ALTERATIONS_AGREEMENT',
  VIEW_ACTIVITY = 'VIEW_ACTIVITY',
}
