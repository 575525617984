import { Formik } from 'formik';

import { Dialog } from 'common/components/Modals/Dialog/Dialog';
import { DialogContent } from 'common/components/Modals/Dialog/DialogContent';
import { DialogTitle } from 'common/components/Modals/Dialog/DialogTitle';

import { MaterialsLongestLeadTimeUpdateForm } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeUpdate/MaterialsLongestLeadTimeUpdateForm/MaterialsLongestLeadTimeUpdateForm';

import {
  validationSchema,
  MATERIALS_LONGEST_LEAD_TIME_UPDATE_DIALOG,
  MATERIALS_LONGEST_LEAD_TIME_UPDATE_INITIAL_VALUES,
} from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeUpdate/materialsLongestLeadTimeUpdate.consts';

export const MaterialsLongestLeadTimeUpdateModal = () => (
  <Dialog id={MATERIALS_LONGEST_LEAD_TIME_UPDATE_DIALOG} maxWidth="md">
    <DialogTitle>Shift Materials Longest Lead Time</DialogTitle>
    <DialogContent>
      <Formik
        initialValues={MATERIALS_LONGEST_LEAD_TIME_UPDATE_INITIAL_VALUES}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        <MaterialsLongestLeadTimeUpdateForm />
      </Formik>
    </DialogContent>
  </Dialog>
);
