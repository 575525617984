import styled from '@emotion/styled';
import { css } from '@emotion/react';

import MUIDialogActions from '@material-ui/core/DialogActions';

import { mq } from 'common/utils/mediaQueries';

export const DialogActions = styled(MUIDialogActions)`
  display: flex;
  flex-flow: row;
  padding: 24px;
`;

export const DialogContent = styled.div`
  padding: 66px 25px 20px;
  text-align: center;

  ${mq.large(css`
    padding: 80px 60px 40px;
  `)}
`;
