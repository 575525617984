import { Formik } from 'formik';

import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';

import { ReactComponent as EyeIcon } from 'common/assets/icons/hidden-eye.svg';

import { useProjectInformation } from 'ProjectProfile/ProjectInformation/v2/projectInformation.hooks';

import { Toolbar } from 'ProjectProfile/ProjectInformation/v2/Toolbar/Toolbar';

import {
  ContentWrapper,
  InfoWrapper,
  InfoTitle,
  Label,
  SpacesWrapper,
  Wrapper,
  SpacesOuter,
  QuestionWrapper,
  VisibilityLabel,
  Section,
  SectionDescription,
  SectionTitle,
  SectionContent,
} from 'ProjectProfile/ProjectInformation/v2/projectInformation.styles';
import { Space } from 'ProjectProfile/ProjectInformation/v2/Space/Space';
import { AddSpace } from 'ProjectProfile/ProjectInformation/v2/AddSpace/AddSpace';
import { GenerateSalesProposal } from 'ProjectProfile/ProjectInformation/v2/GenerateSalesProposal/GenerateSalesProposal';
import { projectTypeList } from 'common/consts/projectTypeList';

import { Dropdown } from 'common/shared/Question/Dropdown/Dropdown';

import { RefererField } from 'ProjectProfile/ProjectInformation/v2/RefererField/RefererField';
import { ProjectInformationQuestion } from 'ProjectProfile/ProjectInformation/v2/ProjectInformationQuestion/ProjectInformationQuestion';

type Props = {
  isInternalNotes?: boolean;
};

export const ProjectInformation = ({ isInternalNotes = false }: Props) => {
  const {
    handleSubmit,
    initialValues,
    isLoading,
    isSaving,
    latestProposal,
    projectId,
    questions,
    referralCampaigns,
    referralCampaignsOptions,
    roomTypeOptions,
    validations,
  } = useProjectInformation(isInternalNotes);

  if (isLoading) return <LoadingSpinnerWithContainer />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validations}
      validateOnChange
      validateOnMount
    >
      {({ dirty, setFieldValue }) => (
        <>
          <Toolbar isSaving={isSaving} onSave={handleSubmit} />
          <GenerateSalesProposal />

          <Wrapper isDirty={dirty}>
            {!isInternalNotes && (
              <InfoWrapper>
                <InfoTitle>* Indicates a required field.</InfoTitle>
              </InfoWrapper>
            )}

            <ContentWrapper>
              <QuestionWrapper>
                <Dropdown
                  id="projectType"
                  isDisabled
                  isRequired
                  label="Project Type*"
                  name="projectType"
                  options={projectTypeList}
                  placeholder="Project Type"
                />
              </QuestionWrapper>

              {!isInternalNotes && (
                <SpacesOuter>
                  <Label>Spaces</Label>
                  <SpacesWrapper>
                    {latestProposal?.pricingEngines.map(
                      (pricingEngine, index) => (
                        <Space
                          index={index}
                          key={pricingEngine.id}
                          projectId={projectId}
                          proposalId={latestProposal.id}
                          room={pricingEngine}
                          rooms={latestProposal?.pricingEngines}
                        />
                      ),
                    )}

                    <AddSpace
                      roomTypeOptions={roomTypeOptions}
                      rooms={latestProposal?.pricingEngines}
                    />
                  </SpacesWrapper>
                </SpacesOuter>
              )}

              {questions.map((question) => (
                <QuestionWrapper key={question.id}>
                  <ProjectInformationQuestion question={question} />
                  {!question.showContractor && (
                    <VisibilityLabel>
                      <EyeIcon />
                      <p>
                        <strong>Not</strong> visible to contractors
                      </p>
                    </VisibilityLabel>
                  )}
                  {!question.showHomeowner && (
                    <VisibilityLabel>
                      <EyeIcon />
                      <p>
                        <strong>Not</strong> visible to homeowners
                      </p>
                    </VisibilityLabel>
                  )}
                </QuestionWrapper>
              ))}

              <Section>
                <SectionTitle>Additional Information</SectionTitle>
                <SectionDescription>
                  These details are not visible to the contractor or homeowner.
                </SectionDescription>

                <SectionContent>
                  <QuestionWrapper>
                    <Dropdown
                      id="campaignId"
                      isRequired={false}
                      label="Referred By"
                      name="campaignId"
                      onChange={(id) => {
                        const campaignCodePrefix = referralCampaigns.find(
                          (campaign) => campaign.id === id,
                        )?.codePrefix;

                        if (!campaignCodePrefix) return;

                        setFieldValue('campaignCodePrefix', campaignCodePrefix);
                      }}
                      options={referralCampaignsOptions}
                      placeholder="Referral Campaign"
                    />
                  </QuestionWrapper>

                  <QuestionWrapper>
                    <RefererField />
                  </QuestionWrapper>
                </SectionContent>
              </Section>
            </ContentWrapper>
          </Wrapper>
        </>
      )}
    </Formik>
  );
};
