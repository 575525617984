import { Section } from 'ProjectProfile/common/components/Section/Section';
import { Skeleton } from 'ProjectProfile/common/components/Skeleton/Skeleton';

import { Answers, Wrapper } from './rooms.styles';

export const RoomsSkeleton = () => (
  <Section>
    <Wrapper>
      <Skeleton height={85} width={85} />
      <Answers>
        <Skeleton width={150} />
        <Skeleton width={100} />
      </Answers>
    </Wrapper>
  </Section>
);
