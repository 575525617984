import { gql } from '@apollo/client';

export const TARGET_DATE_SHIFT = gql`
  mutation targetDateSet($input: KeyDateShiftInput!) {
    keyDateShift(input: $input) {
      id
      value
    }
  }
`;

export const KEY_DATE_CHANGE_EVENT_REASONS = gql`
  query keyDateChangeEventReasons($input: KeyDateChangeEventReasonsInput!) {
    keyDateChangeEventReasons(input: $input) {
      id
      content
    }
  }
`;
