import { ReactComponent as Flask } from 'common/assets/icons/flask.svg';
import { ReactComponent as FlaskGray } from 'common/assets/icons/flask-gray.svg';

import { usePostDSOExperienceMessage } from './PostDSOExperienceMessage.hooks';

import {
  Container,
  Header,
  Message,
  Title,
} from './PostDSOExperienceMessage.styles';

export const PostDSOExperienceMessage = () => {
  const isPostDesignContractorAssignment = usePostDSOExperienceMessage();

  if (isPostDesignContractorAssignment === null) return null;

  const title = isPostDesignContractorAssignment
    ? 'Contractor Match After Design'
    : 'Contractor Match at Sales Handoff';

  const message = isPostDesignContractorAssignment
    ? 'Homeowner will be matched to a contractor after Design'
    : 'Homeowner was matched to a contractor at Sales Handoff';

  const flaskIcon = isPostDesignContractorAssignment ? (
    <Flask />
  ) : (
    <FlaskGray />
  );

  return (
    <Container
      isPostDesignContractorAssignment={isPostDesignContractorAssignment}
    >
      <Header>
        {flaskIcon}
        <Title
          isPostDesignContractorAssignment={isPostDesignContractorAssignment}
        >
          {title}
        </Title>
      </Header>
      <Message>{message}</Message>
    </Container>
  );
};
