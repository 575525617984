type Props = {
  roomsNumber: number;
};
export const ProposalRoomNumberError = ({ roomsNumber }: Props) => (
  <>
    There are {roomsNumber} rooms in this project. Please review the Build
    Timing section on the Proposal Details page to confirm whether the rooms
    will be built simultaneously or consecutively.
  </>
);
