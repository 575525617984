import { object, string, number, array } from 'yup';

import {
  POSITIVE_NUMBER,
  REQUIRED_VALIDATION,
} from 'common/consts/validationMessages';

export const validationSchema = object().shape({
  deliverTo: string().required(REQUIRED_VALIDATION),
  estimatedDeliveryDate: string().required(REQUIRED_VALIDATION),
  items: array()
    .of(
      object().shape({
        quantity: number().min(1, POSITIVE_NUMBER),
      }),
    )
    .min(1),
  type: string().required(REQUIRED_VALIDATION),
});
