import { Button } from 'common/components/Button/Button';

import { DateTimePicker } from 'common/_components/Form/DateTimePicker/DateTimePicker';
import { Checkbox } from 'common/components/Checkbox/Checkbox';
import { ReactComponent as LinkIcon } from 'common/assets/icons/link.svg';
import { ProposalQaType } from 'common/apollo/enums/ProposalQaType';

import {
  ButtonsWrapper,
  DateTimeErrorsWrapper,
  FormLink,
  Header,
  Label,
  QaTimestampExample,
  QaTimestampHeader,
  QuestionWrapper,
  RequestFormBanner,
  Wrapper,
} from './proposalQaRequest.styles';

import { useProposalQaRequest } from './proposalQaRequest.hooks';
import { PROPOSAL_QA_FORM_LINK } from './proposalQaRequest.consts';

type Props = {
  latestDesignQaCompletedAt: Date | null;
  latestProcurementQaCompletedAt: Date | null;
  proposalId: string;
};

export const ProposalQaRequest = ({
  latestDesignQaCompletedAt,
  latestProcurementQaCompletedAt,
  proposalId,
}: Props) => {
  const {
    handleQaTypeChange,
    handleRequestProposalQa,
    onChangeQaFormConfirmation,
    onModalCloseButton,
    qaFormConfirmed,
    requestDate,
    requestedAtValidationError,
    selectedQaTypes,
    validateRequestedAtTimestamp,
  } = useProposalQaRequest(
    latestDesignQaCompletedAt,
    latestProcurementQaCompletedAt,
  );

  return (
    <Wrapper>
      <Header>Request QA</Header>
      <RequestFormBanner>
        Submit your proposal for QA. If you don’t, QA won’t know to start
        reviewing your proposal.
        <FormLink href={PROPOSAL_QA_FORM_LINK} target="_blank">
          QA Submission Form <LinkIcon />
        </FormLink>
      </RequestFormBanner>
      <QuestionWrapper>
        <p>Who needs to review your proposal?*</p>
        <Label>
          <Checkbox
            checked={selectedQaTypes.includes(ProposalQaType.DESIGN)}
            id={ProposalQaType.DESIGN}
            onChange={handleQaTypeChange}
          />
          Design QA
        </Label>
        <Label>
          <Checkbox
            checked={selectedQaTypes.includes(ProposalQaType.PROCUREMENT)}
            id={ProposalQaType.PROCUREMENT}
            onChange={handleQaTypeChange}
          />
          Procurement QA
        </Label>
      </QuestionWrapper>
      <QuestionWrapper>
        <p>Did you complete the QA Submission Form?*</p>
        <Label>
          <Checkbox
            checked={qaFormConfirmed}
            onChange={onChangeQaFormConfirmation}
          />
          Yes, I properly requested QA.
        </Label>
      </QuestionWrapper>
      <QaTimestampHeader>FOR QA ONLY</QaTimestampHeader>
      <DateTimePicker
        ampm={false}
        clearable
        error={!!requestedAtValidationError}
        format="MM/dd/yyyy HH:mm"
        helperText={null}
        label="Manual timestamp"
        onChange={validateRequestedAtTimestamp}
        value={requestDate}
        variant="dialog"
      />
      <DateTimeErrorsWrapper>
        {requestedAtValidationError}
      </DateTimeErrorsWrapper>
      <QaTimestampExample>Example: "12/05/2021 15:00"</QaTimestampExample>
      <ButtonsWrapper>
        <Button
          color="primary"
          onClick={onModalCloseButton}
          small
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          disabled={
            !selectedQaTypes.length ||
            !qaFormConfirmed ||
            !!requestedAtValidationError
          }
          onClick={() => {
            handleRequestProposalQa(proposalId);
          }}
          small
          variant="contained"
        >
          Ready for QA
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
};
