import { ReactNode } from 'react';

import { useSidebarContentProps } from './SidebarProvider.hooks';

import { SidebarContentBase } from './SidebarContent.styles';

type Props = {
  children: ReactNode;
  className?: string;
};

export const SidebarContentWrapper = ({ children, className }: Props) => {
  const { isOpen } = useSidebarContentProps();

  return (
    <SidebarContentBase className={className} isOpen={isOpen}>
      {children}
    </SidebarContentBase>
  );
};
