import styled from '@emotion/styled';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.orange2};
  border: 1px solid ${({ theme }) => theme.color.orange5};
  border-radius: 50%;

  color: ${({ theme }) => theme.color.orange8};

  height: 24px;
  width: 24px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;

  .MuiSvgIcon-root {
    font-size: 18px;
  }
`;
