import { css } from '@emotion/react';

import styled from '@emotion/styled';

export const Label = styled.label`
  ${({ theme }) => theme.typography.body2};
  align-items: center;
  color: ${({ theme }) => theme.color.gray12};
  cursor: pointer;
  display: flex;
  gap: 8px;

  &:has(input[disabled]) {
    color: ${({ theme }) => theme.color.gray11};
    cursor: not-allowed;
  }
`;

export const Control = styled.input<{ hasError?: boolean; order?: number }>`
  ${({ hasError, order, theme }) => css`
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid ${hasError ? theme.color.red6 : theme.color.gray6};
    cursor: pointer;
    height: 20px;
    margin: 0;
    outline: 4px solid transparent;
    transition: all 0.125s ease;
    width: 20px;

    &:focus {
      background-color: ${theme.color.gray2};
      outline: 4px solid ${theme.color.gray2};
    }

    &:hover {
      background-color: ${theme.color.gray1};
      outline-color: ${theme.color.gray1};
    }

    &:active {
      background-color: ${theme.color.gray6};
      outline-color: ${theme.color.gray6};
    }

    &[disabled] {
      background-color: ${theme.color.gray1};
      border-color: ${theme.color.gray6};
      cursor: not-allowed;
      outline: 0;
    }

    &:checked {
      background: ${theme.color.orange8};
      border-color: ${theme.color.orange8};
    }

    ${Number.isNaN(Number(order))
      ? css`
          &:checked::before {
            content: '';
            display: block;
            border: solid ${theme.color.white};
            border-width: 0 0 1px 1px;
            height: 5px;
            transform: translate(4px, 5px) rotate(-50deg);
            width: 10px;
          }
        `
      : css`
          &:checked::before {
            content: '${Number(order) + 1}';
            display: flex;
            color: ${theme.color.white};
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
          }
        `}

    &:checked:focus {
      outline-color: ${theme.color.orange4};
    }

    &:checked:hover {
      outline-color: ${theme.color.orange2};
    }
  `};
`;

Control.defaultProps = {
  type: 'checkbox',
};
