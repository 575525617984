import { useAddSpace } from 'ProjectProfile/ProjectInformation/v2/AddSpace/addSpace.hooks';
import { ButtonWrapper } from 'ProjectProfile/ProjectInformation/v2/AddSpace/addSpace.styles';
import { AddSpaceDialog } from 'ProjectProfile/ProjectInformation/v2/AddSpaceDialog/AddSpaceDialog';
import { RoomTypeOptions } from 'ProjectProfile/ProjectInformation/v2/projectInformation.types';
import { Button } from 'common/components/Button/Button';
import { PricingEngine } from 'common/apollo/models/pricingEngine';

type Props = {
  roomTypeOptions: RoomTypeOptions[];
  rooms?: PricingEngine[];
};

export const AddSpace = ({ roomTypeOptions }: Props) => {
  const { addSpace, cancelAddSpace, onAddRoomClick } =
    useAddSpace(roomTypeOptions);

  return (
    <ButtonWrapper>
      <Button
        color="secondary"
        onClick={onAddRoomClick}
        small
        variant="outlined"
      >
        + Add a Space
      </Button>

      <AddSpaceDialog
        onAccept={addSpace}
        onClose={cancelAddSpace}
        roomTypeOptions={roomTypeOptions}
      />
    </ButtonWrapper>
  );
};
