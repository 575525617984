import { ReactNode } from 'react';

import { EditButton } from 'ProjectProfile/common/components/EditButton/EditButton';

import { RoleLabel } from 'ProjectProfile/common/components/RoleLabel/RoleLabel';
import { RoleLabelType } from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';
import { RoleLabelContainer } from 'ProjectProfile/common/components/RoleLabel/roleLabel.styles';

import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';

import { Header, Inner, Root, Title, TopContainer } from './container.styles';

type Props = {
  children: ReactNode;
  isCollapsible?: boolean;
  isLoading?: boolean;
  onEditClick?: () => void;
  roles?: RoleLabelType[];
  title: string;
};

export const Container = ({
  children,
  isLoading,
  onEditClick,
  roles,
  title,
}: Props) => (
  <Root hasRoleLabel={!!roles && !!roles.length}>
    <Header>
      <TopContainer>
        <Title>{title}</Title>
        {roles && (
          <RoleLabelContainer>
            {roles.map((role) => (
              <RoleLabel key={role} labelType={role} />
            ))}
          </RoleLabelContainer>
        )}
      </TopContainer>
    </Header>
    <Inner>{isLoading ? <LoadingSpinnerWithContainer /> : children}</Inner>

    {onEditClick && (
      <EditButton
        onClick={onEditClick}
        segmentEventProps={{ ...(title && { name: title }) }}
      />
    )}
  </Root>
);
