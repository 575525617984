import { css } from '@emotion/react';

import styled from '@emotion/styled';

import { mq } from 'common/utils/mediaQueries';

export const Info = styled.p`
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.gray};
  font: 600 14px/18px ${({ theme }) => theme.font.inter};
`;

export const Room = styled.div`
  font: normal 16px/22px ${({ theme }) => theme.font.inter};
  letter-spacing: 0.2px;
  padding-top: 30px;

  ${mq.medium(css`
    font-size: 20px;
    line-height: 28px;
    letter-spacing: normal;
  `)};
`;

export const Value = styled.div`
  font: normal 14px/20px ${({ theme }) => theme.font.inter};
  letter-spacing: 0.2px;
  padding: 20px 0 30px;

  ${mq.medium(css`
    font-size: 16px;
    line-height: 22px;
  `)};
`;

export const Wrapper = styled.div`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.color.black};
  }
`;
