const getContractorEditPath = (contractorId = ':contractorId') =>
  `/contractors/${contractorId}`;

const getOrganizationsPath = () => `/organizations`;

const getOrganizationEditPath = (
  contractorOrganizationId = ':contractorOrganizationId',
) => `/organizations/${contractorOrganizationId}/edit`;

export const CONTRACTOR_PATHS = {
  getContractorEditPath,
  getOrganizationEditPath,
  getOrganizationsPath,
};
