import {
  Dispatch,
  SetStateAction,
  ReactNode,
  useState,
  useLayoutEffect,
  createContext,
} from 'react';

import { useHistory } from 'react-router-dom';
import { Action } from 'history';

const HISTORY_ACTION_REPLACE = 'REPLACE';

type Registry = {
  [key: string]: boolean;
};

type ModalContext = Dispatch<SetStateAction<Registry>>;

export const ModalContextStatic = createContext<ModalContext>(() => {});

export const ModalContextDynamic = createContext<Registry>({});

type Props = {
  children: ReactNode;
};

export const ModalProvider = ({ children }: Props) => {
  const [openRegistry, setOpenRegistry] = useState({});
  const history = useHistory();

  useLayoutEffect(() => {
    const unlisten = history.listen((_: unknown, action: Action) => {
      if (action === HISTORY_ACTION_REPLACE) return;

      const isOpen = Object.values(openRegistry).filter(Boolean);
      if (isOpen) setOpenRegistry({});
    });

    return () => unlisten();
  }, [history, openRegistry, setOpenRegistry]);

  return (
    <ModalContextDynamic.Provider value={openRegistry}>
      <ModalContextStatic.Provider value={setOpenRegistry}>
        {children}
      </ModalContextStatic.Provider>
    </ModalContextDynamic.Provider>
  );
};
