import { Dialog } from 'common/components/Modals/Dialog/Dialog';

import { DialogTitle } from 'common/components/Modals/Dialog/DialogTitle';
import { DialogContent } from 'common/components/Modals/Dialog/DialogContent';
import { DialogActions } from 'common/components/Modals/Dialog/DialogActions';

export const DIALOG_ID = 'DELETE_SPACE_CONFIRMATION';
const DIALOG_CONTENT = 'Are you sure you want to delete this space?';
const DIALOG_TITLE = 'This action cannot be undone.';

type Props = {
  id: string;
  onAccept: () => void;
  onClose: () => void;
};

export const DeleteSpaceDialog = ({ id, onAccept, onClose }: Props) => {
  const handleAccept = () => {
    onAccept();
    onClose();
  };

  return (
    <Dialog id={`${DIALOG_ID}${id}`} isClosable onClose={onClose}>
      <DialogTitle>{DIALOG_TITLE}</DialogTitle>
      <DialogContent>{DIALOG_CONTENT}</DialogContent>
      <DialogActions
        acceptLabel="Delete Space"
        cancelLabel="Cancel"
        onAccept={handleAccept}
        onCancel={onClose}
      />
    </Dialog>
  );
};
