import { differenceInCalendarDays } from 'date-fns';

import { RoomType } from 'common/apollo/enums/RoomType';
import { SkuVendorStockStatus } from 'common/apollo/enums/SkuVendorStockStatus';

import { dateToUSFormat } from 'common/utils/date';

import { ReactComponent as CheckMarkIcon } from 'common/assets/icons/check-mark-green.svg';
import { ReactComponent as ExclamationMarkIcon } from 'common/assets/icons/exclamation-mark.svg';
import { ReactComponent as WarningRedIcon } from 'common/assets/icons/warning-red.svg';
import { ReactComponent as WarningYellowIcon } from 'common/assets/icons/warning-yellow.svg';

const LEAD_TIME_MAX = { [RoomType.BATHROOM]: 3, [RoomType.KITCHEN]: 6 };

export const getStockStatusOptions = ({
  backInStockDate,
  leadTimeUpdatedAt,
  maxLeadTimeWeeks,
  minLeadTimeWeeks,
  roomType,
  stockStatus,
}: {
  backInStockDate: string | null;
  leadTimeUpdatedAt: string | null;
  maxLeadTimeWeeks: number | null;
  minLeadTimeWeeks: number | null;
  roomType: RoomType;
  stockStatus: SkuVendorStockStatus | null;
}) => {
  switch (stockStatus) {
    case SkuVendorStockStatus.IN_STOCK: {
      const leadTimeUpdateDaysDifference = differenceInCalendarDays(
        new Date(),
        leadTimeUpdatedAt ? new Date(leadTimeUpdatedAt) : 0,
      );

      const isUpdatedRecently = leadTimeUpdateDaysDifference < 21;

      const isLongLeadTime =
        maxLeadTimeWeeks &&
        maxLeadTimeWeeks >
          LEAD_TIME_MAX[roomType as keyof typeof LEAD_TIME_MAX];

      const icon = isLongLeadTime ? WarningYellowIcon : CheckMarkIcon;

      return {
        action: isUpdatedRecently ? null : 'Update Status',
        description: 'In Stock',
        icon: isUpdatedRecently ? icon : WarningRedIcon,
        tooltip: [
          `Last Updated: ${dateToUSFormat(leadTimeUpdatedAt!)}`,
          `Lead Time: ${minLeadTimeWeeks} – ${maxLeadTimeWeeks} weeks`,
        ],
      };
    }
    case SkuVendorStockStatus.ON_BACK_ORDER: {
      return {
        action: 'Update Status',
        description: 'Backordered',
        icon: WarningRedIcon,
        tooltip: [
          `Last Updated: ${dateToUSFormat(leadTimeUpdatedAt!)}`,
          `Lead Time: ${minLeadTimeWeeks} – ${maxLeadTimeWeeks} weeks`,
          ...(backInStockDate
            ? [`Backordered Until: ${dateToUSFormat(backInStockDate)}`]
            : []),
        ],
      };
    }
    case SkuVendorStockStatus.DISCONTINUED: {
      const leadTimeUpdateDaysDifference = differenceInCalendarDays(
        new Date(),
        leadTimeUpdatedAt ? new Date(leadTimeUpdatedAt) : 0,
      );

      const isUpdatedRecently = leadTimeUpdateDaysDifference < 21;

      return {
        action: isUpdatedRecently ? null : 'Update Status',
        description: 'Discontinued',
        icon: WarningRedIcon,
        tooltip: [`Last Updated: ${dateToUSFormat(leadTimeUpdatedAt!)}`],
      };
    }
    case SkuVendorStockStatus.INVENTORIED: {
      const leadTimeUpdateDaysDifference = differenceInCalendarDays(
        new Date(),
        leadTimeUpdatedAt ? new Date(leadTimeUpdatedAt) : 0,
      );

      const isUpdatedRecently = leadTimeUpdateDaysDifference < 21;

      const isLongLeadTime =
        maxLeadTimeWeeks &&
        maxLeadTimeWeeks >
          LEAD_TIME_MAX[roomType as keyof typeof LEAD_TIME_MAX];

      const icon = isLongLeadTime ? WarningYellowIcon : CheckMarkIcon;

      return {
        action: isUpdatedRecently ? null : 'Update Status',
        description: 'Inventoried',
        icon: isUpdatedRecently ? icon : WarningRedIcon,
        tooltip: [
          `Last Updated: ${dateToUSFormat(leadTimeUpdatedAt!)}`,
          `Lead Time: ${minLeadTimeWeeks} – ${maxLeadTimeWeeks} weeks`,
        ],
      };
    }
    default: {
      return {
        action: 'Add Status',
        description: 'NA',
        icon: ExclamationMarkIcon,
        tooltip: ['Please add status information'],
      };
    }
  }
};
