export type NotificationItemParams = {
  notificationId: string;
  projectId: string;
  scheduleItemId: string;
  taskId: string;
};

const getNotificationsPath = (
  projectId: string | number = ':projectId',
  scheduleItemId: string | number = ':scheduleItemId',
) => `/projects/${projectId}/schedule-items/${scheduleItemId}/notifications`;

const getNotificationsAddPath = (
  projectId: string | number = ':projectId',
  scheduleItemId: string | number = ':scheduleItemId',
) =>
  `/projects/${projectId}/schedule-items/${scheduleItemId}/notifications/new`;

const getNotificationsEditPath = (
  projectId: string | number = ':projectId',
  scheduleItemId: string | number = ':scheduleItemId',
  notificationId: string | number = ':notificationId',
) =>
  `/projects/${projectId}/schedule-items/${scheduleItemId}/notifications/${notificationId}`;

const getTaskNotificationsPath = (
  projectId: string | number = ':projectId',
  taskId: string | number = ':taskId',
) => `/projects/${projectId}/client-tasks/${taskId}/notifications`;

const getTaskNotificationsAddPath = (
  projectId: string | number = ':projectId',
  taskId: string | number = ':taskId',
) => `/projects/${projectId}/client-tasks/${taskId}/notifications/new`;

const getTaskNotificationsEditPath = (
  projectId: string | number = ':projectId',
  taskId: string | number = ':taskId',
  notificationId: string | number = ':notificationId',
) =>
  `/projects/${projectId}/client-tasks/${taskId}/notifications/${notificationId}`;

export const NOTIFICATIONS_PATHS = {
  getNotificationsAddPath,
  getNotificationsEditPath,
  getNotificationsPath,
  getTaskNotificationsAddPath,
  getTaskNotificationsEditPath,
  getTaskNotificationsPath,
};
