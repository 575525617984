import { Label, Control } from 'common/components/Checkbox/checkbox.styles';

type CheckboxProps = { hasError?: boolean; label?: string; order?: number };

type Props = CheckboxProps &
  Omit<React.HTMLProps<HTMLInputElement>, 'as' | 'type'>;

export const Checkbox = ({ label, ...rest }: Props) => {
  if (!label) return <Control {...rest} />;

  return (
    <Label>
      <Control {...rest} />
      {label}
    </Label>
  );
};
