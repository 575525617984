import { useContext, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from 'common/consts/localStorage';
import { AuthUserPermission } from 'common/enums/AuthUserPermission';

import { AuthenticationContext } from './AuthenticationProvider';

export const useCurrentUser = () => {
  const { currentUser } = useContext(AuthenticationContext);

  return currentUser;
};

export const useIsCurrentUserLoading = () => {
  const { isLoading } = useContext(AuthenticationContext);

  return isLoading;
};

export const useStoreAuthenticationToken = () => {
  const { refetch } = useContext(AuthenticationContext);

  return useCallback(
    (token: string) => {
      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, token);
      return refetch();
    },
    [refetch],
  );
};

export const useRemoveAuthenticationToken = () => {
  const { clearStore } = useApolloClient();

  return useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);
    return clearStore();
  }, [clearStore]);
};

export const useAuth = () => useContext(AuthenticationContext);

export const usePermissions = (
  permissions: AuthUserPermission | AuthUserPermission[],
) => {
  const { currentUser } = useContext(AuthenticationContext);

  if (!currentUser?.permissions?.length) return false;

  const list: AuthUserPermission[] = Array.isArray(permissions)
    ? permissions
    : [permissions];

  return list.some((permission) =>
    currentUser?.permissions?.includes(permission),
  );
};
