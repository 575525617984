import { SidebarElement } from 'common/components/Sidebar/SidebarContent.styles';

import { Collapse } from 'common/components/Collapse/Collapse';

import { CollapseContent } from 'common/components/Collapse/CollapseContent';

import { RightChevronCollapseButton } from 'common/components/Collapse/CollapseButtons/RightChevronCollapseButton/RightChevronCollapseButton';

import { useStatusBreakGround } from 'common/shared/Sidebar/StatusBreakGround/statusBreakGround.hooks';

import { ProjectSidebarStatus } from './ProjectSidebarStatus/ProjectSidebarStatus';
import { BreakGroundDate } from './BreakGroundDate/BreakGroundDate';
import { titleStyles, Content } from './statusbreakground.styles';

export const StatusBreakGround = ({ renovationPlanId = '' }) => {
  const removeBreakGroundSidebar = useStatusBreakGround();
  const title = removeBreakGroundSidebar
    ? 'Status'
    : 'Status & Break Ground Date';

  return (
    <SidebarElement>
      <Collapse>
        <RightChevronCollapseButton css={titleStyles} title={title} />
        <CollapseContent>
          <Content>
            <ProjectSidebarStatus renovationPlanId={renovationPlanId} />
            {!removeBreakGroundSidebar && (
              <BreakGroundDate renovationPlanId={renovationPlanId} />
            )}
          </Content>
        </CollapseContent>
      </Collapse>
    </SidebarElement>
  );
};
