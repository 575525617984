import { Form, Formik } from 'formik';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Grid } from '@material-ui/core';

import { ModalCloseButton } from 'common/components/Modals/ModalCloseButton';
import { Button } from 'common/components/Buttons/Button/Button';
import { FormSelect } from 'common/components/Form/Select/FormSelect';

import { FormTextArea } from 'common/_components/Form/FormTextarea/FormTextarea';
import { FormTextField } from 'common/_components/Form/FormTextField/FormTextField';

import { Checkbox } from 'common/shared/Question/Checkbox/Checkbox';
import { TextFieldSize } from 'common/enums/TextField';
import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

import {
  DialogActions,
  DialogContent,
  FieldInfo,
  FieldWrapper,
} from 'ProjectProfile/common/components/EditForm/editForm.styles';

import { Answers } from 'ProjectProfile/common/types/answer';
import { UpdateProjectPlanQuestionsResponse } from 'ProjectProfile/common/responses/updateProjectPlanQuestionsResponse';
import { FlagToggle } from 'ProjectProfile/common/components/FlagToggle/FlagToggle';

import { useSiteSurveyEdit } from './siteSurvey.hooks';
import { SHORT_TEXT_MAX_LENGTH, validationSchema } from './siteSurvey.consts';

type Props = {
  close: () => void;
  handleSuccessfulSubmit: (data: UpdateProjectPlanQuestionsResponse) => void;
};

export const SiteSurveyEdit = ({ close, handleSuccessfulSubmit }: Props) => {
  const { importances, initialValues, options, placeholders, submit } =
    useSiteSurveyEdit();

  const handleSubmit = (values: Answers) =>
    submit(values, importances, handleSuccessfulSubmit);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onBackdropClick={close}
      onEscapeKeyDown={close}
      open
    >
      <DialogTitle>Site Survey Notes</DialogTitle>
      <ModalCloseButton onClick={close} />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <DialogContent scrollable>
              <Grid container spacing={2}>
                <Grid item md={12} sm={12}>
                  <FormTextField
                    id={PropertyGroupIdentifier.SERVICE_ENTRANCE}
                    label="Service Entrance"
                    name={PropertyGroupIdentifier.SERVICE_ENTRANCE}
                    placeholder="Service Entrance"
                  />
                  <FlagToggle
                    disabled={isSubmitting}
                    isOn={importances.SERVICE_ENTRANCE.isOn}
                    onToggle={importances.SERVICE_ENTRANCE.toggle}
                  />
                </Grid>
                <Grid item md={12} sm={12}>
                  <FieldWrapper>
                    <FormTextArea
                      disabled={isSubmitting}
                      id={PropertyGroupIdentifier.SUPER_CONTACT_INFO}
                      label="Super Contact Info"
                      name={PropertyGroupIdentifier.SUPER_CONTACT_INFO}
                      placeholder={placeholders.SUPER_CONTACT_INFO}
                      rows={5}
                    />
                    <FieldInfo>
                      {values.SUPER_CONTACT_INFO?.length}/
                      {SHORT_TEXT_MAX_LENGTH}
                    </FieldInfo>
                    <FlagToggle
                      disabled={isSubmitting}
                      isOn={importances.SUPER_CONTACT_INFO.isOn}
                      onToggle={importances.SUPER_CONTACT_INFO.toggle}
                    />
                  </FieldWrapper>
                </Grid>

                <Grid item md={6} sm={12}>
                  <FormSelect
                    id={PropertyGroupIdentifier.IS_SCOPE_VERIFICATION_REQUIRED}
                    isDisabled={isSubmitting}
                    label="Scope verification required"
                    name={
                      PropertyGroupIdentifier.IS_SCOPE_VERIFICATION_REQUIRED
                    }
                    options={options.IS_SCOPE_VERIFICATION_REQUIRED}
                    placeholder="Scope verification required"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
                <Grid item md={12} sm={12}>
                  <FormTextArea
                    disabled={isSubmitting}
                    id={PropertyGroupIdentifier.POST_SITE_SURVEY_NOTES}
                    label="Post site-survey notes"
                    name={PropertyGroupIdentifier.POST_SITE_SURVEY_NOTES}
                    placeholder={placeholders.POST_SITE_SURVEY_NOTES}
                    rows={5}
                  />
                  <FlagToggle
                    disabled={isSubmitting}
                    isOn={importances.POST_SITE_SURVEY_NOTES.isOn}
                    onToggle={importances.POST_SITE_SURVEY_NOTES.toggle}
                  />
                </Grid>
                <Grid item md={12} sm={12}>
                  <FormTextArea
                    disabled={isSubmitting}
                    id={PropertyGroupIdentifier.PRICING_UPDATES_NOTES}
                    label="Pricing update notes"
                    name={PropertyGroupIdentifier.PRICING_UPDATES_NOTES}
                    placeholder={placeholders.PRICING_UPDATES_NOTES}
                    rows={5}
                  />
                  <FlagToggle
                    disabled={isSubmitting}
                    isOn={importances.PRICING_UPDATES_NOTES.isOn}
                    onToggle={importances.PRICING_UPDATES_NOTES.toggle}
                  />
                </Grid>
                <Grid item md={12} sm={12}>
                  <FormTextArea
                    disabled={isSubmitting}
                    id={PropertyGroupIdentifier.LIST_OF_ALL_TO_VIF}
                    label="List of All to VIF"
                    name={PropertyGroupIdentifier.LIST_OF_ALL_TO_VIF}
                    placeholder={placeholders.LIST_OF_ALL_TO_VIF}
                    rows={5}
                  />
                  <FlagToggle
                    disabled={isSubmitting}
                    isOn={importances.LIST_OF_ALL_TO_VIF.isOn}
                    onToggle={importances.LIST_OF_ALL_TO_VIF.toggle}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <FormSelect
                    id={
                      PropertyGroupIdentifier.IS_THERE_SPACE_IN_THE_SUB_E_PANEL_FOR_ADDITIONAL_BREAKERS
                    }
                    isDisabled={isSubmitting}
                    label="Is there space in the sub-e-panel for additional breakers?"
                    name={
                      PropertyGroupIdentifier.IS_THERE_SPACE_IN_THE_SUB_E_PANEL_FOR_ADDITIONAL_BREAKERS
                    }
                    options={
                      options.IS_THERE_SPACE_IN_THE_SUB_E_PANEL_FOR_ADDITIONAL_BREAKERS
                    }
                    placeholder="Is there space in the sub-e-panel for additional breakers?"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <FormSelect
                    id={
                      PropertyGroupIdentifier.WHAT_IS_TOTAL_AMPERAGE_OF_ELECTRICAL_SERVICE
                    }
                    isDisabled={isSubmitting}
                    label="What is the total amperage of electrical service?"
                    name={
                      PropertyGroupIdentifier.WHAT_IS_TOTAL_AMPERAGE_OF_ELECTRICAL_SERVICE
                    }
                    options={
                      options.WHAT_IS_TOTAL_AMPERAGE_OF_ELECTRICAL_SERVICE
                    }
                    placeholder="What is the total amperage of electrical service?"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <FormSelect
                    id={PropertyGroupIdentifier.ELECTRICAL_PANEL_LOCATION}
                    isDisabled={isSubmitting}
                    label="Electrical Panel Location"
                    name={PropertyGroupIdentifier.ELECTRICAL_PANEL_LOCATION}
                    options={options.ELECTRICAL_PANEL_LOCATION}
                    placeholder="Electrical Panel Location"
                    size={TextFieldSize.EXTRA_LARGE}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <Checkbox
                    id={PropertyGroupIdentifier.PHOTO_OF_ELECTRICAL_PANEL}
                    isDisabled={isSubmitting}
                    isRequired={false}
                    isSingleSelect
                    label="Have you taken a photo of the electrical panel?"
                    name={PropertyGroupIdentifier.PHOTO_OF_ELECTRICAL_PANEL}
                    options={options.PHOTO_OF_ELECTRICAL_PANEL}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
