import { DataProxy, FetchResult, useMutation } from '@apollo/client';

import { useParams } from 'react-router-dom';

import { useAlert } from 'common/hooks/useAlert';

import { UpdateProjectPlanSecondaryContactResponse } from 'ProjectProfile/common/responses/updateProjectPlanSecondaryContactResponse';
import { UpdateProjectPlanSecondaryContactRequest } from 'ProjectProfile/common/requests/updateProjectPlanSecondaryContactRequest';

import { UPDATE_PROJECT_PLAN_SECONDARY_CONTACT } from 'ProjectProfile/common/apollo/gql/updateProjectPlanSecondaryContact';

import { SecondaryContactInput } from 'ProjectProfile/ProjectInformation/v1/ContactInfo/secondaryContact.types';

import { GET_PROJECT_PLAN_SECONDARY_CONTACT } from 'common/apollo/queries/getProjectPlanSecondaryContact';
import { GetProjectPlanSecondaryContactRequest } from 'common/apollo/requests/getProjectPlanSecondaryContactRequest';
import { GetProjectPlanSecondaryContactResponse } from 'common/apollo/responses/getProjectPlanSecondaryContactResponse';

type Props = {
  onSuccessCallback?: () => void;
  values: SecondaryContactInput;
};

export const useProjectPlanSecondaryContactUpdate = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { onCompleted, onError } = useAlert();

  const [updateProjectPlanSecondaryContact] = useMutation<
    UpdateProjectPlanSecondaryContactResponse,
    UpdateProjectPlanSecondaryContactRequest
  >(UPDATE_PROJECT_PLAN_SECONDARY_CONTACT, {
    onCompleted: () => onCompleted('Changes saved'),
    onError: (error) => onError(error.message),
    update: (proxy: DataProxy, response: FetchResult<any>) => {
      const data = proxy.readQuery<
        GetProjectPlanSecondaryContactResponse,
        GetProjectPlanSecondaryContactRequest
      >({
        query: GET_PROJECT_PLAN_SECONDARY_CONTACT,
        variables: {
          input: {
            projectId,
          },
        },
      });

      if (!data || !response.data) return;

      return proxy.writeQuery({
        data: {
          ...data,
          projectPlanSecondaryContact: {
            ...data.projectPlanSecondaryContact,
            ...response.data.projectPlanSecondaryContactUpdate,
          },
        },
        query: GET_PROJECT_PLAN_SECONDARY_CONTACT,
        variables: {
          input: {
            projectId,
          },
        },
      });
    },
  });

  const handleSubmit = async ({ onSuccessCallback, values }: Props) => {
    const response = await updateProjectPlanSecondaryContact({
      variables: {
        input: {
          projectId,
          ...values,
        },
      },
    });

    if (response && onSuccessCallback) onSuccessCallback();
  };

  return {
    handleSubmit,
  };
};
