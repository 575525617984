import styled from '@emotion/styled';

import { TableCell as TableCellMaterial } from '@material-ui/core';

type Props = {
  nowrap?: boolean;
};

const omitProps = ({ nowrap, ...props }: any) => (
  <TableCellMaterial {...props} />
);

export const ResponsiveTableCell = styled(omitProps)<Props>`
  white-space: ${({ nowrap }: Props) => (nowrap ? 'nowrap' : 'normal')};
`;
