import { convertToFraction } from 'common/utils/fraction';

type SkuSizeType = 'length' | 'depth' | 'height' | 'width' | 'thickness';

const SkuSizeSuffixMap: { [key in SkuSizeType]: string } = {
  depth: 'D',
  height: 'H',
  length: 'L',
  thickness: 'T',
  width: 'W',
};

const getSkuSizeValue = (value: string | number | null, type: SkuSizeType) =>
  value ? `${value}"${SkuSizeSuffixMap[type]}` : null;

const getSkuSizeValueWithFraction = (
  value: string | number | null,
  type: SkuSizeType,
) =>
  value ? `${convertToFraction(+value, true)}${SkuSizeSuffixMap[type]}` : null;

type Props = {
  depth: string | number | null;
  height: string | number | null;
  length: string | number | null;
  thickness: string | number | null;
  width: string | number | null;
};

export const getSkuSize = ({
  depth,
  height,
  length,
  thickness,
  width,
}: Props) => {
  const baseSize = [
    getSkuSizeValue(length, 'length'),
    getSkuSizeValue(width, 'width'),
    getSkuSizeValue(height, 'height'),
    getSkuSizeValue(depth, 'depth'),
  ]
    .filter(Boolean)
    .join(' x ');
  return [baseSize, getSkuSizeValue(thickness, 'thickness')]
    .filter(Boolean)
    .join(' | ');
};

export const getSkuSizeWithFraction = ({
  depth,
  height,
  length,
  thickness,
  width,
}: Props) => {
  const baseSize = [
    getSkuSizeValueWithFraction(length, 'length'),
    getSkuSizeValueWithFraction(width, 'width'),
    getSkuSizeValueWithFraction(height, 'height'),
    getSkuSizeValueWithFraction(depth, 'depth'),
  ]
    .filter(Boolean)
    .join(' x ');
  return [baseSize, getSkuSizeValueWithFraction(thickness, 'thickness')]
    .filter(Boolean)
    .join(' | ');
};
