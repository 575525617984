import { object, string } from 'yup';

import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

export const questionIdentifiers = [
  PropertyGroupIdentifier.FLEXIBILITY,
  PropertyGroupIdentifier.INVOLVEMENT,
  PropertyGroupIdentifier.IS_THE_CLIENT_LIVING_THROUGH_THE_RENOVATION,
  PropertyGroupIdentifier.OWNERSHIP_STATUS,
  PropertyGroupIdentifier.PAYMENT_PREFERENCE,
  PropertyGroupIdentifier.PROJECT_BUDGET,
  PropertyGroupIdentifier.RENOVATION_TIMELINE,
];

export const openQuestionsIdentifiers = [
  PropertyGroupIdentifier.CLIENT_NOTES,
  PropertyGroupIdentifier.DECISION_MAKERS,
  PropertyGroupIdentifier.PREVIOUS_RENOVATION_EXPERIENCE,
  PropertyGroupIdentifier.PROJECT_EXTENSION_NOTES,
  PropertyGroupIdentifier.TIMELINE_PREFERENCES,
];

export const identifiers = [
  ...openQuestionsIdentifiers,
  ...questionIdentifiers,
];

export const validationSchema = object().shape({
  [PropertyGroupIdentifier.CLIENT_NOTES]: string(),
  [PropertyGroupIdentifier.DECISION_MAKERS]: string(),
  [PropertyGroupIdentifier.FLEXIBILITY]: string(),
  [PropertyGroupIdentifier.INVOLVEMENT]: string(),
  [PropertyGroupIdentifier.IS_THE_CLIENT_LIVING_THROUGH_THE_RENOVATION]:
    string(),
  [PropertyGroupIdentifier.PAYMENT_PREFERENCE]: string(),
  [PropertyGroupIdentifier.PREVIOUS_RENOVATION_EXPERIENCE]: string(),
  [PropertyGroupIdentifier.PROJECT_BUDGET]: string(),
  [PropertyGroupIdentifier.PROJECT_EXTENSION_NOTES]: string(),
  [PropertyGroupIdentifier.RENOVATION_TIMELINE]: string(),
  [PropertyGroupIdentifier.TIMELINE_PREFERENCES]: string(),
});

export const ProjectExtensionNotesBlock20DefaultValue = `BUDGET:

TIMELINE:

SCOPE:

RENOVATION SOLUTIONS:

KEY SELLING POINTS:
`;
