import { matchPath, RouteProps } from 'react-router-dom';

export const isRouteMatch = (
  { exact, path }: RouteProps,
  currentPath: string,
) =>
  !!matchPath(currentPath, {
    exact,
    path,
  });
