import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { rgba } from 'common/utils/colors';

import { mq } from 'common/utils/mediaQueries';

export const Header = styled.header`
  border-bottom: 1px solid ${({ theme }) => rgba(theme.color.black, 0.12)};
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  column-gap: 20px;

  button:first-of-type {
    margin-right: auto;
  }
`;

export const Filters = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
  margin-top: 32px;

  ${mq.small(css`
    grid-template-columns: repeat(2, 1fr);
  `)};

  ${mq.large(css`
    grid-template-columns: repeat(5, 1fr);
  `)};
`;

/* TODO: Remove, Temporarily solution until finish whole feature */

export const FilterCount = styled.span`
  align-items: center;
  background: ${({ theme }) => theme.color.primaryOrange};
  border-radius: 50%;
  display: flex;
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
  height: 20px;
  justify-content: center;
  margin-left: 10px;
  width: 20px;
`;
