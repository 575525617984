import styled from '@emotion/styled';

export const Wrapper = styled.div`
  .subcopy,
  .indent {
    color: ${({ theme }) => theme.color.darkGray};
    font-size: 14px;
    line-height: 20px;
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
  .indent {
    padding-left: 28px;
  }

  .spacer {
    margin-bottom: 40px;
  }

  .nowrap {
    white-space: nowrap;
  }

  strong {
    font-weight: bold;
  }

  h2 {
    font: 500 16px/24px ${({ theme }) => theme.font.roboto};
    margin: 16px 0;
  }

  p {
    margin-bottom: 8px;
  }

  a {
    color: ${({ theme }) => theme.color.blue};
    text-decoration: underline;
  }

  ul {
    font: normal 14px/24px ${({ theme }) => theme.font.roboto};
    list-style: disc;
    padding-left: 14px;

    ul {
      list-style: none;

      li {
        &::before {
          content: '-';
          display: inline;
          padding-right: 10px;
        }
      }
    }
  }

  ol {
    font: normal 14px/24px ${({ theme }) => theme.font.roboto};
  }
`;
