import styled from '@emotion/styled';

import noImage from 'common/assets/icons/no-image.svg';
import { ReactComponent as CheckboxIcon } from 'common/assets/icons/checkbox.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 274px;
  position: relative;
`;

export const TagsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const ClientSelectionTag = styled.div`
  position: absolute;
  margin: 12px 12px;
`;

export const Name = styled.p`
  font: 400 16px/24px ${({ theme }) => theme.font.inter};
`;

export const Price = styled.p`
  font: 400 16px/24px ${({ theme }) => theme.font.inter};
`;

type ThumbnailProps = {
  isSelectable?: boolean;
  isSelected?: boolean;
};

export const Thumbnail = styled.img<ThumbnailProps>`
  border-radius: 4px;
  border: 1px solid
    ${({ isSelectable, isSelected, theme }) =>
      isSelectable && isSelected ? theme.color.orange8 : theme.color.gray5};
  cursor: ${({ isSelectable }) => (isSelectable ? 'pointer' : 'default')};
  display: block;
  object-fit: cover;
  position: relative;
  width: 274px;
  height: 274px;

  &:after {
    background: #fff url(${noImage}) 100% no-repeat;
    border-radius: 4px;
    content: ' ';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const SelectionBadge = styled.div`
  background: ${({ theme }) => theme.color.orange8};
  border: 1px ${({ theme }) => theme.color.orange8};
  border-radius: 100px;
  padding: 2px 10px;
  text-transform: capitalize;
  width: fit-content;
  position: absolute;
  margin: 12px 12px;
`;

export const StyledCheckboxIcon = styled(CheckboxIcon)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

export const PropertiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PropertyLabel = styled.p`
  ${({ theme }) => theme.typography.body2}

  color: ${({ theme }) => theme.color.gray11};
`;
