import { ReactNode } from 'react';

import { LayoutContent } from './LayoutContent';

import { LayoutSidebar } from './LayoutSidebar';

type Props = {
  children: ReactNode;
  hideSidebar?: boolean;
};

export const Layout = ({ children, hideSidebar }: Props) => (
  <>
    {!hideSidebar && <LayoutSidebar />}
    <LayoutContent hideSidebar={hideSidebar}>{children}</LayoutContent>
  </>
);
