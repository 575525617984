import { ProjectPlanQuestions } from 'common/apollo/models/projectPlanQuestion';

import { Answers } from 'ProjectProfile/common/types/answer';
import { preKickoffNotes } from 'ProjectProfile/PreDesign/PreKickoff/preKickoff.consts';

export const normalizeProjectPlanQuestions = (
  questions: ProjectPlanQuestions[],
) =>
  questions.reduce(
    (acc, question) => ({
      ...acc,
      [question.identifier]:
        question.responseText ||
        question.answers
          .filter(({ isSelected }) => isSelected)
          .map(({ label }) => label)
          .join(', ')
          .trim() ||
        preKickoffNotes[question.identifier as keyof typeof preKickoffNotes] ||
        '',
    }),
    {} as Answers,
  );
