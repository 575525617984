import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { DeliveryBatch } from 'common/apollo/models/deliveryBatch';
import { OrderItem } from 'common/apollo/models/orderItem';

import { CREATE_PROJECT_PROCUREMENT_DELIVERY_BATCH } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/graphql/addBatch.graphql';
import { CreateProjectProcurementDeliveryBatchRequest } from 'common/apollo/requests/createProjectProcurementDeliveryBatchRequest';
import { CreateProjectProcurementDeliveryBatchResponse } from 'common/apollo/responses/createProjectProcurementDeliveryBatchResponse';

import { BatchInput } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/batchRow.types';
import { GET_PROJECT_PROCUREMENT_DELIVERY_BATCHES } from 'Project/Procurement/Warehouse/warehouse.graphql';
import { GetProjectProcurementDeliveryBatchesResponse } from 'Project/Procurement/common/apollo/responses/getProjectProcurementDeliveryBatchesResponse';
import { GetProjectProcurementDeliveryBatchesRequest } from 'Project/Procurement/common/apollo/requests/getProjectProcurementDeliveryBatchesRequest';
import { batchInputMapper } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/batchRow.utils';

export const useAddBatch = (orderItem: OrderItem, onEndAdd: () => void) => {
  const { projectId } = useParams<{ projectId: string }>();

  const [createDeliveryBatch] = useMutation<
    CreateProjectProcurementDeliveryBatchResponse,
    CreateProjectProcurementDeliveryBatchRequest
  >(CREATE_PROJECT_PROCUREMENT_DELIVERY_BATCH, {
    onCompleted: () => onEndAdd(),
  });

  const handleCreate = async (input: BatchInput) => {
    await createDeliveryBatch({
      update: (cache, { data }) => {
        const store = cache.readQuery<
          GetProjectProcurementDeliveryBatchesResponse,
          GetProjectProcurementDeliveryBatchesRequest
        >({
          query: GET_PROJECT_PROCUREMENT_DELIVERY_BATCHES,
          variables: {
            projectId,
          },
        }) as GetProjectProcurementDeliveryBatchesResponse;

        const deliveryBatchCreate = data?.deliveryBatchCreate;
        if (!deliveryBatchCreate) return;

        const newBatch: DeliveryBatch = {
          ...deliveryBatchCreate,
          orderItem,
        };

        cache.writeQuery<
          GetProjectProcurementDeliveryBatchesResponse,
          GetProjectProcurementDeliveryBatchesRequest
        >({
          data: {
            ...store,
            project: {
              ...store.project,
              deliveryBatches: [...store.project.deliveryBatches, newBatch],
            },
          },
          query: GET_PROJECT_PROCUREMENT_DELIVERY_BATCHES,
          variables: {
            projectId,
          },
        });
      },
      variables: {
        input: {
          ...batchInputMapper(input),
          orderItemId: orderItem.id,
        },
      },
    });
  };

  return {
    handleCreate,
  };
};
