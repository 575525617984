import { ReactNode } from 'react';

import { Collapse } from 'common/components/Collapse/Collapse';
import { CollapseContent } from 'common/components/Collapse/CollapseContent';

import { RoleLabelType } from 'ProjectProfile/common/components/RoleLabel/roleLabel.consts';

import { CollapseButton } from './CollapseButton/CollapseButton';
import { Header, Inner, Root } from './collapsibleContainer.styles';

type Props = {
  children: ReactNode;
  isCollapsible?: boolean;
  onEditClick?: () => void;
  roles?: RoleLabelType[];
  title: string;
};

export const CollapsibleContainer = ({
  children,
  onEditClick,
  roles,
  title,
}: Props) => (
  <Collapse isOpen>
    <Root>
      <Header>
        <CollapseButton onEditClick={onEditClick} roles={roles} title={title} />
      </Header>
      <Inner>
        <CollapseContent>{children}</CollapseContent>
      </Inner>
    </Root>
  </Collapse>
);
