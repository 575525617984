import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useMemo } from 'react';

import { GetProjectProcurementOrdersRequest } from 'Project/Procurement/common/apollo/requests/getProjectProcurementOrdersRequest';
import { GetProjectProcurementOrdersResponse } from 'Project/Procurement/common/apollo/responses/getProjectProcurementOrdersResponse';

import { GET_PROJECT_PROCUREMENT_ORDERS } from 'Project/Procurement/Orders/orders.graphql';
import { normalizeProcurementOrdersData } from 'Project/Procurement/Orders/orders.utils';
import { FeatureFlags } from 'common/enums/FeatureFlags';

export const useOrders = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const flags = useFlags();
  const postDsoEnabled = flags[FeatureFlags.POST_DSO];

  const {
    data,
    error,
    loading: isLoading,
  } = useQuery<
    GetProjectProcurementOrdersResponse,
    GetProjectProcurementOrdersRequest
  >(GET_PROJECT_PROCUREMENT_ORDERS, {
    fetchPolicy: 'cache-and-network',
    variables: { projectId },
  });

  const orders = useMemo(
    () => normalizeProcurementOrdersData(data?.project.orders),
    [data],
  );

  return {
    error,
    isLoading,
    orders,
    postDsoEnabled,
    projectId,
  };
};
