import { gql } from '@apollo/client';

export const GET_PROJECT_REFERRER = gql`
  query GetProjectReferrer($id: ID!) {
    project(id: $id) {
      id
      user {
        id
        referrer {
          id
          name
          email
          code
          campaignName
          campaignId
        }
      }
    }
  }
`;
