import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mq } from 'common/utils/mediaQueries';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.gray2};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
`;

export const Header = styled.header`
  align-items: center;
  column-gap: 16px;
  display: flex;
`;

export const Content = styled.section`
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray6};
  flex: 1;
  height: 690px;
  margin-top: 24px;
  overflow: auto;
  width: 100%;
`;

export const Footer = styled.footer`
  align-items: center;
  background: ${({ theme }) => theme.color.gray3};
  border-top: 0;
  border: 1px solid ${({ theme }) => theme.color.gray6};
  display: flex;
  justify-content: space-between;
  padding: 24px 20px;
`;

export const Title = styled.h1`
  font: 500 20px/28px ${({ theme }) => theme.font.inter};
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mq.large(css`
    flex-direction: row;
  `)};
`;

export const Table = styled.table`
  border-bottom: 1px solid ${({ theme }) => theme.color.gray6};
  border-collapse: collapse;
  border-spacing: 0;
  font: 400 14px/24px ${({ theme }) => theme.font.inter};
  letter-spacing: 0;
  text-align: left;
  white-space: nowrap;
  width: 100%;

  thead {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray6};
  }

  th {
    color: ${({ theme }) => theme.color.gray11};
    font-weight: 500;
    padding: 16px;

    ${mq.large(css`
      &:last-of-type {
        width: 30%;
      }
    `)};
  }

  td {
    padding: 16px;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      background: ${({ theme }) => theme.color.gray3};
    }

    ${mq.large(css`
      &:last-of-type {
        width: 20%;
      }
    `)};

    > div {
      width: 150px;
    }
  }

  tr + tr {
    border-top: 1px solid ${({ theme }) => theme.color.gray6};
  }
`;
