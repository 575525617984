import styled from '@emotion/styled';

import { TextField } from 'common/components/Form/TextField/TextField';
import { FilterWrapperInner } from 'common/components/FiltersMenu/CheckboxItem/checkboxFilter.styles';

export const RangeTextField = styled(TextField)`
  flex: 2;
  font: 400 16px/24px ${({ theme }) => theme.font.inter};
`;

export const DashContainer = styled.div`
  flex: 0.5;
  padding: 0 16px;
`;

export const Dash = styled.div`
  border: 1px solid ${({ theme }) => theme.color.gray6};
  height: 1px;
`;

export const RangeFilterWrapperInner = styled(FilterWrapperInner)`
  align-items: center;
  padding: 8px 24px 16px 24px;
`;
