import { gql } from '@apollo/client';

export const GET_PROJECT_PLAN_ROOMS_RENDERS = gql`
  query GetProjectPlanRooms($input: ProjectPlanRoomsInput!) {
    projectPlanRooms(input: $input) {
      id
      name
      roomType
      renders {
        id
        imageUrl
      }
    }
  }
`;
