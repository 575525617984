import styled from '@emotion/styled';

export const RoomName = styled.div`
  font: normal normal 16px/24px ${({ theme }) => theme.font.inter};
  padding-bottom: 15px;
`;

export const Section = styled.div`
  padding-bottom: 25px;
`;
