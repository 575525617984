import FileCopyIcon from '@material-ui/icons/FileCopy';

import styled from '@emotion/styled';

export const Title = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 50%;
  flex-grow: 0;
  font: 600 14px/18px ${({ theme }) => theme.font.inter};
  justify-content: space-between;
  letter-spacing: 1px;
  max-width: 50%;
  padding: 0 5px 15px 0;
  text-transform: uppercase;

  > button:hover {
    background-color: transparent;
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 15px;
`;

export const CopyIcon = styled(FileCopyIcon)`
  height: 18px;
`;
