import styled from '@emotion/styled';

export const Outer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.grayBorder};
  background: ${({ theme }) => theme.color.gray1};
  padding: 16px;
  font: normal 14px/20px ${({ theme }) => theme.font.roboto};
  margin: 24px 0;
`;

export const Header = styled.div`
  color: ${({ theme }) => theme.color.black};
  margin: 4px 0;
`;

export const Strong = styled.span`
  font-weight: 500;
`;

export const Timestamp = styled.div`
  color: ${({ theme }) => theme.color.black54};
  margin: 4px 0;
`;
