import styled from '@emotion/styled';

import { Button as ButtonMui } from '@material-ui/core';

export const Button = styled(ButtonMui)`
  border-bottom: 2px solid;
  border-radius: 0;
  padding: 0;
`;

export const Content = styled.div``;

export const EmptyValue = styled.span`
  color: ${({ theme }) => theme.color.gray11};
`;
