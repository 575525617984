import styled from '@emotion/styled';

export const Outer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.grayBorder};
  border-radius: 4px;
  background: ${({ theme }) => theme.color.white};
  padding: 12px;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Header = styled.p`
  padding-bottom: 2px;
`;

export const NewDifference = styled.div`
  background: ${({ theme }) => theme.color.green14};
  color: ${({ theme }) => theme.color.green13};
  display: inline-block;
  padding: 2px 4px;
  margin: 2px 0;
`;

export const OldDifference = styled.div`
  background: ${({ theme }) => theme.color.red13};
  color: ${({ theme }) => theme.color.crimson6};
  padding: 2px 4px;
  margin: 2px 0;

  p {
    text-decoration: line-through;
  }
`;
export const DifferenceContent = styled.p``;
