import { gql } from '@apollo/client';

export const USER_REFERRAL_CAMPAIGN_UPDATE = gql`
  mutation UserReferralCampaignUpdate(
    $input: UserReferralCampaignUpdateInput!
  ) {
    userReferralCampaignUpdate(input: $input) {
      id
    }
  }
`;
