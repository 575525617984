import { gql } from '@apollo/client';

export const GET_PROJECT_SIDEBAR_DETAILS = gql`
  query ProjectSidebarGet($id: ID!) {
    project(id: $id) {
      id
      user {
        id
        firstName
        lastName
        email
        phoneNumber
      }
      address {
        street
        street_2
        state
        city
        zip
      }
      serviceableStatus
      projectType
      market {
        code
      }
      isPostDesignContractorAssignment
      members {
        ... on TeamMember {
          role
        }
        ... on ContractorOrganizationProjectMember {
          role
        }
      }
    }
  }
`;
