import { ReactElement, createContext, useMemo, useContext } from 'react';

import { ModalProps } from '@material-ui/core/Modal';

import { Wrapper, WrapperInner } from 'common/components/Modal/modal.styles';

type Props = { children: ReactElement; onClose: () => void } & Omit<
  ModalProps,
  'children' | 'onClose'
>;

type ModalProviderState = {
  onClose: () => void;
  open: boolean;
};

const ModalProvider = createContext<ModalProviderState>(
  {} as ModalProviderState,
);

export const Modal = ({ children, onClose, open, ...props }: Props) => {
  const value = useMemo(() => ({ onClose, open }), [onClose, open]);

  return (
    <Wrapper onClose={onClose} open={open} {...props}>
      <ModalProvider.Provider value={value}>
        <WrapperInner>{children}</WrapperInner>
      </ModalProvider.Provider>
    </Wrapper>
  );
};

export const useModal = () => useContext(ModalProvider);
