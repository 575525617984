import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mq } from 'common/utils/mediaQueries';

export const Wrapper = styled.div<{ isSidebarOpen: boolean }>`
  align-items: center;
  background: ${({ theme }) => theme.color.white};

  border-bottom: 1px solid ${({ theme }) => theme.color.gray3};
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 18px 24px;

  button:first-of-type {
    margin-right: auto;
  }

  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  z-index: 100;

  ${({ isSidebarOpen }) => css`
    ${mq.small(css`
      left: ${isSidebarOpen ? '270px' : '61px'};
      right: 15px;
    `)}
  `}
`;

export const Info = styled.div`
  ${({ theme }) => theme.typography.body2};
  align-items: center;
  background-color: ${({ theme }) => theme.color.red2};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.red5};
  color: ${({ theme }) => theme.color.red11};
  display: flex;
  font-weight: 500;
  gap: 8px;
  justify-content: center;
  padding: 4px 12px;

  svg {
    width: 16px;
    height: 16px;
  }
`;
