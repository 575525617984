import { useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import { useParams } from 'react-router-dom';

import { useToggle } from 'common/hooks/useToggle';
import {
  GET_REFERRAL_CAMPAIGNS,
  UPDATE_USER_REFERRED_BY,
} from 'ProjectProfile/ProjectInformation/v1/ClientDetails/ReferredBy/referredBy.graphql';
import {
  GetReferralCampaigns,
  UserReferredByUpdateRequest,
  UserReferredByUpdateResponse,
} from 'ProjectProfile/ProjectInformation/v1/ClientDetails/ReferredBy/graphql/referredBy.graphql';

export const useReferredBy = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [referrerId, setReferrerId] = useState<number>(0);
  const [campaign, setCampaign] = useState<{
    codePrefix: string;
    id: number;
    name: string;
  } | null>(null);

  const { isOn, toggleOff, toggleOn } = useToggle();

  const [submit] = useMutation<
    UserReferredByUpdateResponse,
    UserReferredByUpdateRequest
  >(UPDATE_USER_REFERRED_BY, {
    onCompleted: () => toggleOff(),
  });

  const handleSubmit = () => {
    if (!campaign || !referrerId) {
      return;
    }

    submit({
      variables: {
        input: {
          campaignCodePrefix: campaign.codePrefix,
          campaignId: campaign.id,
          projectId: Number(projectId),
          referrerId,
        },
      },
    });
  };

  return {
    campaign,
    handleSubmit,
    isOn,
    referrerId,
    setCampaign,
    setReferrerId,
    toggleOff,
    toggleOn,
  };
};

export const useReferralCampaigns = () => {
  const { data, loading: isLoading } = useQuery<GetReferralCampaigns>(
    GET_REFERRAL_CAMPAIGNS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  );

  const campaigns = useMemo(() => data?.referralCampaigns || [], [data]);

  return {
    campaigns,
    isLoading,
  };
};
