import { Profile } from 'common/shared/Sidebar/Profile/Profile';
import { ProfileEdit } from 'common/shared/Sidebar/Profile/ProfileEdit/ProfileEdit';
import { ProfileLinks } from 'common/shared/Sidebar/Profile/ProfileLinks';
import { UserProfile } from 'common/models/userProfile';

import { ZipCodeStatus } from 'common/apollo/enums/ZipCodeStatus';
import { MarketCode } from 'common/enums/MarketCode';
import { ProjectType } from 'common/apollo/enums/ProjectType';

import { SidebarElement } from 'common/components/Sidebar/SidebarContent.styles';
import { StatusBreakGround } from 'common/shared/Sidebar/StatusBreakGround/StatusBreakGround';
import { PostDSOExperienceMessage } from 'common/shared/Sidebar/PostDSOExperienceMessage/PostDSOExperienceMessage';

type Props = {
  initialValues: UserProfile;
  isEditInProgress: boolean;
  loadingSubmit: boolean;
  marketCode?: MarketCode | null;
  onSubmit: (data: UserProfile) => void;
  projectType?: ProjectType | null;
  renovationPlanId: string;
  secondaryContactEmail: string;
  serviceableStatus: ZipCodeStatus;
  toggleIsEditInProgress: () => void;
  userData: UserProfile;
  userId: string;
};

export const SidebarContent = ({
  initialValues,
  isEditInProgress,
  loadingSubmit,
  marketCode,
  onSubmit,
  projectType,
  renovationPlanId,
  secondaryContactEmail,
  serviceableStatus,
  toggleIsEditInProgress,
  userData,
  userId,
}: Props) => {
  if (isEditInProgress) {
    return (
      <ProfileEdit
        initialValues={initialValues}
        loadingSubmit={loadingSubmit}
        onCancel={toggleIsEditInProgress}
        onSubmit={onSubmit}
        secondaryContactEmail={secondaryContactEmail}
      />
    );
  }

  return (
    <>
      <PostDSOExperienceMessage />
      <Profile
        marketCode={marketCode}
        onEdit={toggleIsEditInProgress}
        projectId={renovationPlanId}
        projectType={projectType}
        serviceableStatus={serviceableStatus}
        userId={userId}
        {...userData}
      />
      {renovationPlanId && (
        <>
          <StatusBreakGround renovationPlanId={renovationPlanId} />
          <SidebarElement>
            <ProfileLinks renovationPlanId={renovationPlanId} userId={userId} />
          </SidebarElement>
        </>
      )}
    </>
  );
};
