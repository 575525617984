const getProjectPath = (projectId = ':projectId') => `/projects/${projectId}`;

const getProjectChangeOrdersPath = (projectId = ':projectId') =>
  `/projects/${projectId}/change-orders`;

const getProjectTeamMembersPath = (projectId = ':projectId') =>
  `/projects/${projectId}/team-members`;

const getProjectContractorDocumentsPath = (projectId = ':projectId') =>
  `/projects/${projectId}/contractor-documents`;

const getProjectOverviewPath = (proposalId = ':proposalId') =>
  `/proposals/${proposalId}/project-overview`;

const getProjectProcurementPath = (projectId = ':projectId') =>
  `/projects/${projectId}/procurement-beta`;

const getProjectProcurementMaterialsPath = (projectId = ':projectId') =>
  `/projects/${projectId}/procurement-beta/materials`;

const getProjectProcurementOrdersPath = (projectId = ':projectId') =>
  `/projects/${projectId}/procurement-beta/orders`;

const getProjectProcurementShipmentsPath = (projectId = ':projectId') =>
  `/projects/${projectId}/procurement-beta/shipments`;

const getProjectProcurementWarehousePath = (projectId = ':projectId') =>
  `/projects/${projectId}/procurement-beta/warehouse`;

const getProjectProcurementCreateOrderPath = (projectId = ':projectId') =>
  `/projects/${projectId}/procurement-beta/orders/new`;

const getProjectProcurementEditOrderPath = (
  projectId = ':projectId',
  orderId = ':orderId',
) => `/projects/${projectId}/procurement-beta/orders/${orderId}`;

const getProjectProcurementEditMaterialsLeadTimePath = (
  projectId = ':projectId',
) => `/projects/${projectId}/procurement-beta/materials-lead-time-edit`;

const getProjectProcurementLinksPath = (projectId = ':projectId') =>
  `/projects/${projectId}/procurement`;

const getProjectPhotosAndVideosPath = (projectId = ':projectId') =>
  `/projects/${projectId}/photos-videos`;

const getProjectScopeOfWorkPath = (projectId = ':projectId') =>
  `/projects/${projectId}/scope-of-work`;

const getProjectWorkOrderPath = (projectId = ':projectId') =>
  `/projects/${projectId}/work-order`;

const getProjectDesignPath = (projectId = ':projectId') =>
  `/projects/${projectId}/design`;

const getProjectBuildingAndApprovalsPath = (projectId = ':projectId') =>
  `/projects/${projectId}/permitting-approvals`;

const getProjectSchedulePath = (projectId = ':projectId') =>
  `/projects/${projectId}/schedule`;

const getProjectPerformancePath = (projectId = ':projectId') =>
  `/projects/${projectId}/performance`;

const getProjectProfilePath = (projectId = ':projectId') =>
  `/projects/${projectId}/profile`;

const getProjectProfileInformationPath = (projectId = ':projectId') =>
  `/projects/${projectId}/profile/project-information`;

const getProjectProfileChangeLogPath = (projectId = ':projectId') =>
  `/projects/${projectId}/profile/change-log`;

const getProjectProfileBuildingInformationPath = (projectId = ':projectId') =>
  `/projects/${projectId}/profile/building-information`;

const getProjectProfilePreDesignPath = (projectId = ':projectId') =>
  `/projects/${projectId}/profile/pre-design`;

const getProjectProfileNotesPath = (projectId = ':projectId') =>
  `/projects/${projectId}/profile/notes`;

const getProjectProfileInternalNotesPath = (projectId = ':projectId') =>
  `/projects/${projectId}/profile/internal-notes`;

const getProjectScopeRevisionPath = (projectId = ':projectId') =>
  `/projects/${projectId}/scope-revision`;

const getProjectScopeRevisionLaborMaterialsPath = (projectId = ':projectId') =>
  `/projects/${projectId}/scope-revision/labor-materials`;

const getProjectScopeRevisionPermitsPath = (projectId = ':projectId') =>
  `/projects/${projectId}/scope-revision/permits`;

const getProjectScopeRevisionRoomsPath = (projectId = ':projectId') =>
  `/projects/${projectId}/scope-revision/rooms`;

const getProjectScopeRevisionLogPath = (projectId = ':projectId') =>
  `/projects/${projectId}/scope-revision/revision-log`;

const getProjectScopeRevisionDetails = (
  projectId = ':projectId',
  revisionId = ':revisionId',
) => `/projects/${projectId}/scope-revision/revision-log/${revisionId}`;

const getProjectScopeRevisionLaborMaterialsPricingEnginePath = (
  projectId = ':projectId',
  pricingEngineId = ':pricingEngineId?',
) => `/projects/${projectId}/scope-revision/labor-materials/${pricingEngineId}`;

const getProjectRevisionPath = (
  projectId = ':projectId',
  revisionId = ':revisionId',
) => `/projects/${projectId}/revision/${revisionId}`;

const getProjectRevisionLaborMaterialsPath = (
  projectId = ':projectId',
  revisionId = ':revisionId',
) => `/projects/${projectId}/revision/${revisionId}/labor-materials`;

const getProjectRevisionLaborMaterialsPricingEnginePath = (
  projectId = ':projectId',
  revisionId = ':revisionId',
  pricingEngineId = ':pricingEngineId?',
) =>
  `/projects/${projectId}/revision/${revisionId}/labor-materials/${pricingEngineId}`;

const getProjectRevisionPermitsPath = (
  projectId = ':projectId',
  revisionId = ':revisionId',
) => `/projects/${projectId}/revision/${revisionId}/permits`;

const getProjectRevisionRoomsPath = (
  projectId = ':projectId',
  revisionId = ':revisionId',
) => `/projects/${projectId}/revision/${revisionId}/rooms`;

const getProjectRevisionSummaryPath = (
  projectId = ':projectId',
  revisionId = ':revisionId',
) => `/projects/${projectId}/revision/${revisionId}/summary`;

export const PROJECT_PATHS = {
  getProjectBuildingAndApprovalsPath,
  getProjectChangeOrdersPath,
  getProjectContractorDocumentsPath,
  getProjectDesignPath,
  getProjectOverviewPath,
  getProjectPath,
  getProjectPerformancePath,
  getProjectPhotosAndVideosPath,
  getProjectProcurementCreateOrderPath,
  getProjectProcurementEditMaterialsLeadTimePath,
  getProjectProcurementEditOrderPath,
  getProjectProcurementLinksPath,
  getProjectProcurementMaterialsPath,
  getProjectProcurementOrdersPath,
  getProjectProcurementPath,
  getProjectProcurementShipmentsPath,
  getProjectProcurementWarehousePath,
  getProjectRevisionLaborMaterialsPath,
  getProjectRevisionLaborMaterialsPricingEnginePath,
  getProjectRevisionPath,
  getProjectRevisionPermitsPath,
  getProjectRevisionRoomsPath,
  getProjectRevisionSummaryPath,
  getProjectSchedulePath,
  getProjectScopeOfWorkPath,
  getProjectScopeRevisionDetails,
  getProjectScopeRevisionLaborMaterialsPath,
  getProjectScopeRevisionLaborMaterialsPricingEnginePath,
  getProjectScopeRevisionLogPath,
  getProjectScopeRevisionPath,
  getProjectScopeRevisionPermitsPath,
  getProjectScopeRevisionRoomsPath,
  getProjectTeamMembersPath,
  getProjectWorkOrderPath,
};

export const PROJECT_PROFILE_PATHS = {
  getProjectProfileBuildingInformationPath,
  getProjectProfileChangeLogPath,
  getProjectProfileInformationPath,
  getProjectProfileInternalNotesPath,
  getProjectProfileNotesPath,
  getProjectProfilePath,
  getProjectProfilePreDesignPath,
};
