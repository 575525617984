import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useGetProjectPlanQuestions } from 'ProjectProfile/common/hooks/useProjectPlanQuestions';
import { normalizeProjectPlanQuestions } from 'ProjectProfile/common/utils/normalizeProjectPlanQuestions';
import { ProjectPlanQuestions } from 'common/apollo/models/projectPlanQuestion';
import { Importances } from 'ProjectProfile/common/types/importance';
import { getImportances } from 'ProjectProfile/common/utils/questionAnswer';
import { UpdateProjectPlanQuestionsResponse } from 'ProjectProfile/common/responses/updateProjectPlanQuestionsResponse';

import { useToggle } from 'common/hooks/useToggle';

import { identifiers } from './preKickoff.consts';

export const usePreKickoff = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { isOn: isOpen, toggleOff: close, toggleOn: open } = useToggle();

  const { data, isLoading } = useGetProjectPlanQuestions({
    identifiers,
    projectId,
  });

  const answers = normalizeProjectPlanQuestions(data);

  const [projectPlanQuestions, setProjectPlanQuestions] =
    useState<ProjectPlanQuestions[]>(data);

  const [importances, setImportances] = useState<Importances>(
    getImportances(identifiers, projectPlanQuestions),
  );

  useEffect(() => {
    if (data.length) {
      setProjectPlanQuestions(data);
    }
  }, [data]);

  useEffect(() => {
    setImportances(getImportances(identifiers, projectPlanQuestions));
  }, [projectPlanQuestions, isLoading]);

  const handleSuccessfulSubmit = (
    responseData: UpdateProjectPlanQuestionsResponse,
  ) => {
    setProjectPlanQuestions(responseData.projectPlanQuestionsUpdate);
    close();
  };

  return {
    answers,
    close,
    data,
    handleSuccessfulSubmit,
    importances,
    isLoading,
    isOpen,
    open,
  };
};
