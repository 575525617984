import { ChangeEvent, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useToggle } from 'common/hooks/useToggle';

import { useOrderTableAction } from 'Project/Procurement/common/hooks/useOrderTableAction';

import { OrderMaterial } from 'Project/Procurement/common/types/orderMaterial';

export const useOrderMaterials = (materials: OrderMaterial[]) => {
  const { projectId } = useParams<{ projectId: string }>();

  const [selectedMaterial, setSelectedMaterial] =
    useState<OrderMaterial | null>(null);

  const [selectedMaterialIds, setSelectedMaterialIds] = useState<string[]>([]);

  const { handleCopySkuInfo, handleCopyTable } = useOrderTableAction(materials);

  const {
    isOn: isNewShipmentModalOpen,
    toggleOff: handleCloseNewShipmentModal,
    toggleOn: handleOpenNewShipmentModal,
  } = useToggle();

  const {
    isOn: isOrderMaterialUnitsUpdateModalOpen,
    toggleOff: closeOrderMaterialUnitsUpdateModal,
    toggleOn: openOrderMaterialUnitsUpdateModal,
  } = useToggle();

  const {
    isOn: isOrderMaterialDeleteModalOpen,
    toggleOff: closeOrderMaterialDeleteModal,
    toggleOn: openOrderMaterialDeleteModal,
  } = useToggle();

  const isMaterialSelected = (id: string) => !!selectedMaterialIds.includes(id);

  const isAllMaterialsSelected = materials.every((material) =>
    selectedMaterialIds.includes(material.id),
  );

  const handleSelectAllMaterials = () =>
    setSelectedMaterialIds(
      isAllMaterialsSelected ? [] : materials.map(({ id }) => id),
    );

  const handleSelectMaterial = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target;

    if (!checked) {
      setSelectedMaterialIds((prev) => prev.filter((item) => item !== id));

      return;
    }

    setSelectedMaterialIds((prev) => [...prev, id]);
  };

  const hasSelectedMaterials = !!selectedMaterialIds.length;

  const selectedMaterials = materials.filter(({ id }) =>
    selectedMaterialIds.includes(id),
  );

  const handleOpenUpdateMaterialModal = (id: string) => {
    const material = materials.find((item) => item.id === id);

    if (!material) return;

    setSelectedMaterial(material);
    openOrderMaterialUnitsUpdateModal();
  };

  const handleCloseUpdateMaterialModal = () => {
    setSelectedMaterial(null);
    closeOrderMaterialUnitsUpdateModal();
  };

  const handleOpenDeleteMaterialModal = (id: string) => {
    const material = materials.find((item) => item.id === id);

    if (!material) return;

    setSelectedMaterial(material);
    openOrderMaterialDeleteModal();
  };

  const handleCloseDeleteMaterialModal = () => {
    setSelectedMaterial(null);
    closeOrderMaterialDeleteModal();
  };

  return {
    handleCloseDeleteMaterialModal,
    handleCloseNewShipmentModal,
    handleCloseUpdateMaterialModal,
    handleCopySkuInfo,
    handleCopyTable,
    handleOpenDeleteMaterialModal,
    handleOpenNewShipmentModal,
    handleOpenUpdateMaterialModal,
    handleSelectAllMaterials,
    handleSelectMaterial,
    hasSelectedMaterials,
    isAllMaterialsSelected,
    isMaterialSelected,
    isNewShipmentModalOpen,
    isOrderMaterialDeleteModalOpen,
    isOrderMaterialUnitsUpdateModalOpen,
    projectId,
    selectedMaterial,
    selectedMaterials,
  };
};
