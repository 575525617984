import { useFormikContext } from 'formik';

import { FormEvent, useEffect } from 'react';

import { ApolloError, useMutation, useQuery } from '@apollo/client';

import { TargetDateShiftResponse } from 'common/apollo/responses/targetDateShiftResponse';
import { TargetDateShiftRequest } from 'common/apollo/requests/targetDateShiftRequest';
import { GET_ADMIN_KEY_DATES } from 'common/apollo/queries/getAdminKeyDates.graphql';
import { KeyDateType } from 'common/apollo/enums/KeyDateType';

import { calculateDaysLate } from 'common/utils/date';
import { useAlert } from 'common/hooks/useAlert';
import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';

import { TARGET_DATE_SHIFT } from 'ClientTasks/TargetDate/ShiftTargetDateModal/shiftTargetDateModal.graphql';
import { MATERIALS_LONGEST_LEAD_TIME_UPDATE_DIALOG } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeUpdate/materialsLongestLeadTimeUpdate.consts';
import { GetAdminKeyDatesResponse } from 'common/apollo/responses/getAdminKeyDatesResponse';
import { GetAdminConsumerKeyDatesRequest } from 'common/apollo/requests/getAdminConsumerKeyDatesRequest';
import { KeyDateSource } from 'common/apollo/enums/KeyDateSource';
import { useProjectId } from 'MaterialsLongestLeadTime/common/hooks/useProjectId';

type FromValues = {
  date: Date;
  days: number;
  notes: string;
};

export const useMaterialsLongestLeadTimeUpdateForm = () => {
  const setModalOpen = useSetModalOpen();
  const projectId = useProjectId();

  const { data: adminKeyDatesData, loading: adminKeyDatesLoading } = useQuery<
    GetAdminKeyDatesResponse,
    GetAdminConsumerKeyDatesRequest
  >(GET_ADMIN_KEY_DATES, {
    skip: !projectId,
    variables: {
      input: {
        renovationPlanId: projectId || '',
      },
    },
  });

  const lltLatestDate = adminKeyDatesData?.adminKeyDates.find(
    (adminKeyDate) =>
      adminKeyDate.type === KeyDateType.LONGEST_LEAD_TIME &&
      adminKeyDate.source === KeyDateSource.LATEST,
  );

  const { onError } = useAlert();
  const {
    dirty,
    isValid,
    setFieldValue,
    touched,
    values: { date, days, notes },
  } = useFormikContext<FromValues>();

  const [targetDateShift] = useMutation<
    TargetDateShiftResponse,
    TargetDateShiftRequest
  >(TARGET_DATE_SHIFT, {
    onError: (error: ApolloError) => onError(error.message),
    refetchQueries: [
      {
        query: GET_ADMIN_KEY_DATES,
        variables: {
          input: {
            renovationPlanId: projectId || '',
          },
        },
      },
    ],
  });

  useEffect(() => {
    if (lltLatestDate?.value) {
      const daysDiff = -1 * calculateDaysLate(`${lltLatestDate?.value}`);
      setFieldValue('days', daysDiff);
      setFieldValue('date', lltLatestDate?.value);
    }
  }, [lltLatestDate?.value, setFieldValue]);

  useEffect(() => {
    if (touched.date) {
      const daysDiff = -1 * calculateDaysLate(`${date}`);
      setFieldValue('days', daysDiff);
    }
  }, [date, setFieldValue, touched.date]);

  useEffect(() => {
    const dateToday = new Date();

    if (dirty) {
      dateToday.setDate(dateToday.getDate() + days);
      setFieldValue('date', dateToday);
    }
  }, [days, setFieldValue, dirty]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const input = {
      keyDateId: lltLatestDate?.id || '',
      newDate: date,
      reasonNote: notes,
    };

    await targetDateShift({
      variables: {
        input,
      },
    });

    setModalOpen(MATERIALS_LONGEST_LEAD_TIME_UPDATE_DIALOG, false);
  };

  const disableSubmitButton = !isValid || !dirty;

  return {
    disableSubmitButton,
    handleSubmit,
    isLoading: adminKeyDatesLoading,
  };
};
