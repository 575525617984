import { object, number } from 'yup';

import {
  POSITIVE_NUMBER,
  REQUIRED_VALIDATION,
} from 'common/consts/validationMessages';

export const validationSchema = object().shape({
  amount: number().required(REQUIRED_VALIDATION).min(1, POSITIVE_NUMBER),
});
