import { SkuVendorStockStatus } from 'common/apollo/enums/SkuVendorStockStatus';

export const STOCK_STATUS_DICTIONARY = {
  [SkuVendorStockStatus.DISCONTINUED]: 'Discontinued',
  [SkuVendorStockStatus.IN_STOCK]: 'In Stock',
  [SkuVendorStockStatus.ON_BACK_ORDER]: 'Backordered',
  [SkuVendorStockStatus.INVENTORIED]: 'Inventoried',
};

export const STOCK_STATUS_OPTIONS = [
  {
    label: STOCK_STATUS_DICTIONARY[SkuVendorStockStatus.IN_STOCK],
    value: SkuVendorStockStatus.IN_STOCK,
  },
  {
    label: STOCK_STATUS_DICTIONARY[SkuVendorStockStatus.ON_BACK_ORDER],
    value: SkuVendorStockStatus.ON_BACK_ORDER,
  },
  {
    label: STOCK_STATUS_DICTIONARY[SkuVendorStockStatus.DISCONTINUED],
    value: SkuVendorStockStatus.DISCONTINUED,
  },
  {
    label: STOCK_STATUS_DICTIONARY[SkuVendorStockStatus.INVENTORIED],
    value: SkuVendorStockStatus.INVENTORIED,
  },
];
