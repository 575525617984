import ShareIcon from '@material-ui/icons/Share';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';

import { Tooltip } from 'common/components/Tooltip/Tooltip';

import { THEME } from 'common/consts/theme';

import { useCopy } from 'common/hooks/useCopy';

import { ShareTooltip } from 'ProjectProfile/common/components/ShareButton/shareButton.styles';

type Props = {
  shareUrl?: string;
};

export const ShareButton = ({ shareUrl = '' }: Props) => {
  const { didCopy, handleCopy } = useCopy({
    value: shareUrl || window.location.href,
  });

  return (
    <Tooltip
      arrow={false}
      backgroundColor={THEME.color.white}
      color={THEME.color.black}
      content={
        didCopy ? (
          <ShareTooltip>
            <CheckIcon />
            Link copied
          </ShareTooltip>
        ) : (
          <ShareTooltip>
            <ShareIcon /> Copy link to this section
          </ShareTooltip>
        )
      }
      placement="top"
      useShadow
    >
      <IconButton onClick={handleCopy}>
        <ShareIcon />
      </IconButton>
    </Tooltip>
  );
};
