import { ContactRelationship } from 'common/apollo/enums/ContactRelationship';

export const EMAIL_VALIDATION = 'Email format required';
export const DUPLICATE_SECONDARY_EMAIL =
  'Duplicative secondary contact email, please enter a unique email';

export const selectOptions = [
  { label: 'Child', value: ContactRelationship.CHILD },
  { label: 'Parent', value: ContactRelationship.PARENT },
  { label: 'Partner', value: ContactRelationship.PARTNER },
  { label: 'Spouse', value: ContactRelationship.SPOUSE },
  { label: 'Other', value: ContactRelationship.OTHER },
];
