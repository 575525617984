import * as React from 'react';

import { HorizontalAlignment, VerticalAlignment } from 'common/enums/Alignment';
import { CellType } from 'common/enums/GridCell';

import { Trow, RowProps } from './GridTable.styles';

import { useGridTableProps } from './GridTable.hooks';

export type Props = RowProps & {
  cellType?: CellType;
  children: React.ReactNode;
  className?: string;
  hAlignment?: HorizontalAlignment;
  tabIndex?: number;
  vAlignment?: VerticalAlignment;
};

export const GridTableRow = ({ children, className, ...rest }: Props) => {
  const { columnsWidth } = useGridTableProps();

  return (
    <Trow
      {...rest}
      className={className}
      columnsWidth={columnsWidth}
      role="row"
    >
      {children}
    </Trow>
  );
};

export const GridTableFocusableRow = (props: Props) => (
  <GridTableRow {...props} tabIndex={0} />
);
