import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';
import { ProjectPlanRoomsQuestionsRoomUpdateInput } from 'common/apollo/requests/updateProjectPlanQuestionsRequest';

import { DesignerNotesEditFormResponse } from './designerNotesEdit.types';

export const mapFormValuesToResponses = (
  formValues: DesignerNotesEditFormResponse,
): ProjectPlanRoomsQuestionsRoomUpdateInput[] =>
  formValues.designerNotesEdit.map(({ pricingEngineId, value }) => ({
    openQuestions: [
      {
        identifier: PropertyGroupIdentifier.DESIGNER_NOTES,
        value,
      },
    ],
    pricingEngineId,
  }));
