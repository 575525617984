import styled from '@emotion/styled';
import { css } from '@emotion/react';
import MUiCollapse from '@material-ui/core/Collapse';

import { Button } from 'common/components/Button/Button';
import { CircleIcon } from 'common/components/Icons/Icons';

export const Wrapper = styled.div`
  width: 100%;
`;

export const FilterCollapse = styled(MUiCollapse)`
  left: 0;
  position: relative;
  right: 0;
  top: 0;
  z-index: 2;
`;

export const FilterItem = styled.li`
  border-top: 1px solid ${({ theme }) => theme.color.gray6};
  color: ${({ theme }) => theme.color.gray12};
  display: flex;
  flex: 1;
  font: 400 16px/24px ${({ theme }) => theme.font.inter};
  justify-content: space-between;

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray6};
  }

  &:hover {
    background: ${({ theme }) => theme.color.gray5};
  }
`;

export const FilterButton = styled(Button)<{ isSelected?: boolean }>`
  background-color: ${({ theme }) => theme.color.white};
  border: none;
  border-radius: 0;
  cursor: pointer;
  font: 400 16px/24px ${({ theme }) => theme.font.inter};
  justify-content: space-between;
  padding: 24px;
  width: 100%;

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background: ${theme.color.gray2};
    `}
`;

export const AttributeItem = styled.li`
  cursor: pointer;
  padding: 0 8px;
  width: 100%;

  &:hover {
    background: ${({ theme }) => theme.color.gray5};
  }
`;

export const FilterCounter = styled(CircleIcon)`
  font-weight: 500;
  margin-left: 10px;
`;

export const Clear = styled.span`
  ${({ theme }) => theme.typography.header6}

  color: ${({ theme }) => theme.color.gray12};
  margin-right: 12px;
  padding: 2px 4px;
  z-index: 3;
`;

export const FilterTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const CheckboxSearchWrapper = styled.div`
  margin-left: auto;
  min-width: 30%;
  padding: 8px 20px 8px 8px;
`;
