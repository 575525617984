import { useCallback } from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'common/images/logo.svg';

import { SidebarHeader } from 'common/components/Sidebar/SidebarHeader';

import {
  useLayoutState,
  useLayoutActions,
} from 'common/components/Layout/layoutProvider.hooks';

import { Sidebar } from 'common/components/Sidebar/Sidebar';

import { useMatchMedia } from 'common/hooks/useMatchMedia';

import { BASE_PATH } from 'common/routes/routerPaths';

import { routeHaveSubSidebar } from 'common/components/Layout/layout.utils';

import { NavigationMenu } from '../Navigation/NavigationMenu/NavigationMenu';

import { LayoutSidebarToggle } from './LayoutSidebarToggle/LayoutSidebarToggle';
import { LayoutSubSidebar } from './LayoutSubSidebar';
import { VARIANT_TYPE } from '../Sidebar/sidebar.types';

export const LayoutSidebar = () => {
  const isLargeScreen = useMatchMedia();
  const { isSidebarOpen, isSidebarPresent, isSubSidebarPresent, pathname } =
    useLayoutState();

  const { setSidebarOpen } = useLayoutActions();

  const handleOpenToggle = useCallback(
    () => setSidebarOpen((open) => !open),
    [setSidebarOpen],
  );

  const handleCloseSidebar = useCallback(() => {
    if (!isSidebarOpen || isLargeScreen) return;

    setSidebarOpen(false);
  }, [isLargeScreen, isSidebarOpen, setSidebarOpen]);

  const isNavigationMenuVisible = isSidebarPresent && !isSubSidebarPresent;

  const isSubSidebarVisible = routeHaveSubSidebar(pathname) && isSidebarPresent;

  if (!isSidebarPresent) return null;

  return (
    <Sidebar
      onClose={handleCloseSidebar}
      onOpenToggle={handleOpenToggle}
      open={isSidebarOpen}
      variant={isLargeScreen ? VARIANT_TYPE.permanent : VARIANT_TYPE.temporary}
    >
      <>
        <SidebarHeader>
          <Link to={BASE_PATH}>
            <Logo />
          </Link>
        </SidebarHeader>
        {isSubSidebarVisible && <LayoutSidebarToggle pathname={pathname} />}
        {isNavigationMenuVisible && <NavigationMenu />}
        {isSubSidebarVisible && <LayoutSubSidebar />}
      </>
    </Sidebar>
  );
};
