const sendEvent = (eventName: string, data: any = {}) => {
  window.analytics.track(eventName, {
    ...data,
    url: window.location.href,
  });
};

const identifyUser = (userId: string, data: any = {}) => {
  window.analytics.identify(userId, {
    ...data,
  });
};

const pageEvent = (data: any = {}) => {
  window.analytics.page({
    ...data,
  });
};

export { sendEvent, identifyUser, pageEvent };
