import { useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { Proposal } from 'common/models/proposal';

import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';
import { useGetUser } from 'common/hooks/user.hooks';

import { Identifiable } from 'common/types/identifiable';

import { mapToProposal } from 'common/mappers/toProposal';

import { ProposalRouteParams } from 'common/routes/paths/proposalPaths';

import {
  ProposalsListGet,
  ProposalsListGetVariables,
} from 'Proposals/__generated__/proposals.graphql.generated';

import { AUTH_PATHS } from 'common/routes/routerPaths';

import { ProposalType } from 'common/apollo/enums/ProposalType';

import { ProposalStatus } from 'common/apollo/enums/ProposalStatus';

import { FeatureFlags } from 'common/enums/FeatureFlags';

import { useAlert } from 'common/hooks/useAlert';

import {
  GetContractorChoiceProposalsStatusRequest,
  GetContractorChoiceProposalsStatusResponse,
  SetContractorChoiceProposalsStatusRequest,
  SetContractorChoiceProposalsStatusResponse,
} from 'Proposals/proposal.types';

import {
  PROPOSAL_CREATE_DIALOG_ID,
  PROPOSAL_DELETE_DIALOG_ID,
  PROPOSAL_DUPLICATE_DIALOG_ID,
  PROPOSAL_UNPUBLISH_DIALOG_ID,
  PROPOSAL_VISIBILITY_CONFIRMATION_DIALOG_ID,
} from './proposals.consts';

import {
  GET_PROPOSALS_STATUS,
  SET_PROJECT_PROPOSALS_STATUS,
  USER_PROPOSALS,
} from './proposals.graphql';

export const useProposalsFetch = (renovationPlanId: string) => {
  const { data, error, loading, refetch } = useQuery<
    ProposalsListGet,
    ProposalsListGetVariables
  >(USER_PROPOSALS, {
    fetchPolicy: 'cache-and-network',
    skip: !renovationPlanId,
    variables: {
      renovationPlanId,
    },
  });

  const proposals = (data?.proposals || [])
    .map(mapToProposal)
    .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));

  const isLoading = !proposals && loading;

  return {
    error,
    isLoading,
    proposals,
    refetch,
  };
};

const useProposalModalActions = () => {
  const [proposal, setProposal] = useState<Proposal | undefined>();

  const setModalState = useSetModalOpen();

  const handleOpenDuplicateDialog = (value: Proposal) => () => {
    setProposal(value);
    setModalState(PROPOSAL_DUPLICATE_DIALOG_ID, true);
  };

  const handleOpenRemoveDialog = (value: Proposal) => () => {
    setProposal(value);
    setModalState(PROPOSAL_DELETE_DIALOG_ID, true);
  };

  const handleOpenUnpublishDialog = (value: Proposal) => () => {
    setProposal(value);
    setModalState(PROPOSAL_UNPUBLISH_DIALOG_ID, true);
  };

  const handleOpenCreateDialog = () => {
    setProposal(undefined);
    setModalState(PROPOSAL_CREATE_DIALOG_ID, true);
  };

  return {
    handleOpenCreateDialog,
    handleOpenDuplicateDialog,
    handleOpenRemoveDialog,
    handleOpenUnpublishDialog,
    proposal,
  };
};

export const useProposals = () => {
  const { onCompleted, onError } = useAlert();
  const openDialog = useSetModalOpen();
  const { renovationPlanId, userId } = useParams<ProposalRouteParams>();

  const {
    loadingUser,
    user: { firstName, lastName },
  } = useGetUser(userId);

  const { error, isLoading, proposals } = useProposalsFetch(renovationPlanId);
  const { data: contractorChoiceProposalData } = useQuery<
    GetContractorChoiceProposalsStatusResponse,
    GetContractorChoiceProposalsStatusRequest
  >(GET_PROPOSALS_STATUS, {
    variables: {
      id: renovationPlanId,
    },
  });

  const isProposalsReadyEnabled =
    !!contractorChoiceProposalData?.project.contractorChoiceProposalsReady;

  const [setProposalsVisibilityStatus] = useMutation<
    SetContractorChoiceProposalsStatusResponse,
    SetContractorChoiceProposalsStatusRequest
  >(SET_PROJECT_PROPOSALS_STATUS, {
    awaitRefetchQueries: true,
    onCompleted: () => onCompleted('Proposals status updated'),
    onError: () => onError('Failed to update proposals status'),
    refetchQueries: () => [
      {
        query: GET_PROPOSALS_STATUS,
        variables: {
          id: renovationPlanId,
        },
      },
    ],
  });

  const handleOpenConfirmationModal = () => {
    openDialog(PROPOSAL_VISIBILITY_CONFIRMATION_DIALOG_ID, true);
  };

  const handleCloseConfirmationModal = () => {
    openDialog(PROPOSAL_VISIBILITY_CONFIRMATION_DIALOG_ID, false);
  };

  const handleToogleProposalVisibilityStatus = () => {
    setProposalsVisibilityStatus({
      variables: {
        input: {
          contractorChoiceProposalsReady: !isProposalsReadyEnabled,
          projectId: renovationPlanId,
        },
      },
    });
  };

  const {
    handleOpenCreateDialog,
    handleOpenDuplicateDialog,
    handleOpenRemoveDialog,
    handleOpenUnpublishDialog,
    proposal,
  } = useProposalModalActions();

  const pageTitle = `Users / ${firstName} ${lastName} / Proposals`;

  const flags = useFlags();
  const postDSOFlag = flags[FeatureFlags.POST_DSO];

  const acceptedDesignContractorProposals = proposals.some(
    (prop) =>
      (prop.type === ProposalType.DESIGN ||
        prop.type === ProposalType.CONTRACTOR) &&
      prop.status === ProposalStatus.ACCEPTED,
  );

  const showScopeRevisionBanner =
    acceptedDesignContractorProposals && postDSOFlag;
  const showProposalsContextMenus = !(
    acceptedDesignContractorProposals && postDSOFlag
  );

  return {
    error,
    handleCloseConfirmationModal,
    handleOpenConfirmationModal,
    handleOpenCreateDialog,
    handleOpenDuplicateDialog,
    handleOpenRemoveDialog,
    handleOpenUnpublishDialog,
    handleToogleProposalVisibilityStatus,
    isLoading,
    isProposalsReadyEnabled,
    loadingUser,
    pageTitle,
    proposal,
    proposals,
    showProposalsContextMenus,
    showScopeRevisionBanner,
  };
};

export const useProposalRedirect = () => {
  const history = useHistory();
  const { renovationPlanId, userId } = useParams<ProposalRouteParams>();

  const redirectToProposal = ({
    pricingEngines,
    proposalId,
  }: {
    pricingEngines: Identifiable[];
    proposalId: string;
  }) => {
    const hasPricingEngines = !!pricingEngines.length;

    const pricingEngineEntryPath =
      AUTH_PATHS.getPricingEngineEntryPath(proposalId);

    const toProposalPath = AUTH_PATHS.getProposalDetailsPath({
      proposalId,
      renovationPlanId,
      userId,
    });

    history.push(hasPricingEngines ? toProposalPath : pricingEngineEntryPath);
  };

  return { redirectToProposal };
};
