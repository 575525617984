import styled from '@emotion/styled';
import { css } from '@emotion/react';

export type ColorVariants =
  | 'gray11'
  | 'orange11'
  | 'yellow11'
  | 'green12'
  | 'blue11';

type TextProps = {
  colorVariant: ColorVariants;
};

export const Wrapper = styled.div`
  margin: 12px 0 24px 0;
`;

export const BadgeWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Text = styled.p<TextProps>`
  ${({ colorVariant, theme }) => css`
    color: ${theme.color[colorVariant]};
    font: ${theme.typography.body1};
    font-size: 12px;
  `}
`;
