import { ReactNode } from 'react';

import { useLayoutState } from 'common/components/Layout/layoutProvider.hooks';

import { Content } from './LayoutContent.styles';

type Props = {
  children: ReactNode;
  hideSidebar?: boolean;
};

export const LayoutContent = ({ children, hideSidebar = false }: Props) => {
  const { isSidebarOpen, isSidebarPresent } = useLayoutState();

  return (
    <Content
      hasMarginLeft={isSidebarPresent}
      hasMarginLeftLarge={isSidebarOpen}
      hasNoMargin={hideSidebar}
    >
      {children}
    </Content>
  );
};
