import styled from '@emotion/styled';
import { css } from '@emotion/react';

import MUiCollapse from '@material-ui/core/Collapse';

import isPropValid from '@emotion/is-prop-valid';

import { mq } from 'common/utils/mediaQueries';

export const Wrapper = styled.div``;

export const WrapperInner = styled.div`
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray6};
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow:
    0 16px 24px rgba(0, 0, 0, 0.06),
    0 2px 6px rgba(0, 0, 0, 0.04),
    0 0 1px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  overflow: auto;

  ${mq.medium(css`
    max-height: 350px;
  `)};
`;

export const Collapse = styled(MUiCollapse, {
  shouldForwardProp: isPropValid,
})<{
  buttonWidth: number;
  left: number;
  rightAligned?: boolean;
  top: number;
}>`
  left: ${({ buttonWidth, left, rightAligned }) =>
    !rightAligned ? `${left}px` : `${left + buttonWidth - 384}px`};
  position: absolute;
  top: ${({ top }) => `${top}px`};
  z-index: 6;
  width: 384px;
`;

export const List = styled.ul`
  list-style: none;
  flex: 1;
`;

export const ListItem = styled.li<{ isSelected?: boolean }>`
  color: ${({ theme }) => theme.color.gray12};
  cursor: pointer;
  display: flex;
  font: 400 16px/24px ${({ theme }) => theme.font.inter};
  justify-content: space-between;
  padding: 16px 24px;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.color.gray6};
  }

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray6};
  }

  &:hover {
    background: ${({ theme }) => theme.color.gray5};
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background: ${theme.color.gray5};
    `}
`;

export const ListTitle = styled.li`
  color: ${({ theme }) => theme.color.gray12};
  display: flex;
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
  justify-content: space-between;
  padding: 16px 24px;
`;

export const ClearAllFilters = styled.span`
  ${({ theme }) => theme.typography.header6}

  color: ${({ theme }) => theme.color.orange12};
  cursor: pointer;
`;
