import styled from '@emotion/styled';

export const UnitsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
`;

export const UnitsValue = styled.div`
  display: flex;
  flex-direction: column;
  font: 400 14px/24px ${({ theme }) => theme.font.inter};
  white-space: nowrap;
`;

export const UnitsIndicator = styled.span<{ solidBackground?: boolean }>`
  background: ${({ solidBackground, theme }) =>
    solidBackground ? theme.color.green : theme.color.yellow};
  border-radius: 50%;
  border: ${({ solidBackground, theme }) =>
    `1px solid ${
      solidBackground ? theme.color.darkGreen : theme.color.darkYellow
    }`};
  height: 12px;
  width: 12px;
`;
