/* eslint-disable typescript-sort-keys/string-enum */
export enum PropertyGroupIdentifier {
  AVAILABILITY_OTHER_COMMENTS = 'AVAILABILITY_OTHER_COMMENTS',
  AVAILABILITY_TIME_OF_DAY = 'AVAILABILITY_TIME_OF_DAY',
  DESIGN_PACKAGE = 'DESIGN_PACKAGE',
  ROOM_TYPE = 'ROOM_TYPE',
  OTHER_ROOM_SPACE = 'OTHER_ROOM_SPACE',

  // ROOM CLOSED QUESTIONS
  BATHROOM_LAYOUT = 'BATHROOM_LAYOUT',
  FUTURE_ROOM_LAYOUT = 'FUTURE_ROOM_LAYOUT',
  MAJOR_LAYOUT_CHANGES = 'MAJOR_LAYOUT_CHANGES',

  // ROOM OPEN QUESTIONS
  DESIGNER_NOTES = 'DESIGNER_NOTES',

  // PROJECT OPEN QUESTIONS
  ALTERATION_AGREEMENT_HIGHLIGHTS = 'ALTERATION_AGREEMENT_HIGHLIGHTS',
  BEFORE_DESIGN = 'BEFORE_DESIGN',
  BUDGET_AND_PRICING_EXPECTATIONS = 'BUDGET_AND_PRICING_EXPECTATIONS',
  BUILDING_INFORMATION_NOTES = 'BUILDING_INFORMATION_NOTES',
  CLIENT_NOTES = 'CLIENT_NOTES',
  DESIGN_OPS_NOTES = 'DESIGN_OPS_NOTES',
  CONCEPT_BOARD_NOTES = 'CONCEPT_BOARD_NOTES',
  DECISION_MAKERS = 'DECISION_MAKERS',
  EXCEPTIONS = 'EXCEPTIONS',
  IMPORTANT_PROJECT_CALL_OUTS = 'IMPORTANT_PROJECT_CALL_OUTS',
  LIST_OF_ALL_TO_VIF = 'LIST_OF_ALL_TO_VIF',
  MATERIAL_PRICE_NOTES = 'MATERIAL_PRICE_NOTES',
  NEEDS_VS_NICE_TO_HAVES = 'NEEDS_VS_NICE_TO_HAVES',
  POST_SITE_SURVEY_NOTES = 'POST_SITE_SURVEY_NOTES',
  PREVIOUS_RENOVATION_EXPERIENCE = 'PREVIOUS_RENOVATION_EXPERIENCE',
  PRICING_UPDATES_NOTES = 'PRICING_UPDATES_NOTES',
  PROJECT_EXTENSION_NOTES = 'PROJECT_EXTENSION_NOTES',
  SERVICE_ENTRANCE = 'SERVICE_ENTRANCE',
  SPECIAL_CONDITIONS = 'SPECIAL_CONDITIONS',
  SUPER_CONTACT_INFO = 'SUPER_CONTACT_INFO',
  TIMELINE_PREFERENCES = 'TIMELINE_PREFERENCES',

  // PROJECT CLOSED QUESTIONS
  ALTERATION_AGREEMENT_REQUIRED = 'ALTERATION_AGREEMENT_REQUIRED',
  BUILDING_AGE = 'BUILDING_AGE',
  BUILDING_TYPE = 'BUILDING_TYPE',
  ELECTRICAL_PANEL_LOCATION = 'ELECTRICAL_PANEL_LOCATION',
  FLEXIBILITY = 'FLEXIBILITY',
  INSURANCE = 'INSURANCE',
  INVOLVEMENT = 'INVOLVEMENT',
  IS_SCOPE_VERIFICATION_REQUIRED = 'IS_SCOPE_VERIFICATION_REQUIRED',
  IS_THE_CLIENT_LIVING_THROUGH_THE_RENOVATION = 'IS_THE_CLIENT_LIVING_THROUGH_THE_RENOVATION',
  IS_THERE_SPACE_IN_THE_SUB_E_PANEL_FOR_ADDITIONAL_BREAKERS = 'IS_THERE_SPACE_IN_THE_SUB_E_PANEL_FOR_ADDITIONAL_BREAKERS',
  OWNERSHIP_STATUS = 'OWNERSHIP_STATUS',
  PAYMENT_PREFERENCE = 'PAYMENT_PREFERENCE',
  PHOTO_OF_ELECTRICAL_PANEL = 'PHOTO_OF_ELECTRICAL_PANEL',
  PROJECT_BUDGET = 'PROJECT_BUDGET',
  RENOVATION_TIMELINE = 'RENOVATION_TIMELINE',
  WHAT_IS_TOTAL_AMPERAGE_OF_ELECTRICAL_SERVICE = 'WHAT_IS_TOTAL_AMPERAGE_OF_ELECTRICAL_SERVICE',
  REFERRED_BY = 'REFERRED_BY',
  REFERRAL_CAMPAIGN = 'REFERAL_CAMPAIGN',
  PROJECT_BUDGET_MIN = 'PROJECT_BUDGET_MIN',
  PROJECT_BUDGET_MAX = 'PROJECT_BUDGET_MAX',
}
