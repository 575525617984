import { gql } from '@apollo/client';

export const CREATE_PROJECT_PROCUREMENT_DELIVERY_BATCH = gql`
  mutation DeliveryBatchCreate($input: DeliveryBatchCreateInput!) {
    deliveryBatchCreate(input: $input) {
      id
      eta
      pallet
      confirmedAt
      shippedAt
      receivedAt
      units
    }
  }
`;
