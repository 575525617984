import { useCallback, useMemo } from 'react';

import isEqual from 'lodash-es/isEqual';
import { FetchResult, useMutation, DataProxy } from '@apollo/client';

import {
  RenovationInfoAddressInput,
  UserUpdateInput,
} from '__generated/apolloTypes';

import { UserProfile } from 'common/models/userProfile';

import {
  ProjectAddressUpdate,
  ProjectAddressUpdateVariables,
  UserUpdate,
  UserUpdateVariables,
} from 'common/shared/Sidebar/Profile/__generated__/profile.graphql.generated';

import { useAlert } from 'common/hooks/useAlert';

import { UPDATE_USER, UPDATE_PROJECT_ADDRESS } from './profile.graphql';

type User = Omit<UserUpdateInput, '__typename' | 'id'>;
type Address = Omit<RenovationInfoAddressInput, 'projectId' | '__typename'>;

const useSubmit = (
  userId: string,
  projectId: string,
  onAddressUpdateComplete?: (
    proxy: DataProxy,
    response: FetchResult<ProjectAddressUpdate>,
  ) => void,
) => {
  const { onError } = useAlert();
  const [
    updateAddress,
    { error: errorUpdateAddress, loading: loadingUpdateAddress },
  ] = useMutation<ProjectAddressUpdate, ProjectAddressUpdateVariables>(
    UPDATE_PROJECT_ADDRESS,
    {
      onError: (error) => onError(error.message),
    },
  );

  const [updateUser, { error: errorUpdateUser, loading: loadingUpdateUser }] =
    useMutation<UserUpdate, UserUpdateVariables>(UPDATE_USER, {
      onError: (error) => onError(error.message),
    });

  const loadingSubmit = loadingUpdateAddress || loadingUpdateUser;
  const errorSubmit = errorUpdateAddress || errorUpdateUser;

  const onSubmit = (user?: User, address?: Address) => {
    const promises = [];

    if (user) {
      promises.push(
        updateUser({
          variables: {
            input: { id: userId, ...user },
          },
        }),
      );
    }

    if (address) {
      promises.push(
        updateAddress({
          ...(onAddressUpdateComplete && {
            update: (
              proxy: DataProxy,
              response: FetchResult<ProjectAddressUpdate>,
            ) => onAddressUpdateComplete(proxy, response),
          }),
          variables: {
            input: {
              projectId,
              ...address,
            },
          },
        }),
      );
    }

    return Promise.all<
      | Promise<FetchResult<UserUpdate>>
      | Promise<FetchResult<ProjectAddressUpdate>>
    >(promises);
  };

  return { errorSubmit, loadingSubmit, onSubmit };
};

const useInitialValues = ({
  address,
  user,
}: {
  address: Address;
  user: User;
}) => {
  const userData = useMemo<UserProfile>(
    () => ({ ...user, ...address }),
    [user, address],
  );

  const initialValues = useMemo<UserProfile>(
    () =>
      Object.entries(userData).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value === null ? '' : value,
        }),
        { email: '', firstName: '' },
      ),
    [userData],
  );

  return { initialValues, userData };
};

export const useProfileSidebarSection = ({
  address,
  onAddressUpdateComplete,
  onEditComplete,
  renovationPlanId,
  user,
  userId,
}: {
  address: Address;
  onAddressUpdateComplete?: (
    proxy: DataProxy,
    response: FetchResult<ProjectAddressUpdate>,
  ) => void;
  onEditComplete: () => void;
  renovationPlanId: string;
  user: User;
  userId: string;
}) => {
  const { errorSubmit, loadingSubmit, onSubmit } = useSubmit(
    userId,
    renovationPlanId,
    onAddressUpdateComplete,
  );
  const { initialValues, userData } = useInitialValues({
    address,
    user,
  });

  const onSubmitIfChanged = useCallback(
    async ({
      city,
      email,
      firstName,
      lastName,
      phoneNumber,
      state,
      street,
      street_2,
      zip,
    }: UserProfile) => {
      // eslint-disable-next-line no-shadow
      const updatedUser = {
        email,
        firstName,
        lastName,
        phoneNumber,
      };

      const updatedAddress = {
        city,
        state,
        street,
        street_2,
        zip,
      };

      const userToSubmit =
        (!isEqual(user, updatedUser) && updatedUser) || undefined;
      const addressToSubmit =
        (!isEqual(address, updatedAddress) && updatedAddress) || undefined;

      await onSubmit(userToSubmit, addressToSubmit);
      onEditComplete();
    },
    [user, address], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    errorSubmit,
    initialValues,
    loadingSubmit,
    onSubmit: onSubmitIfChanged,
    userData,
  };
};
