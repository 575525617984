import styled from '@emotion/styled';

import { Button as MuiButton } from '@material-ui/core';
import { Form as FormikForm } from 'formik';
import { css } from '@emotion/react';

import { THEME } from 'common/consts/theme';
import { FormTextField } from 'common/components/Form/TextField/FormTextField';
import { Select as FormSelect } from 'common/components/Form/Select/Select';

import { TargetDateButton } from 'common/components/Button/TargetDateButton';

export const Button = styled(MuiButton)`
  color: black;
`;

export const FormRow = styled.div`
  column-gap: 30px;
  display: flex;
  width: 100%;
`;

export const Field = styled.div`
  flex: 1;
  margin-bottom: 30px;
`;

export const FieldLabel = styled.label`
  color: ${({ theme }) => theme.color.gray12};
  font: 500 14px/20px ${({ theme }) => theme.font.inter};
  margin-bottom: 4px;
`;

export const Form = styled(FormikForm)`
  width: 720px;
`;

export const fieldStyles = css`
  display: block;

  input {
    padding: 12px 12px;
    font: 400 16px/24px ${THEME.font.inter};
  }

  fieldset {
    padding: 16px 8px;
    border: 1px solid ${THEME.color.gray6};
    border-radius: 3px;
    height: 100%;
  }
`;

export const TextField = styled(FormTextField)`
  ${fieldStyles};
`;

export const Select = styled(FormSelect)`
  ${fieldStyles};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

export const GearButton = styled(TargetDateButton)`
  right: unset;
  position: relative;
`;
