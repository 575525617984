import { useQuery } from '@apollo/client';

import {
  getMaterialsLongestLeadTime,
  getMaterialsLongestLeadTimeFormVariant,
} from 'MaterialsLongestLeadTime/materialsLongestLeadTime.utils';
import { GetAdminKeyDatesResponse } from 'common/apollo/responses/getAdminKeyDatesResponse';
import { GetAdminConsumerKeyDatesRequest } from 'common/apollo/requests/getAdminConsumerKeyDatesRequest';
import { GET_ADMIN_KEY_DATES } from 'common/apollo/queries/getAdminKeyDates.graphql';

import { useProjectId } from 'MaterialsLongestLeadTime/common/hooks/useProjectId';
import { useGetTasks } from 'common/hooks/useGetTasks';

export const useMaterialsLongestLeadTime = () => {
  const projectId = useProjectId();

  const { data, loading: isLoading } = useQuery<
    GetAdminKeyDatesResponse,
    GetAdminConsumerKeyDatesRequest
  >(GET_ADMIN_KEY_DATES, {
    skip: !projectId,
    variables: {
      input: {
        renovationPlanId: projectId || '',
      },
    },
  });

  const { isLoading: isTasksLoading, phasesList } = useGetTasks(
    projectId || '',
  );

  const formVariant = getMaterialsLongestLeadTimeFormVariant(data, phasesList);
  const materialsllt = getMaterialsLongestLeadTime(data);

  return {
    formVariant,
    isLoading: isLoading || isTasksLoading,
    materialsllt,
  };
};
