import { KeyDateSource } from 'common/apollo/enums/KeyDateSource';

export const FULL_HISTORY_MODAL = 'FULL_HISTORY_MODAL';

export const KeyDateSourceContext = {
  [KeyDateSource.COMPLETED]: 'completed',
  [KeyDateSource.CONFIRMED]: 'confirmed',
  [KeyDateSource.LATEST]: 'updated',
  [KeyDateSource.ORIGINAL]: 'set',
};
