import { useState, useEffect } from 'react';

import { breakpoints } from 'common/utils/mediaQueries';

export const useMatchMedia = ({
  breakpoint = breakpoints.large,
  mediaQuery = 'min-width',
  unit = 'px',
} = {}) => {
  const [isMatched, setIsMatched] = useState(
    () => !!window.matchMedia(`(${mediaQuery}: ${breakpoint}${unit})`).matches,
  );

  useEffect(() => {
    const query = window.matchMedia(`(${mediaQuery}: ${breakpoint}${unit})`);
    const update = () => setIsMatched(query.matches);

    update();
    query.addListener(update);

    return () => query.removeListener(update);
  }, [mediaQuery, breakpoint, unit]);

  return isMatched;
};
