import { Formik } from 'formik';

import { Dialog } from 'common/components/Modals/Dialog/Dialog';
import { DialogContent } from 'common/components/Modals/Dialog/DialogContent';
import { DialogTitle } from 'common/components/Modals/Dialog/DialogTitle';

import { MaterialsLongestLeadTimeSetForm } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeSet/MaterialsLongestLeadTimeSetForm/MaterialsLongestLeadTimeSetForm';

import {
  MATERIALS_LONGEST_LEAD_TIME_SET_DIALOG,
  MATERIALS_LONGEST_LEAD_TIME_SET_INITIAL_VALUES,
  validationSchema,
} from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeSet/materialsLongestLeadTimeSet.consts';

export const MaterialsLongestLeadTimeSetModal = () => (
  <Dialog id={MATERIALS_LONGEST_LEAD_TIME_SET_DIALOG} maxWidth="md">
    <DialogTitle>Set Materials Longest Lead Time</DialogTitle>
    <DialogContent>
      <Formik
        initialValues={MATERIALS_LONGEST_LEAD_TIME_SET_INITIAL_VALUES}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        <MaterialsLongestLeadTimeSetForm />
      </Formik>
    </DialogContent>
  </Dialog>
);
