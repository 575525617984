import { ReactNode, useState } from 'react';

import { EmptyCopy } from 'ProjectProfile/projectProfile.consts';

import { Button, Content, EmptyValue } from './readMore.styles';

type Props = {
  children: ReactNode;
  emptyValue?: string;
  maxChars?: number;
  suffix?: string;
};

export const ReadMore = ({
  children,
  emptyValue = EmptyCopy.REQUIRED_FIELD,
  maxChars = 256,
  suffix = '...',
}: Props) => {
  const [showMore, setShowMore] = useState(true);

  const content = children?.toString() || '';
  const totalChars = content.length || 0;
  const hasMore = totalChars > maxChars;

  if (!hasMore) {
    return <>{children || <EmptyValue>{emptyValue}</EmptyValue>}</>;
  }

  return (
    <>
      <Content>
        {showMore ? (
          <>
            {content.slice(0, maxChars)}
            {suffix}
          </>
        ) : (
          children
        )}
      </Content>
      <Button onClick={() => setShowMore(!showMore)}>
        Read {showMore ? 'more' : 'less'}
      </Button>
    </>
  );
};
