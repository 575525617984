import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { mq } from 'common/utils/mediaQueries';

export const DeleteButton = styled.button<{ isEditMode?: boolean }>`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.darkGray};
  background: ${({ theme }) => theme.color.white}
    url('/assets/images/delete-small.svg') center center no-repeat;
  cursor: pointer;
  display: ${({ isEditMode }) => (isEditMode ? 'block' : 'none')};
  height: 44px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 44px;

  ${({ theme }) =>
    mq.large(css`
      display: none;
      background: ${theme.color.white} url('/assets/images/close.svg') center
        center no-repeat;
      border-radius: 22px;
      border: 2px solid ${theme.color.white};
      cursor: pointer;
      height: 22px;
      left: initial;
      right: -11px;
      top: -11px;
      transform: unset;
      width: 22px;
    `)}
`;

export const Wrapper = styled.div`
  height: 80px;
  position: relative;
  width: 80px;

  ${mq.large(css`
    cursor: pointer;

    &:hover {
      ${DeleteButton} {
        display: block;
      }
    }
  `)}
`;

export const Image = styled.img`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.greige};
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

export const IconImage = styled(Image)`
  padding: 12px;
`;
