import {
  BaseFilter,
  RangeFilter,
} from 'common/components/Filters/filters.types';

export const doRangeFilter = (value: number, rangeFilter: RangeFilter) =>
  (rangeFilter.minValue === null || value >= rangeFilter.minValue) &&
  (rangeFilter.maxValue === null || value <= rangeFilter.maxValue);

export const isRangeFilterActive = (rangeFilter: RangeFilter) =>
  rangeFilter.minValue !== null || rangeFilter.maxValue !== null;

export const isRangeFilter = (filter: BaseFilter): filter is RangeFilter =>
  'maxValue' in filter || 'minValue' in filter;
