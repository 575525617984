import { useCallback } from 'react';

import { Switch } from 'react-router-dom';

import {
  useLayoutState,
  useLayoutActions,
} from 'common/components/Layout/layoutProvider.hooks';

import { SubSidebar } from 'common/components/Sidebar/SubSidebar';

import { useMatchMedia } from 'common/hooks/useMatchMedia';

import { sidebarRoutes } from 'Router/routerConfig';
import { SentryRoute } from 'Router/SentryRoute';

export const LayoutSubSidebar = () => {
  const isLargeScreen = useMatchMedia();
  const { isSidebarOpen, isSubSidebarPresent } = useLayoutState();

  const { setSidebarOpen } = useLayoutActions();

  const handleOpenToggle = useCallback(
    () => setSidebarOpen((open) => !open),
    [setSidebarOpen],
  );

  const handleCloseSidebar = useCallback(() => {
    if (!isSidebarOpen || isLargeScreen) return;

    setSidebarOpen(false);
  }, [isLargeScreen, isSidebarOpen, setSidebarOpen]);

  if (!isSubSidebarPresent) return null;

  return (
    <SubSidebar
      anchor="left"
      onClose={handleCloseSidebar}
      onOpenToggle={handleOpenToggle}
      open={isSidebarOpen && isSubSidebarPresent}
      variant="permanent"
    >
      <Switch>
        {sidebarRoutes.map(({ component: Component, exact, path }) => (
          <SentryRoute exact={exact} key={path} path={path}>
            <Component />
          </SentryRoute>
        ))}
      </Switch>
    </SubSidebar>
  );
};
