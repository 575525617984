import { Formik, FieldArray } from 'formik';

import { Button } from 'common/components/Button/Button';
import { ErrorSegment } from 'common/components/ErrorHandlers/ErrorSegment/ErrorSegment';
import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';
import { Typography } from 'common/components/Typography/Typography';

import { EditMaterialsLeadTimeRow } from './EditMaterialsLeadTimeRow/EditMaterialsLeadTimeRow';

import { validationSchema } from './editMaterialsLeadTime.consts';

import { useEditMaterialsLeadTime } from './editMaterialsLeadTime.hooks';
import {
  ActionWrapper,
  Content,
  Footer,
  Header,
  Table,
  Title,
  Wrapper,
} from './editMaterialsLeadTime.styles';

const EditMaterialsLeadTime = () => {
  const {
    error,
    handleGoBack,
    handleSubmit,
    initialValues,
    isLoading,
    materials,
  } = useEditMaterialsLeadTime();

  if (error) return <ErrorSegment />;
  if (isLoading) return <LoadingSpinnerWithContainer />;

  return (
    <Wrapper>
      <Header>
        <Button onClick={handleGoBack} small>
          Back
        </Button>

        <Title>Bulk Edit Stock Status</Title>
      </Header>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ dirty, handleReset, isSubmitting, submitForm }) => (
          <>
            <Content>
              <Table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Vendor</th>
                    <th>Stock Status</th>
                    <th>Lead Time Min</th>
                    <th>Lead Time Max</th>
                    <th>Restock Date</th>
                  </tr>
                </thead>
                <tbody>
                  <FieldArray
                    name="materials"
                    render={() =>
                      materials.map(
                        (
                          {
                            id,
                            sku: {
                              name: productName,
                              vendor: {
                                vendor: { name: vendorName },
                              },
                            },
                          },
                          index,
                        ) => (
                          <EditMaterialsLeadTimeRow
                            index={index}
                            key={id}
                            productName={productName}
                            vendorName={vendorName}
                          />
                        ),
                      )
                    }
                  />
                </tbody>
              </Table>
            </Content>

            {dirty && (
              <Footer>
                <Typography type="h6">Unsaved Changes</Typography>

                <ActionWrapper>
                  <Button onClick={handleReset} small>
                    Discard
                  </Button>

                  <Button
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    small
                    variant="contained"
                  >
                    Save
                  </Button>
                </ActionWrapper>
              </Footer>
            )}
          </>
        )}
      </Formik>
    </Wrapper>
  );
};

export default EditMaterialsLeadTime;
