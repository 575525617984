import { useContext } from 'react';

import { SidebarContext } from './SidebarProvider';

export const useSidebarContentProps = () => {
  const { isOpen } = useContext(SidebarContext);
  return { isOpen };
};

export const useSidebarHeaderProps = () => {
  const { anchor, isOpen, onOpenToggle } = useContext(SidebarContext);
  const hasAnchor = !!onOpenToggle && !!anchor;
  const isDev = process.env.REACT_APP_ENVIRONMENT !== 'prod';

  return { anchor, hasAnchor, isDev, isOpen, onOpenToggle };
};
