import { Modal } from 'common/components/Modal/Modal';

import { Section } from 'Project/Procurement/common/components/Layout/Order';
import { OrderShipment } from 'Project/Procurement/common/types/orderShipment';

import { EditShipment } from 'Project/Procurement/EditOrder/EditShipment/EditShipment';
import { Shipment } from 'Project/Procurement/EditOrder/OrderShipments/Shipment/Shipment';

import { useOrderShipments } from 'Project/Procurement/EditOrder/OrderShipments/orderShipments.hooks';

import { EmptyState } from 'Project/Procurement/EditOrder/OrderShipments/orderShipments.styles';

type Props = { shipments: OrderShipment[] };

export const OrderShipments = ({ shipments }: Props) => {
  const {
    handleEditShipment,
    handleEditShipmentModalClose,
    isEditShipmentModalOpen,
    selectedShipment,
  } = useOrderShipments();

  if (!shipments.length)
    return (
      <Section>
        <EmptyState>No shipments have been created yet</EmptyState>
      </Section>
    );

  return (
    <>
      {shipments.map((shipment) => (
        <Shipment
          key={shipment.id}
          onEdit={() => handleEditShipment(shipment)}
          shipment={shipment}
        />
      ))}

      <Modal
        onClose={handleEditShipmentModalClose}
        open={isEditShipmentModalOpen}
      >
        <EditShipment shipment={selectedShipment} />
      </Modal>
    </>
  );
};
