import { useParams } from 'react-router-dom';
import { List } from '@material-ui/core';

import { isFunction } from 'common/guards/function';
import { SidebarContentListItem } from 'common/components/Sidebar/SidebarContentListItem';

import { PROPOSAL_LINKS } from './proposal.consts';

type Props = {
  renovationPlanId: string;
  userId: string;
};

export const ProposalLinks = ({ renovationPlanId, userId }: Props) => {
  const { proposalId } = useParams<{ proposalId: string }>();

  return (
    <List disablePadding>
      {PROPOSAL_LINKS.map(({ label, to }) => (
        <SidebarContentListItem
          disabled={!to}
          exact
          key={label}
          label={label}
          to={
            isFunction(to) ? to({ proposalId, renovationPlanId, userId }) : to
          }
        />
      ))}
    </List>
  );
};
