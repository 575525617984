import * as React from 'react';

import {
  ArrowDropDown,
  ArrowDropUp,
  Clear as ClearIcon,
} from '@material-ui/icons';

import { clickableStyles, clearIconStyles } from '../Select.styles';

type Props = {
  clearSelection: () => void;
  isClearable?: boolean;
  isDisabled?: boolean;
  isOpen: boolean;
  toggleMenu: () => void;
};

export const SelectIndicator = ({
  clearSelection,
  isClearable,
  isDisabled,
  isOpen,
  toggleMenu,
  ...props
}: Props) => {
  const handleToggleMenu = React.useCallback(
    (event: React.MouseEvent<SVGElement, MouseEvent>) => {
      if (isDisabled) return;

      event.stopPropagation();
      toggleMenu();
    },
    [toggleMenu, isDisabled],
  );

  const handleClear = React.useCallback(
    (event: React.MouseEvent<SVGElement, MouseEvent>) => {
      if (isDisabled) return;

      event.stopPropagation();
      clearSelection();
    },
    [clearSelection, isDisabled],
  );

  return (
    <>
      {isClearable && (
        <ClearIcon
          aria-label="clear selection"
          css={[clearIconStyles, !isDisabled && clickableStyles]}
          onClick={handleClear}
        />
      )}
      {isOpen ? (
        <ArrowDropUp
          {...props}
          aria-label="close menu"
          css={!isDisabled && clickableStyles}
          onClick={handleToggleMenu}
        />
      ) : (
        <ArrowDropDown
          {...props}
          aria-label="open menu"
          css={!isDisabled && clickableStyles}
          onClick={handleToggleMenu}
        />
      )}
    </>
  );
};
