import { FormikContextType, useFormikContext } from 'formik';

import { Button } from 'common/components/Button/Button';

import { ReactComponent as WarningRedIcon } from 'common/assets/icons/warning-red.svg';

import {
  Wrapper,
  Info,
} from 'ProjectProfile/ProjectInformation/v2/Toolbar/toolbar.styles';
import { useLayoutState } from 'common/components/Layout/layoutProvider.hooks';
import { FormValues } from 'ProjectProfile/ProjectInformation/v2/projectInformation.types';
import { debouncedChange } from 'common/shared/Question/common/utils/debounceChange';

type Props = {
  isSaving: boolean;
  onSave: (
    values: FormValues,
    formikContext: FormikContextType<FormValues>,
  ) => void;
};

export const Toolbar = ({ isSaving, onSave }: Props) => {
  const formikContext = useFormikContext<FormValues>();
  const { initialValues, resetForm, validateForm, values } = formikContext;
  const { isSidebarOpen } = useLayoutState();

  const isDirty = values.rooms.some(
    (r, i) => initialValues.rooms[i]?.photos !== r.photos,
  );

  if (!isDirty) return null;

  const handleDiscardChanges = () => {
    resetForm();
    validateForm();
    debouncedChange.cancel();
  };

  return (
    <Wrapper isSidebarOpen={isSidebarOpen}>
      <Button disabled={isSaving} onClick={handleDiscardChanges} size="medium">
        Discard Changes
      </Button>
      <Info>
        <WarningRedIcon /> You have unsaved changes
      </Info>
      <Button
        color="secondary"
        disabled={isSaving}
        onClick={() => {
          onSave(values, formikContext);
        }}
        size="medium"
      >
        Save Changes
      </Button>
    </Wrapper>
  );
};
