import { ShipmentDeliverTo } from 'common/apollo/enums/ShipmentDeliverTo';
import { ShipmentStatus } from 'common/apollo/enums/ShipmentStatus';
import { ShipmentType } from 'common/apollo/enums/ShipmentType';
import { ShippingMethod } from 'common/apollo/enums/ShippingMethod';

import { OrderShipment } from 'Project/Procurement/common/types/orderShipment';

export const generateInitialValues = (shipment: OrderShipment | null) => ({
  actualDeliveryDate: shipment?.actualDeliveryDate || null,
  deliverTo: shipment?.deliverTo || ('' as ShipmentDeliverTo),
  estimatedDeliveryDate:
    shipment?.estimatedDeliveryDate || new Date().toISOString(),
  shippingCost: shipment?.shippingCost || 0,
  shippingMethod: shipment?.shippingMethod || ('' as ShippingMethod),
  shippingTax: shipment?.shippingTax || 0,
  status: shipment?.status || ('' as ShipmentStatus),
  trackingLink: shipment?.trackingLink || '',
  trackingNumber: shipment?.trackingNumber || '',
  type: shipment?.type || ShipmentType.STANDARD,
});
