import { onError, ErrorResponse } from '@apollo/client/link/error';
import { ServerError } from '@apollo/client/link/utils';

import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from 'common/consts/localStorage';

import { apolloClient } from 'common/apollo/config/initializeApolloClient';

import { PUBLIC_PATHS } from 'common/routes/routerPaths';

const isServerError = (err: ServerError | Error): err is ServerError =>
  'statusCode' in err;

export const errorMiddleware = onError(({ networkError }: ErrorResponse) => {
  if (!networkError) return;

  const isUnauthorized =
    isServerError(networkError) && networkError.statusCode === 401;
  const publicPaths = Object.values(PUBLIC_PATHS);
  const isPublicPath = publicPaths.some((path) =>
    window.location.pathname.includes(path),
  );

  if (!isPublicPath && isUnauthorized) {
    apolloClient.clearStore();
    localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);
    window.location.href = PUBLIC_PATHS.LOG_IN;

    return;
  }

  const hasNoPermission =
    isServerError(networkError) && networkError.statusCode === 403;

  if (hasNoPermission) {
    networkError.message = 'Permission required';
  }
});
