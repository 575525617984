import { useParams } from 'react-router-dom';

import { useGetProjectPlanSecondaryContact } from 'common/hooks/useProjectPlanSecondaryContact';

import { useToggle } from 'common/hooks/useToggle';
import { useProjectPlanSecondaryContactUpdate } from 'ProjectProfile/common/hooks/useProjectPlanSecondaryContactUpdate';
import { useGetProject } from 'common/hooks/Project/useGetProject';

import { SecondaryContactInput } from './secondaryContact.types';

export const usePrimaryContactInfo = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { isLoading: isPrimaryContactLoading, user: primaryContact } =
    useGetProject(projectId);

  return { isPrimaryContactLoading, primaryContact };
};

export const useSecondaryContactInfo = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { isOn, toggleOff, toggleOn } = useToggle(false);

  const { isLoading: isSecondaryContactLoading, secondaryContact } =
    useGetProjectPlanSecondaryContact({
      projectId,
    });
  const hasData =
    !isSecondaryContactLoading &&
    secondaryContact.email &&
    secondaryContact.firstName &&
    secondaryContact.lastName;

  return {
    hasData,
    isSecondaryContactEditOpen: isOn,
    isSecondaryContactLoading,
    secondaryContact,
    secondaryContactEditClose: toggleOff,
    secondaryContactEditOpen: toggleOn,
  };
};

export const useSecondaryContactEdit = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { handleSubmit } = useProjectPlanSecondaryContactUpdate();

  const { secondaryContact } = useGetProjectPlanSecondaryContact({
    projectId,
  });

  const initialValues = {
    email: secondaryContact.email,
    firstName: secondaryContact.firstName,
    lastName: secondaryContact.lastName,
    phoneNumber: secondaryContact.phoneNumber,
    relationship: secondaryContact.relationship,
  };

  const submit = (values: SecondaryContactInput, close: () => void) =>
    handleSubmit({
      onSuccessCallback: close,
      values,
    });

  return {
    initialValues,
    submit,
  };
};
