import { format, differenceInHours, differenceInMinutes } from 'date-fns';

import { pluralize } from 'common/utils/pluralize';

const DATE_TIME_FORMAT_WO_SECONDS = 'MM/dd/yyyy hh:mm a';
const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';
const DEFAULT_DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm:ss a';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

export const formatDate = (
  formatText: string,
  date?: number | string | Date,
): string => (date ? format(new Date(date), formatText) : '');

export const dateToUSFormat = (
  date?: number | string | Date,
  formatText: string = DEFAULT_DATE_FORMAT,
): string => formatDate(formatText, date);

export const dateToUSFormatWithAt = (
  date?: number | string | Date,
  formatText: string = DEFAULT_DATE_TIME_FORMAT,
): string => {
  const formattedDate = dateTimeToUSFormat(date, formatText).split(' ');
  return `${formattedDate[0]} at ${formattedDate.slice(1).join(' ')}`;
};

export const dateTimeToUSFormat = (
  date?: number | string | Date,
  formatText: string = DEFAULT_DATE_TIME_FORMAT,
): string => formatDate(formatText, date);

export const dateTimeToUSFormatWOSeconds = (
  date?: number | string | Date,
  formatText: string = DATE_TIME_FORMAT_WO_SECONDS,
): string => formatDate(formatText, date);

export const differenceInHoursFromNow = (date: Date) =>
  `${differenceInHours(new Date(date), new Date())}h`;

export const differenceInHoursAndMinutes = (date: Date) => {
  const mins = differenceInMinutes(new Date(date), new Date());
  const hours = Math.floor(mins / 60);
  return hours ? `${hours}h ${mins % 60}m` : `${mins % 60}m`;
};

const getUTCDate = (date: Date) => {
  const utcDateInMs = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
  );
  return utcDateInMs;
};

export const calculateDaysLate = (setDate?: number | string) => {
  if (!setDate) return 0;
  const todaysDateUTCMs = getUTCDate(new Date());
  const setDateValueUTCMs = getUTCDate(new Date(setDate));
  return Math.floor((todaysDateUTCMs - setDateValueUTCMs) / MS_PER_DAY);
};

export const getDaysLateStr = (daysLate: number) =>
  daysLate > 0 ? `${daysLate} ${pluralize('day', daysLate)} past` : '';
