import { Formik } from 'formik';

import { AUTH_PATHS } from 'common/routes/routerPaths';
import { Button } from 'common/components/Button/Button';
import { EditOrderFormValues } from 'Project/Procurement/EditOrder/editOrder.types';
import { floatToCurrencyString } from 'common/utils/currencyFormatter';
import { OrderInfoForm } from 'Project/Procurement/EditOrder/OrderInfo/OrderInfoForm/OrderInfoForm';
import { ReactComponent as ChevronLeftColor } from 'common/assets/icons/chevron-left-color.svg';

import { ReactComponent as PencilIcon } from 'common/assets/icons/pencil.svg';

import { useOrderInfo } from 'Project/Procurement/EditOrder/OrderInfo/orderInfo.hooks';

import { validationSchema } from 'Project/Procurement/EditOrder/editOrder.consts';

import {
  Link,
  Section,
  SectionAction,
  SectionContent,
  SectionHeader,
  SectionSubtitle,
  SectionTitle,
} from 'Project/Procurement/common/components/Layout/Order';

type Props = {
  expectedMaterialsCost: number;
  initialValues: EditOrderFormValues;
};

export const OrderInfo = ({ expectedMaterialsCost, initialValues }: Props) => {
  const {
    handleCloseEditMode,
    handleEditOrder,
    handleOpenEditMode,
    isEditMode,
    projectId,
    vendors,
  } = useOrderInfo();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleEditOrder}
      validationSchema={validationSchema}
    >
      {({ dirty, isSubmitting, resetForm, submitForm }) => (
        <Section>
          <Link to={AUTH_PATHS.getProjectProcurementOrdersPath(projectId)}>
            <ChevronLeftColor /> All Orders
          </Link>
          <SectionHeader>
            <SectionTitle>Order Info</SectionTitle>

            <SectionAction>
              {isEditMode ? (
                <>
                  <Button
                    onClick={() => {
                      resetForm();
                      handleCloseEditMode();
                    }}
                    small
                  >
                    Cancel
                  </Button>

                  <Button
                    color="primary"
                    disabled={isSubmitting || !dirty}
                    onClick={submitForm}
                    small
                    variant="contained"
                  >
                    Save Changes
                  </Button>
                </>
              ) : (
                <Button
                  color="secondary"
                  onClick={handleOpenEditMode}
                  size="small"
                  type="button"
                  variant="outlined"
                >
                  <PencilIcon /> Edit Order
                </Button>
              )}
            </SectionAction>
          </SectionHeader>

          <SectionSubtitle>
            Expected Cost: {floatToCurrencyString(expectedMaterialsCost)}
          </SectionSubtitle>

          <SectionContent>
            <OrderInfoForm
              isDisabled={!isEditMode || isSubmitting}
              vendors={vendors}
            />
          </SectionContent>
        </Section>
      )}
    </Formik>
  );
};
