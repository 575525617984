import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { THEME } from 'common/consts/theme';

export const noRoomInfoStyles = css`
  color: ${THEME.color.black};
  font: normal 12px/16px ${THEME.font.roboto};
  margin: 12px 0 28px;
`;

export const sidebarElementStyles = css`
  border: 0;
`;

type StatusProps = {
  published?: boolean;
};

export const StatusWrapper = styled.div<StatusProps>`
  ${({ published, theme }) => css`
    font: normal 12px/16px ${THEME.font.roboto};
    color: ${published ? theme.color.green : theme.color.primaryOrange};
    padding-top: 8px;
    padding-bottom: 26px;
  `}
`;

export const EditWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const IconWrapper = styled.div`
  border: 1px solid transparent;
  padding: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.color.gray3};
    border: 1px solid ${({ theme }) => theme.color.gray6};
    border-radius: 4px;
  }
`;

export const duplicateIconStyles = css`
  height: 15px;
  width: 15px;
`;

export const AddButtonWrapper = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

export const addButtonStyles = css`
  color: ${THEME.color.orange11};
`;

export const AddButtonText = styled.h6`
  ${({ theme }) => theme.typography.header6}
  color: ${THEME.color.orange11};
  margin-left: 12px;
`;
