import { ProposalStatus } from 'common/apollo/enums/ProposalStatus';

import { dateToUSFormatWithAt } from 'common/utils/date';

import { Badge } from 'common/components/Badge/Badge';

import {
  Wrapper,
  BadgeWrapper,
  Text,
} from 'PricingEngineSidebar/Proposal/Status/status.styles';

type Props = {
  acceptedAt?: string;
  publishedAt?: string;
  status: ProposalStatus;
};

export const Status = ({ publishedAt, status }: Props) => {
  const isDraft = status === ProposalStatus.DRAFT;
  const isAccepted = status === ProposalStatus.ACCEPTED;
  const isPublished = status === ProposalStatus.PUBLISHED;
  const isProposed = status === ProposalStatus.PROPOSED;
  const isExpired = status === ProposalStatus.EXPIRED;
  const isRequested = status === ProposalStatus.REQUESTED;

  const hasGeneratStatus = isDraft || isProposed || isExpired || isRequested;

  const statusFormatted = status.toLocaleLowerCase();

  return (
    <Wrapper>
      {hasGeneratStatus && <Badge variant="orange">{statusFormatted}</Badge>}
      {isPublished && (
        <BadgeWrapper>
          <Badge variant="green">{statusFormatted}</Badge>
          <Text colorVariant="green12">
            {dateToUSFormatWithAt(publishedAt, 'MM/dd/yyyy H:mma')}
          </Text>
        </BadgeWrapper>
      )}
      {isAccepted && (
        <BadgeWrapper>
          <Badge variant="blue">{statusFormatted}</Badge>
          <Text colorVariant="blue11">
            {dateToUSFormatWithAt(publishedAt, 'MM/dd/yyyy H:mma')}
          </Text>
        </BadgeWrapper>
      )}
    </Wrapper>
  );
};
