import { gql } from '@apollo/client';

export const GET_ADMIN_KEY_DATES = gql`
  query adminKeyDates($input: AdminKeyDatesInput!) {
    adminKeyDates(input: $input) {
      id
      source
      type
      value
    }
  }
`;
