import {
  SelectionBadge,
  Name,
  Price,
  StyledCheckboxIcon,
  TagsWrapper,
  Thumbnail,
  Wrapper,
  PropertiesWrapper,
  PropertyLabel,
} from 'common/shared/Look/look.styles';
import { AuthUserRole } from 'common/apollo/enums/AuthUserRole';
import { Badge } from 'common/components/Badge/Badge';
import { getSelectionBadgeLabel } from 'common/shared/Look/look.utils';
import noImage from 'common/assets/icons/no-image.svg';
import { Property } from 'common/apollo/models/property';

type Props = {
  collectionName: string;
  imageUrl: string;
  importedByUser?: AuthUserRole[];
  isImported?: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
  maxPrice?: number;
  minPrice?: number;
  name: string;
  onClick?: () => void;
  projectCompatibleItemsOnly?: boolean;
  properties?: Property[];
  tags: string[];
};

export const Look = ({
  collectionName,
  imageUrl,
  importedByUser,
  isImported,
  isSelectable,
  isSelected,
  maxPrice,
  minPrice,
  name,
  onClick,
  projectCompatibleItemsOnly,
  properties,
  tags,
}: Props) => (
  <Wrapper>
    <Thumbnail
      isSelectable={isSelectable}
      isSelected={isSelected}
      onClick={isSelectable ? onClick : undefined}
      src={imageUrl || noImage}
    />
    {isImported && (
      <SelectionBadge>{getSelectionBadgeLabel(importedByUser)}</SelectionBadge>
    )}
    {isSelectable && isSelected && <StyledCheckboxIcon />}
    <TagsWrapper>
      {tags.map((tag) => (
        <Badge key={tag} variant="blue">
          {tag}
        </Badge>
      ))}
      <Badge variant="purple">{collectionName}</Badge>
    </TagsWrapper>
    <Name>{name}</Name>
    {!projectCompatibleItemsOnly && !!properties && (
      <PropertiesWrapper>
        {properties.map((property) => (
          <PropertyLabel key={property.id}>{property.label}</PropertyLabel>
        ))}
      </PropertiesWrapper>
    )}
    {!!maxPrice && !!minPrice && (
      <Price>
        ${minPrice} - ${maxPrice}
      </Price>
    )}
  </Wrapper>
);
