import {
  useCallback,
  useRef,
  useMemo,
  ChangeEvent,
  RefObject,
  FocusEvent,
  KeyboardEvent,
} from 'react';

import { ControllerStateAndHelpers } from 'downshift';

import { TAB_KEY } from 'common/consts/keyboard';

import {
  TextField,
  Props as TextFieldProps,
} from 'common/components/Form/TextField/TextField';
import { SelectMultipleType } from 'common/types/selectMultiple';
import { SelectType } from 'common/types/select';

import { SelectIndicator } from './SelectIndicator';

export type Props<Option> = TextFieldProps & {
  downshift: ControllerStateAndHelpers<any>;
  isClearable?: boolean;
  isReadOnly?: boolean;
  onSelectControlChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  openMenuOnFocus?: boolean;
  options?: Array<SelectMultipleType<Option> | SelectType<Option> | Option>;
  selectRef: RefObject<HTMLInputElement>;
};

export const SelectControl = <Option,>({
  InputProps,
  downshift,
  errorMessage,
  id,
  inputRef: _inputRef,
  isClearable,
  isDisabled,
  isReadOnly = true,
  isRequired,
  label,
  name,
  onBlur,
  onFocus,
  onKeyUp,
  onSelectControlChange,
  openMenuOnFocus,
  placeholder,
  selectRef,
  ...props
}: Props<Option>) => {
  const defaultInputRef = useRef<HTMLInputElement>();
  const inputRef = _inputRef || defaultInputRef;

  const {
    clearSelection,
    getInputProps,
    getLabelProps,
    getToggleButtonProps,
    inputValue,
    isOpen,
    openMenu,
    selectHighlightedItem,
    selectedItem,
    toggleMenu,
  } = downshift;

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (openMenuOnFocus && !isOpen) openMenu();
      onFocus?.(event);
    },
    [isOpen, openMenu, openMenuOnFocus, onFocus],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const keyCode = event.keyCode || event.which;

      if (keyCode !== TAB_KEY) return;
      selectHighlightedItem();
      InputProps?.onKeyDown?.(event);
    },
    [selectHighlightedItem, InputProps],
  );

  const handleClick = useCallback(() => {
    if (!isOpen) openMenu();
  }, [isOpen, openMenu]);
  const value = isReadOnly ? inputValue : undefined;
  const inputProps = useMemo(
    () =>
      getInputProps({
        ...(id && { id }),
        ...(!isDisabled && {
          onBlur,
          onClick: handleClick,
          onFocus: handleFocus,
          onKeyDown: handleKeyDown,
        }),
        ...(onSelectControlChange && { onChange: onSelectControlChange }),
        disabled: isDisabled,
        name,
        placeholder,
        readOnly: isReadOnly,
        ref: selectRef,
        ...(InputProps?.color && { color: InputProps.color }),
        ...(isReadOnly && { value }),
        ...InputProps,
      }),
    [
      getInputProps,
      id,
      isDisabled,
      onBlur,
      handleClick,
      handleFocus,
      handleKeyDown,
      onSelectControlChange,
      name,
      placeholder,
      isReadOnly,
      selectRef,
      InputProps,
      value,
    ],
  );

  const labelProps = useMemo(
    () =>
      getLabelProps({
        disabled: isDisabled,
        ...(id && { htmlFor: id }),
      }),
    [getLabelProps, isDisabled, id],
  );

  const indicatorProps = useMemo(
    () =>
      getToggleButtonProps({
        disabled: isDisabled,
      }),
    [getToggleButtonProps, isDisabled],
  );

  return (
    <TextField
      InputLabelProps={labelProps}
      InputProps={inputProps}
      endAdornment={
        <SelectIndicator
          {...indicatorProps}
          clearSelection={clearSelection}
          isClearable={isClearable && selectedItem}
          isDisabled={isDisabled}
          isOpen={isOpen}
          toggleMenu={toggleMenu}
        />
      }
      errorMessage={errorMessage}
      inputRef={inputRef}
      isDisabled={isDisabled}
      isFullWidth
      isRequired={isRequired}
      label={label}
      {...props}
    />
  );
};
