import { Formik, FieldArray } from 'formik';

import { CloseButton } from 'common/components/Form/CloseButton/CloseButton';

import { Button } from 'common/components/Button/Button';

import { OrderMaterial } from 'Project/Procurement/common/types/orderMaterial';

import {
  Content,
  Footer,
  Header,
  Section,
  SectionContent,
  SectionTitle,
  Wrapper,
} from 'Project/Procurement/common/components/Layout/Shipment';

import { ShipmentForm } from 'Project/Procurement/EditOrder/CreateShipment/ShipmentForm/ShipmentForm';
import { ShipmentItem } from 'Project/Procurement/EditOrder/CreateShipment/ShipmentItem/ShipmentItem';

import { validationSchema } from 'Project/Procurement/EditOrder/CreateShipment/createShipment.consts';

import { useCreateShipment } from 'Project/Procurement/EditOrder/CreateShipment/createShipment.hooks';

type Props = {
  materials: OrderMaterial[];
  orderNumber: string;
  shipmentsLength: number;
};

export const CreateShipment = ({
  materials,
  orderNumber,
  shipmentsLength,
}: Props) => {
  const { handleClose, handleRemoveItem, handleSubmit, initialValues, items } =
    useCreateShipment(materials, shipmentsLength, orderNumber);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, isSubmitting, submitForm }) => (
        <Wrapper>
          <Header>
            Create Shipment
            <CloseButton onClick={handleClose} />
          </Header>

          <Content>
            <Section>
              <SectionTitle>Delivery Information</SectionTitle>

              <SectionContent>
                <ShipmentForm />
              </SectionContent>
            </Section>

            {!!items.length && (
              <Section>
                <SectionTitle>Sku Information</SectionTitle>

                <SectionContent>
                  <FieldArray
                    name="items"
                    render={({ remove }) =>
                      items.map(
                        (
                          { boxCount, id, name, totalUnits, unitType },
                          index,
                        ) => (
                          <ShipmentItem
                            boxCount={boxCount}
                            index={index}
                            key={id}
                            name={name}
                            onRemove={() => {
                              remove(index);
                              handleRemoveItem(id);
                            }}
                            unitType={unitType}
                            unitsAvailable={totalUnits}
                          />
                        ),
                      )
                    }
                  />
                </SectionContent>
              </Section>
            )}
          </Content>

          <Footer>
            <Button onClick={handleClose} small>
              Cancel
            </Button>

            <Button
              color="secondary"
              disabled={!dirty || isSubmitting}
              onClick={submitForm}
              small
              variant="outlined"
            >
              Create Shipment
            </Button>
          </Footer>
        </Wrapper>
      )}
    </Formik>
  );
};
