import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { rgba } from 'common/utils/colors';

import { mq } from 'common/utils/mediaQueries';

export const Table = styled.table`
  border-collapse: collapse;
  border-top: 1px solid ${({ theme }) => rgba(theme.color.black, 0.12)};
  font: 400 14px/24px ${({ theme }) => theme.font.inter};
  letter-spacing: 0;
  text-align: left;
  width: 100%;

  th,
  td {
    padding: 16px;

    > * {
      vertical-align: middle;
    }
  }

  tr + tr {
    border-top: 1px solid ${({ theme }) => rgba(theme.color.black, 0.12)};
  }

  thead {
    background: ${({ theme }) => theme.color.concrete};
    border-bottom: 1px solid ${({ theme }) => rgba(theme.color.black, 0.12)};

    ${mq.large(css`
      white-space: nowrap;
    `)};

    th {
      font-weight: 500;
    }
  }
`;

export const SectionHeaderContent = styled.div`
  align-items: center;
  column-gap: 35px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
`;

export const ContentWrapper = styled.div`
  align-items: flex-end;
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 5px;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.color.gray};
  font: 400 12px/16px ${({ theme }) => theme.font.inter};
`;

export const Value = styled.span`
  font: 400 14px/20px ${({ theme }) => theme.font.inter};
`;

export const SizeWrapper = styled.p`
  white-space: nowrap;
`;
