import { useParams } from 'react-router-dom';

import { QueryParams } from 'ProjectProfile/common/types/queryParams';

import { useProjectChangeLog } from 'ProjectProfile/common/hooks/useProjectPlanChangeLog';

import { normalizeProjectPlanChangeLog } from 'ProjectProfile/common/utils/normalizeChangeLog';

export const useChangeLog = () => {
  const { projectId } = useParams<QueryParams>();
  const { isLoading, projectPlanChangeLog } = useProjectChangeLog({
    projectId,
  });

  return {
    isLoading,
    projectPlanChangeLog: normalizeProjectPlanChangeLog(projectPlanChangeLog),
  };
};
