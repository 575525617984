import { useMutation } from '@apollo/client';

import { SET_PROJECT_CONTRACTOR_CHOICE } from 'common/apollo/mutations/setProjectContractorChoice.graphql';
import { SET_PROJECT_NO_DEPOSIT } from 'common/apollo/mutations/setProjectNoDeposit.graphql';
import { GET_PROJECT_TYPE } from 'common/apollo/queries/getProjectType.graphql';
import { SetProjectContractorChoiceRequest } from 'common/apollo/requests/setProjectContractorChoiceRequest';
import { SetProjectNoDepositRequest } from 'common/apollo/requests/setProjectNoDepositRequest';
import { SetProjectContractorChoiceResponse } from 'common/apollo/responses/setProjectContractorChoiceResponse';
import { SetProjectNoDepositResponse } from 'common/apollo/responses/setProjectNoDepositResponse';
import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';
import { useAlert } from 'common/hooks/useAlert';
import { DIALOG_ID } from 'common/shared/Sidebar/UpdateProjectFlagsDialog/UpdateProjectFlagsDialog';

type FormValues = {
  agreement: string;
  isContractorChoice: string;
  isNoDeposit: string;
};

export const useUpdateProjectFlagsDialog = (projectId: string) => {
  const setDialogOpen = useSetModalOpen();
  const { onCompleted, onError } = useAlert();

  const [setContractorChoice, { loading: isSetContractorChoiceLoading }] =
    useMutation<
      SetProjectContractorChoiceResponse,
      SetProjectContractorChoiceRequest
    >(SET_PROJECT_CONTRACTOR_CHOICE, {
      onError: (error) => onError(error.message),
      refetchQueries: [
        {
          query: GET_PROJECT_TYPE,
          variables: {
            id: projectId,
          },
        },
      ],
    });

  const [setNoDeposit, { loading: isSetNoDepositLoading }] = useMutation<
    SetProjectNoDepositResponse,
    SetProjectNoDepositRequest
  >(SET_PROJECT_NO_DEPOSIT, {
    onError: (error) => onError(error.message),
    refetchQueries: [
      {
        query: GET_PROJECT_TYPE,
        variables: {
          id: projectId,
        },
      },
    ],
  });

  const initialValues = {
    agreement: '',
    isContractorChoice: '',
    isNoDeposit: '',
  };

  const handleSubmit = async (values: FormValues) => {
    let didUpdate = false;
    if (values.isContractorChoice) {
      const result = await setContractorChoice({
        variables: {
          input: {
            projectId,
            value: true,
          },
        },
      });
      if (result) {
        didUpdate = true;
      }
    }

    if (values.isNoDeposit) {
      const result = await setNoDeposit({
        variables: {
          input: {
            projectId,
          },
        },
      });
      if (result) {
        didUpdate = true;
      }
    }

    if (didUpdate) {
      onCompleted('Project Flags Have Been Updated');
    }
    setDialogOpen(DIALOG_ID, false);
  };

  const onClose = () => {
    setDialogOpen(DIALOG_ID, false);
  };

  return {
    handleSubmit,
    initialValues,
    isLoading: isSetContractorChoiceLoading || isSetNoDepositLoading,
    onClose,
  };
};
