import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useToggle } from 'common/hooks/useToggle';

import { ProjectPlanQuestions } from 'common/apollo/models/projectPlanQuestion';
import { GetProjectPlanQuestionsResponse } from 'common/apollo/responses/getProjectPlanQuestionsResponse';

import {
  answersToFormikInitialValues,
  findImportance,
  formikValuesToOpenQuestionsData,
  formikValuesToQuestionsData,
  getImportances,
  getImportancesToggles,
  mapQuestionAnswer,
  mapQuestionToPlaceholder,
} from 'ProjectProfile/common/utils/questionAnswer';
import { useGetProjectPlanQuestions } from 'ProjectProfile/common/hooks/useProjectPlanQuestions';
import { useProjectPlanQuestionsUpdate } from 'ProjectProfile/common/hooks/useProjectPlanQuestionsUpdate';

import { Answers } from 'ProjectProfile/common/types/answer';
import { SelectOptions } from 'ProjectProfile/common/types/selectOptions';
import { normalizeProjectPlanQuestions } from 'ProjectProfile/common/utils/normalizeProjectPlanQuestions';
import { QueryParams } from 'ProjectProfile/common/types/queryParams';
import { UpdateProjectPlanQuestionsResponse } from 'ProjectProfile/common/responses/updateProjectPlanQuestionsResponse';
import { QuestionPlaceholder } from 'ProjectProfile/common/types/questionPlaceholder';
import {
  Importances,
  ImportancesToggles,
} from 'ProjectProfile/common/types/importance';

import {
  identifiers,
  questionIdentifiers,
  openQuestionsIdentifiers,
} from './siteSurvey.consts';

export const useSiteSurvey = () => {
  const { projectId } = useParams<QueryParams>();
  const { isOn, toggleOff, toggleOn } = useToggle(false);

  const { data, isLoading } = useGetProjectPlanQuestions({
    identifiers,
    projectId,
  });

  const answers = normalizeProjectPlanQuestions(data);

  const [projectPlanQuestions, setProjectPlanQuestions] =
    useState<ProjectPlanQuestions[]>(data);

  const [importances, setImportances] = useState<Importances>(
    getImportances(openQuestionsIdentifiers, projectPlanQuestions),
  );

  useEffect(() => {
    if (data.length) {
      setProjectPlanQuestions(data);
    }
  }, [data]);

  useEffect(() => {
    setImportances(
      getImportances(openQuestionsIdentifiers, projectPlanQuestions),
    );
  }, [projectPlanQuestions, isLoading]);

  const handleSuccessfulSubmit = (
    responseData: UpdateProjectPlanQuestionsResponse,
  ) => {
    setProjectPlanQuestions(responseData.projectPlanQuestionsUpdate);
    toggleOff();
  };

  return {
    answers,
    handleSuccessfulSubmit,
    importances,
    isLoading,
    isOn,
    projectId,
    toggleOff,
    toggleOn,
  };
};

export const useSiteSurveyEdit = () => {
  const { projectId } = useParams<QueryParams>();
  const { handleSubmit } = useProjectPlanQuestionsUpdate();

  const { data: projectPlanQuestions, isLoading } = useGetProjectPlanQuestions({
    identifiers,
    onCompleted: (responseData: GetProjectPlanQuestionsResponse) => {
      openQuestionsIdentifiers.forEach((identifier) => {
        if (
          findImportance(identifier, responseData.projectPlanQuestions) !==
          importances[identifier].state
        ) {
          importances[identifier].toggle();
        }
      });
    },
    projectId,
  });

  const submit = (
    answers: Answers,
    importances: ImportancesToggles,
    close: (response: UpdateProjectPlanQuestionsResponse) => void,
  ) =>
    handleSubmit({
      onSuccessCallback: close,
      openQuestions: formikValuesToOpenQuestionsData(
        answers,
        openQuestionsIdentifiers,
        importances,
      ),
      questions: formikValuesToQuestionsData(
        answers,
        projectPlanQuestions,
        questionIdentifiers,
      ),
    });

  const options = questionIdentifiers.reduce(
    (acc, identifier) => ({
      ...acc,
      [identifier]: mapQuestionAnswer(identifier, projectPlanQuestions),
    }),
    {} as SelectOptions,
  );

  const placeholders = openQuestionsIdentifiers.reduce(
    (acc, identifier) => ({
      ...acc,
      [identifier]: mapQuestionToPlaceholder(identifier, projectPlanQuestions),
    }),
    {} as QuestionPlaceholder,
  );

  const initialValues = identifiers.reduce(
    (acc, identifier) => ({
      ...acc,
      [identifier]: answersToFormikInitialValues(
        identifier,
        projectPlanQuestions,
      ),
    }),
    {} as Answers,
  );

  const importances = getImportancesToggles(
    openQuestionsIdentifiers,
    projectPlanQuestions,
  );

  return {
    importances,
    initialValues,
    isLoading,
    options,
    placeholders,
    projectId,
    submit,
  };
};
