import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query GetUsers($input: UsersInput!) {
    users(input: $input) {
      id
      email
      name
      phoneNumber
      renovationPlans {
        id
        status
        projectType
        primaryMember {
          id
          name
        }
        address {
          street
          city
          state
          zip
        }
      }
    }
  }
`;

export const GET_TEAM_MEMBERS = gql`
  query GetTeamMembers {
    teamMembers {
      id
      name
    }
  }
`;
