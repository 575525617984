import * as React from 'react';

import { MenuItem } from '@material-ui/core';

type Props = {
  children: React.ReactNode;
  isFocused: boolean;
  isSelected: boolean;
};

export const SelectOption = ({
  children,
  isFocused,
  isSelected,
  ...props
}: Props) => (
  <MenuItem
    component="div"
    selected={isFocused}
    style={{
      fontSize: 'inherit',
      fontWeight: isSelected ? 500 : 400,
      whiteSpace: 'pre-wrap',
    }}
    {...props}
  >
    {children}
  </MenuItem>
);
