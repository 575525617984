import { gql } from '@apollo/client';

export const UPDATE_MATERIALS_LEAD_TIME = gql`
  mutation UpdateMaterialsLeadTime($input: SkuVendorLeadTimeUpdateInput!) {
    skuVendorLeadTimeUpdate(input: $input) {
      id
      backInStockDate
      maxLeadTimeWeeks
      minLeadTimeWeeks
      stockStatus
      leadTimeUpdatedAt
    }
  }
`;
