import LinkIcon from '@material-ui/icons/Link';

import { ProjectPlanLink } from 'common/apollo/models/projectPlanLink';
import { useToggle } from 'common/hooks/useToggle';

import { EditButton } from 'ProjectProfile/common/components/EditButton/EditButton';
import {
  OutlinedLink,
  OutlinedNoLink,
} from 'ProjectProfile/common/components/Link/Link';

import { OtherLinksEdit } from 'ProjectProfile/PreDesign/Links/OtherLinks/OtherLinksEdit';
import {
  Header,
  Title,
  Wrapper,
} from 'ProjectProfile/PreDesign/Links/links.styles';

import { LinkContainer } from 'ProjectProfile/common/components/Link/LinkContainer/LinkContainer';
import { LinkItem } from 'ProjectProfile/common/components/Link/LinkItem/LinkItem';

type Props = {
  otherLinks: ProjectPlanLink[];
};

export const OtherLinks = ({ otherLinks }: Props) => {
  const { isOn: isOpen, toggleOff: close, toggleOn: open } = useToggle();

  return (
    <Wrapper>
      <Header>
        <Title>Additional links</Title>
        <EditButton
          onClick={open}
          segmentEventProps={{ name: 'Additional Links' }}
        />
      </Header>

      <LinkContainer>
        {otherLinks?.length ? (
          otherLinks.map(({ name, url }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <LinkItem key={index}>
              <OutlinedLink color="primary" href={url}>
                <LinkIcon />
                {name}
              </OutlinedLink>
            </LinkItem>
          ))
        ) : (
          <LinkItem>
            <OutlinedNoLink>No other helpful link is added yet</OutlinedNoLink>
          </LinkItem>
        )}
      </LinkContainer>

      {isOpen && <OtherLinksEdit close={close} />}
    </Wrapper>
  );
}; // 63
