import { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';

import { AUTH_PATHS } from 'common/routes/routerPaths';

import { OrderMaterial } from 'Project/Procurement/common/types/orderMaterial';

import { GetProjectProcurementOrderMaterialsRequest } from 'Project/Procurement/common/apollo/requests/getProjectProcurementOrderMaterialsRequest';
import { GetProjectProcurementOrderMaterialsResponse } from 'Project/Procurement/common/apollo/responses/getProjectProcurementOrderMaterialsResponse';

import { CreateProjectProcurementOrderResponse } from 'Project/Procurement/common/apollo/responses/createProjectProcurementOrderResponse';
import { CreateProjectProcurementOrderRequest } from 'Project/Procurement/common/apollo/requests/createProjectProcurementOrderRequest';

import {
  calculateExpectedMaterialsCost,
  normalizeProjectMaterialsData,
} from 'Project/Procurement/CreateOrder/createOrder.utils';

import {
  GET_PROJECT_ORDER_MATERIALS,
  CREATE_PROJECT_ORDER,
} from 'Project/Procurement/CreateOrder/createOrder.graphql';

import { CreateOrderFormValues } from 'Project/Procurement/CreateOrder/createOrder.types';
import { GET_PROJECT_PROCUREMENT_DELIVERY_BATCHES } from 'Project/Procurement/Warehouse/warehouse.graphql';

export const useCreateOrder = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { push } = useHistory();
  const { onError } = useAlert();
  const { search } = useLocation();
  const [materials, setMaterials] = useState<OrderMaterial[]>([]);

  const searchParams = new URLSearchParams(search);
  const materialsIds = searchParams.get('ids')?.split(',');

  const {
    data,
    error,
    loading: isLoading,
  } = useQuery<
    GetProjectProcurementOrderMaterialsResponse,
    GetProjectProcurementOrderMaterialsRequest
  >(GET_PROJECT_ORDER_MATERIALS, {
    onError: (e) => onError(e.message),
    skip: !materialsIds?.length,
    variables: {
      ids: materialsIds || [],
    },
  });

  const [createOrder] = useMutation<
    CreateProjectProcurementOrderResponse,
    CreateProjectProcurementOrderRequest
  >(CREATE_PROJECT_ORDER, {
    onCompleted: () => {
      push(AUTH_PATHS.getProjectProcurementOrdersPath(projectId));
    },
    onError: (e) => onError(e.message),
    refetchQueries: [
      {
        query: GET_PROJECT_PROCUREMENT_DELIVERY_BATCHES,
        variables: { projectId },
      },
    ],
  });

  useEffect(() => {
    if (!data?.projectMaterials.length) return;

    setMaterials(normalizeProjectMaterialsData(data.projectMaterials));
  }, [setMaterials, data]);

  const handleCreateOrder = (values: CreateOrderFormValues) => {
    const items = materials.map(({ id, totalUnits }) => ({
      amount: totalUnits,
      projectMaterialId: id,
    }));

    const input = Object.entries(values).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value || null }),
      {} as CreateOrderFormValues,
    );

    createOrder({
      variables: {
        input: {
          ...input,
          items,
        },
      },
    });
  };

  const handleRemoveMaterial = (id: string) => {
    setMaterials((values) => values.filter((material) => material.id !== id));
  };

  const handleUpdateMaterial = (id: string, totalUnits: number) => {
    setMaterials((values) =>
      values.map((material) =>
        material.id === id ? { ...material, totalUnits } : material,
      ),
    );
  };

  const vendors = (data?.projectMaterialsVendors || []).map(
    ({ id: value, name: label }) => ({
      label,
      value,
    }),
  );

  const expectedMaterialsCost = useMemo(() => {
    const procurementMaterials = data?.projectMaterials.filter(
      ({ id }) => !!materials.find((material) => material.id === id),
    );

    return calculateExpectedMaterialsCost(procurementMaterials);
  }, [data?.projectMaterials, materials]);

  return {
    error,
    expectedMaterialsCost,
    handleCreateOrder,
    handleRemoveMaterial,
    handleUpdateMaterial,
    isLoading,
    materials,
    vendors,
  };
};
