import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { QueryParams } from 'ProjectProfile/common/types/queryParams';

import { useGetProjectPlanQuestions } from 'ProjectProfile/common/hooks/useProjectPlanQuestions';
import { normalizeProjectPlanQuestions } from 'ProjectProfile/common/utils/normalizeProjectPlanQuestions';
import { Importances } from 'ProjectProfile/common/types/importance';
import { getImportances } from 'ProjectProfile/common/utils/questionAnswer';

import { identifiers } from './notes.consts';

export const useNotes = () => {
  const { projectId } = useParams<QueryParams>();
  const { data, isLoading } = useGetProjectPlanQuestions({
    identifiers,
    projectId,
  });

  const answers = normalizeProjectPlanQuestions(data);

  const [importances, setImportances] = useState<Importances>(
    getImportances(identifiers, data),
  );

  useEffect(() => {
    setImportances(getImportances(identifiers, data));
  }, [data, isLoading]);

  return {
    answers,
    data,
    importances,
    isLoading,
  };
};
