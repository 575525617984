export const getFileTypeErrorText = (allowedMimeTypes: string[]) =>
  `The file type is not allowed. Allowed types: ${allowedMimeTypes.join(', ')}`;

export const noPresignedDataFoundMessage = 'No presigned data found';

export const noFileSelectedMessage = 'No file selected';

export enum UploadStatus {
  Canceled = 'Canceled',
  InitialDataStored = 'InitialDataStored',
  PresignedDataStored = 'PresignedDataStored',
  Ready = 'Ready',
  UploadError = 'UploadError',
  Uploaded = 'Uploaded',
}
