import { useField } from 'formik';

import { ChangeEvent } from 'react';

import { useHasFormError } from 'common/hooks/useHasFormError';
import { shouldValidateField } from 'common/utils/validations';

import { Checkbox } from 'common/components/Checkbox/Checkbox';

import {
  Description,
  ErrorMessage,
  ExtraLabel,
  Label,
  LabelWrapper,
  Wrapper,
} from 'common/shared/Question/Checkbox/MultiCheckbox/multiCheckbox.styles';

import { Option } from 'common/shared/Question/question.types';

export type Props = {
  description?: string;
  id?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  label: string;
  name: string;
  onChange?: (value: string[]) => void;
  options: Option[];
};

export const MultiCheckbox = ({
  description,
  isDisabled,
  isRequired,
  label,
  name,
  onChange,
  options,
}: Props) => {
  const [field, { touched }, { setTouched, setValue }] = useField<string[]>({
    name,
    validate: shouldValidateField(isRequired),
  });

  const error = useHasFormError(name);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!touched) setTouched(true);

    const { checked: isChecked, id: optionValue } = event.target;

    const values = isChecked
      ? [...field.value, optionValue]
      : field.value.filter((selectedValue) => selectedValue !== optionValue);

    onChange?.(values);

    setValue(values);
  };

  return (
    <Wrapper>
      <LabelWrapper>
        <Label>{label}</Label>

        {!isRequired && <ExtraLabel>Optional</ExtraLabel>}
      </LabelWrapper>

      {description && <Description>{description}</Description>}

      <Wrapper>
        {options.map(({ label: optionLabel, value }) => (
          <Checkbox
            checked={field?.value?.includes(value)}
            disabled={isDisabled}
            id={value}
            key={value}
            label={optionLabel}
            onChange={handleChange}
            value={value}
          />
        ))}
      </Wrapper>

      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};
