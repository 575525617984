import styled from '@emotion/styled';
import { css } from '@emotion/react';

import IconButton from '@material-ui/core/IconButton';

import { THEME } from 'common/consts/theme';

import { ANCHOR_TYPE, AnchorType } from './sidebar.types';

type WithAnchor = {
  anchor?: AnchorType;
  hasAnchor?: boolean;
};

type WithVisibility = {
  isOpen?: boolean;
};

type WrapperProps = {
  isDev?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ isDev }) =>
    isDev ? THEME.color.primaryOrange : THEME.color.white};
  min-height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
  padding: 15px;

  @media print {
    border: 0;
    justify-content: flex-start;
    padding: 15px 0;
  }
`;

export const Chevron = styled(IconButton)<WithAnchor>`
  position: absolute !important;
  z-index: 1;
  color: ${THEME.color.darkGray};

  ${({ anchor }) =>
    anchor === ANCHOR_TYPE.right ? 'left:20px' : 'right:20px'};

  @media print {
    display: none;
  }
`;

export const Content = styled.div<WithVisibility & WithAnchor>`
  transition: transform 0.3s;

  ${({ hasAnchor }) => hasAnchor && 'margin: 0 25px'};

  ${({ isOpen }) =>
    !isOpen &&
    css`
      transform: translateX(-100%);
      pointer-events: none;
    `};

  @media print {
    margin: 0;
  }
`;
