import { object, array, string } from 'yup';

import {
  FIELD_REQUIRED,
  REQUIRED_VALIDATION,
} from 'common/consts/validationMessages';

export const validationSchema = object().shape({
  otherLinks: array().of(
    object().shape({
      name: string().required(FIELD_REQUIRED),
      url: string()
        .url('Helpful Link must be a valid URL.')
        .required(REQUIRED_VALIDATION),
    }),
  ),
});

export const emptyLink = { name: '', url: '' };
