import { gql } from '@apollo/client/core';

export const EDIT_SHIPMENT = gql`
  mutation UpdateProjectShipment($input: ShipmentUpdateInput!) {
    shipmentUpdate(input: $input) {
      id
      deliverTo
      estimatedDeliveryDate
      actualDeliveryDate
      number
      shippingMethod
      shippingTax
      shippingCost
      status
      trackingLink
      trackingNumber
      type
      items {
        id
      }
    }
  }
`;
