import { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
};

// TODO: style it
export const ErrorSegment = ({
  children = 'Oops, sth went wrong. Try again',
}: Props) => <span>{children}</span>;
