import { gql } from '@apollo/client';

export const GET_PROJECT_PROCUREMENT_SHIPMENTS = gql`
  query GetProjectProcurementShipments($projectId: ID!) {
    project(id: $projectId) {
      id
      shipments {
        deliverTo
        estimatedDeliveryDate
        actualDeliveryDate
        id
        number
        shippingMethod
        status
        trackingLink
        trackingNumber
        type
        order {
          id
          webOrderNumber
        }
        items {
          id
          orderItem {
            id
            skuVendor {
              id
              vendor {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
