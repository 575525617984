import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';

import { AUTH_PATHS } from 'common/routes/routerPaths';

import { GetProjectProcurementMaterialsLeadTimeRequest } from 'Project/Procurement/common/apollo/requests/getProjectProcurementMaterialsLeadTimeRequest';
import { GetProjectProcurementMaterialsLeadTimeResponse } from 'Project/Procurement/common/apollo/responses/GetProjectProcurementMaterialsLeadTimeResponse';
import { UpdateProjectProcurementMaterialsLeadTimeResponse } from 'Project/Procurement/common/apollo/responses/updateProjectProcurementMaterialsLeadTimeResponse';
import { UpdateProjectProcurementMaterialsLeadTimeRequest } from 'Project/Procurement/common/apollo/requests/updateProjectProcurementMaterialsLeadTimeRequest';

import { GET_PROJECT_MATERIALS_LEAD_TIME } from 'Project/Procurement/common/apollo/gql/getProjectMaterialsLeadTime';
import { UPDATE_MATERIALS_LEAD_TIME } from 'Project/Procurement/common/apollo/gql/updateMaterialsLeadTime';

import { FormValue } from './editMaterialsLeadTime.types';

import { getInitialValues } from './editMaterialsLeadTime.utils';

const useEditMaterials = () => {
  const { onCompleted, onError } = useAlert();
  const [update] = useMutation<
    UpdateProjectProcurementMaterialsLeadTimeResponse,
    UpdateProjectProcurementMaterialsLeadTimeRequest
  >(UPDATE_MATERIALS_LEAD_TIME, {
    onCompleted: () => onCompleted('Successfully updated stock status'),
    onError: ({ message }) => onError(message),
  });

  const handleSubmit = ({ materials }: FormValue) =>
    update({
      variables: {
        input: {
          leadTimes: materials.map(
            ({
              backInStockDate,
              maxLeadTimeWeeks,
              minLeadTimeWeeks,
              ...rest
            }) => ({
              backInStockDate: backInStockDate || null,
              maxLeadTimeWeeks: +maxLeadTimeWeeks || null,
              minLeadTimeWeeks: +minLeadTimeWeeks || null,
              ...rest,
            }),
          ),
        },
      },
    });

  return { handleSubmit };
};

const useGetMaterials = () => {
  const { search } = useLocation();
  const { onError } = useAlert();
  const searchParams = new URLSearchParams(search);
  const materialsIds = searchParams.get('ids')?.split(',');

  const {
    data,
    error,
    loading: isLoading,
  } = useQuery<
    GetProjectProcurementMaterialsLeadTimeResponse,
    GetProjectProcurementMaterialsLeadTimeRequest
  >(GET_PROJECT_MATERIALS_LEAD_TIME, {
    onError: (e) => onError(e.message),
    skip: !materialsIds?.length,
    variables: {
      ids: materialsIds || [],
    },
  });

  const materials = data?.projectMaterials || [];

  const initialValues = getInitialValues(materials);

  return { error, initialValues, isLoading, materials };
};

export const useEditMaterialsLeadTime = () => {
  const { push } = useHistory();
  const { projectId } = useParams<{ projectId: string }>();

  const handleGoBack = () => {
    push(AUTH_PATHS.getProjectProcurementMaterialsPath(projectId));
  };

  const { error, initialValues, isLoading, materials } = useGetMaterials();
  const { handleSubmit } = useEditMaterials();

  return {
    error,
    handleGoBack,
    handleSubmit,
    initialValues,
    isLoading,
    materials,
  };
};
