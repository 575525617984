import { object, string } from 'yup';

import { REQUIRED_VALIDATION } from 'common/consts/validationMessages';

export const validationSchema = object().shape({
  deliverTo: string().required(REQUIRED_VALIDATION),
  shippingMethod: string().required(REQUIRED_VALIDATION),
  status: string().required(REQUIRED_VALIDATION),
  trackingLink: string().required(REQUIRED_VALIDATION),
  trackingNumber: string().required(REQUIRED_VALIDATION),
});
