import { ClassNames } from '@emotion/react';
import SearchIcon from '@material-ui/icons/Search';

import {
  TextField,
  Props as TextFieldProps,
} from 'common/components/Form/TextField/TextField';
import { useDebounce } from 'common/hooks/useDebounce';

import { searchInputStyle } from './searchInput.styles';

type Props = TextFieldProps & {
  onSearchChange: (value: string) => void;
};

export const SearchInput = ({ className, onSearchChange, ...rest }: Props) => {
  const handleChange = useDebounce(onSearchChange);

  return (
    <ClassNames>
      {({ css }) => (
        <TextField
          css={css(searchInputStyle, className)}
          endAdornment={<SearchIcon color="disabled" />}
          onChange={handleChange}
          {...rest}
        />
      )}
    </ClassNames>
  );
};
