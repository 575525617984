import { FileUploadSpec } from 'common/apollo/models/fileUploadSpec';

export enum ActionTypes {
  STORE_PRESIGNED_FILE_DATA = 'store_presigned_file_data',
  UPLOAD_CANCEL = 'upload_cancel',
  UPLOAD_CLEAR = 'upload_clear',
  UPLOAD_ERROR = 'upload_error',
  UPLOAD_LOADING = 'upload_loading',
  UPLOAD_PROGRESS = 'upload_progress',
  UPLOAD_SUCCESS = 'upload_success',
  VALIDATE_AND_STORE_FILE_DATA = 'validate_and_store_file_data',
}

export const storeFileDataAction = (file: File, newId: string) => {
  return {
    payload: {
      file,
      id: newId,
    },
    type: ActionTypes.VALIDATE_AND_STORE_FILE_DATA as typeof ActionTypes.VALIDATE_AND_STORE_FILE_DATA,
  };
};

export const storePresignedDataAction = (data: FileUploadSpec, id: string) => ({
  payload: {
    data,
    id,
  },
  type: ActionTypes.STORE_PRESIGNED_FILE_DATA as typeof ActionTypes.STORE_PRESIGNED_FILE_DATA,
});

export const handleUploadErrorAction = (id: string, error: string) => ({
  payload: { error, id },
  type: ActionTypes.UPLOAD_ERROR as typeof ActionTypes.UPLOAD_ERROR,
});

export const handleUploadSuccessAction = (id: string) => ({
  payload: { id },
  type: ActionTypes.UPLOAD_SUCCESS as typeof ActionTypes.UPLOAD_SUCCESS,
});

export const handleUploadLoadingAction = (id: string, loading: boolean) => ({
  payload: { id, loading },
  type: ActionTypes.UPLOAD_LOADING as typeof ActionTypes.UPLOAD_LOADING,
});

export const handleUploadProgressAction = (id: string, progress: number) => ({
  payload: { id, progress },
  type: ActionTypes.UPLOAD_PROGRESS as typeof ActionTypes.UPLOAD_PROGRESS,
});

export const handleUploadClearAction = (id: string) => ({
  payload: { id },
  type: ActionTypes.UPLOAD_CLEAR as typeof ActionTypes.UPLOAD_CLEAR,
});

export const handleUploadCancelAction = (id: string) => ({
  payload: { id },
  type: ActionTypes.UPLOAD_CANCEL as typeof ActionTypes.UPLOAD_CANCEL,
});

export type Action =
  | ReturnType<typeof storeFileDataAction>
  | ReturnType<typeof storePresignedDataAction>
  | ReturnType<typeof handleUploadErrorAction>
  | ReturnType<typeof handleUploadSuccessAction>
  | ReturnType<typeof handleUploadLoadingAction>
  | ReturnType<typeof handleUploadProgressAction>
  | ReturnType<typeof handleUploadClearAction>
  | ReturnType<typeof handleUploadCancelAction>;
