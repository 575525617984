import { useParams } from 'react-router-dom';

import { QueryParams } from 'ProjectProfile/common/types/queryParams';
import { useGetProjectPlanPropertyInfo } from 'ProjectProfile/common/hooks/useProjectPlanBuildingInfo';

export const useBuildingInfo = () => {
  const { projectId } = useParams<QueryParams>();

  const { data, isLoading } = useGetProjectPlanPropertyInfo({
    projectId,
  });

  const { constructionInfo, hoa, propertyInfo } = data;

  return {
    constructionInfo,
    hoa,
    isLoading,
    propertyInfo,
  };
};
