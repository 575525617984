/* eslint-disable jsx-a11y/control-has-associated-label */

import { ReactComponent as TrashIcon } from 'common/assets/icons/delete-outline.svg';
import { ReactComponent as EditIcon } from 'common/assets/icons/edit-outline.svg';

import { Badge } from 'common/components/Badge/Badge';
import { Button } from 'common/components/Button/Button';
import { Checkbox } from 'common/components/Checkbox/Checkbox';

import { IconButton } from 'common/_components/IconButton/IconButton';
import { Modal } from 'common/components/Modal/Modal';
import { AUTH_PATHS } from 'common/routes/routerPaths';

import {
  Section,
  SectionAction,
  SectionContent,
  SectionHeader,
  SectionTitle,
} from 'Project/Procurement/common/components/Layout/Order';

import { OrderConflictWarning } from 'Project/Procurement/common/components/OrderConflictWarning/OrderConflictWarning';

import { Units } from 'Project/Procurement/common/components/Units/Units';

import {
  ORDER_ITEM_STATUS_COLOR_VARIANT,
  ORDER_ITEM_STATUS_DICTIONARY,
} from 'Project/Procurement/common/consts/order.consts';

import { OrderItem } from 'Project/Procurement/common/types/orderItem';
import { OrderShipment } from 'Project/Procurement/common/types/orderShipment';

import { CreateShipment } from 'Project/Procurement/EditOrder/CreateShipment/CreateShipment';
import { DeleteMaterial } from 'Project/Procurement/EditOrder/OrderMaterials/DeleteMaterial/DeleteMaterial';
import { useOrderMaterials } from 'Project/Procurement/EditOrder/OrderMaterials/orderMaterials.hooks';
import {
  ActionWrapper,
  RevisionLink,
  SizeWrapper,
  Table,
  TableWrapper,
} from 'Project/Procurement/EditOrder/OrderMaterials/orderMaterials.styles';
import { UpdateMaterialUnits } from 'Project/Procurement/EditOrder/OrderMaterials/UpdateMaterialUnits/UpdateMaterialUnits';

type Props = {
  materials: OrderItem[];
  orderNumber: string;
  shipments: OrderShipment[];
};

export const OrderMaterials = ({
  materials,
  orderNumber,
  shipments,
}: Props) => {
  const {
    handleCloseDeleteMaterialModal,
    handleCloseNewShipmentModal,
    handleCloseUpdateMaterialModal,
    handleCopySkuInfo,
    handleCopyTable,
    handleOpenDeleteMaterialModal,
    handleOpenNewShipmentModal,
    handleOpenUpdateMaterialModal,
    handleSelectAllMaterials,
    handleSelectMaterial,
    hasSelectedMaterials,
    isAllMaterialsSelected,
    isMaterialSelected,
    isNewShipmentModalOpen,
    isOrderMaterialDeleteModalOpen,
    isOrderMaterialUnitsUpdateModalOpen,
    projectId,
    selectedMaterial,
    selectedMaterials,
  } = useOrderMaterials(materials);

  return (
    <>
      <Section>
        <SectionHeader>
          <SectionTitle>SKUS in Order</SectionTitle>
          <SectionAction>
            <Button onClick={handleCopyTable} small>
              Copy Table
            </Button>

            <Button onClick={handleCopySkuInfo} small>
              Copy Sku Info
            </Button>

            <Button
              disabled={!hasSelectedMaterials}
              onClick={handleOpenNewShipmentModal}
              small
            >
              New Shipment
            </Button>
          </SectionAction>
        </SectionHeader>

        <SectionContent>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>
                    <Checkbox
                      checked={isAllMaterialsSelected}
                      onChange={handleSelectAllMaterials}
                    />
                  </th>
                  <th />
                  <th style={{ minWidth: '225px' }}>Status</th>
                  <th style={{ minWidth: '150px' }}>Added</th>
                  <th>Subcategory</th>
                  <th>Product</th>
                  <th>Color</th>
                  <th>Finish</th>
                  <th>Size</th>
                  <th>Pattern</th>
                  <th>SKU ID</th>
                  <th>Vendor SKU ID</th>
                  <th>Total Units</th>
                  <th>Units Shipped</th>
                  <th>Units Received</th>
                </tr>
              </thead>

              <tbody>
                {materials.map(
                  ({
                    boxCount,
                    color,
                    finish,
                    generated,
                    id,
                    name,
                    pattern,
                    revisionActionItem,
                    size,
                    skuId,
                    status,
                    subcategory,
                    totalUnits,
                    unitType,
                    unitsReceived,
                    unitsShipped,
                    vendorSkuId,
                  }) => (
                    <tr key={id}>
                      <td>
                        <Checkbox
                          checked={isMaterialSelected(id)}
                          id={id}
                          onChange={handleSelectMaterial}
                        />
                      </td>
                      <td>
                        <ActionWrapper>
                          <IconButton
                            onClick={() => handleOpenDeleteMaterialModal(id)}
                          >
                            <TrashIcon />
                          </IconButton>

                          <IconButton
                            onClick={() => handleOpenUpdateMaterialModal(id)}
                          >
                            <EditIcon />
                          </IconButton>
                        </ActionWrapper>
                      </td>
                      <td>
                        <Badge
                          variant={ORDER_ITEM_STATUS_COLOR_VARIANT[status]}
                        >
                          {ORDER_ITEM_STATUS_DICTIONARY[status]}
                        </Badge>
                        {revisionActionItem && (
                          <OrderConflictWarning leftAlign />
                        )}
                      </td>
                      <td>
                        {revisionActionItem ? (
                          <RevisionLink
                            to={AUTH_PATHS.getProjectScopeRevisionDetails(
                              projectId,
                              revisionActionItem.revision.id,
                            )}
                          >
                            View Revision
                          </RevisionLink>
                        ) : generated ? (
                          'Automatically'
                        ) : (
                          'Manually'
                        )}
                      </td>
                      <td>{subcategory}</td>
                      <td>{name}</td>
                      <td>{color}</td>
                      <td>{finish}</td>
                      <td>
                        <SizeWrapper>{size}</SizeWrapper>
                      </td>
                      <td>{pattern}</td>
                      <td>{skuId}</td>
                      <td>{vendorSkuId}</td>
                      <td>
                        <Units
                          amount={totalUnits}
                          boxCount={boxCount}
                          unitType={unitType}
                        />
                      </td>
                      <td>{unitsShipped}</td>
                      <td>{unitsReceived}</td>
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
          </TableWrapper>
        </SectionContent>
      </Section>

      <Modal
        onClose={handleCloseNewShipmentModal}
        open={isNewShipmentModalOpen}
      >
        <CreateShipment
          materials={selectedMaterials}
          orderNumber={orderNumber}
          shipmentsLength={shipments.length}
        />
      </Modal>

      <Modal
        onClose={handleCloseUpdateMaterialModal}
        open={isOrderMaterialUnitsUpdateModalOpen}
      >
        <UpdateMaterialUnits material={selectedMaterial} />
      </Modal>

      <Modal
        onClose={handleCloseDeleteMaterialModal}
        open={isOrderMaterialDeleteModalOpen}
      >
        <DeleteMaterial material={selectedMaterial} />
      </Modal>
    </>
  );
};
