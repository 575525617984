import {
  BaseFilter,
  RangeFilter,
} from 'common/components/Filters/filters.types';

import {
  Clear,
  FilterButton,
  FilterCollapse,
  FilterItem,
  FilterTitleWrapper,
  Wrapper,
} from 'common/components/FiltersMenu/common/filterMenuItem.styles';
import { useRangeFilter } from 'common/components/FiltersMenu/RangeFilter/rangeFilter.hooks';
import {
  Dash,
  DashContainer,
  RangeFilterWrapperInner,
} from 'common/components/FiltersMenu/RangeFilter/rangeFilter.styles';
import { isRangeFilterActive } from 'common/components/FiltersMenu/RangeFilter/rangeFilter.utils';
import { RangeFilterTextField } from 'common/components/FiltersMenu/RangeFilter/RangeFilterTextField';
import { ExpandedIcon } from 'common/components/Icons/Icons';

type Props = {
  filter: BaseFilter;
  handleFilterChange: (filter: BaseFilter) => void;
};

export const RangeFilterMenuItem = ({ filter, handleFilterChange }: Props) => {
  const rangeFilter = filter as RangeFilter;
  const { label } = rangeFilter;
  const {
    clearSelections,
    containerRef,
    isOpen,
    maxValue,
    minValue,
    toggle,
    updateMaxValue,
    updateMinValue,
  } = useRangeFilter(rangeFilter, handleFilterChange);

  return (
    <FilterItem>
      <Wrapper ref={containerRef}>
        <FilterButton isSelected={isOpen} onClick={toggle} small type="button">
          <FilterTitleWrapper>{label}</FilterTitleWrapper>
          <FilterTitleWrapper>
            {isRangeFilterActive(rangeFilter) && (
              <Clear
                onClick={(e) => {
                  e.stopPropagation();
                  clearSelections();
                }}
              >
                Clear
              </Clear>
            )}
            <ExpandedIcon open={isOpen} />
          </FilterTitleWrapper>
        </FilterButton>

        <FilterCollapse in={isOpen} unmountOnExit>
          <RangeFilterWrapperInner>
            <RangeFilterTextField
              placeholder="Min"
              rangeFilter={rangeFilter}
              updateValue={updateMinValue}
              value={minValue}
            />
            <DashContainer>
              <Dash />
            </DashContainer>
            <RangeFilterTextField
              placeholder="Max"
              rangeFilter={rangeFilter}
              updateValue={updateMaxValue}
              value={maxValue}
            />
          </RangeFilterWrapperInner>
        </FilterCollapse>
      </Wrapper>
    </FilterItem>
  );
};
