import { useLayoutEffect } from 'react';
import * as React from 'react';

import { HorizontalAlignment, VerticalAlignment } from 'common/enums/Alignment';

import { useGridTableProps } from './GridTable.hooks';
import { Thead, RowProps } from './GridTable.styles';

export type Props = RowProps & {
  children: React.ReactElement | React.ReactElement[];
  className?: string;
  columnsInPrintMode?: number;
  hAlignment?: HorizontalAlignment;
  tabIndex?: number;
  vAlignment?: VerticalAlignment;
};

export const GridTableHead = ({ children, className, ...rest }: Props) => {
  const { columnsWidth, setColumnsWidth } = useGridTableProps();

  useLayoutEffect(() => {
    const width: string[] =
      React.Children.map(
        children,
        (element) => (element && element.props.width) || '1fr',
      ) || [];
    setColumnsWidth(width);
  }, [children, setColumnsWidth]);

  return (
    <Thead
      {...rest}
      className={className}
      columnsWidth={columnsWidth}
      role="row"
    >
      {children}
    </Thead>
  );
};

export const GridTableFocusableHead = (props: Props) => (
  <GridTableHead {...props} tabIndex={0} />
);
