import { useCallback, useLayoutEffect, useState, useRef } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

export const useQueryParam = (paramKey: string) => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const paramsRef = useRef(new URLSearchParams(search));
  const [queryParamValue, setQueryParamValue] = useState(() =>
    paramsRef.current.get(paramKey),
  );

  useLayoutEffect(() => {
    paramsRef.current = new URLSearchParams(search);
    const currentParamValue = paramsRef.current.get(paramKey);

    if (currentParamValue === queryParamValue) return;
    setQueryParamValue(currentParamValue);
  }, [search, queryParamValue, paramKey, setQueryParamValue, paramsRef]);

  const swapLocationParamValue = useCallback(
    (paramValue: string) => {
      paramsRef.current.set(paramKey, paramValue);

      history.replace({
        search: paramsRef.current.toString(),
      });
    },
    [history, paramKey],
  );

  const removeLocationParam = useCallback(() => {
    paramsRef.current.delete(paramKey);

    history.replace({ pathname, search: paramsRef.current.toString() });
  }, [pathname, history, paramKey]);

  const isMatchQuery = useCallback(
    (toMatch: string) => queryParamValue === toMatch,
    [queryParamValue],
  );

  return {
    isMatchQuery,
    queryParamValue,
    removeLocationParam,
    swapLocationParamValue,
  };
};

export const useQueryParams = <T = string>(
  paramKey: string,
): [T | null, (value: T) => void] => {
  const { replace } = useHistory();
  const { pathname, search } = useLocation();
  const paramsRef = useRef(new URLSearchParams(search));
  const [query, setQuery] = useState<T | null>(
    () => paramsRef.current.get(paramKey) as T | null,
  );

  useLayoutEffect(() => {
    paramsRef.current = new URLSearchParams(search);
  }, [search]);

  const setQueryParams = useCallback(
    (value: T) => {
      if (value) {
        paramsRef.current.set(paramKey, String(value));
      } else {
        paramsRef.current.delete(paramKey);
      }

      replace({
        pathname,
        search: paramsRef.current.toString(),
      });

      setQuery(value);
    },

    [paramKey, pathname, replace],
  );

  return [query, setQueryParams];
};
