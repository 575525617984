import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  &:first-of-type {
    margin-top: 16px;
  }
`;
