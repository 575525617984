import { AUTH_PATHS } from 'common/routes/routerPaths';

export const UPDATE_PROJECT_PROFILE_SUCCESS = 'Data saved successfully';
export const EmptyCopy = {
  OPTIONAL_FIELD: '(If Applicable)',
  REQUIRED_FIELD: '(Required)',
};

export enum ProjectProfileSubSection {
  BUILDING_INFORMATION = 'Building Information',
  CLIENT_DETAILS = 'Homeowner Details',
  CONTACT_INFO = 'Contact Info',
  DURING_DESIGN = 'During Design',
  HOMEOWNER_LOOKS = "Homeowner's Selected Looks From the Quiz",
  LINKS = 'Links',
  LOOKS = 'Looks',
  PRE_KICKOFF = 'Pre-Kickoff',
  SITE_SURVEY = 'Site Survey',
  SUMMARY = 'Summary',
}

export const PROJECT_PROFILE_TABS = [
  {
    label: 'Project Information',
    path: AUTH_PATHS.getProjectProfileInformationPath,
  },
  {
    label: 'Building Information',
    path: AUTH_PATHS.getProjectProfileBuildingInformationPath,
  },
  {
    label: 'Pre-Design',
    path: AUTH_PATHS.getProjectProfilePreDesignPath,
  },
  {
    label: 'View All Notes',
    path: AUTH_PATHS.getProjectProfileNotesPath,
  },
  {
    label: 'Change Log',
    path: AUTH_PATHS.getProjectProfileChangeLogPath,
  },
];

export const PROJECT_PROFILE_HOMEOWNER_ONBOARDING_V2_TABS = [
  {
    label: 'Project Information',
    path: AUTH_PATHS.getProjectProfileInformationPath,
  },
];
