import { array, object, string } from 'yup';

import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';
import { RoomType } from 'common/apollo/enums/RoomType';

export const identifiers = [PropertyGroupIdentifier.DESIGNER_NOTES];

export const validationSchema = object().shape({
  designerNotesEdit: array().of(
    object().shape({
      pricingEngineId: string().required(),
      value: string(),
    }),
  ),
});

export const roomTypeNotes = {
  [RoomType.BATHROOM]: `Designer to reiterate what sales communicated, voice over design of Look, positives and specifics of products listed below. 

Vanity: does the function and color align with their preference? 

Plumbing fixtures: does the color match their preference?

Mirror/med cab: does the spec match their preference?

Lighting: does the color match their preference / accommodate their space?

Wall tile: does tile coverage match their preference?

Floor tile: does the color match their preference?

- Reiterate the functional requirements that was previously discussed (e.g., storage, maintenance)

- Does the space require more storage/bigger vanity than the Look shows?

- Wet zone: Rain head? Hand shower? Niche?`,
  [RoomType.KITCHEN]: `Designer to voice over design of Look, positives and specifics of products listed below. 

Cabinets: do the profile and  color/tone match their preference? Note to confirm if color is available in their desired Group. 

Counter: does the color match their preference?

Backsplash: does the color match their preference?

Hardware: does the color/type match their preference?

Wall tile: does tile coverage match their preference?

Floor tile: does the color match their preference?

General Kitchen Notes 
- Layout and functional requirements? (e.g., storage, maintenance)’

- Will the design require more cabinets than there are currently?

- Appliances: Brands and needs?
  - Adding appliances may require an electrical panel replacement
  
  - Do they have appliances already? If so, make sure homeowner sends prior to design session
  
  - Are they changing the size of any appliances? If so, does it look like it will fit?
  
  - Refrigerator: Do they need a waterline or ice maker? If so, is there an existing waterline?

- Lighting + Electrical:
  - Does their current lighting conditions meet their needs?
  
  - Is additional electrical scope anticipated? 

- Appliances:
  - Do they have a microwave? Range hood? MW hood?
  
  - Does their hood vent to the exterior?
  
-  Your own list of questions that will be critical for a successful design session
`,
};
