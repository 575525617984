import { object, array, string } from 'yup';

import { REQUIRED_VALIDATION } from 'common/consts/validationMessages';

export const validationSchema = object().shape({
  projectPlanRoomsLinks: array().of(
    object().shape({
      pricingEngineId: string(),
      url: string()
        .url('Canvas link must be a valid URL')
        .required(REQUIRED_VALIDATION),
    }),
  ),
});

export const emptyLink = { name: '', pricingEngineId: '', url: '' };
