import { KeyDateStatus } from 'common/enums/KeyDateStatus';
import { KeyDateType } from 'common/apollo/enums/KeyDateType';
import { Variant } from 'common/shared/Tag/Tag';
import { StageIdentifier } from 'common/apollo/enums/StageIdentifier';

import { DesignApprovalHistory } from 'ClientTasks/TargetDate/TargetDateHistoryList/DesignApprovalHistory/DesignApprovalHistory';
import { BreakgroundHistory } from 'ClientTasks/TargetDate/TargetDateHistoryList/BreakgroundHistory/BreakgroundHistory';

export const targetDateStatusVariant = {
  [KeyDateStatus.COMPLETED]: Variant.completed,
  [KeyDateStatus.SET]: Variant.success,
  [KeyDateStatus.OVERDUE]: Variant.danger,
};

export const targetDateStatus = {
  [KeyDateStatus.COMPLETED]: 'Completed',
  [KeyDateStatus.SET]: 'On Track',
};

/* TODO - subject to change with following releases */
export const stageNamesMap = {
  BUILD: KeyDateType.BUILD_COMPLETION,
  DESIGN: KeyDateType.DESIGN_APPROVAL,
  PERMITS: KeyDateType.BREAK_GROUND,
};

/* TODO - subject to change with following releases */
export const targetDateHeader = {
  BUILD: 'Build Completion Target Date',
  DESIGN: 'Design Approval Target Date',
  PERMITS: 'Break Ground Target Date',
};

export const StageNameToKeyDateType = {
  [StageIdentifier.BUILD]: 'Build Completion',
  [StageIdentifier.DESIGN]: 'Design Approval',
  [StageIdentifier.PERMITS]: 'Break Ground',
};

export const HistoryListMap = {
  [StageIdentifier.BUILD]: DesignApprovalHistory, // temporary fill
  [StageIdentifier.DESIGN]: DesignApprovalHistory,
  [StageIdentifier.PERMITS]: BreakgroundHistory,
};

export const SET_TARGET_DATE_MODAL = 'SET_TARGET_DATE_MODAL';
export const SHIFT_TARGET_DATE_MODAL = 'SHIFT_TARGET_DATE_MODAL';
export const UNCONFIRM_BREAK_GROUND_MODAL = 'UNCONFIRM_BREAK_GROUND_MODAL';
