import styled from '@emotion/styled';
import { Grid, GridProps } from '@material-ui/core';

import { ReactNode } from 'react';

const SPACING = 50;

const GridLinkContainer = styled(Grid)`
  margin: -${SPACING / 2}px;
  width: calc(100% + ${SPACING}px);

  > .MuiGrid-item {
    padding: 10px ${SPACING / 2 - 1}px;
  }
`;

type Props = {
  children: ReactNode;
} & GridProps;

export const LinkContainer = ({ children, ...gridProps }: Props) => (
  <GridLinkContainer container {...gridProps}>
    {children}
  </GridLinkContainer>
);
