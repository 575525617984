import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_PROJECT_PROCUREMENT_SHIPMENTS } from 'Project/Procurement/Shipments/shipments.graphql';

import { GetProjectProcurementShipmentsRequest } from 'Project/Procurement/common/apollo/requests/getProjectProcurementShipmentsRequest';
import { GetProjectProcurementShipmentsResponse } from 'Project/Procurement/common/apollo/responses/getProjectProcurementShipmentsResponse';

export const useShipments = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const {
    data,
    error,
    loading: isLoading,
  } = useQuery<
    GetProjectProcurementShipmentsResponse,
    GetProjectProcurementShipmentsRequest
  >(GET_PROJECT_PROCUREMENT_SHIPMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectId,
    },
  });

  const shipments = data?.project.shipments || [];

  return { error, isLoading, projectId, shipments };
};
