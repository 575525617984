import styled from '@emotion/styled';

import { css, Theme } from '@emotion/react';

export type Variant =
  | 'gray'
  | 'orange'
  | 'purple'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'red';

export type Size = 'small' | 'medium' | 'large';

const getVariant = (variant: Variant, theme: Theme) => {
  const variants: Record<
    Variant,
    { background: string; borderColor: string; color: string }
  > = {
    blue: {
      background: `${theme.color.blue2}`,
      borderColor: `${theme.color.blue5}`,
      color: `${theme.color.blue11}`,
    },
    gray: {
      background: `${theme.color.gray2}`,
      borderColor: `${theme.color.gray5}`,
      color: `${theme.color.gray11}`,
    },
    green: {
      background: `${theme.color.green2}`,
      borderColor: `${theme.color.green5}`,
      color: `${theme.color.green11}`,
    },
    orange: {
      background: `${theme.color.orange2}`,
      borderColor: `${theme.color.orange5}`,
      color: `${theme.color.orange11}`,
    },
    purple: {
      background: `${theme.color.purple2}`,
      borderColor: `${theme.color.purple5}`,
      color: `${theme.color.purple11}`,
    },
    red: {
      background: `${theme.color.red2}`,
      borderColor: `${theme.color.red5}`,
      color: `${theme.color.red11}`,
    },
    yellow: {
      background: `${theme.color.yellow2}`,
      borderColor: `${theme.color.yellow5}`,
      color: `${theme.color.yellow11}`,
    },
  };

  return variants[variant];
};

const sizeMap: Record<Size, string> = {
  large: '8px 16px',
  medium: '4px 12px',
  small: '2px 8px',
};

type Props = {
  size?: Size;
  variant: Variant;
};

export const Badge = styled.div<Props>`
  ${({ size = 'small', theme, variant }) => css`
    ${size === 'small'
      ? theme.typography.buttonText
      : theme.typography.header6};

    align-items: center;
    border-radius: 100px;
    border: 1px solid;
    display: flex;
    gap: 8px;
    padding: ${sizeMap[size]};
    text-transform: capitalize;
    width: fit-content;
    margin-bottom: 5px;

    ${getVariant(variant, theme)};
  `}
`;
