import Dialog from '@material-ui/core/Dialog';

import DialogTitle from '@material-ui/core/DialogTitle';

import { ModalCloseButton } from 'common/components/Modals/ModalCloseButton';

import { ConfirmationDialog } from 'ProjectProfile/BuildingInformation/common/components/ConfirmationDialog/ConfirmationDialog';
import { Section } from 'ProjectProfile/common/components/Section/Section';

import { BuildingAgeEdit } from './BuildingAgeEdit/BuildingAgeEdit';
import { useBuildingAge } from './buildingAge.hooks';

type Props = {
  value: string | null;
};

export const BuildingAge = ({ value }: Props) => {
  const {
    dropdownOptions,
    editMode,
    handleClose,
    handleEditFormOpen,
    isOpen,
    open,
    questionId,
    selectedAnswerId,
  } = useBuildingAge();

  return (
    <>
      <Section onEditClick={open} title="Building Age">
        {value}
      </Section>

      <Dialog fullWidth maxWidth="sm" open={isOpen}>
        <ModalCloseButton onClick={handleClose} />

        {editMode ? (
          <>
            <DialogTitle>Building Age</DialogTitle>
            <BuildingAgeEdit
              answerId={selectedAnswerId}
              onClose={handleClose}
              options={dropdownOptions}
              questionId={questionId}
            />
          </>
        ) : (
          <ConfirmationDialog
            onClose={handleClose}
            onProceed={handleEditFormOpen}
          >
            Changing building type and building age will result in change of
            pricing. Do you still want to proceed?
          </ConfirmationDialog>
        )}
      </Dialog>
    </>
  );
};
