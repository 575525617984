import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mq } from 'common/utils/mediaQueries';

export const Wrapper = styled.div`
  height: 100%;
  overflow: auto;
`;

export const WrapperInner = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
  padding: 24px;

  ${mq.small(css`
    grid-template-columns: 1fr 1fr;
  `)};

  ${mq.large(css`
    grid-template-columns: repeat(3, 1fr);
  `)};
`;
