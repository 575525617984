import { gql } from '@apollo/client';

export const GET_REFERRAL_CAMPAIGNS = gql`
  query GetReferralCampaigns {
    referralCampaigns {
      id
      name
      codePrefix
    }
  }
`;
