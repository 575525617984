import { ReactNode } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FeatureFlags } from 'common/enums/FeatureFlags';

type Props = {
  children: ReactNode;
  id: FeatureFlags;
};

export const FeatureFlag = ({ children, id }: Props) => {
  const flags = useFlags();
  const flag = flags[id];
  const isUnset = flag === undefined;

  const shouldRenderFeature = flag || isUnset;

  return shouldRenderFeature ? <>{children}</> : null;
};
