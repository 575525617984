import { ReactElement } from 'react';

import { Helmet } from 'react-helmet';

type Props = {
  children: ReactElement;
  isLoading?: boolean;
  title?: string;
};

const DEFAULT_PAGE_TITLE = 'Block Admin';

export const SeoManager = ({
  children,
  isLoading,
  title = DEFAULT_PAGE_TITLE,
}: Props) => (
  <>
    <Helmet title={isLoading ? DEFAULT_PAGE_TITLE : title} />
    {children}
  </>
);
