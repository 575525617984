import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';

export const IconButtonStyled = styled(IconButton)`
  && {
    position: absolute;
    padding: 0;
    top: 20px;
    right: 20px;
  }
`;
