import { ProjectInformation as ProjectInformationV1 } from 'ProjectProfile/ProjectInformation/v1/ProjectInformation';
import { ProjectInformation as ProjectInformationV2 } from 'ProjectProfile/ProjectInformation/v2/ProjectInformation';

import { useProjectInformation } from 'ProjectProfile/ProjectInformation/projectInformation.hooks';

const ProjectInformation = () => {
  const { isProjectInformationV2 } = useProjectInformation();

  return isProjectInformationV2 ? (
    <ProjectInformationV2 />
  ) : (
    <ProjectInformationV1 />
  );
};

export default ProjectInformation;
