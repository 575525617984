// TOOD: Rename to InputSize
export enum TextFieldSize {
  EXTRA_LARGE = 'extra_large',
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export enum TextFieldVariant {
  FILLED = 'filled',
  OUTLINED = 'outlined',
  STANDARD = 'standard',
}
