import styled from '@emotion/styled';

type Props = {
  isPostDesignContractorAssignment: boolean;
};

export const Container = styled.div<Props>`
  background-color: ${({ isPostDesignContractorAssignment, theme }) =>
    isPostDesignContractorAssignment ? theme.color.yellow2 : theme.color.gray3};
  border: 0.5px solid
    ${({ isPostDesignContractorAssignment, theme }) =>
      isPostDesignContractorAssignment
        ? theme.color.yellow6
        : theme.color.gray6};
  display: flex;
  flex-direction: column;
  padding: 15px 10px 15px 12px;
  margin: 8px;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const Title = styled.h3<Props>`
  color: ${({ isPostDesignContractorAssignment, theme }) =>
    isPostDesignContractorAssignment
      ? theme.color.yellow12
      : theme.color.gray11};
  font: 500 12px/16px ${({ theme }) => theme.font.inter};
  margin-left: 10px;
`;

export const Message = styled.p`
  font: normal 14px/20px ${({ theme }) => theme.font.inter};
  margin-left: 20px;
  margin-top: 8px;
`;
