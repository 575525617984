/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
export enum ANCHOR_TYPE {
  left = 'left',
  right = 'right',
}

export enum VARIANT_TYPE {
  permanent = 'permanent',
  persistent = 'persistent',
  temporary = 'temporary',
}

export type AnchorType = 'left' | 'right' | 'top' | 'bottom';
export type VariantType = 'permanent' | 'persistent' | 'temporary';
