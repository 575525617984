import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';

import { PROPOSAL_MATERIALS_QUANTITIES_VALIDATION_MODAL } from 'PricingEngineSidebar/Proposal/ProposalAction/proposalAction.consts';
import { GetProposalSideBardActionDataPricingEngine } from 'PricingEngineSidebar/Proposal/ProposalAction/__generated__/proposalAction.graphql.generated';

export const useProposalMaterialsQuantitiesError = (
  numberOfPricingEngines: number,
  affectedPricingEngines: GetProposalSideBardActionDataPricingEngine[],
) => {
  const setDialogOpen = useSetModalOpen();

  const onModalCloseButton = () =>
    setDialogOpen(PROPOSAL_MATERIALS_QUANTITIES_VALIDATION_MODAL, false);

  const roomNumberValidation = numberOfPricingEngines > 1;
  const materialsQuantityValidation = !!affectedPricingEngines.length;

  return {
    materialsQuantityValidation,
    onModalCloseButton,
    roomNumberValidation,
  };
};
