import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useToggle } from 'common/hooks/useToggle';

import { AUTH_PATHS } from 'common/routes/routerPaths';

import { GetProjectProcurementMaterialsRequest } from 'Project/Procurement/common/apollo/requests/getProjectProcurementMaterialsRequest';
import { GetProjectProcurementMaterialsResponse } from 'Project/Procurement/common/apollo/responses/getProjectProcurementMaterialsResponse';

import { GetProjectProcurementMaterialsFiltersRequest } from 'Project/Procurement/common/apollo/requests/getProjectProcurementMaterialsFiltersRequest';
import { GetProjectProcurementMaterialsFiltersResponse } from 'Project/Procurement/common/apollo/responses/getProjectProcurementMaterialsFiltersResponse';

import {
  GET_PROJECT_PROCUREMENT_MATERIALS,
  GET_PROJECT_PROCUREMENT_MATERIALS_FILTERS,
} from 'Project/Procurement/Materials/materials.graphql';

import {
  normalizeProcurementMaterialsData,
  normalizeProcurementMaterialsFiltersData,
} from 'Project/Procurement/Materials/materials.utils';

import { Material } from 'Project/Procurement/Materials/materials.types';
import { useTableSort } from 'common/hooks/useTableSort';
import { FeatureFlags } from 'common/enums/FeatureFlags';

const useSelect = <T, K extends keyof T>(items: T[], key: K) => {
  const itemsIds = useMemo(
    () => items.map((value) => value[key]),
    [key, items],
  );

  const [selected, setSelected] = useState<T[K][]>([]);

  const setIsSelected = (e: ChangeEvent<{ checked: boolean; id: T[K] }>) => {
    const { checked, id } = e.target;

    if (checked) return setSelected((prev) => [...prev, id]);

    setSelected((prev) => prev.filter((itemId) => itemId !== id));
  };

  const isAllSelected = items.every((value) => selected.includes(value[key]));

  const setIsAllSelected = () =>
    isAllSelected ? setSelected([]) : setSelected(itemsIds);

  useEffect(() => {
    setSelected([]);
  }, [items, setSelected]);

  return {
    isAllSelected,
    selected,
    setIsAllSelected,
    setIsSelected,
  };
};

export const useMaterials = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { projectId } = useParams<{ projectId: string }>();

  const flags = useFlags();
  const postDsoEnabled = flags[FeatureFlags.POST_DSO];

  const [materials, setMaterials] = useState<Material[]>([]);

  const [selectedMaterial, setSelectedMaterial] = useState<Material | null>(
    null,
  );

  const { handleToggleSort, sortColumn, sortItems } = useTableSort<Material>();

  const { isAllSelected, selected, setIsAllSelected, setIsSelected } =
    useSelect(materials, 'id');

  const {
    isOn: isAddMaterialsModalOpen,
    toggleOff: handleCloseAddMaterialsModal,
    toggleOn: handleOpenAddMaterialsModal,
  } = useToggle();

  const {
    isOn: isMaterialNotesUpdateModalOpen,
    toggleOff: closeMaterialNotesUpdateModal,
    toggleOn: openMaterialNotesUpdateModal,
  } = useToggle();

  const {
    isOn: isMaterialUpdateModalOpen,
    toggleOff: closeMaterialUpdateModal,
    toggleOn: openMaterialUpdateModal,
  } = useToggle();

  const {
    isOn: isMaterialStockStatusUpdateModalOpen,
    toggleOff: closeMaterialStockStatusUpdateModal,
    toggleOn: openMaterialStockStatusUpdateModal,
  } = useToggle();

  const {
    data,
    error,
    loading: isLoading,
  } = useQuery<
    GetProjectProcurementMaterialsResponse,
    GetProjectProcurementMaterialsRequest
  >(GET_PROJECT_PROCUREMENT_MATERIALS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      projectId,
    },
  });

  const { data: filtersData } = useQuery<
    GetProjectProcurementMaterialsFiltersResponse,
    GetProjectProcurementMaterialsFiltersRequest
  >(GET_PROJECT_PROCUREMENT_MATERIALS_FILTERS, {
    variables: {
      projectId,
    },
  });

  const procurementMaterials = useMemo(
    () => normalizeProcurementMaterialsData(data?.project.materials),
    [data],
  );

  const { pricingEngines, subcategories, vendors } =
    normalizeProcurementMaterialsFiltersData(filtersData, procurementMaterials);

  useEffect(() => {
    if (!procurementMaterials.length) return;

    const roomName = new URLSearchParams(search).get('roomName');
    const subcategoryId = new URLSearchParams(search).get('subcategoryId');
    const vendorId = new URLSearchParams(search).get('vendorId');
    const status = new URLSearchParams(search).get('status');
    const stockStatus = new URLSearchParams(search).get('stockStatus');

    const filteredMaterials = procurementMaterials.filter((material) =>
      Object.entries({ roomName, status, stockStatus, subcategoryId, vendorId })
        .filter(([_, value]) => Boolean(value))
        .every(([key, value]) => material[key as keyof Material] === value),
    );

    const sortedMaterials = sortItems(filteredMaterials);
    setMaterials(sortedMaterials);
  }, [procurementMaterials, search, sortItems]);

  const handleCreateOrder = () => {
    if (!selected.length) return;

    push({
      pathname: AUTH_PATHS.getProjectProcurementCreateOrderPath(projectId),
      search: `ids=${selected.join(',')}`,
    });
  };

  const handleEditLeadTime = () => {
    if (!selected.length) return;

    push({
      pathname:
        AUTH_PATHS.getProjectProcurementEditMaterialsLeadTimePath(projectId),
      search: `ids=${selected.join(',')}`,
    });
  };

  const handleCloseMaterialUpdateModal = () => {
    setSelectedMaterial(null);
    closeMaterialUpdateModal();
  };

  const handleOpenMaterialUpdateModal = (id: string) => {
    const material = materials.find((value) => value.id === id);

    if (!material) return;

    setSelectedMaterial(material);
    openMaterialUpdateModal();
  };

  const handleOpenMaterialNotesUpdateModal = (id: string) => {
    const material = materials.find((value) => value.id === id);

    if (!material) return;

    setSelectedMaterial(material);
    openMaterialNotesUpdateModal();
  };

  const handleCloseMaterialNotesUpdateModal = () => {
    setSelectedMaterial(null);
    closeMaterialNotesUpdateModal();
  };

  const handleOpenMateriaStockStatusUpdateModal = (id: string) => {
    const material = materials.find((value) => value.id === id);

    if (!material) return;

    setSelectedMaterial(material);
    openMaterialStockStatusUpdateModal();
  };

  const handleCloseMaterialStockStatusUpdateModal = () => {
    setSelectedMaterial(null);
    closeMaterialStockStatusUpdateModal();
  };

  const proposalName = data?.project.firstAcceptedProcurementProposal?.name;
  const materialsGeneratedAtDate =
    data?.project.materialsAutomaticallyGeneratedAt;

  return {
    error,
    handleCloseAddMaterialsModal,
    handleCloseMaterialNotesUpdateModal,
    handleCloseMaterialStockStatusUpdateModal,
    handleCloseMaterialUpdateModal,
    handleCreateOrder,
    handleEditLeadTime,
    handleOpenAddMaterialsModal,
    handleOpenMateriaStockStatusUpdateModal,
    handleOpenMaterialNotesUpdateModal,
    handleOpenMaterialUpdateModal,
    handleToggleSort,
    isAddMaterialsModalOpen,
    isAllSelected,
    isLoading,
    isMaterialNotesUpdateModalOpen,
    isMaterialStockStatusUpdateModalOpen,
    isMaterialUpdateModalOpen,
    materials,
    materialsGeneratedAtDate,
    postDsoEnabled,
    pricingEngines,
    projectId,
    proposalName,
    selected,
    selectedMaterial,
    setIsAllSelected,
    setIsSelected,
    sortColumn,
    subcategories,
    vendors,
  };
};
