import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { useMutation, ApolloError, DataProxy } from '@apollo/client';

import { useAlert } from 'common/hooks/useAlert';

import { AUTH_PATHS } from 'common/routes/routerPaths';

import { REMOVE_PRICING_ENGINE } from 'PricingEngineSidebar/Proposal/PricingEngines/pricingEngines.graphql';
import { updateProposalCacheOnPricingEngineDelete } from 'PricingEngineSidebar/pricingEngineSidebar.state';

import {
  PricingEngineRemove,
  PricingEngineRemoveVariables,
} from 'PricingEngineSidebar/Proposal/PricingEngines/__generated__/pricingEngines.graphql.generated';

export const usePricingEngines = () => {
  const { push } = useHistory();

  const { pricingEngineId, proposalId } = useParams<{
    pricingEngineId?: string;
    proposalId: string;
  }>();
  const { onCompleted, onError } = useAlert();

  const [removePricingEngine] = useMutation<
    PricingEngineRemove,
    PricingEngineRemoveVariables
  >(REMOVE_PRICING_ENGINE, {
    onCompleted: () => onCompleted('Successfully removed '),
    onError: (error: ApolloError) => onError(error.message),
  });

  const handleRemovePricingEngine = (id: string) => () => {
    removePricingEngine({
      update: (proxy: DataProxy) =>
        updateProposalCacheOnPricingEngineDelete(proxy, id, proposalId),
      variables: { input: { pricingEngineId: id } },
    });
  };

  const handleRedirectToPricingEngine = (id: string) => () => {
    push(
      AUTH_PATHS.getPricingEngineRoomInfoPath({
        pricingEngineId: id,
        proposalId,
      }),
    );
  };

  const handleRedirectToProjectOverview = () => {
    push(AUTH_PATHS.getProjectOverviewPath(proposalId));
  };

  const isActive = (id: string) => pricingEngineId === id;

  const isProjectOverviewActive = !!useRouteMatch(
    AUTH_PATHS.getProjectOverviewPath(),
  );

  return {
    handleRedirectToPricingEngine,
    handleRedirectToProjectOverview,
    handleRemovePricingEngine,
    isActive,
    isProjectOverviewActive,
  };
};
