import { Box, Grid } from '@material-ui/core';

import { Button } from 'common/components/Buttons/Button/Button';
import { Dialog } from 'common/components/Modals/Dialog/Dialog';
import { DialogContent } from 'common/components/Modals/Dialog/DialogContent';
import { DatePicker } from 'common/components/Form/DatePicker/DatePicker';
import { TextField } from 'common/components/Form/TextField/TextField';
import { Typography } from 'common/components/Typography/Typography';

import { ReactComponent as SetModalNote } from 'common/assets/icons/lightbulb-yellow.svg';

import { StageIdentifier } from 'common/apollo/enums/StageIdentifier';

import { StageNameToKeyDateType } from 'ClientTasks/TargetDate/targetDate.consts';
import { SET_TARGET_DATE_MODAL } from 'ClientTasks/TargetDate/SetTargetDateModal/setTargetDateModal.consts';
import { useSetTargetDateModal } from 'ClientTasks/TargetDate/SetTargetDateModal/setTargetDateModal.hooks';
import {
  AdditionalNotes,
  ButtonWrapper,
  DialogTitle,
  InputTitle,
  OptionalTitle,
  Status,
  StatusMessageWrapper,
  Wrapper,
} from 'ClientTasks/TargetDate/common/targetDateModal.styles';
import { getModalId } from 'ClientTasks/TargetDate/common/targetDateModal.utils';

type Props = {
  stageIdentifier: StageIdentifier;
};

export const SetTargetDateModal = ({ stageIdentifier }: Props) => {
  const {
    handleCloseDialog,
    handleSave,
    handleSetDate,
    handleSetDays,
    handleSetNote,
    isValid,
    note,
    targetDate,
    targetDays,
    validateBreakGroundMinimumDays,
  } = useSetTargetDateModal(stageIdentifier);
  const modalId = getModalId(SET_TARGET_DATE_MODAL, stageIdentifier);
  const keyDateType =
    StageNameToKeyDateType[
      stageIdentifier as keyof typeof StageNameToKeyDateType
    ];

  return (
    <Dialog
      disableBackdropClick
      fullWidth
      id={modalId}
      maxWidth="sm"
      onClose={handleCloseDialog}
    >
      <DialogTitle>
        <Typography type="h5">Add {keyDateType} Target Date</Typography>
      </DialogTitle>
      {validateBreakGroundMinimumDays && (
        <Wrapper isBordered>
          <Status isLate={false} isNotifying>
            <StatusMessageWrapper>
              <SetModalNote />
              Over the past three months, the average time it takes for
              homeowners to reach Break Ground is <b>150 days (5 months)</b>.
            </StatusMessageWrapper>
          </Status>
        </Wrapper>
      )}
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Grid
            alignItems="center"
            container
            justify="space-between"
            spacing={2}
          >
            <Grid item xs={6}>
              <InputTitle type="h6">Set Target {keyDateType} (Date)</InputTitle>
              <DatePicker
                onChange={handleSetDate}
                placeholder="MM/DD/YYYY"
                value={targetDate}
              />
            </Grid>

            <Grid item xs={6}>
              <InputTitle type="h6">Set Target {keyDateType} (Days)</InputTitle>
              <TextField
                isFullWidth
                onChange={handleSetDays}
                placeholder="Number of days"
                type="number"
                value={targetDays || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <InputTitle type="h6">Additional Notes</InputTitle>
              <OptionalTitle type="h6">(Optional)</OptionalTitle>
              <AdditionalNotes
                isFullWidth
                isMultiline
                onChange={handleSetNote}
                placeholder="Add notes here"
                rows={4}
                value={note || ''}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <ButtonWrapper>
        <Button
          color="primary"
          disabled={!isValid}
          onClick={handleSave}
          small
          variant="contained"
        >
          Add Target Date
        </Button>
      </ButtonWrapper>
    </Dialog>
  );
};
