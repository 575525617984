import { gql } from '@apollo/client';

export const GET_PROJECT_TYPE = gql`
  query GetProjectType($id: ID!) {
    project(id: $id) {
      id
      projectType
      isContractorChoice
      projectFlags
      plannerId
    }
  }
`;
