import { ApolloLink } from '@apollo/client';

import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from 'common/consts/localStorage';

export const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);

  operation.setContext(({ headers = {} }: any) => ({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      ...headers,
    },
  }));

  return forward(operation);
});
