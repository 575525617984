import {
  Wrapper,
  Label,
  Control,
  Description,
} from 'common/_components/Form/TextField/textField.styles';

type TextFieldType =
  | 'text'
  | 'number'
  | 'email'
  | 'password'
  | 'phone'
  | 'textarea';

type TextFieldProps = {
  description?: string;
  fluid?: boolean;
  hasError?: boolean;
  id: string;
  label?: string;
  readonly?: boolean;
  type?: TextFieldType;
  value?: string;
};

type Props = TextFieldProps &
  Exclude<Omit<React.HTMLProps<HTMLInputElement>, 'as'>, TextFieldProps>;

export const TextField = ({
  description,
  disabled,
  fluid,
  hasError,
  id,
  label,
  readonly,
  type = 'text',
  ...rest
}: Props) => (
  <Wrapper fluid={fluid}>
    {label && (
      <Label disabled={disabled} hasError={hasError} htmlFor={id}>
        {label}
      </Label>
    )}

    <Control
      disabled={disabled}
      hasError={hasError}
      id={id}
      readOnly={readonly}
      type={type}
      {...rest}
    />

    {description && !hasError && <Description>{description}</Description>}
  </Wrapper>
);
