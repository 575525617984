import { ProjectKeyDateInputType } from 'common/apollo/enums/ProjectKeyDateInputType';

import { BreakGroundDateDisabled } from './BreakGroundDateDisabled';
import { BreakGroundDateEditable } from './BreakGroundDateEditable';
import { BreakGroundDateInitial } from './BreakGroundDateInitial';
import { BreakGroundDateLikeliestOnly } from './BreakGroundDateLikeliestOnly';

export const ProjectKeyDateInputTypeDictionary = {
  [ProjectKeyDateInputType.INITIAL]: 'Initial',
  [ProjectKeyDateInputType.PROJECTED]: 'Projected',
  [ProjectKeyDateInputType.SCHEDULED]: 'Scheduled',
  [ProjectKeyDateInputType.ACTUAL]: 'Actual',
};

export enum BreakGroundStates {
  DISABLED = 'DISABLED',
  EDITABLE = 'EDITABLE',
  INITIAL = 'INITIAL',
  LIKELIEST_ONLY = 'LIKELIEST_ONLY',
}

export const breakGroundComponent = {
  [BreakGroundStates.DISABLED]: BreakGroundDateDisabled,
  [BreakGroundStates.EDITABLE]: BreakGroundDateEditable,
  [BreakGroundStates.INITIAL]: BreakGroundDateInitial,
  [BreakGroundStates.LIKELIEST_ONLY]: BreakGroundDateLikeliestOnly,
};
