export type PayoutScheduleRouteParams = {
  payoutScheduleId: string;
  payoutScheduleTermId: string;
  renovationPlanId: string;
  userId: string;
};

const getPayoutScheduleEntryPath = ({
  renovationPlanId = ':renovationPlanId',
  userId = ':userId',
} = {}) =>
  `/users/${userId}/renovation-plans/${renovationPlanId}/payout-schedule`;

const getPayoutSchedulePath = ({
  payoutScheduleId = ':payoutScheduleId',
  renovationPlanId = ':renovationPlanId',
  userId = ':userId',
} = {}) =>
  `/users/${userId}/renovation-plans/${renovationPlanId}/payout-schedule/${payoutScheduleId}`;

const getPayoutScheduleTermPath = ({
  payoutScheduleId = ':payoutScheduleId',
  payoutScheduleTermId = ':payoutScheduleTermId',
  renovationPlanId = ':renovationPlanId',
  userId = ':userId',
} = {}) =>
  `/users/${userId}/renovation-plans/${renovationPlanId}/payout-schedule/${payoutScheduleId}/payout-schedule-term/${payoutScheduleTermId}`;

export const PAYOUT_SCHEDULE_PATHS = {
  getPayoutScheduleEntryPath,
  getPayoutSchedulePath,
  getPayoutScheduleTermPath,
};
