import { Fragment } from 'react';

import {
  DateOverdueText,
  Divider,
  HistoryExtraText,
  HistorySetShiftText,
  HistoryText,
  HistoryTextWrapper,
  TableRowFiller,
  TableRowWrapper,
} from 'ClientTasks/TargetDate/TargetDateHistoryList/targetDateHistoryList.styles';

type Props = {
  history: Array<Record<string, string>>;
  isCompleted: boolean;
};

export const DesignApprovalHistoryRow = ({ history, isCompleted }: Props) => {
  const numHistoryEntries = history.length - 1;
  return (
    <>
      {history.map((entry, index) => {
        const isOutdated = history.length !== 1 && index !== history.length - 1;

        const showLateText =
          !isOutdated && !isCompleted && entry.daysLateText !== '';

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <TableRowWrapper>
              <HistoryTextWrapper>
                <HistoryText isOutdated={isOutdated} type="h6">
                  {entry.targetDateText}
                </HistoryText>
              </HistoryTextWrapper>
              <HistoryExtraText>
                <HistoryText type="h6">
                  {showLateText && (
                    <DateOverdueText>{entry.daysLateText}</DateOverdueText>
                  )}
                </HistoryText>
                <HistoryText type="h6">
                  {!isOutdated && `Homeowner Sees: ${entry.targetDate}`}
                </HistoryText>
              </HistoryExtraText>
              <TableRowFiller />
              <HistorySetShiftText>
                {entry.setTargetDateText}
              </HistorySetShiftText>
            </TableRowWrapper>
            {index !== numHistoryEntries ? <Divider /> : ''}
          </Fragment>
        );
      })}
    </>
  );
};
