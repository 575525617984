import {
  QuestionList,
  RoomList,
} from 'PricingEngineSidebar/Proposal/ProposalAction/ValidationErrorModal/MaterialsQuantityError/materialsQuantityError.styles';
import { GetProposalSideBardActionDataPricingEngine } from 'PricingEngineSidebar/Proposal/ProposalAction/__generated__/proposalAction.graphql.generated';

type Props = {
  affectedPricingEngines: GetProposalSideBardActionDataPricingEngine[];
};

export const MaterialsQuantityError = ({ affectedPricingEngines }: Props) => (
  <>
    Some Rooms have different quantities on the Labor & Materials page versus
    what's stated on the Room Info page. Please update Room Info accordingly:
    <RoomList>
      {affectedPricingEngines.map((pricingEngine) => (
        <div key={pricingEngine.id}>
          {pricingEngine.name} :
          <QuestionList>
            {pricingEngine.dataErrors.quantityErrors.map(
              (quantityError, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>
                  {quantityError.conflictingQuestion} -{' '}
                  {quantityError.currentAnswer}
                </div>
              ),
            )}
          </QuestionList>
        </div>
      ))}
    </RoomList>
  </>
);
