import { useParams } from 'react-router-dom';

import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';
import { GetProjectPlanQuestionsResponse } from 'common/apollo/responses/getProjectPlanQuestionsResponse';

import {
  answersToFormikInitialValues,
  findImportance,
  formikValuesToOpenQuestionsData,
  formikValuesToQuestionsData,
  getImportancesToggles,
  mapQuestionAnswer,
  mapQuestionToPlaceholder,
  multiAnswersToFormikInitialValues,
} from 'ProjectProfile/common/utils/questionAnswer';

import { useGetProjectPlanQuestions } from 'ProjectProfile/common/hooks/useProjectPlanQuestions';
import { useProjectPlanQuestionsUpdate } from 'ProjectProfile/common/hooks/useProjectPlanQuestionsUpdate';

import { QueryParams } from 'ProjectProfile/common/types/queryParams';
import { QuestionPlaceholder } from 'ProjectProfile/common/types/questionPlaceholder';
import { SelectOptions } from 'ProjectProfile/common/types/selectOptions';
import { BuildingInformationAnswers } from 'ProjectProfile/BuildingInformation/BuildingInfo/buildingInfo.types';
import { ImportancesToggles } from 'ProjectProfile/common/types/importance';
import { UpdateProjectPlanQuestionsResponse } from 'ProjectProfile/common/responses/updateProjectPlanQuestionsResponse';

import {
  identifiers,
  questionIdentifiers,
  openQuestionsIdentifiers,
} from './buildingInfoEdit.consts';

export const useBuildingInfoEdit = () => {
  const { projectId } = useParams<QueryParams>();
  const { handleSubmit } = useProjectPlanQuestionsUpdate();

  const { data: projectPlanQuestions, isLoading } = useGetProjectPlanQuestions({
    identifiers,
    onCompleted: (responseData: GetProjectPlanQuestionsResponse) => {
      openQuestionsIdentifiers.forEach((identifier) => {
        if (
          findImportance(identifier, responseData.projectPlanQuestions) !==
          importances[identifier].state
        ) {
          importances[identifier].toggle();
        }
      });
    },
    projectId,
  });

  const submit = (
    answers: BuildingInformationAnswers,
    importances: ImportancesToggles,
    close: (response: UpdateProjectPlanQuestionsResponse) => void,
  ) =>
    handleSubmit({
      onSuccessCallback: close,
      openQuestions: formikValuesToOpenQuestionsData(
        answers,
        openQuestionsIdentifiers,
        importances,
      ),
      questions: [
        ...formikValuesToQuestionsData(answers, projectPlanQuestions, [
          PropertyGroupIdentifier.ALTERATION_AGREEMENT_REQUIRED,
        ]),
        {
          answerIds: [...answers.INSURANCE],
          identifier: PropertyGroupIdentifier.INSURANCE,
        },
      ],
    });

  const options = questionIdentifiers.reduce(
    (acc, identifier: PropertyGroupIdentifier) => ({
      ...acc,
      [identifier]: mapQuestionAnswer(identifier, projectPlanQuestions),
    }),
    {} as SelectOptions,
  );

  const singleAnswersValues = identifiers.reduce(
    (acc, identifier: PropertyGroupIdentifier) => ({
      ...acc,
      [identifier]: answersToFormikInitialValues(
        identifier,
        projectPlanQuestions,
      ),
    }),
    {},
  );

  const multiAnswersValues = {
    [PropertyGroupIdentifier.INSURANCE]: multiAnswersToFormikInitialValues(
      PropertyGroupIdentifier.INSURANCE,
      projectPlanQuestions,
    ),
  };

  const initialValues = {
    ...singleAnswersValues,
    ...multiAnswersValues,
  } as BuildingInformationAnswers;

  const placeholders = identifiers.reduce(
    (acc, identifier) => ({
      ...acc,
      [identifier]: mapQuestionToPlaceholder(identifier, projectPlanQuestions),
    }),
    {} as QuestionPlaceholder,
  );

  const importances = getImportancesToggles(
    openQuestionsIdentifiers,
    projectPlanQuestions,
  );

  return {
    importances,
    initialValues,
    isLoading,
    options,
    placeholders,
    projectId,
    submit,
  };
};
