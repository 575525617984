import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { skipForwardProps } from 'common/utils/styles';

export type Props = NavLinkProps & {
  disabled?: boolean;
  lowerCase?: boolean;
  primary?: boolean;
};

export const Link = styled(NavLink, {
  shouldForwardProp: skipForwardProps(['primary', 'disabled', 'lowerCase']),
})<Props>`
  ${({ lowerCase, primary, theme }) => css`
    color: ${primary ? theme.color.primaryOrange : theme.color.black};
    font: normal 500 14px/16px ${theme.font.roboto};
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: ${lowerCase ? 'none' : 'uppercase'};

    &:hover {
      text-decoration: underline;
    }
  `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.color.darkGray};
      cursor: not-allowed;
      pointer-events: none;
      text-decoration: none;
    `}
`;
