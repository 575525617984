import { gql } from '@apollo/client';

export const GET_QUESTIONS_BY_IDENTIFIERS = gql`
  query GetQuestionsByIdentifiers($identifiers: [PropertyGroupIdentifier!]!) {
    questions(identifiers: $identifiers) {
      description
      id
      identifier
      isSingleSelect
      label
      order
      presentationType
      systemType
      title
      type
      answers {
        id
        label
        description
        identifier
        propertyGroupId
        order
      }
    }
  }
`;
