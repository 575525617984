import { MaterialsLongestLeadTimeUpdateModal } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeUpdate/MaterialsLongestLeadTimeUpdateModal';

import { useMaterialsLongestLeadTimeUpdate } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeUpdate/materialsLongestLeadTimeUpdate.hooks';

import { GearButton } from 'MaterialsLongestLeadTime/MaterialsLongestLeadTimeUpdate/materialsLongestLeadTimeUpdate.styles';

export const MaterialsLongestLeadTimeUpdate = () => {
  const { handleMaterialsLongestLeadTimeUpdateClick } =
    useMaterialsLongestLeadTimeUpdate();

  return (
    <>
      <GearButton
        isDisabled={false}
        onClick={handleMaterialsLongestLeadTimeUpdateClick}
      >
        Shift Longest Lead Time
      </GearButton>
      <MaterialsLongestLeadTimeUpdateModal />
    </>
  );
};
