import { lazy } from 'react';
import { Router as ReactRouter, Switch, Redirect } from 'react-router-dom';

import { History } from 'history';

import { AUTH_PATHS, BASE_PATH } from 'common/routes/routerPaths';

import { Layout } from 'common/components/Layout/Layout';
import { LayoutProvider } from 'common/components/Layout/LayoutProvider';
import { ModalProvider } from 'common/hooks/ModalProvider/ModalProvider';

import { authRoutes, publicRoutes } from 'Router/routerConfig';

import { AuthGuard } from 'Router/AuthGuard';
import { SeoManager } from 'Router/SeoManager';
import { SentryRoute } from 'Router/SentryRoute';

const PageNotFound = lazy(() => import('PageNotFound/PageNotFound'));

type Props = {
  history: History;
};

export const Router = ({ history }: Props) => (
  <ReactRouter history={history}>
    <ModalProvider>
      <LayoutProvider>
        <Switch>
          <SentryRoute exact path={BASE_PATH}>
            <Redirect to={AUTH_PATHS.USERS} />
          </SentryRoute>

          {publicRoutes.map(({ component: Component, exact, path, title }) => (
            <SentryRoute exact={exact} key={path} path={path} title={title}>
              <SeoManager>
                <Component />
              </SeoManager>
            </SentryRoute>
          ))}

          {authRoutes.map(
            ({
              component: Component,
              exact,
              hideSidebar,
              path,
              routes = [],
              title,
            }) => (
              <SentryRoute exact={exact} key={path} path={path}>
                <AuthGuard>
                  <Layout hideSidebar={hideSidebar}>
                    <SeoManager title={title}>
                      <Component routes={routes} />
                    </SeoManager>
                  </Layout>
                </AuthGuard>
              </SentryRoute>
            ),
          )}

          <SentryRoute>
            <PageNotFound />
          </SentryRoute>
        </Switch>
      </LayoutProvider>
    </ModalProvider>
  </ReactRouter>
);
