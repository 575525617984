import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { GET_KEY_DATES } from 'ClientTasks/TargetDate/targetDate.graphql';
import { GetKeyDatesRequest } from 'common/apollo/requests/getKeyDatesRequest';
import { GetKeyDatesResponse } from 'common/apollo/responses/getKeyDatesResponse';
import { StageIdentifier } from 'common/apollo/enums/StageIdentifier';

export const useTargetDate = (stageName: StageIdentifier) => {
  const { projectId } = useParams<{ projectId: string }>();

  const { data, refetch } = useQuery<GetKeyDatesResponse, GetKeyDatesRequest>(
    GET_KEY_DATES,
    {
      variables: {
        input: {
          projectId,
        },
      },
    },
  );

  const adminStages = data?.adminGetStages || [];
  const stageKeyDates = adminStages?.find(
    (stage) => stage.identifier === stageName,
  );

  const adminKeyDates = stageKeyDates?.adminKeyDates || [];
  const consumerKeyDates = stageKeyDates?.consumerKeyDates || [];

  return { adminKeyDates, consumerKeyDates, refetchKeyDates: refetch };
};
