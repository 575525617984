import styled from '@emotion/styled';

export const Outer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.grayBorder};
  border-radius: 4px;
  background: ${({ theme }) => theme.color.white};
  padding: 12px;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 2px;
`;

export const FlagWrapper = styled.div`
  color: ${({ theme }) => theme.color.orange8};
  margin-right: 4px;

  .MuiSvgIcon-root {
    font-size: 18px;
  }
`;

export const Inner = styled.div`
  background: ${({ theme }) => theme.color.orange2};
  color: ${({ theme }) => theme.color.orange11};
  display: inline-block;
  padding: 2px 4px;
  margin: 2px 0;
`;

export const Content = styled.p``;
