import { useToggle } from 'common/hooks/useToggle';
import { useQueryParams } from 'common/hooks/useQueryParam';

export const useMaterialsHeader = () => {
  const [vendorId, handleVendorFilterChange] = useQueryParams('vendorId');
  const [subcategoryId, handleSubcategoryFilterChange] =
    useQueryParams('subcategoryId');
  const [roomName, handleRoomFilterChange] = useQueryParams('roomName');
  const [status, handleStatusFilterChange] = useQueryParams('status');
  const [stockStatus, handleStockStatusFilterChange] =
    useQueryParams('stockStatus');

  const { isOn: isFiltersOpen, toggle: toggleFilters } = useToggle(
    !!vendorId || !!subcategoryId || !!roomName || !!status || !!stockStatus,
  );

  const filtersCount = [
    status,
    stockStatus,
    roomName,
    subcategoryId,
    vendorId,
  ].filter(Boolean).length;

  return {
    filtersCount,
    handleRoomFilterChange,
    handleStatusFilterChange,
    handleStockStatusFilterChange,
    handleSubcategoryFilterChange,
    handleVendorFilterChange,
    isFiltersOpen,
    roomName,
    status,
    stockStatus,
    subcategoryId,
    toggleFilters,
    vendorId,
  };
};
