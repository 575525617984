import { useParams } from 'react-router-dom';

import { ApolloError, useMutation, useQuery } from '@apollo/client';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useAlert } from 'common/hooks/useAlert';

import { ProposalStatus } from 'common/apollo/enums/ProposalStatus';
import { ProposalType } from 'common/apollo/enums/ProposalType';

import { AUTH_PATHS } from 'common/routes/routerPaths';

import {
  DUPLICATE_PROPOSAL,
  PUBLISH_PROPOSAL,
  UNPUBLISH_PROPOSAL,
} from 'common/apollo/mutations/proposal.graphql';
import {
  ProposalPublish,
  ProposalPublishVariables,
} from 'common/apollo/mutations/__generated__/ProposalPublish';
import {
  ProposalUnPublish,
  ProposalUnPublishVariables,
} from 'common/apollo/mutations/__generated__/ProposalUnPublish';
import {
  ProposalDuplicate,
  ProposalDuplicateVariables,
} from 'common/apollo/mutations/__generated__/ProposalDuplicate';

import {
  GetProposalSidebarActionData,
  GetProposalSidebarActionDataVariables,
  GetProposalSideBardActionDataPricingEngine,
  UpdateProposalRequireAcceptance,
  UpdateProposalRequireAcceptanceVariables,
} from 'PricingEngineSidebar/Proposal/ProposalAction/__generated__/proposalAction.graphql.generated';

import {
  GET_PROPOSAL_SIDEBAR_ACTION_DATA,
  UPDATE_PROPOSAL_REQUIRE_ACCEPTANCE,
} from 'PricingEngineSidebar/Proposal/ProposalAction/proposalAction.graphql';
import {
  PROPOSAL_MATERIALS_QUANTITIES_VALIDATION_MODAL,
  REQUEST_PROPOSAL_QA_MODAL,
} from 'PricingEngineSidebar/Proposal/ProposalAction/proposalAction.consts';

import { useToggle } from 'common/hooks/useToggle';

import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';

import { FeatureFlags } from 'common/enums/FeatureFlags';

import { useProposalsFetch } from 'Proposals/proposals.hooks';

import { hasAcceptedDesignOrContractorProposal } from 'common/utils/proposals';

import { getErrorMessage } from 'common/utils/error';

import { ProposalQaStatus } from '../../../__generated/apolloTypes';

const MISSING_PROJECT_OVERVIEW_ANSWERS_MESSAGE =
  'Proposal is missing Project Overview answers';

const usePublishProposal = (
  proposal: GetProposalSidebarActionData['proposal'] | undefined,
) => {
  const {
    isOn: isErrorPublishingProposalModalOpen,
    toggleOff: closeErrorPublishingProposalModal,
    toggleOn: openErrorPublishingProposalModal,
  } = useToggle();

  const { proposalId } = useParams<{ proposalId: string }>();

  const setDialogOpen = useSetModalOpen();
  const { onCompleted, onError } = useAlert();

  const [publish] = useMutation<ProposalPublish, ProposalPublishVariables>(
    PUBLISH_PROPOSAL,
    {
      onCompleted: () => {
        onCompleted('Successfully published proposal');
        setDialogOpen(PROPOSAL_MATERIALS_QUANTITIES_VALIDATION_MODAL, false);
      },
      onError: (error: ApolloError) => {
        if (error.message === MISSING_PROJECT_OVERVIEW_ANSWERS_MESSAGE)
          openErrorPublishingProposalModal();
        onError(error.message);
      },
    },
  );

  const pricingEnginesWithMaterialsQuantitiesError =
    proposal?.pricingEngines.reduce(
      (
        pricingEnginesWithError: GetProposalSideBardActionDataPricingEngine[],
        pricingEngine: GetProposalSideBardActionDataPricingEngine,
      ) => {
        if (pricingEngine.dataErrors.quantityErrors.length) {
          pricingEnginesWithError.push(pricingEngine);
          return pricingEnginesWithError;
        }
        return pricingEnginesWithError;
      },
      [],
    );

  const hasMoreThanOnePricingEngine = proposal
    ? proposal.pricingEngines.length > 1
    : false;

  const validateAndPublishProposal = () => {
    if (
      pricingEnginesWithMaterialsQuantitiesError?.length ||
      hasMoreThanOnePricingEngine
    ) {
      setDialogOpen(PROPOSAL_MATERIALS_QUANTITIES_VALIDATION_MODAL, true);
    } else {
      publishProposal();
    }
  };

  const publishProposal = () =>
    publish({
      variables: {
        input: { proposalId },
      },
    });

  return {
    closeErrorPublishingProposalModal,
    isErrorPublishingProposalModalOpen,
    pricingEnginesWithMaterialsQuantitiesError,
    proposalId,
    publishProposal,
    validateAndPublishProposal,
  };
};

const useUnPublishProposal = () => {
  const { proposalId } = useParams<{ proposalId: string }>();

  const { onCompleted, onError } = useAlert();

  const [unpublish] = useMutation<
    ProposalUnPublish,
    ProposalUnPublishVariables
  >(UNPUBLISH_PROPOSAL, {
    onCompleted: () => onCompleted('Successfully unpublished proposal'),
    onError: (error: ApolloError) => onError(error.message),
  });

  const unpublishProposal = () =>
    unpublish({
      variables: {
        input: { proposalId },
      },
    });

  return { unpublishProposal };
};

const useDuplicateProposal = (name: string | null) => {
  const { proposalId } = useParams<{ proposalId: string }>();
  const { onError } = useAlert();

  const [duplicate] = useMutation<
    ProposalDuplicate,
    ProposalDuplicateVariables
  >(DUPLICATE_PROPOSAL, {
    variables: {
      input: {
        name: `Copy of ${name}`,
        proposalId,
      },
    },
  });

  const duplicateProposal = async () => {
    try {
      const response = await duplicate();

      if (!response.data) throw new Error();

      const { proposalDuplicate: proposal } = response.data;

      const url = proposal.pricingEngines.length
        ? AUTH_PATHS.getPricingEngineLaborMaterialsPath({
            pricingEngineId: proposal.pricingEngines[0].id,
            proposalId: proposal.id,
          })
        : AUTH_PATHS.getPricingEngineEntryPath(proposal.id);

      window.open(url, '_blank');
    } catch (error) {
      onError(getErrorMessage(error));
    }
  };

  return { duplicateProposal };
};

const useGetProposal = () => {
  const { proposalId } = useParams<{ proposalId: string }>();

  const { data } = useQuery<
    GetProposalSidebarActionData,
    GetProposalSidebarActionDataVariables
  >(GET_PROPOSAL_SIDEBAR_ACTION_DATA, {
    variables: { proposalId },
  });

  return data?.proposal;
};

const useUpdateRequireAcceptance = () => {
  const { proposalId } = useParams<{ proposalId: string }>();
  const { onError } = useAlert();
  const [update] = useMutation<
    UpdateProposalRequireAcceptance,
    UpdateProposalRequireAcceptanceVariables
  >(UPDATE_PROPOSAL_REQUIRE_ACCEPTANCE);

  const updateRequireAcceptance = async (
    _: any,
    requireAcceptance: boolean,
  ) => {
    try {
      await update({
        optimisticResponse: {
          proposalRequireAcceptanceUpdate: {
            __typename: 'Proposal',
            id: proposalId,
            requireAcceptance,
          } as UpdateProposalRequireAcceptance['proposalRequireAcceptanceUpdate'],
        },
        variables: {
          input: {
            proposalId,
            requireAcceptance,
          },
        },
      });
    } catch (error) {
      onError(getErrorMessage(error));
    }
  };

  return { updateRequireAcceptance };
};

const useDoesRenovationPlanHaveAcceptedDesignOrContractorProposal = (
  renovationPlanId: string,
) => {
  const { isLoading, proposals } = useProposalsFetch(renovationPlanId);

  if (!proposals) {
    return false;
  }

  return isLoading || hasAcceptedDesignOrContractorProposal(proposals);
};

export const useIsProposalQaEnabled = () => {
  const flags = useFlags();
  return !!flags[FeatureFlags.PROPOSAL_QA];
};

export const useIsPostDsoEnabled = () => {
  const flags = useFlags();
  return !!flags[FeatureFlags.POST_DSO];
};

export const useProposalAction = () => {
  const proposal = useGetProposal();

  const {
    closeErrorPublishingProposalModal,
    isErrorPublishingProposalModalOpen,
    pricingEnginesWithMaterialsQuantitiesError,
    proposalId,
    publishProposal,
    validateAndPublishProposal,
  } = usePublishProposal(proposal);
  const { unpublishProposal } = useUnPublishProposal();
  const { duplicateProposal } = useDuplicateProposal(proposal?.name || '');
  const { updateRequireAcceptance } = useUpdateRequireAcceptance();
  const setDialogOpen = useSetModalOpen();
  const isProposalQaEnabled = useIsProposalQaEnabled();
  const isPostDsoEnabled = useIsPostDsoEnabled();
  const doesRenovationPlanHaveAcceptedDesignOrContractorProposal =
    useDoesRenovationPlanHaveAcceptedDesignOrContractorProposal(
      proposal?.renovationPlan.id || '',
    );

  const isConfigurator = proposal?.type === ProposalType.CONFIGURATOR;
  const isDesign = proposal?.type === ProposalType.DESIGN;
  const isSales = proposal?.type === ProposalType.SALES;

  const isAccepted = proposal?.status === ProposalStatus.ACCEPTED;
  const isDraft = proposal?.status === ProposalStatus.DRAFT;
  const isExpired = proposal?.status === ProposalStatus.EXPIRED;
  const isPublished = proposal?.status === ProposalStatus.PUBLISHED;
  const isProposed = proposal?.status === ProposalStatus.PROPOSED;

  const canPublish = isDraft;
  const canUnPublish = isPublished || isAccepted || isExpired || isProposed;

  const isPublishDisabled =
    !proposal?.pricingEngines.length ||
    (isPostDsoEnabled &&
      doesRenovationPlanHaveAcceptedDesignOrContractorProposal);
  const isUnPublishDisabled = isConfigurator || isAccepted;

  const isDraftDesign = isDraft && isDesign;
  const isDraftSales = isDraft && isSales;

  const canUpdateRequireAcceptance =
    isDraftDesign || (isDraftSales && !proposal?.requirePayment);

  const numberOfPricingEngines = proposal?.pricingEngines.length || 0;

  const anyQaRequestedOrNotCompleted =
    (!!proposal?.latestDesignQaEntry &&
      proposal?.latestDesignQaEntry?.status !== ProposalQaStatus.COMPLETED) ||
    (!!proposal?.latestProcurementQaEntry &&
      proposal?.latestProcurementQaEntry?.status !==
        ProposalQaStatus.COMPLETED);

  const isProposalQaRequestDisabled =
    !isDraft ||
    anyQaRequestedOrNotCompleted ||
    (isPostDsoEnabled &&
      doesRenovationPlanHaveAcceptedDesignOrContractorProposal);

  const handleCloseErrorPublishingProposalModal = () => {
    closeErrorPublishingProposalModal();
  };

  const handleRequestProposalQa = () => {
    setDialogOpen(REQUEST_PROPOSAL_QA_MODAL, true);
  };

  const publishProposalButtonLabel =
    isProposalQaEnabled &&
    ((!proposal?.latestDesignQaEntry && !proposal?.latestProcurementQaEntry) ||
      proposal?.latestDesignQaEntry?.status !== ProposalQaStatus.COMPLETED ||
      proposal?.latestProcurementQaEntry?.status !== ProposalQaStatus.COMPLETED)
      ? 'Publish & Skip QA'
      : 'Publish';

  const latestDesignQaCompletedAt = proposal?.latestDesignQaEntry?.completedAt
    ? new Date(proposal?.latestDesignQaEntry?.completedAt)
    : null;
  const latestProcurementQaCompletedAt = proposal?.latestProcurementQaEntry
    ?.completedAt
    ? new Date(proposal?.latestProcurementQaEntry?.completedAt)
    : null;

  return {
    canPublish,
    canUnPublish,
    canUpdateRequireAcceptance,
    duplicateProposal,
    handleCloseErrorPublishingProposalModal,
    handleRequestProposalQa,
    isErrorPublishingProposalModalOpen,
    isProposalQaEnabled,
    isProposalQaRequestDisabled,
    isPublishDisabled,
    isUnPublishDisabled,
    latestDesignQaCompletedAt,
    latestProcurementQaCompletedAt,
    numberOfPricingEngines,
    pricingEnginesWithMaterialsQuantitiesError,
    proposalId,
    publishProposal,
    publishProposalButtonLabel,
    requireAcceptance: !!proposal?.requireAcceptance,
    unpublishProposal,
    updateRequireAcceptance,
    validateAndPublishProposal,
  };
};
