import { dateToUSFormat } from 'common/utils/date';

import { AUTH_PATHS } from 'common/routes/routerPaths';

import { ErrorSegment } from 'common/components/ErrorHandlers/ErrorSegment/ErrorSegment';
import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';
import {
  Link,
  LinkExternal,
} from 'common/components/Navigation/Link/LinkExternal';
import { Badge } from 'common/components/Badge/Badge';

import { EmptyState } from 'Project/Procurement/common/components/Layout/Layout';
import {
  Table,
  TableHead,
} from 'Project/Procurement/common/components/Layout/Table';

import {
  PROJECT_SHIPMENT_DELIVER_TO_DICTIONARY,
  PROJECT_SHIPMENT_SHIPPING_METHOD_DICTIONARY,
  PROJECT_SHIPMENT_STATUS_COLOR_VARIANT,
  PROJECT_SHIPMENT_STATUS_DICTIONARY,
  PROJECT_SHIPMENT_TYPE_DICTIONARY,
} from 'Project/Procurement/common/consts/shipment.consts';

import { useShipments } from 'Project/Procurement/Shipments/shipments.hooks';

import { ShipmentsWrapper } from 'Project/Procurement/Shipments/shipments.styles';

const Shipments = () => {
  const { error, isLoading, projectId, shipments } = useShipments();

  if (isLoading) return <LoadingSpinnerWithContainer />;
  if (error) return <ErrorSegment />;

  return (
    <ShipmentsWrapper>
      {shipments.length ? (
        <Table>
          <TableHead>
            <tr>
              <th>Shipment ID</th>
              <th>Status</th>
              <th>Type</th>
              <th>Shipment #</th>
              <th>Order #</th>
              <th>Vendor</th>
              <th>Shipping Method</th>
              <th>Tracking #</th>
              <th>Est. Delivery Date</th>
              <th>Actual Delivery Date</th>
              <th>Destination</th>
            </tr>
          </TableHead>

          <tbody>
            {shipments.map(
              ({
                actualDeliveryDate,
                deliverTo,
                estimatedDeliveryDate,
                id,
                items,
                number,
                order,
                shippingMethod,
                status,
                trackingLink,
                trackingNumber,
                type,
              }) => (
                <tr key={id}>
                  <td>{`#${id}`}</td>
                  <td>
                    <Badge
                      variant={PROJECT_SHIPMENT_STATUS_COLOR_VARIANT[status]}
                    >
                      {PROJECT_SHIPMENT_STATUS_DICTIONARY[status]}
                    </Badge>
                  </td>
                  <td>{PROJECT_SHIPMENT_TYPE_DICTIONARY[type]}</td>
                  <td>{number}</td>

                  <td>
                    <Link
                      to={AUTH_PATHS.getProjectProcurementEditOrderPath(
                        projectId,
                        order?.id,
                      )}
                    >
                      {order?.webOrderNumber}
                    </Link>
                  </td>
                  <td>{items[0].orderItem.skuVendor.vendor.name}</td>
                  <td>
                    {
                      PROJECT_SHIPMENT_SHIPPING_METHOD_DICTIONARY[
                        shippingMethod
                      ]
                    }
                  </td>
                  <td>
                    <LinkExternal
                      href={trackingLink}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {trackingNumber}
                    </LinkExternal>
                  </td>
                  <td>
                    {estimatedDeliveryDate &&
                      dateToUSFormat(estimatedDeliveryDate)}
                  </td>
                  <td>
                    {actualDeliveryDate && dateToUSFormat(actualDeliveryDate)}
                  </td>
                  <td>{PROJECT_SHIPMENT_DELIVER_TO_DICTIONARY[deliverTo]}</td>
                </tr>
              ),
            )}
          </tbody>
        </Table>
      ) : (
        <EmptyState> No Shipments Found</EmptyState>
      )}
    </ShipmentsWrapper>
  );
};

export default Shipments;
