import { ProjectMaterial } from 'common/apollo/models/projectMaterial';
import { SkuVendorStockStatus } from 'common/apollo/enums/SkuVendorStockStatus';

export const getInitialValues = (materials: ProjectMaterial[]) => ({
  materials: materials.map(
    ({
      sku: {
        vendor: {
          backInStockDate,
          id: skuVendorId,
          maxLeadTimeWeeks,
          minLeadTimeWeeks,
          stockStatus,
        },
      },
    }) => ({
      backInStockDate: backInStockDate || null,
      maxLeadTimeWeeks: maxLeadTimeWeeks || '',
      minLeadTimeWeeks: minLeadTimeWeeks || '',
      skuVendorId,
      stockStatus: stockStatus || ('' as SkuVendorStockStatus),
    }),
  ),
});
