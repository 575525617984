export enum VerticalAlignment {
  BOTTOM = 'bottom',
  CENTER = 'center',
  TOP = 'top',
}

export enum HorizontalAlignment {
  CENTER = 'center',
  LEFT = 'left',
  NORMAL = 'normal',
  RIGHT = 'right',
}
