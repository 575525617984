import { dateToUSFormat } from 'common/utils/date';
import { DatePicker } from 'common/components/Form/DatePicker/DatePicker';

import {
  CloseIcon,
  Date,
  EditIcon,
  InputWrapper,
  LineHeader,
  Row,
} from './breakGroundDate.styles';

type Props = {
  handleCloseClick: () => void;
  handleDateChange: (date: Date | null) => void;
  handleEditClick: () => void;
  initialDate?: string | null;
  likeliestDate?: string | null;
  likeliestDateType?: string | null;
  selectedDate: Date | null;
  showDateInput: boolean;
};

export const BreakGroundDateEditable = ({
  handleCloseClick,
  handleDateChange,
  handleEditClick,
  initialDate,
  likeliestDate,
  likeliestDateType,
  selectedDate,
  showDateInput,
}: Props) => (
  <>
    {showDateInput ? (
      <InputWrapper>
        <DatePicker
          label="Initial Sales Target Date"
          onChange={handleDateChange}
          value={selectedDate}
        />
        <CloseIcon onClick={handleCloseClick} />
      </InputWrapper>
    ) : (
      <Row>
        <LineHeader>
          Initial Sales Target Date
          <EditIcon onClick={handleEditClick} />
        </LineHeader>
        <Date>{initialDate && dateToUSFormat(initialDate)}</Date>
      </Row>
    )}
    <Row>
      <LineHeader>Likeliest Date</LineHeader>
      <Date>
        {likeliestDate && dateToUSFormat(likeliestDate)} - {likeliestDateType}
      </Date>
    </Row>
  </>
);
