import styled from '@emotion/styled';

import { css } from '@emotion/react';

import gearRedIcon from 'common/assets/icons/gear-red.svg';
import gearGrayIcon from 'common/assets/icons/gear-gray.svg';

type ButtonProps = {
  isDisabled: boolean;
};

export const TargetDateButton = styled.button<ButtonProps>`
  align-items: center;
  background: ${({ theme }) => theme.color.orange2};
  border: 1px solid ${({ theme }) => theme.color.orange6};
  border-radius: 4px;
  color: ${({ theme }) => theme.color.orange11};
  display: flex;
  font: 500 14px/20px Inter;
  padding: 10px 16px;
  position: absolute;
  right: 0;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.color.orange3};
  }

  &:before {
    background: url(${gearRedIcon}) 100% no-repeat;
    content: '';
    display: inline-block;
    height: 20px;
    margin-right: 10px;
    width: 20px;
  }

  ${({ isDisabled, theme }) => css`
    ${isDisabled &&
    css`
      background: ${theme.color.gray3};
      border: none;
      color: ${theme.color.gray11};
      cursor: not-allowed;

      &:hover {
        cursor: not-allowed;
        background: ${theme.color.gray3};
      }
      &:before {
        background: url(${gearGrayIcon}) 100% no-repeat;
      }
    `}
  `}
`;
