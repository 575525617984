import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { NAVIGATION_MENU_ITEMS } from 'common/components/Navigation/NavigationMenu/navigationMenu.consts';

import {
  useLayoutState,
  useLayoutActions,
} from 'common/components/Layout/layoutProvider.hooks';

import { navigationLabel } from '../layout.utils';
import { ToggleWrapper, ToggleButton } from './LayoutSidebarToggle.styles';
import { ArrowType } from './LayoutSidebarToggle.types';

type Props = {
  pathname: string;
};

export const LayoutSidebarToggle = ({ pathname }: Props) => {
  const { isSidebarOpen, isSubSidebarPresent } = useLayoutState();
  const { setSubSidebarPresent } = useLayoutActions();

  const label = navigationLabel(NAVIGATION_MENU_ITEMS, pathname);

  return (
    <ToggleWrapper isOpen={isSidebarOpen}>
      {isSubSidebarPresent ? (
        <ToggleButton
          aria-label="Home Navigation"
          arrow={ArrowType.LEFT}
          color="primary"
          onClick={() => setSubSidebarPresent(false)}
          variant="text"
        >
          <ChevronLeftIcon />
        </ToggleButton>
      ) : (
        <ToggleButton
          aria-label={`{Back to ${label} Navigation}`}
          arrow={ArrowType.RIGHT}
          color="primary"
          onClick={() => setSubSidebarPresent(true)}
          variant="text"
        >
          Back to {label} Navigation <ChevronRightIcon />
        </ToggleButton>
      )}
    </ToggleWrapper>
  );
};
