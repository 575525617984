import React from 'react';

import { ShipmentStatus } from 'common/apollo/enums/ShipmentStatus';

import { FormDatePicker } from 'common/components/Form/DatePicker/FormDatePicker';
import { FormSelect } from 'common/components/Form/Select/FormSelect';
import { Select } from 'common/components/Form/Select/Select';
import { FormTextField } from 'common/components/Form/TextField/FormTextField';
import { FormCurrencyField } from 'common/components/Form/CurrencyField/FormCurrencyField';

import {
  PROJECT_SHIPMENT_DELIVER_TO_OPTIONS,
  PROJECT_SHIPMENT_SHIPPING_METHOD_OPTIONS,
  PROJECT_SHIPMENT_STATUS_OPTIONS,
  PROJECT_SHIPMENT_TYPE_OPTIONS,
} from 'Project/Procurement/common/consts/shipment.consts';

export const ShipmentForm = () => (
  <>
    <FormSelect
      id="type"
      label="Type"
      name="type"
      options={PROJECT_SHIPMENT_TYPE_OPTIONS}
    />
    <Select
      isDisabled
      label="Status"
      options={PROJECT_SHIPMENT_STATUS_OPTIONS}
      value={ShipmentStatus.SHIPPED}
    />
    <FormDatePicker label="ETA to Destination" name="estimatedDeliveryDate" />
    <FormSelect
      id="deliverTo"
      label="Destination"
      name="deliverTo"
      options={PROJECT_SHIPMENT_DELIVER_TO_OPTIONS}
    />
    <FormSelect
      id="shippingMethod"
      label="Shipment Method"
      name="shippingMethod"
      options={PROJECT_SHIPMENT_SHIPPING_METHOD_OPTIONS}
    />
    <FormTextField
      id="trackingNumber"
      label="Tracking Number"
      name="trackingNumber"
    />
    <FormCurrencyField
      id="shippingCost"
      label="Cost to Ship"
      name="shippingCost"
    />
    <FormTextField
      id="trackingLink"
      label="Tracking Link"
      name="trackingLink"
    />
    <FormCurrencyField
      id="shippingTax"
      label="Tax on Shipping"
      name="shippingTax"
    />
  </>
);
