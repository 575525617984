import { gql } from '@apollo/client';

export const GET_PROJECT_PROCUREMENT_MATERIALS = gql`
  query GetProjectProcurementMaterials($projectId: ID!) {
    project(id: $projectId) {
      id
      materialsAutomaticallyGeneratedAt
      firstAcceptedProcurementProposal {
        id
        name
      }
      materials {
        id
        amount
        boxCount
        name
        generated
        pricingEngine {
          id
          name
        }
        category {
          id
          name
          materialsCategory {
            id
            roomType
          }
        }
        unitsOrdered {
          boxCount
          amount
        }
        revisionActionItem {
          id
          revision {
            id
          }
        }
        sku {
          id
          sku
          width
          depth
          height
          length
          thickness
          vendor {
            id
            vendorSku
            link
            backInStockDate
            maxLeadTimeWeeks
            minLeadTimeWeeks
            leadTimeUpdatedAt
            stockStatus
            vendor {
              id
              name
            }
          }
          unit {
            type
          }
          color {
            description
          }
          finish {
            description
          }
          pattern {
            description
          }
        }
        status
        notes
        shipments {
          id
          deliverTo
          estimatedDeliveryDate
          actualDeliveryDate
          number
          shippingMethod
          shippingTax
          shippingCost
          status
          trackingLink
          trackingNumber
          items {
            id
            orderItem {
              id
              skuVendor {
                id
                vendor {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PROJECT_PROCUREMENT_MATERIALS_FILTERS = gql`
  query GetProjectProcurementMaterialsFilters($projectId: ID!) {
    project(id: $projectId) {
      id
      pricingEngines {
        id
        name
      }
    }

    materialsCategories {
      id
      categoryChildren {
        id
        name
        roomType
      }
    }

    vendors {
      id
      name
    }
  }
`;
