import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { useToggle } from 'common/hooks/useToggle';

import { UpdateProjectProcurementOrderResponse } from 'Project/Procurement/common/apollo/responses/updateProjectProcurementOrderResponse';
import { UpdateProjectProcurementOrderRequest } from 'Project/Procurement/common/apollo/requests/updateProjectProcurementOrderRequest';
import { GetProjectProcurementVendorsResponse } from 'Project/Procurement/common/apollo/responses/getProjectProcurementVendorsResponse';

import { GET_PROJECT_PROCUREMENT_VENDORS } from 'Project/Procurement/common/apollo/gql/getProjectProcurementVendors';
import { UPDATE_PROJECT_ORDER } from 'Project/Procurement/EditOrder/OrderInfo/orderInfo.graphql';

import { EditOrderFormValues } from 'Project/Procurement/EditOrder/editOrder.types';
import { useAlert } from 'common/hooks/useAlert';

export const useOrderInfo = () => {
  const { orderId, projectId } = useParams<{
    orderId: string;
    projectId: string;
  }>();
  const { onCompleted, onError } = useAlert();

  const {
    isOn: isEditMode,
    toggleOff: handleCloseEditMode,
    toggleOn: handleOpenEditMode,
  } = useToggle();

  const [editOrder] = useMutation<
    UpdateProjectProcurementOrderResponse,
    UpdateProjectProcurementOrderRequest
  >(UPDATE_PROJECT_ORDER, {
    onCompleted: () => {
      onCompleted('Successfully edited order');
      handleCloseEditMode();
    },
    onError: (e) => onError(e.message),
  });

  const { data } = useQuery<GetProjectProcurementVendorsResponse>(
    GET_PROJECT_PROCUREMENT_VENDORS,
  );

  const handleEditOrder = (values: EditOrderFormValues) => {
    const input = Object.entries(values).reduce(
      (acc, [key, value]) =>
        key === 'vendorId' ? acc : { ...acc, [key]: value || null },
      {} as EditOrderFormValues,
    );

    editOrder({
      variables: {
        input: {
          ...input,
          orderId,
        },
      },
    });
  };

  const vendors = (data?.vendors || []).map(({ id: value, name: label }) => ({
    label,
    value,
  }));

  return {
    handleCloseEditMode,
    handleEditOrder,
    handleOpenEditMode,
    isEditMode,
    projectId,
    vendors,
  };
};
