import { RefObject, useMemo } from 'react';

import MaskedInput, { MaskedInputProps, Mask } from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

type Props = {
  inputRef: { inputComponent: RefObject<MaskedInput> };
  maskOptions?: MaskedInputProps;
  value: string;
};

export const CurrencyMask = ({
  inputRef,
  maskOptions,
  ...inputProps
}: Props) => {
  const currencyMask = useMemo<Mask>(
    () =>
      createNumberMask({
        allowDecimal: true,
        allowLeadingZeroes: false,
        allowNegative: true,
        decimalLimit: 2,
        decimalSymbol: '.',
        includeThousandsSeparator: true,
        prefix: '',
        suffix: '',
        thousandsSeparatorSymbol: ',',
        ...maskOptions,
      }),
    [maskOptions],
  );

  return (
    <MaskedInput
      mask={currencyMask}
      ref={inputRef.inputComponent}
      {...inputProps}
    />
  );
};
