import { dateToUSFormat } from 'common/utils/date';

import { Date, LineHeader } from './breakGroundDate.styles';

type Props = {
  likeliestDate?: string | null;
  likeliestDateType?: string | null;
};

export const BreakGroundDateLikeliestOnly = ({
  likeliestDate,
  likeliestDateType,
}: Props) => (
  <>
    <LineHeader>Likeliest Date</LineHeader>
    <Date>
      {likeliestDate && dateToUSFormat(likeliestDate)} - {likeliestDateType}
    </Date>
  </>
);
