import { useBatch } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/batchRow.hooks';
import { BatchRowBase } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/BatchRowBase';
import { BatchRowEditable } from 'Project/Procurement/Warehouse/WarehouseTable/BatchRow/BatchRowEditable';

type Props = {
  confirmedAt: string | null;
  eta: string | null;
  handleToggleBatch: (batchId: string) => void;
  id: string;
  inventoryUnits: number;
  isSelected: boolean;
  number: number;
  pallet: string | null;
  receivedAt: string | null;
  shippedAt: string | null;
  units: number;
  unitsRemaining: number;
};

export const BatchRow = ({
  confirmedAt,
  eta,
  handleToggleBatch,
  id,
  inventoryUnits,
  isSelected,
  number,
  pallet,
  receivedAt,
  shippedAt,
  units,
  unitsRemaining,
}: Props) => {
  const { isEditing, onCancelEdit, onDelete, onEdit, onSave } = useBatch(id);
  const unitsRemainingIncludingBatch = unitsRemaining + units;

  return isEditing ? (
    <BatchRowEditable
      confirmedAt={confirmedAt}
      eta={eta}
      handleToggleBatch={handleToggleBatch}
      id={id}
      inventoryUnits={inventoryUnits}
      isSelected={isSelected}
      number={number}
      onCancelEdit={onCancelEdit}
      onSave={onSave}
      pallet={pallet}
      receivedAt={receivedAt}
      shippedAt={shippedAt}
      units={units}
      unitsRemaining={unitsRemainingIncludingBatch}
    />
  ) : (
    <BatchRowBase
      confirmedAt={confirmedAt}
      eta={eta}
      handleToggleBatch={handleToggleBatch}
      id={id}
      inventoryUnits={inventoryUnits}
      isSelected={isSelected}
      number={number}
      onDelete={onDelete}
      onEdit={onEdit}
      pallet={pallet}
      receivedAt={receivedAt}
      shippedAt={shippedAt}
      units={units}
    />
  );
};
