import { Link } from 'react-router-dom';

import { css, Theme } from '@emotion/react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

import { Size } from 'common/components/Button/button.types';

type Color = 'primary' | 'secondary' | 'danger';

type Variant = 'outlined' | 'contained';

export type Props = {
  color?: Color;
  fluid?: boolean;
  size?: Size;
  small?: boolean;
  uppercase?: boolean;
  variant?: Variant;
};

type ButtonProps = { color?: Color; theme: Theme; variant?: Variant };

const getButtonPropsByVariantAndColor = ({
  color,
  theme,
  variant,
}: ButtonProps) => {
  const defaultStyles = {
    active: {
      background: `${theme.color.gray5}`,
      borderColor: `${theme.color.gray8}`,
      color: `${theme.color.black}`,
    },
    common: {
      background: `${theme.color.white}`,
      borderColor: `${theme.color.gray7}`,
      color: `${theme.color.black}`,
    },
    hover: {
      background: `${theme.color.gray4}`,
      borderColor: `${theme.color.gray8}`,
      color: `${theme.color.black}`,
    },
  };

  if (!variant || !color) return defaultStyles;

  const variants = {
    contained: {
      danger: {
        active: {
          background: `${theme.color.red9}`,
          borderColor: `${theme.color.red9}`,
          color: `${theme.color.white}`,
        },
        common: {
          background: `${theme.color.red9}`,
          borderColor: `${theme.color.red9}`,
          color: `${theme.color.white}`,
        },
        hover: {
          background: `${theme.color.red10}`,
          borderColor: `${theme.color.white}`,
          color: `${theme.color.white}`,
        },
      },
      primary: {
        active: {
          background: `${theme.color.blue8}`,
          borderColor: `${theme.color.blue5}`,
          color: `${theme.color.blue12}`,
        },
        common: {
          background: `${theme.color.blue3}`,
          borderColor: `${theme.color.blue7}`,
          color: `${theme.color.blue12}`,
        },
        hover: {
          background: `${theme.color.blue4}`,
          borderColor: `${theme.color.blue8}`,
          color: `${theme.color.blue12}`,
        },
      },
      secondary: {
        active: {
          background: `${theme.color.orange8}`,
          borderColor: `${theme.color.orange6}`,
          color: `${theme.color.black}`,
        },
        common: {
          background: `${theme.color.orange8}`,
          borderColor: `${theme.color.orange8}`,
          color: `${theme.color.black}`,
        },
        hover: {
          background: `${theme.color.orange10}`,
          borderColor: `${theme.color.orange10}`,
          color: `${theme.color.black}`,
        },
      },
    },
    outlined: {
      danger: {
        active: {
          background: `${theme.color.red9}`,
          borderColor: `${theme.color.red9}`,
          color: `${theme.color.white}`,
        },
        common: {
          background: `${theme.color.red9}`,
          borderColor: `${theme.color.red9}`,
          color: `${theme.color.white}`,
        },
        hover: {
          background: `${theme.color.red10}`,
          borderColor: `${theme.color.red10}`,
          color: `${theme.color.white}`,
        },
      },
      primary: {
        active: {
          background: `${theme.color.blue8}`,
          borderColor: `${theme.color.blue5}`,
          color: `${theme.color.blue12}`,
        },
        common: {
          background: `${theme.color.blue3}`,
          borderColor: `${theme.color.blue7}`,
          color: `${theme.color.blue12}`,
        },
        hover: {
          background: `${theme.color.blue4}`,
          borderColor: `${theme.color.blue8}`,
          color: `${theme.color.blue12}`,
        },
      },
      secondary: {
        active: {
          background: `${theme.color.orange5}`,
          borderColor: `${theme.color.orange8}`,
          color: `${theme.color.orange10}`,
        },
        common: {
          background: `${theme.color.orange2}`,
          borderColor: `${theme.color.orange6}`,
          color: `${theme.color.orange10}`,
        },
        hover: {
          background: `${theme.color.orange4}`,
          borderColor: `${theme.color.orange8}`,
          color: `${theme.color.orange10}`,
        },
      },
    },
  };

  return variants[variant][color];
};

const ButtonSizeMap = {
  large: '48px',
  medium: '40px',
  small: '32px',
};

const ButtonSizePaddingMap: Record<Size, string> = {
  large: '14px 24px',
  medium: '10px 16px',
  small: '6px 12px',
};

export const Button = styled('button', {
  shouldForwardProp: isPropValid,
})<Props>`
  ${({ color, fluid, size, small, theme, uppercase, variant = 'outlined' }) => {
    const { active, common, hover } = getButtonPropsByVariantAndColor({
      color,
      theme,
      variant,
    });

    const isContainedButton = variant === 'contained';

    return css`
      align-items: center;
      border-radius: 4px;
      border: 1px solid;
      cursor: pointer;
      display: flex;
      font: 500 14px/20px ${theme.font.inter};
      gap: 10px;
      height: ${small ? '32px' : '48px'};
      justify-content: center;
      padding: 14px 24px;
      text-transform: ${uppercase ? 'uppercase' : 'none'};
      transition:
        background-color 0.125s ease,
        color 0.125s ease,
        outline 0.125s linear;
      width: ${fluid ? '100%' : 'auto'};
      white-space: nowrap;

      ${common}

      ${size && { height: ButtonSizeMap[size] }}
      ${size && { padding: ButtonSizePaddingMap[size] }}

      &:focus {
        border-color: transparent;
        outline: 2px solid ${theme.color.blue7};
      }

      &:hover {
        ${hover}
      }

      &:active {
        ${active}
      }

      &[disabled] {
        background-color: ${isContainedButton
          ? theme.color.gray3
          : theme.color.gray5};
        border-color: ${theme.color.gray6};
        color: ${theme.color.gray12};
        cursor: not-allowed;
      }

      svg {
        height: 15px;
        width: 15px;

        path {
          fill: currentColor;
        }
      }
    `;
  }};
`;

export const ExternalButton = Button.withComponent('a');
export const LinkButton = Button.withComponent(Link);
