import { dateToUSFormat } from 'common/utils/date';

import { Date, LineHeader, LockIcon, Row } from './breakGroundDate.styles';

type Props = {
  initialDate?: string | null;
  likeliestDate?: string | null;
  likeliestDateType?: string | null;
};

export const BreakGroundDateDisabled = ({
  initialDate,
  likeliestDate,
  likeliestDateType,
}: Props) => (
  <>
    <Row>
      <LineHeader>
        Initial Sales Target Date
        <LockIcon />
      </LineHeader>
      <Date>{initialDate && dateToUSFormat(initialDate)}</Date>
    </Row>
    <Row>
      <LineHeader>Likeliest Date</LineHeader>
      <Date>
        {likeliestDate && dateToUSFormat(likeliestDate)} - {likeliestDateType}
      </Date>
    </Row>
  </>
);
