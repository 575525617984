import { TABLE_HEAD_CONFIG } from 'Project/Procurement/Warehouse/WarehouseTable/BatchHeader/batchHeader.consts';
import {
  HeaderCell,
  Row,
  stickyColumnStyle,
} from 'Project/Procurement/Warehouse/WarehouseTable/BatchHeader/batchHeader.styles';

import { Checkbox } from 'common/components/Form/Checkboxes/Checkbox';
import { OrderItem } from 'common/apollo/models/orderItem';

import { WarehouseZIndexMap } from 'Project/Procurement/Warehouse/warehouse.consts';

type Props = {
  handleToggleAllBatchesForOrderItem: (orderItemId: string) => void;
  orderItem: OrderItem;
  selectedOrderItems: string[];
};

export const BatchHeader = ({
  handleToggleAllBatchesForOrderItem,
  orderItem,
  selectedOrderItems,
}: Props) => {
  const { id } = orderItem;
  return (
    <Row>
      {TABLE_HEAD_CONFIG.map(({ content, isSticky, width }, index) => (
        <HeaderCell
          cellWidth={width}
          css={isSticky && stickyColumnStyle}
          isSticky={isSticky}
          /* eslint-disable-next-line react/no-array-index-key */
          key={index}
          zIndex={WarehouseZIndexMap.batchHeaderCell}
        >
          {content !== 'Checkbox' ? (
            content
          ) : (
            <Checkbox
              checked={selectedOrderItems.includes(id)}
              onChange={() => handleToggleAllBatchesForOrderItem(id)}
              title="selected"
            />
          )}
        </HeaderCell>
      ))}
    </Row>
  );
};
