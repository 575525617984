export enum PresentationType {
  ADDRESS_AUTOCOMPLETE = 'ADDRESS_AUTOCOMPLETE',
  CHECKBOX = 'CHECKBOX',
  CONFIRM = 'CONFIRM',
  DROPDOWN = 'DROPDOWN',
  DUAL_DROPDOWN = 'DUAL_DROPDOWN',
  EMAIL = 'EMAIL',
  HDYH = 'HDYH',
  NAME = 'NAME',
  NUMBER = 'NUMBER',
  PHONE_NUMBER = 'PHONE_NUMBER',
  RADIO = 'RADIO',
  RANK = 'RANK',
  REFERRAL_CODE = 'REFERRAL_CODE',
  SECTION_NAME = 'SECTION_NAME',
  STARTING_POINTS = 'STARTING_POINTS',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  THUMBNAIL_LARGE = 'THUMBNAIL_LARGE',
  THUMBNAIL_SMALL = 'THUMBNAIL_SMALL',
  ZIP = 'ZIP',
}
