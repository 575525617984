import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mq } from 'common/utils/mediaQueries';

export const Wrapper = styled.div`
  display: grid;
  gap: 24px;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  padding: 16px 24px 24px 24px;

  > div {
    width: 100%;
  }

  ${mq.medium(css`
    grid-template-columns: 1fr 1fr;

    > div:nth-of-type(13) {
      grid-row: 7/10;
    }
  `)};

  ${mq.xxLarge(css`
    width: 70%;
  `)};
`;
