import { useState, useCallback } from 'react';

import { useQuery, FetchResult, DataProxy } from '@apollo/client';

import { useProfileSidebarSection } from 'common/shared/Sidebar/Profile/profile.hooks';
import { GET_USER_DETAILS } from 'common/apollo/queries/user.graphql';
import { GET_PROJECT_MARKET } from 'common/apollo/queries/projectMarket.graphql';
import {
  UserDetailsGet,
  UserDetailsGetVariables,
} from 'common/apollo/queries/__generated__/UserDetailsGet';
import { ProjectAddressUpdate } from 'common/shared/Sidebar/Profile/__generated__/profile.graphql.generated';
import { useAlert } from 'common/hooks/useAlert';

import { useGetProjectPlanSecondaryContact } from 'common/hooks/useProjectPlanSecondaryContact';

const useGetUser = (userId: string) => {
  const {
    data,
    error: errorFetch,
    loading: loadingFetch,
  } = useQuery<UserDetailsGet, UserDetailsGetVariables>(GET_USER_DETAILS, {
    variables: {
      id: userId,
    },
  });

  const { currentRenovationPlan, id, ...user } = data?.user || {
    email: '',
    firstName: '',
    id: '',
    lastName: '',
  };

  const {
    address,
    id: renovationPlanId,
    projectType,
  } = currentRenovationPlan || {
    address: {
      city: '',
      state: '',
      street: '',
      street_2: '',
      zip: '',
    },
    id: '',
  };

  return {
    address,
    errorFetch,
    loadingFetch,
    projectType,
    renovationPlanId,
    user,
  };
};

const useGetMarketCode = (id: string) => {
  const { onError } = useAlert();

  const { data } = useQuery(GET_PROJECT_MARKET, {
    onError: ({ message }) => onError(message),
    skip: !id,
    variables: {
      id,
    },
  });

  const { market, serviceableStatus } = data?.project || {};

  return {
    marketCode: (market && market.code) || null,
    serviceableStatus,
  };
};

const useUserAddressUpdate = (userId: string) => {
  const onAddressUpdateComplete = useCallback(
    (proxy: DataProxy, { data }: FetchResult<ProjectAddressUpdate>) => {
      const userDetails = proxy.readQuery<
        UserDetailsGet,
        UserDetailsGetVariables
      >({
        query: GET_USER_DETAILS,
        variables: {
          id: userId,
        },
      });

      if (!userDetails || !data?.projectAddressUpdate) return;

      const { address } = data.projectAddressUpdate;

      proxy.writeQuery({
        data: {
          user: {
            ...userDetails.user,
            currentRenovationPlan: {
              ...userDetails.user.currentRenovationPlan,
              address,
            },
          },
        },
        query: GET_USER_DETAILS,
        variables: {
          id: userId,
        },
      });
    },
    [userId],
  );

  return { onAddressUpdateComplete };
};

export const useUserSidebar = (userId: string) => {
  const [isEditInProgress, setIsEditInProgress] = useState(false);
  const {
    address,
    errorFetch,
    loadingFetch,
    projectType,
    renovationPlanId,
    user,
  } = useGetUser(userId);

  const toggleIsEditInProgress = useCallback(
    () => setIsEditInProgress((status) => !status),
    [setIsEditInProgress],
  );

  const { onAddressUpdateComplete } = useUserAddressUpdate(userId);

  const { secondaryContact } = useGetProjectPlanSecondaryContact({
    projectId: renovationPlanId,
  });
  const secondaryContactEmail = secondaryContact.email;

  const { initialValues, loadingSubmit, onSubmit, userData } =
    useProfileSidebarSection({
      address,
      onAddressUpdateComplete,
      onEditComplete: toggleIsEditInProgress,
      renovationPlanId,
      user,
      userId,
    });

  const { marketCode, serviceableStatus } = useGetMarketCode(renovationPlanId);

  return {
    errorFetch,
    initialValues,
    isEditInProgress,
    loadingFetch,
    loadingSubmit,
    marketCode,
    onSubmit,
    projectType,
    renovationPlanId,
    secondaryContactEmail,
    serviceableStatus,
    toggleIsEditInProgress,
    userData,
  };
};
