import { gql } from '@apollo/client';

export const typeDefs = gql`
  extend type User {
    currentRenovationPlan: RenovationPlan
    hasRenovationPlan: Boolean!
    currentProject: Project
  }

  extend type PaymentScheduleTermInvoice {
    stripeUrl: String!
    oldCheckoutUrl: String!
  }
`;
