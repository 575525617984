import { FormDatePicker } from 'common/components/Form/DatePicker/FormDatePicker';
import { FormSelect } from 'common/components/Form/Select/FormSelect';
import { FormTextField } from 'common/components/Form/TextField/FormTextField';
import { FormCurrencyField } from 'common/components/Form/CurrencyField/FormCurrencyField';

import {
  PROJECT_SHIPMENT_DELIVER_TO_OPTIONS,
  PROJECT_SHIPMENT_SHIPPING_METHOD_OPTIONS,
  PROJECT_SHIPMENT_STATUS_OPTIONS,
  PROJECT_SHIPMENT_TYPE_OPTIONS,
} from 'Project/Procurement/common/consts/shipment.consts';

export const ShipmentForm = () => (
  <>
    <FormSelect
      id="type"
      isDisabled
      label="Type"
      name="type"
      options={PROJECT_SHIPMENT_TYPE_OPTIONS}
    />
    <FormSelect
      id="status"
      label="Shipment Status"
      name="status"
      options={PROJECT_SHIPMENT_STATUS_OPTIONS}
    />
    <FormDatePicker label="ETA to Destination" name="estimatedDeliveryDate" />
    <FormSelect
      id="deliverTo"
      label="Destination"
      name="deliverTo"
      options={PROJECT_SHIPMENT_DELIVER_TO_OPTIONS}
    />
    <FormDatePicker label="Actual Delivery Date" name="actualDeliveryDate" />
    <FormSelect
      id="shippingMethod"
      label="Shipment Method"
      name="shippingMethod"
      options={PROJECT_SHIPMENT_SHIPPING_METHOD_OPTIONS}
    />
    <FormTextField
      id="trackingNumber"
      label="Tracking Number"
      name="trackingNumber"
    />
    <FormCurrencyField
      id="shippingCost"
      label="Cost to Ship"
      name="shippingCost"
    />
    <FormTextField
      id="trackingLink"
      label="Tracking Link"
      name="trackingLink"
    />
    <FormCurrencyField
      id="shippingTax"
      label="Tax on Shipping"
      name="shippingTax"
    />
  </>
);
