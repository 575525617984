import { useParams } from 'react-router-dom';

import { sendEvent } from 'common/utils/segment';
import { LEGACY_PLAN_INFORMATION_CLICKED_EVENT } from 'common/consts/segmentEvents';
import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';

import { useGetProject } from 'common/hooks/Project/useGetProject';
import { useAuth } from 'common/hooks/AuthenticationProvider/authenticationProvider.hooks';

import { QueryParams } from 'ProjectProfile/common/types/queryParams';
import { QuestionPlaceholder } from 'ProjectProfile/common/types/questionPlaceholder';
import { useGetProjectPlanQuestions } from 'ProjectProfile/common/hooks/useProjectPlanQuestions';
import { normalizeProjectPlanQuestions } from 'ProjectProfile/common/utils/normalizeProjectPlanQuestions';
import { mapQuestionToPlaceholder } from 'ProjectProfile/common/utils/questionAnswer';

import { identifiers } from './summary.consts';

export const useSummary = () => {
  const { projectId } = useParams<QueryParams>();
  const { isPostDesignContractorAssignment, projectType, userId } =
    useGetProject(projectId);
  const { currentUser } = useAuth();

  const { data, isLoading } = useGetProjectPlanQuestions({
    identifiers,
    projectId,
  });

  const normalizedProjectPlanQuestions = normalizeProjectPlanQuestions(data);

  const handleSegmentClick = () => {
    sendEvent(LEGACY_PLAN_INFORMATION_CLICKED_EVENT, {
      adminUserId: currentUser?.id,
      renovationPlanId: projectId,
    });
  };

  const placeholders = identifiers.reduce(
    (acc, identifier) => ({
      ...acc,
      [identifier]: mapQuestionToPlaceholder(identifier, data),
    }),
    {} as QuestionPlaceholder,
  );

  return {
    exceptions:
      normalizedProjectPlanQuestions[PropertyGroupIdentifier.EXCEPTIONS],
    handleSegmentClick,
    importantProjectCallOuts:
      normalizedProjectPlanQuestions[
        PropertyGroupIdentifier.IMPORTANT_PROJECT_CALL_OUTS
      ],
    isLoading,
    isPostDesignContractorAssignment: isPostDesignContractorAssignment ?? false,
    placeholders,
    projectType,
    userId,
  };
};
