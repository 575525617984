import { Modal } from 'common/components/Modal/Modal';

import { Switch } from 'common/components/Form/Switch/Switch';

import { useProposalAction } from 'PricingEngineSidebar/Proposal/ProposalAction/proposalAction.hooks';

import {
  Button,
  ButtonsWrapper,
  Wrapper,
} from 'PricingEngineSidebar/Proposal/ProposalAction/proposalAction.styles';

import { ErrorPublishingProposal } from 'PricingEngineSidebar/Proposal/ProposalAction/ErrorPublishingProposal/ErrorPublishingProposal';

import { Dialog } from 'common/components/Modals/Dialog/Dialog';
import { ProposalQaRequest } from 'PricingEngineSidebar/Proposal/ProposalAction/ProposalQaRequest/ProposalQaRequest';
import {
  PROPOSAL_MATERIALS_QUANTITIES_VALIDATION_MODAL,
  REQUEST_PROPOSAL_QA_MODAL,
} from 'PricingEngineSidebar/Proposal/ProposalAction/proposalAction.consts';
import { ValidationErrorModal } from 'PricingEngineSidebar/Proposal/ProposalAction/ValidationErrorModal/ValidationErrorModal';

export const ProposalAction = () => {
  const {
    canPublish,
    canUnPublish,
    canUpdateRequireAcceptance,
    handleCloseErrorPublishingProposalModal,
    handleRequestProposalQa,
    isErrorPublishingProposalModalOpen,
    isProposalQaEnabled,
    isProposalQaRequestDisabled,
    isPublishDisabled,
    isUnPublishDisabled,
    latestDesignQaCompletedAt,
    latestProcurementQaCompletedAt,
    numberOfPricingEngines,
    pricingEnginesWithMaterialsQuantitiesError,
    proposalId,
    publishProposal,
    publishProposalButtonLabel,
    requireAcceptance,
    unpublishProposal,
    updateRequireAcceptance,
    validateAndPublishProposal,
  } = useProposalAction();

  return (
    <Wrapper>
      {canUpdateRequireAcceptance && (
        <Switch
          checked={requireAcceptance}
          id="require-payment"
          label="Allow Acceptance"
          onChange={updateRequireAcceptance}
        />
      )}
      {isProposalQaEnabled && (
        <Button
          color="primary"
          disabled={isProposalQaRequestDisabled}
          onClick={handleRequestProposalQa}
        >
          Request QA
        </Button>
      )}

      <ButtonsWrapper>
        {canUnPublish && (
          <Button disabled={isUnPublishDisabled} onClick={unpublishProposal}>
            Unpublish
          </Button>
        )}

        {canPublish && (
          <Button
            disabled={isPublishDisabled}
            onClick={validateAndPublishProposal}
          >
            {publishProposalButtonLabel}
          </Button>
        )}
      </ButtonsWrapper>

      <Modal
        onClose={handleCloseErrorPublishingProposalModal}
        open={isErrorPublishingProposalModalOpen}
      >
        <ErrorPublishingProposal proposalId={proposalId} />
      </Modal>
      <Dialog fullWidth id={REQUEST_PROPOSAL_QA_MODAL} maxWidth="sm">
        <ProposalQaRequest
          latestDesignQaCompletedAt={latestDesignQaCompletedAt}
          latestProcurementQaCompletedAt={latestProcurementQaCompletedAt}
          proposalId={proposalId}
        />
      </Dialog>
      <Dialog
        fullWidth
        id={PROPOSAL_MATERIALS_QUANTITIES_VALIDATION_MODAL}
        maxWidth="sm"
      >
        <ValidationErrorModal
          affectedPricingEngines={
            pricingEnginesWithMaterialsQuantitiesError?.length
              ? pricingEnginesWithMaterialsQuantitiesError
              : []
          }
          numberOfPricingEngines={numberOfPricingEngines}
          publishAction={publishProposal}
        />
      </Dialog>
    </Wrapper>
  );
};
