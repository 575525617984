import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mq } from 'common/utils/mediaQueries';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.white};
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  height: 100%;
  max-height: 75vh;
  width: 100vw;

  ${mq.small(css`
    width: 500px;
  `)};
`;

export const Content = styled.div`
  padding: 24px;
  overflow-y: auto;
`;

export const Header = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.greige};
  border-bottom: 1px solid ${({ theme }) => theme.color.alto};
  display: grid;
  font: 500 18px/32px ${({ theme }) => theme.font.inter};
  grid-template-columns: 1fr 20px;
  padding: 24px;
  position: relative;
`;

export const Footer = styled.div`
  column-gap: 16px;
  display: flex;
  justify-content: flex-end;
  padding: 0 24px 24px;
`;
