import { LoadingSpinnerWithContainer } from 'common/components/LoadingSpinner/LoadingSpinnerWithContainer';

import { useBreakGroundDate } from './breakGroundDate.hooks';

export const BreakGroundDate = ({ renovationPlanId = '' }) => {
  const {
    BreakGroundDateComponent,
    handleCloseClick,
    handleDateChange,
    handleEditClick,
    initialDate,
    isLoading,
    likeliestDate,
    likeliestDateType,
    selectedDate,
    showDateInput,
  } = useBreakGroundDate(renovationPlanId);

  return (
    <>
      {isLoading ? (
        <LoadingSpinnerWithContainer />
      ) : (
        <BreakGroundDateComponent
          handleCloseClick={handleCloseClick}
          handleDateChange={handleDateChange}
          handleEditClick={handleEditClick}
          initialDate={initialDate}
          likeliestDate={likeliestDate}
          likeliestDateType={likeliestDateType}
          selectedDate={selectedDate}
          showDateInput={showDateInput}
        />
      )}
    </>
  );
};
