import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-bottom: solid 1px ${({ theme }) => theme.color.gray6};
`;

export const DeleteSpace = styled.a`
  color: ${({ theme }) => theme.color.red11};
  text-align: right;
  cursor: pointer;
`;
