import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Paper } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

import { rgba } from 'common/utils/colors';

export const Button = styled.button`
  ${({ theme }) => theme.typography.header6}
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray3};
  border: 1px solid ${({ theme }) => theme.color.gray6};
  border-radius: 4px;
  color: ${({ theme }) => theme.color.black};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-width: 200px;
  outline: none;
  padding: 10px 8px 10px 16px;

  &:focus {
    border-color: ${({ theme }) => theme.color.primaryOrange};
    color: ${({ theme }) => theme.color.primaryOrange};
  }

  &:hover {
    color: ${({ theme }) => theme.color.primaryOrange};
    text-decoration: underline;
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.color.lightGray};
    border: 0;
    color: ${({ theme }) => theme.color.darkGray};
    cursor: default;
    text-decoration: none;
  }
`;

export const ButtonIcon = styled(MoreVertIcon)<SvgIconProps>`
  color: ${({ theme }) => theme.color.gray12};

  && {
    cursor: pointer;
    height: 24px;
    width: 24px;

    &:hover {
      color: ${({ theme }) => theme.color.primaryOrange};
    }
  }
`;

type MenuProps = {
  width?: number;
};

export const Menu = styled(Paper)<MenuProps>`
  && {
    border: 1px solid ${({ theme }) => rgba(theme.color.black, 0.14)};
    box-shadow: none;
    box-sizing: border-box;
    padding: 0;
    width: ${({ width }) => `${width}px`};
  }
`;

export const MenuItem = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.black};
  cursor: pointer;
  display: flex;
  font: normal 300 12px/16px ${({ theme }) => theme.font.inter};
  padding: 12px 19px;

  &:hover {
    background-color: ${({ theme }) => theme.color.greige};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => rgba(theme.color.black, 0.14)};
  }

  > svg {
    margin-right: 19px;
    color: ${({ theme }) => theme.color.gainsboro};
  }
`;

export const poperStyles = css`
  z-index: 9;
`;
