import { useParams } from 'react-router-dom';

import { QueryParams } from 'ProjectProfile/common/types/queryParams';
import { useProjectPlanLinksUpdate } from 'ProjectProfile/common/hooks/useProjectPlanLinksUpdate';
import { useProjectPlanLinks } from 'ProjectProfile/common/hooks/useProjectPlanLinks';

import { ProjectPlanLinksInput } from 'ProjectProfile/PreDesign/Links/links.types';

import { emptyLink } from './otherLinks.consts';

export const useOtherLinksEdit = () => {
  const { projectId } = useParams<QueryParams>();
  const { handleSubmit } = useProjectPlanLinksUpdate();
  const { projectPlanLinks } = useProjectPlanLinks({
    projectId,
  });

  const otherLinks = (projectPlanLinks.otherLinks || []).map(
    ({ name, url }) => ({
      name,
      url,
    }),
  );

  const initialValues = {
    otherLinks: otherLinks.length ? otherLinks : [emptyLink],
  };

  const submit = (values: ProjectPlanLinksInput, close: () => void) =>
    handleSubmit({
      onSuccessCallback: close,
      values,
    });

  return {
    emptyLink,
    initialValues,
    submit,
  };
};
